import {ImageListItemBar, Typography } from "@mui/material";
import { memo } from "react";
import { theme } from "../../theme";

interface Props {
  imageSrc: string;
  kpiName: string;
}
const ThumbnailComponent = (props: Props) => {
 
  return (
    <>
      <img
        src={props.imageSrc}
        alt="kpi-thumbnail"
      />
      <ImageListItemBar
        position="below"
        title={
          <Typography
            noWrap
            fontSize={"12px"}
            sx={{ color: theme.custom.primaryGreyColor }}
            variant="inter_500_10"
          >
            {props.kpiName}
          </Typography>
        }
      />
    </>
  );
};

export default memo(ThumbnailComponent);
