import {
  Button,
  CircularProgress,
  Container,
  Drawer,
  Grid,
  Menu,
  Popper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useRef, useEffect, useState, memo } from "react";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useFullScreenHandle } from "react-full-screen";
import TripleDot from "../../../assets/fi-rr-menu-dots.png";
import getKPIData, {
  generateSmartStatementForKpi,
} from "../../../services/kpi-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { theme } from "../../../theme";
import { ChartData } from "../../../Types/ChartData";
import { DataForAtoms } from "../../../Types/DataForAtoms";
import AreaChart from "../../atoms/AreaChart";
import BarChart from "../../atoms/BarChart";
import BarStackedChart from "../../atoms/BarStackedChart";
import ColumnAndLineChart from "../../atoms/ColumnAndLineChart";
import ColumnChart from "../../atoms/ColumnChart";
import ColumnStackedChart from "../../atoms/ColumnStackedChart";
import DisplayNumberDataComponent from "../../atoms/DisplayNumberDataComponent";
import DoublePieChart from "../../atoms/DoublePieCharts";
import GroupedColumnChart from "../../atoms/GroupedColumnChart";
import GroupedColumnStackedChart from "../../atoms/GroupedColumnStackedChart";
import MultipleAreaChart from "../../atoms/MultipleAreaCharts";
import MultipleLineChart from "../../atoms/MultipleLineCharts";
import PieChart from "../../atoms/PieChart";
import ShadowColumnChart from "../../atoms/ShadowColumnChart";
import TreeMapChart from "../../atoms/TreeMapChart";
import DataTableMolecule from "../DataTableMolecule";
import WordCloudChart from "../../atoms/WordCloudChart";
import WaterfallChart from "../../atoms/WaterFallChart";
import AreaPercentageChart from "../../atoms/AreaPercentageChart";
import GroupedBarChart from "../../atoms/GroupedBarChart";
import KPIInfoPopover from "../../atoms/KPIInfoPopover";
import InfoIcon from "../../../assets/info.png";
import FilterIcon from "../../../assets/filter_icon.png";
import KPIDrawer from "../../atoms/KPIDrawer";
import "./KPIMolecule.scss";
import GroupedColumnAndLineChart from "../../atoms/GroupedColumnAndLineChart";
import GroupedColumnStackedAndLineChart from "../../atoms/GroupedColumnStackedAndLineChart";
import { useDeepCompareDiffEffect } from "../../../hooks/useDeepCompareDiffEffect";
import LineChart from "../../atoms/LineChart";
import DisplayNumberWithoutTitleComponent from "../../atoms/NumberComponentWithoutTitle";
import {
  setSelectedKPI,
  setSelectedKPITitle,
} from "../../../store/filter-state-store";
import { useNavigate } from "react-router-dom";
import { filterDataFormatter } from "../../../utils/filterDataFormatter";
import MoveIcon from "../../../assets/move_icon.png";
import { DEFAULT_ERROR_MESSAGE } from "../../../constants/constants";
import {
  compact,
  each,
  every,
  filter,
  isArray,
  isEmpty,
  isObject,
  last,
  map,
  mapValues,
  remove,
  set,
  uniq,
} from "lodash";
import {
  extractDropdownItems,
  extractDropdownKeys,
  filterChartData,
  filterDataByConditions,
  filterStringBasedOnKey,
} from "../../../utils/kpi-utils";
import AreaStackedChart from "../../atoms/AreaStackedChart";
import KPISmartStatementPopover from "../../atoms/KPISmartStatementPopover";
import { returnSmartStatementTransformedData, findLabelsForShowTitleFilters } from "../../../utils/CommonKPIResponseFormatterHelper";
import { countNumbers } from "../../../utils/data-utils";
import DrillDownModal from "../DrillDownModal";
import KPIBubbleMenu from "./KPIBubbleMenu";
import TablePagination from "../../atoms/data-table/TablePagination";
import MagicStick from "../../../../src/assets/magic_wand_multi_color.png";
import GroupTable from "../DataTable/GroupTable";
import {
  downloadAttachments,
  previewAttachment,
  updateInvoiceState,
} from "../../../services/board-service";
import CustomMonthPicker from "../../atoms/CustomMonthPicker";
import moment from "moment";
import { io } from "socket.io-client";
import { environment } from "../../../environments/environment-dev";
import { getCompanyId } from "../../../services/userinfo-service";
import { nanoid } from "nanoid";
import { LoadingButton } from "@mui/lab";
import { dateFormatter } from "../../../utils/dataFormatters";
interface Props {
  chartData: ChartData;
  filters: any;
  customKPI?: boolean;
  setNuggetFunc?: Function;
  element?: any;
  selectedSelf?: any;
  addTextElement?: Function;
  duplicateElement?: Function;
  deleteElement?: Function;
  updateElement?: Function;
  isElementOnMove?: boolean;
  editorMode?: boolean;
  isPublic?: boolean;
  commKey?: string;
  gridMetaData?: any;
  belongToCustomBoard?: boolean;
  board_slug?: string;
  source?: string;
  board_type?: string;
  showSmartStatement?: boolean;
  layoutInfo?: any;
  extraProps?: any;
  availableChartData?: any;
  inPanorama?: boolean;
  attributes?:any;
}

enum ErrorTypes {
  NONE,
  NO_DATA,
  CONNECTOR_NOT_LINKED,
  UM_RECURSION_ERROR,
  SERVER_GOOFED_UP,
  NO_BUDGET,
  NO_PROGRAM_USER_MAPPING,
  KPI_DOESNT_EXIST,
  NO_BUDGET_DATA,
  KPI_ACCESS_DENIED,
}

const KPIMolecule: React.FC<Props> = (data) => {
  const layoutInfo = data.layoutInfo || null;
  const isPublic = data.isPublic;
  const handle = useFullScreenHandle();
  const navigate = useNavigate();
  const board_slug = data.board_slug;
  const belongToCustomBoard = data.belongToCustomBoard || false;
  const boardType = data.board_type || "";
  const showSmartStatement = data.showSmartStatement || false;
  const containerRef = useRef(null as any);
  const selectedKPICurrentFilters: any = useAppSelector(
    (state) => state.filterState.selectedKPICurrentFilters
  );
  const selectedElementAllFilters: any = useAppSelector((
    (state) => state.selectedDesignElement.allFiltersInStore
  ))

  const heightRef = useRef(75);

  const [socket, setSocket] = useState<any>(null);
  const availableChartData = data.availableChartData || {};
  const inPanorama = data.inPanorama || false;

  const INIT_STATE = {
    data: [],
    meta_data: {},
    drill_down_config: {},
    slug: "",
    type: "",
    dropdown_opts: [],
    pagination: {},
  };

  const INIT_TABLE_STATE = {
    includedRows: [] as any,
    excludedRows: [] as any,
    selectAll: false as boolean,
  };

  const dispatch = useAppDispatch();
  const kpiData: any = data.chartData;
  const editorMode: boolean = data.editorMode || false;
  const showTitle =  !editorMode ||  (data.filters?.show_title !== 'no');
  const [kpiState, setKpiState] = useState<DataForAtoms>(INIT_STATE);
  const [kpiMasterData, setKpiMasterData] = useState([]);

  const [isKPIRendered, setIsKPIRendered] = useState(false);
  const [chartComponentReference, setChartComponentReference] =
    useState<any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [isError, setIsError] = useState(false);
  const [errorType, setErrorType] = useState<ErrorTypes>(ErrorTypes.NONE);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [infoDrawerOpen, setInfoDrawerOpen] = useState(false);
  const [openedPopover, setOpenedPopover] = useState(false);
  const [disableSmartStatement, setDisableSmartStatement] = useState(false);
  const [openedSmartStatementPopover, setSmartStatementPopover] =
    useState(false);
  const smartStatementPopoverAnchor = useRef(null);
  const [kpiTransformedData, setKpiTransformedData] = useState({});
  const popoverAnchor = useRef(null);
  const [height, setHeight] = useState<any>();
  const [width, setWidth] = useState<any>();
  const [titleHeight, setTitleHeight] = useState<number>(75);
  const chartPadding = 8;
  const [loadingSmartStatement, setLoadingSmartStatement] = useState(true);
  const [loadingSmartStatementText, setLoadingSmartStatementText] = useState(
    "Analysing KPI via Pebble AI.."
  );
  const [smartStatementText, setSmartStatementText] = useState<any>("");

  const [dropdownOpts, setDropdownOptions] = useState({} as any);
  const [selectedOptions, setSelectedOptions] = useState({} as any);
  const [dropdownIterables, setDropdownIterables] = useState([] as string[]);
  const [dropdownItems, setDropdownItems] = useState({} as any);

  const [openDrilldownModal, setOpenDrilldownModal] = useState(false);
  const [budgetDrillDownPayload, setBudgetDrilldownPayload] = useState<any>({});

  const [anchorSettingEl, setAnchorSettingEl] =
    React.useState<null | HTMLElement>(null);
  const [showPopper, setShowPopper] = useState(false);
  const [loadingAddSmartStatementElement, setLoadingAddSmartStatementElement] =
    useState(false);
  const popperId = showPopper ? "simple-popper" : undefined;
  const extraProps = data.extraProps || {};
  const selectedRows = useRef<any>(INIT_TABLE_STATE);
  const [monthSelectedForBulkUpdate, setMonthSelectedForBulkUpdate] =
    useState<string>("");
  const [downloading, setDownloading] = useState<boolean>(false);
  const [totalRows, setTotalRows] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [enableClearInvoice, setClearInvoice] = useState<boolean>(false);

  const ignoredAttachments = useRef<any>({});

  const getTitle = () =>{
    const title = kpiData.kpi_slug.includes("drill-down")
      ? "Transaction details"
      : kpiData?.kpi_title || kpiState?.meta_data?.chart?.title;

    if(!editorMode) return title;

    return title + (data.attributes?.show_title_filters_labels ?? '');
  
  }

  useEffect(() => {
    let titleHeight = showTitle ? Math.min(
      ((data?.gridMetaData?.column || 6) / 6) * 75,
      75
    ):0;
    setTitleHeight(titleHeight);
    if (kpiData.height) {
      setHeight(kpiData.height - titleHeight - chartPadding * 2);
    }
    if (kpiData.width) {
      setWidth(kpiData.width - chartPadding * 2);
    }
    if (editorMode && kpiData.height) {
      setTitleHeight(
        Math.min((titleHeight * kpiData.height) / heightRef.current, 75)
      );
      heightRef.current = kpiData.height;
    }
  }, [kpiData.height, kpiData.width, showTitle]);

  useEffect(() => {
    // this magical number is guesstimated
    if (countNumbers(kpiState) > 100) {
      setDisableSmartStatement(true);
    } else {
      setDisableSmartStatement(false);
    }
  }, [kpiState]);

  useEffect(() => {
    if (availableChartData && Object.keys(availableChartData).length) {
      setKpiState((prev) => {
        return {
          ...prev,
          data: availableChartData["data"],
          meta_data: availableChartData?.meta_data,
          slug: availableChartData?.slug,
          type: availableChartData?.type,
          drill_down_config: availableChartData?.drill_down_config,
          pagination: availableChartData?.pagination,
        };
      });
      setIsKPIRendered(true);
      setIsError(false);
    }

    let obj: any = {};
    if (isArray(availableChartData["data"])) {
      if (availableChartData["data"].length) {
        availableChartData["data"].forEach((ele: any) => {
          let temp = dropdownRenderHelper(ele);
          obj = { ...obj, ...temp };
        });

        Object.keys(obj).forEach((ele: string) => {
          obj[ele] = compact(uniq(map(availableChartData["data"], ele)));
        });
        if (obj && Object.keys(obj).length === 1) {
          setSelectedOptions((prev: any) => {
            return {
              ...prev,
              "dropdown-menu-item": last(obj["dropdown-menu-item"]),
            };
          });

          let filteredData = filterChartData(availableChartData["data"], {
            "dropdown-menu-item": last(obj["dropdown-menu-item"]) as string,
          });
          if (filteredData) {
            setKpiState((prev) => {
              return { ...prev, data: filteredData };
            });
          }
        } else {
          let _selectedOptions = { ...selectedOptions };
          Object.keys(obj).forEach((ele: string) => {
            _selectedOptions[ele] = filterStringBasedOnKey(obj[ele], "All");
            setSelectedOptions(_selectedOptions);
          });
          //For pre fetching the data on the first load
          let filteredData = filterDataBasedOnFiltersSelected(
            availableChartData["data"],
            _selectedOptions
          );
          if (filteredData) {
            setKpiState((prev) => {
              return { ...prev, data: filteredData };
            });
          }
        }
        setDropdownOptions(obj);
      }
    } else {
      let obj: any = {};
      if (isObject(availableChartData["data"])) {
        each(availableChartData["data"], (value: any, key) => {
          value.forEach((ele: any) => {
            let temp = dropdownRenderHelper(ele);
            obj = { ...obj, ...temp };
          });

          Object.keys(obj).forEach((ele: string) => {
            obj[ele] = compact(uniq(map(value, ele)));
          });
        });
        if (obj && Object.keys(obj).length === 1) {
          setSelectedOptions((prev: any) => {
            return {
              ...prev,
              "dropdown-menu-item": last(obj["dropdown-menu-item"]),
            };
          });

          let filteredData = filterChartData(availableChartData["data"], {
            "dropdown-menu-item": last(obj["dropdown-menu-item"]) as string,
          });
          if (filteredData) {
            setKpiState((prev) => {
              return { ...prev, data: filteredData };
            });
          }
        } else {
          let _selectedOptions = { ...selectedOptions };
          Object.keys(obj).forEach((ele: string) => {
            _selectedOptions[ele] = filterStringBasedOnKey(obj[ele], "All");
            setSelectedOptions(_selectedOptions);
          });
          //For pre fetching the data on the first load
          let filteredData = filterDataBasedOnFiltersSelected(
            availableChartData["data"],
            _selectedOptions
          );
          if (filteredData) {
            setKpiState((prev) => {
              return { ...prev, data: filteredData };
            });
          }
        }
        setDropdownOptions(obj);
      }
    }
  }, [availableChartData]);

  useDeepCompareDiffEffect(
    (diff) => {
      if (availableChartData && Object.keys(availableChartData).length) {
        return;
      }
      if (Object.keys(diff).length > 0) {
        setKpiState(INIT_STATE);
        setIsError(false);
        setErrorType(ErrorTypes.NONE);
        setIsKPIRendered(false);
        let filters = { ...data.filters };
        if (filters) {
          getData(
            kpiData?.kpi_slug,
            filters,
            data.customKPI || false,
            data.isPublic || false,
            data.commKey,
            !editorMode ? board_slug : "impact-report"
          );
        }
      }
    },
    [data.filters]
  );

  useEffect(() => {
    if (data.board_slug !== "expense-proof-and-invoice-tracking") return;
    if (socket) {
      socket.on("my_response", (msg: any) => {
        if (typeof msg.data === "object") {
          if (msg.data.status === "Failed") {
            setDownloading(false);
            dispatch(
              showMessage({
                message: "Download failed. Try again",
              })
            );
          }
          if (msg.data.hasOwnProperty("url")) {
            let link = document.createElement("a");
            link.setAttribute("href", msg.data?.["url"]);
            document.body.appendChild(link);
            link.setAttribute("download", msg.data?.["url"].split("/").pop());
            link.click();
            setDownloading(false);
            dispatch(
              showMessage({
                message: "Download successful",
                variant: "success",
              })
            );
            socket.disconnect();
          }
        }
      });
    }
  }, [socket]);

  const dropdownRenderHelper = (obj: any) => {
    let _obj: any = {};
    Object.keys(obj).filter((keys: any) => {
      if (/dropdown-menu-item/.test(keys)) {
        _obj[keys] = [];
      }
    });
    return _obj;
  };

  const getData = (
    kpi_slug: string,
    filters: any,
    isStory: boolean,
    isPublic: boolean,
    commKey?: string,
    board_slug?: string
  ) => {
    if (availableChartData && Object.keys(availableChartData).length) {
      return;
    }
    setKpiState((prev) => {
      return { ...prev, data: [] };
    });
    if (!kpi_slug) {
      return;
    }
    getKPIData(
      kpi_slug,
      filters,
      isStory,
      isPublic,
      commKey,
      board_slug,
      data.source
    ).then((res) => {
      if (res?.status === 404) {
        setIsError(true);
        setErrorType(ErrorTypes.KPI_DOESNT_EXIST);
        return;
      } else if (res?.status === 403) {
        setIsError(true);
        setErrorType(ErrorTypes.KPI_ACCESS_DENIED);
        return;
      } else if (res?.status === 406) {
        setIsError(true);
        setErrorType(ErrorTypes.CONNECTOR_NOT_LINKED);
        return;
      } else if (res?.status === 422) {
        setIsError(true);
        setErrorType(ErrorTypes.UM_RECURSION_ERROR);
        return;
      } else if (
        res.status === 200 &&
        !res.data?.is_um_done &&
        res.data?.is_budget_kpi
      ) {
        setIsError(true);
        setErrorType(ErrorTypes.NO_PROGRAM_USER_MAPPING);
        return;
      } else if (
        res.status === 200 &&
        res.data?.is_um_done &&
        !res.data?.has_active_budget &&
        res.data?.is_budget_kpi === true
      ) {
        setIsError(true);
        setErrorType(ErrorTypes.NO_BUDGET);
        if (data.setNuggetFunc) {
          data.setNuggetFunc({
            title: "",
            value: "No budget available",
          });
        }

        return;
      } else if (
        res.status === 200 &&
        res.data?.is_budget_kpi &&
        res.data?.data?.length === 0
      ) {
        setIsError(true);
        setErrorType(ErrorTypes.NO_BUDGET_DATA);
        return;
      } else if (
        res.status === 200 &&
        !res.data?.is_budget_kpi &&
        res.data?.data?.length === 0
      ) {
        setIsError(true);
        setErrorType(ErrorTypes.NO_DATA);
        return;
      } else if (res?.status === 500) {
        setIsError(true);
        setErrorType(ErrorTypes.SERVER_GOOFED_UP);
        return;
      }
      if (!!data.setNuggetFunc) data.setNuggetFunc(res.data.nugget || "");
      setKpiState((prev) => {
        return {
          ...prev,
          data: res?.data?.data,
          meta_data: res?.data?.meta_data,
          slug: res?.data?.slug,
          type: res?.data?.type,
          drill_down_config: res?.data?.drill_down_config,
          pagination: res?.data?.pagination,
        };
      });
      setIsKPIRendered(true);
      if (res?.data && !!data.setNuggetFunc)
        data.setNuggetFunc(res.data.nugget || { title: "", value: "" });
      if (res && res.data && res?.data?.data && res?.data?.data.length === 0) {
        setIsError(true);
        setErrorType(ErrorTypes.NO_DATA);

        setIsKPIRendered(false);
        setKpiState((prev) => {
          return { ...prev, meta_data: res?.data?.meta_data };
        });
        return;
      }
      setKpiMasterData(res.data.data);

      let dropdownKeys = extractDropdownKeys(res.data.data);

      setDropdownIterables(dropdownKeys);

      let dd_items = extractDropdownItems(
        filterDataByConditions(
          res.data.data,
          dropdownKeys[0] in selectedOptions
            ? { [dropdownKeys[0]]: selectedOptions[dropdownKeys[0]] }
            : {}
        )
      );

      setDropdownItems(dd_items);

      let obj: any = {};
      if (isArray(res.data.data)) {
        if (res?.data?.data?.length) {
          res?.data?.data.forEach((ele: any) => {
            let temp = dropdownRenderHelper(ele);
            obj = { ...obj, ...temp };
          });

          Object.keys(obj).forEach((ele: string) => {
            obj[ele] = compact(uniq(map(res?.data?.data, ele)));
          });
          if (obj && Object.keys(obj).length === 1) {
            setSelectedOptions((prev: any) => {
              return {
                ...prev,
                "dropdown-menu-item": last(obj["dropdown-menu-item"]),
              };
            });

            let filteredData = filterChartData(res.data.data, {
              "dropdown-menu-item": last(obj["dropdown-menu-item"]) as string,
            });
            if (filteredData) {
              setKpiState((prev) => {
                return { ...prev, data: filteredData };
              });
            }
          } else {
            let _selectedOptions = { ...selectedOptions };
            Object.keys(obj).forEach((ele: string) => {
              _selectedOptions[ele] = obj[ele][0];
              setSelectedOptions(_selectedOptions);
            });

            dd_items = extractDropdownItems(
              filterDataByConditions(
                res.data.data,
                dropdownKeys[0] in _selectedOptions
                  ? { [dropdownKeys[0]]: _selectedOptions[dropdownKeys[0]] }
                  : {}
              )
            );

            let dd_item_parent = {
              [dropdownKeys[0]]: extractDropdownItems(res.data.data)[
                dropdownKeys[0]
              ],
            };
            //For making parents filter to have all the options
            setDropdownItems({ ...dd_items, ...dd_item_parent });

            //For pre fetching the data on the first load
            let filteredData = filterDataBasedOnFiltersSelected(
              res.data.data,
              _selectedOptions
            );
            if (filteredData) {
              setKpiState((prev) => {
                return { ...prev, data: filteredData };
              });
            }
          }
          setDropdownOptions(obj);
        }
      } else {
        let obj: any = {};
        if (isObject(res.data.data)) {
          each(res.data.data, (value, key) => {
            value.forEach((ele: any) => {
              let temp = dropdownRenderHelper(ele);
              obj = { ...obj, ...temp };
            });

            Object.keys(obj).forEach((ele: string) => {
              obj[ele] = compact(uniq(map(value, ele)));
            });
          });
          if (obj && Object.keys(obj).length === 1) {
            setSelectedOptions((prev: any) => {
              return {
                ...prev,
                "dropdown-menu-item": last(obj["dropdown-menu-item"]),
              };
            });

            let filteredData = filterChartData(res.data.data, {
              "dropdown-menu-item": last(obj["dropdown-menu-item"]) as string,
            });
            if (filteredData) {
              setKpiState((prev) => {
                return { ...prev, data: filteredData };
              });
            }
          } else {
            let _selectedOptions = { ...selectedOptions };
            Object.keys(obj).forEach((ele: string) => {
              _selectedOptions[ele] = obj[ele][0];
              setSelectedOptions(_selectedOptions);
            });
            dd_items = extractDropdownItems(
              filterDataByConditions(
                res.data.data,
                dropdownKeys[0] in _selectedOptions
                  ? { [dropdownKeys[0]]: _selectedOptions[dropdownKeys[0]] }
                  : {}
              )
            );

            let dd_item_parent = {
              [dropdownKeys[0]]: extractDropdownItems(res.data.data)[
                dropdownKeys[0]
              ],
            };
            //For making parents filter to have all the options
            setDropdownItems({ ...dd_items, ...dd_item_parent });
            //For pre fetching the data on the first load
            let filteredData = filterDataBasedOnFiltersSelected(
              res.data.data,
              _selectedOptions
            );
            if (filteredData) {
              setKpiState((prev) => {
                return { ...prev, data: filteredData };
              });
            }
          }
          setDropdownOptions(obj);
        }
      }
      if (kpiState.type === "table") {
        setIsKPIRendered(true);
      }
    });
  };

  useEffect(() => {
    if (openedSmartStatementPopover) {
      getKPISmartStatement(kpiTransformedData, {});
    } else {
      setLoadingSmartStatement(true);
      setLoadingSmartStatementText("Generating smart statement with Pebble AI");
      setSmartStatementText("");
    }
  }, [openedSmartStatementPopover]);

  const getKPISmartStatement = (kpiTransformedData: any, event: any) => {
    generateSmartStatementForKpi(kpiState.slug, kpiTransformedData)
      .then((res) => {
        setLoadingSmartStatement(false);
        if (res?.data?.response) {
          setSmartStatementText(res?.data?.response);
          if (editorMode) {
            setLoadingAddSmartStatementElement(false);
            let textElement = {
              attributes: {
                text: "",
                html: res?.data?.response,
                font: "dm-sans",
              },
              delta: {
                ops: [
                  {
                    insert: "",
                    attributes: {
                      bold: false,
                    },
                  },
                ],
              },
            };

            data.addTextElement?.(event, textElement, false);
          }
        } else {
          setLoadingSmartStatementText(
            "We couldn’t generate a smart statement at the end. Please try again or try different filter."
          );
        }
      })
      .finally(() => {
        setLoadingSmartStatement(false);
      });
  };

  const handleSmartStatementBulbClick = (event: any) => {
    setLoadingAddSmartStatementElement(true);
    getKPISmartStatement(returnSmartStatementTransformedData(kpiState), event);
  };

  useEffect(() => {
    if (!data.selectedSelf) {
      setAnchorSettingEl(null);
      setShowPopper(false);
    } else {
      setAnchorSettingEl(containerRef.current);
      setShowPopper(true);
    }
  }, [data.selectedSelf]);

  useEffect(() => {
    if (availableChartData && Object.keys(availableChartData).length) {
      return;
    }
    if (isCustomKPIFilterSelected()) {
      setKpiState(INIT_STATE);
      setIsKPIRendered(false);
      getData(
        selectedKPICurrentFilters.kpi_slug,
        {
          ...filterDataFormatter(selectedKPICurrentFilters.data),
          pQoz9ykBE7: "Yke5G9WinP",
        },
        false,
        false,
        "",
        board_slug
      );
    }
  }, [selectedKPICurrentFilters]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openSmartStatementPopover = () => {
    setSmartStatementPopover(true);
    setKpiTransformedData(returnSmartStatementTransformedData(kpiState));
  };

  const closeSmartStatementPopover = () => {
    setSmartStatementPopover(false);
  };

  const onPaginationChange = (page: number) => {
    if (kpiState?.pagination?.["current_page"] === page) {
      return;
    }
    setPageNumber(page);

    setIsKPIRendered(false);
    getData(
      kpiData?.kpi_slug,
      { ...data.filters, page: "" + page },
      data.customKPI || false,
      data.isPublic || false,
      data.commKey,
      !editorMode ? board_slug : "impact-report"
    );
  };

  const pickChartsBasedOnType = () => {
    switch (kpiState.type) {
      case "column":
        return (
          <ColumnChart
            chartMeta={kpiState.meta_data}
            customKPI={data.customKPI}
            chartData={kpiState.data}
            kpiRendered={setIsKPIRendered}
            dateRange={data.filters?.daterange}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "bar":
        return (
          <BarChart
            chartMeta={kpiState.meta_data}
            customKPI={data.customKPI}
            chartData={kpiState.data}
            kpiRendered={setIsKPIRendered}
            dateRange={data.filters?.daterange}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "pie":
        return (
          <PieChart
            chartMeta={kpiState.meta_data}
            customKPI={data.customKPI}
            chartData={kpiState.data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "bar-stacked":
        return (
          <BarStackedChart
            chartMeta={kpiState.meta_data}
            customKPI={data.customKPI}
            chartData={kpiState.data}
            kpiRendered={setIsKPIRendered}
            dateRange={data.filters?.daterange}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "column-stacked":
        return (
          <ColumnStackedChart
            chartMeta={kpiState.meta_data}
            customKPI={data.customKPI}
            chartData={kpiState.data}
            kpiRendered={setIsKPIRendered}
            dateRange={data.filters?.daterange}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "grouped":
        return (
          <GroupedColumnChart
            chartMeta={kpiState.meta_data}
            customKPI={data.customKPI}
            chartData={kpiState.data}
            kpiRendered={setIsKPIRendered}
            dateRange={data.filters?.daterange}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "grouped-bar":
        return (
          <GroupedBarChart
            chartMeta={kpiState.meta_data}
            customKPI={data.customKPI}
            chartData={kpiState.data}
            kpiRendered={setIsKPIRendered}
            dateRange={data.filters?.daterange}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "group-stacked":
        return (
          <GroupedColumnStackedChart
            chartMeta={kpiState.meta_data}
            customKPI={data.customKPI}
            chartData={kpiState.data}
            kpiRendered={setIsKPIRendered}
            dateRange={data.filters?.daterange}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "group-column-stacked-line":
        return (
          <GroupedColumnStackedAndLineChart
            chartMeta={kpiState.meta_data}
            customKPI={data.customKPI}
            chartData={kpiState.data}
            kpiRendered={setIsKPIRendered}
            dateRange={data.filters?.daterange}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "treemap":
        return (
          <TreeMapChart // NA
            chartData={kpiState.data}
            customKPI={data.customKPI}
            chartMeta={kpiState.meta_data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "area":
        return (
          <AreaChart //Done
            chartData={kpiState.data}
            customKPI={data.customKPI}
            chartMeta={kpiState.meta_data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
            kpiPosition={kpiData?.position}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "line":
        return (
          <LineChart // done
            chartData={kpiState.data}
            customKPI={data.customKPI}
            chartMeta={kpiState.meta_data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
            kpiPosition={kpiData?.position}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "multiple-area":
        return (
          <MultipleAreaChart
            chartData={kpiState.data}
            customKPI={data.customKPI}
            chartMeta={kpiState.meta_data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "multiple-line":
        return (
          <MultipleLineChart
            chartData={kpiState.data}
            customKPI={data.customKPI}
            chartMeta={kpiState.meta_data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "column-line":
        return (
          <ColumnAndLineChart
            chartData={kpiState.data}
            customKPI={data.customKPI}
            chartMeta={kpiState.meta_data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "grouped-column-line":
        return (
          <GroupedColumnAndLineChart
            chartData={kpiState.data}
            customKPI={data.customKPI}
            chartMeta={kpiState.meta_data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "double-pie":
        return (
          <DoublePieChart
            chartData={kpiState.data}
            customKPI={data.customKPI}
            chartMeta={kpiState.meta_data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "number":
        return (
          <DisplayNumberDataComponent
            chartData={kpiState.data}
            chartMeta={kpiState.meta_data}
            kpiRendered={setIsKPIRendered}
            kpiPosition={kpiData?.position}
          />
        );
      case "single-number":
        return (
          <DisplayNumberWithoutTitleComponent //NA
            chartData={kpiState.data}
            chartMeta={kpiState.meta_data}
            kpiPosition={kpiData?.position}
            kpiRendered={setIsKPIRendered}
          />
        );
      case "table":
        return (
          <DataTableMolecule
            slug={kpiState.slug}
            setFullscreen={setIsFullscreen}
            isFullscreen={isFullscreen}
            chartData={kpiState.data}
            chartMeta={kpiState.meta_data}
            kpiRendered={setIsKPIRendered}
            flushIgnoredAttachments={flushIgnoredAttachements}
            drillDownConfig={kpiState.drill_down_config}
            onDataPointClick={onDataPointClick}
            onUpdateCallback={onUpdateRows}
            onRowsSelection={onRowsSelection}
            onSingleRowSelection={onSingleRowSelection}
            ignoredAttachments={ignoredAttachments.current}
            onAttachmentClick={onAttachmentClick}
            handleIgnoreList={handleIgnoreList}
            selectedRows={selectedRows.current}
            extraProps={{ filters: data.filters }}
          />
        );

      case "shadow-column":
        return (
          <ShadowColumnChart
            chartData={kpiState.data}
            customKPI={data.customKPI}
            chartMeta={kpiState.meta_data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "wordcloud":
        return (
          <WordCloudChart
            chartData={kpiState.data}
            customKPI={data.customKPI}
            chartMeta={kpiState.meta_data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
          />
        );
      case "waterfall":
        return (
          <WaterfallChart//done
            chartData={kpiState.data}
            customKPI={data.customKPI}
            chartMeta={kpiState.meta_data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "area-percentage":
        return (
          <AreaPercentageChart
            chartData={kpiState.data}
            customKPI={data.customKPI}
            chartMeta={kpiState.meta_data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "area-stacked":
        return (
          <AreaStackedChart
            chartData={kpiState.data}
            customKPI={data.customKPI}
            chartMeta={kpiState.meta_data}
            dateRange={data.filters?.daterange}
            kpiRendered={setIsKPIRendered}
            chartReference={setChartComponentReference}
            height={height}
            width={width}
            editorMode={editorMode}
          />
        );
      case "pivot":
        return (
          <GroupTable
            chartMeta={kpiState.meta_data}
            chartData={kpiState.data}
            isFullscreen={isFullscreen}
            setFullscreen={setIsFullscreen}
            kpiRendered={setIsKPIRendered}
            drillDownConfig={kpiState.drill_down_config}
            onDataPointClick={onDataPointClick}
          />
        );
      default:
        break;
    }
  };

  const onSingleDropdownChange = (dropdownValue: string) => {
    setSelectedOptions((prev: any) => {
      return {
        ...prev,
        "dropdown-menu-item": dropdownValue,
      };
    });

    let filteredData: any;
    if (availableChartData && Object.keys(availableChartData).length > 0) {
      filteredData = filterChartData(availableChartData?.data, {
        "dropdown-menu-item": dropdownValue,
      });
    } else {
      filteredData = filterChartData(kpiMasterData, {
        "dropdown-menu-item": dropdownValue,
      });
    }
    if (filteredData) {
      setKpiState((prev) => {
        return { ...prev, data: filteredData };
      });
    }
  };

  const onMultiDropdownChange = (key: string, value: string, index: number) => {
    setDropdownFilters(key, value, index);
  };

  const exportChartHandler = (type: string, extension: string) => {
    chartComponentReference?.current?.chart.exportChart({
      type: `${type}/${extension}`,
      filename: kpiData.name,
    });
  };

  const showFullScreenHandler = () => {
    var chart = chartComponentReference.current.chart;
    if (chart) {
      chart.fullscreen.toggle();
    }
  };

  const flushIgnoredAttachements = () => {
    ignoredAttachments.current = {};
    setIgnoreList({});
  }

  const printChartHandler = () => {
    var chart = chartComponentReference.current.chart;
    chart.print();
  };

  const exportToXLSXHelper = (slug: string) => {
    let tempFilters = { ...data.filters };
    tempFilters["download"] = "1";
    getKPIData(
      kpiData?.kpi_slug,
      tempFilters,
      false,
      false,
      "",
      board_slug
    ).then((res) => {
      if (res?.data?.data.length > 0) {
        var link = document.createElement("a");
        if (!res.data?.data[0]?.url) {
          dispatch(showMessage({ message: "No File Available" }));
          return;
        }
        link.href = res.data?.data[0]?.url;
        document.body.appendChild(link);
        link.click();
      } else {
        dispatch(showMessage({ message: "No File Available" }));
      }
    });
  };

  const errorBlockRender = () => {
    let errorMessage: any = DEFAULT_ERROR_MESSAGE;
    const connectorList = data.chartData.connector_list;
    let statusString = "";
    if (connectorList?.length) {
      if (connectorList.length === 1) {
        statusString = `Please ensure ${connectorList[0]} is connected.`;
      } else {
        if (Array.isArray(connectorList[0])) {
          statusString = `Please ensure ${connectorList
            .map((i) => (i.length === 1 ? i[0] : `(${i.join(" and ")})`))
            .join(" or ")} is connected.`;
        } else {
          statusString = `Please ensure ${connectorList.join(
            " and "
          )} are connected.`;
        }
      }
    }
    if (errorType === ErrorTypes.NO_BUDGET_DATA) {
      errorMessage = (
        <Grid
          px={8}
          mt={8}
          container
          direction="column"
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
          justifyItems={"center"}
        >
          <Grid item>
            <Typography>
              There exist no budgets. Either <strong>change filters </strong>
            </Typography>
          </Grid>
          <Grid item mt={3}>
            <Typography>OR</Typography>
          </Grid>
          <Grid item mt={3}>
            <Button variant="outlined" href="/budgets">
              Create Budget
            </Button>
          </Grid>
        </Grid>
      );
    } else if (errorType === ErrorTypes.KPI_DOESNT_EXIST) {
      errorMessage = "This chart is not configured correctly.";
    } else if (errorType === ErrorTypes.KPI_ACCESS_DENIED) {
      errorMessage =
        "You don’t have access to this KPI. Please reach out to admin to get access";
    } else if (errorType === ErrorTypes.NO_PROGRAM_USER_MAPPING) {
      errorMessage = (
        <>
          <div style={{ whiteSpace: "normal" }}>
            We couldn’t find any programs, please ensure it is properly mapped
            to a relevant attribute.
          </div>
          {!isPublic && (
            <Button
              sx={{ mt: 2 }}
              variant="outlined"
              href="/connectors/quickbooks/user-mapping"
            >
              Set Usermapping
            </Button>
          )}
        </>
      );
    } else if (errorType === ErrorTypes.CONNECTOR_NOT_LINKED) {
      errorMessage = (
        <>
          {!editorMode && <div>{statusString}</div>}
          {editorMode && <div>No data available.</div>}
          {!isPublic && (
            <Button variant="outlined" sx={{ mt: 2 }} href="/connectors/list">
              Go to Connectors
            </Button>
          )}
        </>
      );
    } else if (errorType === ErrorTypes.NO_DATA) {
      errorMessage = "No data found. Please try changing filter.";
    } else if (errorType === ErrorTypes.UM_RECURSION_ERROR) {
      errorMessage = "Seems like you have circular reference in user mapping!";
    } else if (errorType === ErrorTypes.NO_BUDGET) {
      errorMessage = (
        <Grid
          px={8}
          mt={8}
          container
          direction="column"
          alignContent={"center"}
          justifyContent={"center"}
          alignItems={"center"}
          justifyItems={"center"}
        >
          <Grid item>
            <Typography>
              There exists no <strong>active</strong> budget.
            </Typography>
          </Grid>
          <Grid item mt={3}>
            <Button variant="outlined" href="/budgets">
              Create Budget
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Typography
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "pre",
        }}
        variant="inter_p_500_14"
        sx={{
          color: theme.custom.dropdownPrimaryColor,
          marginBottom: 1,
          textAlign: "center",
        }}
      >
        {errorMessage}
      </Typography>
    );
  };

  const openDrawerComponent = useCallback(() => {
    setInfoDrawerOpen(true);
  }, []);

  const closeDrawerComponent = useCallback(() => {
    setInfoDrawerOpen(false);
  }, []);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };

  const kpiBorderGenerator = () => {
    if (data.customKPI) {
      return `${theme.custom.primaryGreyColor} !important`;
    } else if (
      belongToCustomBoard &&
      selectedKPICurrentFilters &&
      selectedKPICurrentFilters.kpi_slug &&
      selectedKPICurrentFilters.kpi_slug === kpiData.kpi_slug
    ) {
      return `${theme.palette.primary.main} !important`;
    } else {
      return `${theme.custom.KPIMoleculeBorderColor} !important`;
    }
  };

  const isCustomKPIFilterSelected = () => {
    return (
      !belongToCustomBoard ||
      (belongToCustomBoard &&
        selectedKPICurrentFilters &&
        selectedKPICurrentFilters.kpi_slug &&
        selectedKPICurrentFilters.kpi_slug === kpiData.kpi_slug)
    );
  };

  const returnCustomKPIClassName = () => {
    if (isCustomKPIFilterSelected()) {
      return "filter-selected";
    }
    return "";
  };

  const resetDropdownItems = (index: number) => {
    let toReset = dropdownIterables.slice(index + 1);
    toReset.forEach((ele: any) => {
      set(selectedOptions, [ele], "All");
    });
  };

  const setDropdownFilters = (key: string, value: string, index: number) => {
    resetDropdownItems(index);
    let _selectedOptions = { ...selectedOptions };
    _selectedOptions[key] = value;

    setSelectedOptions(_selectedOptions);

    let filteredData: any = {};
    let dd_items: any;
    if (Object.values(_selectedOptions)[0] === "All") {
      dd_items = extractDropdownItems(
        filterDataByConditions(kpiMasterData, [_selectedOptions[0]])
      );
    } else {
      dd_items = extractDropdownItems(
        filterDataByConditions(
          kpiMasterData,
          dropdownIterables[0] in _selectedOptions
            ? { [dropdownIterables[0]]: _selectedOptions[dropdownIterables[0]] }
            : {}
        )
      );
    }
    let dd_item_parent = {
      [dropdownIterables[0]]:
        extractDropdownItems(kpiMasterData)[dropdownIterables[0]],
    };

    //For making parents filter to have all the options
    setDropdownItems({ ...dd_items, ...dd_item_parent });

    filteredData = filterDataBasedOnFiltersSelected(
      kpiMasterData,
      _selectedOptions
    );

    setKpiState((prev) => {
      return {
        ...prev,
        data: filteredData,
      };
    });
  };

  const filterDataBasedOnFiltersSelected = (
    masterList: any,
    _selectedOptions: any
  ) => {
    let result: any;
    if (isObject(masterList)) {
      result = mapValues(masterList, (items: any[]) =>
        filter(items, (item) =>
          every(_selectedOptions, (value, key) => value === item[key])
        )
      );
    }
    if (isArray(masterList)) {
      result = filter(masterList, (item) =>
        every(_selectedOptions, (value, key) => value === item[key])
      );
    }

    return result;
  };

  const onDataPointClick = (obj: any) => {
    if (isEmpty(kpiState.drill_down_config)) {
      return;
    }

    setOpenDrilldownModal(true);
    setBudgetDrilldownPayload(obj);
  };

  const onUpdateRows = (
    rows: any,
    multiple: boolean = false,
    selectAll: boolean = false,
    clearInvoice: boolean = false,
    bulkUpdate: boolean = false
  ) => {
    let _rows: any;
    if (multiple) {
      _rows = selectAll ? rows["excludedRows"] : rows["includedRows"];
    } else {
      _rows = rows;
    }
    setIsKPIRendered(false);
    let arr: any = [];
    if (isArray(_rows)) {
      arr = [
        ...arr,
        ..._rows.map((ele: any) => {
          return invoiceDataFormatter(ele?.["original"], multiple, clearInvoice);
        }),
      ];
    } else if (isObject(_rows)) {
      arr = [...arr, invoiceDataFormatter(_rows, false, clearInvoice)];
    }
    let obj = {
      budget_uid: data?.filters?.["budget_uid"] || "",
      invoiced_date: clearInvoice ? null : monthSelectedForBulkUpdate.length
        ? moment(monthSelectedForBulkUpdate, "YYYY-MM")
            .startOf("month")
            .format("YYYY-MM-DD")
        : null,
      filters: {
        ...data.filters,
        select_all:
          (selectAll || multiple) && selectedRows.current.selectAll
            ? "yes"
            : "no",
        transactions: arr,
        bulk_update: bulkUpdate,
      },
    };

    updateInvoiceState(obj).then((res) => {
      if (res.status === 201) {
        getData(
          kpiData?.kpi_slug,
          { ...data.filters, page: "" + (kpiState?.pagination?.["current_page"] || 1)  },
          data.customKPI || false,
          data.isPublic || false,
          data.commKey,
          !editorMode ? board_slug : "impact-report"
        );
        setMonthSelectedForBulkUpdate("");
        onRowsSelection([], false)
      }
    });
  };

  const onRowsSelection = (rows: Array<any>, isAllRowsSelected: boolean) => {
    if (isAllRowsSelected === undefined) return;
    selectedRows.current.selectAll = isAllRowsSelected;
    selectedRows.current.includedRows = rows;
    selectedRows.current.excludedRows = [];
    clearInvoiceAction([], selectedRows.current.selectAll);
    setTotalRows(selectedRows.current.includedRows.length);
  };

  const onSingleRowSelection = (rowSelected: boolean, row: any) => {
    if (!selectedRows.current.selectAll) {
      if (rowSelected) {
        let _selectedRows = { ...selectedRows.current };
        _selectedRows.includedRows.push(row);
      } else {
        remove(
          selectedRows.current["includedRows"],
          function (currentObject: any) {
            return (
              currentObject?.original?.gl_ref_id?.value?.primary_value ===
              row.original?.gl_ref_id?.value?.primary_value
            );
          }
        );
      }
    } else {
      if (!rowSelected) {
        let _selectedRows = { ...selectedRows.current };
        _selectedRows.excludedRows.push(row);
      } else {
        remove(
          selectedRows.current["excludedRows"],
          function (currentObject: any) {
            return (
              currentObject?.original?.gl_ref_id?.value?.primary_value ===
              row.original?.gl_ref_id?.value?.primary_value
            );
          }
        );
      }
    }

    clearInvoiceAction(selectedRows.current?.includedRows, (selectedRows.current?.selectAll || false));
    setTotalRows(selectedRows.current?.includedRows?.length);
    if (!rowSelected && ignoredAttachments.current[row.original?.gl_ref_id?.value?.primary_value]) {
      delete ignoredAttachments.current[row.original?.gl_ref_id?.value?.primary_value]
    }
  };

  const onAttachmentClick = (attachmentId: string) => {
    previewAttachment(attachmentId).then((res) => {
      if (res.data && res.data?.url) {
        window.open(res.data?.url, "_blank");
      }
    });
  };
  const [ignoreList, setIgnoreList] = useState({});
  const handleIgnoreList = (checked: boolean, row_id: string, attachment_id: string) => {
    ignoredAttachments.current = {
      ...ignoredAttachments.current,
      [attachment_id]: checked
    }
    setIgnoreList(ignoredAttachments.current);
  };

  const getAttachmentIgnoreList = () => {
    let _ignoreList: any[] = [];
    for (let i in ignoredAttachments.current) {
      if (!ignoredAttachments.current[i]) {
        _ignoreList.push(i);
      }
    }
    return _ignoreList;
  }

  const invoiceDataFormatter = (obj: any, multiple: boolean = false, clearInvoice: boolean = false) => {

    let clearState = multiple
      ? monthSelectedForBulkUpdate.length === 0
        ? true
        : false
      : obj?.["newValue"] && obj["newValue"].length
        ? false
        : true

    return {
      transaction_id: obj?.["Transaction Id"]?.value?.primary_value || "",
      transaction_type: obj?.["Transaction Type"]?.value?.primary_value || "",
      invoiced_date: clearInvoice || clearState ? null : multiple
        ? monthSelectedForBulkUpdate.length
          ? moment(monthSelectedForBulkUpdate, "YYYY-MM")
              .startOf("month")
              .format("YYYY-MM-DD")
          : moment().startOf("month").format("YYYY-MM-DD")
        : obj?.["newValue"] && obj["newValue"].length
        ? moment(obj["newValue"], "YYYY-MM")
            .startOf("month")
            .format("YYYY-MM-DD")
        : moment().startOf("month").format("YYYY-MM-DD"),
      gl_ref_id: obj?.["gl_ref_id"]?.value?.primary_value || "",
    };
  };

  const updateMultipleRows = () => {
    if (selectedRows.current) {
      onUpdateRows(selectedRows.current, true, selectedRows.current.selectAll, false, true);
    }
  };

  const clearInvoiceAction = (
    rows: any,
    selectAll: boolean = false
  ) => {
    let hasInvoiceDate = selectAll

    rows.map((ele: any) => {
      if(ele?.["original"]?.["Invoiced Date"]?.value?.primary_value || null) {
        hasInvoiceDate = true
      }
    })

    setClearInvoice(hasInvoiceDate)

  };

  const clearMultipleRows = () => {
    if (selectedRows.current) {
      onUpdateRows(selectedRows.current, true, (selectedRows.current?.selectAll || false), true, true);
    }
  };

  const downloadSelectedAttachments = () => {
    setDownloading(true);
    let obj = {
      room_key: getCompanyId() + "-" + nanoid(7),
      filters: {
        ...data.filters,
        download_all: selectedRows.current.selectAll ? "yes" : "no",
        gl_ref_ids: selectedRows.current.selectAll
          ? selectedRows.current.excludedRows.map((ele: any) => {
              return ele.original?.["gl_ref_id"]?.["value"]?.["primary_value"];
            })
          : selectedRows.current.includedRows.map((ele: any) => {
              return ele.original?.["gl_ref_id"]?.["value"]?.["primary_value"];
            }),
      },
      attachments_ignore_list: getAttachmentIgnoreList()
    };
    let socket = io(environment.socketUrl || "");
    setSocket(socket);
    socket.emit("join", { room: obj["room_key"] });

    downloadAttachments(obj).then((res) => {
      if (res.status === 200) {
        console.log(res.data?.room_key || "");
      } else if (res.status === 400) {
        setDownloading(false);
        dispatch(showMessage({ message: "Download failed. Try again later." }));
      }
    });
  };

  const formatMinMaxValue = (date: string) => {
    if (date.length === 10) {
      return date.slice(0, 7);
    }
    return "";
  };

  return (
    <>
      <Container
        className={inPanorama ? "disable-padding" : ""}
        ref={containerRef}
        disableGutters={editorMode ? true : false}
        maxWidth={false}
        sx={{ height: "100%", px: 0 }}
      >
        {isKPIRendered && data.board_slug === "expense-proof-and-invoice-tracking" && (
          <Box
            sx={{
              color: theme.custom.primaryDarkColor,
            }}
          >
            <Stack mb={2} direction={"row"}>
              <Grid item>
                <Typography variant="inter_p_600_16">
                  {data.extraProps?.["urlKpiFilters"]?.["budget_name"] || ""}
                </Typography>
                <Typography my={1} variant="inter_500_12">
                  {data.extraProps?.["urlKpiFilters"]?.["program"]?.["label"] ||
                    ""}
                  &nbsp; - &nbsp;
                  {data.extraProps?.["urlKpiFilters"]?.["contract"]
                    .map((el: any) => el["label"])
                    .join(",") || ""}
                </Typography>
                <Typography variant="inter_500_12">
                  {dateFormatter(
                    data.extraProps?.["urlKpiFilters"]?.["daterange"]?.[
                      "start_date"
                    ],
                    "MMM, DD YYYY"
                  )}
                  &nbsp; to &nbsp;
                  {dateFormatter(
                    data.extraProps?.["urlKpiFilters"]?.["daterange"]?.[
                      "end_date"
                    ],
                    "MMM, DD YYYY"
                  )}
                </Typography>
              </Grid>
            </Stack>
          </Box>
        )}
        {totalRows > 0 && (
          <Box mb={3}>
            <Grid container justifyContent={"space-between"}>
              <Grid item>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div style={{ marginRight: "5px" }}>
                    <CustomMonthPicker
                      extraProps={{
                        style: {
                          height: "35px",
                        },
                      }}
                      onChange={(month: string) => {
                        setMonthSelectedForBulkUpdate(month);
                      }}
                      value={monthSelectedForBulkUpdate}
                      minValue={formatMinMaxValue(
                        data?.filters?.["daterange"]?.["start_date"] || ""
                      )}
                      maxValue={formatMinMaxValue(
                        data?.filters?.["daterange"]?.["end_date"] || ""
                      )}
                    ></CustomMonthPicker>
                  </div>
                  <div>
                    <Button
                      disabled={!monthSelectedForBulkUpdate.length}
                      variant="contained"
                      style={{ height: "35px" }}
                      onClick={updateMultipleRows}
                      size="small"
                    >
                      Mark selected Invoiced
                    </Button>
                  </div>
                  <div style={{ paddingLeft: "15px" }}>
                    <Button
                      disabled={!enableClearInvoice}
                      variant="contained"
                      style={{ height: "35px" }}
                      onClick={clearMultipleRows}
                      size="small"
                    >
                      Clear Invoice
                    </Button>
                  </div>
                </div>
              </Grid>
              <Grid item>
                <LoadingButton
                  loading={downloading}
                  onClick={() => downloadSelectedAttachments()}
                  variant={"contained"}
                  sx={{ height: "35px" }}
                  size={"small"}
                >
                  Download Selected
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        )}
        <Stack
          direction={"column"}
          id={`kpi-element-${kpiData.kpi_slug}`}
          className={`kpi_wrapper ${returnCustomKPIClassName()}`}
          sx={{
            height: "100%",
            border: 1,
            borderColor: kpiBorderGenerator(),
            borderRadius: "6px",
            backgroundColor: theme.custom.KPIMoleculeBackgroundColor,
          }}
        >
          {showTitle && <Box
            sx={{
              borderBottom: 1,
              borderColor: theme.custom.KPIMoleculeBorderColor,
            }}
          >
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
              px={2}
            >
              <Stack
                direction="row"
                alignItems={"center"}
                justifyItems={"start"}
                style={{
                  height: `${titleHeight}px`,
                  flexGrow: 1,
                  overflow: "hidden",
                  flexBasis: 0,
                  minWidth:60,
                }}
              >
               <Tooltip
                  title={getTitle()}
                >
                  <Typography
                    noWrap
                    sx={{
                      my: editorMode ? 1 : 3,
                      mr: 1,
                      color: theme.custom.primaryDarkColor,
                      maxWidth:'100%',          
                    }}
                    variant="poppins_h5_600_18"
                  >
                    {getTitle()}
                  </Typography>
                </Tooltip>
                {kpiData.snippet && kpiData.snippet.length > 0 && (
                  <div>
                    <img
                      width={`${(data?.gridMetaData?.column / 6 || 1) * 24}px`}
                      alt="img"
                      style={{
                        verticalAlign: "middle",
                        cursor: "pointer",
                        minHeight: "16px",
                        minWidth: "16px",
                        maxHeight: "24px",
                        maxWidth: "24px",
                      }}
                      className={`kpi_info_icon_${kpiState.slug}`}
                      src={InfoIcon}
                      ref={popoverAnchor}
                      onMouseEnter={popoverEnter}
                      onMouseLeave={popoverLeave}
                    />

                    <KPIInfoPopover
                      kpiTitle={
                        kpiData?.kpi_title || kpiState?.meta_data?.chart?.title
                      }
                      slug={kpiState.slug}
                      snippet={kpiData.snippet}
                      drawerOpen={openDrawerComponent}
                      id={`kpi_info_${kpiState.slug}`}
                      open={openedPopover}
                      anchorEl={popoverAnchor.current}
                      onMouseEnter={popoverEnter}
                      onMouseLeave={popoverLeave}
                    />
                  </div>
                )}
              </Stack>
              <Stack direction={"row"} alignItems="center" justifyItems={"end"} flexWrap={'wrap'}>
                {dropdownOpts &&
                Object.keys(dropdownOpts).length &&
                Object.keys(dropdownOpts).length === 1 ? (
                  <FormControl
                    size="small"
                    sx={{
                      my: editorMode ? 1 : 2,
                      minWidth: inPanorama ? 60 : 100,
                      maxWidth: inPanorama ? 120 : 140,
                    }}
                  >
                    <Select
                      sx={{ borderRadius: "6px" }}
                      onChange={(event) =>
                        onSingleDropdownChange(event.target.value)
                      }
                      value={selectedOptions["dropdown-menu-item"] || ""}
                      defaultValue={
                        last(dropdownOpts["dropdown-menu-item"])
                          ? last(dropdownOpts["dropdown-menu-item"])
                          : ""
                      }
                    >
                      {dropdownOpts &&
                        Object.keys(dropdownOpts).length &&
                        dropdownOpts["dropdown-menu-item"].map(
                          (options: any) => {
                            return (
                              <MenuItem key={options} value={options}>
                                <Typography
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "pre",
                                  }}
                                  variant="inter_p_500_14"
                                  sx={{
                                    color: theme.custom.dropdownPrimaryColor,
                                    marginTop: 0.5,
                                  }}
                                >
                                  {options}
                                </Typography>
                              </MenuItem>
                            );
                          }
                        )}
                    </Select>
                  </FormControl>
                ) : (
                  <></>
                )}
                {!editorMode && belongToCustomBoard && (
                  <Box
                    sx={{
                      display: isError ? "none" : "block",
                      cursor: "pointer",
                      ml: 2,
                    }}
                  >
                    <Button
                      size="small"
                      sx={{
                        ":hover": {
                          bgcolor: "primary.main", // theme.palette.primary.main
                          color: "white",
                        },
                        height: 60,
                        width: 40,
                        borderRadius: "50%",
                        background: isCustomKPIFilterSelected()
                          ? theme.palette.primary.main
                          : "#fff",
                        border: isCustomKPIFilterSelected()
                          ? `1px solid ${theme.palette.primary.main}`
                          : "none",
                      }}
                      onClick={() => {
                        dispatch(setSelectedKPI(kpiData.kpi_slug));
                        dispatch(setSelectedKPITitle(kpiData.kpi_title));
                      }}
                    >
                      <img
                        style={{
                          marginTop: 2,
                          filter: isCustomKPIFilterSelected()
                            ? "brightness(0) invert(1)"
                            : "brightness(1) invert(0)",
                        }}
                        height="20px"
                        width="20px"
                        src={FilterIcon}
                        alt="menu"
                      />
                    </Button>
                  </Box>
                )}
                {!editorMode &&
                  !data.customKPI &&
                  kpiData.is_position_applicable && (
                    <Box
                      className={"drag-handle"}
                      sx={{
                        cursor: "pointer",
                        ml: 2,
                        display: boardType === "grid" ? "block" : "none",
                      }}
                    >
                      <img
                        style={{
                          minHeight: "16px",
                          minWidth: "16px",
                          maxHeight: "24px",
                          maxWidth: "24px",
                        }}
                        height={`${
                          (data?.gridMetaData?.column / 6 || 1) * 24
                        }px`}
                        width={`${
                          (data?.gridMetaData?.column / 6 || 1) * 24
                        }px`}
                        src={MoveIcon}
                        alt="menu"
                      />
                    </Box>
                  )}
                {!editorMode && !belongToCustomBoard && !showSmartStatement && (
                  <Box
                    sx={{
                      display: isError ? "none" : "block",
                      cursor: "pointer",
                      ml: 2,
                    }}
                  >
                    {kpiState.data.length === 0 || disableSmartStatement ? (
                      <Tooltip
                        title={
                          kpiState && disableSmartStatement
                            ? "Smart statement is disabled due to data size. Please change filters to enable"
                            : "Please wait while data is getting loaded."
                        }
                      >
                        <img
                          height="24px"
                          width="24px"
                          src={MagicStick}
                          style={{
                            filter: "gray",
                            WebkitFilter: "grayscale(100%)",
                            opacity: 0.3,
                          }}
                          alt="smart-statement-disabled"
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip title={"Analyze KPI via Pebble AI"}>
                        <img
                          onClick={openSmartStatementPopover}
                          aria-controls={
                            open ? "kpi-open-smart-statement-btn" : undefined
                          }
                          height="24px"
                          width="24px"
                          src={MagicStick}
                          ref={smartStatementPopoverAnchor}
                          alt="smart-statement"
                        />
                      </Tooltip>
                    )}
                    <KPISmartStatementPopover
                      slug={kpiState.slug}
                      closePopup={closeSmartStatementPopover}
                      id={`kpi_smart-statement_${kpiState.slug}`}
                      open={openedSmartStatementPopover}
                      smartStatementText={smartStatementText}
                      loadingSmartStatementText={loadingSmartStatementText}
                      loadingSmartStatement={loadingSmartStatement}
                      anchorEl={smartStatementPopoverAnchor.current}
                    />
                  </Box>
                )}
                {!editorMode && (
                  <Box
                    sx={{
                      display: isError ? "none" : "block",
                      cursor: "pointer",
                      ml: 2,
                    }}
                  >
                    <img
                      onClick={handleClick}
                      aria-controls={open ? "kpi-menu-btn" : undefined}
                      height="24px"
                      width="24px"
                      src={TripleDot}
                      alt="menu"
                    />
                  </Box>
                )}
                {kpiState.type !== "table" &&
                  kpiState.type !== "number" &&
                  kpiState.type !== "single-number" && (
                    <Menu
                      anchorEl={anchorEl}
                      id="kpi-menu"
                      open={open}
                      onClose={handleClose}
                      onClick={handleClose}
                      PaperProps={{
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      }}
                      transformOrigin={{ horizontal: "right", vertical: "top" }}
                      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                    >
                      {belongToCustomBoard && (
                        <MenuItem
                          onClick={() => {
                            navigate(`/kpi-builder/${kpiState.slug}`);
                          }}
                        >
                          <Typography
                            variant="inter_500_12"
                            sx={{ color: theme.custom.primaryDarkColor }}
                          >
                            Edit
                          </Typography>
                        </MenuItem>
                      )}
                      <MenuItem onClick={() => showFullScreenHandler()}>
                        <Typography
                          variant="inter_500_12"
                          sx={{ color: theme.custom.primaryDarkColor }}
                        >
                          Full Screen
                        </Typography>
                      </MenuItem>
                      <MenuItem onClick={() => printChartHandler()}>
                        <Typography
                          variant="inter_500_12"
                          sx={{ color: theme.custom.primaryDarkColor }}
                        >
                          Print Chart
                        </Typography>
                      </MenuItem>

                      <MenuItem
                        onClick={() => exportChartHandler("image", "png")}
                      >
                        <Typography
                          variant="inter_500_12"
                          sx={{ color: theme.custom.primaryDarkColor }}
                        >
                          Download PNG
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => exportChartHandler("image", "jpeg")}
                      >
                        <Typography
                          variant="inter_500_12"
                          sx={{ color: theme.custom.primaryDarkColor }}
                        >
                          Download JPEG
                        </Typography>
                      </MenuItem>

                      <MenuItem
                        onClick={() => exportChartHandler("image", "svg+xml")}
                      >
                        <Typography
                          variant="inter_500_12"
                          sx={{ color: theme.custom.primaryDarkColor }}
                        >
                          Download SVG
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => exportChartHandler("application", "pdf")}
                      >
                        <Typography
                          variant="inter_500_12"
                          sx={{ color: theme.custom.primaryDarkColor }}
                        >
                          Download PDF
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => exportToXLSXHelper(kpiState.slug)}
                      >
                        <Typography
                          variant="inter_500_12"
                          sx={{ color: theme.custom.primaryDarkColor }}
                        >
                          Download XLSX
                        </Typography>
                      </MenuItem>
                    </Menu>
                  )}
                {kpiState.type === "table" && (
                  <Menu
                    anchorEl={anchorEl}
                    sx={{
                      zIndex: "9999999 !important",
                    }}
                    id="kpi-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem
                      onClick={() => {
                        handle.enter();
                        setIsFullscreen(true);
                      }}
                    >
                      <Typography
                        variant="inter_500_12"
                        sx={{ color: theme.custom.primaryDarkColor }}
                      >
                        Full Screen
                      </Typography>
                    </MenuItem>
                    {kpiState.slug !== "expense-proof-and-invoice-tracking" && (
                      <MenuItem
                        onClick={() => exportToXLSXHelper(kpiState.slug)}
                      >
                        <Typography
                          variant="inter_500_12"
                          sx={{ color: theme.custom.primaryDarkColor }}
                        >
                          Export to XLSX
                        </Typography>
                      </MenuItem>
                    )}
                  </Menu>
                )}
                {(kpiState.type === "number" ||
                  kpiState.type === "single-number") && (
                  <Menu
                    anchorEl={anchorEl}
                    id="kpi-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem onClick={() => exportToXLSXHelper(kpiState.slug)}>
                      <Typography
                        variant="inter_500_12"
                        sx={{ color: theme.custom.primaryDarkColor }}
                      >
                        Export to XLSX
                      </Typography>
                    </MenuItem>
                  </Menu>
                )}
              </Stack>
            </Grid>
          </Box>}
          {dropdownItems &&
            Object.keys(dropdownItems) &&
            Object.keys(dropdownItems).length > 1 && (
              <Box
                sx={{
                  padding: "10px",

                  color: "#fff",
                }}
              >
                {Object.keys(dropdownItems).map(
                  (ele: string, index: number) => {
                    return (
                      <Select
                        MenuProps={{
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        }}
                        sx={{
                          minWidth: "150px",
                          maxWidth: "150px",
                          borderRadius: "6px",
                          mr: 2,
                          height: "40px",
                        }}
                        onChange={(event) =>
                          onMultiDropdownChange(ele, event.target.value, index)
                        }
                        value={selectedOptions[ele] || ""}
                      >
                        {dropdownItems[ele].map(
                          (menuItems: string, index: number) => {
                            return (
                              <MenuItem key={menuItems} value={menuItems}>
                                <Typography
                                  style={{
                                    textOverflow: "ellipsis",
                                    overflow: "hidden",
                                    whiteSpace: "pre",
                                  }}
                                  variant="inter_p_500_14"
                                  sx={{
                                    color: theme.custom.dropdownPrimaryColor,
                                    marginTop: 0.5,
                                  }}
                                >
                                  {menuItems}
                                </Typography>
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    );
                  }
                )}
              </Box>
            )}
          <Box
            sx={{
              overflow:
                kpiState.type === "table" || kpiState.type === "pivot"
                  ? "auto"
                  : "none",
              height: "100%",
              padding:
                kpiState.type === "table" || kpiState.type === "pivot"
                  ? "0px"
                  : chartPadding + "px",
            }}
          >
            <Box
              sx={{
                height: "100%",
                display: isKPIRendered ? "block" : "none",
              }}
            >
              {pickChartsBasedOnType()}
              <DrillDownModal
                openModal={openDrilldownModal}
                onCloseModal={() => {
                  setOpenDrilldownModal(false);
                  setBudgetDrilldownPayload({});
                }}
                payload={budgetDrillDownPayload}
                slug={kpiState?.drill_down_config?.board_slug || ""}
                title={kpiData?.kpi_title || ""}
              />
            </Box>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              height={data.customKPI ? 400 : "100%"}
              sx={{
                display: isKPIRendered ? "none" : "block",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <Grid
                sx={{
                  textAlign: "center",
                  height: `calc(100% - ${titleHeight})`,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
                item
              >
                {isError && errorBlockRender()}
                {!isKPIRendered && !isError && <CircularProgress />}
              </Grid>
            </Grid>
            <Drawer
              sx={{ zIndex: 2000 }}
              PaperProps={{
                sx: { width: 500 },
              }}
              anchor={"right"}
              open={infoDrawerOpen}
              BackdropProps={{ invisible: false }}
            >
              <KPIDrawer
                kpiTitle={
                  kpiData?.kpi_title || kpiState?.meta_data?.chart?.title
                }
                slug={kpiData.kpi_slug}
                closeDrawer={closeDrawerComponent}
              />
            </Drawer>
          </Box>
        </Stack>
        <Stack spacing={2} py={1} alignItems="center">
          {kpiState.pagination &&
            !isEmpty(kpiState.pagination) &&
            kpiState.pagination["total_pages"] > 1 && (
              <TablePagination
                pagination={kpiState?.pagination || {}}
                onPaginationChange={onPaginationChange}
              />
            )}
        </Stack>
      </Container>
      {editorMode && !data.isElementOnMove && (
        <Popper
          id={popperId}
          open={showPopper}
          anchorEl={anchorSettingEl}
          style={{ zIndex: 1 }}
          placement="top-start"
        >
          <KPIBubbleMenu
            id={data.element.id}
            element={data.element}
            loadingAddSmartStatementElement={loadingAddSmartStatementElement}
            disableSmartStatement={disableSmartStatement}
            handleSmartStatementBulbClick={handleSmartStatementBulbClick}
            duplicateElement={data?.duplicateElement}
            deleteElement={data?.deleteElement}
          ></KPIBubbleMenu>
        </Popper>
      )}
    </>
  );
};

export default memo(KPIMolecule);
