import { Stack, Box, Tabs, Tab, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { Sidebar } from "../components/complexes/Sidebar";
import { theme } from "../theme";
import "swiper/swiper.scss";
import "./PanoramaPage.scss";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import CustomKPIComplex from "../components/complexes/CustomKPIComplex";
import MetricsTab from "../components/complexes/MetricsTab";
import { Link, useLocation } from "react-router-dom";

const MetricsPage = () => {
  const { pathname } = useLocation();
  const [value, setValue] = React.useState("/metrics/financial");

  useEffect(() => {
    if (pathname.includes("statements")) {
      document.title = "Statements - Pebble";
      return;
    } else if (pathname.includes("financial")) {
      document.title = "Financial Boards - Pebble";
      return;
    } else if (pathname.includes("donor")) {
      document.title = "Donors - Pebble";
      return;
    } else if (pathname.includes("custom-kpis ")) {
      document.title = "Custom KPI's - Pebble";
      return;
    }
    document.title = "Financial Boards - Pebble";
  }, []);
  const samePageLinkNavigation = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    return !(
      event.defaultPrevented ||
      event.button !== 0 || // ignore everything but left-click
      event.metaKey ||
      event.ctrlKey ||
      event.altKey ||
      event.shiftKey
    );
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (newValue.includes("statements")) {
      document.title = "Statements - Pebble";
    } else if (newValue.includes("financial")) {
      document.title = "Financial Boards - Pebble";
    } else if (newValue.includes("donor")) {
      document.title = "Donors - Pebble";
    } else if (newValue.includes("custom-kpis ")) {
      document.title = "Custom KPI's - Pebble";
    }

    // event.type can be equal to focus with selectionFollowsFocus.
    if (
      event.type !== "click" ||
      (event.type === "click" &&
        samePageLinkNavigation(
          event as React.MouseEvent<HTMLAnchorElement, MouseEvent>
        ))
    ) {
      setValue(newValue);
    }
  };

  return (
    <>
      <Stack
        sx={{ width: "calc(100% - 30px)" }}
        direction="row"
        className="panorama-page-wrapper"
      >
        <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
          <Sidebar />
        </Box>
        <Box sx={{ width: "100%", typography: "body1" }} pl={5} py={5}>
          <Typography sx={{ pl: "5px" }} variant="poppins_h6_600_16">
            Metrics
          </Typography>
          <TabContext value={pathname}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} aria-label="Metrics Page">
                <Tab
                  sx={{ paddingLeft: 0 }}
                  label="Financial"
                  component={Link}
                  to="/metrics/financial"
                  value="/metrics/financial"
                />
                <Tab
                  sx={{ paddingLeft: 0 }}
                  label="Statements"
                  component={Link}
                  to="/metrics/statements"
                  value="/metrics/statements"
                />
                <Tab
                  sx={{ paddingLeft: 0 }}
                  label="Donor"
                  component={Link}
                  to="/metrics/donor"
                  value="/metrics/donor"
                />
                <Tab
                  sx={{ paddingLeft: 0 }}
                  label="Custom KPIs"
                  component={Link}
                  to="/metrics/custom-kpis"
                  value="/metrics/custom-kpis"
                />
              </TabList>
            </Box>
            <TabPanel sx={{ paddingLeft: 0 }} value="/metrics/financial">
              <MetricsTab category={"financial"} />
            </TabPanel>
            <TabPanel sx={{ paddingLeft: 0 }} value="/metrics/statements">
              <MetricsTab category={"statements"} />
            </TabPanel>
            <TabPanel sx={{ paddingLeft: 0 }} value="/metrics/donor">
              <MetricsTab category={"donor"} />
            </TabPanel>
            <TabPanel sx={{ paddingLeft: 0 }} value="/metrics/custom-kpis">
              <CustomKPIComplex category={"custom_kpis"} />
            </TabPanel>
          </TabContext>
        </Box>
      </Stack>
    </>
  );
};

export default MetricsPage;
