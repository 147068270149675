import { NavigateFunction } from "react-router-dom";
import { Account, QBDataBase } from "../services/user-mapping-service";

const connectorSlugs = [
  "quickbooks",
  "donorperfect",
  "survey_monkey",
  "bloomerang",
  "googlesheet",
];

export const validateConnectorSlug = (
  navigate: NavigateFunction,
  connectorSlug?: string
) => {
  if (!connectorSlug || !connectorSlugs.includes(connectorSlug)) {
    navigate("/not-found");
  }
};

export const accountFilterLogic = (
  accounts: Account[],
  selectionKey: string
) => {
  let returned_accounts = [];
  if (selectionKey === "payroll_and_benefits") {
    returned_accounts = accounts.filter(
      (a) =>
        a.classification === "Expense" ||
        a.account_type === "Expense" ||
        a.classification === "Liability" ||
        a.account_type === "Liability"
    );
  } else if (selectionKey === "fund_raising_expenses") {
    returned_accounts = accounts.filter(
      (a) => a.classification === "Expense" || a.account_type === "Expense"
    );
  } else if (selectionKey === "gna_expense") {
    returned_accounts = accounts.filter(
      (a) => a.classification === "Expense" || a.account_type === "Expense"
    );
  } else if (selectionKey === "unbilled_ar_account") {
    returned_accounts = accounts.filter(
      (a) => a.classification === "Asset" || a.account_type === "Asset"
    );
  } else if (selectionKey === "deferred_revenue_account") {
    returned_accounts = accounts.filter(
      (a) => a.classification === "Liability" || a.account_type === "Liability"
    );
  } else if (selectionKey === "pledge_receivable") {
    returned_accounts = accounts.filter(
      (a) => a.classification === "Asset" || a.account_type === "Asset"
    );
  } else if (selectionKey === "netassets_unrestricted") {
    returned_accounts = accounts.filter((a) => a.classification === "Equity");
  } else {
    returned_accounts = accounts;
  }
  return returned_accounts;
};

export const isParentQBData = (qb_datas: QBDataBase[], qb_item: QBDataBase) => {
  return qb_datas.filter((i) => i.id === i.parent_account_id);
};
