import {
  Autocomplete,
  Box,
  styled,
  TextField,
  Grid,
  Stack,
  Typography,
  Skeleton,
  Checkbox,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { fontInter, theme } from "../../../theme";
import DateRangePickerCustomComponent from "../../atoms/DateRangePickerCustomComponent";
import { getFiltersByKPISlug } from "../../../services/impact-report-service";
import { dataExists } from "../../../utils/data-utils";
import CustomChip from "../../atoms/CustomChip";
import SingleDatePickerComponent from "../../atoms/SingleDatePickerComponent";
import "./KPIStyles.scss";
import moment from "moment";
import CustomSwitch from "../../atoms/CustomSwitch";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import {setAllFiltersInStore} from "../../../store/selected-design-element-store";
import { findLabelsForShowTitleFilters } from "../../../utils/CommonKPIResponseFormatterHelper";

const AutoCompleteComponent = styled(Autocomplete)({
  ".MuiAutocomplete-inputRoot": {
    color: theme.custom.dropdownPrimaryColor,
    fontWeight: 500,
    borderRadius: 8,
    fontSize: "14px",
    fontFamily: fontInter,
  },
});

function KPIEditorSettings(props: any) {
  const [allFilters, setAllFilters] = useState<any>({});
  const { updateElement, element: selectedElement } = props;
  const [filtersLoaded, setFiltersLoaded] = useState(false);

  const selectedElementsAttribute = selectedElement?.attributes;
  const selectedElementsFilters = selectedElementsAttribute?.filters;

  const [showTotal, setShowTotal] = useState(selectedElementsFilters?.total_column === "yes");
  const [showTitle, setShowTitle] = useState(selectedElementsFilters?.show_title !== "no");

  const dispatch = useAppDispatch();

  useEffect(() => {
    setFiltersLoaded(false);
    setAllFilters({});
    let slug = selectedElementsAttribute?.slug;
    if (slug) {
      getFiltersByKPISlug(slug).then((res) => {
        setFiltersLoaded(true);
        if (res?.data?.data) {
          setAllFilters(res?.data?.data);
          dispatch(setAllFiltersInStore(res?.data?.data));
        }
      });
    }
  }, [selectedElement.id, selectedElementsAttribute?.slug]);

  const onDateRangeChange = (dateRange: any, id: string) => {
    let nextElement = { ...selectedElement };
    let temp = { ...nextElement.attributes.filters };
    temp[id.length === 0 ? "daterange" : id] = dateRange;

    nextElement.attributes = {
      ...nextElement.attributes,
      filters: temp,
      show_title_filters_labels: findLabelsForShowTitleFilters( temp, allFilters),
    };

      if (
        dateRange.start !== "Invalid date" &&
        dateRange.end !== "Invalid date"
      ) {
        updateElement(selectedElement.id, {
          attributes: nextElement.attributes,
        });
      }
  };

  const toggleAttribute = useCallback(
    (show: boolean, id: string, attributeKey: string) => {
      let updatedFilters = { ...selectedElement?.attributes?.filters };
      let updatedAttributes = {...selectedElement?.attributes};
  
      switch (attributeKey) {
        case 'total_column':
          updatedFilters[attributeKey] = show ? "yes" : "no";
          break;
  
        case 'show_title':
          updatedFilters[attributeKey] = show ? "yes" : "no";
          if(show){
            updatedFilters['show_title_filters'] = []
          }else {
            delete updatedFilters['show_title_filters']
          }  
          updatedAttributes['show_title_filters_labels'] = '';
          break;
  
        default:
          console.warn(`Unknown attributeKey: ${attributeKey}`);
          return;
      }
  
      updateElement(selectedElement.id, {
        attributes: {
          ...updatedAttributes,
          filters: updatedFilters,
        },
      });
    },
    [selectedElement?.attributes?.filters, selectedElement?.id]
  );
  
  
  const showTotalToggle = (show: boolean) => {
    toggleAttribute(show, selectedElement.id, "total_column");
  };
  
  const showTitleToggle = (show: boolean) => {
    toggleAttribute(show, selectedElement.id, "show_title");
  };
  

  const getFilterOptions = (ele: any, options: any, searchTerm: string) => {
    const _options = options.filter((i: any) =>
      i.label?.toLowerCase().trim().includes(searchTerm)
    );
    if (allFilters[ele]?.["name"].toLowerCase().includes("program")) {
      return [
        {},
        ...(_options.filter((i: any) =>
          hideDeleted ? !i.label?.includes("(deleted)") : true
        ) || []),
      ];
    }

    return _options || [];
  };

  const [hideDeleted, setHideDeleted] = useState(true);

  const renderOption = (props: any, option: any, ele: any) => {
    if (
      allFilters[ele]?.["name"].toLowerCase().includes("program") &&
      props["data-option-index"] === 0
    ) {
      return (
        <Grid container justifyContent={"end"}>
          <Grid
            item
            component="li"
            {...props}
            onClick={() => {}}
            sx={{
              background: "none !important",
            }}
          >
            <Checkbox
              checked={hideDeleted}
              onClick={(e) => {
                setHideDeleted(!hideDeleted);
              }}
            />
            <Typography py={1} variant="inter_p_500_14">
              Hide deleted
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item component="li" {...props}>
        <Stack direction="column">
          <Typography py={1} variant="inter_p_500_14">
            {option?.label}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  const handleChangeFilters = (filter: any, opt: any, multiple: any) => {
    let nextElement = { ...selectedElement };
    if (multiple) {
      let temp = { ...nextElement.attributes.filters };
      if (temp.hasOwnProperty(filter)) {
        if (!dataExists(temp[filter], opt.option.value)) {
          let arr = [];
          arr = [...temp[filter]];
          arr.push(opt.option.value);
          temp[filter] = arr;
        }
      } else {
        temp[filter] = [opt.option.value];
      }

      nextElement.attributes = {
        ...nextElement.attributes,
        filters: temp,
        show_title_filters_labels: findLabelsForShowTitleFilters(temp, allFilters),
      };
      updateElement(selectedElement.id, { attributes: nextElement.attributes });
    } else {
      let temp = { ...nextElement.attributes.filters };
      temp[filter] = opt.option.value;

      nextElement.attributes = {
        ...nextElement.attributes,
        filters: temp,
        show_title_filters_labels: findLabelsForShowTitleFilters(temp, allFilters),
      };
    }
    updateElement(selectedElement.id, { attributes: nextElement.attributes });
  };

  const getSelectedFilters = (filterType: any, multiple: boolean) => {
    let filters = selectedElementsFilters;
    if (multiple) {
      return getFilterValue(filterType, filters[filterType], multiple);
    } else {
      return getFilterValue(filterType, filters[filterType], multiple)[0];
    }
  };

  const getFilterValue = (filter: string, value: any, multiple: boolean) => {
    let data = filter !== 'show_title_filters' ? allFilters?.[filter]?.data : showTitleOptionsData;
    if (multiple) {
      let tempArr: any = [];
      if (value) {
        for (let i = 0; i < value.length; i++) {
          for (let j = 0; j < data.length; j++) {
            if (value[i] === data[j]["value"]) {
              tempArr.push(data[j]);
            }
          }
        }
      }
      return tempArr;
    } else {
      if (Object.keys(data).length === 0) {
        return [];
      }
      if (data) {
        return data.filter((ele: any) => {
          return ele.value === value;
        });
      }
      return [];
    }
  };
  const removeFilterItem = (ele: any, opt: any) => {
    let nextElement = { ...selectedElement };
    let tempFilters = { ...selectedElement.attributes.filters };
      let temp = [...tempFilters[ele]];
      tempFilters[ele] = temp.filter((element: string) => element !== opt.value);
      nextElement.attributes = {
        ...nextElement.attributes,
        filters: tempFilters,
        show_title_filters_labels : findLabelsForShowTitleFilters(tempFilters, allFilters),

    };
    updateElement(selectedElement.id, { attributes: nextElement.attributes });
  };

  const removeLastElementFromFilters = (filter: string) => {
    let nextElement = { ...selectedElement };
    let tempFilters = { ...selectedElement.attributes.filters };
    let temp = [...tempFilters[filter]];
    if (temp && temp.length > 0) {
      temp.pop();
      tempFilters[filter] = temp;
      nextElement.attributes = {
        ...nextElement.attributes,
        filters: tempFilters,
      };
      updateElement(selectedElement.id, { attributes: nextElement.attributes });
    }
  };

  const showTitleOptionsData = (
    allFilters &&
    Object.keys(allFilters).length > 0
  ) 
    ? Object.keys(allFilters)
        .filter((selectedFilter) => selectedFilter !== 'total_column')
        .map((selectedFilter) => {
            return {
              value: selectedFilter,
              label: allFilters[selectedFilter].name,
            };
        })
    : [];
  
  const getOptions = (filter: any) => {
    if (allFilters[filter]?.name?.toLowerCase().includes("program")) {
      return [
        {},
        ...(allFilters[filter]?.data?.filter((i: any) =>
          hideDeleted ? !i.label.includes("(deleted)") : true
        ) || []),
      ];
    } else if(filter === 'show_title_filters'){
      return showTitleOptionsData;
    }  
    return allFilters[filter]?.data || [];
  };

  const removeAllFromFilters = (filter: string) => {
    let nextElement = { ...selectedElement };
    let tempFilters = { ...selectedElement.attributes.filters };
    [...tempFilters[filter]] = [];
    nextElement.attributes = {
      ...nextElement.attributes,
      filters: tempFilters,
      show_title_filters_labels : findLabelsForShowTitleFilters(tempFilters, allFilters)
    };
    updateElement(selectedElement.id, { attributes: nextElement.attributes });
  };

  const onSingleDateChange = (date: any, key: string) => {
    let nextElement = { ...selectedElement };

    let temp:any = { ...nextElement.attributes.filters };
    temp["single-date"] = {
      end: moment(date).format("YYYY-MM-DD")
    };

    nextElement.attributes = {
      ...nextElement.attributes,
      filters: temp,
      show_title_filters_labels : findLabelsForShowTitleFilters(temp, allFilters)
    };
    updateElement(selectedElement.id, { attributes: nextElement.attributes });
  };

  const dropDownRenderer =(filter:string) =>{
      return <Box mb={1.5}>
                <Grid container>
                  <Grid item sm={6} justifyContent={"start"}>
                    <Typography
                      sx={{ color: theme.custom.primaryDarkColor }}
                      variant="inter_400_14"
                    >
                      {iterableFilters[filter]["name"]}
                    </Typography>
                  </Grid>
                  {iterableFilters[filter]["multi_select"] && (
                    <Grid
                      item
                      sm={6}
                      sx={{ textDecoration: "underline", cursor: "pointer" }}
                      textAlign={"right"}
                    >
                      <Typography
                        sx={{ color: theme.custom.primaryDarkColor }}
                        variant="inter_500_10"
                        onClick={() => removeAllFromFilters(filter)}
                      >
                        Clear all
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Box mt={1}>
                  <AutoCompleteComponent
                    disableClearable
                    multiple={iterableFilters[filter]["multi_select"]}
                    limitTags={2}
                    size={"small"}
                    fullWidth
                    id={filter + "autocomplete"}
                    options={getOptions(filter)}
                    renderOption={(optProps, option) =>
                      renderOption(optProps, option, filter)
                    }
                    filterOptions={(options, state) =>
                      getFilterOptions(
                        filter,
                        options,
                        state.inputValue.toLowerCase().trim()
                      )
                    }
                    getOptionLabel={(option: any) => {
                      return option ? option.label : "";
                    }}
                    filterSelectedOptions={true}
                    onChange={(e: any, v: any, r: any, opt: any) =>
                      handleChangeFilters(
                        filter,
                        opt,
                        iterableFilters[filter]["multi_select"]
                      )
                    }
                    value={getSelectedFilters(
                      filter,
                      iterableFilters[filter]["multi_select"]
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        onKeyDown={(event: any) => {
                          if (event.key === "Backspace") {
                            if(event.target.value === '') removeLastElementFromFilters(filter);
                            event.stopPropagation();
                          }
                        }}
                        InputLabelProps={{
                          style: {
                            marginLeft: 7,
                          },
                        }}
                      />
                    )}
                    renderTags={(tagValue, getTagProps) => {
                      return tagValue.map((option: any, index) => (
                        <CustomChip
                          extraSX={{
                            background: theme.custom.whiteColor,
                            color: theme.custom.primaryDarkColor,
                            border: `1px solid ${theme.custom.primaryDarkColor}`,
                            marginRight: "2px",
                            marginBottom: "5px",
                            overflow: "hidden",
                          }}
                          extraProps={{
                            onDelete: () => {
                              removeFilterItem(filter, option);
                            },
                            labelText: option?.label,
                            size: "small",
                          }}
                          closeIconProps={{
                            fill: theme.CustomChip.closeIconColor,
                            fontSize: "18px !important",
                            marginRight: "12px !important",
                          }}
                        />
                      ));
                    }}
                  />
                </Box>
            </Box>
  }
  const iterableFilters = {...allFilters, 'show_title_filters': {name:'Add Title Details', showTitle: showTitle, default:'yes', multi_select : true, type: 'static_list'}}

  return (
    <Box>
      <div className="kpi-settings">
        {!filtersLoaded && allFilters && Object.keys(allFilters).length === 0 && (
          <Box mb={2}>
            <Skeleton
              sx={{ mb: 2, bgcolor: "grey.100" }}
              height={45}
              width={"100% "}
            />
            <Skeleton
              sx={{ mb: 2, bgcolor: "grey.100" }}
              height={45}
              width={"100% "}
            />
            <Skeleton
              sx={{ mb: 2, bgcolor: "grey.100" }}
              height={45}
              width={"100% "}
            />
            <Skeleton
              sx={{ mb: 2, bgcolor: "grey.100" }}
              height={45}
              width={"100% "}
            />
          </Box>
        )}
        {allFilters &&
          Object.keys(allFilters).length > 0 &&
          Object.keys(iterableFilters).map((filter: any) => {
            if (iterableFilters[filter].type === "daterange" && filter !== "single-date") {
              return (
                <>
                  <Typography
                    sx={{ color: theme.custom.primaryDarkColor }}
                    variant="inter_400_14"
                  >
                    {allFilters[filter]["name"]}
                  </Typography>
                  <Box
                    mb={1.5}
                    mt={1.5}
                    sx={{
                      border: `1px solid ${theme.custom.menuDividerColor}`,
                      borderRadius: "6px",
                    }}
                  >
                    <DateRangePickerCustomComponent
                      dateRangeValue={
                        selectedElementsFilters?.[filter]
                      }
                      onChange={onDateRangeChange}
                      isSmall={true}
                      id={filter}
                      updateOverlay={props?.updateOverlay}
                    />
                  </Box>
                </>
              );
            } else if (
              filter === "single-date" &&
              iterableFilters[filter].type === "daterange"
            ) {
              return (
                <>
                  <Typography
                    sx={{ color: theme.custom.primaryDarkColor }}
                    variant="inter_400_14"
                  >
                    {allFilters[filter]["name"]}
                  </Typography>
                  <Box
                    mb={1}
                    mt={1.5}
                    sx={{
                      border: `1px solid ${theme.custom.menuDividerColor}`,
                      borderRadius: "6px",
                      padding: "2px",
                    }}
                  >
                    <SingleDatePickerComponent
                      isSmall={true}
                      date={
                        selectedElementsFilters?.["single-date"]?.[
                          "end"
                        ] || ""
                      }
                      onDateChange={(date: any) => {
                        onSingleDateChange(date, filter);
                      }}
                      updateOverlay={props?.updateOverlay}
                    />
                  </Box>
                </>
              );
            } else if (
              filter === "total_column" &&
              iterableFilters[filter].type === "toggle"
            ) {
              return (
                <>
                  <Box mb={2}>
                    <Stack alignItems={"center"} direction={"row"}>
                      <Typography
                        sx={{ color: theme.custom.primaryDarkColor, mr: 2 }}
                        variant="inter_400_14"
                      >
                        Show Total
                      </Typography>
                      <CustomSwitch
                        active={
                          selectedElement?.attributes?.filters?.total_column ===
                          "yes"
                            ? true
                            : false
                        }
                        extraSX={{}}
                        backgroundColor={theme.palette.primary.main}
                        trackColor="grey"
                        key={"total_switch"}
                        onToggle={() => {
                          showTotalToggle(!showTotal);
                          setShowTotal(!showTotal);
                        }}
                      />
                    </Stack>
                  </Box>
                </>
              );
            } else if(filter === "show_title_filters"){
                return (<>
                <Box mb={2}>
                  <Stack alignItems={"center"} direction={"row"}>
                    <Typography
                      sx={{ color: theme.custom.primaryDarkColor, mr: 2 }}
                      variant="inter_400_14"
                    >
                      Show Title
                    </Typography>
                    <CustomSwitch
                      active={showTitle}
                      extraSX={{}}
                      backgroundColor={theme.palette.primary.main}
                      trackColor="grey"
                      key={"show_total"}
                      onToggle={() => {
                        showTitleToggle(!showTitle);
                        setShowTitle(!showTitle);
                      }}
                    />
                  </Stack>
                </Box>
                {showTitle && dropDownRenderer(filter)}
                </>)
            }
            return (
              dropDownRenderer(filter)
            );
          })}
      </div>
    </Box>
  );
}

export default React.memo(KPIEditorSettings);
