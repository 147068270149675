import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  ImageList,
  ImageListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import { uploadBrandMedia } from "../../../services/impact-report-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import { theme } from "../../../theme";

function BrandPopularMediaLibrary(props: any) {
  let mouseOrigin = useRef({ left: 0, top: 0 });
  let cursorPosition = useRef({ left: 0, top: 0 });
  const dispatch = useAppDispatch();
  const [mediaUploading, setMediaUploading] = useState(false);
  const [progress, setProgress] = useState(0);
  let pageRef = useRef({ popular: 1, search: 1, uploaded: 1 });

  const addElement = (
    e: any,
    element: any,
    type: string,
    dnd: boolean = true
  ) => {
    let _element = null;
    if (type === "library") {
      _element = {
        type: "ImageEditor",
        transparency: 1,
        zIndex: 0,
        posX: 100,
        posY: 100,
        rotate: 0,
        attributes: {
          url: element?.urls?.regular,
          type,
          title: element?.description || "Unnamed",
          credits: {
            firstName: element?.user?.first_name,
            lastName: element?.user?.last_name,
            userLink: element?.user?.links?.html,
          },
        },
      };
    } else {
      _element = {
        type: "ImageEditor",
        transparency: 1,
        zIndex: 0,
        posX: 100,
        posY: 100,
        rotate: 0,
        attributes: {
          url: element?.media,
          type,
          title: element?.alt,
        },
      };
    }

    props.addElement(
      _element,
      {
        left: cursorPosition.current.left - mouseOrigin.current.left,
        top: cursorPosition.current.top - mouseOrigin.current.top,
      },
      "drop",
      "",
      dnd
    );
    e.preventDefault();
  };

  const onDrag = (e: any) => {
    //TODO: On DragEnd page coordinates are coming wrong.
    // Monkey patch to force correct it. Heavy ops issue here.
    cursorPosition.current.top = e.pageY;
    cursorPosition.current.left = e.pageX;
  };

  const onDragStart = (e: any) => {
    let _bounds = e.currentTarget.getBoundingClientRect();
    mouseOrigin.current.top = e.clientY - _bounds.top;
    mouseOrigin.current.left = e.clientX - _bounds.left;
  };

  const onFileChange = (event: any) => {
    let file = event.target.files[0];
    function isImage(file: any) {
      return file["type"].split("/")[0] === "image"; //returns true or false
    }
    if (!file || !isImage(file)) {
      dispatch(showMessage({ message: "Please select a valid image file!" }));
      event.target.value = ''; // Reset input value
      return;
    }

    setMediaUploading(true);
    let fileExtension = file.name.split('.').pop().toUpperCase();
    if (!["JPEG", "PNG", "WEBP"].includes(fileExtension)) {
      fileExtension = "JPEG";
    }
    const resizeFile = (fileToResize: any) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          fileToResize,
          240,
          240,
          fileExtension,
          90,
          0,
          (uri: any) => {
            resolve(uri);
          },
          "file"
        );
      });
    resizeFile(file).then((thumbnail: any) => {
      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("media_type", "IMAGE");
      formData.append("media_sub_type", "UPLOAD");
      formData.append("thumbnail", thumbnail, file.name);
      uploadBrandMedia(formData, {
        onUploadProgress: (data: any) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      }).then((resp) => {
        if (resp && resp.status === 201) {
          // load fresh images from beginning
          pageRef.current.uploaded = 1;
          props.loadBrandImages();
          dispatch(
            showMessage({
              message: "Successfully uploaded image",
              variant: "success",
            })
          );
        } else {
          dispatch(
            showMessage({
              message: "Failed to upload, please ensure file size is < 10 MB",
              variant: "error",
            })
          );
        }
      }).finally(() => {
        setMediaUploading(false);
        event.target.value = ''; // Reset input value
      });
    });
  };

  return (
    <>
      <Grid item md={10}>
        <Grid container justifyContent={"space-between"} alignItems="center">
          <Grid item>
            <Typography
              variant="inter_p_600_16"
              sx={{ color: theme.custom.primaryDarkColor }}
            >
              Media
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item md={2}>
        <Button variant="text" onClick={() => props.seeAllEvent("Media")}>
          <Typography
            variant="inter_500_12"
            sx={{ color: theme.custom.primaryDarkColor }}
          >
            See all
          </Typography>
        </Button>
      </Grid>
      <Grid container py={2}>
        <LoadingButton
          color="primary"
          component="label"
          fullWidth
          variant="outlined"
          loading={mediaUploading}
          loadingIndicator={
            <Box display="flex" justifyContent="center" alignItems="center">
              <CircularProgress
                color="inherit"
                size={26}
                variant={progress === 100 ? "indeterminate" : "determinate"}
                value={progress}
              />
              <Typography position="absolute" variant="inter_500_10">
                {progress}
              </Typography>
            </Box>
          }
        >
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={(e) => onFileChange(e)}
          />
          Upload image
        </LoadingButton>
        <Box sx={{ textAlign: "center", width: "100%" }}>
                <Typography
                  variant="inter_400_10"
                  color="text.secondary"
            >
              Max file size 10MB
          </Typography>
        </Box>
      </Grid>
      <Grid item md={12}>
        <ImageList variant="standard" cols={2} gap={12}>
          {props.brandImageList.map((item: any) => (
            <Tooltip
              key={item.id}
              title={item.alt}
              placement="top"
              PopperProps={{
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -28],
                    },
                  },
                ],
              }}
            >
              <ImageListItem
                sx={{
                  padding: 1,
                  border: `1px solid ${theme.custom.menuDividerColor}`,
                  borderRadius: "6px", 
                  cursor: "pointer",
                }}
              >
                <img
                  draggable={true}
                  onDrag={onDrag}
                  onDragStart={onDragStart}
                  onDragEnd={(e) => addElement(e, item, "uploaded")}
                  onClick={(e) => addElement(e, item, "uploaded", false)}
                  src={item.thumbnail}
                  alt={item.alt}
                  loading="lazy"
                  style={{
                    objectFit: "contain",
                  }}
                />
              </ImageListItem>
            </Tooltip>
          ))}
        </ImageList>
      </Grid>
    </>
  );
}

export default React.memo(BrandPopularMediaLibrary);
