import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { theme, fontInter } from "../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SimpleSidebar } from "../components/atoms/SimpleSidebar";
import { resetPassword } from "../services/forgot-password-service";
import LoadingButton from "@mui/lab/LoadingButton";
import { showMessage } from "../store/error-handler-store";
import { useAppDispatch } from "../store/hook";

const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useAppDispatch();

  const INIT_STATE = {
    newpassword: "",
    confirmpassword: "",
    passwordkey: params.passwordkey ? params.passwordkey : "",
  };

  const ERR_INIT_STATE = {
    newPasswordErrorMsg: "",
    confirmPasswordErrorMsg: "",
    otherErrMsg: "",
  };
  const [resetPasswordState, setResetPasswordState] = useState(INIT_STATE);
  const [errObj, setErrObj] = useState(ERR_INIT_STATE);
  const [loading, setLoading] = useState(false);

  const navigateToLogin = () => {
    navigate("/login");
  };

  const onChange = (event: any, key: string) => {
    setResetPasswordState((prev) => {
      return { ...prev, [key]: event.target.value };
    });
  };

  const onResetPassword = () => {
    if (resetPasswordState.newpassword.length < 8) {
      setErrObj((prev) => {
        return {
          ...prev,
          newPasswordErrorMsg: "Atleast 8 characters are required",
        };
      });
    } else {
      setErrObj((prev) => {
        return {
          ...prev,
          newPasswordErrorMsg: "",
        };
      });
    }

    if (resetPasswordState.confirmpassword.length < 8) {
      setErrObj((prev) => {
        return {
          ...prev,
          confirmPasswordErrorMsg: "Atleast 8 characters are required",
        };
      });
    } else {
      setErrObj((prev) => {
        return {
          ...prev,
          confirmPasswordErrorMsg: "",
        };
      });
    }
    if (resetPasswordState.newpassword !== resetPasswordState.confirmpassword) {
      setErrObj((prev) => {
        return {
          ...prev,
          otherErrMsg: "Both Passwords should be same",
        };
      });
      return;
    }
    if (
      resetPasswordState.newpassword.length >= 8 &&
      resetPasswordState.confirmpassword.length >= 8
    ) {
      setLoading(true);
      resetPassword(resetPasswordState).then((res: any) => {
        setLoading(false);
        if (res.status === 200) {
          navigateToLogin();
        }
      });
    }
  };

  return (
    <React.Fragment>
      <Stack direction="row">
        <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
          <SimpleSidebar />
        </Box>

        <Box
          onClick={navigateToLogin}
          sx={{
            position: "absolute",
            top: "5%",
            left: "10%",
            cursor: "pointer",
          }}
        >
          <ArrowBackIcon sx={{ color: theme.custom.primaryDarkColor }} />
        </Box>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          <Box
            p={5}
            sx={{
              width: 500,
              border: `1px solid ${theme.custom.primaryGreyColor}`,
              borderRadius: "6px",
            }}
          >
            <form onSubmit={(e) => e.preventDefault()}>
              <Grid container>
                <Grid mb={2} textAlign={"left"} item xs>
                  <Typography
                    sx={{ color: theme.custom.primaryDarkColor }}
                    variant="poppins_h2_700_30"
                  >
                    Reset Password
                  </Typography>
                </Grid>
                <Grid mb={4} item xs={12}>
                  <Typography
                    sx={{ color: theme.custom.primaryGreyColor }}
                    variant="inter_p_400_16"
                  >
                    Please enter your new password. New password must be at
                    least 8 characters!
                  </Typography>
                </Grid>
                <Grid mb={3} item xs={12}>
                  <TextField
                    onChange={(e) => onChange(e, "newpassword")}
                    fullWidth
                    type={"password"}
                    id="username"
                    placeholder={"Enter new password"}
                    variant="outlined"
                    label="Enter new Password"
                    inputProps={{
                      style: {
                        fontFamily: fontInter,
                        fontSize: "14px",
                        color: theme.custom.placeholderColor,
                      },
                    }}
                  />
                  {errObj.newPasswordErrorMsg.length > 0 && (
                    <Typography
                      sx={{ marginTop: 1, color: theme.custom.errorColor }}
                      variant="inter_p_500_14"
                    >
                      {errObj.newPasswordErrorMsg}
                    </Typography>
                  )}
                </Grid>
                <Grid mb={3} item xs={12}>
                  <TextField
                    onChange={(e) => onChange(e, "confirmpassword")}
                    fullWidth
                    type={"password"}
                    id="username"
                    placeholder={"Confirm new password"}
                    variant="outlined"
                    label="Confirm new Password"
                    inputProps={{
                      style: {
                        fontFamily: fontInter,
                        fontSize: "14px",
                        color: theme.custom.placeholderColor,
                      },
                    }}
                  />
                  {errObj.confirmPasswordErrorMsg.length > 0 && (
                    <Typography
                      sx={{ marginTop: 1, color: theme.custom.errorColor }}
                      variant="inter_p_500_14"
                    >
                      {errObj.confirmPasswordErrorMsg}
                    </Typography>
                  )}
                </Grid>
                <Grid item xs={12} mb={2}>
                  <LoadingButton
                    fullWidth
                    sx={{ height: "50px" }}
                    onClick={onResetPassword}
                    variant="contained"
                    loading={loading}
                    type="submit"
                  >
                    <Typography variant="poppins_p_600_14">
                      Change password & Sign In
                    </Typography>
                  </LoadingButton>
                </Grid>
                {errObj.otherErrMsg.length > 0 && (
                  <Grid item xs={12}>
                    <Typography
                      sx={{
                        textAlign: "center",
                        color: theme.custom.errorColor,
                      }}
                    >
                      {errObj.otherErrMsg}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </form>
          </Box>
        </Grid>
      </Stack>
    </React.Fragment>
  );
};

export default ResetPasswordPage;
