import { Grid, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import {
  ActionItem,
  OtherPermissionsMetaData,
  PermissionsDetail,
} from "../../../Types/UserManagement";
import { theme } from "../../../theme";
import CustomizedCheckbox from "../../atoms/CustomizedCheckbox";

type Props = {
  metaItem: OtherPermissionsMetaData;
  setCheckboxSelected: CallableFunction;
  memberDetailsForItem: PermissionsDetail | undefined;
};

function MemberOtherPermissionCheckboxItem({
  metaItem,
  setCheckboxSelected,
  memberDetailsForItem,
}: Props) {
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    if (memberDetailsForItem === undefined) {
      handleOnChange(!!metaItem.default.length);
    } else setIsSelected(memberDetailsForItem.has_full_access);
  }, [memberDetailsForItem]);

  const handleOnChange = (checked: boolean) => {
    let _actions: { [x: string]: ActionItem } = {};
    _actions[metaItem.actions[0].slug] = { permitted: checked };
    setCheckboxSelected(metaItem, {
      has_full_access: checked,
      actions: _actions,
    });
  };

  return (
    <Grid container alignItems={"center"} justifyContent={"space-between"}>
      <Grid item>
        <CustomizedCheckbox
          isChecked={isSelected}
          onChange={(value: boolean) => {
            handleOnChange(value);
          }}
          labelProps={{
            title: metaItem.permission_name,
            extraSx: {
              variant: "inter_p_600_16",
              color: theme.custom.primaryDarkColor,
            },
          }}
        />
      </Grid>
      <Grid>
        <Typography>{metaItem.actions[0].label}</Typography>
      </Grid>
    </Grid>
  );
}

export default memo(MemberOtherPermissionCheckboxItem);
