import { Button, Grid } from "@mui/material";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserListObject, UserObject } from "../../Types/UserObject";
import {
  deleteUser,
  getAllUsers,
} from "../../services/user-management-service";
import { showMessage } from "../../store/error-handler-store";
import { useAppDispatch } from "../../store/hook";
import UsersListTable from "../molecules/UsersListTable";
import AddEditMemberModal from "./UserManagement/AddEditMemberModal";
const ManageUsers = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [showProgress, setShowProgress] = useState<boolean>(false);
  const [users, setUsers] = useState<UserListObject[]>([]);
  const [memberUUID, setMemberUUID] = useState();

  useEffect(() => {
    getUsers();
  }, []);

  const getUsers = () => {
    setShowProgress(true);
    getAllUsers().then((res) => {
      if (res.status === 403) {
        dispatch(showMessage({ message: res?.data?.message }));
        navigate("/");
        return;
      }
      if (res.data) {
        setUsers(res?.data);
        setShowProgress(false);
      }
    });
  };

  const addUserCallback = useCallback(() => {
    getUsers();
  }, [users]);

  let removeUser = useCallback(
    (uuid: string) => {
      setShowProgress(true);
      deleteUser(uuid).then((res) => {
        if (res.status === 204) {
          setUsers(
            users.filter((ele) => {
              return ele.uuid !== uuid;
            })
          );
          setShowProgress(false);
          dispatch(
            showMessage({
              message: "User Deleted Successfully",
              variant: "success",
            })
          );
        }
      });
    },
    [users]
  );
  return (
    <Fragment>
      <Grid container>
        <Grid mb={2} textAlign={"right"} xs={12} item>
          <Button
            sx={{ borderRadius: "6px", height: "40px" }}
            onClick={() => setOpenPopup(true)}
            variant="contained"
          >
            Add User
          </Button>
        </Grid>
        <Grid xs={12} item>
          <UsersListTable
            showProgress={showProgress}
            deleteUserCallback={removeUser}
            usersList={users}
            setOpenPopup={setOpenPopup}
            setMemberUUID={setMemberUUID}
          />
        </Grid>
      </Grid>
      <AddEditMemberModal
        showPopup={openPopup}
        setShowPopup={setOpenPopup}
        addUserCallback={addUserCallback}
        memberUUID={memberUUID}
        setMemberUUID={setMemberUUID}
      />
    </Fragment>
  );
};

export default React.memo(ManageUsers);
