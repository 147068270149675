import {
  Stack,
  Box,
  Grid,
  Typography,
  Button,
  Skeleton,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState, useCallback, useRef } from "react";
import BudgetFilters from "../components/complexes/BudgetFilters";
import BudgetList from "../components/complexes/BudgetList";
import { Sidebar } from "../components/complexes/Sidebar";
import { fetchDataCarts, listAllBudgets } from "../services/budget-service";
import AddBudgetModalComponent from "../components/molecules/AddBudgetModalComponent";
import { theme } from "../theme";
import { BudgetListItem } from "../Types/BudgetListItem";
import { ProgramFilterItem } from "../Types/ProgramFilterItem";
import { useLocation } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
const BudgetListPage = () => {
  const [budgets, setBudgets] = useState<BudgetListItem[]>([]);
  const [budgetsFetched, setBudgetsFetched] = useState<boolean>(false);
  const [programEmpty, setProgramEmpty] = useState(false);
  const [connectorNotLinked, setConnectorNotLinked] = useState(false);
  const [budgetFiltersList, setBudgetFiltersList] = useState<
    ProgramFilterItem[]
  >([]);

  const [authorized, setAuthorized] = useState(true);
  const [creatable, setCreatable] = useState(false);
  const [hasProgramAccess, setHasProgramAccess] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState({} as any);

  const [isExpenseProofAllowed, setIsExpenseProofAllowed] =
    useState<boolean>(false);
  useEffect(() => {
    getAllBudgets();
    getDataCarts();
    document.title = "Budget Management - Pebble";
  }, []);

  const [budgetsListHasMore, setBudgetsListHasMore] = useState(true);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  let pageRef = useRef({ page: 1 });

  const getAllBudgets = (filters = {}, page: number = 1) => {
    setBudgetsFetched(false);
    let filterObj = filterDataFormatter(filters);
    listAllBudgets(filterObj, page).then((res) => {
      if (res.status === 403) {
        setProgramEmpty(true);
        setBudgetsFetched(true);
        setAuthorized(false);
        return;
      }
      setCreatable(res.data?.is_creatable || false);
      setHasProgramAccess(res.data?.has_program_access || false);
      setIsExpenseProofAllowed(res.data?.is_expense_proof_allowed || false);
      if (res?.data?.results.length > 0) {
        setBudgetsFetched(true);
        setBudgets(res.data.results);
        if (page === 1) {
          setBudgets([...res?.data?.results]);
        } else {
          setBudgets([...budgets, ...res.data.results]);
        }
        if (res.data.next) {
          pageRef.current.page = page + 1;
          setBudgetsListHasMore(true);
          return;
        }
        setBudgetsListHasMore(false);
      } else {
        setBudgetsFetched(true);
        setBudgetsListHasMore(false);
      }
      if (res?.data?.results.length === 0) {
        setBudgetsFetched(true);
      }
    });
  };

  const onFilterChange = useCallback((filters: any) => {
    setBudgets([]);
    setSelectedFilters(filters);
    getAllBudgets(filters, 1);
  }, []);

  const getDataCarts = () => {
    fetchDataCarts().then((res) => {
      if (res.status === 406) {
        setConnectorNotLinked(true);
      } else if (!res.data?.data?.program?.length) {
        setProgramEmpty(true);
      } else if (res?.data?.data) {
        setBudgetFiltersList(res?.data?.data?.program);
      }
    });
  };

  const filterDataFormatter = (filterState: any) => {
    let jsonObj: any = {};
    Object.keys(filterState).forEach((filter: any) => {
      if (!filterState[filter]) {
        return;
      }

      if (filter === "ref_program_id" || filter === "ref_contract_id") {
        jsonObj[filter] = filterState[filter]["id"];
      } else if (filter === "active") {
        if (filterState[filter] === "true") {
          jsonObj[filter] = 1;
        } else {
          jsonObj[filter] = 0;
        }
      } else {
        jsonObj[filter] = filterState[filter];
      }
    });

    return jsonObj;
  };

  const location = useLocation();

  useEffect(() => {
    if (location.hash !== "" && budgetsFetched) {
      let budgetId = location.hash.slice(1);
      setTimeout(() => {
        let ele = document.getElementById(budgetId);
        if (ele) {
          window.scrollTo({
            behavior: "smooth",
            top:
              ele.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              100,
          });
        }
      }, 1000);
    }
  }, [location, budgetsFetched]);

  const loadMore = () => {
    getAllBudgets(selectedFilters, pageRef.current.page);
  };
  return (
    <React.Fragment>
      <Stack direction="row">
        <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
          <Sidebar />
        </Box>
        <Box mb={4} className="scrollableContainer" sx={{ width: "100%" }}>
          <InfiniteScroll
            dataLength={budgets.length}
            next={loadMore}
            hasMore={budgetsListHasMore}
            loader={
              <Typography
                variant="inter_500_12"
                style={{ color: "grey", textAlign: "center", marginTop: 10 }}
              >
                Loading . . .
              </Typography>
            }
            scrollableTarget="scrollableContainer"
            endMessage={
              <Typography
                variant="inter_500_12"
                style={{ color: "grey", textAlign: "center" }}
              >
                {budgetsFetched && budgets.length
                  ? "No more budgets to load"
                  : " "}
              </Typography>
            }
          >
            <Grid container>
              <Grid item xs={12}>
                <Box mt={3} px={8}>
                  <Typography variant="poppins_h4_600_20">
                    Budget Management
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box mt={4} px={8}>
                  <BudgetFilters
                    filtersChange={onFilterChange}
                    programFilters={budgetFiltersList}
                  />
                </Box>
              </Grid>

              <Grid mt={5} px={8} container>
                <Grid item xs={10}>
                  <Box>
                    <Typography variant="poppins_h4_600_20">Budget</Typography>
                  </Box>
                </Grid>
                {(budgets.length !== 0 || !hasProgramAccess) &&
                  !connectorNotLinked && (
                    <Grid textAlign="right" item xs={2}>
                      <Box>
                        <Tooltip
                          placement="top"
                          title={
                            !creatable || !hasProgramAccess
                              ? "You don't have permission for this action"
                              : "Add Budget"
                          }
                        >
                          <span>
                            <Button
                              disabled={!creatable || !hasProgramAccess}
                              sx={{ height: "50px" }}
                              onClick={() => setOpenPopup(true)}
                              variant="contained"
                              className="budgets_created_add_new"
                            >
                              Add Budget
                            </Button>
                          </span>
                        </Tooltip>
                      </Box>
                    </Grid>
                  )}
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  {!budgetsFetched && (
                    <Box px={6}>
                      <Grid rowGap={2} px={2} mt={4} container>
                        <Grid item sm={12}>
                          <Skeleton
                            sx={{ bgcolor: "grey.200", borderRadius: 2 }}
                            variant="rectangular"
                            width="100%"
                            height={100}
                          />
                        </Grid>
                        <Grid item sm={12}>
                          <Skeleton
                            sx={{ bgcolor: "grey.200", borderRadius: 2 }}
                            variant="rectangular"
                            width="100%"
                            height={100}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {!connectorNotLinked &&
                    programEmpty &&
                    budgetsFetched &&
                    !authorized && (
                      <Box py={3} px={6}>
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            background: theme.custom.bgImageColor,
                            minHeight: "250px",
                            borderRadius: 3,
                          }}
                        >
                          <Grid item>
                            <Typography variant="inter_p_500_14">
                              You don’t have access to view budget. Please reach
                              out to admin to get access
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  {connectorNotLinked && authorized && (
                    <Box py={3} px={6}>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          background: theme.custom.bgImageColor,
                          minHeight: "250px",
                          borderRadius: 3,
                        }}
                      >
                        <Grid item>
                          <Typography variant="inter_p_500_14">
                            Please ensure Quickbooks is connected and synced.
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            sx={{ mt: 2 }}
                            variant="contained"
                            href="/connectors/list"
                            className="budget_qbo_not_connected"
                          >
                            Go to Connectors
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                  {programEmpty && authorized && hasProgramAccess && (
                    <Box py={3} px={6}>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          background: theme.custom.bgImageColor,
                          minHeight: "250px",
                          borderRadius: 3,
                        }}
                      >
                        <Grid item>
                          <Typography variant="inter_p_500_14">
                            We couldn’t find any programs, please ensure it is
                            properly mapped to a relevant attribute.
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Button
                            sx={{ mt: 2 }}
                            variant="contained"
                            href="/connectors/quickbooks/user-mapping"
                          >
                            Go to User mapping
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  )}

                  {budgets.length === 0 &&
                    budgetsFetched &&
                    !programEmpty &&
                    !connectorNotLinked &&
                    hasProgramAccess &&
                    creatable && (
                      <Box py={3} px={6}>
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            background: theme.custom.bgImageColor,
                            minHeight: "250px",
                            borderRadius: 3,
                          }}
                        >
                          <Grid item>
                            <Button
                              disabled={!hasProgramAccess}
                              onClick={() => setOpenPopup(true)}
                              variant="outlined"
                              className="budget_qbo_connected_add_new"
                            >
                              Add New Budget
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  {budgets.length === 0 &&
                    budgetsFetched &&
                    programEmpty &&
                    !connectorNotLinked &&
                    !hasProgramAccess &&
                    !creatable && (
                      <Box py={3} px={6}>
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            background: theme.custom.bgImageColor,
                            minHeight: "250px",
                            borderRadius: 3,
                          }}
                        >
                          <Grid item>
                            <Typography variant="inter_p_500_14">
                              You don't have access to create a budget. Please
                              reach out to admin to get access
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  {budgets.length === 0 &&
                    budgetsFetched &&
                    !connectorNotLinked &&
                    hasProgramAccess &&
                    !creatable && (
                      <Box py={3} px={6}>
                        <Grid
                          container
                          spacing={0}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          sx={{
                            background: theme.custom.bgImageColor,
                            minHeight: "250px",
                            borderRadius: 3,
                          }}
                        >
                          <Grid item>
                            <Typography variant="inter_p_500_14">
                              You don't have access to create a budget. Please
                              reach out to admin to get access
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  {!connectorNotLinked && budgets.length > 0 && !programEmpty && (
                    <Box sx={{ position: "relative" }} px={6} mb={5}>
                      <BudgetList
                        budgets={budgets}
                        isExpenseProofAllowed={isExpenseProofAllowed}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </InfiniteScroll>
        </Box>

        <AddBudgetModalComponent
          program_name={budgetFiltersList}
          showPopup={openPopup}
          setShowPopup={setOpenPopup}
        />
      </Stack>
    </React.Fragment>
  );
};

export default React.memo(BudgetListPage);
