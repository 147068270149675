import {
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
  Typography,
  ListItemButton,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { NavigationMenuItem } from "../molecules/NavigationMenuItem";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { theme } from "../../theme";
import { StyledList } from "../../utils/StyledList";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export const MultiLevelMenuItem = ({
  item,
  menuClicked,
  onHoverMenuItem,
}: any) => {
  const [mouseOverState, setMouseOverState] = useState<boolean>(false);
  const { sub_menu: children } = item;
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const mouseOverEvent = () => {
    setMouseOverState(true);
  };

  const mouseOutEvent = () => {
    setMouseOverState(false);
  };

  return (
    <React.Fragment>
      <StyledList>
        <ListItemButton
          onMouseOver={mouseOverEvent}
          onMouseOut={mouseOutEvent}
          sx={{ maxHeight: "45px", borderRadius: "6px" }}
          selected={open ? true : false}
          onClick={handleClick}
        >
          <ListItemIcon onMouseOver={onHoverMenuItem}>
            <img
              src={require(`../../assets/${item.icon_handle}.png`).default}
              alt="ICON"
              className={mouseOverState || open ? "" : "grey-img"}
              style={{ height: "auto", maxWidth: "42px" }}
            />
          </ListItemIcon>
          <ListItemText sx={{ marginLeft: 2, marginRight: "60px" }}>
            <Typography
              variant="inter_400_14"
              color={
                mouseOverState || open
                  ? theme.palette.primary.main
                  : theme.custom.primaryGreyColor
              }
            >
              {item.title}
            </Typography>
          </ListItemText>
          {open ? (
            <ExpandMore
              sx={{
                color:
                  mouseOverState || open
                    ? theme.palette.primary.main
                    : theme.custom.filterBorderColor,
                fontSize: "30px",
              }}
            />
          ) : (
            <ArrowForwardIosIcon
              sx={{
                color:
                  mouseOverState || open
                    ? theme.palette.primary.main
                    : theme.custom.filterBorderColor,
                fontSize: "20px",
              }}
            />
          )}
        </ListItemButton>
      </StyledList>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children.map((child: any, key: any) => (
            <NavigationMenuItem
              onHoverMenuItem={onHoverMenuItem}
              menuClicked={menuClicked}
              key={key}
              item={child}
            />
          ))}
        </List>
      </Collapse>
    </React.Fragment>
  );
};
