import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import { ListConnectorAPIResponseModel } from "../Types/ListConnectorAPIResponseModel";

interface ConnectorStoreModel {
  connectors: ListConnectorAPIResponseModel[];
  moveToMapping: string;
  closeAuthWindow: boolean;
}

const initialState = {
  connectors: [],
  moveToMapping: "",
  closeAuthWindow: false,
} as ConnectorStoreModel;

export const connectorStoreSlice = createSlice({
  name: "connectorStore",
  initialState,
  reducers: {
    setConnectors: (state, action) => {
      state.connectors = action.payload;
    },
    triggerWindowClose: (state) => {
      state.closeAuthWindow = true;
    },
    markConnected: (state, action) => {
      let connectorSlug = action.payload.slug;
      let connector = state.connectors.find((v) => v.slug === connectorSlug);
      if (connector !== undefined) {
        connector.linked = !!action.payload.status;
      }
    },
    setConnectorSyncStatuses: (state, action) => {
      _.forEach(state.connectors, (item, idx) => {
        let statusItem = action.payload.find(
          (x: any) => x.connector_id === item.id
        );
        if (!!statusItem) {
          state.connectors[idx] =
            item.id === statusItem.connector_id
              ? {
                  ...state.connectors[idx],
                  sync_status: statusItem.status,
                  last_successful_sync_time:
                    statusItem.last_successful_sync_time,
                }
              : state.connectors[idx];
        } else {
          state.connectors[idx] = state.connectors[idx].linked
            ? { ...state.connectors[idx], sync_status: "running" }
            : state.connectors[idx];
        }
      });
    },
    setConnectorSyncStatusSingleBySlug: (state, action) => {
      state.connectors = state.connectors.map((x) => {
        if (action.payload.slug === x.slug) {
          return { ...x, sync_status: action.payload.status };
        } else {
          return x;
        }
      });
    },
    setConnectorSyncStatusSingleByRefSlug: (state, action) => {
      state.connectors = state.connectors.map((x) => {
        if (
          action.payload.external_reference_slug === x.external_reference_slug
        ) {
          let last_successful_sync_time = x.last_successful_sync_time;
          if (!x.needs_relinking && action.payload.last_successful_sync_time && action.payload.status === "success") {
            last_successful_sync_time =
              action.payload.last_successful_sync_time;
          }
          return {
            ...x,
            sync_status: action.payload.status,
            last_successful_sync_time: last_successful_sync_time,
          };
        } else {
          return x;
        }
      });
    },
    setExternalReferenceSlug: (state, action) => {
      let connectorSlug = action.payload.slug;
      let connector = state.connectors.find((v) => v.slug === connectorSlug);
      if (connector !== undefined) {
        connector.external_reference_slug =
          action.payload.external_reference_slug;
      }
    },
    setConnectionInProgress: (state, action) => {
      let connectorSlug = action.payload.slug;
      let connector = state.connectors.find((v) => v.slug === connectorSlug);
      if (connector !== undefined) {
        connector.connecting = !!action.payload.status;
      }
    },
    setMoveToMapping: (state, action) => {
      state.moveToMapping = action.payload.connectorSlug;
    },
    disconnectConnector: (state, action) => {
      let connector = state.connectors.find(
        (item) => item.slug === action.payload.slug
      );
      if (connector) connector.linked = false;
    },
  },
});

export const {
  setConnectors,
  setConnectorSyncStatuses,
  setConnectorSyncStatusSingleBySlug,
  setConnectorSyncStatusSingleByRefSlug,
  setExternalReferenceSlug,
  markConnected,
  setConnectionInProgress,
  setMoveToMapping,
  triggerWindowClose,
  disconnectConnector,
} = connectorStoreSlice.actions;

export default connectorStoreSlice.reducer;
