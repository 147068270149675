import { Grid, Skeleton, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import { map, orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import bgImage from "../../../assets/Group-177-min.png";
import FilterListWithOverlay from "../FiltersListWithOverlay";
import KPIList from "../KPIList";
import getChartsForBoard from "../../../services/board-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import { theme } from "../../../theme";
import AuthorizationErrorHandler from "../../molecules/Editor/AuthorizationErrorHandler";
import { filterDataFormatter } from "../../../utils/filterDataFormatter";

interface Props {
  data: any;
  filters: any;
  board_type: string;
}

const INIT_STATE = {
  data: [],
  filters: {},
  board_type: "grid",
};

type BudgetProgramsMonitoringProps = {
  kpiSlug: string;
  isTabClicked: boolean;
};

const BudgetProgramsMonitoring = ({
  kpiSlug,
  isTabClicked,
}: BudgetProgramsMonitoringProps) => {
  const slug = "program-budget-tracker";

  const navigate = useNavigate();
  const [board, setBoard] = useState<Props>(INIT_STATE);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);

  const onFiltersApply = (_filters: any, allFilters: any = {}) => {
    fetchKPIsForBoard({ ...filterDataFormatter(_filters, allFilters) });
  };

  const fetchKPIsForBoard = (filters: any) => {
    setBoard((prev) => {
      return { ...prev, data: [] };
    });
    setLoading(true);
    getChartsForBoard(slug, "", filters)
      .then((res) => {
        if (
          res &&
          res.data &&
          res.data.kpi &&
          Object.keys(res.data.kpi).length === 0
        ) {
          setAuthorized(false);
        }
        if (
          res &&
          res.data &&
          res.data.kpi &&
          Object.keys(res.data.kpi).length > 0
        ) {
          setLoading(false);
          setAuthorized(true);
          let data = res.data.kpi || [];
          data = map(data, (ele: any) => {
            if (!ele.is_position_applicable) {
              return {
                ...ele,
                position: 999,
                position_x: 999,
                position_y: 999,
                row: 3,
                column: 12,
              };
            } else {
              return ele;
            }
          });
          let orderedData = orderBy(data, ["position"], ["asc"]);
          setBoard((prev) => {
            return {
              ...prev,
              data: orderedData,
              filters: filters,
            };
          });
        }
      })
      .catch((error) => {
        dispatch(showMessage({ message: "Error fetching data" }));
        navigate("/connectors/list");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  let [searchParams, setSearchParams] = useSearchParams();
  const [urlKpiFilters, setUrlKpiFilters] = useState({});

  useEffect(() => {
    if (kpiSlug !== "" && !loading) {
      setTimeout(() => {
        let ele = document.getElementById(kpiSlug);
        if (ele) {
          window.scrollTo({
            behavior: "smooth",
            top:
              ele.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              100,
          });
        }
      }, 1000);
    }
  }, [kpiSlug, loading]);

  useEffect(() => {
    if (searchParams?.get("encoded_filter_data") && !isTabClicked) {
      const data = JSON.parse(
        atob(searchParams?.get("encoded_filter_data") || "{}")
      );
      setUrlKpiFilters(data);
    } else {
      setUrlKpiFilters({});
    }
  }, [searchParams]);

  return (
    <React.Fragment>
      <Stack direction="row">
        <Grid container sx={{ height: "100%" }}>
          <Grid item md={12}>
            {
              <Box mt={3} px={6} mb={3}>
                <FilterListWithOverlay
                  onFiltersApply={onFiltersApply}
                  board_slug={slug}
                  urlKpiFilters={urlKpiFilters}
                />
              </Box>
            }

            {!authorized && !loading && (
              <Grid container px={6}>
                <AuthorizationErrorHandler
                  errorMessage="You don’t have access to see Budget Monitoring. Please reach out to
        admin to get access"
                />
              </Grid>
            )}
            {board.data.length > 0 && !loading && (
              <Box
                px={6}
                sx={{
                  backgroundImage: `url(${bgImage})`,
                  backgroundRepeat: "repeat",
                  position: "relative",
                  color: theme.custom.filterBackgroundColor,
                  zIndex: "auto",
                }}
              >
                <KPIList
                  data={board.data}
                  filters={board.filters}
                  board_slug={slug}
                  board_type={board.board_type}
                />
              </Box>
            )}
            {board.data.length === 0 && !loading && authorized && (
              <Box px={6}>
                <AuthorizationErrorHandler
                  errorMessage="
                  Either there are no linked KPIs or you don't have permission
                  to view KPIs. Please reach out to admin to get
                  access or create budget. 
                "
                ></AuthorizationErrorHandler>
              </Box>
            )}
            {loading && (
              <>
                <Skeleton
                  sx={{ mx: 6, my: 2, bgcolor: "grey.100" }}
                  variant="rectangular"
                  animation="wave"
                  height={400}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Stack>
    </React.Fragment>
  );
};

export default BudgetProgramsMonitoring;
