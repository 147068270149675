import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { fontInter, theme } from "../../theme";
import { styled } from "@mui/material/styles";
import ChipsForBoard from "./ChipsForBoard";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { showOverlay } from "../../store/filter-overlay-store";
import {
  setCompanyStartDate,
  setKpiStatus,
} from "../../store/filter-state-store";
import { getFilters, saveFilters } from "../../services/filter-service";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "../../assets/save_icon.png";
import SearchIcon from "../../assets/search.png";
import UpwardArrowIcon from "../../assets/upward_arrow.png";
import DownwardArrowIcon from "../../assets/downward_arrow.png";
import { showMessage } from "../../store/error-handler-store";
import { clearLocalStorage } from "../../services/authentication-service";
import SingleDatePickerComponent from "../atoms/SingleDatePickerComponent";
import moment from "moment";

import AutoCompleteWithFilters from "../molecules/AutoCompleteWithFilters";
import {
  filter,
  find,
  isArray,
  isEmpty,
  set,
  sortBy,
  unset,
  values,
} from "lodash";
import DateRangePickerCustomComponent from "../atoms/DateRangePickerCustomComponent";
import CustomMonthPicker from "../atoms/CustomMonthPicker";
import CustomSwitch from "../atoms/CustomSwitch";

const AutoCompleteComponent = styled(Autocomplete)({
  ".MuiAutocomplete-inputRoot": {
    color: theme.custom.dropdownPrimaryColor,
    fontWeight: 500,
    borderRadius: 8,
    fontSize: "14px",
    fontFamily: fontInter,
  },
});

interface Props {
  board_slug: string;
  onFiltersApply: Function;
  urlKpiFilters: any;
}

const FilterList: React.FC<Props> = (props) => {
  const { board_slug, onFiltersApply, urlKpiFilters } = props;

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [showMore, setShowMore] = useState(false);
  const [allFilters, setAllFilters] = useState<any>({});
  const [currentFilters, setCurrentFilters] = useState<any>({});
  const [savedFilters, setSavedFilters] = useState<any>({});
  const overlay = useAppSelector((state) => state.filterOverlay.overlay);
  const [isDateRangeValid, setDateRangeValid] = useState(true);
  const [filtersLoaded, setFiltersLoaded] = useState(false);
  const [filtersApply, setFiltersApply] = useState<boolean>(false);

  const [allFiltersList, setAllFiltersList] = useState([] as any[]);

  useEffect(() => {
    if (board_slug.length) {
      getAllFilters(board_slug);
    }
  }, [board_slug]);

  useEffect(() => {
    setCurrentFilters(urlKpiFilters);
    setFiltersApply(true);
  }, [urlKpiFilters]);

  useEffect(() => {
    if (overlay === false) {
      setShowMore(false);
    }
  }, [overlay]);

  const getAllFilters = (slug_name: string) => {
    setAllFiltersList([]);
    setFiltersLoaded(false);

    let budget_uid = "";
    if (urlKpiFilters && board_slug === "expense-proof-and-invoice-tracking") {
      budget_uid = urlKpiFilters?.["budget_uid"] || "";
    }

    getFilters(slug_name, budget_uid)
      .then((res) => {
        if (res.status === 417) {
          clearLocalStorage();
          navigate("/login");
          return;
        }
        if (res.status === 403) {
          dispatch(
            setKpiStatus({
              kpisStatus:
                "You don't have permission for editing the budget. Please contact admin for access",
            })
          );
        }

        if (res.data?.kpi_status) {
          dispatch(setKpiStatus({ kpiStatus: res.data.kpi_status }));
        }

        setAllFilters(res.data.data);
        setAllFiltersList(sortBy(values(res.data.data), "position"));

        let initSavedFilters: any = {};
        Object.keys(res.data.data).forEach((key) => {
          initSavedFilters[key] = populateSavedData(res.data.data, key);
        });

        Object.keys(initSavedFilters).forEach((ele: string) => {
          if (
            ele === "daterange" ||
            ele === "aggregation" ||
            ele === "single-date"
          ) {
            initSavedFilters[ele] = {
              ...initSavedFilters[ele],
              action: "reset",
            };
          }
        });

        dispatch(setCompanyStartDate(res?.data?.data?.daterange?.start_date));
        setSavedFilters(initSavedFilters);
        // override if filters coming from url.
        if (
          urlKpiFilters &&
          board_slug !== "expense-proof-and-invoice-tracking"
        ) {
          if (urlKpiFilters.program_name) {
            const program_name = urlKpiFilters.program_name.toLowerCase();
            // try to match with program
            const selectedProgram = res?.data?.data.program.data.find(
              (i: any) => i.label.toLowerCase() === program_name
            );
            if (
              window.confirm(
                `Applying filter with program ${selectedProgram.label}. Proceed?`
              )
            ) {
              urlKpiFilters.program = [
                { ...selectedProgram, action: "delete" },
              ];
            }
          }
          initSavedFilters = { ...initSavedFilters, ...urlKpiFilters };
        } else if (
          urlKpiFilters &&
          board_slug === "expense-proof-and-invoice-tracking"
        ) {
          if (urlKpiFilters["daterange"]) {
            initSavedFilters = {
              ...initSavedFilters,
              monthrange: urlKpiFilters?.["daterange"]?.["end_date"].slice(
                0,
                7
              ),
            };
          }
        }
        setCurrentFilters(initSavedFilters);
        onFiltersApply(initSavedFilters, res?.data?.data || {}, res.status);
      })
      .finally(() => {
        setFiltersLoaded(true);
      });
  };

  const populateSavedData = (filterData: any, key: string) => {
    if (key === "daterange" || key === "single-date") {
      return filterData[key]?.["saved_data"] || [];
    } else if (
      (filterData?.[key]?.data?.length ?? 0) > 0 &&
      filterData[key]?.multi_select
    ) {
      return !isEmpty(filterData[key]?.["saved_data"])
        ? filterData[key]?.["saved_data"]
        : [];
    } else if (
      (filterData?.[key]?.data?.length ?? 0) > 0 &&
      !filterData[key]?.multi_select
    ) {
      return !isEmpty(filterData[key]?.["saved_data"])
        ? filterData[key]?.["saved_data"]
        : { label: "", value: "" };
    } else if (key === "total_column") {
      return !isEmpty(filterData[key]?.["saved_data"])
        ? filterData[key]?.["saved_data"]
        : { value: "", action: "reset" };
    } else {
      return [];
    }
  };

  const showMoreFilters = () => {
    setShowMore(!showMore);
    dispatch(showOverlay(!showMore));
  };

  const onApplyClick = () => {
    onFiltersApply(currentFilters, allFilters);
    setFiltersApply(true);
    closeFilter();
  };

  const onFilterSave = () => {
    setFiltersApply(false);
    onApplyClick();
    saveFilters(board_slug, currentFilters).then((res) => {
      dispatch(
        showMessage({
          message: "Filters Saved Successfully",
          variant: "success",
        })
      );
      setSavedFilters(currentFilters);
    });
  };

  const closeFilter = () => {
    if (showMore === true) {
      setShowMore(false);
      dispatch(showOverlay(false));
    }
  };

  const deleteFilterElement = (filterToBeDeleted: any) => {
    let _currentFilters: any = { ...currentFilters };
    let _allFilters = { ...allFilters };
    if (
      filterToBeDeleted["filter"] !== "daterange" &&
      filterToBeDeleted["filter"] !== "single-date"
    ) {
      if (!filterToBeDeleted && !filterToBeDeleted["filter"]) {
        return;
      }
      let tempFilterData = filter(
        _allFilters[filterToBeDeleted["filter"]]["data"],
        (value: any) => {
          return value === filterToBeDeleted["value"];
        }
      );

      if (tempFilterData.length === 0) {
        if (
          !find(
            _allFilters[filterToBeDeleted["filter"]]["data"],
            (val) => val["value"] === filterToBeDeleted["value"]
          )
        ) {
          _allFilters[filterToBeDeleted["filter"]]["data"].push(
            filterToBeDeleted
          );
          setAllFilters(_allFilters);
        }
      }
    }

    if (filterToBeDeleted["action"] === "delete") {
      if (isArray(_currentFilters[filterToBeDeleted["filter"]])) {
        let options = _currentFilters[filterToBeDeleted["filter"]];
        _currentFilters[filterToBeDeleted["filter"]] = filter(
          options,
          (opt: any) => {
            return opt["value"] !== filterToBeDeleted["value"];
          }
        );
      } else {
        set(_currentFilters, filterToBeDeleted["filter"], {
          label: "",
          value: "",
        });
      }
    } else if (filterToBeDeleted["action"] === "reset") {
      _currentFilters[filterToBeDeleted?.filter] = reset(
        filterToBeDeleted["filter"]
      );
    }

    setFiltersApply(true);
    onFiltersApply(_currentFilters, allFilters);
    setCurrentFilters(_currentFilters);
  };

  const reset = (key: string = "all") => {
    if (key === "all") {
      return savedFilters;
    } else {
      return savedFilters[key];
    }
  };

  const resetFilters = () => {
    onFiltersApply(reset(), allFilters);
    setCurrentFilters(reset());
  };

  const clearAllFilters = () => {
    let _currentFilters: any = {};

    if (allFilters && allFilters["daterange"]) {
      _currentFilters["daterange"] = {
        start:
          allFilters?.daterange?.multi_filter?.ytd?.start ||
          moment().startOf("year").format("YYYY-MM-DD"),
        end:
          allFilters?.daterange?.multi_filter?.ytd?.end ||
          moment().format("YYYY-MM-DD"),
        action: "reset",
      };
    }

    if (allFilters && allFilters["aggregation"]) {
      _currentFilters["aggregation"] = {
        label: "Monthly",
        value: "monthly",
        action: "reset",
      };
    }

    if (allFilters && allFilters["total_column"]) {
      _currentFilters["total_column"] = {
        value: "yes",
        action: "reset",
      };
    }
    onFiltersApply(_currentFilters, allFilters);
    setCurrentFilters(_currentFilters);
  };

  const getOptions = (ele: any) => {
    if (allFilters && allFilters[ele] && allFilters[ele]["name"]) {
      if (allFilters[ele]["name"].toLowerCase().includes("program")) {
        return [
          {},
          ...(allFilters[ele]?.data?.filter((i: any) =>
            hideDeleted ? !i.label.includes("(deleted)") : true
          ) || []),
        ];
      }
    }
    return allFilters[ele]?.data || [];
  };

  const getFilterOptions = (ele: any, options: any, searchTerm: string) => {
    const _options = options.filter((i: any) =>
      i.label?.toLowerCase().trim().includes(searchTerm)
    );
    if (allFilters[ele]["name"].toLowerCase().includes("program")) {
      return [
        {},
        ...(_options.filter((i: any) =>
          hideDeleted ? !i.label?.includes("(deleted)") : true
        ) || []),
      ];
    }

    return _options || [];
  };
  const [hideDeleted, setHideDeleted] = useState(true);

  const renderOption = (props: any, option: any, ele: any) => {
    if (
      allFilters?.[ele]?.["name"].toLowerCase().includes("program") &&
      props["data-option-index"] === 0
    ) {
      return (
        <Grid container justifyContent={"end"}>
          <Grid
            item
            component="li"
            {...props}
            onClick={() => {}}
            sx={{
              background: "none !important",
            }}
          >
            <Checkbox
              checked={hideDeleted}
              onClick={(e) => {
                setHideDeleted(!hideDeleted);
              }}
            />
            <Typography py={1} variant="inter_p_500_14">
              Hide deleted
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item component="li" {...props}>
        <Stack direction="column">
          <Typography py={1} variant="inter_p_500_14">
            {option?.label}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  const getAutocompleteValue = (key: any) => {
    if (currentFilters && currentFilters[key]) {
      if (
        currentFilters[key] &&
        isEmpty(currentFilters[key]) &&
        !allFilters[key]["multi_select"]
      ) {
        return { label: "", value: "" };
      } else if (
        allFilters[key]["multi_select"] &&
        currentFilters[key].length === 0
      ) {
        return [];
      } else {
        return isEmpty(currentFilters[key]) ? [] : currentFilters[key];
      }
    }
    return allFilters[key]["multi_select"] ? [] : { label: "", value: "" };
  };
  const handleChangeAutocomplete = (
    element: any,
    opt: any,
    multiple?: boolean
  ) => {
    setFiltersApply(false);
    let _currentFilters: any = { ...currentFilters };
    if (!multiple) {
      _currentFilters[element] = opt?.option;
      if (element === "daterange" || element === "aggregation") {
        if (!_currentFilters[element]["action"]) {
          _currentFilters[element]["action"] = "reset";
        }
      } else {
        if (!_currentFilters[element]["action"]) {
          _currentFilters[element]["action"] = "delete";
        }
      }
    } else {
      if (
        _currentFilters[element] &&
        !_currentFilters[element].some(
          (ele: any) => ele.value === opt?.option.value
        )
      ) {
        _currentFilters[element] = [
          ..._currentFilters[element],
          { ...opt?.option, action: "delete" },
        ];
      } else {
        _currentFilters[element] = [{ ...opt?.option, action: "delete" }];
      }
      let tmp = allFilters[element]["data"];
      let filtered = tmp.filter(
        (ele: any) => ele["value"] !== opt?.option.value
      );

      let _allFilters = { ...allFilters };
      _allFilters[element]["data"] = filtered;
      setAllFilters(_allFilters);
    }

    let kpi_slug = allFilters[element]["kpi_slug"];

    Object.keys(_currentFilters).forEach((ele: any) => {
      if (ele !== element) {
        if (_currentFilters[ele]?.[kpi_slug]) {
          _currentFilters[ele] = multiple
            ? []
            : { label: "", value: "", action: "delete" };
        }
      }
    });

    setCurrentFilters(_currentFilters);
  };

  const handleDelete = (key: any, option: any, index: number) => {
    setFiltersApply(false);
    let tempFilters = { ...currentFilters };
    let tempAllFilters = { ...allFilters };
    let arrToUpdate = [...tempFilters[key]];
    arrToUpdate.splice(index, 1);
    tempFilters[key] = arrToUpdate;
    setCurrentFilters(tempFilters);
    let exists = tempAllFilters[key]["data"].some(
      (ele: any) => ele.value === option.value
    );
    if (!exists) {
      tempAllFilters[key]["data"].unshift(option);
    }

    setAllFilters(tempAllFilters);
  };

  const onSingleDateChange = (date: any, key: string) => {
    setFiltersApply(false);
    let _currentFilters = { ...currentFilters };
    _currentFilters[key] = {
      end: moment(date).format("YYYY-MM-DD"),
      action: "reset",
    };

    setCurrentFilters(_currentFilters);
  };

  return (
    <React.Fragment>
      <Box
        pt={3}
        sx={{
          background: theme.custom.filterBackgroundColor,
          height: "auto",
          width: "100%",
          border: 1,
          borderColor: theme.custom.filterBorderColor,
          borderRadius: "6px",
          display: isEmpty(allFilters) ? "none" : "block",
        }}
      >
        <Box
          mb={2}
          sx={{ visibility: filtersLoaded ? "visible" : "hidden" }}
          mx={1}
          px={2}
        >
          <Grid container justifyContent={"space-between"}>
            <Grid item sm={9}>
              <Grid container spacing={2}>
                {allFiltersList &&
                  allFiltersList.length > 0 &&
                  allFiltersList.slice(0, 4).map((ele: any, index: number) => {
                    if (ele["filter_slug"] === "daterange") {
                      return (
                        <Grid
                          key={index}
                          item
                          sm={Object.keys(allFilters).length >= 4 ? 3 : 4}
                        >
                          <Box
                            sx={{
                              border: `1px solid ${theme.custom.borderColor}`,
                              borderRadius: "6px",
                            }}
                          >
                            <DateRangePickerCustomComponent
                              onChange={(date: any) => {
                                let _currentFilters = { ...currentFilters };
                                _currentFilters[ele["filter_slug"]] = date;
                                setCurrentFilters(_currentFilters);
                              }}
                              menuOptions={ele["multi_filter"]}
                              dateRangeValue={
                                currentFilters[ele["filter_slug"]]
                              }
                              id={ele["filter_slug"] + "-" + index}
                            />
                          </Box>
                        </Grid>
                      );
                    } else if (ele["filter_slug"] === "single-date") {
                      return (
                        <Grid
                          key={index}
                          item
                          sm={Object.keys(allFilters).length >= 4 ? 3 : 4}
                        >
                          <Box
                            sx={{
                              border: `1px solid ${theme.custom.borderColor}`,
                              borderRadius: "6px",
                              padding: "1px",
                            }}
                          >
                            <SingleDatePickerComponent
                              date={
                                (currentFilters &&
                                  currentFilters["single-date"] &&
                                  currentFilters["single-date"]["end"]) ||
                                ""
                              }
                              onDateChange={(date: any) => {
                                onSingleDateChange(date, ele["filter_slug"]);
                              }}
                            />
                          </Box>
                        </Grid>
                      );
                    } else if (ele["type"] === "search") {
                      return (
                        <Grid
                          key={index}
                          item
                          sm={Object.keys(allFilters).length >= 4 ? 3 : 4}
                        >
                          <TextField
                            fullWidth
                            onChange={(e) => {
                              let _currentFilters = { ...currentFilters };
                              _currentFilters["search"] = e.target.value;
                              setCurrentFilters(_currentFilters);
                            }}
                            value={currentFilters["search"] || ""}
                            placeholder="Search"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    height="18"
                                    src={SearchIcon}
                                    alt="Search"
                                  />
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              style: {
                                height: "20px",
                                background: theme.custom.whiteColor,
                                fontFamily: fontInter,
                                fontSize: "14px",
                                fontWeight: 500,
                              },
                            }}
                          />
                        </Grid>
                      );
                    } else if (ele["type"] === "monthrange") {
                      return (
                        <Grid
                          key={index}
                          item
                          sm={Object.keys(allFilters).length >= 4 ? 3 : 4}
                        >
                          <Box key={index}>
                            <CustomMonthPicker
                              onChange={(value: any) => {
                                let _currentFilters = { ...currentFilters };
                                _currentFilters[ele["filter_slug"]] = value;
                                setCurrentFilters(_currentFilters);
                              }}
                              key={ele["filter_slug"]}
                              value={currentFilters[ele["filter_slug"]]}
                              minValue={(
                                urlKpiFilters?.["daterange"]?.["start_date"] ||
                                ""
                              ).slice(0, 7)}
                              maxValue={(
                                urlKpiFilters?.["daterange"]?.["end_date"] || ""
                              ).slice(0, 7)}
                              label={ele?.["name"] || ""}
                            />
                          </Box>
                        </Grid>
                      );
                    } else if (ele["type"] === "toggle") {
                      return (
                        <Grid
                          key={index}
                          item
                          sm={Object.keys(allFilters).length >= 4 ? 3 : 4}
                        >
                          <Box key={index}>
                            <Box mb={2} mt={2}>
                              <Stack alignItems={"center"} direction={"row"}>
                                <Typography
                                  sx={{
                                    color: theme.custom.primaryDarkColor,
                                    mr: 2,
                                  }}
                                  variant="inter_400_14"
                                >
                                  Show Total
                                </Typography>
                                <CustomSwitch
                                  active={
                                    currentFilters?.[ele?.["filter_slug"]]?.[
                                      "value"
                                    ] === "yes"
                                      ? true
                                      : false
                                  }
                                  extraSX={{}}
                                  backgroundColor={theme.palette.primary.main}
                                  trackColor="grey"
                                  key={"total_switch"}
                                  onToggle={() => {
                                    let _currentFilters = { ...currentFilters };
                                    _currentFilters[ele["filter_slug"]] =
                                      currentFilters[ele["filter_slug"]][
                                        "value"
                                      ] === "yes"
                                        ? { value: "no", action: "reset" }
                                        : { value: "yes", action: "reset" };
                                    setCurrentFilters(_currentFilters);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    }

                    return (
                      <Grid
                        key={index}
                        item
                        xs={Object.keys(allFilters).length >= 4 ? 3 : 4}
                      >
                        <AutoCompleteWithFilters
                          allFilters={allFilters}
                          currentFilters={currentFilters}
                          disableClearable
                          filterSelectedOptions={true}
                          fullwidth={true}
                          getAutocompleteValue={getAutocompleteValue}
                          filterKey={ele["filter_slug"]}
                          multiple={ele["multi_select"]}
                          onDelete={handleDelete}
                          options={
                            ele?.data && ele?.data.length > 0 ? ele?.data : []
                          }
                          tagsLimit={2}
                          onChange={(
                            filterKey: any,
                            opt: any,
                            multiple: any
                          ) => {
                            handleChangeAutocomplete(filterKey, opt, multiple);
                          }}
                        />
                      </Grid>
                    );
                  })}
              </Grid>
            </Grid>
            <Grid item justifyContent={"space-between"} xs={3}>
              <Grid
                spacing={
                  allFilters && Object.keys(allFilters).length > 4 ? 0 : 2
                }
                container
                justifyContent={
                  allFilters && Object.keys(allFilters).length > 4
                    ? "space-evenly"
                    : "right"
                }
              >
                <Grid item sm={4}>
                  <Button
                    fullWidth
                    sx={{
                      height: "50px",
                    }}
                    size={"medium"}
                    variant="contained"
                    onClick={onApplyClick}
                    disabled={!isDateRangeValid}
                  >
                    <Typography variant="poppins_p_600_14"> Apply </Typography>
                  </Button>
                </Grid>
                <Grid item sm={3}>
                  <Button
                    onClick={() => {
                      onFilterSave();
                    }}
                    sx={{
                      height: "50px",
                    }}
                    disabled={!isDateRangeValid}
                    fullWidth
                    variant="outlined"
                  >
                    <img src={SaveIcon} height={25} alt="save" />
                  </Button>
                </Grid>
                {Object.keys(allFilters).length > 4 && (
                  <Grid item sm={3}>
                    <Button
                      fullWidth
                      onClick={showMoreFilters}
                      sx={{
                        height: "50px",
                      }}
                      variant="outlined"
                    >
                      {!showMore && (
                        <img
                          src={DownwardArrowIcon}
                          height={15}
                          width={20}
                          alt="Downward arrow"
                        />
                      )}
                      {showMore && (
                        <img
                          src={UpwardArrowIcon}
                          height={15}
                          width={20}
                          alt="Upward arrow"
                        />
                      )}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {showMore && <Divider sx={{ my: 4 }} />}
        {showMore && (
          <Box my={2} mx={1} px={2} mb={4}>
            <Grid container spacing={2}>
              {allFiltersList &&
                allFiltersList.length > 0 &&
                allFiltersList
                  .slice(4, Object.keys(allFilters).length)
                  .map((ele: any, index: number) => {
                    if (ele["filter_slug"] === "daterange") {
                      return (
                        <Grid item mt={2} pl={3} xs={3}>
                          <DateRangePickerCustomComponent
                            onChange={(date: any) => {
                              let _currentFilters = { ...currentFilters };
                              _currentFilters[ele["filter_slug"]] = date;
                              setCurrentFilters(_currentFilters);
                            }}
                            menuOptions={ele["multi_filter"]}
                            dateRangeValue={currentFilters[ele["filter_slug"]]}
                            id={ele["filter_slug"] + "-" + index}
                          />
                        </Grid>
                      );
                    } else if (ele["filter_slug"] === "single-date") {
                      return (
                        <Grid
                          key={index}
                          item
                          sm={Object.keys(allFilters).length >= 4 ? 3 : 4}
                        >
                          <Box
                            sx={{
                              border: `1px solid ${theme.custom.borderColor}`,
                              borderRadius: "6px",
                              padding: "1px",
                            }}
                          >
                            <SingleDatePickerComponent
                              date={
                                (currentFilters &&
                                  currentFilters["single-date"] &&
                                  currentFilters["single-date"]["end"]) ||
                                ""
                              }
                              onDateChange={(date: any) => {
                                onSingleDateChange(date, ele["filter_slug"]);
                              }}
                            />
                          </Box>
                        </Grid>
                      );
                    } else if (ele["type"] === "search") {
                      return (
                        <Grid
                          key={index}
                          item
                          sm={Object.keys(allFilters).length >= 4 ? 3 : 4}
                        >
                          <TextField
                            fullWidth
                            onChange={(e) => {
                              let _currentFilters = { ...currentFilters };
                              _currentFilters["search"] = e.target.value;
                              setCurrentFilters(_currentFilters);
                            }}
                            value={currentFilters["search"] || ""}
                            placeholder="Search"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    height="18"
                                    src={SearchIcon}
                                    alt="Search"
                                  />
                                </InputAdornment>
                              ),
                            }}
                            inputProps={{
                              style: {
                                height: "20px",
                                background: theme.custom.whiteColor,
                                fontFamily: fontInter,
                                fontSize: "14px",
                                fontWeight: 500,
                              },
                            }}
                          />
                        </Grid>
                      );
                    } else if (ele["type"] === "monthrange") {
                      return (
                        <Grid
                          key={index}
                          item
                          sm={Object.keys(allFilters).length >= 4 ? 3 : 4}
                        >
                          <Box key={index}>
                            <CustomMonthPicker
                              onChange={(value: any) => {
                                let _currentFilters = { ...currentFilters };
                                _currentFilters[ele["filter_slug"]] = value;
                                setCurrentFilters(_currentFilters);
                              }}
                              key={ele["filter_slug"]}
                              value={currentFilters[ele["filter_slug"]]}
                              minValue={(
                                urlKpiFilters?.["daterange"]?.["start_date"] ||
                                ""
                              ).slice(0, 7)}
                              maxValue={(
                                urlKpiFilters?.["daterange"]?.["end_date"] || ""
                              ).slice(0, 7)}
                              label={ele?.["name"] || ""}
                            />
                          </Box>
                        </Grid>
                      );
                    } else if (ele["type"] === "toggle") {
                      return (
                        <Grid
                          key={index}
                          item
                          sm={Object.keys(allFilters).length >= 4 ? 3 : 4}
                        >
                          <Box key={index}>
                            <Box mb={2} mt={2}>
                              <Stack alignItems={"center"} direction={"row"}>
                                <Typography
                                  sx={{
                                    color: theme.custom.primaryDarkColor,
                                    mr: 2,
                                  }}
                                  variant="inter_400_14"
                                >
                                  Show Total
                                </Typography>
                                <CustomSwitch
                                  active={
                                    currentFilters?.[ele?.["filter_slug"]]?.[
                                      "value"
                                    ] === "yes"
                                      ? true
                                      : false
                                  }
                                  extraSX={{}}
                                  backgroundColor={theme.palette.primary.main}
                                  trackColor="grey"
                                  key={"total_switch"}
                                  onToggle={() => {
                                    let _currentFilters = { ...currentFilters };
                                    _currentFilters[ele["filter_slug"]] =
                                      currentFilters[ele["filter_slug"]][
                                        "value"
                                      ] === "yes"
                                        ? { value: "no", action: "reset" }
                                        : { value: "yes", action: "reset" };
                                    setCurrentFilters(_currentFilters);
                                    // showTotalToggle(!showTotal, "showTotal");
                                    // setShowTotal(!showTotal);
                                  }}
                                />
                              </Stack>
                            </Box>
                          </Box>
                        </Grid>
                      );
                    }
                    return (
                      <Grid key={index} item xs={2.5}>
                        <AutoCompleteComponent
                          limitTags={2}
                          multiple={ele["multi_select"]}
                          fullWidth
                          id={ele + "autocomplete"}
                          disableClearable
                          options={getOptions(ele["filter_slug"])}
                          filterSelectedOptions
                          onChange={(e: any, v: any, r: any, opt: any) =>
                            handleChangeAutocomplete(
                              ele["filter_slug"],
                              opt,
                              ele["multi_select"]
                            )
                          }
                          filterOptions={(options, state) =>
                            getFilterOptions(
                              ele["filter_slug"],
                              options,
                              state.inputValue.toLowerCase().trim()
                            )
                          }
                          value={getAutocompleteValue(ele["filter_slug"])}
                          getOptionLabel={(option: any) =>
                            option ? option.label : ""
                          }
                          renderOption={(props, option) =>
                            renderOption(props, option, ele["filter_slug"])
                          }
                          renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option: any, index) => (
                              <Chip
                                sx={{
                                  background: theme.custom.whiteColor,
                                  border: `1px solid ${theme.custom.primaryDarkColor}`,
                                  marginRight: "2px",
                                }}
                                label={
                                  <Typography variant="inter_500_12">
                                    {option?.label}
                                  </Typography>
                                }
                                onDelete={() => {
                                  handleDelete(
                                    ele["filter_slug"],
                                    option,
                                    index
                                  );
                                }}
                                deleteIcon={<CloseIcon />}
                                size={"small"}
                              />
                            ));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                style: {
                                  marginLeft: 7,
                                },
                              }}
                              label={
                                <Typography variant="inter_p_500_10">
                                  {ele?.["name"] || ""}
                                </Typography>
                              }
                            />
                          )}
                        />
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
        )}
        <Box
          sx={{ visibility: filtersLoaded ? "visible" : "hidden" }}
          mt={4}
          px={2}
          ml={1}
        >
          <ChipsForBoard
            slug={board_slug}
            clearAllFilters={clearAllFilters}
            isFiltersLoaded={filtersLoaded}
            currentAppliedFilters={currentFilters}
            deleteFilterElement={deleteFilterElement}
            filterApply={filtersApply}
            resetFilters={resetFilters}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default FilterList;
