import React, { useCallback, useEffect, useState } from "react";
import FilterList from "./FiltersList";
import Backdrop from "@mui/material/Backdrop";
import { theme } from "../../theme";
import Box from "@mui/material/Box";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { setOverlay } from "../../store/filter-overlay-store";
import CustomFilterPanel from "./CustomFilterPanel";
interface Props {
  onFiltersApply: Function;
  isCustomBoard?: boolean;
  board_slug: string;
  urlKpiFilters?: any;
}

const FilterListWithOverlay = (props: Props) => {
  const [slug, setSlug] = useState<string>("");
  useEffect(() => {
    setSlug(props.board_slug);
  }, [props.board_slug]);

  const { onFiltersApply, isCustomBoard, board_slug } = props;

  const [open, setOpen] = React.useState(false);
  const overlay = useAppSelector((state) => state.filterOverlay.overlay);
  const dispatch = useAppDispatch();

  const handleClose = useCallback(() => {
    dispatch(setOverlay(false));
    setOpen(false);
  }, [overlay]);

  const _handleKeyDown = (e: any) => {
    switch (e.code) {
      case "Escape":
        return handleClose();
      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", _handleKeyDown);

    return () => {
      document.removeEventListener("keydown", _handleKeyDown);
    };
  }, []);

  useEffect(() => {
    setOpen(overlay);
  }, [overlay]);

  return (
    <React.Fragment>
      <Backdrop
        sx={{
          color: theme.custom.filterBackgroundColor,
          zIndex: (th) => th.zIndex.drawer + 1,
        }}
        open={open}
        onClick={handleClose}
      ></Backdrop>
      <Box
        sx={{
          position: "relative",
          color: theme.custom.filterBackgroundColor,
          zIndex: (th) => th.zIndex.drawer + 1,
        }}
      >
        {isCustomBoard ? (
          <CustomFilterPanel
            board_slug={slug}
            onFiltersApply={onFiltersApply}
            urlKpiFilters={props.urlKpiFilters || {}}
          />
        ) : (
          <FilterList
            urlKpiFilters={props.urlKpiFilters || {}}
            onFiltersApply={onFiltersApply}
            board_slug={slug}
          />
        )}
      </Box>
    </React.Fragment>
  );
};

export default FilterListWithOverlay;
