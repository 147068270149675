import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/CancelOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { get, includes, omit, set, some, unset } from "lodash";
import { nanoid } from "nanoid";
import { memo, useCallback, useEffect, useState } from "react";
import {
  ConnectorMappingConfig,
  getDimAttr,
} from "../../services/user-mapping-service";
import { showMessage } from "../../store/error-handler-store";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { updateMapping } from "../../store/user-mapping-store";
import { theme } from "../../theme";
import { createMappingEntry } from "../../utils/user-mapping-utils";
import CustomChip from "../atoms/CustomChip";
import { DraggableItem, DraggableItemType } from "../atoms/DraggableItem";
import { DropResultType, DropTarget } from "../atoms/DropTarget";

type Props = {
  userMappingMeta: ConnectorMappingConfig;
  handleCloseModal: CallableFunction;
};

function UserMappingGroupEditor({ userMappingMeta, handleCloseModal }: Props) {
  const [selectedGroupName, setSelectedGroupName] = useState(
    userMappingMeta?.user_mapping?.mapping?.groups?.[0]?.["value_slug"] || ""
  );
  const [selectedGroupItems, setSelectedGroupItems] = useState("");
  const [groupNameResolved, setGroupNameResolved] = useState<any[]>([]);
  const [groupItemsResolved, setGroupItemsResolved] = useState<any[]>([]);
  const [droppedItems, setDroppedItem] = useState<DroppedResultType>({});
  const [showDeletedGroups, setShowDeletedGroups] = useState(false);
  const [showDeletedItems, setShowDeletedItems] = useState(false);
  const [searchTextItems, setSearchTextItems] = useState("");
  const [searchTextGroups, setSearchTextGroups] = useState("");

  const dispatch = useAppDispatch();
  const savingUM = useAppSelector((state) => {
    return state.userMapping.modifiedUMMetaId;
  });
  const attrs = useAppSelector((state) => {
    return state.userMapping.attrs;
  });

  const allPossibleKeys = useAppSelector((state) => {
    return omit(state.userMapping.allPossibleKeys, userMappingMeta.slug);
  });
  const [selectedItems, setSelectedItems] = useState<any>({});
  const [errorText, setErrorText] = useState("");
  const [loadingGroupItems, setLoadingGroupItems] = useState(false);
  const [loadingGroupName, setLoadingGroupName] = useState(false);
  const onGroupNameChange = (field: string) => {
    if (
      selectedGroupName !== field &&
      selectedGroupName !== "" &&
      !window.confirm(
        "All existing NON MANUAL groups will get removed. Are you sure?"
      )
    ) {
      return;
    }
    setSelectedGroupName(field);
    setLoadingGroupName(true);
    getDimAttr("quickbooks", field).then((resp) => {
      if (resp.status === 200) {
        // preserve manual items
        const manualItems = groupNameResolved?.filter(
          (item) => item?.isManual || false
        );
        setGroupNameResolved([...resp.data, ...manualItems]);
      }
      setLoadingGroupName(false);
    });
  };
  const handleGroupItemsChange = (field: string) => {
    setSelectedGroupItems(field);
    setLoadingGroupItems(true);
    getDimAttr("quickbooks", field).then((resp) => {
      if (resp.status === 200) {
        setGroupItemsResolved(resp.data);
      }
      setLoadingGroupItems(false);
    });
  };

  useEffect(() => {
    if (userMappingMeta.user_mapping.mapping?.groups?.length) {
      // load selected right dropdown resolved.
      onGroupNameChange(selectedGroupName);

      // load userMapping.
      let _droppedItems: any = {};
      // prepopulated _selectedItems with empty keys of all possible item types.
      let _selectedItems: any = Object.keys(allPossibleKeys).reduce(
        (o, k) => set(o, k, []),
        {}
      );
      for (const group of userMappingMeta.user_mapping.mapping.groups) {
        const targetDataId = group["values"][0];
        const targetHashId = group["hash_id"];
        set(_droppedItems, [targetDataId], {
          hashId: targetHashId,
        });
        if (group["value_slug_type"] === "manual") {
          createManualGroupItem(group["values"][0]);
        }
        for (const condition of group["conditions"]) {
          let itemType = condition["value_slug"];
          for (const item of condition["resolved_values"]) {
            if (!Object.keys(item).includes("name")) {
              // group item is already moved and does not exist anymore.
              continue;
            }
            let _item = { ...item, targetDataId, type: itemType };

            let id = nanoid(10);
            set(_droppedItems, [targetDataId, "data", itemType, id], {
              ..._item,
              dropId: id,
            });
            // add to selected items
            _selectedItems[itemType].push(_item.id);
          }
        }
        if (!_droppedItems[targetDataId]["data"]) {
          unset(_droppedItems, [targetDataId]);
        }
      }

      setSelectedItems(_selectedItems);
      setDroppedItem(_droppedItems);
    }
  }, [userMappingMeta.user_mapping]);

  interface DroppedResultType {
    [x: string]: {
      [x: string]: Array<DraggableItemType>;
    };
  }

  const onDrop = useCallback(
    (item: DraggableItemType, result: DropResultType) => {
      deleteItem(item);
      let _droppedItems = { ...droppedItems };
      let _item = {
        ...item,
        targetDataId: result.id,
      };
      let id = nanoid(10);
      set(_droppedItems, [result.id, "data", item.type, id], {
        ..._item,
        dropId: id,
      });
      setDroppedItem(_droppedItems);
      let newSelectedItems = { ...selectedItems };
      newSelectedItems[item.type] = [
        ...(selectedItems[item.type] || []),
        _item.id,
      ];
      setSelectedItems(newSelectedItems);
    },
    [droppedItems, selectedItems]
  );

  const handleSave = () => {
    // transform to um mapping
    let groups = [];
    for (const [id, group] of Object.entries(droppedItems)) {
      let umGroup: any = {};
      umGroup["value_slug"] = selectedGroupName;
      umGroup["value_slug_type"] = Object.keys(attrs).includes(
        selectedGroupName
      )
        ? "attr"
        : "dim";
      umGroup["values"] = [id];
      umGroup["hash_id"] = group["hashId"];
      umGroup["conditions"] = [];
      const isManual =
        groupNameResolved.find((i) => i.id === id)?.isManual || false;
      if (isManual) {
        umGroup["value_slug_type"] = "manual";
      }
      // todo: whats a better way to do this?
      let i = 0;
      for (const [subGroupType, subGroup] of Object.entries(group["data"])) {
        let condition: any = {
          operator: i === 0 ? "" : "OR",
          value_slug: subGroupType,
          value_slug_type: Object.keys(attrs).includes(subGroupType)
            ? "attr"
            : "dim",
          values: [],
          is_not: false,
          conditions: [],
        };

        for (const item of Object.values(subGroup)) {
          condition["values"].push(item["id"]);
        }
        umGroup["conditions"].push(condition);
        i += 1;
      }
      if (umGroup["conditions"].length > 0) {
        groups.push(umGroup);
      }
    }

    dispatch(
      updateMapping({
        meta_data_id: userMappingMeta.id,
        mapping: createMappingEntry("group", undefined, undefined, groups),
      })
    );
    handleCloseModal();
  };

  const [originalManualGroupItemName, setOriginalManualGroupItemName] =
    useState("");

  const createManualGroupItem = (itemName: string) =>
    groupNameResolved.push({
      id: itemName,
      name: itemName,
      classification: "",
      parent_id: "",
      parent_name: "",
      entity: "Manual",
      type: "",
      isManual: true,
    });

  const editManualGroupItem = (itemName: string) => {
    setGroupNameResolved([
      ...groupNameResolved.filter((m) => m.id !== originalManualGroupItemName),
      {
        id: itemName,
        name: itemName,
        classification: "",
        parent_id: "",
        parent_name: "",
        entity: "Manual",
        type: "",
        isManual: true,
      },
    ]);
    let _droppedItems = { ...droppedItems };
    _droppedItems[itemName] = { ..._droppedItems[originalManualGroupItemName] };
    delete _droppedItems[originalManualGroupItemName];
    Object.values(_droppedItems[itemName]["data"]).forEach((entity) => {
      Object.values(entity).forEach((i) => (i.targetDataId = itemName));
    });
    setDroppedItem(_droppedItems);
  };

  const addManualGroup = () => {
    // validations

    const _manualItemName = manualItemName.trim();

    // check for no change when edited.
    if (originalManualGroupItemName === _manualItemName) {
      handleClose();
      return;
    }

    if (_manualItemName.length === 0) {
      setErrorText("This field is required!");
      return;
    }

    let regex = /^[\w \_\-\(\)\s]+$/gi;
    if (!regex.test(_manualItemName)) {
      setErrorText(
        "Only allowed characters are alphanumeric, space, -, _, (, ) for Group Name"
      );
      return;
    }

    // check existing name
    if (
      groupNameResolved
        .filter(
          (i) =>
            i.name.toLowerCase() !== originalManualGroupItemName.toLowerCase()
        )
        .find((i) => i.name.toLowerCase() === _manualItemName.toLowerCase())
    ) {
      setErrorText("An element with same name already exists.");
      return;
    }
    originalManualGroupItemName
      ? editManualGroupItem(_manualItemName)
      : createManualGroupItem(_manualItemName);
    handleClose();
    dispatch(
      showMessage({
        message: originalManualGroupItemName
          ? "Edited successfully!"
          : "Created successfully",
        variant: "success",
      })
    );
  };

  const deleteItem = useCallback(
    (item: any) => {
      let _droppedItems = { ...droppedItems };
      if (item.targetDataId) {
        unset(_droppedItems, [
          item.targetDataId,
          "data",
          item.type,
          item.dropId,
        ]);
        if (
          Object.keys(_droppedItems[item.targetDataId]["data"][item.type])
            .length === 0
        ) {
          unset(_droppedItems, [item.targetDataId, "data", item.type]);
          if (
            Object.keys(_droppedItems[item.targetDataId]["data"]).length === 0
          ) {
            unset(_droppedItems, [item.targetDataId]);
          }
        }
        setDroppedItem(_droppedItems);
      }
      let newSelectedItems = { ...selectedItems };
      newSelectedItems[item.type] = newSelectedItems[item.type]?.filter(
        (i: any) => i !== item.id
      );
      setSelectedItems(newSelectedItems);
    },
    [droppedItems, selectedItems]
  );

  const handleManualItemDeleteClick = (item: any) => {
    if (window.confirm("Are you sure you want to delete the group?")) {
      if (droppedItems[item.id]?.["data"]) {
        let newSelectedItems = { ...selectedItems };
        Object.values(droppedItems[item.id]["data"]).map((i: any) =>
          Object.values(i).map((j: any) => {
            newSelectedItems[j.type] = newSelectedItems[j.type]?.filter(
              (k: any) => k !== j.id
            );
          })
        );
        let _droppedItems = { ...droppedItems };

        unset(_droppedItems, [item.name]);
        setDroppedItem(_droppedItems);
        setSelectedItems(newSelectedItems);
        dispatch(
          showMessage({
            message: "Deleted successfully!",
            variant: "success",
          })
        );
      }
      setGroupNameResolved(groupNameResolved.filter((i) => i !== item));
    }
  };

  const checkTermInGroup = (group: any): boolean => {
    const _lowerSearchText = searchTextGroups.trim().toLowerCase();

    return (
      includes(group.name.toLowerCase(), _lowerSearchText) ||
      some(get(droppedItems, [group.id, "data"]), (item) =>
        some(item, (v) => includes(v.name.toLowerCase(), _lowerSearchText))
      )
    );
  };

  const renderGroupNameResolved = () => {
    if (loadingGroupName)
      return (
        <Skeleton
          sx={{ bgcolor: "grey.100", my: 1.5, mx: 2, borderRadius: 3 }}
          variant="rectangular"
          animation="wave"
          height={250}
          width={"100%"}
        />
      );
    if (!groupNameResolved.length)
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            background: theme.custom.profileBackgroundColor,
            minHeight: "250px",
            borderRadius: 3,
            mt: 1,
          }}
        >
          <Grid item>
            <Typography variant="inter_p_500_14">
              Please add a group to get started.
            </Typography>
          </Grid>
        </Grid>
      );

    return groupNameResolved
      .filter((i) => (showDeletedGroups ? !i.name.includes("(deleted)") : i))
      .filter((i) => (searchTextGroups === "" ? i : checkTermInGroup(i)))
      .map((i) => {
        return (
          <Grid item>
            <Box sx={{ mb: 1 }}>
              <Typography variant="inter_p_600_14">{i.name} </Typography>
              {i.isManual ? (
                <>
                  <Button
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={() => {
                      setManualItemName(i.name);
                      setOriginalManualGroupItemName(i.name);
                      setOpen(true);
                    }}
                    variant="outlined"
                  >
                    <Typography variant="inter_500_10">Edit</Typography>
                  </Button>
                  <Button
                    size="small"
                    sx={{ ml: 1 }}
                    onClick={() => handleManualItemDeleteClick(i)}
                    variant="outlined"
                    color="error"
                  >
                    <Typography variant="inter_500_10">Delete</Typography>
                  </Button>
                </>
              ) : (
                ""
              )}
            </Box>
            <DropTarget
              name={i.name}
              id={i.id}
              accepts={Object.keys(allPossibleKeys)}
              onDrop={onDrop}
              isEmpty={
                droppedItems[i.id] === undefined ||
                Object.keys(droppedItems[i.id]).length === 0
              }
              item={i}
            >
              <div>
                {droppedItems[i.id] &&
                  droppedItems[i.id]["data"] &&
                  Object.keys(droppedItems[i.id]["data"]).map((k: any) => {
                    let items: any = droppedItems[i.id]["data"][k];
                    return (
                      <div>
                        <div>
                          <Typography variant="inter_500_10">
                            {allPossibleKeys[k]}
                          </Typography>
                        </div>
                        {Object.keys(items).map((item_key: string) => {
                          let i = items[item_key];
                          return (
                            <>
                              <DraggableItem
                                type={i.type}
                                name={i.name}
                                style={{ display: "inline-block" }}
                                id={i.id}
                                item={i}
                              >
                                <CustomChip
                                  key={i.id}
                                  extraProps={{
                                    labelText: i.name,
                                    key: i.name,
                                    onDelete: () => {
                                      deleteItem(i);
                                    },
                                  }}
                                  extraSX={{
                                    m: 0.5,
                                    border: `1px solid ${theme.custom.borderColor}`,
                                    backgroundColor: "white",
                                    color: theme.palette.text.primary,
                                    borderRadius: "6px",
                                  }}
                                  labelProps={{
                                    textOverflow: "ellipsis",
                                    maxWidth: "200px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                  }}
                                  closeIconProps={{
                                    fill: theme.palette.text.primary,
                                    fontSize: "18px !important",
                                    marginRight: "12px !important",
                                  }}
                                />
                              </DraggableItem>
                            </>
                          );
                        })}
                      </div>
                    );
                  })}
              </div>
            </DropTarget>
          </Grid>
        );
      });
  };

  const renderGroupItemsResolved = () => {
    if (loadingGroupItems)
      return (
        <Skeleton
          sx={{ bgcolor: "grey.100", my: 1.5, mx: 2, borderRadius: 3 }}
          variant="rectangular"
          animation="wave"
          height={250}
          width={"100%"}
        />
      );
    if (!groupItemsResolved.length)
      return (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{
            background: theme.custom.profileBackgroundColor,
            height: "250px",
            borderRadius: 3,
          }}
        >
          <Grid item>
            <Typography variant="inter_p_500_14">
              Please choose an item to show.
            </Typography>
          </Grid>
        </Grid>
      );
    return groupItemsResolved
      .filter((i) => !selectedItems[selectedGroupItems]?.includes(i.id))
      .filter((i) => (showDeletedItems ? !i.name.includes("(deleted)") : i))
      .filter((i) =>
        searchTextItems === ""
          ? i
          : i.name.toLowerCase().includes(searchTextItems.trim().toLowerCase())
      )
      .map((i) => {
        return (
          <DraggableItem
            type={selectedGroupItems}
            name={i.name}
            id={i.id}
            item={i}
          >
            <Typography variant="inter_500_12" style={{ color: "black" }}>
              {i.name}
            </Typography>
          </DraggableItem>
        );
      });
  };

  const [open, setOpen] = useState(false);
  const [manualItemName, setManualItemName] = useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setManualItemName("");
    setErrorText("");
    setOriginalManualGroupItemName("");
  };
  return (
    <Box
      sx={{
        bgcolor: "background.paper",
        p: 4,
        width: "1000px",
        zIndex: (th: any) => th.zIndex.drawer + 3000,
      }}
    >
      <Grid container justifyContent={"space-between"} alignItems={"center"}>
        <Grid item>
          <Grid container alignItems={"center"}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              {userMappingMeta.title}
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <Button onClick={() => handleCloseModal()}>
            <CloseIcon />
          </Button>
        </Grid>
      </Grid>
      <Grid container mt={2} justifyContent={"space-between"}>
        <Grid item xs={5.8}>
          <Grid
            container
            sx={{ mt: 2 }}
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Grid item xs={6}>
              <Select
                size="small"
                displayEmpty
                value={selectedGroupItems}
                MenuProps={{
                  style: { zIndex: 3000 },
                }}
                fullWidth
                onChange={(e) => handleGroupItemsChange(e.target.value)}
              >
                {Object.entries(allPossibleKeys).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                      {value}
                    </Typography>
                  </MenuItem>
                ))}
                <MenuItem key="" value="">
                  <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                    None
                  </Typography>
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6} pl={2}>
              <FormGroup>
                <FormControlLabel
                  componentsProps={{
                    typography: { variant: "inter_p_500_14" },
                  }}
                  control={
                    <Checkbox
                      onChange={(e) => setShowDeletedItems(e.target.checked)}
                    />
                  }
                  label="Hide deleted items"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid container py={1} mt={2}>
            <Grid item xs={3}>
              <Typography variant="inter60016">Items</Typography>
            </Grid>
            <Grid item xs={9} sx={{ textAlign: "end" }}>
              <Typography variant="inter_p_500_14">
                Move items to groups on the right
              </Typography>
            </Grid>
          </Grid>
          <Grid item my={2}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <Search
                sx={{
                  minHeight: "100%",
                  color: "action.active",
                  mr: 1,
                  my: 0.5,
                }}
              />

              <TextField
                placeholder="Search items"
                focused
                variant="standard"
                fullWidth
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "lightgray",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "lightgray",
                  },
                }}
                value={searchTextItems}
                onChange={(e) => setSearchTextItems(e.target.value)}
              />
              <CloseIcon
                sx={{ color: "action.active", ml: 1, my: 0.5 }}
                onClick={(e) => setSearchTextItems("")}
              />
            </Box>
          </Grid>

          <Grid container mt={3} sx={{ maxHeight: "400px", overflow: "auto" }}>
            {renderGroupItemsResolved()}
          </Grid>
        </Grid>
        <Grid item>
          <Divider variant="fullWidth" orientation="vertical"></Divider>
        </Grid>
        <Grid item xs={5.8} mt={2}>
          <Grid container>
            <Grid item xs={6}>
              <Select
                fullWidth
                size="small"
                displayEmpty
                value={selectedGroupName}
                MenuProps={{
                  style: { zIndex: 3000 },
                }}
                onChange={(e) => onGroupNameChange(e.target.value)}
              >
                {Object.entries(allPossibleKeys).map(([key, value]) => (
                  <MenuItem key={key} value={key}>
                    <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                      {value}
                    </Typography>
                  </MenuItem>
                ))}
                <MenuItem key="" value="">
                  <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                    None
                  </Typography>
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6} pl={2}>
              <FormGroup>
                <FormControlLabel
                  componentsProps={{
                    typography: { variant: "inter_p_500_14" },
                  }}
                  control={
                    <Checkbox
                      onChange={(e) => setShowDeletedGroups(e.target.checked)}
                    />
                  }
                  label="Hide deleted items"
                />
              </FormGroup>
            </Grid>
          </Grid>
          <Grid
            container
            sx={{ mt: 2 }}
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Grid item sx={{ width: "100%" }}>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item xs={6}>
                  <Typography variant="inter60016">Groups</Typography>
                </Grid>

                <Grid item xs={6}>
                  <Grid container p={0} justifyContent={"end"}>
                    <Grid item>
                      <Button onClick={handleClickOpen}>
                        <AddIcon />
                        <Typography variant="poppins_p_600_14">
                          Add new group
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                  <Dialog
                    hideBackdrop
                    open={open}
                    onClose={handleClose}
                    sx={{
                      zIndex: (th: any) => th.zIndex.drawer + 1000,
                    }}
                  >
                    <DialogTitle id="responsive-dialog-title">
                      <Typography variant="inter_p_600_16">
                        {originalManualGroupItemName !== ""
                          ? "Edit Group Name"
                          : "Add new group"}
                      </Typography>
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <TextField
                          inputProps={{ maxLength: 128 }}
                          autoFocus
                          margin="dense"
                          placeholder="Enter group name"
                          value={manualItemName}
                          onChange={(e: any) =>
                            setManualItemName(e.target.value)
                          }
                          fullWidth
                          variant="outlined"
                          error={errorText !== ""}
                          helperText={errorText}
                        />
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button autoFocus onClick={handleClose}>
                        Cancel
                      </Button>
                      <Button onClick={addManualGroup} autoFocus>
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                </Grid>
              </Grid>
            </Grid>
            <Grid item></Grid>
          </Grid>
          <Grid item my={2}>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <Search sx={{ color: "action.active", mr: 1, my: 0.5 }} />
              <TextField
                placeholder="Search for groups and items"
                focused
                variant="standard"
                fullWidth
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "lightgray",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "lightgray",
                  },
                }}
                value={searchTextGroups}
                onChange={(e) => setSearchTextGroups(e.target.value)}
              />
              <CloseIcon
                sx={{ color: "action.active", ml: 1, my: 0.5 }}
                onClick={(e) => setSearchTextGroups("")}
              />
            </Box>
          </Grid>
          <Grid
            container
            mt={0}
            sx={{ maxHeight: "460px", overflow: "auto" }}
            spacing={groupNameResolved.length ? 2 : 0}
          >
            {renderGroupNameResolved()}
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent={"end"} mt={3}>
        <Grid item>
          <LoadingButton
            loading={savingUM !== null}
            onClick={handleSave}
            variant="contained"
          >
            Save
          </LoadingButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default memo(UserMappingGroupEditor);
