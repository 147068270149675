import { environment } from "../environments/environment-dev";

export const GET_ROLES = `${environment.connectorApiUrl}/permissions/roles`;

export const GET_STEPS = (role: string) =>
  `${environment.connectorApiUrl}/permissions/steps?role=${role}`;

export const GET_MEMBER_DETAILS = (
  memberUUIDOrEmail: string,
  payloadType: string
) => {
  let payload = "";
  if (payloadType === "uuid") {
    payload = `uuid.${memberUUIDOrEmail}`;
  } else if (payloadType === "email") {
    payload = `email.${Buffer.from(memberUUIDOrEmail).toString("base64")}`;
  }
  return `${environment.connectorApiUrl}/permissions/members/${payload}`;
};

export const GET_USER_PROFILE = `${environment.connectorApiUrl}/permissions/me`;

export const SAVE_MEMBER_DETAILS = `${environment.connectorApiUrl}/permissions/member`;

export const PERMISSIONS_META = (permission_slug: string, role: string) =>
  `${environment.connectorApiUrl}/permissions/${permission_slug}/meta?role_slug=${role}`;
