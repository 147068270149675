import styled from "@emotion/styled";
import { Switch, SwitchProps, Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import React, { useState } from "react";
import { theme } from "../../theme";

interface Props {
  extraProps?: SwitchProps;
  backgroundColor: string;
  trackColor: string;
  active: boolean;
  onToggle?: any;
  disabled?: boolean;
  extraSX: SxProps<Theme>;
}

const CustomSwitch = (props: Props) => {
  let toggle = props.onToggle;
  const [checked, setChecked] = useState(props.active);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggle(event.target.checked);
    setChecked(event.target.checked);
  };

  const CustomSwitchComponent = styled(Switch)(() => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: theme.custom.whiteColor,
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor: props.backgroundColor,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: props.trackColor,
      boxSizing: "border-box",
    },
  }) as any);
  return (
    <CustomSwitchComponent
      sx={{
        ...(props?.extraSX || {}),
      }}
      disabled={props.disabled ? props.disabled : false}
      checked={props.active}
      inputProps={{ "aria-label": "CustomSwitch" }}
      onChange={handleChange}
      {...(props.extraProps || {})}
    />
  );
};
export default CustomSwitch;
