import { isEqual } from "lodash";
import { useEffect, useRef } from "react";
import { usePrevious } from "react-use";

function deepCompareDiff(currentObj: any, prevObj: any) {
  // fast check for equality
  if (isEqual(currentObj || {}, prevObj || {})) return {};

  // Checking only upto level 1. Needs refactor to consider further
  // levels down and according to type if array.
  let diffArray = Object.keys(currentObj || {}).reduce(
    (_diffArray: any, key: any) => {
      if (prevObj[key] === currentObj[key]) return _diffArray;
      return { ..._diffArray, ...{ [key]: currentObj[key] } };
    },
    {}
  );

  return diffArray;
}

function useDeepCompareDiffEffect(
  callback: (_diffArray: any) => any,
  deps: any
) {
  let prevDeps = usePrevious(deps);
  useEffect(() => {
    let _diff = {};
    if (Array.isArray(deps) && deps.length === 1) {
      _diff = deepCompareDiff(deps[0], (prevDeps && prevDeps[0]) || {});
    }
    // Note: Use hooks does equality check on obj instead of deep compare
    // on value so if deps is an object, it will always fire even if object is not
    // changed
    if (_diff) {
      callback(_diff);
    }
  }, deps);
}

export { useDeepCompareDiffEffect };