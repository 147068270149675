import { EditNote } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PresentationIcon from "../../../assets/Play icon 1.png";
import BackButton from "../../../assets/back_button.png";
import CloudIcon from "../../../assets/cloud.png";
import PrintIcon from "../../../assets/print.png";
import RedoIcon from "../../../assets/redo.png";
import UndoIcon from "../../../assets/undo.png";
import ZoomIcon from "../../../assets/zoom.png";
import {
  createReportLink,
  saveTemplate,
  updateReportName,
} from "../../../services/impact-report-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import { fontInter, theme } from "../../../theme";
import PrintStatusPopover from "../../atoms/PrintStatusPopover";
import SaveTemplateDialog from "./SaveTemplateDialog";
import ShareDialog from "./ShareDialog";

interface Props {
  reportName: string;
  reportId: any;
  saving: boolean;
  onUndo: Function;
  onRedo: Function;
  historyAvailable: any;
  onPrint: Function;
  progress: number;
  openedPrintStatusPopover: boolean;
  closePrintStatusPopover: Function;
  isPrinting: boolean;
  onZoomChange: any;
  zoom: any;
  is_editable: boolean;
  is_exportable: boolean;
  is_shareable: boolean;
  reportDataRef: any;
  resetSelection: Function;
  reportAttributes: any;
  onResetSelection?: Function;
}

const EditorToolbar = ({
  reportName,
  reportId,
  saving,
  onUndo,
  onRedo,
  historyAvailable,
  onPrint,
  progress,
  openedPrintStatusPopover,
  closePrintStatusPopover,
  isPrinting,
  onZoomChange,
  zoom,
  is_exportable,
  resetSelection,
  is_shareable,
  reportDataRef,
  reportAttributes,
  onResetSelection,
}: Props) => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = React.useState("");
  const dispatch = useAppDispatch();
  const [reportTitle, setReportTitle] = useState("");
  const [toolbarReportTitleState, setToolbarReportTitleState] = useState("");
  const [editTitle, setEditTitle] = useState(false);
  const [_zoom, setZoom] = useState(zoom);
  const printStatusPopoverAnchor = useRef(null);

  useEffect(() => {
    setReportTitle(reportName);
    setToolbarReportTitleState(reportName);
  }, [reportName]);

  const handleChange = (event: ChangeEvent<any>) => {
    setReportTitle(event.target.value);
  };

  const updateZoom = (e: any) => {
    setZoom(e.target.value);
    onZoomChange(e.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const openSaveTemplate = () => {
    setShowDialog("template");
    setAnchorEl(null);
  };

  const onSaveTemplate = (
    setSaving: Function,
    setError: Function,
    templateName: string,
    handleClose: Function
  ) => {
    setError("");
    if (!reportAttributes?.templateId) {
      // validations for new template
      if (!templateName.length) {
        setError("Template name cannot be empty");
        return;
      }
      if (templateName.length > 128) {
        setError("Template name cannot be more than 128 characters");
        return;
      }
    }
    setSaving(true);
    saveTemplate(
      JSON.stringify(reportDataRef.current.pages),
      reportAttributes?.templateId ? toolbarReportTitleState : templateName,
      reportAttributes
    ).then((resp) => {
      if (resp && resp.status === 200) {
        dispatch(
          showMessage({
            message: "Saved Template!",
            variant: "success",
            extraProps: {
              anchorOrigin: { vertical: "top", horizontal: "right" },
            },
          })
        );
        handleClose();
        if (reportAttributes?.templateId) navigate("/create-impact-report");
      }
      if (resp && resp.status === 400 && resp.data) {
        setError(resp.data);
      }
      setSaving(false);
    });
  };

  const [generatingLinkPresentation, setGeneratingLinkPresentation] =
    useState(false);

  const openPresentation = () => {
    setGeneratingLinkPresentation(true);
    createReportLink(reportId, {
      password: "",
      is_public: true,
    }).then((resp) => {
      if (resp && resp.status === 201) {
        window.open(
          new URL(resp.data.full_link_presentation[0]).href,
          "_blank"
        );
      }
      if (resp && resp.status === 400 && resp.data.emails) {
        dispatch(
          showMessage({
            message: resp.data.emails[0],
            extraProps: {
              anchorOrigin: { vertical: "top", horizontal: "right" },
            },
          })
        );
      }
      setGeneratingLinkPresentation(false);
    });
  };
  const updateTitle = () => {
    if (reportTitle) {
      let regex = /^[\w \_\-\(\)\s]+$/gi;
      if (!regex.test(reportTitle) && reportTitle.length > 0) {
        dispatch(
          showMessage({
            message:
              "Only allowed character alphanumeric, space, -, _, (, ) for Report name",
          })
        );
        return;
      }
      let uid = reportId.split("_").at(-1) || "";
      let obj = {
        uid: uid,
        name: reportTitle,
      };
      updateReportName(uid, obj).then((res) => {
        if (res.status === 200) {
          dispatch(
            showMessage({
              message: reportAttributes?.templateId
                ? "Template draft name updated."
                : "Report name updated.",
              variant: "success",
            })
          );
          setToolbarReportTitleState(reportTitle);
          setEditTitle(false);
        }
      });
    } else {
      dispatch(
        showMessage({
          message: "Invalid name",
        })
      );
    }
  };

  const onCancel = () => {
    setReportTitle(toolbarReportTitleState);
    setEditTitle(false);
  };

  const handleBackButtonClick = () => {
    if (onResetSelection) {
      onResetSelection();
    }
    reportAttributes?.templateId
      ? setShowDialog("template")
      : navigate("/impact-report");
  };

  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"space-between"}
      px={2}
      sx={{ position: "relative" }}
    >
      <Stack
        direction="row"
        alignItems={"center"}
        justifyItems={"space-between"}
        spacing={4}
      >
        <Box>
          <img
            onClick={handleBackButtonClick}
            src={BackButton}
            alt="Back"
            style={{
              maxHeight: "20px",
              cursor: "pointer",
              position: "relative",
              marginTop: "8px",
            }}
          ></img>
        </Box>
        <Box>
          <ButtonGroup
            variant="text"
            sx={{
              ".MuiButtonGroup-groupedText	": {
                borderRight: `0px !important`,
              },
            }}
          >
            <Button onClick={() => onUndo()} disabled={!historyAvailable.undo}>
              <Tooltip
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, 10],
                      },
                    },
                  ],
                }}
                title="Undo"
              >
                <img
                  src={UndoIcon}
                  alt="Back"
                  style={{
                    maxHeight: "20px",
                    cursor: "pointer",
                    marginRight: "20%",
                  }}
                ></img>
              </Tooltip>
            </Button>
            <Button onClick={() => onRedo()} disabled={!historyAvailable.redo}>
              <Tooltip
                PopperProps={{
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, 10],
                      },
                    },
                  ],
                }}
                title="Redo"
              >
                <img
                  src={RedoIcon}
                  alt="Back"
                  style={{
                    maxHeight: "20px",
                    cursor: "pointer",
                  }}
                ></img>
              </Tooltip>
            </Button>
          </ButtonGroup>
        </Box>
        <Box>
          <Stack spacing={2} direction="row" alignItems="center">
            <Slider
              size="small"
              min={0.1}
              sx={{
                color: theme.custom.opaqueWhite,
                height: "7px",
                minWidth: "200px",
                ".MuiSlider-valueLabel": {
                  display: "none",
                },
              }}
              max={5}
              step={0.1}
              valueLabelDisplay="auto"
              value={zoom}
              onChange={updateZoom}
            />
            <img
              src={ZoomIcon}
              alt="ZoomIcon"
              style={{
                maxHeight: "1em",
                cursor: "pointer",
              }}
            ></img>
            <span>{Math.round(zoom * 100)}%</span>
          </Stack>
        </Box>
      </Stack>
      {!editTitle && (
        <Stack direction="row" alignItems={"center"} justifyItems={"center"}>
          {reportAttributes?.templateId && (
            <Chip
              size="small"
              sx={{
                mr: 1,
                borderRadius: 1,
                backgroundColor: theme.custom.KPIMoleculeBorderColor,
              }}
              color="secondary"
              label={
                <Grid
                  container
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                    alignContent: "center",
                    width: "auto",
                  }}
                >
                  <EditNote
                    sx={{
                      color: theme.custom.primaryDarkColor,
                      height: "20px",
                    }}
                  />
                  <Typography
                    variant="poppins_600_12"
                    color={theme.custom.primaryDarkColor}
                  >
                    DRAFT
                  </Typography>
                </Grid>
              }
              variant="filled"
            ></Chip>
          )}
          <Tooltip title={reportTitle}>
            <Typography
              noWrap
              onClick={() => {
                setEditTitle(true);
              }}
              variant="inter_p_600_16"
              sx={{ mr: 1, maxWidth: 200 }}
            >
              {reportTitle}
            </Typography>
          </Tooltip>
          <img
            src={CloudIcon}
            alt="CloudIcon"
            style={{
              maxHeight: "25px",
              cursor: "pointer",
              filter: saving ? "contrast(0.5)" : "",
            }}
          ></img>
          <div style={{ width: "64px" }}>
            <Fade in={saving} timeout={{ exit: 2500 }}>
              <Typography variant="inter_500_12" sx={{ ml: 1 }} color={"white"}>
                Saving ...
              </Typography>
            </Fade>
          </div>
        </Stack>
      )}
      {editTitle && (
        <ClickAwayListener onClickAway={onCancel}>
          <Stack direction="row" alignItems={"center"} justifyItems={"center"}>
            <TextField
              variant="standard"
              value={reportTitle}
              inputProps={{
                style: {
                  background: theme.custom.whiteColor,
                  fontFamily: fontInter,
                  fontSize: "14px",
                  fontWeight: 500,
                },
                maxLength: 200,
              }}
              onChange={handleChange}
            />

            <IconButton
              onClick={updateTitle}
              aria-label="delete"
              sx={{ color: theme.custom.whiteColor }}
            >
              <DoneIcon />
            </IconButton>
            <IconButton
              onClick={onCancel}
              aria-label="delete"
              sx={{ color: theme.custom.whiteColor }}
            >
              <CloseIcon />
            </IconButton>
            <div style={{ width: "64px" }}>
              <Fade in={saving} timeout={{ exit: 2500 }}>
                <Typography variant="inter_500_12" sx={{ ml: 1 }}>
                  Saving ...
                </Typography>
              </Fade>
            </div>
          </Stack>
        </ClickAwayListener>
      )}
      <Stack direction="row" alignItems={"center"} justifyItems={"end"}>
        <Tooltip
          ref={printStatusPopoverAnchor}
          title={
            is_exportable
              ? "Print Report"
              : "You don't have access to this action"
          }
        >
          <LoadingButton
            loading={isPrinting}
            sx={{
              height: 1,
              display: reportAttributes?.templateId ? "none" : "block",
              minWidth: "50px",
            }}
            onClick={() => {
              if (is_exportable) {
                onPrint(true);
              }
            }}
          >
            <img
              src={PrintIcon}
              alt="Back"
              style={{
                maxHeight: "25px",
                opacity: is_exportable ? 1 : 0.5,
                cursor: "pointer",
                display: isPrinting ? "none" : "block",
              }}
            ></img>
          </LoadingButton>
        </Tooltip>
        <PrintStatusPopover
          id={`print_status`}
          progress={progress}
          open={openedPrintStatusPopover}
          closePopup={closePrintStatusPopover}
          anchorEl={printStatusPopoverAnchor.current}
        ></PrintStatusPopover>
        <Tooltip
          title={
            is_shareable
              ? "Show presentation"
              : "You don't have access to this action"
          }
        >
          <LoadingButton
            sx={{
              height: 1,
              minWidth: "54px",
            }}
            loading={generatingLinkPresentation}
            onClick={() => {
              if (is_shareable) {
                openPresentation();
              }
            }}
          >
            {generatingLinkPresentation ? (
              ""
            ) : (
              <img
                src={PresentationIcon}
                alt="Back"
                style={{
                  maxHeight: "25px",
                  cursor: "pointer",
                }}
              ></img>
            )}
          </LoadingButton>
        </Tooltip>
        <Box sx={{ display: reportAttributes?.templateId ? "none" : "block" }}>
          <Tooltip
            title={
              is_shareable ? "Share" : "You don't have access for this action"
            }
          >
            <Typography
              onClick={() => {
                if (is_shareable) {
                  setShowDialog("share");
                  resetSelection();
                }
              }}
              variant="poppins_p_600_14"
              sx={{
                mr: 2,
                color: theme.impactReportingColors.shareTextColor,
                opacity: is_shareable ? 1 : 0.5,
                cursor: "pointer",
              }}
            >
              Share
            </Typography>
          </Tooltip>
          <ShareDialog
            reportId={reportId}
            showDialog={showDialog}
            setShowDialog={setShowDialog}
          />
        </Box>

        <Box sx={{ display: reportAttributes?.templateId ? "block" : "none" }}>
          <Typography
            onClick={() => {
              if (is_shareable) {
                setShowDialog("template");
              }
            }}
            variant="poppins_p_600_14"
            sx={{
              mr: 2,
              color: theme.impactReportingColors.shareTextColor,
              opacity: is_shareable ? 1 : 0.5,
              cursor: "pointer",
            }}
          >
            Update template
          </Typography>
        </Box>

        <Box sx={{ display: reportAttributes?.templateId ? "none" : "block" }}>
          <>
            <Button
              onClick={handleClick}
              sx={{
                mr: 2,
                color: theme.impactReportingColors.shareTextColor,
                cursor: "pointer",
              }}
            >
              <Typography variant="poppins_p_600_14">More</Typography>
              <KeyboardArrowDownIcon sx={{ fontSize: "24px" }} />
            </Button>
            <Menu
              transformOrigin={{
                vertical: -20,
                horizontal: "right",
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={openSaveTemplate}
                sx={{ p: 2, width: "214px" }}
              >
                <Typography
                  variant="inter_p_500_14"
                  sx={{ color: "grey !important" }}
                >
                  Save as a template
                </Typography>
              </MenuItem>
            </Menu>
          </>
        </Box>
        <SaveTemplateDialog
          showDialog={showDialog}
          onSaveTemplate={onSaveTemplate}
          setShowDialog={setShowDialog}
          reportAttributes={reportAttributes}
        />
      </Stack>
    </Grid>
  );
};

export default memo(EditorToolbar);
