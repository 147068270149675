import LoadingButton from "@mui/lab/LoadingButton";
import {
  Avatar,
  Button,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { ReactElement, useState } from "react";
import DeleteIcon from "../../assets/deleteIcon.png";
import TripleDot from "../../assets/fi-rr-menu-dots.png";
import MappingIcon from "../../assets/fi-rr-settings-sliders.png";
import GoogleLoginButton from "../../assets/btn_google_signin_light_normal_web@2x.png";

import {
  deleteConnector,
  getConnectorAuthUrl,
  triggerResync,
} from "../../services/connector-service";
import {
  disconnectConnector,
  setConnectionInProgress,
  setConnectorSyncStatusSingleBySlug,
} from "../../store/connector-store";
import { showMessage } from "../../store/error-handler-store";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { theme, fontPoppins } from "../../theme";
import { ListConnectorAPIResponseModel } from "../../Types/ListConnectorAPIResponseModel";
import {
  generateStatusDisplay,
  popupCenter,
} from "../../utils/ConnectorUIUtils";
import RefreshIcon from "@mui/icons-material/Refresh";
import NavigateIcon from "../../assets/greater.png";
import { useNavigate } from "react-router-dom";

interface IProps {
  item: ListConnectorAPIResponseModel;
  refreshList: Function;
}

function ConnectorListItem({ item, refreshList }: IProps): ReactElement {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // const loading = useAppSelector((state) => {
  //   return state.connector.connectors.find((v) => v.id === item.id)?.connecting;
  // });
  const [loading, setLoading] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [deleting, setDeleting] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  function connectButtonHandler(
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    dispatch(setConnectionInProgress({ slug: item.slug, status: true }));
    getConnectorAuthUrl(item.slug)
      .then((resp) => {
        if (resp && resp.status === 200) {
          popupCenter(
            window,
            window.screen,
            resp.data.auth_url,
            "Connect",
            600,
            600
          );
        }
      })
      .catch((err) => {
        dispatch(setConnectionInProgress({ slug: item.slug, status: false }));
      });
  }
  const handleDelete = (ev: any) => {
    ev.preventDefault();
    handleClose();
    let deleteMessage = "";
    if (item.slug === "quickbooks") {
      deleteMessage =
        "On delete of this connection all your budgets along with synced data will also be deleted, are you sure you want to continue?";
    } else {
      deleteMessage =
        "On delete of this connection all your synced data will also be deleted, are you sure you want to continue?";
    }
    if (window.confirm(deleteMessage)) {
      setDeleting(true);
      deleteConnector(item.slug)
        .then((resp) => {
          if (resp && resp.data && resp.data.status === "success") {
            // close popup
            handleClose();
            dispatch(disconnectConnector({ slug: item.slug }));
            refreshList();
            dispatch(
              showMessage({
                message: "Deleted successfully",
                variant: "success",
              })
            );
            setDeleting(false);
          } else {
            dispatch(
              showMessage({ message: "Some error occurred", variant: "error" })
            );
            setDeleting(false);
          }
        })
        .catch((e) => {
          dispatch(
            showMessage({ message: "Some error occurred", variant: "error" })
          );
          setDeleting(false);
        });
    }
  };

  const handleResync = (ev: any) => {
    ev.preventDefault();
    triggerResync(item.slug).then((resp) => {
      if (resp && resp.data && resp.data.status === "success") {
        // close popup
        handleClose();
        dispatch(
          showMessage({
            message: "Resync triggered successfully",
            variant: "success",
          })
        );
        dispatch(
          setConnectorSyncStatusSingleBySlug({
            slug: item.slug,
            status: "running",
          })
        );
      } else {
        showMessage({ message: "Resync failed", variant: "error" });
      }
    });
  };

  const renderButtonText = () => {
    if (deleting) {
      return "Deleting...";
    }
    if (item.linked) {
      {
        if (item.needs_relinking) return "Reconnect";
        else return "Connected";
      }
    } else return "Connect";
  };

  const navigateToConnectorsList = (slug: string) => {
    if (slug === "excel") {
      navigate("/connectors/excel/list");
    } else if (slug === "googlesheet") {
      navigate("/connectors/googlesheet/list");
    }
  };

  const googleIconSX = {
    backgroundImage: `url(${GoogleLoginButton})`,
    backgroundSize: "cover",
    height: 36,
    width: 148,
    p: 0,
  };
  const id = open ? "simple-popover" : undefined;
  return (
    <Paper
      variant="outlined"
      sx={{
        px: 1,
        py: 2,
        my: 2,
        borderColor: theme.custom.KPIMoleculeBorderColor,
        borderRadius: "6px",
      }}
    >
      <ListItem
        className={`connectors ${item.name.toLowerCase()} ${
          item.linked ? "connected" : "not-connected"
        }`}
      >
        <ListItemAvatar>
          <Avatar src={item.logo.toString()}></Avatar>
        </ListItemAvatar>
        <ListItemText>
          <Typography variant="h6" component="div">
            {item.name}
          </Typography>
          {item.connection_user && item.connection_user?.length > 0 && (
            <Typography
              sx={{ pb: 0.5 }}
              variant="inter_400_14"
              color="grey.600"
              component="div"
            >
              {item.connection_user}
            </Typography>
          )}
          {(item.slug === "excel" || item.slug === "googlesheet") && (
            <Typography
              sx={{ color: theme.custom.primaryDarkColor }}
              variant="inter_400_14"
              component="div"
            >
              {item?.number_of_connections} Workbooks
            </Typography>
          )}
        </ListItemText>
        {item.slug !== "excel" && item.slug !== "googlesheet" && (
          <Stack direction={"column"} justifyItems="center" alignItems={"end"}>
            <Grid>
              {item.linked && !deleting
                ? generateStatusDisplay(
                    item.needs_relinking ? "auth_failed" : item.sync_status
                  )
                : ""}
            </Grid>
            {item.linked && item.last_successful_sync_time ? (
              <Grid>
                <Typography variant="inter_500_10" color="grey.600">
                  Last synced:{" "}
                  {moment(item.last_successful_sync_time)
                    .local()
                    .format("Do MMMM'YY h:mm A")}
                </Typography>
              </Grid>
            ) : (
              ""
            )}
          </Stack>
        )}
        {item.slug !== "excel" && (
          <LoadingButton
            disabled={
              (!!item.linked && !item.needs_relinking) ||
              deleting ||
              !item.is_creatable
            }
            variant="outlined"
            loading={!!loading}
            onClick={connectButtonHandler}
            sx={{
              ml: 1,
              ...(item.slug === "googlesheet" &&
              (!item.linked || item.needs_relinking) &&
              item.is_creatable
                ? googleIconSX
                : {}),
            }}
          >
            {item.slug === "googlesheet" &&
            (!item.linked || item.needs_relinking) &&
            item.is_creatable
              ? ""
              : renderButtonText()}
          </LoadingButton>
        )}
        {item.slug !== "excel" && (
          <Button
            aria-describedby={id}
            variant="text"
            onClick={handleClick}
            className={`connector-options ${item.slug} all`}
            sx={{ display: item.linked && !deleting ? "block" : "none" }}
          >
            <Grid container alignContent="center" justifyContent={"center"}>
              <img height="24px" width="24px" src={TripleDot} />
            </Grid>
          </Button>
        )}
        {(item.slug === "excel" ||
          (item.slug === "googlesheet" && item.linked)) && (
          <div
            onClick={() => navigateToConnectorsList(item.slug)}
            style={{
              textAlign: "center",
              cursor: "pointer",
              marginLeft: 15,
              display: !item.needs_relinking && !deleting ? "block" : "none",
            }}
          >
            <span
              style={{
                color: theme.custom.popoverLinkColor,
                textTransform: "none",
                marginRight: 30,
                verticalAlign: "middle",
                fontFamily: fontPoppins,
                fontWeight: 600,
                fontSize: 14,
                lineHeight: "21px",
              }}
            >
              Manage files
            </span>
            <img
              style={{ verticalAlign: "middle" }}
              height="13px"
              width="7px"
              src={NavigateIcon}
              alt="Navigate"
            />
          </div>
        )}
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            horizontal: "right",
            vertical: "top",
          }}
        >
          <List sx={{ width: "250px" }}>
            {["quickbooks"].includes(item.slug) &&
            !item.needs_relinking &&
            item.is_usermapping ? (
              <Link href={`${item.slug}/user-mapping/`} underline="none">
                <ListItem
                  className={`connector-options ${item.slug} usermapping`}
                >
                  <ListItemIcon>
                    <img height={24} width={24} src={MappingIcon} />
                  </ListItemIcon>
                  <ListItemText>
                    <Typography variant="poppins_p_600_14" color="primary">
                      View/Edit Usermapping
                    </Typography>
                  </ListItemText>
                </ListItem>
              </Link>
            ) : (
              ""
            )}

            {item.slug !== "googlesheet" &&
            item.linked &&
            !item.needs_relinking &&
            item.is_syncable ? (
              <ListItem
                onClick={(e) => handleResync(e)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemIcon>
                  <RefreshIcon color={"primary"} />
                </ListItemIcon>
                <ListItemText>
                  <Typography variant="poppins_p_600_14">Sync now</Typography>
                </ListItemText>
              </ListItem>
            ) : (
              ""
            )}
            {item.is_deletable && (
              <ListItem
                onClick={(e) => handleDelete(e)}
                sx={{ cursor: "pointer" }}
              >
                <ListItemIcon>
                  <img height={24} width={20} src={DeleteIcon} />
                </ListItemIcon>
                <ListItemText>
                  <Typography
                    variant="poppins_p_600_14"
                    color={theme.custom.primaryGreyColor}
                  >
                    Delete
                  </Typography>
                </ListItemText>
              </ListItem>
            )}
          </List>
        </Popover>
      </ListItem>
    </Paper>
  );
}

export default ConnectorListItem;
