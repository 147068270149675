import { createSlice } from "@reduxjs/toolkit";

const navigationHandler = createSlice({
  name: "navigation-store",
  initialState: {
    slug: "",
    title: "",
  },
  reducers: {
    setNavigationData(state, action) {
      state.slug = action?.payload?.board_slug || "";
      state.title = action?.payload?.title || "";
    },
  },
});

export const { setNavigationData } = navigationHandler.actions;
export default navigationHandler.reducer;
