import { Grid, Typography } from "@mui/material";
import React from "react";
import { fontPoppins } from "../../theme";
import { MetaDataObject } from "../../Types/MetaDataObject";
import { colorPickerHelper } from "../../utils/color-picker-helper";
import { labelNumberFormatter } from "../../utils/dataFormatters";

interface Props {
  chartData: any;
  chartMeta: MetaDataObject;
  kpiRendered: React.Dispatch<React.SetStateAction<boolean>>;
  kpiPosition: number;
}

const NumberComponentWithoutTitle: React.FC<Props> = (chartData) => {
  let data = chartData.chartData;

  chartData.kpiRendered(true);
  return (
    <React.Fragment>
      <Grid
        sx={{
          position: "relative",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <span
          style={{
            color: colorPickerHelper(chartData.kpiPosition || 0),
            textAlign: "center",
            fontFamily: fontPoppins,
            fontSize: "90px",
            lineHeight: "95px",
          }}
        >
          {labelNumberFormatter(data[0]?.value)}
        </span>
        <span
          style={{
            color: colorPickerHelper(chartData.kpiPosition || 0),
            textAlign: "center",
            fontFamily: fontPoppins,
            fontSize: "24px",
            lineHeight: "24px",
          }}
        >
          {data[0]?.number_format}
        </span>
      </Grid>
    </React.Fragment>
  );
};

export default NumberComponentWithoutTitle;
