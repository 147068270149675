import { Icon } from "@iconify/react";
import React, { useRef, useState, useEffect } from "react";

function IconsEditor(props: any) {
  const { attributes, height, width, padding, updateElement } = props;
  const iconsRef = useRef<HTMLDivElement>(null as any);
  const [dimension, setDimension] = useState({
    height: attributes?.height,
    width: attributes?.width,
  });

  useEffect(() => {
    setDimension({ height: height, width: width });
    updateElement({ height: height, width: width }, "post-render");
  }, [height]);

  return (
    <>
      <div style={{ height: "100%", width: "100%" }} ref={iconsRef}>
        <Icon
          icon={`${attributes?.prefix}:${attributes?.title}`}
          height={dimension?.height + "px"}
          width={dimension?.width + "px"}
          color={attributes?.fill}
        />
      </div>
    </>
  );
}

export default React.memo(IconsEditor);
