import { Typography, Button, Grid, Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import TemplateThumbnailComponent from "../../atoms/TemplateThumbnailComponent";
import { theme } from "../../../theme";
import { useNavigate } from "react-router-dom";
import {
  getTemplateLibrary,
  defaultReportSize,
  createReport,
} from "../../../services/impact-report-service";
import { getCompanyId } from "../../../services/userinfo-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import DefaultA4Thumbnail from "../../../assets/thumbnailA4.png";
import DefaultPPTThumbnail from "../../../assets/thumbnailPPT.png";
import CreateNewImpactReport from "./CreateImpactReport";
import InfiniteScroll from "react-infinite-scroll-component";

type Props = {
  audience: string;
  purpose: string;
  userQuery: string;
};

const CreateImpactReportAIPreview = (props: Props) => {
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any | null>(null);
  const [templateList, setTemplateList] = useState<Array<Object>>([]);
  const [loadTemplateLibrary, setLoadTemplateLibrary] = useState(true);
  const [loading, setLoading] = useState(true);

  const page = useRef(1);
  const [hasNext, sethasNext] = useState(true);

  useEffect(() => {
    if (loadTemplateLibrary) {
      setLoading(true);
      if (page.current === 1) setTemplateList([]);
      getTemplateLibrary(page.current, {
        audience: props.audience,
        purpose: props.purpose,
      }).then((resp) => {
        setLoading(false);
        if (resp && resp.data) {
          if (page.current === 1) {
            setTemplateList(resp.data.results || []);
          } else {
            setTemplateList([...templateList, ...resp.data.results]);
          }
          if (resp.data.next) {
            sethasNext(true);
          } else {
            sethasNext(false);
          }
        }
      });
      setLoadTemplateLibrary(false);
    }
  }, [loadTemplateLibrary]);

  const onDeleteTemplate = () => {
    setLoadTemplateLibrary(true);
  };
  const handleTemplateClick = (item: any) => {
    setSelectedTemplate(item);
    setOpenPopup(true);
  };

  const onPopupClose = (popupOpen: boolean) => {
    setOpenPopup(popupOpen);
    setSelectedTemplate(null);
  };

  const loadPages = () => {
    if (!hasNext) return;
    page.current += 1;
    setLoadTemplateLibrary(true);
  };

  const dispatch = useAppDispatch();

  const setEditTemplate = (template: any) => {
    let reportData = {
      name: template?.attributes?.title,
      template_id: template?.id,
      is_template_edit_flow: true,
      company_uid: getCompanyId(),
      attributes: defaultReportSize,
    };
    createReport(reportData).then((res) => {
      if (res.status === 403) {
        dispatch(
          showMessage({
            message:
              "You don’t have access to edit this template. Please reach out to admin to get access",
            variant: "error",
          })
        );
        return;
      }
      if (res.status === 201) {
        navigate(`/impact-report/${res["data"]["name"]}_${res["data"]["uid"]}`);
      }
    });
  };

  const getDefaultThumbnail = (slug: any) => {
    if (slug === "presentation_16_9") return DefaultPPTThumbnail;
    return DefaultA4Thumbnail;
  };

  return (
    <Grid container justifyContent={"center"}>
      {!loading && !templateList.length ? (
        <p style={{ textAlign: "center" }}>
          <Typography
            sx={{ color: theme.custom.primaryGreyColor }}
            variant="inter_p_600_16"
          >
            No templates found. Try changing the filters
          </Typography>
        </p>
      ) : (
        <InfiniteScroll
          dataLength={templateList.length}
          next={loadPages}
          hasMore={hasNext}
          endMessage={
            <div
              style={{
                textAlign: "center",
                marginTop: "32px",
                marginBottom: "16px",
              }}
            >
              <Typography
                sx={{ color: theme.custom.primaryGreyColor }}
                variant="inter_p_600_16"
              >
                No more templates to load
              </Typography>
            </div>
          }
          loader={
            <p style={{ textAlign: "center", marginTop: "32px" }}>
              <Typography
                sx={{ color: theme.custom.primaryGreyColor }}
                variant="inter_p_600_16"
              >
                Loading...
              </Typography>
            </p>
          }
        >
          <Grid container spacing={3} justifyContent={"center"}>
            {templateList.map((i: any) => (
              <Grid item sx={{ cursor: "pointer" }} key={i.id}>
                <TemplateThumbnailComponent
                  onDeleteTemplate={onDeleteTemplate}
                  templateId={i.id}
                  templateObj={i}
                  handleSelect={() => handleTemplateClick(i)}
                  template_icon={
                    i.attributes.attributes.thumbnail ||
                    getDefaultThumbnail(i.attributes.attributes.slug)
                  }
                  templateIsMutable={i.is_mutable}
                  onEditTemplate={() => setEditTemplate(i)}
                  style={{}}
                  variant="large"
                  userQuery={props.userQuery}
                  audience={props.audience}
                  purpose={props.purpose}
                  title={i.attributes.title}
                  pageLen={Object.keys(i.attributes.pages).length}
                />
              </Grid>
            ))}
          </Grid>
        </InfiniteScroll>
      )}
      <CreateNewImpactReport
        close={() => setOpenPopup(false)}
        open={openPopup}
        userQuery={props.userQuery}
        audience={props.audience}
        purpose={props.purpose}
        selectedTemplate={selectedTemplate}
        closePreviewModal={() => onPopupClose(false)}
      />
    </Grid>
  );
};

export default CreateImpactReportAIPreview;
