import { environment } from "../environments/environment-dev";

export const GET_CONNECTORS_URL = `${environment.connectorApiUrl}/list-all-connectors`;

export const GET_CONNECTOR_AUTH_URL = (connectorSlug: string) =>
  `${environment.connectorApiUrl}/${connectorSlug}/authorization-url`;

export const POST_VERIFY_CONNECTOR_URL = (connectorSlug: string) =>
  `${environment.connectorApiUrl}/${connectorSlug}/verify-token`;

export const GET_CONNECTOR_SYNC_STATUS = (connectorSlug: string) =>
  `${environment.connectorApiUrl}/${connectorSlug}/sync-status`;

export const GET_CONNECTOR_SYNC_STATUS_LIST = `${environment.connectorApiUrl}/sync-status-list`;

export const DELETE_CONNECTOR = (connectorSlug: string) =>
  `${environment.connectorApiUrl}/connectors/${connectorSlug}`;

export const TRIGGER_RESYNC = (connectorSlug: string) =>
  `${environment.connectorApiUrl}/connectors/${connectorSlug}/sync`;

export const LIST_GOOGLE_SHEETS = (
  connectorSlug: string,
  nextPageToken: string,
  searchText: string
) =>
  `${environment.connectorApiUrl}/connectors/${connectorSlug}/get-all-spreadsheets?nextPageToken=${nextPageToken}&searchText=${searchText}`;
