import { CopyAll, Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  createReportLink,
  sendIREmails,
} from "../../../services/impact-report-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import { theme } from "../../../theme";
import { setSettingEditActive } from "../../../store/selected-design-element-store";

type Props = {
  showDialog: string;
  reportId: string;
  setShowDialog: CallableFunction;
};

function ShareDialog({ showDialog, reportId, setShowDialog }: Props) {
  const [open, setOpen] = React.useState(false);
  const [emails, setEmails] = React.useState("");
  const [sendingMail, setSendingMail] = React.useState(false);
  const [generatingLink, setGeneratingLink] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [sendMailError, setSendMailError] = React.useState("");
  const [password, setPassword] = React.useState("");
  const dispatch = useAppDispatch();
  const handleClose = () => {
    setOpen(false);
    setShowDialog("");
    setEmails("");
    setPassword("");
  };
  useEffect(() => {
    setOpen(showDialog === "share");
  }, [showDialog]);

  const handleSend = () => {
    setSendingMail(true);
    setSendMailError("");
    sendIREmails(
      reportId,
      _.trim(emails, " ,")
        .split(",")
        .map((i) => _.trim(i)),
      password
    ).then((resp) => {
      if (resp && resp.status === 200) {
        dispatch(
          showMessage({
            message: "Sent Email(s)!",
            variant: "success",
            extraProps: {
              anchorOrigin: { vertical: "top", horizontal: "right" },
            },
          })
        );
        // clear all boxes
        setEmails("");
        setPassword("");
      }
      if (resp && resp.status === 400 && resp.data.emails) {
        setSendMailError(resp.data.emails[0]);
      }
      setSendingMail(false);
    });
  };
  const generateLink = () => {
    setGeneratingLink(true);
    createReportLink(reportId, { password, is_public: true }).then((resp) => {
      if (resp && resp.status === 201) {
        navigator.clipboard.writeText(new URL(resp.data.full_link[0]).href)
          .then(() => {
            dispatch(
              showMessage({
                message: "Link copied to clipboard!",
                variant: "success",
                extraProps: {
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                },
              })
            );
          })
          .catch(() => {
            dispatch(
              showMessage({
                message: "Failed to copy: Document is not focused. Please try again.",
                variant: "error",
                extraProps: {
                  anchorOrigin: { vertical: "top", horizontal: "right" },
                },
              })
            );
          });
      }
      if (resp && resp.status === 400 && resp.data.emails) {
        showMessage({
          message: resp.data.emails[0],
          extraProps: {
            anchorOrigin: { vertical: "top", horizontal: "right" },
          },
        });
      }
      setGeneratingLink(false);
    });
  };

  return (
    <Dialog
      hideBackdrop
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          border: `1px solid ${theme.custom.borderColor}`,
          width: "500px",
        },
      }}
      sx={{
        ".MuiDialog-scrollPaper": {
          justifyContent: "end",
          alignItems: "start",
          mr: 6,
          mt: 6,
        },
      }}
    >
      <DialogTitle>
        <Typography variant="poppins_h3_600_24">Share Report</Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (t) => t.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="inter_p_600_16">Password required?</Typography>
        <Grid
          container
          mt={1}
          mb={3}
          sx={{ height: "50px" }}
          justifyContent="space-between"
        >
          <Grid item xs={12}>
            <TextField
              fullWidth
              autoComplete="off"
              placeholder="Password (Optional)"
              value={password}
              type={showPassword ? "text" : "password"}
              onFocus={() => dispatch(setSettingEditActive(true))}
              onBlur={() => dispatch(setSettingEditActive(false))}
              onChange={(e) => setPassword(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showPassword ? (
                      <IconButton edge="end">
                        <Visibility onClick={() => setShowPassword(false)} />
                      </IconButton>
                    ) : (
                      <IconButton edge="end">
                        <VisibilityOff onClick={() => setShowPassword(true)} />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
                autoComplete: "off",
              }}
              inputProps={{
                maxLength: 200,
              }}
            ></TextField>
          </Grid>
        </Grid>
        <Typography variant="inter_p_600_16">Get sharable link</Typography>
        <Grid
          container
          mt={1}
          mb={3}
          sx={{ height: "50px" }}
          justifyContent="space-between"
        >
          <Grid item xs={12}>
            <LoadingButton
              loading={generatingLink}
              sx={{ height: 1 }}
              fullWidth
              variant="outlined"
              onClick={generateLink}
            >
              Generate and copy <CopyAll sx={{ ml: 1 }} />
            </LoadingButton>
          </Grid>
        </Grid>
        <Typography variant="inter_p_600_16">
          Enter Email Ids to share
        </Typography>
        <Grid container mb={3} mt={1} justifyContent="space-between">
          <Grid item xs={8.9}>
            <TextField
              fullWidth
              multiline
              error={sendMailError !== ""}
              helperText={sendMailError}
              onFocus={() => dispatch(setSettingEditActive(true))}
              onBlur={() => dispatch(setSettingEditActive(false))}
              placeholder="Seperate by comma."
              value={emails}
              onChange={(e) => setEmails(e.target.value)}
            ></TextField>
          </Grid>
          <Grid item xs={2.9}>
            <LoadingButton
              loading={sendingMail}
              fullWidth
              sx={{ height: 1 }}
              variant="outlined"
              onClick={handleSend}
            >
              Send
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(ShareDialog);
