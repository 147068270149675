import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  axisLabelStyle,
  chartLabelsStyle,
  fontInter,
  theme,
  tooltipColors,
} from "../../theme";
import { numberFormatter, labelNumberFormatter } from "../../utils/dataFormatters";
import {
  returnCategoriesForAreaOrLineCharts,
  returnMultipleAreaOrLineChartData,
} from "../../utils/CommonKPIResponseFormatterHelper";
import { returnFileName } from "../../utils/file-name-helper";
import { ChartProps } from "../../Types/ChartProps";

const AreaPercentageChart: React.FC<ChartProps> = (chartData) => {
  let data = chartData.chartData;
  let meta_data = chartData.chartMeta;
  let data_type = meta_data?.chart?.currencytype || "$";
  let date_range = chartData.dateRange;
  const height = chartData.height;
  const width = chartData.width;
  const editorMode = chartData.editorMode;
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);

  let disableDataLabels = meta_data.chart["data-label-disabled"] || false;

  const options = {
    chart: {
      type: "area",
      zoomType: editorMode ? "" : "xy",
      events: {
        render: function () {
          chartData.kpiRendered(true);
          chartData.chartReference(chartComponentRef);
          if (chartData.customKPI === false) {
            chartComponentRef.current?.chart.reflow();
          }
        },
      },
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: returnCategoriesForAreaOrLineCharts(data),
      title: {
        text: meta_data.chart?.["x-axis-text"] || "",
        style: axisLabelStyle,
        margin: 20,
      },
      labels: {
        formatter: function (this: any) {
          return `<span style="${chartLabelsStyle}">${this.value}</span>`;
        },
      },
    },
    yAxis: {
      labels: {
        formatter: function (this: any) {
          return `<span style="${chartLabelsStyle}">${labelNumberFormatter(
            this.value,
            "%",
            true
          )}</span>`;
        },
      },
      title: {
        text: meta_data.chart?.["y-axis-text"] || "",
        style: axisLabelStyle,
      },
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      formatter: function (this: any) {
        return ["<b>" + this.x + "</b>"].concat(
          this.points
            ? this.points.map(function (point: any) {
                return (
                  `<span style="color: ${point.color}; font-family: ${fontInter}">${point.series.name}</span>` +
                  ": " +
                  `<span style="font-weight: bold; font-family: ${fontInter}">${labelNumberFormatter(
                    point.percentage,
                    "%"
                  )}</span>` +
                  " ( " +
                  `<span style="font-family: ${fontInter}">${labelNumberFormatter(
                    point.y,
                    "$"
                  )}</span>` +
                  " )"
                );
              })
            : []
        );
      },
      split: true,
    },
    plotOptions: {
      area: {
        stacking: "percent",
        lineColor: theme.custom.whiteColor,
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: theme.custom.whiteColor,
        },
        dataLabels: {
          inside: true,
          enabled: !disableDataLabels,
          formatter: function (this: any) {
            return numberFormatter(this.y, data_type);
          },
          style: {
            fontWeight: 500,
            fontFamily: fontInter,
            color: theme.custom.primaryDarkColor,
          },
        },
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      filename: returnFileName(meta_data?.chart?.title || "", date_range),
    },
    series: returnMultipleAreaOrLineChartData(data, "area-percentage"),
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{
        style: {
          width: "100%",
          height: "calc(100% - 1px)",
        },
      }}
      options={
        editorMode
          ? {
              ...options,
              ...{ chart: { ...options.chart, height: height, width: width } },
            }
          : options
      }
      ref={chartComponentRef}
    ></HighchartsReact>
  );
};

export default AreaPercentageChart;
