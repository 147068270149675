import { Box, ImageList, ImageListItem } from "@mui/material";
import { useRef } from "react";
import NoHeaderIcon from "../../../assets/noheader.png";
import WithHeaderIcon from "../../../assets/withheader.png";
import { theme } from "../../../theme";
import ThumbnailComponent from "../../atoms/ThumbnailComponent";

const TableLibrary = (props: any) => {
  let mouseOrigin = useRef({ left: 0, top: 0 });
  let cursorPosition = useRef({ left: 0, top: 0 });
  const onDrag = (e: any) => {
    //TODO: On DragEnd page coordinates are coming wrong.
    // Monkey patch to force correct it. Heavy ops issue here.
    cursorPosition.current.top = e.pageY;
    cursorPosition.current.left = e.pageX;
  };

  const onDragStart = (e: any) => {
    let _bounds = e.currentTarget.getBoundingClientRect();
    mouseOrigin.current.top = e.clientY - _bounds.top;
    mouseOrigin.current.left = e.clientX - _bounds.left;
  };

  const defaultTableConfig = {
    headerFont: "roboto",
    headerFontSize: 17,
    headerFontColor: theme.palette.text.primary,
    headerBgColor: "#FFF8ED",
    bodyFont: "roboto",
    bodyFontSize: 17,
    bodyFontColor: theme.palette.text.primary,
    bodyBgColor: "#fff",
    displayBorder: true,
  };
  const addElem = (e: any, element: any, dnd: boolean = false) => {
    const { addElement } = props;
    const _element = {
      type: "TableEditor",
      transparency: 1,
      zIndex: 0,
      posX: 100,
      posY: 100,
      height: 672,
      width: 1351,
      rotate: 0,
      scale: 3,
      attributes: {...element.attributes, title: "Table"},
      tableConfig: defaultTableConfig,
    };

    addElement(
      _element,
      {
        left: cursorPosition.current.left,
        top: cursorPosition.current.top,
      },
      "drop",
      "",
      dnd
    );
    e.preventDefault();
  };
  return (
    <Box pt={2}>
      <ImageList variant="standard" cols={2} gap={12}>
        <ImageListItem
          draggable={true}
          onDrag={onDrag}
          onDragStart={onDragStart}
          onDragEnd={(e) => addElem(e, { attributes: { showHeader: true } })}
          onClick={(e) => addElem(e, { attributes: { showHeader: true } }, false)}
          sx={{ height: 100, width: 100, cursor: "pointer" }}
        >
          <ThumbnailComponent
            imageSrc={WithHeaderIcon}
            kpiName="Table with Header"
          />
        </ImageListItem>
        <ImageListItem
          draggable={true}
          onDrag={onDrag}
          onDragStart={onDragStart}
          onDragEnd={(e) => addElem(e, { attributes: { showHeader: false } })}
          onClick={(e) => addElem(e, { attributes: { showHeader: false } }, false)}
          sx={{ height: 100, width: 100, cursor: "pointer" }}
        >
          <ThumbnailComponent
            imageSrc={NoHeaderIcon}
            kpiName="Table without Header"
          />
        </ImageListItem>
      </ImageList>
    </Box>
  );
};

export default TableLibrary;
