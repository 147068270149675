import axios from "axios";
import { environment } from "../environments/environment-dev";

export const fetchAllGoogleSheets = () => {
  let url = `${environment.connectorApiUrl}/connectors/googlesheet/list`;
  return axios.get(url);
};

export const addNewGoogleSheet = (requestBody: Object) => {
  let url = `${environment.connectorApiUrl}/connectors/googlesheet/create/`;
  return axios.post(url, requestBody);
};

export const syncWorkbook = (uid: string) => {
  let url = `${environment.connectorApiUrl}/connectors/googlesheet/${uid}/sync`;
  return axios.get(url);
};

export const deleteWorkbookByUid = (uid: string) => {
  let url = `${environment.connectorApiUrl}/connectors/googlesheet/${uid}`;
  return axios.delete(url);
};

export const deleteAllConnections = () => {
  let url = `${environment.connectorApiUrl}/connectors/googlesheet`;
  return axios.delete(url);
};
