import {
  Box,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDebounce, useEffectOnce } from "react-use";
import { CompaniesObject } from "../../Types/CompaniesObject";
import KPIIcon from "../../assets/kpi-icon.png";
import PageIcon from "../../assets/page-icon.png";
import SearchIcon from "../../assets/search.png";
import LinkIcon from "../../assets/link_icon.png";

import {
  splotlightPopularItems,
  splotlightSearch,
} from "../../services/spotlight-service";
import { getCompanyId } from "../../services/userinfo-service";
import { RecentHistory } from "../../Types/UserObject";
import { useNavigate } from "react-router-dom";
import SearchedListItem from "../atoms/spotlight-search/SearchedListItem";

type Props = {
  handleCloseGS: Function;
  openGS: boolean;
  companies: CompaniesObject[];
  history: RecentHistory[];
};

function SpotlightDialog({ openGS, handleCloseGS, companies, history }: Props) {
  const [searchText, setSearchText] = useState("");
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [debouncedValue, setDebouncedValue] = useState("");
  const [popularItems, setPopularItems] = useState<any[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (!openGS) {
      setSearchResults(popularItems);
      setSearchText("");
    }
  }, [openGS]);

  const getPopularItems = () => {
    splotlightPopularItems().then((resp) => {
      if (resp.data && resp.status === 200) {
        setPopularItems(resp.data.results);
      }
    });
  };

  useDebounce(
    () => {
      setDebouncedValue(searchText);
      if (!searchText) {
        getPopularItems();
      }
    },
    800,
    [searchText]
  );

  useEffect(() => {
    if (!debouncedValue || !openGS) {
      setSearchResults(popularItems);
      return;
    }

    splotlightSearch({
      searchText: debouncedValue,
      companyStartDate: companies.find((i) => i.company_id === getCompanyId())
        ?.start_date,
    }).then((resp) => {
      if (resp.data && resp.status === 200) {
        setSearchResults(resp.data?.results || []);
      }
    });
  }, [debouncedValue, popularItems, companies]);

  const handleSearchClick = (e: any, url: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleCloseGS();
    navigate(url);
    // window.location.href = `${url}`;
  };

  const iconSelector = (_type: string) => {
    switch (_type) {
      case "kpi":
        return KPIIcon;
      case "recent":
        return LinkIcon;
      default:
        return PageIcon;
    }
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "flex-end" }}>
        <TextField
          placeholder="Global Search"
          focused
          autoFocus
          variant="standard"
          sx={{
            px: 1,
            pt: 2,
            "& .MuiInput-underline:before": {
              borderBottomColor: "white",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "white",
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  style={{ marginLeft: 5 }}
                  height="18px"
                  width="18px"
                  src={SearchIcon}
                  alt="Search"
                />
              </InputAdornment>
            ),
          }}
          fullWidth
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
        />
      </Box>

      {searchResults && (
        <Grid container>
          <Typography
            variant="inter_p_500_14"
            sx={{ ml: 2, mt: 2, fontStyle: "italic" }}
          >
            {searchText.length ? "Search Results" : "Popular"}
          </Typography>
          <List sx={{ width: "100%" }}>
            {searchResults.map((result: any) => (
              <ListItem sx={{ py: 0.5, px: 0 }} key={result.payload.nav_url}>
                <SearchedListItem
                  payload={result?.payload}
                  onClick={handleSearchClick}
                  query={debouncedValue}
                />
              </ListItem>
            ))}
          </List>
        </Grid>
      )}

      {!searchText.length && history && (
        <Grid container>
          <Typography
            variant="inter_p_500_14"
            sx={{ ml: 2, mt: 2, fontStyle: "italic" }}
          >
            Recent
          </Typography>
          <List sx={{ width: "100%" }}>
            {history.map((item: any) => (
              <ListItem sx={{ py: 0.5, px: 0 }} key={item.location}>
                <Tooltip title={item.location} placement="top-start">
                  <ListItemButton
                    onClick={(e) => handleSearchClick(e, item.location)}
                  >
                    <img
                      style={{ marginRight: 5 }}
                      height="18px"
                      width="18px"
                      src={iconSelector("recent")}
                      alt={item.title}
                    />
                    <Typography
                      variant="inter_p_400_16"
                      sx={{ wordWrap: "normal", maxWidth: 400, ml: 1 }}
                    >
                      {(item.title?.length || 0) > 38
                        ? item.title?.substr(0, 38) + "..."
                        : item.title}
                    </Typography>
                  </ListItemButton>
                </Tooltip>
              </ListItem>
            ))}
          </List>
        </Grid>
      )}
    </>
  );
}

export default SpotlightDialog;
