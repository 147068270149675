import AddIcon from "@mui/icons-material/Add";
import {
  Button,
  Checkbox,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import DotIcon from "../../assets/Dot.png";
import TrashIcon from "../../assets/trash.png";
import { theme } from "../../theme";

interface Props {
  menuType: string;
  id: string;
  addRowAtSpecificIndex?: Function;
  rowIndex?: number;
  deleteSpecificRow?: Function;
  deleteSpecificColumn?: Function;
  addColumnAtSpecificIndex?: Function;
  returnSelectedRowCol: Function;
}
const CheckboxMenu = (props: Props) => {
  const {
    id,
    addRowAtSpecificIndex,
    rowIndex,
    deleteSpecificRow,
    menuType,
    deleteSpecificColumn,
    addColumnAtSpecificIndex,
    returnSelectedRowCol,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [checked, setChecked] = useState(false);
  const [show, setShow] = useState(false);

  const addNewRow = () => {
    if (addRowAtSpecificIndex) {
      addRowAtSpecificIndex(rowIndex);
    }
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
    setChecked(false);
    setShow(false);
    returnSelectedRowCol("unselect", "unselect");
  };

  const onDeleteRow = () => {
    if (deleteSpecificRow) {
      deleteSpecificRow(rowIndex);
    }
  };

  const onCheckBoxClicked = (event: any) => {
    setAnchorEl(event.currentTarget);
    setChecked(!checked);
    returnSelectedRowCol(rowIndex, id);
  };

  const onDeleteColumn = () => {
    if (deleteSpecificColumn) {
      deleteSpecificColumn(id);
    }
  };

  const onAddColumn = () => {
    if (addColumnAtSpecificIndex) {
      addColumnAtSpecificIndex(id);
      returnSelectedRowCol("unselect", "unselect");

    }
  };

  const generateMenuItems = () => {
    if (menuType === "row") {
      return (
        <div>
          <MenuItem sx={{ padding: 1.5 }} onClick={addNewRow}>
            <ListItemIcon sx={{ color: theme.custom.primaryDarkColor }}>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography
                sx={{ color: theme.custom.primaryGreyColor }}
                variant="inter_p_500_14"
              >
                Add a row below
              </Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem sx={{ padding: 1.5 }} onClick={onDeleteRow}>
            <ListItemIcon sx={{ color: theme.custom.primaryDarkColor }}>
              <img height="18px" width="18px" src={TrashIcon} alt="Delete" />
            </ListItemIcon>
            <ListItemText>
              <Typography
                sx={{ color: theme.custom.primaryGreyColor }}
                variant="inter_p_500_14"
              >
                Delete row
              </Typography>
            </ListItemText>
          </MenuItem>
        </div>
      );
    }
    if (menuType === "column") {
      return (
        <div>
          <MenuItem sx={{ padding: 1.5 }} onClick={onAddColumn}>
            <ListItemIcon sx={{ color: theme.custom.primaryDarkColor }}>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>
              <Typography
                sx={{ color: theme.custom.primaryGreyColor }}
                variant="inter_p_500_14"
              >
                Add column
              </Typography>
            </ListItemText>
          </MenuItem>
          <MenuItem sx={{ padding: 1.5 }} onClick={onDeleteColumn}>
            <ListItemIcon sx={{ color: theme.custom.primaryDarkColor }}>
              <img height="18px" width="18px" src={TrashIcon} alt="Delete" />
            </ListItemIcon>
            <ListItemText>
              <Typography
                sx={{ color: theme.custom.primaryGreyColor }}
                variant="inter_p_500_14"
              >
                Delete column
              </Typography>
            </ListItemText>
          </MenuItem>
        </div>
      );
    }
  };
  return (
    <React.Fragment>
      {
        <img
          onMouseOver={() => setShow(true)}
          style={{
            display: !show ? "block" : "none",
            cursor: "pointer",
            marginTop: 15,
          }}
          src={DotIcon}
          height="10px"
          width="10px"
          alt="dot"
        />
      }
      {
        <div
          onMouseLeave={() => {
            !checked ? setShow(false) : setShow(true);
          }}
          style={{ visibility: show ? "visible" : "hidden" }}
        >
          <Checkbox
            sx={{ left: -15 }}
            id={id}
            inputProps={{style: {color: "red"}}}
            onClick={onCheckBoxClicked}
            aria-haspopup="true"
            checked={checked}
          />
          <Menu
            id="basic-menu"
            sx={{ zIndex: 5000 }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            PaperProps={{ style: { borderRadius: "6px" } }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            keepMounted
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            {generateMenuItems()}
          </Menu>
        </div>
      }
    </React.Fragment>
  );
};

export default React.memo(CheckboxMenu);
