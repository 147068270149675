import axios from "axios";
import { environment } from "../environments/environment-dev";

export const getAllQuickActions = () => {
  const url = `${environment.connectorApiUrl}/overview/get-quick-actions`;
  return axios.get(url);
};

export const getAllTopBudgets = (payload: string = "") => {
  const url = `${environment.connectorApiUrl}/overview/get-budgets${
    payload.length ? "?as_of=" + payload : ""
  }`;
  return axios.get(url);
};

export const getAllFavouriteKPIs = (payload: any = {}) => {
  const url = `${environment.connectorApiUrl}/overview/get-favourite-kpis`;
  return axios.post(url, payload);
};

export const getAllReportsAndTemplates = () => {
  const url = `${environment.connectorApiUrl}/overview/get-reports`;
  return axios.get(url);
};
