import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Backdrop,
  Box, Button, Divider, Fade,
  Grid,
  Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Typography,
} from "@mui/material";
import React, {memo, useEffect, useState} from "react";
import { theme } from "../../theme";
import FileUploader from "./FileUploader";
import LoaderIcon from "../../assets/loader.png";
import {BudgetDocuments} from "../../Types/BudgetDocumentsItem";
import {dateFormatter} from "../../utils/dataFormatters";
import Download from "../../assets/fi-rr-download 1.png";
import Pdf from "../../assets/pdf.png";
import File from "../../assets/fi-rr-document.png";
import Open from "../../assets/fi-rr-arrow-small-right.png";

import PdfViewer from "../atoms/PdfViewer";
import {
  deleteBudgetDocument,
  getAllBudgetDocuments,
  getBudgetDocument,
  uploadBudgetDocument
} from "../../services/budget-service";
import BudgetDocumentsMenuItem from "../atoms/BudgetDocumentsMenuItem";
import BudgetDocumentsTable from "../atoms/BudgetDocumentsTable";

interface Props {
  open: boolean;
  close: Function;
  budget_uid: string;
  modal: boolean;
}

const BudgetDocumentsMolecule = (props: Props) => {

  const [showUpload, setShowUpload] = useState(false);
  let [isLoading, setIsLoading] = useState(false);
  let [isModalLoading, setIsModalLoading] = useState(false);
  let [tableData, setTableData] = useState<BudgetDocuments[]>([]);
  let [showPdfViewer, setShowPdfViewer] = useState(false);
  let [selectedDocument, setSelectedDocument] = useState<BudgetDocuments|null>(null);
  const [showActionId, setShowActionId] = useState("");

  useEffect(() => {
    setIsModalLoading(true)
    fetchTableData(props.budget_uid);
  }, [props.budget_uid]);

  const fetchTableData = (budget_uid: string) => {
    getAllBudgetDocuments(budget_uid).then((response) => {
      setIsModalLoading(false)
      setIsLoading(false)
      return setTableData(response.data.data)
    })
  }

  const style = {
    position: "absolute" as "absolute",
    top: "20%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: "6px",
    boxShadow: 24,
    outline: "none",
    p: 4,
  };

  const handleClose = (event: any, reason: any = undefined) => {
    if (reason === "backdropClick") return;
    props.close(false);
  };

  const cancelUpload = (event: any) => {
  }

  const toggleUploadBox = (show: boolean) => {
    setShowUpload(show)
  }

  const showList = () => {
    setSelectedDocument(null)
    setShowPdfViewer(false)
  }

  const viewDocument = (document_uid: string) => {
    getBudgetDocument(props.budget_uid, document_uid).then((response) => {
      setSelectedDocument(response.data.data)
      setShowPdfViewer(true)
    })
  }

  const uploadActions = ({ onUpload, onClose }: any) => {
    return (
      <></>
    );
  };

  const onSuccessfulUpload = (uploadUrl: string, fileName: string) => {
    uploadBudgetDocument(
      props.budget_uid,
      {
        "name": fileName,
        "location": uploadUrl,
        "type": "pdf"
      }
    ).then(response => {
      if (response.status == 200) {
        setIsLoading(true)
        fetchTableData(props.budget_uid)
      }
    })
  }

  const deleteDocument = (document_uid: string) => {
    if (window.confirm("Are you sure to delete the document?") == true) {
      deleteBudgetDocument(props.budget_uid, document_uid).then(response => {
        if (response.status == 200) {
          setIsLoading(true)
          fetchTableData(props.budget_uid)
        }
      })
    }
  }

  const downloadDocument = (document_uid: string) => {
    getBudgetDocument(props.budget_uid, document_uid).then((response) => {
      const link = document.createElement('a');
      link.href = response.data.data.signed_url;
      link.setAttribute('download', `${response.data.data.name}`);
      link.setAttribute('target', '_blank"');
      document.body.appendChild(link);
      link.click();
    })
  }

  const modalContent = () => {
    return <Fade in={props.open}>
      <Box sx={style}>
        {!showPdfViewer ?
          <>
            <Grid container>
              <Grid textAlign={"left"} item xs={11}>
                <Typography
                  sx={{ color: `${theme.custom.primaryDarkColor}` }}
                  variant="poppins_h3_600_24"
                >
                  Manage Documents
                </Typography>
              </Grid>
              <Grid textAlign="right" item xs={1}>
                <CancelOutlinedIcon
                  onClick={handleClose}
                  sx={{
                    color: `${theme.custom.primaryGreyColor}`,
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
            <Grid container mt={3} mb={2} style={{position: "relative"}}>
              <Grid item xs={10}>
                <Typography
                  variant="inter_p_400_16"
                  sx={{ color: theme.custom.primaryDarkColor }}
                >
                  {tableData.length ? (`${tableData.length} Documents`) : (`Upload Documents`)}
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right" alignItems="right" justifyContent={"right"} mt={1} mb={1}>
                {
                  tableData.length > 0 && !showUpload &&
                  (<Button
                    sx={{height: "50px"}}
                    variant="contained"
                    onClick={() => toggleUploadBox(true)}
                  >
                    Upload File
                  </Button>)
                }
                {
                  showUpload &&
                  (<Button
                    sx={{color: theme.custom.primaryGreyColor}}
                    variant="text"
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      height: "24px",
                      bottom: "-48px",
                      right: "5px",
                    }}
                    onClick={() => toggleUploadBox(false)}
                  >
                    Cancel
                  </Button>)
                }
              </Grid>
            </Grid>
            <Grid container>
              <Grid textAlign="center" alignItems="center" justifyContent={"center"} xs={12} item>
                {(tableData.length === 0 || showUpload) &&
                <FileUploader
                    width={1000}
                    height={300}
                    closeModal={cancelUpload}
                    onUploadSuccess={onSuccessfulUpload}
                    UploadActions={uploadActions}
                    variant="compact"
                    allowedFileTypes={[".pdf"]}
                />
                }
              </Grid>
            </Grid>
            <BudgetDocumentsTable
              deleteDocument={deleteDocument}
              downloadDocument={downloadDocument}
              isLoading={isLoading}
              setShowActionId={setShowActionId}
              showActionId={showActionId}
              tableData={tableData}
              viewDocument={viewDocument}
            />
          </>
          : (
            <Grid container mt={2} style={{minWidth: 1000, height:800}}>
              <Grid textAlign={"left"} item xs={11}>
                <Typography
                  sx={{ color: `${theme.custom.primaryDarkColor}` }}
                  variant="poppins_h3_600_24"
                >
                  Manage Documents
                </Typography>
              </Grid>
              <Grid textAlign="right" item xs={1}>
                <CancelOutlinedIcon
                  onClick={handleClose}
                  sx={{
                    color: `${theme.custom.primaryGreyColor}`,
                    cursor: "pointer",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <PdfViewer
                  file={selectedDocument?.signed_url}
                  name={selectedDocument?.name}
                  uid={selectedDocument?.uid}
                  backButtonCallback={showList}
                  downloadButtonCallback={downloadDocument}
                />
              </Grid>
            </Grid>
          )}
      </Box>
    </Fade>
  }

  const content = () => {
    return <Fade in={props.open}>
      <Box sx={{
        left: "50%",
        bgcolor: "background.paper",
        border: `1px solid #c7c7c7`,
        borderRadius: "6px",
        outline: "none",
        minHeight: "calc(100vh - 190px)",
        p: 4
      }}>
        {!showPdfViewer ?
          <>
            <Grid container >
              <Grid textAlign={"left"} item xs={11} pb={2}>
                <Typography
                  sx={{ color: `${theme.custom.primaryDarkColor}` }}
                  variant="subtitle1"
                >
                  Manage Documents
                </Typography>
              </Grid>
              <Grid textAlign="right" item xs={1} pb={2}>
                <CancelOutlinedIcon
                  onClick={handleClose}
                  sx={{
                    color: `${theme.custom.primaryGreyColor}`,
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
            <Divider style={{margin: "-32px"}} />
            <Grid container mt={3} mb={2} style={{position: "relative"}}>
              <Grid item xs={10}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: theme.custom.primaryDarkColor }}
                >
                  {tableData.length ? (`${tableData.length} Documents`) : (`Upload Documents`)}
                </Typography>
              </Grid>
              <Grid item xs={2} textAlign="right" alignItems="right" justifyContent={"right"} mt={1} mb={1}>
                {
                  tableData.length > 0 && !showUpload &&
                  (<Button
                    sx={{height: "50px"}}
                    variant="contained"
                    onClick={() => toggleUploadBox(true)}
                  >
                    Upload File
                  </Button>)
                }
                {
                  showUpload &&
                  (<Button
                    sx={{color: theme.custom.primaryGreyColor}}
                    variant="text"
                    style={{
                      position: "absolute",
                      zIndex: 2,
                      height: "24px",
                      bottom: "-48px",
                      right: "5px",
                    }}
                    onClick={() => toggleUploadBox(false)}
                  >
                    Cancel
                  </Button>)
                }
              </Grid>
            </Grid>
            <Grid container>
              <Grid textAlign="center" alignItems="center" justifyContent={"center"} xs={12} item>
                {(tableData.length === 0 || showUpload) &&
                <FileUploader
                    width={"100%"}
                    height={300}
                    closeModal={cancelUpload}
                    onUploadSuccess={onSuccessfulUpload}
                    UploadActions={uploadActions}
                    variant="compact"
                    allowedFileTypes={[".pdf"]}
                />
                }
              </Grid>
            </Grid>
            <BudgetDocumentsTable
              deleteDocument={deleteDocument}
              downloadDocument={downloadDocument}
              isLoading={isLoading}
              setShowActionId={setShowActionId}
              showActionId={showActionId}
              tableData={tableData}
              viewDocument={viewDocument}
            />
          </>
          : (
            <Grid container>
              <Grid textAlign={"left"} item xs={11}>
                <Typography
                  sx={{ color: `${theme.custom.primaryDarkColor}` }}
                  variant="subtitle1"
                  pb={2}
                >
                  Manage Documents
                </Typography>
                <Divider style={{marginLeft: "-32px", marginRight: "-120px"}} />
              </Grid>
              <Grid textAlign="right" item xs={1} pb={2}>
                <CancelOutlinedIcon
                  onClick={handleClose}
                  sx={{
                    color: `${theme.custom.primaryGreyColor}`,
                    cursor: "pointer",
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <PdfViewer
                  file={selectedDocument?.signed_url}
                  name={selectedDocument?.name}
                  uid={selectedDocument?.uid}
                  backButtonCallback={showList}
                  downloadButtonCallback={downloadDocument}
                  nonModal={true}
                />
              </Grid>
            </Grid>
          )}
      </Box>
    </Fade>
  }

  return (
    props.modal ?
      (!isModalLoading ?
    <Modal
      sx={{ position: "absolute", zIndex: 10500, top: "40%" }}
      open={props.open}
      onBackdropClick={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      { modalContent() }
    </Modal> : <></>) : content()
  );
};

export default memo(BudgetDocumentsMolecule);
