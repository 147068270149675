import { Box, Divider, Grid, Stack } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PebbleBgImage from "../components/atoms/PebbleBgImage";
import { Sidebar } from "../components/complexes/Sidebar";
import { fontPoppins, theme } from "../theme";
import NavigateIcon from "../assets/back_button.png";
import ExcelConnectorFileList from "../components/molecules/ExcelConnector/ExcelConnectorFileList";

const ExcelConnectorFilesListPage: React.FC = () => {
  const navigate = useNavigate();
  const navigateToConnectorsList = () => {
    navigate("/connectors/list/");
  };

  useEffect(() => {
    document.title = "Excel Connector - Pebble"
  },[])

  return (
    <Stack direction="row">
      <Sidebar />
      <Divider orientation="vertical" flexItem />
      <Grid container>
        <Grid sx={{ pl: 10, pr: 7, pt: 8 }} item md={9}>
          <Grid container>
            <Grid item md={4} sx={{ position: "relative" }}>
              <Box>
                <div
                  onClick={navigateToConnectorsList}
                  style={{
                    textAlign: "left",
                    cursor: "pointer",
                    marginLeft: 5,
                  }}
                >
                  <img
                    style={{
                      verticalAlign: "middle",
                      filter: `brightness(0) invert(0)`,
                    }}
                    height={13}
                    width={22}
                    src={NavigateIcon}
                    alt="Navigate"
                  />
                  <span
                    style={{
                      color: theme.custom.primaryDarkColor,
                      textTransform: "none",
                      marginLeft: 20,
                      verticalAlign: "middle",
                      fontFamily: fontPoppins,
                      fontWeight: 600,
                      fontSize: 18,
                      lineHeight: "27px",
                    }}
                  >
                    MS Excel
                  </span>
                </div>
              </Box>
            </Grid>
          </Grid>

          <ExcelConnectorFileList />
        </Grid>
        <Grid item xs={3}>
          <PebbleBgImage url="https://pebble-fe-assets.s3.us-west-1.amazonaws.com/Group+65-min.png" />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default React.memo(ExcelConnectorFilesListPage);
