import { Box, Grid, Modal, Stack } from "@mui/material";
import { memo } from "react";
import { theme } from "../../../theme";
import SingleDatePickerComponent from "../../atoms/SingleDatePickerComponent";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import "./BudgetTrackerFilterDate.scss";

const style = {
  position: "absolute" as "absolute",
  top: "35%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 320,
  minHeight: "auto",
  maxHeight: 380,
  bgcolor: "#fff",
  borderRadius: "6px",
};

const BudgetTrackerFilterDate = (props: any) => {
  const onDateChange = (date: any) => {
    props.handleClose();
    props.onDateChange(date);
  };

  return (
    <>
      <Modal
        sx={{ zIndex: 10000 }}
        open={props.open}
        onClose={props.handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="budget-tracker-filter-date">
          <Grid container>
            <Grid item>
              <div className="tracker_filter_date_cancel">
                <CancelOutlinedIcon
                  onClick={props.handleClose}
                  sx={{
                    color: `${theme.custom.primaryGreyColor}`,
                    cursor: "pointer",
                  }}
                />
              </div>
              <Stack alignItems={"center"} direction={"row"}>
                <Grid item>
                  <SingleDatePickerComponent
                    date={props.date ? props.date : props.maxDate}
                    displayLabel={false}
                    onDateChange={onDateChange}
                    minDate={props.minDate}
                    maxDate={props.maxDate}
                    focused={true}
                  />
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default memo(BudgetTrackerFilterDate);
