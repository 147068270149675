import React, { useEffect, useState } from "react";
import { fontInter, theme } from "../../theme";
import { Typography } from "@mui/material";

type Props = {
  onChange: Function;
  value: string;
  minValue?: string;
  maxValue?: string;
  key?: string;
  label?: string;
  extraProps?: any;
};

const CustomMonthPicker = (props: Props) => {
  const { onChange, value, minValue, maxValue, key, label, extraProps } = props;

  const [monthValue, setMonthValue] = useState<string>("");

  useEffect(() => {
    setMonthValue(value);
  }, [value]);
  return (
    <div style={{ width: "100%", position: "relative" }}>
      <label
            style={{
              position: "absolute",
              zIndex: 10000,
              color: theme.custom.primaryGreyColor,
              background: theme.custom.whiteColor,
              paddingLeft: "8px",
              paddingRight: "8px",
              top: "-10px",
              left: "10px"
            }}
          >
          <Typography variant="inter_500_10">{label}</Typography>
      </label>
      <input
        min={minValue}
        style={{
          height: extraProps?.style?.height
            ? extraProps?.style?.height
            : "50px",
          width: "100%",
          borderRadius: "6px",
          padding: "10px",
          fontFamily: fontInter,
          fontWeight: 500,
          fontSize: extraProps?.style?.fontSize
            ? extraProps?.style?.fontSize
            : "1rem",
          border: `1px solid ${theme.custom.borderColor}`,
        }}
        onKeyDown={(e) => e.preventDefault()}
        max={maxValue}
        type="month"
        value={monthValue}
        onChange={(e) => {
          setMonthValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </div>
  );
};

export default CustomMonthPicker;
