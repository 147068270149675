import CustomCarousel from "../atoms/CustomCarousel";
import {useEffect, useState} from "react";
import {getBannerItems} from "../../services/cms-service";

interface Props {
    slug: string;
    extra?: any;
}

const Banner = (props: Props) => {
    const [banners, setBanners] = useState<any>([]);
    useEffect(() => {
        getBannerItems(props.slug).then(res => {
            setBanners(res.data?.data)
        })
    }, []);

    return (
        <CustomCarousel data={banners} extra={props?.extra}></CustomCarousel>
    );
};

export default Banner;