import { Box, Grid, Typography } from "@mui/material";
import React, { memo } from "react";
import { theme } from "../../../theme";
import { reduce } from "lodash";

type Props = {
  dataPermissions: any;
};

const MemberDataPermissionDetails = (props: Props) => {
  const { dataPermissions } = props;
  const renderDataPermissions = () => {
    if (
      dataPermissions &&
      Object.keys(dataPermissions).length > 0 &&
      dataPermissions["program_permissions"]
    ) {
      return dataPermissions["program_permissions"]["has_full_access"] ? (
        <Typography
          variant="inter_p_600_16"
          sx={{ color: theme.custom.primaryDarkColor }}
        >
          All Programs
        </Typography>
      ) : (
        <>{dataPermissionFormatter(dataPermissions)}</>
      );
    }
  };

  const dataPermissionFormatter = (permissions: any) => {
    const data = reduce(
      permissions,
      function (result: any, value, key) {
        if (value.has_full_access) {
          result.push(value["value_slug"]);
          return result;
        } else {
          if (value && value.values) {
            result = [
              ...result,
              ...value.values.map((ele: any) => ele["label"]),
            ];
            return result;
          } else {
            return [];
          }
        }
      },
      []
    );

    if (data.length === 0) {
      return (
        <>
          <Grid container mb={2}>
            <Typography
              variant="inter_p_600_16"
              sx={{ color: theme.custom.primaryDarkColor }}
            >
              No Programs
            </Typography>
          </Grid>
        </>
      );
    }

    return (
      <>
        {data.map((ele: any) => {
          return (
            <Grid container mb={2}>
              <Typography
                variant="inter_p_600_16"
                sx={{ color: theme.custom.primaryDarkColor }}
              >
                {ele}
              </Typography>
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Box my={2}>
        <Typography
          sx={{ color: theme.custom.primaryDarkColor }}
          variant="poppins_h4_600_20"
        >
          Programs assigned
        </Typography>
      </Box>
      {renderDataPermissions()}
    </>
  );
};

export default memo(MemberDataPermissionDetails);
