import { useAppSelector } from "../store/hook";

export const useGetMetaBySlug = (slug: string) =>
  useAppSelector((state) => {
    return state.userMapping.enrichedUMDatawithMeta.find(
      (i) => i.slug === slug
    );
  });

export const useGetSelectedMappingBySlug = (slug: string) =>
  useAppSelector((state) => {
    return state.userMapping.enrichedUMDatawithMeta.find(
      (i) => i.slug === slug
    );
  });

export const createMappingEntry = (
  mappingType: string,
  valueSlugType: string = "attr",
  valueSlug?: string,
  groups: any[] = [],
  conditions: any[] = []
) => {
  if (mappingType === "direct") {
    conditions = [
      {
        is_not: false,
        values: [],
        operator: "",
        conditions: [],
        value_slug: valueSlug,
        value_slug_type: valueSlugType,
      },
    ];
  }
  return {
    groups: groups,
    conditions: conditions,
    mapping_type: mappingType,
  };
};
