import { useParams } from "react-router-dom";
import ConnectBloomerang from "../components/complexes/ConnectBloomerang";
import ConnectDonorPerfect from "../components/complexes/ConnectDonorPerfect";
import NotFoundPage from "./NotFoundPage";

type Props = {};

function ConnectPage({}: Props) {
  const routeParams = useParams();
  
  if (routeParams["connectorSlug"] === "donorperfect") {
    return <ConnectDonorPerfect />;
  } else if (routeParams["connectorSlug"] === "bloomerang") {
    return <ConnectBloomerang />;
  } else {
    return <NotFoundPage />;
  }
}

export default ConnectPage;
