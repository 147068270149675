import { Grid, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { theme } from "../../../theme";
import { FileMetaType } from "../../../Types/FileMetaType";
import { dateFormatterWithTime } from "../../../utils/dataFormatters";
import DoneIcon from "../../../assets/correct.png";
import MenuIcon from "../../../assets/fi-rr-menu-dots.png";
import CloudUploadIcon from "../../../assets/cloud_upload.png";
import DeleteIcon from "../../../assets/deleteIcon.png";
import { generateStatusDisplay } from "../../../utils/ConnectorUIUtils";
import ExcelConnectorFileReplaceConfirm from "./ExcelConnectorFileReplaceConfirm";

type Props = {
  fileMeta: FileMetaType;
  deleteFile: Function;
  onUploadLatest: Function;
  connectionSyncStatus: any;
};

const ExcelConnectorFileListItem = (props: Props) => {
  const { fileMeta, deleteFile, onUploadLatest, connectionSyncStatus } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [latestSyncTime, setLatestSyncTime] = useState<string>("");
  const [socketInfo, setSocketInfo] = useState<any>({
    connector_id: "",
    status: "",
    last_successful_sync_time: "",
  });
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (connectionSyncStatus) {
      setSocketInfo(connectionSyncStatus);
    }
  }, [connectionSyncStatus]);

  useEffect(() => {
    setStatus(fileMeta.status ? fileMeta.status : "syncing");
    if (fileMeta.last_successful_sync_time) {
      setLatestSyncTime(
        dateFormatterWithTime(fileMeta.last_successful_sync_time)
      );
    }
    if (fileMeta.external_reference_slug === socketInfo.connector_id) {
      setStatus(socketInfo.status);
    }
    if (socketInfo.status === "success") {
      setLatestSyncTime(
        dateFormatterWithTime(socketInfo.last_successful_sync_time)
      );
    }
  }, [fileMeta, socketInfo]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openUploadLatest = () => {
    setShowMessage(true);
  };

  const deleteExcel = () => {
    let confirm = window.confirm("Are you sure you want to delete?");
    if (confirm) {
      deleteFile(fileMeta.uid);
    }
  };

  const closeConfirmModal = () => {
    setShowMessage(false);
  };

  const confirmToReWriteTheFile = (confirm: boolean) => {
    if (confirm === true) {
      handleClose();
      onUploadLatest({ replaceFile: true, fileReplaceId: fileMeta.uid });
    } else {
      handleClose();
      closeConfirmModal();
    }
  };
  return (
    <>
      <Grid container pt={3} pb={2} spacing={2}>
        <Grid item xs={4}>
          <Tooltip title={fileMeta.name} placement="top">
            <Typography
              sx={{ color: theme.custom.primaryDarkColor }}
              variant="inter_p_400_16"
              noWrap
            >
              {fileMeta.name}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={4} textAlign="right" mt={0.5}>
          {latestSyncTime !== "" && (
            <Tooltip
              title={"Last Uploaded on " + latestSyncTime}
              placement="top"
            >
              <Typography
                noWrap
                sx={{ color: theme.custom.primaryGreyColor }}
                variant="inter_500_12"
              >
                Last Uploaded on {latestSyncTime}
              </Typography>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={3} textAlign="right">
          <li style={{ listStyleType: "none" }}>
            {status === "success" && (
              <img
                style={{
                  height: 10,
                  width: 15,
                  cursor: "pointer",
                  marginRight: 10,
                }}
                src={DoneIcon}
                alt="Done"
              />
            )}

            {generateStatusDisplay(status, {
              progressProps: { mt: 0.5 },
              progressJustify: "flex-end",
              width: "50px",
              variant: "inter_400_14",
              isExcelUploadStatus: true,
            })}
          </li>
        </Grid>
        {(fileMeta.is_editable || fileMeta.is_deletable) && (
          <Grid textAlign={"center"} item xs={1}>
            <img
              style={{ height: 18, width: 24, cursor: "pointer" }}
              src={MenuIcon}
              onClick={handleClick}
              alt="menu"
            />
          </Grid>
        )}

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {fileMeta.is_editable && (
            <MenuItem onClick={openUploadLatest}>
              <Grid
                mt={1}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item mt={0.5}>
                  <img
                    style={{ height: "18px", width: "18px" }}
                    src={CloudUploadIcon}
                    alt="Cloud Upload"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ color: theme.custom.primaryGreyColor, ml: 1 }}
                    variant="inter_p_500_14"
                  >
                    Upload latest
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          )}
          {fileMeta.is_deletable && (
            <MenuItem onClick={deleteExcel}>
              <Grid
                mb={1}
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
              >
                <Grid item mt={0.5}>
                  <img
                    style={{ height: "18px", width: "18px" }}
                    src={DeleteIcon}
                    alt="Delete"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ color: theme.custom.primaryGreyColor, ml: 1 }}
                    variant="inter_p_500_14"
                  >
                    Delete file
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          )}
        </Menu>
      </Grid>

      <ExcelConnectorFileReplaceConfirm
        open={showMessage}
        closeModal={closeConfirmModal}
        confirmToReWriteTheFile={confirmToReWriteTheFile}
      />
    </>
  );
};

export default React.memo(ExcelConnectorFileListItem);
