import { Grid, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { theme } from "../../../theme";
import React, { useEffect, useState } from "react";
import { generateStatusDisplay } from "../../../utils/ConnectorUIUtils";
import DoneIcon from "../../../assets/correct.png";
import MenuIcon from "../../../assets/fi-rr-menu-dots.png";
import SyncIcon from "../../../assets/sync.png";
import DeleteIcon from "../../../assets/deleteIcon.png";
import { FileMetaType } from "../../../Types/FileMetaType";
import { dateFormatterWithTime } from "../../../utils/dataFormatters";

type Props = {
  googleSheetMeta: FileMetaType;
  connectionSyncStatus: any;
  deleteGoogleSheetWorkbookItem: Function;
  syncGoogleSheetWorkbook: Function;
};

const GoogleSheetConnectorFileListItem = (props: Props) => {
  const {
    googleSheetMeta,
    connectionSyncStatus,
    deleteGoogleSheetWorkbookItem,
    syncGoogleSheetWorkbook,
  } = props;
  const [status, setStatus] = useState<string>("");
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [latestSyncTime, setLatestSyncTime] = useState<string>("");
  const [socketInfo, setSocketInfo] = useState<any>({
    connector_id: "",
    status: "",
    last_successful_sync_time: "",
  });
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (connectionSyncStatus) {
      setSocketInfo((prev: any) => {
        return {
          ...prev,
          connector_id: connectionSyncStatus.connector_id,
          status: connectionSyncStatus.status,
          last_successful_sync_time:
            connectionSyncStatus.last_successful_sync_time,
        };
      });
    }
  }, [connectionSyncStatus]);

  useEffect(() => {
    setStatus(googleSheetMeta.status ? googleSheetMeta.status : "syncing");
    if (googleSheetMeta.last_successful_sync_time) {
      setLatestSyncTime(
        dateFormatterWithTime(googleSheetMeta.last_successful_sync_time)
      );
    }
  }, [googleSheetMeta]);

  useEffect(() => {
    if (socketInfo.status === "success") {
      setLatestSyncTime(
        dateFormatterWithTime(socketInfo.last_successful_sync_time)
      );
      setStatus(socketInfo.status);
    }
  }, [socketInfo]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSyncNow = () => {
    handleClose();
    setStatus("resyncing");
    syncGoogleSheetWorkbook(googleSheetMeta.uid);
  };

  const deleteWorkbookItem = () => {
    let confirm = window.confirm("Are you sure you want to delete?");
    if (confirm) {
      deleteGoogleSheetWorkbookItem(googleSheetMeta.uid);
      handleClose();
    }
  };
  return (
    <>
      <Grid container pt={3} pb={2} spacing={2}>
        <Grid item xs={4}>
          <Tooltip title={googleSheetMeta.name} placement="top">
            <Typography
              sx={{ color: theme.custom.primaryDarkColor }}
              variant="inter_p_400_16"
              noWrap
            >
              {googleSheetMeta.name}
            </Typography>
          </Tooltip>
        </Grid>
        <Grid item xs={4} textAlign="right" mt={0.5}>
          {latestSyncTime !== "" && (
            <Tooltip
              title={"Last Uploaded on " + latestSyncTime}
              placement="top"
            >
              <Typography
                noWrap
                sx={{ color: theme.custom.primaryGreyColor }}
                variant="inter_500_12"
              >
                Last Uploaded on {latestSyncTime}
              </Typography>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={3} textAlign="right">
          <li style={{ listStyleType: "none" }}>
            {status === "success" && (
              <img
                style={{
                  height: 10,
                  width: 15,
                  cursor: "pointer",
                  marginRight: 10,
                }}
                src={DoneIcon}
                alt="Done"
              />
            )}
            {generateStatusDisplay(status, {
              progressProps: { mt: 0.5 },
              progressJustify: "flex-end",
              width: "50px",
              variant: "inter_400_14",
            })}
          </li>
        </Grid>
        {(googleSheetMeta.is_editable || googleSheetMeta.is_deletable) && (
          <Grid textAlign={"center"} item xs={1}>
            <img
              style={{ height: 18, width: 24, cursor: "pointer" }}
              src={MenuIcon}
              onClick={handleClick}
              alt="menu"
            />
          </Grid>
        )}

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {googleSheetMeta.is_editable && (
            <MenuItem onClick={handleSyncNow}>
              <Grid
                mt={1}
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Grid item mt={0.5}>
                  <img
                    style={{ height: "18px", width: "18px" }}
                    src={SyncIcon}
                    alt="Sync Now"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ color: theme.custom.primaryGreyColor, ml: 1 }}
                    variant="inter_p_500_14"
                  >
                    Sync now
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          )}
          {googleSheetMeta.is_deletable && (
            <MenuItem onClick={deleteWorkbookItem}>
              <Grid
                mb={1}
                container
                direction="row"
                justifyContent="left"
                alignItems="center"
              >
                <Grid item mt={0.5}>
                  <img
                    style={{ height: "18px", width: "18px" }}
                    src={DeleteIcon}
                    alt="Delete"
                  />
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ color: theme.custom.primaryGreyColor, ml: 1 }}
                    variant="inter_p_500_14"
                  >
                    Delete workbook
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          )}
        </Menu>
      </Grid>
    </>
  );
};

export default GoogleSheetConnectorFileListItem;
