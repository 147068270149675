import {
  Grid,
  Box,
  Typography,
  Divider,
  Tooltip,
  Stack,
  Badge,
  Chip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../theme";
import { BudgetListItem } from "../../Types/BudgetListItem";
import { BudgetDetailsTable, BudgetItem } from "../../Types/BudgetDetailsItem";
import {
  dateFormatter,
  returnAccountsString,
} from "../../utils/dataFormatters";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SimpleTableForBudget from "./SimpleTableForBudget";
import {
  fetchBudgetDetailsForBudgetId,
  updateBudget,
} from "../../services/budget-service";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../store/hook";
import { showMessage } from "../../store/error-handler-store";
import moment from "moment";
import { KeyboardArrowUp } from "@mui/icons-material";
import DocumentsIcon from "../../assets/fi-rr-document.png";
import BudgetDocumentsMolecule from "./BudgetDocumentsMolecule";
import BudgetListMoreMenu from "../atoms/BudgetListMoreMenu";
import MagicStick from "../../../src/assets/magic_wand_multi_color.png";
import BudgetAnalysisModal from "./BudgetManagement/BudgetAnalysisModal";
import LinearProgressWithLabels from "../atoms/LinearProgressWithLabels";
import { isEmpty } from "lodash";
import BudgetTypeDetailsItem from "../atoms/BudgetTypeDetailsItem";
import { BudgetTypeDetails } from "../../Types/BudgetTypeDetails";
import TargetIcon from "../../../src/assets/target.png";
import ConstructionIcon from "@mui/icons-material/Construction";
interface Props {
  item: BudgetListItem;
  isExpenseProofAllowed?: boolean;
}

const INIT_STATE = {
  active: false,
};

const BudgetListItemMolecule: React.FC<Props> = (data) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const item: any = data.item;
  const isExpenseProofAllowed = data.isExpenseProofAllowed || false;
  const [budget, setBudget] = useState(INIT_STATE);
  const [tableData, setTableData] = useState<BudgetDetailsTable[]>([]);
  const [budgetDetails, setBudgetDetails] = useState<any[]>([]);
  const [isTableDisplayed, setIsTableDisplayed] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [openDocumentList, setOpenDocumentList] = useState(false);
  const [openBudgetAnalysis, setOpenBudgetAnalysis] = useState(false);

  useEffect(() => {
    setBudget((prev) => {
      return { ...prev, active: item.active };
    });
  }, []);

  useEffect(() => {
    tableCreationHelper(budgetDetails);
  }, [budgetDetails]);

  const getBudgetDetails = () => {
    setLoading(true);
    fetchBudgetDetailsForBudgetId(item.budget_uid).then((res) => {
      if (res.data.data) {
        setLoading(false);
        let _response: BudgetListItem = res.data.data;
        if (Object.keys(_response.budget_type_details).length) {
          let _budgetDetails: any = [];
          Object.values(_response.budget_type_details).forEach((ele) => {
            _budgetDetails = [..._budgetDetails, ...ele["items"]];
          });

          setBudgetDetails(_budgetDetails);
        }
      }
      setLoading(false);
    });
  };

  const tableCreationHelper = (dataForTable: any) => {
    let tempArr: BudgetDetailsTable[] = [];
    dataForTable.forEach((type: BudgetItem) => {
      let obj = {
        amount: type?.amount,
        category_name: type?.category_name,
        id: type?.id,
        type: type?.type,
        accounts: returnAccountsString(type?.accounts, "ref_account_name"),
        remarks: type?.remarks,
      };
      tempArr.push(obj);
      setTableData(tempArr);
    });
  };

  const toggleTable = () => {
    if (tableData.length === 0) {
      getBudgetDetails();
    }
    setIsTableDisplayed(!isTableDisplayed);
  };

  const editBudgetUrlHelper = () => {
    var str = item.name.replace(
      /[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
      "-"
    );
    return `/budgets/${str}_${item.budget_uid}`;
  };

  const navigateToEditBudgetItem = () => {
    navigate(editBudgetUrlHelper());
  };

  const showDocumentsList = () => {
    setOpenDocumentList(true);
  };

  const closeDocumentsList = () => {
    setOpenDocumentList(false);
  };

  const closeBudgetAnalysis = () => {
    setOpenBudgetAnalysis(false);
  };

  const generateAnalysis = (e: any) => {
    setOpenBudgetAnalysis(true);
  };

  const navigateToExpenseProof = () => {
    let filter_data: any = {
      program: {
        action: "delete",
        label: item.ref_program_name,
        value: item.ref_program_id,
      },
      contract: [
        {
          action: "delete",
          label: item.ref_contract_title,
          program: item.ref_program_id,
          value: item.ref_contract_id,
        },
      ],
      daterange: {
        action: "delete",
        start_date: item.start_date,
        end_date: item.end_date,
      },
      budget_uid: item.budget_uid,
      budget_name: item.name || "",
    };

    navigate(
      `/board/expense-proof-and-invoice-tracking?encoded_filter_data=${btoa(
        JSON.stringify(filter_data)
      )}`
    );
  };

  const navigateToBudgetTracker = () => {
    let filter_data: any = {
      program_single: {
        action: "delete",
        label: item.ref_program_name,
        value: item.ref_program_id,
      },
      contract: {
        action: "delete",
        label: item.ref_contract_title,
        program: item.ref_program_id,
        value: item.ref_contract_id,
      },
    };

    navigate(
      `/budgets-monitoring?encoded_filter_data=${btoa(
        JSON.stringify(filter_data)
      )}#contracts`
    );
  };

  const onToggleSwitch = (isActive: boolean) => {
    setBudget((prev: any) => {
      return {
        ...prev,
        active: isActive,
      };
    });
    let obj = {
      active: isActive,
      end_date: moment(item.end_date).format("YYYY-MM-DD"),
      start_date: moment(item.start_date).format("YYYY-MM-DD"),
      name: item.name,
      ref_program_id: item.ref_program_id,
      ref_program_name: item.ref_program_name,
    };
    updateBudget(obj, item.budget_uid).then((res) => {
      if (res.status === 200) {
        setErrMsg("");
        dispatch(
          showMessage({
            message: "Budget Updated Successfully",
            variant: "success",
          })
        );
      } else {
        setErrMsg(res?.data?.message);
        setBudget((prev: any) => {
          return {
            ...prev,
            active: budget.active,
          };
        });
        dispatch(
          showMessage({
            message: "Something went wrong.",
            variant: "error",
          })
        );
      }
    });
  };

  const calculateProgressPercentage = (timeElapsed: any) => {
    let value = 0;
    if (!isEmpty(timeElapsed)) {
      const endDate = moment(timeElapsed?.end_date);
      const startDate = moment(timeElapsed?.start_date);
      value =
        (timeElapsed.time_lapse / (endDate.diff(startDate, "days") + 1)) * 100;
    }
    return value;
  };

  return (
    <React.Fragment>
      <Box
        mt={3}
        ml={2}
        mr={2}
        sx={{
          background: theme.custom.filterBackgroundColor,
          height: "auto",
          border: 1,
          borderColor: theme.custom.filterBorderColor,
          borderRadius: "6px",
        }}
        id={item.budget_uid}
      >
        <Box>
          <Grid p={2} container alignItems={"center"}>
            <Grid item xs={9}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"start"}
                spacing={2}
              >
                <Box>
                  <Stack direction={"column"}>
                    <Box>
                      <Tooltip title={item.name}>
                        <Typography
                          sx={{
                            maxWidth: 250,
                            color: theme.custom.primaryDarkColor,
                          }}
                          noWrap
                          variant="poppins_h5_600_18"
                        >
                          {item.name}
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Stack>
                </Box>
                <Box>
                  {budget.active ? (
                    <Chip
                      style={{ borderRadius: "6px" }}
                      label="Active"
                      color="success"
                      size={"small"}
                    />
                  ) : (
                    <Chip
                      style={{ borderRadius: "6px" }}
                      label="Inactive"
                      size={"small"}
                    />
                  )}
                </Box>
                <Box>
                  <Typography
                    variant="inter_400_14"
                    color={theme.custom.primaryDarkColor}
                  >
                    {dateFormatter(item.start_date)}
                    {" - "}
                    {dateFormatter(item.end_date)}
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={3}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"end"}
                spacing={1}
              >
                {data.isExpenseProofAllowed && (
                  <Box>
                    <Tooltip title={"Expense proof"} placement="top">
                      <ConstructionIcon
                        onClick={navigateToExpenseProof}
                        sx={{ cursor: "pointer" }}
                        color="disabled"
                      />
                    </Tooltip>
                  </Box>
                )}
                <Box>
                  <Tooltip
                    title={"Analyze budget via Pebble AI"}
                    placement="top"
                  >
                    <img
                      height="30px"
                      width="30px"
                      style={{
                        maxHeight: "20px",
                        cursor: "pointer",
                        width: "auto",
                      }}
                      onClick={(e) => {
                        generateAnalysis(e);
                      }}
                      src={MagicStick}
                      alt="magic_stick"
                    />
                  </Tooltip>
                </Box>
                <Box>
                  <Tooltip title={"Track budget"} placement="top">
                    <img
                      height="30px"
                      width="30px"
                      style={{
                        maxHeight: "20px",
                        marginLeft: "5px",
                        cursor: "pointer",
                        width: "auto",
                      }}
                      onClick={() => {
                        navigateToBudgetTracker();
                      }}
                      src={TargetIcon}
                      alt="Target"
                    />
                  </Tooltip>
                </Box>
                <Box>
                  {item.is_editable ? (
                    <Tooltip title={"Budget Documents"}>
                      <Badge
                        badgeContent={item.budget_documents_count}
                        color="primary"
                        anchorOrigin={{ vertical: "top", horizontal: "left" }}
                        style={{ marginLeft: "10px" }}
                      >
                        <img
                          onClick={showDocumentsList}
                          src={DocumentsIcon}
                          alt="edit"
                          style={{ maxHeight: "20px", cursor: "pointer" }}
                          height="20px"
                        />
                      </Badge>
                    </Tooltip>
                  ) : (
                    <></>
                  )}
                </Box>
                <Box>
                  <BudgetListMoreMenu
                    toggleBudgetActive={onToggleSwitch}
                    isActive={budget.active}
                    navigateToEditBudgetItem={navigateToEditBudgetItem}
                    isEditable={item.is_editable}
                  />
                </Box>
              </Stack>
            </Grid>
          </Grid>
          {errMsg.length > 0 && (
            <Typography sx={{ color: theme.custom.errorColor }}>
              {errMsg}
            </Typography>
          )}
        </Box>
        <Divider />
        <Box px={2}>
          <Grid py={1} container alignItems={"center"}>
            <Grid item xs={10}>
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"start"}
                spacing={3}
              >
                <Box>
                  <Typography
                    variant="inter_400_14"
                    sx={{
                      color: theme.custom.primaryGreyColor,
                    }}
                  >
                    Program
                  </Typography>{" "}
                  <Box sx={{ display: "inline-flex" }}>
                    <Typography
                      variant="inter_p_600_14"
                      sx={{
                        maxWidth: 250,
                        color: theme.custom.primaryDarkColor,
                        display: "inline-block",
                      }}
                      noWrap
                    >
                      {item.ref_program_name}
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography
                    variant="inter_400_14"
                    sx={{
                      color: theme.custom.primaryGreyColor,
                    }}
                  >
                    Contract
                  </Typography>{" "}
                  <Box sx={{ display: "inline-flex" }}>
                    <Typography
                      variant="inter_p_600_14"
                      sx={{
                        maxWidth: 250,
                        color: theme.custom.primaryDarkColor,
                        display: "inline-block",
                      }}
                      noWrap
                    >
                      {item.ref_contract_title}
                    </Typography>
                  </Box>
                </Box>
              </Stack>
            </Grid>
            <Grid textAlign={"right"} item md={2}>
              <LinearProgressWithLabels
                start_date={item?.time_elapsed?.start_date || ""}
                end_date={item?.time_elapsed?.end_date || ""}
                time_elapsed={item?.time_elapsed?.time_lapse}
                unit={item?.time_elapsed?.days_label || ""}
                value={calculateProgressPercentage(item?.time_elapsed)}
                showLabels={false}
                extraProps={{
                  style: {
                    top: 8,
                    left: "45%",
                  },
                }}
              ></LinearProgressWithLabels>
            </Grid>
          </Grid>
          {errMsg.length > 0 && (
            <Typography sx={{ color: theme.custom.errorColor }}>
              {errMsg}
            </Typography>
          )}
          <Divider />
        </Box>
        <Box px={2}>
          <Grid container alignItems={"center"} justifyContent={"flex-start"}>
            {item &&
              item.budget_type_details &&
              item.budget_type_details.map(
                (ele: BudgetTypeDetails, index: number) => {
                  return (
                    <>
                      {index % 4 !== 0 && (
                        <Grid>
                          <Divider
                            variant="middle"
                            sx={{ height: "4em" }}
                            orientation="vertical"
                            flexItem
                          />
                        </Grid>
                      )}
                      <Grid sx={{ p: 2 }} textAlign={"left"} item sm={2.9}>
                        <BudgetTypeDetailsItem details={ele} />
                      </Grid>
                    </>
                  );
                }
              )}
          </Grid>
        </Box>
        <Box pt={1} pb={2} mx={2}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid sx={{ marginLeft: 2 }} item>
              <Typography
                variant="inter_400_12"
                sx={{ color: theme.custom.primaryGreyColor }}
              >
                Actuals as on {dateFormatter(item.as_of_date, "MMM DD, YYYY")}
              </Typography>
            </Grid>
            <Grid sx={{ marginRight: 2 }} item textAlign={"right"}>
              <Box
                onClick={toggleTable}
                sx={{
                  color: theme.palette.primary.main,
                  display: "flex",
                  float: "right",
                  cursor: "pointer",
                }}
              >
                <Stack direction="row" alignItems="center">
                  <Typography
                    variant="inter_p_600_14"
                    sx={{
                      lineHeight: "24px",
                    }}
                  >
                    Details
                  </Typography>
                  {isTableDisplayed && tableData.length > 0 ? (
                    <KeyboardArrowUp />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {isTableDisplayed && (
          <Box mt={2}>
            <SimpleTableForBudget isLoading={loading} data={tableData} />
          </Box>
        )}
      </Box>
      {openDocumentList && (
        <BudgetDocumentsMolecule
          open={true}
          close={closeDocumentsList}
          budget_uid={item.budget_uid}
          modal={true}
        />
      )}
      {openBudgetAnalysis && (
        <BudgetAnalysisModal
          open={openBudgetAnalysis}
          close={closeBudgetAnalysis}
          budgetId={item.budget_uid}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(BudgetListItemMolecule);
