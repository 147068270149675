import { Box, Grid, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import EditIcon from "../assets/pencil.png";
import MemberPermissionDetails from "../components/complexes/MemberPermissionDetails";
import { Sidebar } from "../components/complexes/Sidebar";
import {
  getUserProfile,
} from "../services/user-management-service";
import { theme } from "../theme";
import EditMyProfileModal from "../components/complexes/EditMyProfileModal";
import {fetchUserInfo, setCompanyDataToLocalStorage} from "../services/userinfo-service";
import useLocalStorageState from "use-local-storage-state";
import {USER_INFO_LOCAL_STORAGE_KEY} from "../constants/constants";

type Props = {};

const MyProfilePage = (props: Props) => {
  const params = useParams();

  const [memberDetails, setMemberDetails] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [localStorageInfo, setLocalStorageInfo] = useLocalStorageState(
    USER_INFO_LOCAL_STORAGE_KEY,
    {}
  );

  const refreshName = () => {
    fetchUserInfo().then((userInfo) => {
      setLocalStorageInfo(userInfo?.data);
    });
  };

  const getDetails = () => {
    setLoading(true);
    getUserProfile()
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          setMemberDetails(res.data);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getDetails();
  }, []);

  const onEdit = () => {
    setOpenPopup(true);
  };

  const onEditSuccess = () => {
    getDetails();
    refreshName();
  };
  return (
    <>
      <Stack direction="row">
        <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
          <Sidebar />
        </Box>
        <Box sx={{ width: "100%" }} p={8}>
          <Grid container>
            <Grid item>
              <Typography
                variant="poppins_h4_600_20"
                sx={{ color: theme.custom.primaryDarkColor }}
                ml={2}
              >
                My Profile
              </Typography>
            </Grid>
          </Grid>
          {loading && (
            <Skeleton
              sx={{ borderRadius: "6px", mt: 2, bgcolor: "grey.100" }}
              variant="rectangular"
              height={130}
            />
          )}
          {!loading && (
            <Box
              mt={3}
              sx={{
                width: "100%",
                background: theme.custom.chipGroupBackground,
                borderRadius: "6px",
              }}
            >
              <Stack p={3} alignItems={"center"} direction={"row"}>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item>
                        <Box
                          sx={{
                            padding: 2,
                            borderRadius: "50%",
                            background: theme.custom.accentBgColor,
                          }}
                        >
                          <Typography
                            sx={{ color: theme.custom.whiteColor }}
                            variant="poppins_h2_700_30"
                          >
                            {[
                              (memberDetails.first_name &&
                                memberDetails.first_name.length &&
                                memberDetails.first_name[0]) ||
                              "",
                              (memberDetails.last_name &&
                                memberDetails.last_name.length &&
                                memberDetails.last_name[0]) ||
                              "",
                            ].join("")}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box>
                          <Typography
                            color={theme.custom.primaryDarkColor}
                            variant="poppins_h3_600_24"
                          >
                            {`${
                              memberDetails.first_name
                                ? memberDetails.first_name
                                : ""
                            } ${
                              memberDetails.last_name
                                ? memberDetails.last_name
                                : ""
                            }`}
                          </Typography>
                          <Typography
                            variant="inter_400_14"
                            sx={{ color: theme.custom.primaryDarkColor }}
                          >
                            {memberDetails.email || ""}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems={"center"} spacing={7}>
                      <Grid item>
                        <Typography
                          variant="inter_400_14"
                          sx={{ color: theme.custom.primaryDarkColor }}
                        >
                          Role
                        </Typography>{" "}
                        <br />
                        <Typography
                          sx={{ color: theme.custom.primaryDarkColor }}
                          variant="inter_p_600_14"
                        >
                          {memberDetails?.role?.name || ""}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems={"center"} spacing={3}>
                          <Grid item>
                            <Tooltip title="Edit">
                              <img
                                onClick={onEdit}
                                src={EditIcon}
                                style={{ cursor: "pointer" }}
                                alt="Edit"
                                height={22}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          )}
          {(
            <Box mt={2}>
              <MemberPermissionDetails memberDetails={memberDetails} />
            </Box>
          )}
          <EditMyProfileModal
            showPopup={openPopup}
            addUserCallback={onEditSuccess}
            setShowPopup={setOpenPopup}
          />
        </Box>
      </Stack>
    </>
  );
};

export default memo(MyProfilePage);
