import React from "react";
import Markdown from "markdown-to-jsx";
import { Box } from "@mui/material";
import { fontInter, fontPoppins, theme } from "../../theme";
interface Props {
  markdown: string;
  extraSx?: any;
}

const MarkdownRenderer = (props: Props) => {
  return (
    <>
      <Box
        sx={{
          "h1, h2, h3, h4, h5, h6": {
            ...(props.extraSx || {}),
            ...{
              fontFamily: fontPoppins,
              fontWeight: 600,
              color: theme.custom.primaryDarkColor,
              "& strong": {
                fontWeight: 600,
              },
            },
          },
          "*": {
            ...(props.extraSx || {}),
            ...{
              fontFamily: fontInter,
              coor: theme.custom.primaryDarkColor,
              "& strong": {
                fontWeight: 600,
              },
            },
          },
        }}
      >
        <Markdown>{props.markdown}</Markdown>
      </Box>
    </>
  );
};

export default MarkdownRenderer;
