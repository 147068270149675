import axios from "axios";
import {
  BUDGETS,
  DATA_CARTS_URL,
  GET_BUDGET_ANALYSIS,
} from "../endpoints/budget-endpoints";
import { AddStaffingObject } from "../Types/AddStaffingObject";
import { BudgetItemData } from "../Types/BudetItemData";
import { StaffingItemsObject } from "../Types/BudgetDetailsItem";
import { staffingItemObject } from "../Types/StaffingItemObject";
import { updateCustomBudgetTypeObject } from "../Types/UpdateCustomBudgetTypeObject";

export const listAllBudgets = (filterData: any, page: number = 1) => {
  let queryParams: string[] = [];
  Object.keys(filterData).forEach((ele) => {
    queryParams.push(`${ele}=${filterData[ele]}`);
  });

  return axios.get(`${BUDGETS}?page=${page}&${queryParams.join("&")}`);
};

export const addBudget = (budgetData: any) => {
  return axios.post(BUDGETS, budgetData);
};

export const updateBudget = (budgetData: any, budget_id: string) => {
  let url = `${BUDGETS}${budget_id}`;
  return axios.put(url, budgetData);
};

export const fetchBudgetDetailsForBudgetId = (budgetId: string) => {
  let url = `${BUDGETS}detail/${budgetId}`;
  return axios.get(url);
};

export const fetchDataCarts = () => {
  return axios.get(`${DATA_CARTS_URL}/program-and-accounts`);
};

export const addBudgetItem = (
  budget_id: string,
  budgetItemData: BudgetItemData
) => {
  let url = `${BUDGETS}${budget_id}/items`;
  return axios.post(url, budgetItemData);
};

export const deleteBudgetItem = (budget_id: string, item_id: number) => {
  let url = `${BUDGETS}${budget_id}/items/${item_id}`;
  return axios.delete(url);
};

export const editBudgetItem = (
  budget_id: string,
  item_id: number,
  budgetItemData: BudgetItemData
) => {
  let url = `${BUDGETS}${budget_id}/items/${item_id}`;
  return axios.put(url, budgetItemData);
};

export const getEmployeeList = () => {
  let url = `${BUDGETS}all/employees/list`;
  return axios.get(url);
};

export const addStaffingItem = (
  budget_uid: string,
  staffingData: AddStaffingObject
) => {
  let url = `${BUDGETS}/${budget_uid}/staffing-items`;
  return axios.post(url, staffingData);
};

export const deleteStaffingItem = (
  budget_uid: string,
  budget_staffing_item_id: number
) => {
  let url = `${BUDGETS}/${budget_uid}/staffing-items/${budget_staffing_item_id}`;
  return axios.delete(url);
};

export const editStaffingItem = (
  budget_uid: string,
  budget_staffing_item_id: number,
  staffing_item: AddStaffingObject
) => {
  let url = `${BUDGETS}/${budget_uid}/staffing-items/${budget_staffing_item_id}`;
  return axios.put(url, staffing_item);
};
export const getBudgetTrackerCards = (
  program_id: string,
  budgetCardObj?: any
) => {
  let body;

  if (!budgetCardObj) {
    body = {
      data: {
        program: program_id,
      },
    };
  } else {
    body = {
      data: budgetCardObj,
    };
  }

  return axios.post(`${DATA_CARTS_URL}/budget/budget-card`, body);
};

export const saveBudgetType = (customBudgetType: string) => {
  let url = `${BUDGETS}/types`;
  return axios.post(url, { budget_type: customBudgetType });
};

export const getAllBudgetTypes = () => {
  let url = `${BUDGETS}/types`;
  return axios.get(url);
};

export const deleteCustomBudgetType = (
  budget_type: string,
  budget_uid: string
) => {
  let url = `${BUDGETS}/types/${budget_type}`;
  let data = {
    budget_uid: budget_uid,
  };
  return axios.delete(url, { data: data });
};

export const editCustomBudgetType = (
  value: updateCustomBudgetTypeObject,
  budget_uid: string
) => {
  let url = `${BUDGETS}/types/${value.newValue}`;
  return axios.put(url, {
    existing_budget_type: value.oldValue,
    budget_uid: budget_uid,
  });
};

export const getAllBudgetDocuments = (budget_uid: string) => {
  let url = `${BUDGETS}${budget_uid}/documents`;
  return axios.get(url);
};

export const getBudgetDocument = (budget_uid: string, document_uid: any) => {
  let url = `${BUDGETS}${budget_uid}/documents/${document_uid}`;
  return axios.get(url);
};

export const uploadBudgetDocument = (budget_uid: string, document: any) => {
  let url = `${BUDGETS}${budget_uid}/documents`;
  return axios.post(url, document);
};

export const deleteBudgetDocument = (budget_uid: string, document_uid: any) => {
  let url = `${BUDGETS}${budget_uid}/documents/${document_uid}`;
  return axios.delete(url);
};

export const fetchDrilldownData = (
  budget_uid: string,
  kpi_slug: string,
  payload: any
) => {
  let url = `${BUDGETS}${budget_uid}/${kpi_slug}/drill_down`;
  return axios.post(url, payload);
};

export const getAllContractsForProgram = (program: string) => {
  let url = `${DATA_CARTS_URL}/contract`;
  let payload = {
    data: {
      program: [program],
    },
  };
  return axios.post(url, payload);
};

export const getBudgetAnalysis = (budgetId: string) => {
  return axios.get(GET_BUDGET_ANALYSIS(budgetId));
};
