import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Grid,
  Box,
} from "@mui/material";
import { theme } from "../../theme";
import { BudgetDetailsTable } from "../../Types/BudgetDetailsItem";
import LoaderIcon from "../../assets/loader.png";
import { numberFormatter } from "../../utils/dataFormatters";

interface Props {
  data: Array<BudgetDetailsTable>;
  isLoading: boolean;
}

const SimpleTableForBudget: React.FC<Props> = (data) => {
  let isLoading = data.isLoading;
  let [tableData, setTableData] = useState<BudgetDetailsTable[]>([]);

  useEffect(() => {
    setTableData(data.data);
  }, [data.data]);

  return (
    <TableContainer>
      <Table style={{ overflow: "none" }}>
        {isLoading && tableData.length === 0 && (
          <caption>
            <Box
              sx={{
                height: 100,
                width: "100%",
                position: "relative",
              }}
            >
              <Grid
                textAlign={"center"}
                className="show-loader"
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item sm={12} textAlign={"center"}>
                  <img src={LoaderIcon} height="100" width="100" alt="Loader" />
                </Grid>
              </Grid>
            </Box>
          </caption>
        )}
        {!isLoading && tableData.length > 0 && (
          <TableHead sx={{ background: theme.dataTableColors.headerBgColor }}>
            <TableCell>
              <Typography variant="inter_p_600_16">Type</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="inter_p_600_16">Category</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="inter_p_600_16">Accounts</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="inter_p_600_16">Budget Value</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="inter_p_600_16">Remarks</Typography>
            </TableCell>
          </TableHead>
        )}
        {!isLoading && tableData.length > 0 && (
          <TableBody sx={{ overflow: "none" }}>
            {tableData.map((row) => (
              <TableRow key={row.id}>
                <TableCell component="th" scope="row">
                  <Typography
                    variant="inter_400_14"
                    sx={{
                      color: theme.custom.primaryDarkColor,
                      textTransform: "capitalize",
                    }}
                  >
                    {row.type}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="inter_400_14"
                    sx={{ color: theme.custom.primaryDarkColor }}
                  >
                    {row.category_name}
                  </Typography>
                </TableCell>
                <TableCell>
                  {" "}
                  <Typography
                    variant="inter_400_14"
                    sx={{ color: theme.custom.primaryDarkColor }}
                  >
                    {row.accounts}
                  </Typography>
                </TableCell>
                <TableCell>
                  {" "}
                  <Typography
                    variant="inter_400_14"
                    sx={{ color: theme.custom.primaryDarkColor }}
                  >
                    {numberFormatter(+row.amount || 0, "$", false)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography
                    variant="inter_400_14"
                    sx={{ color: theme.custom.primaryDarkColor }}
                  >
                    {row.remarks}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        )}
        {!isLoading && tableData.length === 0 && (
          <caption>
            <Box
              sx={{
                height: 100,
                width: "100%",
                position: "relative",
              }}
            >
              <Grid
                textAlign={"center"}
                className="show-loader"
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Typography
                  variant="inter_p_400_16"
                  sx={{ color: theme.custom.primaryDarkColor }}
                >
                  No records found.{" "}
                </Typography>
              </Grid>
            </Box>
          </caption>
        )}
      </Table>
    </TableContainer>
  );
};

export default React.memo(SimpleTableForBudget);
