import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import { preview } from "../../../services/kpi-builder-service";
import { theme } from "../../../theme";
import AreaChart from "../../atoms/AreaChart";
import BarChart from "../../atoms/BarChart";
import BarStackedChart from "../../atoms/BarStackedChart";
import ColumnChart from "../../atoms/ColumnChart";
import ColumnStackedChart from "../../atoms/ColumnStackedChart";
import GroupedBarChart from "../../atoms/GroupedBarChart";
import GroupedColumnChart from "../../atoms/GroupedColumnChart";
import LineChart from "../../atoms/LineChart";
import MultipleAreaChart from "../../atoms/MultipleAreaCharts";
import MultipleLineChart from "../../atoms/MultipleLineCharts";
import PieChart from "../../atoms/PieChart";
import ChartThumbnail from "../../../assets/chart_thumbnail.png";
import DataTableMolecule from "../DataTableMolecule";
import { useDeepCompareEffect } from "react-use";
import { isEmpty } from "lodash";
import GroupTable from "../DataTable/GroupTable";

type Props = {
  showTable: boolean;
  previewData: any;
  builderState: any;
  updateValidationMessage: Function;
  isValid: any;
};

const INIT_CHART_DATA = {
  data: [] as any,
  meta_data: {} as any,
  type: "",
};

const KPIPreview = (props: Props) => {
  const {
    showTable,
    previewData,
    builderState,
    updateValidationMessage,
    isValid,
  } = props;

  const [showTableElement, setShowTableElement] = useState(false);

  const [valid, setValid] = useState({} as any);
  useEffect(() => {
    setShowTableElement(showTable);
  }, [showTable]);

  useEffect(() => {
    setValid(isValid);
  }, [isValid]);

  useDeepCompareEffect(() => {
    callPreviewAPI();
  }, [JSON.stringify(previewData), isValid]);

  const callPreviewAPI = () => {
    if (
      isValid.seriesValid &&
      isValid.chartTypeValid &&
      isValid.uniqueAxisNameValid
    ) {
      if (isEmpty(previewData["chart_data"])) {
        return;
      }
      setIsError(false);
      setKpiDataAvailable(false);
      updateValidationMessage("");
      preview(previewData).then((resp: any) => {
        if (resp.status === 403) {
          setAuthorizedAccess(false);
          updateValidationMessage(" "); //Disables the save and continue button
          return;
        }

        if (resp.status === 200) {
          setKpiDataAvailable(true);
          setIsError(false);
          let temp = {
            type: resp?.data?.type || "",
            meta_data: resp?.data?.meta_data || {},
            data: resp?.data?.data || [],
          };
          setChartData(temp);
          return;
        }
        if (resp.status === 400 || resp.status === 500) {
          setKpiDataAvailable(false);
          setIsError(true);
          updateValidationMessage(
            resp?.data?.message || "Something went wrong!"
          );
        }
      });
    }
  };

  const [kpiDataAvaliable, setKpiDataAvailable] = useState(false);
  const [chartData, setChartData] = useState(INIT_CHART_DATA);
  const [authorizedAccess, setAuthorizedAccess] = useState(true);
  const [isError, setIsError] = useState(false);

  const pickChartsBasedOnType = () => {
    switch (chartData.type) {
      case "pie":
        return (
          <PieChart
            chartMeta={chartData.meta_data}
            dateRange={{ start: "", end: "" }}
            customKPI={true}
            chartData={chartData.data}
            kpiRendered={() => {}}
            chartReference={() => {}}
            showTable={showTableElement}
          />
        );

      case "line":
        return (
          <LineChart
            chartMeta={chartData.meta_data}
            dateRange={{ start: "", end: "" }}
            customKPI={true}
            chartData={chartData.data}
            kpiRendered={() => {}}
            chartReference={() => {}}
            showTable={showTableElement}
          />
        );

      case "multiple-line":
        return (
          <MultipleLineChart
            chartMeta={chartData.meta_data}
            dateRange={{ start: "", end: "" }}
            customKPI={true}
            chartData={chartData.data}
            kpiRendered={() => {}}
            chartReference={() => {}}
            showTable={showTableElement}
          />
        );
      case "area":
        return (
          <AreaChart
            chartMeta={chartData.meta_data}
            dateRange={{ start: "", end: "" }}
            customKPI={true}
            chartData={chartData.data}
            kpiRendered={() => {}}
            chartReference={() => {}}
            showTable={showTableElement}
          />
        );

      case "multiple-area":
        return (
          <MultipleAreaChart
            chartMeta={chartData.meta_data}
            dateRange={{ start: "", end: "" }}
            customKPI={true}
            chartData={chartData.data}
            kpiRendered={() => {}}
            chartReference={() => {}}
            showTable={showTableElement}
          />
        );

      case "column":
        return (
          <ColumnChart
            chartMeta={chartData.meta_data}
            dateRange={{ start: "", end: "" }}
            customKPI={true}
            chartData={chartData.data}
            kpiRendered={() => {}}
            chartReference={() => {}}
            showTable={showTableElement}
          />
        );

      case "grouped":
        return (
          <GroupedColumnChart
            chartMeta={chartData.meta_data}
            dateRange={{ start: "", end: "" }}
            customKPI={true}
            chartData={chartData.data}
            kpiRendered={() => {}}
            chartReference={() => {}}
            showTable={showTableElement}
          />
        );

      case "column-stacked":
        return (
          <ColumnStackedChart
            chartMeta={chartData.meta_data}
            dateRange={{ start: "", end: "" }}
            customKPI={true}
            chartData={chartData.data}
            kpiRendered={() => {}}
            chartReference={() => {}}
            showTable={showTableElement}
          />
        );

      case "bar":
        return (
          <BarChart
            chartMeta={chartData.meta_data}
            dateRange={{ start: "", end: "" }}
            customKPI={true}
            chartData={chartData.data}
            kpiRendered={() => {}}
            chartReference={() => {}}
            showTable={showTableElement}
          />
        );
      case "bar-stacked":
        return (
          <BarStackedChart
            chartMeta={chartData.meta_data}
            dateRange={{ start: "", end: "" }}
            customKPI={true}
            chartData={chartData.data}
            kpiRendered={() => {}}
            chartReference={() => {}}
            showTable={showTableElement}
          />
        );

      case "grouped-bar":
        return (
          <GroupedBarChart
            chartMeta={chartData.meta_data}
            dateRange={{ start: "", end: "" }}
            customKPI={true}
            chartData={chartData.data}
            kpiRendered={() => {}}
            chartReference={() => {}}
            showTable={showTableElement}
          />
        );

      case "table":
        return (
          <DataTableMolecule
            chartData={chartData.data}
            chartMeta={chartData.meta_data}
          />
        );

      case "pivot":
        return (
          <GroupTable
            chartData={chartData.data}
            chartMeta={chartData.meta_data}
          />
        );

      default:
        break;
    }
  };

  const render = () => {
    if (
      !valid["seriesValid"] ||
      !valid["chartTypeValid"] ||
      !valid["uniqueAxisNameValid"] ||
      isError
    ) {
      return (
        <Grid
          sx={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          item
        >
          <img src={ChartThumbnail} height="60" alt="" />
          <Typography
            sx={{ color: theme.custom.primaryGreyColor }}
            variant="inter_500_12"
            mt={1}
          >
            {authorizedAccess
              ? builderState?.chart_type?.chart_type
                ? "Select valid data series to see the preview"
                : "Select a chart to see the preview"
              : ""}
            {!authorizedAccess &&
              `You don’t have access to KPI Builder. Please reach out to admin to get access`}
          </Typography>
        </Grid>
      );
    } else if (
      valid.seriesValid &&
      valid.chartTypeValid &&
      valid.uniqueAxisNameValid &&
      !kpiDataAvaliable &&
      !isError
    ) {
      return (
        <Grid
          sx={{
            textAlign: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
          item
        >
          <CircularProgress />
        </Grid>
      );
    } else if (
      kpiDataAvaliable &&
      valid.seriesValid &&
      valid.chartTypeValid &&
      valid.uniqueAxisNameValid &&
      !isError
    ) {
      return pickChartsBasedOnType();
    }
  };

  return (
    <Box>
      <Grid mt={1} container>
        <Grid sx={{ position: "relative", width: "100%" }} item pr={2}>
          <Grid sx={{ width: "100%", height: 350 }} container>
            <Grid item sm={12}>
              {render()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(KPIPreview);
