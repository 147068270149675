import {
  Box,
  Typography,
  Stack,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell, TableBody
} from "@mui/material";
import { useState } from "react";
import { theme } from "../../theme";
import { numberFormatter } from "../../utils/dataFormatters";
import MagicWriteIcon from "../../assets/magic_wand_multi_color.png";
import TrackIcon from "../../assets/target.png";
import DocumentsIcon from "../../assets/fi-rr-document.png";

import "./TopBudgetItem.scss";
import {
  BudgetItemData,
  QuickBudgetItemTypes,
} from "../../Types/QuickBudgetItemTypes";
import { isEmpty, orderBy } from "lodash";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import BudgetDocumentsMolecule from "../molecules/BudgetDocumentsMolecule";
import BudgetAnalysisModal from "../molecules/BudgetManagement/BudgetAnalysisModal";
import BudgetCircularProgressBar from "./BudgetCircularProgressBar";
import {ArrowDropDown, ArrowDropUp, ArrowUpward} from "@mui/icons-material";
type Props = {
  item: QuickBudgetItemTypes;
  as_of: string;
};

const TopBudgetItem = (props: Props) => {
  const { item, as_of } = props;
  const navigate = useNavigate();
  const [openDocuments, setOpenDocuments] = useState<boolean>(false);
  const [openBudgetAnalysis, setOpenBudgetAnalysis] = useState<boolean>(false);
  let status = "active";

  const calculateProgressPercentage = (timeElapsed: any) => {
    let value = 0;
    if (!isEmpty(timeElapsed)) {
      const endDate = moment(timeElapsed?.end_date);
      const startDate = moment(timeElapsed?.start_date);
      value =
        (+timeElapsed.time_lapse / (endDate.diff(startDate, "days") + 1)) * 100;
    }

    return value;
  };

  const editBudgetUrlHelper = (item: any) => {
    var str = item.budget_name.replace(
      /[` ~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
      "-"
    );
    return `/budgets/${str}_${item.budget_uid}`;
  };

  const onBudgetView = (item: any) => {
    navigate(editBudgetUrlHelper(item));
  };

  const getVarianceColors = (ele: BudgetItemData) : {color: string, type: string} => {
    if (ele.is_credit_account) {
      if (ele.actual > ele.budgeted_to_date) {
        return {color: 'green', type: 'success'};
      }
    } else {
      if (ele.budgeted_to_date > ele.actual) {
        return {color: 'green', type: 'success'};
      }
    }
    return {color: 'red', type: 'warning'};
  }

  const getVarianceArrow = (ele: BudgetItemData) => {
      if (ele.actual > ele.budgeted_to_date) {
        return (<ArrowDropUp sx={{color: getVarianceColors(ele).color, fontSize: 20}} />);
      }
      return (<ArrowDropDown sx={{color: getVarianceColors(ele).color, fontSize: 20}} />);
  }

  const navigateToBudgetTracker = (item: any) => {
    let filter_data: any = {
      program_single: {
        action: "delete",
        label: item.program_name,
        value: item.program_id,
      },
      contract: {
        action: "delete",
        label: item.contract_title,
        program: item.program_id,
        value: item.contract_id,
      },
    };

    navigate(
      `/budgets-monitoring?encoded_filter_data=${btoa(
        JSON.stringify(filter_data)
      )}#contracts`
    );
  };

  return (
    <>
      <div>
        <div
          className="quick-budget-item-container"
          style={{
            border: `1px solid ${theme.custom.borderColor}`,
            borderRadius: "6px",
            overflowY: "hidden",
            height: 160,
            width: "400px"
          }}
        >
          <div className="quick-budget-item-container-sub">
            <div style={{padding: 10}}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography noWrap variant="inter_p_600_16" maxWidth={"95%"}>
                  {item.budget_name}
                </Typography>
                <span
                  style={{
                    marginLeft: 5,
                    height: 10,
                    width: 10,
                    borderRadius: "50%",
                    content: " ",
                    background: status === "active" ? "green" : "red",
                  }}
                ></span>
              </div>
            </div>
            <Box>
              <TableContainer sx={{overflowX: "hidden"}}>
                <Table sx={{fontSize: 12}} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="right">
                      </TableCell>
                      <TableCell sx={{paddingY:"5px", paddingX:0}} align="right">
                        <Typography
                          sx={{ ml: 1 }}
                          variant="inter_400_12"
                          noWrap
                        >
                          Actual
                        </Typography>
                      </TableCell>
                      <TableCell sx={{paddingY:"5px", paddingX:0, lineHeight:"1rem"}} align="right">
                        <Typography
                          sx={{ ml: 1 }}
                          variant="inter_400_12"
                          noWrap
                        >
                          Budgeted<br/>(to date)
                        </Typography>
                      </TableCell>
                      <TableCell sx={{paddingY:"5px", paddingX:0, paddingRight:"10px", lineHeight:"0.7rem"}} align="right">
                        <Typography
                          sx={{ ml: 1 }}
                          variant="inter_400_12"
                          noWrap
                        >
                          Remaining<br/>Balance
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item &&
                      orderBy(item?.budget_item_data, ["position"], "asc")
                        .map((ele: BudgetItemData, index: number) => {
                          return (
                            <TableRow
                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell sx={{paddingY:"5px", paddingLeft:"10px", width: "20%"}}>
                                <Stack alignItems={"center"} direction={"row"}>
                                  <span style={{ height: 15, width: 15 }}>
                                    <BudgetCircularProgressBar
                                      budgetData={ele}
                                      value={
                                        ele["budgeted_to_date"] > 0
                                          ? (ele["actual"] /
                                            ele["budgeted_to_date"]) *
                                          100
                                          : 0
                                      }
                                    />
                                  </span>
                                  <Typography
                                    sx={{ ml: 1, maxWidth: 100, color: theme.custom.primaryDarkColor }}
                                    variant="inter_400_12"
                                    noWrap
                                  >
                                    {ele?.label || ""}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell sx={{paddingY:"5px", paddingX:0,width: "15%"}} align="right">
                                {/*for revenue account, check if actuals is greater than budgeted to date to be green else red*/}
                                <Stack alignItems={"center"} justifyContent={"end"} direction={"row"}>
                                  {getVarianceArrow(ele)}
                                  <Typography
                                    sx={{color: getVarianceColors(ele).color }}
                                    variant="inter_400_12"
                                    noWrap
                                  >
                                    {numberFormatter(ele.actual - ele.budgeted_to_date, "$", true, 2, true)}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell sx={{paddingY:"5px", paddingX:0,width: "20%"}} align="right">
                                <Typography
                                  sx={{ml: 1, color: theme.custom.primaryDarkColor }}
                                  variant="inter_400_12"
                                  noWrap
                                >
                                  {numberFormatter(ele.actual, "$", true, 2)}
                                </Typography>
                              </TableCell>
                              <TableCell sx={{paddingY:"5px", paddingX:0,width: "20%"}} align="right">
                                <Typography
                                  sx={{ ml: 1, color: theme.custom.primaryDarkColor }}
                                  variant="inter_400_12"
                                  noWrap
                                >
                                  {numberFormatter(ele.budgeted_to_date, "$", true, 2)}
                                </Typography>
                              </TableCell>
                              <TableCell sx={{paddingY:"5px", paddingX:0,paddingRight:"10px", width: "20%"}} align="right">
                                <Typography
                                  sx={{ ml: 1, color: getVarianceColors(ele).color }}
                                  variant="inter_400_12"
                                  noWrap
                                >
                                  {numberFormatter(ele?.remaining_balance, "$", true, 2)}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    {
                      item?.budget_item_data.length === 1 && (
                        <TableRow>
                          <TableCell colSpan={5} sx={{
                            padding: 0,
                            border: "none",
                            height: "30px"
                          }}>
                            &nbsp;
                          </TableCell>
                        </TableRow>
                      )
                    }
                    <TableRow>
                      <TableCell colSpan={5} sx={{
                        padding: 0,
                        backgroundColor: "#ccc",
                        border: "none",
                        borderBottomLeftRadius: "6px",
                        borderBottomRightRadius: calculateProgressPercentage(item.time_lapse) < 100 ? "0px" : "6px",
                        height: "20px"
                      }}>
                        <div style={{
                          height: "20px",
                          width: `${Math.min(
                            calculateProgressPercentage(item.time_lapse),
                            100
                          )}%`,
                          color: "#000 !important",
                          background: theme.custom.accentBgColor,
                          borderBottomLeftRadius: "6px",
                          borderBottomRightRadius: "6px"
                        }}>
                        </div>
                        <Typography variant={"inter_400_10"} sx={{
                          position: "absolute",
                          bottom: 0,
                          left: 144
                        }}>
                          {item.time_lapse.time_lapse}/{moment(item.end_date).diff(item.start_date, 'days')} days
                          elapsed
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </div>
          <div className="middle">
            <div style={{marginBottom: 10}}>
              <Button
                onClick={() => onBudgetView(item)}
                sx={{width: 30}}
                variant="outlined"
              >
                View
              </Button>
            </div>
            <div style={{display: "flex", background: "#ffff"}}>
              <img
                onClick={() => setOpenBudgetAnalysis(true)}
                style={{marginRight: "10px", cursor: "pointer"}}
                src={MagicWriteIcon}
                height={20}
                width={20}
                alt="AI Assistant"
              />
              <img
                onClick={() => navigateToBudgetTracker(item)}
                style={{ marginRight: "10px", cursor: "pointer" }}
                src={TrackIcon}
                height={20}
                width={20}
                alt="Track budget"
              />
              <img
                onClick={() => setOpenDocuments(true)}
                style={{ cursor: "pointer" }}
                src={DocumentsIcon}
                height={20}
                width={20}
                alt="Documents"
              />
            </div>
          </div>
        </div>
        {openDocuments && (
          <BudgetDocumentsMolecule
            open={openDocuments}
            close={() => setOpenDocuments(false)}
            budget_uid={item.budget_uid}
            modal={true}
          />
        )}
        {openBudgetAnalysis && (
          <BudgetAnalysisModal
            open={openBudgetAnalysis}
            close={() => setOpenBudgetAnalysis(false)}
            budgetId={item.budget_uid}
          />
        )}
      </div>
    </>
  );
};

export default TopBudgetItem;
