import React from "react";
import { Typography } from "@mui/material";
import { theme } from "../../../theme";

type Props = {
  row: any;
  cell: any;
  cellValue: any;
  onCellClick: Function;
  isReport: boolean;
};

const CustomHyperLinkCell = (props: Props) => {
  const { row, cellValue } = props;

  return (
    <a
      href={
        row?.original?.transaction_external_link?.value?.primary_value || ""
      }
      target="_blank"
      rel="noreferrer"
      className="hyperlink"
      style={{ pointerEvents: props.isReport ? "none" : "all" }}
    >
      <Typography
        sx={{ color: theme.custom.primaryDarkColor, lineHeight: "0.9rem" }}
        variant={
          row?.original?.type === "TOTAL_HEADER"
            ? "inter_400_12"
            : "inter_500_12"
        }
      >
        {cellValue?.["value"]?.["primary_value"] || ""}
      </Typography>
    </a>
  );
};

export default CustomHyperLinkCell;
