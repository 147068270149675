import axios from "axios";
import { environment } from "../environments/environment-dev";

export const listAllExcelFiles = () => {
  let url = `${environment.connectorApiUrl}/connectors/excel/list`;
  return axios.get(url);
};

export const deleteExcelFileBasedOnId = (rowId: string) => {
  let url = `${environment.connectorApiUrl}/connectors/excel/${rowId}`;
  return axios.delete(url);
};

export const createConnection = (uploadUrl: string, fileName: string) => {
  let url = `${environment.connectorApiUrl}/connectors/excel/create/`;
  return axios.post(url, { s3_url: uploadUrl, file_name: fileName });
};

export const deleteAllExcelFiles = () => {
  let url = `${environment.connectorApiUrl}/connectors/excel`;
  return axios.delete(url);
};

export const updateExcelFile = (id: string, uploadUrl: string, fileName: string ) => {
  let url = `${environment.connectorApiUrl}/connectors/excel/${id}`;
  return axios.put(url, { s3_url: uploadUrl, file_name: fileName });
};
