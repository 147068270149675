import React, { memo, useState, SyntheticEvent } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography, Box } from "@mui/material";
import MemberDataPermissionDetails from "../molecules/user-permission-management/MemberDataPermissionDetails";
import MemberBoardPermissionDetails from "../molecules/user-permission-management/MemberBoardPermissionDetails";
import MemberOtherModulePermissionDetails from "../molecules/user-permission-management/MemberOtherModulePermissionDetails";
import { theme } from "../../theme";

type Props = {
  memberDetails: any;
};

const MemberPermissionDetails = (props: Props) => {
  const { memberDetails } = props;
  const [value, setValue] = useState(0);

  const switchTab = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`member-details-tabpanel-${index}`}
        aria-labelledby={`member-details-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ mt: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `member-details-tab-${index}`,
      "aria-controls": `member-details-tabpanel-${index}`,
    };
  }

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          sx={{
            "& button.Mui-selected": { color: theme.palette.primary.main },
          }}
          value={value}
          onChange={switchTab}
          aria-label="member-details-tabs"
        >
          <Tab
            label={<Typography variant="inter_p_600_16">Data</Typography>}
            {...a11yProps(0)}
          />
          <Tab
            label={<Typography variant="inter_p_600_16">Boards</Typography>}
            {...a11yProps(1)}
          />
          <Tab
            label={
              <Typography variant="inter_p_600_16">
                Other Permissions
              </Typography>
            }
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <MemberDataPermissionDetails
          dataPermissions={memberDetails["data_permissions"]}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MemberBoardPermissionDetails
          boardPermissions={memberDetails["board_kpi_permissions"]}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <MemberOtherModulePermissionDetails
          otherModulePermissions={memberDetails["other_module_permissions"]}
        />
      </TabPanel>
    </>
  );
};

export default memo(MemberPermissionDetails);
