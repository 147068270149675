import axios from "axios";
import { POST_KPIS_FOR_BOARD } from "../endpoints/board-endpoints";
import { environment } from "../environments/environment-dev";

const getChartsForBoard = (
  slug: string,
  survey_value?: string,
  filters?: any
) => {
  let url = `${POST_KPIS_FOR_BOARD}${slug}/kpis`;
  if (slug === "survey-indicators") {
    url = `${POST_KPIS_FOR_BOARD}${slug}/kpis`;
    return axios.post(url, { survey: survey_value });
  } else {
    if (filters) {
      return axios.post(url, { data: filters });
    }
    return axios.post(url);
  }
};

export const updateBoardLayout = (layout: any, data: any) => {
  let url = `${POST_KPIS_FOR_BOARD}layout`;
  return axios.put(url, { layout: layout, data: data });
};

export const updateInvoiceState = (obj: any) => {
  let url = `${environment.connectorApiUrl}/invoiced-state`;
  return axios.put(url, obj);
};

export const previewAttachment = (attachmentId: string) => {
  let url = `${environment.connectorApiUrl}/attachments/${attachmentId}`;
  return axios.get(url);
};

export const downloadAttachments = (payload: any) => {
  let url = `${environment.connectorApiUrl}/attachments/download`;
  return axios.post(url, payload);
};

export default getChartsForBoard;
