import React, { useState, useEffect, memo } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableBody,
  Typography,
  TableRow,
  Grid,
  Box,
  Tooltip,
} from "@mui/material";
import { theme } from "../../theme";
import { ProgramFilterItem } from "../../Types/ProgramFilterItem";
import AddBudgetValuesForm from "./AddBudgetValuesForm";
import { BudgetItem } from "../../Types/BudgetDetailsItem";
import {
  numberFormatter,
  returnAccountsString,
} from "../../utils/dataFormatters";
import {
  addBudgetItem,
  deleteBudgetItem,
  editBudgetItem,
} from "../../services/budget-service";
import { BudgetItemData } from "../../Types/BudetItemData";
import DeleteIcon from "../../assets/deleteIcon.png";
import PencilIcon from "../../assets/pencil.png";
import LoaderIcon from "../../assets/loader.png";
import EditBudgetValueIcon from "../../assets/Line_arrows.png";
import _ from "lodash";
import { useAppDispatch } from "../../store/hook";
import { showMessage } from "../../store/error-handler-store";
import NonRatableBudgetModal from "./BudgetManagement/NonRatableBudgetModal";
interface Props {
  accounts?: ProgramFilterItem[];
  tableData: { label: string; items: BudgetItem[]; position: 0 };
  type: string;
  budget_uid: string;
  is_editable: boolean;
  latestPosition: number;
  loading: boolean;
  getLatest: Function;
  index: number;
}

const AddBudgetDataTableMolecule: React.FC<Props> = (data) => {
  let latestPosition = data.latestPosition;
  let index = data.index;
  let type = data.type;
  let editable = data.is_editable;
  let budget_uid = data.budget_uid;
  let getLatestData = data.getLatest;

  const [tableData, setTableData] = useState(data.tableData);
  const [showProgress, setShowProgress] = useState<boolean>(data.loading);
  const [accounts, setAccounts] = useState(data.accounts || []);
  const dispatch = useAppDispatch();
  const [openNonRatableBudgetModal, setOpenNonRatableBudgetModal] =
    useState<boolean>(false);

  const [activeBudgetItem, setActiveBudgetItem] = useState({} as any);

  useEffect(() => {
    if (tableData.items.length > 0) {
      let temp = [...tableData.items];
      setTableData((prev) => {
        return {
          ...prev,
          items: temp.map((ele) => {
            return { ...ele, edit: false };
          }),
        };
      });
    }
  }, [data.tableData]);

  const accountsFormatter = (arr: ProgramFilterItem[]) => {
    return arr.map((ele) => {
      return {
        ref_account_id: ele.id,
        ref_account_name: ele.name,
      };
    });
  };

  const onAdd = (dataForTable: any) => {
    setShowProgress(true);
    let obj: BudgetItemData = {
      position: dataForTable.position || latestPosition + 1,
      id: dataForTable.id,
      type: type,
      amount: Number(dataForTable.budget).toFixed(2),
      category_name: dataForTable.category,
      accounts: accountsFormatter(dataForTable.accounts),
      remarks: dataForTable.remarks,
    };
    if (dataForTable.edit) {
      editBudgetItem(budget_uid, dataForTable?.id, obj).then((res) => {
        if (res.status === 200) {
          getLatestData(index, showProgress);
        } else if (res.status === 403) {
          dispatch(
            showMessage({ message: "You don't have the permission to edit" })
          );
        }
      });
    } else {
      addBudgetItem(budget_uid, obj).then((res) => {
        if (res.status === 403) {
          dispatch(
            showMessage({ message: "You don't have the permission to add" })
          );
          return;
        }

        if (res.status === 200) {
          obj.id = res?.data?.budget_item_id;
          getLatestData(index, showProgress);
        }
      });
    }
  };

  const onDeleteItem = (id: number) => {
    if (!editable) {
      return;
    }
    setShowProgress(true);
    let confirm = window.confirm("Are you sure to delete?");

    if (confirm) {
      deleteBudgetItem(budget_uid, id).then((res) => {
        if (res.status === 403) {
          dispatch(
            showMessage({ message: "You don't have the permission to delete" })
          );
          setShowProgress(false);
          return;
        }
        if (res.status === 404) {
          dispatch(showMessage({ message: "Something went wrong." }));
          setShowProgress(false);
          return;
        }
        getLatestData(index, showProgress);
      });
    } else {
      setShowProgress(false);
    }
  };

  const onEditRow = (index: number) => {
    if (!editable) {
      return;
    }
    let temp = [...tableData.items];
    temp[index]["edit"] = true;
    setTableData((prev) => {
      return { ...prev, items: temp };
    });
  };

  const updateCancel = (id: number) => {
    let temp = [...tableData.items];
    let row = temp.filter((ele) => ele.id === id);
    row[0].edit = false;
    setTableData((prev) => {
      return { ...prev, items: temp };
    });
  };

  return (
    <React.Fragment>
      <TableContainer
        sx={{
          position: "relative",
          border: `1px solid ${theme.dataTableColors.borderColor}`,
          borderRadius: "6px",
        }}
      >
        <Table>
          <TableHead sx={{ background: theme.dataTableColors.headerBgColor }}>
            <TableCell>
              <Typography variant="inter_p_600_16">Category</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="inter_p_600_16">Accounts</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="inter_p_600_16">Budget Value</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="inter_p_600_16">Remarks</Typography>
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableHead>
          <TableBody>
            {editable && (
              <AddBudgetValuesForm
                extraSX={{ backgroundColor: theme.custom.chipGroupBackground }}
                onAdd={onAdd}
                accounts={accounts}
                tableData={tableData.items}
                position={tableData.position}
                className={`budget_${type}_add`}
              />
            )}
            {tableData &&
              tableData.items.length > 0 &&
              tableData.items.map((ele: any, index) => {
                return (
                  <React.Fragment key={index}>
                    {!ele.edit && (
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="inter_400_14">
                            {ele.category_name}
                          </Typography>{" "}
                        </TableCell>
                        <TableCell>
                          <Typography variant="inter_400_14">
                            {returnAccountsString(
                              ele.accounts,
                              "ref_account_name"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ minWidth: 200 }}>
                          <div>
                            <Grid container alignItems={"center"}>
                              <Grid item mr={1.5}>
                                <Typography
                                  sx={{
                                    padding: ele.is_non_ratable ? 0.5 : 0,
                                    borderRadius: "6px",
                                    background: ele.is_non_ratable
                                      ? theme.custom.secondaryYellowColor
                                      : "white",
                                  }}
                                  variant="inter_400_14"
                                >
                                  {numberFormatter(+ele.amount, "$")}
                                </Typography>
                              </Grid>
                              <Grid item mt={0.3}>
                                <img
                                  onClick={() => {
                                    setActiveBudgetItem({
                                      ...ele,
                                      budget_uid: budget_uid,
                                    });
                                    setOpenNonRatableBudgetModal(true);
                                  }}
                                  style={{ cursor: "pointer" }}
                                  src={EditBudgetValueIcon}
                                  height={20}
                                  width={20}
                                  alt="Add/Edit Non Ratable Amount"
                                />
                              </Grid>
                            </Grid>
                          </div>
                        </TableCell>
                        <TableCell>
                          <Typography variant="inter_400_14">
                            {ele.remarks}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              editable
                                ? "Delete"
                                : "You are not allowed to perform this action"
                            }
                          >
                            <img
                              onClick={() => onDeleteItem(ele.id)}
                              style={{
                                marginRight: 20,
                                cursor: "pointer",
                                opacity: editable ? 1 : 0.5,
                              }}
                              height={24}
                              width={20}
                              alt="delete"
                              src={DeleteIcon}
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              editable
                                ? "Edit"
                                : "You are not allowed to perform this action"
                            }
                          >
                            <img
                              onClick={() => onEditRow(index)}
                              style={{
                                cursor: "pointer",
                                opacity: editable ? 1 : 0.5,
                              }}
                              height={24}
                              width={20}
                              alt="edit"
                              src={PencilIcon}
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    )}
                    {ele.edit && (
                      <AddBudgetValuesForm
                        extraSX={{
                          backgroundColor: theme.custom.chipGroupBackground,
                        }}
                        editMode={true}
                        values={ele}
                        is_non_ratable={ele.is_non_ratable}
                        onAdd={onAdd}
                        position={tableData.position}
                        accounts={accounts}
                        tableData={tableData.items}
                        updateCancel={updateCancel}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            <TableRow>
              <TableCell>
                <Typography variant="inter_p_600_16">Total</Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Typography variant="inter_p_600_16">
                  {numberFormatter(
                    _.sumBy(tableData.items, function (o: any) {
                      return +o.amount;
                    }),
                    "$"
                  )}
                </Typography>
              </TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box sx={{ display: showProgress ? "block" : "none" }}>
          <Grid
            className="show-loader"
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <img src={LoaderIcon} height="100" width="100" alt="Loader" />
            </Grid>
          </Grid>
        </Box>
      </TableContainer>
      <NonRatableBudgetModal
        openModal={openNonRatableBudgetModal}
        closeModal={(refresh: boolean = false) => {
          setOpenNonRatableBudgetModal(false);
          if (refresh) {
            setShowProgress(true);
            getLatestData(index, showProgress);
          }
        }}
        payload={activeBudgetItem}
      />
    </React.Fragment>
  );
};

export default memo(AddBudgetDataTableMolecule);
