import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { theme } from "../../../theme";
import { filterTypeObject } from "../../../Types/FilterTypeObject";
import CancelIcon from "../../../assets/cancel.png";
import ArrowDownIcon from "../../../assets/movedown.png";

type Props = {
  id: string;
  filtersList: Array<any>;
  onSelectFilter: Function;
  filterTypes: Array<filterTypeObject>;
  onDeleteFilter: Function;
  savedFilter: any;
};


const CustomKPISelectFilter = (props: Props) => {
  const {
    filtersList,
    onSelectFilter,
    id,
    filterTypes,
    onDeleteFilter,
    savedFilter,
  } = props;

  const [availableFilters, setAvailableFilters] = useState([] as any);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setAvailableFilters(filtersList);
  }, [filtersList]);

  const onFilterTypeChange = (value: string) => {
    let _selectedFilter = { ...savedFilter, filterType: value };
    onSelectFilter(id, _selectedFilter);
  };

  const deleteFilter = (filterId: string) => {
    let confirm = window.confirm("Are you sure to delete the filter?");
    if (confirm) {
      onDeleteFilter(filterId);
    }
  };

  const selectFilterItem = (ele: any) => {
    onSelectFilter(id, ele);
    handleClose();
  };

  const displaySelectedFilter = () => {
    if (
      savedFilter.workbookName &&
      savedFilter.sheetName &&
      savedFilter.columnName
    ) {
      return (
        <Typography
          variant="inter_p_500_14"
          sx={{ color: theme.custom.primaryDarkColor }}
        >
          {savedFilter.workbookName +
            ">" +
            savedFilter.sheetName +
            ">" +
            savedFilter.columnName}
        </Typography>
      );
    } else {
      return (
        <Typography
          variant="inter_p_500_14"
          sx={{ color: theme.custom.primaryDarkColor }}
        >
          Please select the filter
        </Typography>
      );
    }
  };

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item sm={9.5}>
            <div style={{ width: "100%", position: "relative" }}>
              <Button
                fullWidth
                variant="outlined"
                sx={{ height: 40, borderColor: "#ccc" }}
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item>{displaySelectedFilter()}</Grid>
                  <Grid item>
                    <img
                      style={{ marginTop: 10 }}
                      src={ArrowDownIcon}
                      alt="arrow_down"
                    />
                  </Grid>
                </Grid>
              </Button>
              <Menu
                MenuListProps={{
                  "aria-labelledby": "filter-button",
                  sx: { width: anchorEl && anchorEl.offsetWidth },
                }}
                PaperProps={{
                  sx: {
                    borderRadius: "6px",
                  },
                }}
                id="filter-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {availableFilters &&
                  availableFilters.length > 0 &&
                  availableFilters.map((ele: any, index: number) => {
                    if(!ele) return
                    if (ele.columnId && ele.workbookId && ele.sheetId) {
                      return (
                        <MenuItem
                          sx={{
                            height: 40,
                            borderBottom: `1px solid ${theme.custom.borderColor}`,
                          }}
                          onClick={() => selectFilterItem(ele)}
                          key={index}
                          value={ele}
                        >
                          <Typography variant="inter_p_500_14" mt={0.3}>
                            {ele.workbookName +
                              " > " +
                              ele.sheetName +
                              " > " +
                              ele.columnName}
                          </Typography>
                        </MenuItem>
                      );
                    }
                  })}
              </Menu>
            </div>
          </Grid>
          <Grid item sm={2}>
            <TextField
              size="small"
              select
              sx={{ minWidth: 150 }}
              label={
                <Typography
                  variant="inter_500_12"
                  sx={{ color: theme.custom.primaryGreyColor }}
                >
                  Filter type
                </Typography>
              }
              value={savedFilter.filterType || ""}
              fullWidth
              onChange={(event) => onFilterTypeChange(event.target.value)}
            >
              {filterTypes &&
                filterTypes.length > 0 &&
                filterTypes.map((ele: filterTypeObject, index: number) => {
                  return (
                    <MenuItem
                      sx={{
                        height: 40,
                        borderBottom: `1px solid ${theme.custom.borderColor}`,
                      }}
                      key={index}
                      value={ele.value}
                    >
                      <Typography variant="inter_p_500_14" mt={0.3}>
                        {ele.label}
                      </Typography>
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
          {
            <Grid item sm={0.5}>
              <img
                onClick={() => deleteFilter(id)}
                src={CancelIcon}
                style={{ cursor: "pointer", marginTop: 15 }}
                height="12"
                width="12"
                alt="delete"
              />
            </Grid>
          }
        </Grid>
      </Box>
    </>
  );
};

export default CustomKPISelectFilter;
