import { LoadingButton } from "@mui/lab";
import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import PrintIcon from "../../../assets/print.png";
import {
  createPublicReportPdf,
  createReportLink,
  createReportPdf,
} from "../../../services/impact-report-service";
import { theme } from "../../../theme";
import ShareDialog from "./ShareDialog";

import { useNavigate } from "react-router-dom";
import PrintStatusPopover from "../../atoms/PrintStatusPopover";

import PresentationIcon from "../../../assets/Play icon 1.png";
import BackButton from "../../../assets/back_button.png";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
type Props = {
  reportName: string;
  reportId: any;
  onZoomChange: any;
  zoom: any;
  is_editable?: boolean;
  is_shareable?: boolean;
  is_exportable?: boolean;
  isPublicManuallySet?: boolean;
  publicReportId?: string;
  mode?: string;
  pages: number;
};

function ImpactReportPublicToolbar({
  reportName,
  reportId,
  onZoomChange,
  zoom,
  is_editable,
  isPublicManuallySet,
  is_shareable,
  is_exportable,
  publicReportId,
  mode,
  pages,
}: Props) {
  const [isPrinting, setIsPrinting] = React.useState(false);
  const [_zoom, setZoom] = useState(zoom);
  const [showDialog, setShowDialog] = React.useState("");
  const [openedPrintStatusPopover, setPrintStatusPopover] = useState(false);
  const printStatusPopoverAnchor = useRef(null);
  const [progress, setProgress] = useState(10);
  const [reportPDF, setReportPDF] = useState(null);

  let userInfo = localStorage.getItem("userinfo");

  const navigate = useNavigate();
  const updateZoom = (e: any) => {
    setZoom(e.target.value);
    onZoomChange(e.target.value);
  };

  const printPage = () => {
    setProgress(10);
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 90 ? prevProgress : prevProgress + 1
      );
    }, 50 + pages * 20);
    if (!publicReportId) return;
    setIsPrinting(true);
    if (mode === "public") {
      createPublicReportPdf(publicReportId, pages)
        .then((resp) => {
          if (resp && resp.status === 200) {
            setProgress(100);
            setReportPDF(resp.data);
            clearInterval(timer);
          }
        })
        .finally(() => {
          setIsPrinting(false);
        });
    } else {
      createReportPdf(reportId, pages)
        .then((resp) => {
          if (resp && resp.status === 200) {
            setProgress(100);
            setReportPDF(resp.data);
            clearInterval(timer);
          }
        })
        .finally(() => {
          setIsPrinting(false);
        });
    }
  };

  const openPrintStatusPopover = () => {
    setPrintStatusPopover(true);
  };

  const closePrintStatusPopover = () => {
    if (reportPDF) {
      Object.assign(document.createElement("a"), {
        target: "_blank",
        rel: "noreferrer",
        href: URL.createObjectURL(reportPDF),
      }).click();
    }
    setPrintStatusPopover(false);
  };

  const [generatingLinkPresentation, setGeneratingLinkPresentation] =
    useState(false);

  const dispatch = useAppDispatch();

  const openPresentation = () => {
    setGeneratingLinkPresentation(true);
    createReportLink(reportId, {
      password: "",
      is_public: true,
    }).then((resp) => {
      if (resp && resp.status === 201) {
        window.open(
          new URL(resp.data.full_link_presentation[0]).href,
          "_blank"
        );
      }
      if (resp && resp.status === 400 && resp.data.emails) {
        dispatch(
          showMessage({
            message: resp.data.emails[0],
            extraProps: {
              anchorOrigin: { vertical: "top", horizontal: "right" },
            },
          })
        );
      }
      setGeneratingLinkPresentation(false);
    });
  };


  return (
    <Grid
      container
      alignItems={"center"}
      justifyContent={"space-between"}
      direction={"row"}
      px={2}
      sx={{ position: "relative" }}
    >
      <Grid item>
        <div style={{ display: "flex" }}>
          {userInfo && userInfo.length > 0 && (
            <div>
              <img
                onClick={() => navigate("/impact-report")}
                src={BackButton}
                alt="Back"
                style={{
                  maxHeight: "20px",
                  marginTop: "5px",
                  marginRight: "50px",
                  cursor: "pointer",
                  position: "relative",
                }}
              ></img>
            </div>
          )}
          <div>
            <Typography
              variant="inter_p_600_16"
              noWrap
              sx={{ maxWidth: 200, marginTop: "5px", display: "block" }}
            >
              {reportName}
            </Typography>
          </div>
        </div>
      </Grid>
      <Grid item>
        <Stack direction="row" alignItems={"center"} justifyItems={"end"}>
          <>
            <Tooltip
              title={
                is_shareable || !isPublicManuallySet
                  ? "Share"
                  : "You don't have access for this action"
              }
            >
              <Typography
                onClick={() => {
                  if (is_shareable || !isPublicManuallySet) {
                    setShowDialog("share");
                  }
                }}
                variant="poppins_p_600_14"
                sx={{
                  mr: 2,
                  color: theme.impactReportingColors.shareTextColor,
                  cursor: "pointer",
                  opacity: is_shareable || !isPublicManuallySet ? 1 : 0.5,
                }}
              >
                Share
              </Typography>
            </Tooltip>
            <ShareDialog
              reportId={reportId}
              showDialog={showDialog}
              setShowDialog={setShowDialog}
            />
          </>

          <Tooltip
            title={
              is_shareable || !isPublicManuallySet
                ? "Show presentation"
                : "You don't have access to this action"
            }
          >
            <LoadingButton
              sx={{
                height: 1,
                minWidth: "54px",
                opacity: is_shareable || !isPublicManuallySet ? 1 : 0.5,
              }}
              loading={generatingLinkPresentation}
              onClick={() => {
                if (is_shareable || !isPublicManuallySet) {
                  openPresentation();
                }
              }}
            >
              {generatingLinkPresentation ? (
                ""
              ) : (
                <img
                  src={PresentationIcon}
                  alt="Back"
                  style={{
                    maxHeight: "25px",
                    cursor: "pointer",
                  }}
                ></img>
              )}
            </LoadingButton>
          </Tooltip>

          {publicReportId && (
            <>
              <Tooltip
                ref={printStatusPopoverAnchor}
                title={
                  is_exportable || mode === "public"
                    ? "Print Report"
                    : "You don't have access to this action"
                }
              >
                <LoadingButton
                  loading={isPrinting}
                  fullWidth
                  sx={{ height: 1 }}
                  onClick={() => {
                    if (is_exportable || mode === "public") {
                      printPage();
                      openPrintStatusPopover();
                    }
                  }}
                >
                  <img
                    src={PrintIcon}
                    alt="Back"
                    style={{
                      maxHeight: "25px",
                      opacity: is_exportable || mode === "public" ? 1 : 0.5,
                      cursor: "pointer",
                      display: isPrinting ? "none" : "block",
                    }}
                  ></img>
                </LoadingButton>
              </Tooltip>
              <PrintStatusPopover
                id={`print_status`}
                progress={progress}
                open={openedPrintStatusPopover}
                closePopup={closePrintStatusPopover}
                anchorEl={printStatusPopoverAnchor.current}
              ></PrintStatusPopover>
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
}

export default React.memo(ImpactReportPublicToolbar);
