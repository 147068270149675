import SearchIcon from "./assets/search.png";

import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useDeepCompareEffect } from "react-use";
import useLocalStorageState from "use-local-storage-state";
import "./App.scss";
import { CompaniesObject } from "./Types/CompaniesObject";
import Profile from "./assets/profile_1.png";
import ProfileIcon from "./assets/profile_icon.png";
import MenuSelected from "./assets/selectedopt.png";
import UserSettingsIcon from "./assets/user-setting.png";
import ToastMessage from "./components/atoms/ToastMessage";
import SpotlightDialog from "./components/molecules/SpotlightDialog";
import { USER_INFO_LOCAL_STORAGE_KEY } from "./constants/constants";
import Board from "./pages/Board";
import BudgetListPage from "./pages/BudgetListPage";
import ConnectPage from "./pages/ConnectPage";
import ConnectorAuthCallback from "./pages/ConnectorAuthCallback";
import { ConnectorListPage } from "./pages/ConnectorsListPage";
import CreateImpactReportPage from "./pages/CreateImpactReportPage";
import CustomKPIListPage from "./pages/CustomKPIListPage";
import EditBudgetPage from "./pages/EditBudgetPage";
import ExcelConnectorFilesListPage from "./pages/ExcelConnectorFilesListPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import GoogleSheetConnectionsListPage from "./pages/GoogleSheetConnectorFileListPage";
import ImpactReportEditor from "./pages/ImpactReportEditor";
import ImpactReportPage from "./pages/ImpactReportPage";
import ImpactReportPublic from "./pages/ImpactReportPublic";
import KPIBuilderPage from "./pages/KPIBuilderPage";
import MappingCompleted from "./pages/MappingComplete";
import MemberDetailsPage from "./pages/MemberDetailsPage";
import MyProfilePage from "./pages/MyProfilePage";
import NotFoundPage from "./pages/NotFoundPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import UserManagementPage from "./pages/UserManagementPage";
import UserMapping from "./pages/UserMapping";
import Login from "./pages/login";
import { clearLocalStorage } from "./services/authentication-service";
import {
  getCompanies,
  getCompanyDataFromLocalStorage,
  setCompanyDataToLocalStorage,
} from "./services/userinfo-service";
import { useAppSelector } from "./store/hook";
import { theme } from "./theme";
import AuthGuard from "./utils/AuthGuard";
import AuthNavGuard from "./utils/AuthNavGuard";
import CreateImpactReportAIPage from "./pages/CreateImpactReportAIPage";
import BudgetMonitoringPage from "./pages/BudgetMonitoringPage";
import { RecentHistory } from "./Types/UserObject";
import PanoramaPage from "./pages/PanoramaPage";
import MetricsPage from "./pages/MetricsPage";
import SuprSendInbox from "@suprsend/react-inbox";
import { environment } from "./environments/environment-dev";

function App() {
  const [localStorageInfo, setLocalStorageInfo] = useLocalStorageState<any>(
    USER_INFO_LOCAL_STORAGE_KEY,
    {}
  );
  const [userInfo, setUserInfo] = useState<any>(localStorageInfo);
  const navigate = useNavigate();
  const location = useLocation();
  let token = localStorage.getItem("pebbletoken");
  let suprsendWorkspaceKey = environment.suprsendWorkspaceKey || "";
  useDeepCompareEffect(() => {
    if (localStorageInfo) {
      setUserInfo(
        typeof localStorageInfo === "string"
          ? JSON.parse(localStorageInfo)
          : localStorageInfo
      );
    }
  }, [localStorageInfo]);

  const errState: any = useAppSelector((state) => state.errorHandler);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElementSettings, setAnchorElementSettings] =
    useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const openSettings = Boolean(anchorElementSettings);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickSettings = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElementSettings(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseSettings = () => {
    setAnchorElementSettings(null);
  };
  const [companies, setCompanies] = useState<CompaniesObject[]>([]);
  const [currentCompany, setCurrentCompany] = useState<CompaniesObject>({
    company_id: "",
    company_name: "",
    start_date: "",
  });

  const handleChange = (event: any) => {
    setCompanyDataToLocalStorage(
      JSON.stringify(
        companies.filter((ele) => ele.company_id === event.target.value)[0]
      )
    );
    setCurrentCompany(
      companies.filter((ele) => ele.company_id === event.target.value)[0]
    );
    window.location.reload();
  };

  const onClickSettingItems = (item: string) => {
    switch (item) {
      case "manage-users":
        setAnchorElementSettings(null);
        navigate("/members");
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (token) {
      getAllCompanies();
      let companyData = JSON.parse(getCompanyDataFromLocalStorage());
      setCurrentCompany(companyData);
    }
  }, [token]);

  const getAllCompanies = () => {
    getCompanies().then((res) => {
      if (res?.data?.data) {
        setCompanies(res.data.data);
      }
    });
  };

  const returnDefaultCompany = () => {
    let temp: CompaniesObject[] = companies.filter((company) => {
      if (company.company_id === currentCompany.company_id) {
        return company;
      }
    });

    return temp.length > 0 ? temp[0].company_name : "";
  };

  const [history, setHistory] = useState<RecentHistory[]>(
    JSON.parse(localStorage.getItem("recent_history") || "[]")
  );

  useEffect(() => {
    const location = window.location.pathname;
    if (location === "/") return; // ignore root page

    let newHistory = history;
    const existingLocationIdx = history.findIndex(
      (i) => i.location === location
    );
    if (existingLocationIdx !== -1) {
      newHistory.splice(existingLocationIdx, 1);
    }
    newHistory = [
      { title: document.title, location },
      ...newHistory.slice(0, 4),
    ];
    setHistory(newHistory);
    localStorage.setItem("recent_history", JSON.stringify(newHistory));
  }, [window.location.pathname, document.title]);

  const onLogOut = () => {
    clearLocalStorage();
    navigate("/login");
    setAnchorEl(null);
  };

  const [openGS, setOpenGS] = React.useState(false);

  const handleCloseGS = () => {
    setOpenGS(false);
  };

  const onKeyDown = (e: any) => {
    if ((e.keyCode == 32 && e.ctrlKey) || (e.keyCode == 32 && e.metaKey)) {
      setOpenGS(true);
    }
    if (e.key === "Esc") {
      setOpenGS(false);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, []);

  return (
    <div style={{ position: "relative" }} className="App">
      {!location.pathname.match(/login$/) &&
        !location.pathname.match("/impact-report/.*") &&
        !location.pathname.match(/create-impact-report$/) &&
        !location.pathname.match(/create-impact-report-ai$/) &&
        !location.pathname.match(/connect$/) &&
        !location.pathname.match(/connector-auth-callback$/) && (
          <AppBar
            sx={{
              background: theme.custom.whiteColor,
              boxShadow: "none",
              borderBottom: `1px solid ${theme.custom.menuDividerColor}`,
              zIndex: 1202,
            }}
            position="fixed"
          >
            <Toolbar>
              {token &&
                !location.pathname.match(/connector-auth-callback$/) &&
                !location.pathname.match(/connect$/) &&
                !location.pathname.match(/forgot-password$/) &&
                !location.pathname.match(/resetpassword/) && (
                  <Grid
                    spacing={2}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    container
                  >
                    (
                    <Grid item>
                      <Button
                        onClick={() => setOpenGS(true)}
                        sx={{
                          borderRadius: "6px",
                          width: 150,
                          height: 46,
                          justifyContent: "flex-start",
                          border: `1px solid ${theme.custom.borderColor}`,
                        }}
                        variant="outlined"
                        startIcon={
                          <img
                            style={{ marginLeft: 5 }}
                            height="18px"
                            width="18px"
                            src={SearchIcon}
                            alt="Search"
                          />
                        }
                      >
                        <Typography variant="inter_400_14">Search</Typography>
                      </Button>
                    </Grid>
                    )
                    <Grid item>
                      <TextField
                        variant="filled"
                        sx={{
                          width: 150,
                          background: "#dbdbdb",
                          borderRadius: "6px",
                        }}
                        id="choose_company"
                        value={currentCompany.company_id}
                        defaultValue={currentCompany.company_id}
                        select
                        onChange={handleChange}
                        SelectProps={{
                          renderValue: returnDefaultCompany,
                          MenuProps: {
                            PaperProps: {
                              sx: {
                                bgcolor: theme.custom.whiteColor,
                                borderRadius: "6px",
                                "& .MuiMenuItem-root": {
                                  width: 350,
                                },
                                "& .MuiMenuItem-root: hover": {
                                  bgcolor: theme.custom.chipGroupBackground,
                                },
                                "&& .Mui-selected": {
                                  backgroundColor: `${theme.custom.whiteColor} !important`,
                                },
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "right",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                          },
                        }}
                      >
                        <MenuItem sx={{ px: 2, py: 3 }} value="">
                          <Typography
                            sx={{ color: theme.custom.primaryDarkColor }}
                            variant="inter60016"
                          >
                            Choose Company
                          </Typography>
                        </MenuItem>
                        <Divider />
                        {companies &&
                          companies.map((ele) => [
                            <MenuItem
                              sx={{ px: 2, py: 3 }}
                              value={ele.company_id}
                            >
                              <Grid alignItems={"center"} container>
                                <Grid item sm={8}>
                                  <Tooltip title={ele.company_name}>
                                    <Typography
                                      sx={{
                                        color: theme.custom.primaryDarkColor,
                                      }}
                                      variant="inter_500_12"
                                    >
                                      {ele.company_name}
                                    </Typography>
                                  </Tooltip>
                                </Grid>
                                {ele.company_id.toString() ===
                                  currentCompany.company_id.toString() && (
                                  <Grid textAlign={"right"} item sm={4}>
                                    <img
                                      src={MenuSelected}
                                      height="25px"
                                      alt="Checked"
                                    />
                                  </Grid>
                                )}
                              </Grid>
                            </MenuItem>,
                            <Divider variant="middle" />,
                          ])}
                      </TextField>
                    </Grid>
                    <Grid
                      className="notification-wrapper"
                      sx={{ maxWidth: 40, mt: 0.5 }}
                    >
                      <SuprSendInbox
                        workspaceKey={suprsendWorkspaceKey}
                        subscriberId={userInfo?.user_info?.subscriber_id}
                        distinctId={userInfo?.user_info?.distinct_id}
                        theme={{ bell: { width: 50, height: 35 } }}
                      />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        display:
                          userInfo?.user_info?.role === "admin" ||
                          userInfo?.user_info?.role === "owner"
                            ? "block"
                            : "none",
                      }}
                    >
                      <div>
                        <img
                          id="Settings"
                          onClick={handleClickSettings}
                          src={UserSettingsIcon}
                          alt="Settings"
                          style={{
                            maxHeight: "50px",
                            maxWidth: "50px",
                            cursor: "pointer",
                          }}
                        />
                        <Menu
                          id="settings-menu"
                          anchorEl={anchorElementSettings}
                          open={openSettings}
                          onClose={handleCloseSettings}
                          MenuListProps={{
                            "aria-labelledby": "settings-menu",
                          }}
                          PaperProps={{
                            sx: {
                              borderRadius: 3,
                              marginTop: 1,
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem sx={{ minWidth: 200, padding: 2 }}>
                            <Typography
                              variant="inter_p_600_16"
                              sx={{ color: theme.custom.primaryDarkColor }}
                            >
                              Settings
                            </Typography>
                          </MenuItem>
                          <Divider variant="middle" />
                          <MenuItem
                            sx={{ minWidth: 200, padding: 2 }}
                            onClick={() => onClickSettingItems("manage-users")}
                          >
                            <Typography
                              variant="inter_500_12"
                              sx={{ color: theme.custom.primaryDarkColor }}
                            >
                              Manage Users
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </div>
                    </Grid>
                    <Grid item>
                      <div>
                        <img
                          id="profile"
                          onClick={handleClick}
                          src={ProfileIcon}
                          alt="profile"
                          style={{
                            maxHeight: "26px",
                            maxWidth: "26px",
                            cursor: "pointer",
                          }}
                        />
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "basic-menu",
                          }}
                          PaperProps={{
                            sx: {
                              borderRadius: 3,
                              marginTop: 1,
                            },
                          }}
                          transformOrigin={{
                            horizontal: "right",
                            vertical: "top",
                          }}
                          anchorOrigin={{
                            horizontal: "right",
                            vertical: "bottom",
                          }}
                        >
                          <MenuItem
                            sx={{
                              marginY: 0,
                              background: theme.custom.profileBackgroundColor,
                              borderTopRightRadius: "6px",
                              borderTopLeftRadius: "6px",
                            }}
                            onClick={() => {
                              navigate("/my-profile");
                            }}
                          >
                            <Grid
                              container
                              spacing={0}
                              direction="column"
                              alignItems="center"
                              justifyContent="center"
                              sx={{ height: "100%" }}
                            >
                              <Grid item xs={12} mt={2}>
                                <img
                                  src={Profile}
                                  height="50"
                                  width={50}
                                  alt="prfile"
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="poppins_h5_600_18">
                                  {userInfo?.user_info?.first_name +
                                    " " +
                                    userInfo?.user_info?.last_name}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Typography variant="inter_500_10">
                                  {userInfo?.user_info?.username}
                                </Typography>
                              </Grid>
                            </Grid>
                          </MenuItem>
                          <Divider variant="middle" />
                          <MenuItem
                            sx={{ minWidth: 200, padding: 2 }}
                            onClick={() => {
                              navigate("/my-profile");
                            }}
                          >
                            <Typography
                              variant="inter_500_12"
                              sx={{ color: theme.custom.primaryDarkColor }}
                            >
                              My Profile
                            </Typography>
                          </MenuItem>
                          <Divider variant="middle" />
                          <MenuItem
                            sx={{ minWidth: 200, padding: 2 }}
                            onClick={onLogOut}
                          >
                            <Typography
                              variant="inter_500_12"
                              sx={{ color: theme.custom.primaryDarkColor }}
                            >
                              Logout
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </div>
                    </Grid>
                  </Grid>
                )}
            </Toolbar>
          </AppBar>
        )}
      {!location.pathname.match(/login$/) && <Toolbar />}
      <Routes>
        <Route
          path="login"
          element={
            <AuthNavGuard>
              <Login />
            </AuthNavGuard>
          }
        />
        <Route
          path="connectors/list"
          element={
            <AuthGuard>
              <ConnectorListPage />
            </AuthGuard>
          }
        />
        <Route
          path="/connectors/:connectorSlug/connect"
          element={
            <AuthGuard>
              <ConnectPage />
            </AuthGuard>
          }
        />
        <Route
          path="/connectors/:connectorSlug/connector-auth-callback"
          element={
            <AuthGuard>
              <ConnectorAuthCallback />
            </AuthGuard>
          }
        />
        <Route
          path="/board/:slug_name"
          element={
            <AuthNavGuard>
              <Board />
            </AuthNavGuard>
          }
        />
        <Route
          path="/connectors/:connectorSlug/user-mapping"
          element={
            <AuthGuard>
              <UserMapping />
            </AuthGuard>
          }
        />
        <Route
          path="/connectors/:connectorSlug/status"
          element={
            <AuthGuard>
              <MappingCompleted />
            </AuthGuard>
          }
        />
        <Route
          path="/connectors/excel/list"
          element={
            <AuthGuard>
              <ExcelConnectorFilesListPage />
            </AuthGuard>
          }
        />
        <Route
          path="/connectors/googlesheet/list"
          element={
            <AuthGuard>
              <GoogleSheetConnectionsListPage />
            </AuthGuard>
          }
        />
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
        <Route
          path="/members"
          element={
            <AuthNavGuard>
              <UserManagementPage />
            </AuthNavGuard>
          }
        />
        <Route
          path="/resetpassword/:passwordkey"
          element={<ResetPasswordPage />}
        />
        <Route
          path="/budgets"
          element={
            <AuthNavGuard>
              <BudgetListPage />
            </AuthNavGuard>
          }
        />
        <Route
          path="/budgets-monitoring/*"
          element={
            <AuthNavGuard>
              <BudgetMonitoringPage />
            </AuthNavGuard>
          }
        />
        <Route
          path="/budgets/:budget_id"
          element={
            <AuthNavGuard>
              <EditBudgetPage />
            </AuthNavGuard>
          }
        />

        <Route
          path="/kpi-builder/list"
          element={
            <AuthNavGuard>
              <CustomKPIListPage />
            </AuthNavGuard>
          }
        />
        <Route
          path="/kpi-builder"
          element={
            <AuthNavGuard>
              <KPIBuilderPage />
            </AuthNavGuard>
          }
        />
        <Route
          path="/kpi-builder/:slug_name"
          element={
            <AuthNavGuard>
              <KPIBuilderPage />
            </AuthNavGuard>
          }
        />
        <Route
          path="*"
          element={
            <AuthNavGuard invalidRoute={true}>
              <p></p>
            </AuthNavGuard>
          }
        />
        <Route
          path="/impact-report"
          element={
            <AuthGuard>
              <ImpactReportPage />
            </AuthGuard>
          }
        />
        <Route
          path="/impact-report/public/:id"
          element={<ImpactReportPublic />}
        />
        <Route
          path="/impact-report/:id"
          element={
            <AuthGuard>
              <ImpactReportEditor />
            </AuthGuard>
          }
        />
        <Route
          path="/create-impact-report"
          element={
            <AuthGuard>
              <CreateImpactReportPage />
            </AuthGuard>
          }
        ></Route>
        <Route
          path="/create-impact-report-ai"
          element={
            <AuthGuard>
              <CreateImpactReportAIPage />
            </AuthGuard>
          }
        ></Route>
        <Route
          path="/members/:uuid"
          element={
            <AuthNavGuard>
              <MemberDetailsPage />
            </AuthNavGuard>
          }
        />
        <Route
          path="/my-profile"
          element={
            <AuthNavGuard>
              <MyProfilePage />
            </AuthNavGuard>
          }
        />

        <Route
          path="/panorama"
          element={
            <AuthNavGuard>
              <PanoramaPage />
            </AuthNavGuard>
          }
        />
        <Route
          path="/metrics/:slug"
          element={
            <AuthNavGuard>
              <MetricsPage />
            </AuthNavGuard>
          }
        />
      </Routes>
      <Dialog
        open={openGS}
        onClose={handleCloseGS}
        sx={{
          zIndex: (th: any) => th.zIndex.drawer + 10000,
        }}
        PaperProps={{
          sx: {
            border: `1px solid ${theme.custom.borderColor}`,
            width: "576px",
          },
        }}
      >
        <DialogContent sx={{ p: 0 }}>
          <SpotlightDialog
            openGS={openGS}
            handleCloseGS={handleCloseGS}
            companies={companies}
            history={history}
          />
        </DialogContent>
      </Dialog>
      {errState.error["message"] && <ToastMessage />}
    </div>
  );
}

export default App;
