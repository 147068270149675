import { isArray, isNumber, isObject, sortBy, sumBy, values } from "lodash";
import { Navigate } from "react-router-dom";

export const dataExists = (arr: Array<any>, key: string) => {
  return arr.some(function (el) {
    return el.value === key;
  });
};

export const copyToClipboard = async (data: ClipboardItems) => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.write(data);
  }

  return false;
};

export const pasteFromClipboard = async () => {
  if ("clipboard" in navigator) {
    return await navigator.clipboard.read();
  }

  return false;
};

export const isJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const sortObjects = (obj: any, sortKey: string) => {
  let sortedData: any = [];
  if (isObject(obj)) {
    sortedData = sortBy(Object.values(obj), [
      function (o: any) {
        return o[sortKey];
      },
    ]);
  }
  return sortedData;
};

export const countNumbers = (data: any): number => {
  if (isArray(data)) {
    // If the current value is an array, sum the counts for each element
    return sumBy(data, countNumbers);
  }

  if (isObject(data)) {
    // If the current value is an object, sum the counts for each value
    return sumBy(values(data), countNumbers);
  }

  // Base case: If the current value is a number, return 1, otherwise return 0
  return isNumber(numberWithCommas(data)) ? 1 : 0;
};

function numberWithCommas(x: string) {
  return x && isNaN(+x) && parseFloat(x.replace(/,/g, ""));
}

export function isValidUrl(url: string): boolean {
  try {
    const parsedUrl = new URL(addHttpsToUrl(url));
    // Check for a valid domain name (at least two parts, each part 1-63 chars long, only alphanumeric and hyphens)
    const domainRegex = /^[a-zA-Z0-9-]{1,63}(\.[a-zA-Z0-9-]{1,63})+$/;
    return domainRegex.test(parsedUrl.hostname);
  } catch (error) {
    return false;
  }
}

export function addHttpsToUrl(url: string): string {
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    url = "https://" + url;
  }
  return url;
}
