import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Wordcloud from "highcharts/modules/wordcloud";
import { returnFileName } from "../../utils/file-name-helper";
import { ChartProps } from "../../Types/ChartProps";
Wordcloud(Highcharts);

const returnWordCloudData = (wordCloudData: any) => {
  var str = wordCloudData[0]["text"];
  str = str.replace(/[^a-z0-9+]+/gi, " ");
  var words = str.split(/[,\. ]+/g);
  return Highcharts.reduce(
    words,
    function (arr: string[], word: string) {
      var obj: any = {};
      obj = Highcharts.find(arr, function (temp: any) {
        return temp.name === word;
      });
      if (obj) {
        obj["weight"] += 1;
      } else {
        obj = {
          name: word,
          weight: 1,
        };
        arr.push(obj);
      }
      return arr;
    },
    []
  );
};

const WordCloudChart: React.FC<ChartProps> = (chartData) => {
  let data = chartData.chartData;
  let meta_data = chartData.chartMeta;
  let date_range = chartData.dateRange;
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);

  const options = {
    accessibility: {
      screenReaderSection: {
        beforeChartFormat:
          "<h5>{chartTitle}</h5>" +
          "<div>{chartSubtitle}</div>" +
          "<div>{chartLongdesc}</div>" +
          "<div>{viewTableButton}</div>",
      },
    },
    plotOptions: {
      wordcloud: {
        events: {
          afterAnimate: function () {
            chartData.kpiRendered(true);
            chartData.chartReference(chartComponentRef);
            if (chartData.customKPI === false) {
              chartComponentRef.current?.chart.reflow();
            }
          },
        },
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      filename: returnFileName(meta_data?.chart?.title || "", date_range),
    },
    series: [
      {
        type: "wordcloud",
        data: returnWordCloudData(data),
        name: meta_data.chart.title,
      },
    ],
    title: {
      text: "",
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{ style: { width: "100%", height: "100%" } }}
      options={options}
      ref={chartComponentRef}
    ></HighchartsReact>
  );
};

export default WordCloudChart;
