import {Badge, Grid, Stack} from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { Sidebar } from "../components/complexes/Sidebar";
import BudgetContractsMonitoring from "../components/complexes/budget-monitoring/BudgetContractsMonitoring";
import BudgetProgramsMonitoring from "../components/complexes/budget-monitoring/BudgetProgramsMonitoring";
import { setFiltersLoaded } from "../store/filter-state-store";
import { useAppDispatch } from "../store/hook";
import { theme } from "../theme";
import { useLocation } from "react-router-dom";
import BudgetAlertsMonitoring from "../components/complexes/budget-monitoring/BudgetAlertsMonitoring";
import {getBudgetsAlertsCount} from "../services/alerts-service";
type Props = {};

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BudgetMonitoringPage = (props: Props) => {
  const [value, setValue] = useState(0);
  const [budgetsAlertsCount, setBudgetsAlertsCount] = useState(0);
  const dispatch = useAppDispatch();
  const [isTabClicked, setIsTabClicked] = useState({
    contracts: false,
    programs: false,
  });
  const location = useLocation();
  const [kpiSlug, setKpiSlug] = useState("");

  useEffect(() => {
    document.title = "Budget Monitoring - Pebble";
  }, []);

  useEffect(() => {
    let tabType = location.pathname.split("/").pop();
    switch (tabType) {
      case "contracts":
        setValue(0);
        break;
      case "programs":
        setValue(1);
        break;
      case "alerts":
        setValue(2);
        break;
      default:
    }
  }, [location]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setIsTabClicked((prev) => {
      return {
        ...prev,
        contracts: newValue === 0 ? true : false,
        programs: newValue === 1 ? true : false,
        alerts: newValue === 2 ? true : false,
      };
    });
    dispatch(setFiltersLoaded(false));
  };

  useEffect(() => {
    if (location.hash !== "") {
      let [tabType, _kpiSlug] = location.hash.slice(1).split("#");
      setKpiSlug(_kpiSlug);
      switch (tabType) {
        case "contracts":
          setValue(0);
          break;
        case "programs":
          setValue(1);
          break;
        case "alerts":
          setValue(2);
          break;
        default:
          setValue(0);
      }
    }
  }, [location]);

  useEffect(() => {
    getBudgetsAlertsCount().then(res => {
      if (res.status === 200 && res.data?.data?.response?.status === 200) {
        setBudgetsAlertsCount(res.data?.data?.response?.data[0]?.budget_alert_count)
      }
    })
  }, [])

  return (
    <>
      <Stack direction="row">
        <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
          <Sidebar />
        </Box>
        <Grid container sx={{ height: "100%" }}>
          <Grid item md={12}>
            <Box mt={10} px={8}>
              <Typography variant="poppins_h4_600_20">
                Budgets Monitoring
              </Typography>
            </Box>
            <Box mt={5}>
              <Box sx={{ width: "100%" }}>
                <Box mx={6} sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="budget-tracker-monitor"
                  >
                    <Tab
                      label={
                        <Typography variant="inter_p_600_14">
                          Contracts
                        </Typography>
                      }
                      {...a11yProps(0)}
                    />
                    <Tab
                      label={
                        <Typography variant="inter_p_600_14">
                          Programs
                        </Typography>
                      }
                      {...a11yProps(1)}
                    />
                    <Tab
                      label={
                        <Typography variant="inter_p_600_14">
                          Alerts <Badge sx={{paddingLeft: 2}} badgeContent={budgetsAlertsCount} color="error"/>
                        </Typography>
                      }
                      {...a11yProps(2)}
                    />
                    {/* <Tab
                      label={
                        <Typography variant="inter_p_600_14">
                          Organization
                        </Typography>
                      }
                      {...a11yProps(2)}
                    /> */}
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <BudgetContractsMonitoring
                    kpiSlug={kpiSlug}
                    isTabClicked={isTabClicked.contracts}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <BudgetProgramsMonitoring
                    kpiSlug={kpiSlug}
                    isTabClicked={isTabClicked.programs}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <BudgetAlertsMonitoring />
                </CustomTabPanel>
                {/* <CustomTabPanel value={value} index={2}>
                  <BudgetOrganizationMonitoring />
                </CustomTabPanel> */}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
};

export default BudgetMonitoringPage;
