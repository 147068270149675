import { Box } from "@mui/material";
import React, { CSSProperties } from "react";
import { useDrag } from "react-dnd";
import { DropResultType } from "./DropTarget";

const defaultStyle: CSSProperties = {
  border: "1px solid #3937A5",
  borderRadius: "6px",
  backgroundColor: "white",
  padding: "0.5rem 1rem",
  marginRight: "8px",
  marginBottom: "8px",
  cursor: "move",
  float: "left",
};

interface DraggableItemType {
  targetDataType?: string;
  dropId?: string;
  id: any;
  name: string;
  sx?: any;
  type: string;
  style?: any;
  item?: any;
  extraProps?: any;
  children?: React.ReactNode;
  onDragEnd?: any;
  targetDataId?: any;
}

interface DraggableResultType {
  id: any;
  name: string;
  type: string;
  [x: string]: any;
}

const DraggableItem = React.memo(function (item: DraggableItemType) {
  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: item.type,
      item: item,
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult<DropResultType>();
        if (item && item.onDragEnd) {
          let itemResult: DraggableResultType = {
            ...item.item,
            id: item.id,
            name: item.name,
            type: item.type,
          };
          item.onDragEnd(itemResult, dropResult);
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [item]
  );

  const opacity = isDragging ? 0.4 : 1;
  return (
    <>
      <Box
        ref={drag}
        style={{ ...(item.style ? item.style : defaultStyle), opacity }}
        sx={item?.sx}
        data-testid={`draggle-${item.id}`}
        {...item?.extraProps}
      >
        {item.children}
      </Box>
    </>
  );
});

export { DraggableItem };
export type { DraggableItemType, DraggableResultType };
