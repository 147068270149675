import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  numberFormatter,
  labelNumberFormatter,
  numberFormatterWithoutDecimals,
} from "../../utils/dataFormatters";
import {
  axisLabelStyle,
  chartColors,
  chartLabelsStyle,
  fontInter,
  returnChartColors,
  theme,
  tooltipColors,
} from "../../theme";
import { returnFileName } from "../../utils/file-name-helper";
import Waterfall from "highcharts/highcharts-more";
import { WaterfallSeriesObject } from "../../Types/WaterfallSeriesObject";
import { ChartProps } from "../../Types/ChartProps";
Waterfall(Highcharts);

const returnWaterfallData = (waterfallData: WaterfallSeriesObject[]) => {
  return (
    waterfallData &&
    waterfallData.length > 0 &&
    waterfallData.map((ele, index) => {
      return {
        name: ele.primary_name,
        y: ele.value,
        isSum: ele.isSum,
        isIntermediateSum: ele.isIntermediateSum,
        color:
          ele.isSum || ele.isIntermediateSum
            ? theme.custom.placeholderColor
            : chartColors.reverse()[index],
      };
    })
  );
};

const WaterfallChart: React.FC<ChartProps> = (chartData) => {
  let data = chartData.chartData;
  let meta_data = chartData.chartMeta;
  let data_type = meta_data?.chart?.currencytype || "$";
  let date_range = chartData.dateRange;
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const height = chartData.height;
  const width = chartData.width;
  const editorMode = chartData.editorMode;
  let disableDataLabels = meta_data.chart["data-label-disabled"] || false;
  let removeDecimal = meta_data.chart["remove-decimal"] || false;

  const options = {
    chart: {
      type: "waterfall",
      zoomType: editorMode ? "" : "xy",
      events: {
        render: function () {
          chartData.kpiRendered(true);
          chartData.chartReference(chartComponentRef);
          chartData.chartReference(chartComponentRef);
          if (chartData.customKPI === false) {
            chartComponentRef.current?.chart.reflow();
          }
        },
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      title: {
        text: meta_data.chart?.["x-axis-text"],
        style: axisLabelStyle,
        margin: 20,
      },
      type: "category",
      labels: {
        formatter: function (this: any) {
          return `<span style="${chartLabelsStyle}">${this.value}</span>`;
        },
      },
    },
    legend: {
      enabled: meta_data?.["legend"]?.["enabled"] || false,
      align: "center",
      verticalAlign: "top",
      x: 0,
    },
    yAxis: {
      title: {
        text: meta_data.chart["y-axis-text"]
          ? meta_data.chart["y-axis-text"]
          : null,
        style: axisLabelStyle,
      },
      labels: {
        formatter: function (this: any) {
          return `<span style="${chartLabelsStyle}">${labelNumberFormatter(
            this.value,
            data_type,
            true
          )}</span>`;
        },
      },
    },
    colors: returnChartColors(data && data.length),
    tooltip: {
      backgroundColor: tooltipColors.bgColor,
      borderColor: tooltipColors.borderColor,
      color: tooltipColors.dataFormatColor,
      padding: 10,
      borderRadius: 5,
      borderWidth: 1,
      headerFormat: ``,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        return `<span style="color: ${theme.custom.whiteColor}">${
          this.key
        }: ${labelNumberFormatter(this.y, data_type)}</span>`;
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    exporting: {
      filename: returnFileName(meta_data?.chart?.title || "", date_range),
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
      },
    },
    series: [
      {
        data: returnWaterfallData(data),
        dataLabels: {
          inside: true,
          enabled: !disableDataLabels,

          formatter: function (this: any) {
            return removeDecimal
              ? numberFormatterWithoutDecimals(this.y, data_type, true)
              : numberFormatter(this.y, data_type, true);
          },
          style: {
            fontSize: 9,
            fontFamily: fontInter,
            color: theme.custom.primaryDarkColor,
          },
        },
        pointPadding: 0,
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{
        style: {
          width: "100%",
          height: "calc(100% - 1px)",
        },
      }}
      options={
        editorMode
          ? {
              ...options,
              ...{ chart: { ...options.chart, height: height, width: width } },
            }
          : options
      }
      ref={chartComponentRef}
    ></HighchartsReact>
  );
};

export default WaterfallChart;
