import React from "react";
import { BudgetTypeDetails } from "../../Types/BudgetTypeDetails";
import { Box, Stack, Typography } from "@mui/material";
import { numberFormatter, labelNumberFormatter } from "../../utils/dataFormatters";
import { theme } from "../../theme";
import ArrowRightIcon from "../../assets/arrow_right_vector.png";
import Popover from "@mui/material/Popover";
import BudgetCircularProgressBar from "./BudgetCircularProgressBar"

type Props = {
  details: BudgetTypeDetails;
};

const BudgetTypeDetailsItem = (props: Props) => {
  const { details } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box>
        <Typography variant="inter_400_14">{details["label"] || ""}</Typography>
      </Box>
      <Box>
        <table style={{ borderCollapse: "collapse" }}>
          <tr style={{ borderBottom: "5px solid transparent" }}>
            <td>
              <Typography variant="inter_500_10">Current actuals</Typography>
            </td>
            <td></td>
            <td>
              <Typography
                variant="inter_500_10"
                sx={{ color: theme.custom.primaryGreyColor }}
              >
                Budget
              </Typography>
            </td>
          </tr>
          <tr>
            <td>
              <Stack direction={"row"} spacing={1.5} alignItems={"center"}>
                <Box
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  sx={{ height: "20px", width: "20px" }}
                >
                  <BudgetCircularProgressBar
                    budgetData={details}
                    value={(details["actuals"] / details["total_budget"]) * 100}
                  />

                  <Popover
                    id="mouse-over-popover"
                    sx={{
                      pointerEvents: "none",
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                  >
                    <div style={{ padding: 15 }}>
                      <div>
                        <Typography variant="inter_p_600_14">
                          Current actuals:{" "}
                          {labelNumberFormatter(details["actuals"] || 0, "$", false)}
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="inter_p_600_14">
                          Budgeted to date:{" "}
                          {labelNumberFormatter(
                            details["budgeted_to_date"] || 0,
                            "$",
                            false
                          )}
                        </Typography>
                      </div>
                      <div>
                        <Typography variant="inter_p_600_14">
                          Total budget:{" "}
                          {labelNumberFormatter(
                            details["total_budget"] || 0,
                            "$",
                            false
                          )}
                        </Typography>
                      </div>
                    </div>
                  </Popover>
                </Box>
                <Typography
                  variant="inter_p_600_14"
                  noWrap
                  sx={{ maxWidth: "8em", pr: "1.2em" }}
                >
                  {labelNumberFormatter(details["actuals"] || 0, "$", false)}
                </Typography>
              </Stack>
            </td>
            <td style={{ paddingRight: "1em" }}>
              <img
                style={{ height: "15px" }}
                src={ArrowRightIcon}
                alt="Arrow"
              />
            </td>
            <td>
              <Typography
                sx={{ color: theme.custom.primaryGreyColor }}
                variant="inter_p_600_14"
              >
                {labelNumberFormatter(details["total_budget"] || 0, "$", false)}
              </Typography>
            </td>
          </tr>
        </table>
      </Box>
    </>
  );
};

export default BudgetTypeDetailsItem;
