import { Stack, Box } from "@mui/material";
import Logo from "../../assets/Logo.png";
import { useNavigate } from "react-router-dom";
export const SimpleSidebar = () => {
  const navigate = useNavigate();
  const navigateToLogin = () => {
    navigate("/login");
  };
  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{ height: "100vh", width: "100px", boxShadow: 2 }}
    >
      <Box sx={{ position: "relative", top: "5%" }}>
        <img
          src={Logo}
          alt="Pebble"
          onClick={navigateToLogin}
          style={{ cursor: "pointer" }}
        ></img>
      </Box>
    </Stack>
  );
};
