import React from "react";
import {Grid, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import {MoreHoriz, VisibilityOutlined} from "@mui/icons-material";
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PencilIcon from "../../assets/pencil.png";

type Props = {
  isActive: boolean;
  isEditable: boolean;
  navigateToEditBudgetItem: Function;
  toggleBudgetActive: Function;
};

const BudgetListMoreMenu = (props: Props) => {
  const { isActive, isEditable, navigateToEditBudgetItem, toggleBudgetActive } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const ITEM_HEIGHT = 48;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        sx={{padding: 0}}
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreHoriz />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}
      >
        <MenuItem onClick={() => navigateToEditBudgetItem()} style={{margin: 5}}>
          {!isEditable ? (
            <>
              <VisibilityOutlined
                onClick={() => navigateToEditBudgetItem()}
                style={{
                  height: "15px",
                  width: "15px",
                  cursor: "pointer",
                  marginRight: "10px"
                }}
              />
              <Typography variant="inter_400_14">Preview</Typography>
            </>
          ) : (
            <>
              <img
                onClick={() => navigateToEditBudgetItem()}
                src={PencilIcon}
                alt="edit"
                style={{ height: "15px", width: "15px", cursor: "pointer", marginRight: "10px" }}
              />
              <Typography variant="inter_400_14">Edit</Typography>
            </>
          )}
        </MenuItem>
        <MenuItem onClick={() => {toggleBudgetActive(!isActive); handleClose();}} style={{margin: 5}}>
          <PowerSettingsNewIcon style={{height: "15px", width: "15px", cursor: "pointer", marginRight: "10px"}}/>
          <Typography variant="inter_400_14">{isActive ? "Make Inactive" : "Make Active"}</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}

export default React.memo(BudgetListMoreMenu);