import React, { memo } from "react";

import CustomKPIList from "../components/complexes/CustomKPIComplex";

const CustomKPIListPage = () => {
  return (
    <CustomKPIList/>
  );
};

export default memo(CustomKPIListPage);
