import {
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  Typography,
  Badge,
} from "@mui/material";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { setNavigationData } from "../../store/navigation-store";
import { theme } from "../../theme";
import { StyledList } from "../../utils/StyledList";
import {
  setFiltersLoaded,
  setSelectedKPI,
  setSelectedKPICurrentFilters,
  setSelectedKPITitle,
} from "../../store/filter-state-store";

export const SingleLevelMenuItem = ({
  item,
  menuClicked,
  onHoverMenuItem,
}: any) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector((state) => state.menuData.title);
  const menuItemClickHandler = () => {
    let url: string = "";
    // TODO: Move complete menu to BE config
    if (item.board_slug === "custom_kpis" && item.type !== "transaction") {
      url = `/kpi-builder`;
    } else if (item.type === "grid") {
      url = `/board/${item.board_slug}`;
    } else if (item.type === "story") {
      url = `/${item.board_slug}`;
    } else if (item.type === "table") {
      url = `/board/${item.board_slug}`;
    } else if (item.type === "transaction" && item.board_slug === "budgets") {
      url = `/${item.board_slug}`;
    } else if (
      item.type === "transaction" &&
      item.board_slug === "connectors-list"
    ) {
      url = `/connectors/list`;
    } else if (item.type === "budget") {
      url = `/${item.board_slug}`;
    } else if (
      item.type === "transaction" &&
      item.board_slug === "impact-report"
    ) {
      url = `/${item.board_slug}`;
    } else if (
      item.type === "transaction" &&
      item.board_slug === "kpi-builder"
    ) {
      url = `/kpi-builder/list`;
    } else if (
      item.board_slug === "matrices"
    ) {
      url = `/metrics/financial`;
    } else if (item.board_slug === "panorama") {
      url = `/panorama`;
    }
    dispatch(setNavigationData(item));
    navigate(url);
    dispatch(setSelectedKPICurrentFilters({ data: [], kpi_slug: "" }));
    dispatch(setSelectedKPI(""));
    dispatch(setSelectedKPITitle(""));
    dispatch(setFiltersLoaded(false));
    menuClicked();
  };

  const [mouseOverState, setMouseOverState] = useState<boolean>(false);

  const mouseOverEvent = () => {
    setMouseOverState(true);
  };

  const mouseOutEvent = () => {
    setMouseOverState(false);
  };

  return (
    <StyledList>
      <ListItemButton
        onMouseOver={mouseOverEvent}
        onMouseOut={mouseOutEvent}
        sx={{
          maxHeight: "45px",
          borderRadius: "6px",
          background:
            activeTab === item.title
              ? theme.sidebar.menuItemBackgroundColor
              : "",
        }}
        onClick={menuItemClickHandler}
      >
        <ListItemIcon onMouseOver={onHoverMenuItem} sx={{ cursor: "pointer" }}>
          {item.icon_handle &&
            (item.board_slug === "kpi-builder" ||
            item.board_slug === "custom_kpis" ? (
              <img
                src={
                  item.icon_handle
                    ? require(`../../assets/${item.icon_handle}.png`).default
                    : ""
                }
                alt=""
                style={{ height: "auto", maxWidth: "42px" }}
                className={mouseOverState ? "" : "grey-img"}
              />
            ) : (
              <img
                src={
                  item.icon_handle
                    ? require(`../../assets/${item.icon_handle}.png`).default
                    : ""
                }
                alt=""
                style={{ height: "auto", maxWidth: "42px" }}
                className={mouseOverState ? "" : "grey-img"}
              />
            ))}
        </ListItemIcon>
        <ListItemText sx={{ marginLeft: 2 }}>
          <Typography
            color={
              mouseOverState
                ? theme.palette.primary.main
                : theme.custom.primaryGreyColor
            }
            variant="inter_400_14"
          >
            {item.title}
          </Typography>
        </ListItemText>
      </ListItemButton>
    </StyledList>
  );
};
