import axios from "axios";
import {
  MemberDetailsAPIResponse,
  StepsAPIResponse,
  UserRoleAPIResponse,
} from "../Types/UserManagement";
import { UserObject } from "../Types/UserObject";
import {
  GET_MEMBER_DETAILS,
  GET_ROLES,
  GET_STEPS, GET_USER_PROFILE,
  PERMISSIONS_META,
  SAVE_MEMBER_DETAILS,
} from "../endpoints/user-management-endpoints";
import { environment } from "../environments/environment-dev";

export const addUser = (userData: UserObject) => {
  let url = `${environment.authUrl}/users/`;
  return axios.post(url, userData);
};

export const getAllUsers = () => {
  let url = `${environment.connectorApiUrl}/permissions/members`;
  return axios.get(url);
};

export const deleteUser = (uuid: string) => {
  let url = `${environment.connectorApiUrl}/permissions/members/uuid.${uuid}`;
  return axios.delete(url);
};

export const getAccessibleRoles = () => {
  return axios.get<UserRoleAPIResponse>(GET_ROLES);
};

export const getSteps = (role: string) => {
  return axios.get<StepsAPIResponse>(GET_STEPS(role));
};

export const getMemberDetails = (
  memberUUIDOrEmail: string,
  payloadType: string
) => {
  return axios.get<MemberDetailsAPIResponse>(
    GET_MEMBER_DETAILS(memberUUIDOrEmail, payloadType)
  );
};

export const getUserProfile = () => {
  return axios.get<MemberDetailsAPIResponse>(GET_USER_PROFILE);
};

export const saveUserProfile = (body: any) => {
  return axios.put(GET_USER_PROFILE, body);
};

export const saveMemberDetails = (body: any) => {
  return axios.put<MemberDetailsAPIResponse>(SAVE_MEMBER_DETAILS, body);
};

export const getPermissionsMeta = (permission_slug: string, email: string) => {
  return axios.get<any>(PERMISSIONS_META(permission_slug, email));
};
