import {
  Box,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuIcon from "../../assets/fi-rr-menu-dots.png";
import { theme } from "../../theme";
import { FileMetaType } from "../../Types/FileMetaType";

import { set } from "lodash";
import { useNavigate } from "react-router";
import { io } from "socket.io-client";
import DeleteIcon from "../../assets/deleteIcon.png";
import LoaderIcon from "../../assets/loader.png";
import { environment } from "../../environments/environment-dev";
import {
  deleteAllConnections,
  deleteWorkbookByUid,
  fetchAllGoogleSheets,
  syncWorkbook,
} from "../../services/googlesheets-connection-service";
import { showMessage } from "../../store/error-handler-store";
import { useAppDispatch } from "../../store/hook";
import GoogleSheetConnectorFileAdd from "../molecules/GoogleSheetConnector/GoogleSheetConnectorFileAdd";
import GoogleSheetConnectorFileListItem from "../molecules/GoogleSheetConnector/GoogleSheetConnectorFileListItem";

type Props = {};
const GoogleSheetConnectorFileList = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [googleSheetList, setGoogleSheetList] = useState([] as FileMetaType[]);
  const [isLoading, setIsLoading] = useState(false);
  const [connectionSyncStatus, setConnectionSyncStatus] = useState({} as any);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [googleSheetOwner, setGoogleSheetOwner] = useState("");

  const open = Boolean(anchorEl);

  useEffect(() => {
    ListAllGoogleSheets();
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const deleteGoogleSheetWorkbookItem = (uid: string) => {
    deleteWorkbookByUid(uid).then((res) => {
      if (res.status === 200) {
        ListAllGoogleSheets();
        dispatch(
          showMessage({
            message: "Workbook deleted successfully",
            variant: "success",
          })
        );
      }
    });
  };
  useEffect(() => {
    const socket = io(environment.socketUrl || "");
    socket.on("connect", () => {
      console.log("Connected to Socket!!!");
    });
    socket.emit("join", { room: environment.socketRoom });
    socket.on("my_response", function (msg) {
      if (
        msg.data.hasOwnProperty("connector_id") &&
        msg.data.hasOwnProperty("event_status")
      ) {
        let temp = { ...connectionSyncStatus };
        set(temp, [msg.data.connector_id], {
          connector_id: msg.data.connector_id,
          status: msg.data.event_status,
          last_successful_sync_time: msg.data.event_time,
        });
        setConnectionSyncStatus(temp);
      }
    });
  }, []);

  const ListAllGoogleSheets = () => {
    setIsLoading(true);
    fetchAllGoogleSheets().then((response) => {
      if (response.status === 200) {
        setGoogleSheetList(response.data.data);
        setGoogleSheetOwner(response.data.user);
      }
      setIsLoading(false);
    });
  };

  const deleteConnection = () => {
    let confirm = window.confirm(
      "Are you sure you want to delete the connection?"
    );
    if (confirm) {
      setIsLoading(true);
      deleteAllConnections().then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          dispatch(
            showMessage({
              message: "Connection deleted successfully",
              variant: "success",
            })
          );
          navigate("/connectors/list");
        }
      });
    }
  };

  const syncGoogleSheetWorkbook = (uid: string) => {
    syncWorkbook(uid).then((res) => {
      dispatch(
        showMessage({
          message: "Sync started",
          variant: "success",
        })
      );
    });
  };

  return (
    <>
      <Grid
        mb={2}
        textAlign={"right"}
        item
        md={12}
        sx={{ position: "relative" }}
      >
        {googleSheetList.length > 0 && (
          <Grid container flexDirection={"row"} justifyContent={"flex-end"}>
            <Grid item mr={2}>
              <Stack direction="row" alignItems={"center"} spacing={0.5}>
                <GoogleSheetConnectorFileAdd
                  extraSx={{
                    sx: { height: 40, width: 100 },
                    variant: "outlined",
                  }}
                  refresh={ListAllGoogleSheets}
                  owner={googleSheetOwner}
                />
              </Stack>
            </Grid>
            <Grid item style={{ display: "flex" }}>
              <Stack direction="row" alignItems={"center"} spacing={0.5}>
                <img
                  style={{
                    verticalAlign: "middle",
                    filter: `brightness(0) invert(0)`,
                    marginRight: 5,
                    cursor: "pointer",
                  }}
                  height={13}
                  width={20}
                  src={MenuIcon}
                  alt="Menu"
                  onClick={handleClick}
                />
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={deleteConnection}>
                    <Grid
                      mb={1}
                      container
                      direction="row"
                      justifyContent="left"
                      alignItems="center"
                    >
                      <Grid item mt={0.5}>
                        <img
                          style={{ height: "18px", width: "18px" }}
                          src={DeleteIcon}
                          alt="Delete"
                        />
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{ color: theme.custom.primaryGreyColor, ml: 1 }}
                          variant="inter_p_500_14"
                        >
                          Delete Connection
                        </Typography>
                      </Grid>
                    </Grid>
                  </MenuItem>
                </Menu>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Grid>
      <div
        style={{
          border: `1px solid ${theme.custom.borderColor}`,
          borderRadius: "6px",
          position: "relative",
        }}
      >
        {googleSheetList.length > 0 ? (
          <Box p={4}>
            <Box>
              <Typography
                variant="inter_p_600_14"
                sx={{ color: theme.custom.primaryDarkColor }}
              >
                {googleSheetList.length} workbooks
              </Typography>
            </Box>
            <Box sx={{ overflowY: "auto" }}>
              {googleSheetList &&
                googleSheetList.length > 0 &&
                googleSheetList.map((workbook, index: number) => {
                  return (
                    <>
                      <>
                        <GoogleSheetConnectorFileListItem
                          googleSheetMeta={workbook}
                          connectionSyncStatus={
                            connectionSyncStatus[
                              workbook.external_reference_slug
                            ]
                          }
                          deleteGoogleSheetWorkbookItem={
                            deleteGoogleSheetWorkbookItem
                          }
                          syncGoogleSheetWorkbook={syncGoogleSheetWorkbook}
                        />
                      </>
                      {!(googleSheetList.length === index + 1) && (
                        <Divider
                          orientation="horizontal"
                          sx={{ width: "100%", height: 2 }}
                        />
                      )}
                    </>
                  );
                })}
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={{ height: 400, display: isLoading ? "block" : "none" }}>
              <Grid
                className="show-loader"
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid item>
                  <img src={LoaderIcon} height="100" width="100" alt="Loader" />
                </Grid>
              </Grid>
            </Box>
            {!isLoading && (
              <Box
                sx={{
                  height: 400,
                  borderRadius: "6px",
                  width: "100%",
                }}
              >
                <Grid
                  container
                  spacing={0}
                  height="75%"
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Typography
                    variant="poppins_h4_600_20"
                    sx={{
                      color: theme.custom.primaryDarkColor,
                      lineHeight: "40px",
                    }}
                  >
                    Add workbooks to sync data
                  </Typography>
                  <Typography
                    variant="inter_400_14"
                    sx={{
                      color: theme.custom.primaryDarkColor,
                      lineHeight: "25px",
                    }}
                  >
                    You can start adding the links to the workbooks. The added
                    workbooks will appear here
                  </Typography>
                  <GoogleSheetConnectorFileAdd
                    extraSx={{
                      variant: "contained",
                      sx: { height: 50, width: 180, mt: 4 },
                    }}
                    refresh={ListAllGoogleSheets}
                    owner={googleSheetOwner}
                  />
                </Grid>
              </Box>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default React.memo(GoogleSheetConnectorFileList);
