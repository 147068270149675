import { Box, Grid, Typography } from "@mui/material";
import { CSSProperties, useEffect, useState } from "react";
import { theme } from "../../../theme";
import styled from "@emotion/styled";
import { memo } from "react";
import { ThemeColorPicker } from "./ThemeColorPicker";
import { addAlphaToHexCode } from "../../../utils/color-picker-helper";
import { useAppDispatch } from "../../../store/hook";
import { setSettingEditActive } from "../../../store/selected-design-element-store";

const IconsEditorSettings = (props: any) => {
  const Swatch = styled("div")({
    width: "24px",
    height: "24px",
    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.15)",
    border: "1px solid #fff",
    borderRadius: "6px",
    margin: "2px",
    display: "inline-block",
    cursor: "pointer",
  });

  const colorPickerStyle: CSSProperties = {
    position: "absolute",
    padding: "10px",
    background: "#FFFFFF",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.12)",
    borderRadius: "6px",
    zIndex: 1,
  };

  const { attributes, updateElement, id } = props;
  const fill = attributes?.fill;

  const [displayPicker, setDisplayPicker] = useState(false);
  const [color, setColor] = useState("");
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );

  useEffect(() => {
    setColor(attributes?.fill || "#000000");
  }, [fill]);

  const togglePicker = (e: any) => {
    if (!displayPicker) {
      setAnchorEl(e.currentTarget);
      props.updateOverlay(true);
    } else {
      handlePickerClose();
    }
    dispatch(setSettingEditActive(!displayPicker));
    setDisplayPicker(!displayPicker);
  };

  const updateStyle = (value: any, callBack: any) => {
    let nextElement = { ...attributes, fill: value };

    updateElement(id, {
      attributes: nextElement,
    });
    callBack(value);
  };

  const updateHexCode = (hexCode: string) => {
    updateStyle(hexCode, setColor);
  };

  const handlePickerClose = () => {
    setAnchorEl(null);
    setDisplayPicker(false);
    dispatch(setSettingEditActive(false));
    props.updateOverlay(false);
  };

  return (
    <Grid container>
      <Grid sx={{ zIndex: 100 }} item sm={12}>
        <Grid container>
          <Grid item>
            <label>
              <Typography
                variant="inter_p_600_16"
                sx={{ color: theme.custom.primaryDarkColor }}
              >
                Fill Color
              </Typography>
            </label>
            <div aria-describedby={"fill-popper"} onClick={togglePicker}>
              <Swatch style={{ background: color }} />
            </div>
          </Grid>
          <Grid item sm={12}>
            {displayPicker && (
              <Box style={colorPickerStyle}>
                <ThemeColorPicker
                  anchorEl={anchorEl}
                  handleClose={handlePickerClose}
                  closePicker={togglePicker}
                  isShapes={true}
                  onChange={(colour: any) =>
                    updateStyle(
                      addAlphaToHexCode(colour.hex, colour.rgb["a"]),
                      setColor
                    )
                  }
                  updateHexCode={updateHexCode}
                  color={color}
                  colorPickerTitle={"Fill color"}
                  gradientApplied={attributes?.gradient}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(IconsEditorSettings);
