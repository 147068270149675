import { Dialog, DialogTitle, Skeleton, Typography, Grid } from "@mui/material";
import { omit, pick, sortBy } from "lodash";
import React, { memo, useEffect, useState } from "react";
import {
  FormattedMemberDetails,
  MemberDetailsAPIResponse,
  Permission,
} from "../../../Types/UserManagement";
import {
  getMemberDetails,
  getSteps,
  saveMemberDetails,
} from "../../../services/user-management-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import { theme } from "../../../theme";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import MemberOtherPermissionForm from "../../molecules/UserManagement/MemberOtherPermissionForm";
import MemberProfileDetailsForm from "../../molecules/UserManagement/MemberProfileDetailsForm";
import MemberDataPermissionForm from "../../molecules/UserManagement/MemberDataPermissionForm";
import MemberBoardPermissionForm from "../../molecules/UserManagement/MemberBoardPermissionForm";
interface Props {
  showPopup: boolean;
  setShowPopup?: React.Dispatch<React.SetStateAction<boolean>>;
  addUserCallback?: Function;
  memberUUID?: string;
  setMemberUUID: CallableFunction;
}

const AddEditMemberModal = (props: Props) => {
  const dispatch = useAppDispatch();
  const setShowModal = props.setShowPopup;
  const [open, setOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(-1);
  const [steps, setSteps] = useState<Permission[]>([]);
  const [memberDetailsLoading, setMemberDetailsLoading] = useState(false);
  const [memberDetails, setMemberDetails] =
    useState<MemberDetailsAPIResponse>();
  const [formattedMemberDetails, setFormattedMemberDetails] =
    useState<FormattedMemberDetails>();

  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    if (!memberDetails) return;
    // transform to formatted member details
    let _newFormattedMemberDetails: any = { ...memberDetails };

    let permission_list = [
      "other_module_permissions",
      "board_kpi_permissions",
      "data_permissions",
      "connection_permissions",
    ];
    _newFormattedMemberDetails = {
      ...omit(memberDetails, permission_list),
    };
    _newFormattedMemberDetails[_newFormattedMemberDetails.role_slug] = pick(
      memberDetails,
      permission_list
    );
    setFormattedMemberDetails(_newFormattedMemberDetails);
  }, [memberDetails]);

  useEffect(() => {
    if (props.memberUUID && open) {
      setMemberDetailsLoading(true);
      getMemberDetails(props.memberUUID, "uuid")
        .then((resp) => {
          if (resp && resp.status === 200) {
            if (resp.data) {
              // patch role_slug
              resp.data.role_slug = resp.data.role.slug;
              setMemberDetails(resp.data);
            }
            setMemberDetailsLoading(false);
          }
        })
        .catch((err) => {
          dispatch(
            showMessage({
              message:
                "Sorry something went wrong. Please close this popup and try again later.",
            })
          );
        });
    }
  }, [props.memberUUID, open]);

  const updateFormattedMemberDetails = (state: any) => {
    setFormattedMemberDetails({ ...formattedMemberDetails, ...state });
  };

  const updateMemberStateAndNext = (state: any) => {
    let _newFormattedMemberDetails: FormattedMemberDetails = {
      ...formattedMemberDetails,
      ...state,
    };
    setFormattedMemberDetails(_newFormattedMemberDetails);
    let _newStep = currentStep + 1;
    // currentStep is zero indexed.
    if (steps.length && _newStep > steps.length - 1) {
      // format data for request payload
      let _finalData = {
        ...omit(_newFormattedMemberDetails, [...roles]),
        ..._newFormattedMemberDetails[_newFormattedMemberDetails.role_slug],
      };
      // call save api and got back to use list
      saveMemberDetails(_finalData).then((resp) => {
        if (resp && resp.status === 200) {
          dispatch(
            showMessage({ message: "Succesfully saved.", variant: "success" })
          );
          handleClose();
          if (props.addUserCallback) {
            props.addUserCallback();
          }
        }
      });
    } else {
      setCurrentStep(_newStep);
    }
  };

  const getStepsAndUpdateMemberDetails = (state: any) => {
    getSteps(state.role_slug).then((resp) => {
      if (resp && resp.data) {
        setSteps(sortBy(resp.data.data.permissions, "position"));
        updateMemberStateAndNext(state);
      }
    });
  };
  const loadPreviousPage = () => setCurrentStep(currentStep - 1);

  const renderCurrentStep = () => {
    if (currentStep === -1)
      return (
        <MemberProfileDetailsForm
          isEditFlow={props.memberUUID !== undefined}
          setExistingMemberDetails={setMemberDetails}
          formattedMemberDetails={formattedMemberDetails}
          getStepsAndUpdateMemberDetails={getStepsAndUpdateMemberDetails}
          setRoles={setRoles}
        />
      );
    if (!formattedMemberDetails) {
      return <></>;
    }
    switch (steps?.[currentStep]?.permission_slug) {
      case "data_permissions":
        return (
          <MemberDataPermissionForm
            loadPreviousPage={loadPreviousPage}
            formattedMemberDetails={formattedMemberDetails}
            updateFormattedMemberDetails={updateFormattedMemberDetails}
            updateMemberStateAndNext={updateMemberStateAndNext}
          />
        );

      case "board_kpi_permissions":
        return (
          <MemberBoardPermissionForm
            loadPreviousPage={loadPreviousPage}
            formattedMemberDetails={formattedMemberDetails}
            updateFormattedMemberDetails={updateFormattedMemberDetails}
            updateMemberStateAndNext={updateMemberStateAndNext}
          />
        );

      case "other_module_permissions":
        return (
          <MemberOtherPermissionForm
            loadPreviousPage={loadPreviousPage}
            formattedMemberDetails={formattedMemberDetails}
            updateFormattedMemberDetails={updateFormattedMemberDetails}
            updateMemberStateAndNext={updateMemberStateAndNext}
          />
        );

      default:
        return <></>;
    }
  };

  useEffect(() => {
    setOpen(props.showPopup);
  }, [props.showPopup]);

  const handleClose = (event?: any, reason?: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setOpen(false);
    props.setMemberUUID(undefined);
    setFormattedMemberDetails(undefined);
    setMemberDetails(undefined);
    setCurrentStep(-1);
    if (setShowModal) {
      setShowModal(false);
    }
  };
  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={open}
      keepMounted={false}
      onClose={handleClose}
      sx={{
        zIndex: (th: any) => th.zIndex.drawer + 1000,
      }}
      PaperProps={{
        sx: {
          width: "760px",
          maxWidth: "760px",
          height: "670px",
          maxHeight: "670px",
          p: 1,
        },
      }}
    >
      <DialogTitle>
        <Grid container>
          <Grid textAlign={"center"} item xs={11}>
            <Typography
              sx={{
                color: `${theme.custom.primaryDarkColor}`,
                textAlign: "center",
              }}
              variant="poppins_h3_600_24"
            >
              {!props.memberUUID ? "Add new user" : "Edit user"}
            </Typography>
          </Grid>
          <Grid textAlign="right" item xs={1}>
            <CancelOutlinedIcon
              onClick={handleClose}
              sx={{
                color: `${theme.custom.primaryGreyColor}`,
                cursor: "pointer",
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      {!memberDetailsLoading ? (
        renderCurrentStep()
      ) : (
        <Skeleton
          sx={{ bgcolor: "grey.100", my: 1.5, mx: 2, borderRadius: 3 }}
          variant="rectangular"
          animation="wave"
          height={250}
        />
      )}
    </Dialog>
  );
};

export default memo(AddEditMemberModal);
