import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import { theme } from "../../theme";
import { useState, useEffect } from "react";
import { FormControlLabel, Typography } from "@mui/material";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 4,
  width: 20,
  height: 20,
  boxShadow: theme.custom.CustomCheckboxBoxShadow,
  backgroundColor: theme.custom.CustomCheckboxColor,
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: theme.custom.CustomCheckboxBgColor,
  "&:before": {
    display: "block",
    width: 20,
    height: 20,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
});

export default function CustomizedCheckbox(props: any) {
  const { onChange, labelProps, isChecked } = props;
  const [checked, setChecked] = useState<boolean>(isChecked);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.checked);
  };
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          onChange={handleChange}
          color="primary"
          checkedIcon={<BpCheckedIcon />}
          icon={<BpIcon />}
        />
      }
      label={
        <Typography
          variant={labelProps.extraSx.variant}
          sx={{ color: labelProps.extraSx.color, mt: 0.4 }}
        >
          {labelProps.title}
        </Typography>
      }
    />
  );
}
