import {
  ImageList,
  ImageListItem,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { shapesLibrary } from "../../../services/impact-report-service";
import IconsLibrary from "./IconsLibrary";
import AuthorizationErrorHandler from "./AuthorizationErrorHandler";

function ShapesLibrary(props: any) {
  let mouseOrigin = useRef({ left: 0, top: 0 });
  let cursorPosition = useRef({ left: 0, top: 0 });

  const [shapesData, setShapesData] = useState<any>([]);
  const [authorized, setAuthorized] = useState(true);

  useEffect(() => {
    shapesLibrary().then((res: any) => {
      if (res.status === 403) {
        setAuthorized(false);
        return;
      }

      if (res.status === 200) {
        setShapesData(res.data.results);
      }
    });
  }, []);
  const addElem = (e: any, element: any, dnd: boolean = true) => {
    const { addElement } = props;
    const _element = {
      type: "ShapesEditor",
      transparency: 1,
      zIndex: 0,
      rotate: 0,
      height: element.attributes.height,
      width: element.attributes.width,
      attributes: {
        fill: element.attributes.fill,
        height: element.attributes.height,
        title: element.attributes.title,
        width: element.attributes.width,
        viewBoxHeight: element.attributes.viewBoxHeight,
        viewBoxWidth: element.attributes.viewBoxWidth,
        strokeWidth: element.attributes.strokeWidth,
        strokeColor: element.attributes.strokeColor,
        path: element?.attributes?.path,
        strokeDashArray: element?.attributes?.strokeDashArray,
      },
    };

    addElement(
      _element,
      {
        left: e.pageX || 0,
        top: e.pageY || 0,
      },
      "drop",
      "",
      dnd
    );
    e.preventDefault();
  };
  const onDrag = (e: any) => {
    //TODO: On DragEnd page coordinates are coming wrong.
    // Monkey patch to force correct it. Heavy ops issue here.
    cursorPosition.current.top = e.pageY;
    cursorPosition.current.left = e.pageX;
  };

  const onDragStart = (e: any) => {
    let _bounds = e.currentTarget.getBoundingClientRect();
    mouseOrigin.current.top = e.clientY - _bounds.top;
    mouseOrigin.current.left = e.clientX - _bounds.left;
  };

  return (
    <>
      {authorized && (
        <ImageList variant="standard" cols={3} gap={12}>
          {shapesData &&
            shapesData.length > 0 &&
            shapesData.map((ele: any, index: number) => {
              return (
                <ImageListItem
                  sx={{
                    height: 70,
                    width: 70,
                  }}
                  key={index}
                >
                  <img
                    style={{ objectFit: "contain" }}
                    draggable={true}
                    onDrag={onDrag}
                    onDragStart={onDragStart}
                    onDragEnd={(e) => addElem(e, ele)}
                    onClick={(e) => addElem(e, ele, false)}
                    src={ele?.attributes?.imageUrl}
                    alt={ele?.attributes?.shape}
                    loading="lazy"
                  />
                </ImageListItem>
              );
            })}
        </ImageList>
      )}
      {!authorized && (
        <AuthorizationErrorHandler
          errorMessage="You don’t have access to see the list of shapes. Please reach out to
        admin to get access"
        />
      )}
    </>
  );
}

export default React.memo(ShapesLibrary);
