import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { theme } from "../../theme";
import SelectedIcon from "../../assets/selected_icon.png";
type Props = {
  tagName: string;
  isSelected: boolean;
  onSelect: Function;
  slug: string;
};

const TagComponent = (props: Props) => {
  const { tagName, isSelected, onSelect, slug } = props;
  const [selected, setSelected] = useState(isSelected);

  const onTagSelected = () => {
    let nextSelected = !selected;
    setSelected(nextSelected);
    onSelect(nextSelected, slug);
  };

  useEffect(() => {
    onSelect(selected, slug);
  }, []);

  return (
    <>
      <div onClick={onTagSelected}>
        <Grid
          container
          justifyContent={"space-evenly"}
          alignContent={"center"}
          sx={{
            paddingTop: "6px",
            paddingBottom: selected ? "0px" : "6px",
            background: selected
              ? theme.custom.progressBarColor
              : theme.custom.whiteColor,
            textAlign: "center",
            border: `1px solid ${theme.custom.primaryGreyColor}`,
            cursor: "pointer",
            borderRadius: "30px",
          }}
        >
          <Grid item>
            <Typography
              variant="inter_500_12"
              sx={{
                color: selected
                  ? theme.custom.whiteColor
                  : theme.custom.primaryGreyColor,
              }}
            >
              {tagName}
            </Typography>
          </Grid>
          <Grid item style={{ display: selected ? "block" : "none" }}>
            <img src={SelectedIcon} height={15} width={15} alt="Selected" />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default TagComponent;
