import { useEffect, useState } from "react";
import { theme } from "../../theme";
import CustomChip from "../atoms/CustomChip";
import { Grid, Tooltip } from "@mui/material";
import { capitalize, isEmpty } from "lodash";

interface Props {
  slug: string;
  currentAppliedFilters: any;
  filterApply: boolean;
  deleteFilterElement: Function;
  resetFilters: Function;
  clearAllFilters: Function;
  isFiltersLoaded: boolean;
}

const ChipsForBoard = (props: Props) => {
  const {
    slug,
    currentAppliedFilters,
    clearAllFilters,
    deleteFilterElement,
    filterApply,
    resetFilters,
    isFiltersLoaded,
  } = props;

  interface ChipData {
    key: number;
    label: string;
    filter: string;
    action: string;
  }

  const [chipList, setChipList] = useState<ChipData[]>([]);

  useEffect(() => {
    createChips();
  }, [isFiltersLoaded]);

  useEffect(() => {
    if (filterApply) {
      createChips();
    }
  }, [filterApply, currentAppliedFilters]);

  const createChips = () => {
    let appliedFilters: any = { ...currentAppliedFilters };
    Object.keys(appliedFilters).forEach((filter: any, index: number) => {
      if (
        appliedFilters[filter] === "" ||
        appliedFilters[filter]?.label === "" ||
        !appliedFilters[filter] ||
        appliedFilters[filter].length === 0 ||
        isEmpty(appliedFilters[filter])
      ) {
        delete appliedFilters[filter];
      }

      if (appliedFilters[filter] && appliedFilters[filter].length > 0) {
        if (appliedFilters[filter] instanceof Array) {
          appliedFilters[filter].forEach((ele: any, idx: number) => {
            createChipHelper(
              index + "_" + idx,
              ele["label"],
              filter,
              ele["action"],
              ele["value"]
            );
          });
        }
      } else if (filter === "daterange" || filter === "single-date") {
        let label;
        if (appliedFilters[filter]["start"] && appliedFilters[filter]["end"]) {
          label =
            appliedFilters[filter]["start"] +
            " - " +
            appliedFilters[filter]["end"];
        } else if (appliedFilters[filter]["start"]) {
          label = appliedFilters[filter]["start"];
        } else if (appliedFilters[filter]["end"]) {
          label = appliedFilters[filter]["end"];
        }

        createChipHelper(
          index,
          label,
          filter,
          appliedFilters[filter]["action"]
        );
      } else if (filter === "total_column") {
        let label = capitalize(appliedFilters?.[filter]?.["value"] || "");

        createChipHelper(
          index,
          label,
          filter,
          appliedFilters?.[filter]?.["action"] || "reset",
          appliedFilters?.[filter]?.["value"] || ""
        );
      } else {
        if (appliedFilters[filter]) {
          createChipHelper(
            index,
            appliedFilters[filter]["label"],
            filter,
            appliedFilters[filter]["action"],
            appliedFilters[filter]["value"]
          );
        }
      }
    });
    if (isEmpty(appliedFilters)) {
      setChipList([]);
    }
  };
  let chips: any = [];
  const createChipHelper = (
    key: any,
    label: string,
    filter: string,
    action?: string,
    value?: string
  ) => {
    chips.push({
      key: key,
      label: label,
      filter: filter,
      action: action,
      value: value,
    });

    setChipList(chips);
  };

  const handleDelete = (filter: any) => {
    deleteFilterElement(filter);
  };

  const resetAll = () => {
    resetFilters();
  };

  const clearAll = () => {
    clearAllFilters();
  };

  return (
    <Grid container xs={12}>
      {chipList.map((ele, index) => {
        return (
          <Grid key={index} item>
            <CustomChip
              key={index}
              extraSX={{ marginRight: 2, marginBottom: 2 }}
              extraProps={{
                key: ele.key,
                onDelete: () => {
                  handleDelete(ele);
                },
                labelText: ele.label,
              }}
              closeIconProps={{
                fill: theme.CustomChip.closeIconColor,
                fontSize: "18px !important",
                marginRight: "12px !important",
              }}
              action={ele?.action || "delete"}
            />
          </Grid>
        );
      })}
      {![
        "program-budget-tracker",
        "budget-tracker",
        "organization-budget-tracker",
        "um-not-linked",
        "expense-proof-and-invoice-tracking",
      ].includes(slug) &&
        !slug.includes("drill-down") &&
        slug !== "" && (
          <Tooltip
            title="Clears all filters. Set aggregation to monthly and date to YTD"
            placement="top"
            arrow
          >
            <Grid sx={{ marginLeft: 1, marginBottom: 2 }} key={"clear"} item>
              <CustomChip
                extraProps={{
                  key: "Clear",
                  onClick: clearAll,
                  labelText: "Clear All",
                }}
                labelProps={{ variant: "inter_p_500_14" }}
                extraSX={{
                  backgroundColor: theme.custom.whiteColor,
                  color: theme.custom.blackColor,
                  border: `1px solid ${theme.custom.blackColor}`,
                }}
              />
            </Grid>
          </Tooltip>
        )}
      <Tooltip
        placement="top"
        title="Resets the filters to previously saved one"
        arrow
      >
        <Grid sx={{ marginLeft: "5px", marginBottom: 2 }} key={"reset"} item>
          <CustomChip
            extraProps={{
              onClick: resetAll,
              labelText: "Reset",
            }}
            labelProps={{ vairant: "inter_p_600_16" }}
            extraSX={{
              backgroundColor: theme.custom.whiteColor,
              color: theme.CustomChip.primaryBlue,
            }}
          />
        </Grid>
      </Tooltip>
    </Grid>
  );
};
export default ChipsForBoard;
