import {Box, Grid} from "@mui/material";
import React, {useEffect} from "react";
import {getBoards} from "../../services/menu-service";
import LoaderIcon from "../../assets/loader.png";
import MetricsStatementMolecule from "../molecules/Metrics/MetricsStatementsMolecule";
import MetricsGenericMolecule from "../molecules/Metrics/MetricsGenericMolecule";

interface Props {
  category: any
}

const MetricsTab = (props: Props) => {
  const [boards, setBoards] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  useEffect(() => {
    getAllBoardItems(props.category);
  }, []);

  const getAllBoardItems = (category: string) => {
    setIsLoading(true)
    getBoards(category).then((res) => {
      if (res.status === 200) {
        setBoards(getSubBoards(res.data.menu))
        setIsLoading(false)
      }
    });
  };

  const getSubBoards = (boardList: any) => {
    let finalBoards: any = []
    boardList.forEach((board: any) => {
       if (board.board_slug == 'panorama') {
         finalBoards.push(board)
       } else {
         finalBoards = [...finalBoards, ...board.sub_menu]
       }
    })
    return finalBoards;
  }

  return (
    <>
      {isLoading &&
      <Box
          sx={{
            height: 100,
            display: isLoading ? "block" : "none",
          }}
      >
          <Grid
              className="show-loader"
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
          >
              <Grid item>
                  <img
                      src={LoaderIcon}
                      height="100"
                      width="100"
                      alt="Loader"
                  />
              </Grid>
          </Grid>
      </Box>}
      <Grid container spacing={2} columns={{md: 12}}>
        {
          props.category === 'statements' ?
            <MetricsStatementMolecule boards={boards}/>
           :
            <MetricsGenericMolecule boards={boards} category={props.category}/>
        }
      </Grid>
    </>
  )
};

export default MetricsTab;