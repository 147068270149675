import { Typography, Box, Grid } from "@mui/material";
import { capitalize, filter, map } from "lodash";
import React, { useEffect, useState } from "react";
import { theme } from "../../../theme";

type Props = {
  otherModulePermissions: any;
};

const MemberOtherModulePermissionDetails = (props: Props) => {
  const { otherModulePermissions } = props;
  const [otherModulePermissionsFormatted, setOtherModulePermissionsFormatted] =
    useState({} as any);

  useEffect(() => {
    otherModulePermissionFormatter(otherModulePermissions);
  }, [otherModulePermissions]);

  const keyFormatter = (key: string) => {
    if (key.includes("_")) {
      return key.split("_").map(capitalize).join(" ");
    } else {
      return key.split(" ").map(capitalize).join(" ");
    }
  };

  const reduceValues = (actions: any) => {
    let _values = filter(
      map(Object.keys(actions), (ele) => {
        if (actions[ele]["permitted"]) {
          return actions[ele]["label"];
        }
      })
    ).join(", ");

    if (_values.length === 0) {
      return "Not given";
    }

    return _values;
  };

  const otherModulePermissionFormatter = (permissions: any) => {
    let obj: any = {};
    if (permissions && Object.keys(permissions).length > 0) {
      Object.keys(permissions).forEach((ele) => {
        obj = {
          ...obj,
          [keyFormatter(ele)]: permissions[ele]["has_full_access"]
            ? "Complete Access"
            : reduceValues(permissions[ele]["actions"]),
        };
      });
    }
    setOtherModulePermissionsFormatted(obj);
  };

  return (
    <>
      <Box my={2}>
        <Typography
          sx={{ color: theme.custom.primaryDarkColor }}
          variant="poppins_h4_600_20"
        >
          Manage Permissions
        </Typography>
      </Box>
      <Grid container justifyContent={"space-between"}>
        {otherModulePermissionsFormatted &&
          Object.keys(otherModulePermissionsFormatted).length > 0 &&
          Object.keys(otherModulePermissionsFormatted).map((ele: any) => {
            return (
              <Grid container justifyContent={"space-between"}>
                <Grid mb={2} item>
                  <Typography
                    variant="inter_p_600_16"
                    sx={{ color: theme.custom.primaryDarkColor }}
                  >
                    {ele}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="inter_p_400_16">
                    {otherModulePermissionsFormatted[ele]}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default MemberOtherModulePermissionDetails;
