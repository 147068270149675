import axios from "axios";
import { BUDGETS } from "../endpoints/budget-endpoints";
import { NonRatableItem } from "../Types/NonRatableItemObject";

export const getNonRatableBudgetItem = (budget_uid: string, budget_item_id: string) => {
  return axios.get(
    `${BUDGETS}${budget_uid}/items/${budget_item_id}/non-ratable`
  );
};

export const updateNonRatableBudgetItem = (
  budget_uid: string,
  budget_item_id: string,
  payload: NonRatableItem[]
) => {
  return axios.put(
    `${BUDGETS}${budget_uid}/items/${budget_item_id}/non-ratable`,
    { data: payload }
  );
};
