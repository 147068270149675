import React, { useEffect, useState, useRef, useMemo } from "react";
import KPIMolecule from "../../molecules/KPIMolecule/KPIMolecule";
import { isEqual } from "lodash";
import { DEFAULT_KPI_SIZE } from "../../../constants/constants";

function KPIEditor(props: any) {
  const {
    attributes,
    height,
    width,
    isPublic,
    element,
    addTextElement,
    updateElement,
    isElementOnMove,
    scaleValue,
    selectedSelf,
    duplicateElement,
    deleteElement,
    source,
    keepRatio,
  } = props;

  useEffect(() => {
    let _scaleValue = Math.min(
      height / DEFAULT_KPI_SIZE.height,
      width / DEFAULT_KPI_SIZE.width
    );
    updateElement(
      {
        scale: _scaleValue,
        attributes: {
          ...attributes,
          height: height / _scaleValue,
          width: width / _scaleValue,
        },
      },
      "post-render"
    );
  }, [height, width, keepRatio]);

  let chartData = useMemo(
    () => ({
      kpi_slug: attributes.slug,
      kpi_title: attributes.title,
      position: attributes.position,
      // height: attributes.height * zoom,
      // width: attributes.width * zoom,
      height: attributes.height,
      width: attributes.width,
    }),
    [attributes]
  );

  return (
    <div
      style={{
        // width: attributes.width * zoom,
        // height: attributes.height * zoom,
        width: attributes.width,
        height: attributes.height,
        transform: `scale(${scaleValue})`,
        // transform: `scale(${1 / zoom})`,
        transformOrigin: "top left",
      }}
    >
      <KPIMolecule
        editorMode={true}
        chartData={chartData}
        filters={attributes.filters}
        isPublic={isPublic}
        commKey={attributes.commKey}
        element={element}
        isElementOnMove={isElementOnMove}
        addTextElement={addTextElement}
        selectedSelf={selectedSelf}
        duplicateElement={duplicateElement}
        deleteElement={deleteElement}
        updateElement={updateElement}
        source={source}
        attributes={attributes}
      />
    </div>
  );
}

// Adding a custom hook for deep lookup, as object literals as props
// will carry different refs
export default React.memo(KPIEditor, isEqual);
