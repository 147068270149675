import {
  Button,
  FormGroup,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  TextField,
} from "@mui/material";
import React, { FocusEventHandler, SyntheticEvent, useEffect, useRef, useState } from "react";
import { useResolvedPath } from "react-router";
import { useDebounce } from "react-use";
import { theme, fontInter } from "../../../theme";

interface InputSelectProps {
  value: any;
  onChange: Function;
  /**
   * The variant to use.
   * @default 'outlined'
   */
  variant?: "standard" | "filled" | "outlined" | undefined;
  /**
   * The variant to use.
   * @default 'medium'
   */
  size?: "medium" | "small" | undefined;
  onFocus?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  options?: any;
  [x: string]: any;
}

const InputSelect = React.memo(function (props: InputSelectProps) {
  const [value, setValue] = useState<any>((+props.value).toFixed(1));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = useState(false);
  let isChangeDebounced = useRef(false);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setOpen(true);
  };

  useEffect(() => {
    setValue((+props.value).toFixed(1));
  }, [props.value]);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [, cancel] = useDebounce(
    () => {
      if (isChangeDebounced.current) {
        props.onChange(+value);
        isChangeDebounced.current = false;
        setOpen(false);
      }
    },
    400,
    [value]
  );

  const onChange = (e: any) => {
    isChangeDebounced.current = true;
    setValue(e.target.value);
  };

  const onSelectOption = (value:number) => {
    isChangeDebounced.current = false;
    setValue(value);
    props.onChange(value);
    setOpen(false);
  }

  return (
    <div style={{ position: "relative" }}>
      <FormGroup row>
        <TextField
          variant={props.variant}
          size={props.size}
          value={value}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onClick={handleClick}
          onChange={onChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton
                  edge="start"
                  color="primary"
                  onClick={(e) => {
                    props.onChange(+value - 1);
                    e.stopPropagation();
                  }}
                  sx={{
                    width: "20px",
                  }}
                >
                  -
                </IconButton>
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  color="primary"
                  onClick={(e) => {
                    props.onChange(+value + 1);
                    e.stopPropagation();
                  }}
                  sx={{
                    width: "20px",
                  }}
                >
                  +
                </IconButton>
              </InputAdornment>
            ),
            style: {
              color: theme.custom.primaryGreyColor,
              fontFamily: fontInter,
            },
          }}
          sx={{
            "& .MuiInputAdornment-root": {
              margin: 0,
            },
            "& .MuiInputBase-input": {
              borderLeft: "1px solid #eee",
              borderRight: "1px solid #eee",
              paddingLeft: "1px",
              paddingRight: "1px",
              textAlign: "center",
            },
          }}
        />
      </FormGroup>
      {open && (
        <div ref={menuRef}>
          <Paper
            style={{
              position: "absolute",
              width: "100%",
              maxHeight: "60vh",
              overflowY: "scroll",
              zIndex: 1000,
            }}
          >
            {props.options.map((item: any) => (
              <MenuItem onClick={() => onSelectOption(item.label)}>{item.label}</MenuItem>
            ))}
          </Paper>
        </div>
      )}
    </div>
  );
});

export { InputSelect };
