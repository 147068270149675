import React from "react";
import { BudgetListItem } from "../../Types/BudgetListItem";
import BudgetListItemMolecule from "../molecules/BudgetListItemMolecule";

interface Props {
  budgets: Array<BudgetListItem>;
  isExpenseProofAllowed?: boolean;
}
const BudgetList: React.FC<Props> = (data) => {
  const budgets = data.budgets;

  return (
    <React.Fragment>
      {budgets.map((budget, index) => {
        return (
          <BudgetListItemMolecule
            key={index}
            item={budget}
            isExpenseProofAllowed={data.isExpenseProofAllowed || false}
          />
        );
      })}
    </React.Fragment>
  );
};

export default React.memo(BudgetList);
