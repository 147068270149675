import {
  Skeleton,
  Box,
  Button,
  Typography,
  Grid,
  Tooltip,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { useDeepCompareDiffEffect } from "../../../hooks/useDeepCompareDiffEffect";
import getKPIData from "../../../services/kpi-service";
import { theme } from "../../../theme";
import { useAppSelector } from "../../../store/hook";
import DataTableMolecule from "../DataTableMolecule";
import GroupTable from "../DataTable/GroupTable";
import { findLabelsForShowTitleFilters } from "../../../utils/CommonKPIResponseFormatterHelper";

function TabularReportEditor(props: any) {
  const tableRef = useRef<HTMLDivElement>(null);
  const {
    attributes,
    height,
    width,
    updateElement,
    isPublic,
    pageHeight,
    pageWidth,
  } = props;
  const [filters, setFilters] = useState<any>(null);
  const [tableData, setTableData] = useState<any>([]);
  const [metaData, setMetaData] = useState<any>({});
  const [scale, setScale] = useState(1);
  const [tableRenderWidth, setTableRenderWidth] = useState<number>(width || 1);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [err, setErr] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [tableType, setTableType] = useState<string>("");

  const showTitle = attributes?.filters?.show_title !== 'no';

  const selectedElementAllFilters: any = useAppSelector((
    (state) => state.selectedDesignElement.allFiltersInStore
  ))

  // TODO: Take it from element props
  let currentPageHeight = pageHeight;
  let currentPageWidth = pageWidth;

  const [tableContainerDimension, setTableContainerDimension] = useState<any>({
    height: height || "auto",
    width: width || "auto",
  });

  const prevDimension = useRef<any>({
    height: height || "auto",
    width: width || "auto",
  });

  const ERROR_INIT = {
    status: 200,
    message: "",
  };

  const reducer = (state: any, action: any) => {
    let newState = ERROR_INIT;
    switch (action.status) {
      case 200:
        newState = {
          status: 200,
          message: action.message,
        };
        break;
      default:
        newState = {
          status: action.status,
          message: action.message,
        };
    }

    return newState;
  };

  const [errState, dispatch] = useReducer(reducer, ERROR_INIT);
  const isDimensionChanged = (prev: number, current: number) => {
    const tolerance = prev * 0.02;
    return Math.abs(prev - current) > tolerance;
  };

  useEffect(() => {
    let _width:any = width || "auto";
    let _height:any = height || "auto";
    const isWidthChanged = isDimensionChanged(_width, prevDimension.current.width);
    const isHeightChanged = isDimensionChanged(_height, prevDimension.current.height);
    if (isWidthChanged && isHeightChanged) {
      const baseScale = _width / prevDimension.current.width * scale;
      setTableContainerDimension({
        width: _width,
        height: _height,
      });
      setScale(baseScale);
    } else if (isWidthChanged) {
      setTableContainerDimension({
        ...tableContainerDimension,
        width: _width
      });
      // Add a setTimeout to allow the DataTable to update its dimensions
      setTimeout(() => {
        if (tableRef.current) {
          // Once it's rendered, get the actuals width
          // as table can't be full expanded or contracted
          // for best content fit
          let scrollWidth = tableRef.current.scrollWidth * scale;
          if (isDimensionChanged(scrollWidth, _width)) {
            _width = scrollWidth;
            setTableContainerDimension({
              ...tableContainerDimension,
              width: _width
            });
            updateElement({ width: _width }, "post-render");
          }
        }
      });
    }
    prevDimension.current = {
      height: _height,
      width: _width,
    };
  }, [width, height, tableRenderWidth]);

  const [isRendered, setIsRendered] = useState(true);

  useEffect(() => {
    //TODO: this is a monkey patching. It creates a flickering issue.
    //We should avoid this appproach but currently moving forward with release  
    setTimeout(()=>{
      if (
        isDataLoaded &&
        tableRef?.current?.offsetHeight &&
        tableRef?.current?.offsetWidth
      ) {
        const offsetHeight = tableRef.current.offsetHeight;
        const offsetWidth = tableRef.current.offsetWidth;
        setTableRenderWidth(offsetWidth);
  
        let width,
          height,
          scale = 1;
  
        if (tableContainerDimension.width !== "auto") {
          width = tableContainerDimension.width;
          scale = width / offsetWidth;
          height = offsetHeight * scale;
  
          // if (height > currentPageHeight) {
          //   const scaleByHeight = currentPageHeight / offsetHeight;
          //   if (scaleByHeight < scale) {
          //     scale = scaleByHeight;
          //     height = currentPageHeight;
          //     width = offsetWidth * scale;
          //   }
          // }
        } else {
          scale = Math.min(offsetWidth / currentPageWidth, offsetHeight / currentPageHeight);
          width = offsetWidth * scale;
          height = offsetHeight * scale;
        }
        updateElement({ height, width }, "post-render");
        setTableContainerDimension({ width, height });
        prevDimension.current = {
          height: height,
          width: width,
        };
        setScale(scale);
        setIsRendered(true);
      }

    })
  }, [tableData, isDataLoaded]);

  const onRender = useCallback(() => {
    setIsDataLoaded(true);
  }, []);

  useDeepCompareDiffEffect(
    (diff = {}) => {
      if (Object.keys(diff).length !== 0) {
        if (tableContainerDimension) {
          setIsLoading(true);
          setFilters(attributes.filters);
          // Set scale to 1 to get right client dimension on render
          setScale(1);
          getKPIData(
            attributes.slug,
            attributes.filters,
            false,
            isPublic,
            attributes.commKey,
            "impact-report",
            "impact-reporting"
          )
            .then((res) => {
              if (res.status === 403) {
                setErr(true);
                dispatch({
                  status: 403,
                  message:
                    "You don’t have access to this KPI. Please reach out to admin to get access",
                });
                setMetaData(res?.data?.meta_data);
                props.onLoad();
              } else if (res.status === 406) {
                setErr(true);
                dispatch({ status: 406, message: res.data?.message });
                setMetaData(res?.data?.meta_data);
                props.onLoad();
              } else if (res.status === 200) {
                if (res.data) {
                  if (res.data && res.data.data && res.data.data.length === 0) {
                    setErr(true);
                    dispatch({
                      status: 200,
                      message: "No data found. Please try changing filter.",
                    });
                    setMetaData(res?.data?.meta_data);
                  } else {
                    setIsLoading(false);
                    setIsRendered(false);
                    setTableData(res.data?.data);
                    setMetaData(res.data?.meta_data);
                    setErr(false);
                    dispatch({ status: 200, message: "" });
                    setTableType(res?.data?.type || "table");
                  }
                }
              } else {
                setIsLoading(false);
              }
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      }
    },
    [attributes.filters]
  );

  const getTitle = (type?:string) =>{
    let title:string = "";
    if(type === 'attributes'){      
      title = attributes?.title
    }else{
      title = metaData?.chart?.title || "";
    }
    return title + (attributes?.show_title_filters_labels ?? '');
  }

  const titleRenderer = (type: string) => {
    const titleText: string = getTitle(err? 'attributes':'');
    let sx:Object=  {};
    switch(type){
      case 'err': 
        sx={
          borderBottom: `1px solid ${theme.custom.menuDividerColor}`
        }
        break;
      case 'noPivot': 
        sx={ m: 1 }
        break;
      case 'pivot':
        sx={ m: 2 }
        break;
    }
    
    return (
      <Box
        sx={sx}
      >
        <Tooltip title={titleText}>
          <Typography
            variant="poppins_h4_600_20"
            sx={{ color: theme.custom.primaryDarkColor }}
            noWrap
          >
            {titleText}
          </Typography>
        </Tooltip>
      </Box>)
  }

  return (
    <>
      {err ? (
        <div
          style={{ position: "relative", background: theme.custom.whiteColor }}
        >
          <div
            style={{
              height: tableContainerDimension.height || 300,
              width: tableContainerDimension.width || 300,
              border: `1px solid ${theme.custom.menuDividerColor}`,
            }}
          >
            {showTitle && titleRenderer('err')}
            <Grid
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                textAlign: "center",
              }}
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="inter60016"
                sx={{
                  color: theme.custom.dropdownPrimaryColor,
                  marginBottom: 1,
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "pre",
                }}
              >
                {errState.message}
              </Typography>
              {!isPublic && errState.status === 406 && (
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  href="/connectors/list"
                >
                  Go to Connectors
                </Button>
              )}
            </Grid>
          </div>
        </div>
      ) : (
        <div
          style={{
            transform: `scale(${scale})`,
            transformOrigin: "top left",
            height: (tableContainerDimension.height || 300) / scale,
            width: (tableContainerDimension.width || 300) / scale,
          }}
        >
          {isLoading ? (
            <Skeleton
              animation="wave"
              style={{
                width: "100%",
                height: "100%",
              }}
            />
          ) : tableType !== "pivot" ? (
            <div
              id="tableRef"
              ref={tableRef}
              style={{
                display: isRendered ? "flex" : "inline-table",
                background: "white",
                flexDirection: "column",
              }}
            >
              {showTitle && titleRenderer('noPivot')}
              {!!Object.keys(metaData).length && tableType !== "pivot" && (
                <DataTableMolecule
                  chartData={tableData}
                  chartMeta={metaData}
                  forReport={true}
                  onRender={onRender}
                />
              )}
            </div>
          ) : (
            <div
              id="tableRef-group"
              ref={tableRef}
              style={{
                display: isRendered ? "flex" : "inline-table",
                background: "white",
                flexDirection: "column",
              }}
            >
              {showTitle && titleRenderer('pivot')}
              {!!Object.keys(metaData).length && tableType === "pivot" && (
                <GroupTable
                  chartData={tableData}
                  chartMeta={metaData}
                  forReport={true}
                  onRender={onRender}
                />
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default React.memo(TabularReportEditor);
