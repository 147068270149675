import axios from "axios";
import {
  POST_LOGIN_URL,
  POST_REFRESH_TOKEN_URL,
} from "../endpoints/auth-endpoints";
import { LoginBody } from "../Types/LoginBody";
import { Token } from "../Types/Token";
const tokenKey = "pebbletoken";

export const logIn = (body: LoginBody) => {
  return axios.post(POST_LOGIN_URL, body);
};

export const setToken = (token: Token) => {
  localStorage.setItem(tokenKey, JSON.stringify(token));
};

export const getToken = () => {
  return JSON.parse(localStorage.getItem(tokenKey) || "{}");
};

export const getAccessToken = () => {
  const token = JSON.parse(localStorage.getItem(tokenKey) || "{}");
  return token ? token.access : token;
};

export const getRefreshToken = () => {
  const token = JSON.parse(localStorage.getItem(tokenKey) || "{}");
  return token ? token.refresh : token;
};

export const refreshToken = () => {
  return axios.post(POST_REFRESH_TOKEN_URL, {
    refresh: getRefreshToken(),
    auth: "jwt",
  });
};

export const removeToken = () => {
  localStorage.removeItem(tokenKey);
};

export const clearLocalStorage = () => {
  localStorage.clear();
}
