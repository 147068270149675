import {
  each,
  every,
  filter,
  flatten,
  forIn,
  isArray,
  isObject,
  mapValues,
  set,
  startsWith,
  values,
} from "lodash";

export const filterChartData = (kpi_master_data: any, filters: any) => {
  let filteredData: any;

  if (isArray(kpi_master_data)) {
    filteredData = kpi_master_data.filter((item: any) => {
      for (let [key, value] of Object.entries(filters)) {
        if (item[key] !== value) return false;
        return item;
      }
    });
  } else {
    if (isObject(kpi_master_data)) {
      let _options: any = {};
      each(kpi_master_data, (value: any, key) => {
        set(
          _options,
          [key],
          filter(value, (item: any) => {
            for (const [k, v] of Object.entries(filters)) {
              if (item[k] !== v) {
                return false;
              }
            }
            return item;
          })
        );
      });
      filteredData = _options;
    }
  }

  return filteredData;
};

export const filterStringBasedOnKey = (arr: any, key: string) => {
  if (arr) {
    return arr.filter((el: string) => (el === key ? el : false))[0];
  }
};

export function extractDropdownItems(data: any): { [key: string]: string[] } {
  const result: { [key: string]: Set<string> } = {};

  function traverse(obj: any): void {
    forIn(obj, (value, key) => {
      if (isObject(value)) {
        // Recursively traverse the object
        traverse(value);
      } else if (startsWith(key, "dropdown-menu-item")) {
        // Initialize the Set if it's not already there
        if (!result[key]) {
          result[key] = new Set();
        }
        result[key].add(value);
      }
    });
  }

  // Start the traversal
  traverse(data);

  // Convert Sets back to arrays
  return mapValues(result, (values) => [...values]);
}

export function filterDataByConditions(input: any, conditions: any) {
  // Flatten the input if it's an object, otherwise use as is if it's an array
  const dataPoints = isArray(input) ? input : flatten(values(input));

  return dataPoints.filter((dataPoint) =>
    every(conditions, (value, key) => dataPoint[key] === value)
  );
}

export function extractDropdownKeys(data: any): string[] {
  const resultSet: Set<string> = new Set();

  function traverse(obj: any) {
    forIn(obj, (value, key) => {
      if (startsWith(key, "dropdown-menu-item") && !resultSet.has(key)) {
        resultSet.add(key);
      }

      // This is by design. We assume each object
      // follows the same structure. if obj can have
      // diff DS then remove this break
      if (resultSet.has(key)) {
        return;
      }

      if (isObject(value)) {
        traverse(value);
      }
    });
  }

  traverse(data);

  return [...resultSet];
}
