import { Box, Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import React, { memo } from "react";
import TripleDot from "../../assets/fi-rr-menu-dots.png";
import { deleteTemplate } from "../../services/impact-report-service";
import { showMessage } from "../../store/error-handler-store";
import { useAppDispatch } from "../../store/hook";
import { theme } from "../../theme";
import PreviewTemplatePopup from "../molecules/Editor/PreviewTemplatePopup";
import ThumbnailSlider from "./ThumbnailSlider";
import { has, isEmpty } from "lodash";

type Props = {
  style: any;
  template_icon: any;
  onEditTemplate: Function;
  handleSelect: any;
  templateId: string;
  templateIsMutable: boolean;
  onDeleteTemplate: Function;
  templateObj: any;
  variant?: string;
  userQuery?: string;
  audience?: string;
  purpose?: string;
  title?: string;
  pageLen?: number;
  forPanoroma?: boolean;
  extraSx?: any;
};

const TemplateThumbnailComponent = (props: Props) => {
  const { templateObj, userQuery, forPanoroma, extraSx, style } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const thumbnail = props.template_icon;
  const handleClick = (event: React.MouseEvent<HTMLImageElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleEdit = () => {
    setAnchorEl(null);
    props.onEditTemplate();
  };

  const dispatch = useAppDispatch();
  const handleDelete = () => {
    setAnchorEl(null);
    if (window.confirm("Are you sure you want to delete the template?")) {
      deleteTemplate(templateObj.id).then((resp) => {
        if (resp.status === 200) {
          dispatch(
            showMessage({ message: "Deleted template", variant: "success" })
          );
          props.onDeleteTemplate();
        }
      });
    }
  };
  return (
    <Grid container direction={"column"}>
      <Box
        className={"report-thumbnail-container"}
        sx={{
          height: !isEmpty(style) && has(style, "height") ? style?.height : 200,
          border: "1px solid #e1e1e1",
          borderRadius: "6px",
          overflow: "hidden",
        }}
      >
        {typeof thumbnail == "string" ? (
          <img
            className="report-thumbnail-image"
            src={thumbnail}
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              borderRadius: "16px",
            }}
            alt=""
          />
        ) : (
          <div
            style={{
              maxWidth: "100%",
              borderRadius: "16px",
              maxHeight: "100%",
              display: "grid",
            }}
          >
            <ThumbnailSlider blurBackground thumbnail={thumbnail} />
          </div>
        )}
        <img
          className="triple-dot-container"
          height="24px"
          width="24px"
          src={TripleDot}
          style={{
            display: props.templateIsMutable && !forPanoroma ? "block" : "none",
          }}
          onClick={(e) => handleClick(e)}
          alt="menu"
        />
        <Menu
          transformOrigin={{
            vertical: -10,
            horizontal: "right",
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => setAnchorEl(null)}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={handleEdit} sx={{ p: 1.5, width: "214px" }}>
            <Typography
              variant="inter_p_500_14"
              sx={{ color: `${theme.custom.primaryGreyColor} !important` }}
            >
              Edit Template
            </Typography>
          </MenuItem>
          <MenuItem onClick={handleDelete} sx={{ p: 1.5, width: "214px" }}>
            <Typography
              variant="inter_p_500_14"
              sx={{ color: `${theme.custom.errorColor} !important` }}
            >
              Delete Template
            </Typography>
          </MenuItem>
        </Menu>
        <div className="middle">
          <Button
            sx={{ mb: 2 }}
            fullWidth
            variant="contained"
            onClick={props.handleSelect}
          >
            <Typography variant="poppins_p_600_14">Select</Typography>
          </Button>
          <Button
            sx={{
              mb: 2,
              display: props.templateIsMutable && !userQuery ? "block" : "none",
            }}
            fullWidth
            variant="outlined"
            onClick={() => props.onEditTemplate()}
          >
            <Typography variant="poppins_p_600_14">Edit</Typography>
          </Button>
          {
            <PreviewTemplatePopup
              extraSx={{
                color: theme.palette.primary.main,
                fontSize: "12px",
                cursor: "pointer",
              }}
              templateObj={templateObj}
              userQuery={userQuery}
              audience={props.audience}
              purpose={props.purpose}
            />
          }
        </div>
        <Box
          sx={{
            display: forPanoroma ? "none" : "block",
            borderRadius: "12px",
            backgroundColor: "#00000045",
            // maxWidth: "180px",
            px: "12px",
            py: "4px",
            position: "absolute",
            bottom: "8px",
            left: "8px",
          }}
        >
          <Typography
            sx={{
              color: theme.custom.whiteColor,
            }}
            variant="inter_500_12"
          >
            {props.pageLen} Page
            {(props.pageLen || 0) > 1 ? "s" : ""}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          // maxWidth: "200px",
          height: "42px",
          mt: 1,
        }}
      >
        <Typography
          sx={{
            wordWrap: "break-word",
            color: theme.custom.primaryDarkColor,
          }}
          variant="poppins_p_600_14"
        >
          <div title={props.title}>
            {(props.title?.length || 0) > 38
              ? props.title?.substr(0, 38) + "..."
              : props.title}
          </div>
        </Typography>
      </Box>
    </Grid>
  );
};

export default memo(TemplateThumbnailComponent);
