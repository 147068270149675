import { DateRange } from "../Types/DateRange";

export const returnFileName = (title: string, dateRange: DateRange) => {
  if (dateRange) {
    return (
      title.replaceAll(" ", "-") + "-" + dateRange.start + "-" + dateRange.end
    );
  } else {
    return title.replaceAll(" ", "-");
  }
};
