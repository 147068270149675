import { Box, Divider, Stack, Tooltip } from "@mui/material";
import React, { SyntheticEvent, memo } from "react";
import CopyElementIcon from "../../assets/copy_element.png";
import ElementDeleteIcon from "../../assets/delete_red.png";
import { theme } from "../../theme";

type Props = {
  children?: React.ReactNode;
  id: any;
  element?: any;
  duplicateElement?: Function;
  deleteElement?: Function;
};



const BubbleMenu = (props: Props) => {
  const { children, id, element, duplicateElement, deleteElement } = props;
  const _deleteElement = (event:SyntheticEvent) => {
    deleteElement?.(id);
    event.stopPropagation();
  }

  return (
    <div>
      <Stack direction={"row"} spacing={1} justifyContent={"center"}
        style={{
          background: theme.custom.whiteColor,
          boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.12)",
          borderRadius: "6px",
          width: "min-content"
        }} p={1} mt={1} mb={1}
        sx={{
          "& img": {
            height: "32px",
            width: "32px",
            padding: "7px",
            cursor: "pointer",
          }
        }}>
        {children}
        <Tooltip title={"Duplicate"} placement="top">
          <img
            src={CopyElementIcon}
            alt="Paste Element"
            style={{
              marginLeft: "15px",
            }}
            onClick={(e) => {
              duplicateElement?.(e, JSON.stringify(element));
            }}
          />
        </Tooltip>
        <Tooltip title={"Delete"} placement="top">
          <img
            src={ElementDeleteIcon}
            alt="Delete"
            onClick={_deleteElement}
          />
        </Tooltip>
      </Stack>
    </div>
  );
};

export default memo(BubbleMenu);
