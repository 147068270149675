import { Box, Grid, Popover, Typography } from "@mui/material";
import React, { memo } from "react";
import { fontPoppins, theme } from "../../theme";

type Props = {
  slug: string;
  snippet: string;
  kpiTitle: string;
  drawerOpen: Function;
  id: string;
  open: boolean;
  anchorEl: any;
  onMouseEnter: any;
  onMouseLeave: any;
};

const KPIInfoPopover = (props: Props) => {
  const openDrawer = () => {
    props.drawerOpen();
  };
  return (
    <React.Fragment>
      <Popover
        id={props.id}
        open={props.open}
        anchorEl={props.anchorEl}
        PaperProps={{
          onMouseEnter: props.onMouseEnter,
          onMouseLeave: props.onMouseLeave,
        }}
      >
        <Box
          p={3}
          sx={{
            maxHeight: 350,
            width: 400,
            position: "relative",
            borderRadius: "6px",
          }}
        >
          <Grid container>
            <Grid mb={2.5} item sm={12}>
              <Typography
                variant="inter_p_600_16"
                color={theme.custom.primaryDarkColor}
              >
                {props.kpiTitle}
              </Typography>
            </Grid>
            <Grid mb={2} item sm={12}>
              <div style={{ fontFamily: fontPoppins }}>
                <div
                  dangerouslySetInnerHTML={{ __html: `${props.snippet}` }}
                ></div>
              </div>
            </Grid>
            <Grid mb={1} item sm={12}>
              <Typography
                onClick={openDrawer}
                sx={{ cursor: "pointer" }}
                variant="poppins_p_600_14"
                color={theme.custom.popoverLinkColor}
              >
                Learn more
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Popover>
    </React.Fragment>
  );
};

export default memo(KPIInfoPopover);
