import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  ThemeProvider,
  Toolbar,
} from "@mui/material";
import {
  chain,
  entries,
  filter,
  forEach,
  isEmpty,
  isEqual,
  keys,
  map,
  minBy,
  orderBy,
  pickBy,
  set,
  get,
  sortBy,
  isArray,
} from "lodash";
import { nanoid } from "nanoid";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { deepFlat } from "@daybrush/utils";
import Selecto from "react-selecto";
import Moveable from "react-moveable";
import { GroupManager } from "@moveable/helper";
import { useNavigate, useParams } from "react-router-dom";
import {
  useCopyToClipboard,
  useCounter,
  useEffectOnce,
  useWindowSize,
} from "react-use";
import SAT from "sat";

import LibraryandSetting from "../components/complexes/Editor/LibraryandSetting";
import EditorToolbar from "../components/molecules/Editor/EditorToolbar";
import ImpactReportPresentationToolbar from "../components/molecules/Editor/ImpactReportPresentationToolbar";
import ImpactReportPublicToolbar from "../components/molecules/Editor/ImpactReportPublicToolbar";
import Page from "../components/molecules/Editor/Page";
import PresentationLeftPanel from "../components/molecules/Editor/PresentationLeftPanel";
import {
  createPublicReportPdf,
  createReportPdf,
  getReport,
  saveReport,
} from "../services/impact-report-service";
import { useAppDispatch, useAppSelector } from "../store/hook";
import CopyElementIcon from "../assets/copy_element.png";
import ElementDeleteIcon from "../assets/delete_red.png";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {
  setCurrentFormat,
  setIsTableEditorActive,
  setIsTextEditorActive,
  setSelectedDesignElement,
  setSettingEditActive,
} from "../store/selected-design-element-store";
import { darkTheme, theme } from "../theme";
import {
  getClipStylesFromBounds,
  getElementBounds,
  getRectOverlap,
} from "../utils/ui-utils";
import "./Editor.scss";


const clone = require("rfdc")();
function ImpactReportEditor({ isPresentation = false, ...props }: any) {
  const params = useParams();
  const mode = props.mode || "view";
  const disableToolbar = props.disableToolbar || false;
  const inPrint = props.print || false;
  const [isPublic, setIsPublic] = useState<Boolean>(props.isPublic);
  const [isPrinting, setIsPrinting] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [isShareable, setIsShareable] = useState(false);
  const [isExportable, setIsExportable] = useState(false);
  const [reportId, setReportId] = useState<any>("");
  const [reportData, setReportData] = useState<any>(null);
  const [isElementOnMove, setIsElementOnMove] = useState<Boolean>(false);
  const { width: ww, height: wh } = useWindowSize();
  const inViewPageId = useRef(null as any);
  const [clipBoardItem, copyItem] = useCopyToClipboard();
  const [snapElements, setSnapElements] = useState([] as any);
  const [progress, setProgress] = useState(10);
  const [reportPDF, setReportPDF] = useState(null);
  const [openedPrintStatusPopover, setPrintStatusPopover] = useState(false);

  const [isDragging, setIsDragging] = useState(false);
  const mouseDownPos = useRef({ x: 0, y: 0 });

  const handleMouseDown = (e: React.MouseEvent) => {
    mouseDownPos.current = { x: e.clientX, y: e.clientY };
    setIsDragging(false);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (e.buttons === 1) {  // Left mouse button is pressed
      const dx = Math.abs(e.clientX - mouseDownPos.current.x);
      const dy = Math.abs(e.clientY - mouseDownPos.current.y);
      if (dx > 5 || dy > 5) {  // Threshold for considering it a drag
        setIsDragging(true);
      }
    }
  };
  const [selectionMade, setSelectionMade] = useState(false);

  const handleMouseUp = (e: React.MouseEvent) => {
    if (!isDragging && !!!isPublic && !e.shiftKey && !selectionMade) {
      resetSelection();
    }
    setSelectionMade(false);
    setIsDragging(false);
  };

  // Since clipboard is being used in event listener
  // the callback functions might not get latest state value.
  let clipBoardItemRef = useRef(clipBoardItem);
  useEffect(() => {
    clipBoardItemRef.current = clipBoardItem;
  }, [clipBoardItem]);
  const [zoom, setZoom] = useState(0.35);
  let zoomRef = useRef(0.35);
  const pageContainerRef = useRef(null as any);
  const updateZoom = useCallback((nextZoom: number, point: any = undefined) => {
    resetSelection();
    if (inPrint) {
      setZoom(1);
      zoomRef.current = 1;
      return;
    }
    let { x, y } = getPageContainerScrollBy(nextZoom, point);
    setZoom(nextZoom);
    zoomRef.current = nextZoom;
    if (pageContainerRef.current) {
      pageContainerRef.current.scrollBy(x, y);
    }
  }, []);

  const fitPagetoScreen = (pageWidth: number, pageHeight: number) => {
    if (inPrint) {
      setZoom(1);
      zoomRef.current = 1;
      return;
    }

    if (pageContainerRef.current) {
      let _zoom = Math.min(
        (pageContainerRef.current.clientHeight -
          50 -
          (!isEmpty(pageBounds.current)
            ? Math.min(...map(pageBounds.current, "top"))
            : 120)) /
        pageHeight,
        (pageContainerRef.current.clientWidth - 100) / pageWidth
      );
      setZoom(_zoom < 0 ? 0.15 : _zoom);
      zoomRef.current = _zoom < 0 ? 0.15 : _zoom;
    }
  };
  useEffect(() => {
    if (inPrint) {
      setZoom(1);
      zoomRef.current = 1;
      return;
    }

    if (pageContainerRef.current) {
      let _zoom = Math.min(
        (pageContainerRef.current.clientHeight -
          50 -
          (!isEmpty(pageBounds.current)
            ? Math.min(...map(pageBounds.current, "top"))
            : 120)) /
        defaultPage.current.height,
        (pageContainerRef.current.clientWidth - 100) / defaultPage.current.width
      );
      setZoom(_zoom);
      zoomRef.current = _zoom;
    }
  }, []);

  const [isThemeDark, setIsThemeDark] = useState(true);
  const [contextMenu, setContextMenu] = useState<{
    mouseX: number;
    mouseY: number;
  } | null>(null);

  let defaultPageHeight = 3508,
    defaultPageWidth = 2480;

  // TODO: Add typings
  const defaultPage = useRef<any>({
    position: 1,
    width: defaultPageWidth,
    height: defaultPageHeight,
    elements: {} as any,
  });
  const init_page_id = useRef<any>(nanoid(10));

  const [editorState, setEditorState] = useState({} as any);
  const [reportName, setReportName] = useState("");
  let historyRef = useRef({ stateHistory: [] as any, historyIndex: -1 });
  const [historyAvailable, setHistoryAvailable] = useState({
    undo: true,
    redo: true,
  });

  useEffect(() => {
    if (reportName) {
      document.title = `Impact Report ${reportName} - Pebble`;
    }
  }, [reportName]);

  const setActivePageId = () => {
    if (pageBounds.current) {
      if (
        pageContainerRef.current &&
        inViewPageId.current &&
        pageBounds.current[inViewPageId.current] &&
        pageBounds.current[inViewPageId.current].top -
        pageContainerRef.current.scrollTop <
        wh / 2 &&
        pageBounds.current[inViewPageId.current].bottom -
        pageContainerRef.current.scrollTop >
        wh / 2
      ) {
        //Do nothing
      } else {
        Object.keys(pageBounds.current).map((i) => {
          if (
            pageBounds.current[i].top - pageContainerRef.current.scrollTop <
            wh / 2 &&
            pageBounds.current[i].bottom - pageContainerRef.current.scrollTop >
            wh / 2
          ) {
            inViewPageId.current = i;
          }
        });
        if (
          !inViewPageId.current ||
          !(inViewPageId.current in pageBounds.current)
        ) {
          inViewPageId.current = Object.keys(pageBounds.current)[0];
        }
      }
    }
  };

  useEffect(() => {
    if (pageContainerRef.current) {
      pageContainerRef.current.addEventListener(
        "scroll",
        setActivePageId,
        false
      );
      return function cleanup() {
        if (pageContainerRef.current) {
          pageContainerRef.current.removeEventListener(
            "scroll",
            () => { },
            false
          );
        }
      };
    }
  }, []);

  const [currentPageIndex, setCurrentPageIndex] = useState(0);

  useEffect(() => {
    if (isPublic && props.reportDetails) {
      setReportId(props.reportDetails.reportId);
      setReportName(props.reportDetails.name);
      setReportData(props.reportDetails);
      const decompressedString = props.reportDetails.content || "{}";
      const decompressedState = JSON.parse(decompressedString);
      if (Object.keys(decompressedState).length) {
        defaultPage.current = {
          ...defaultPage.current,
          ...{
            height:
              decompressedState?.height ||
              props?.reportDetails?.attributes?.height ||
              defaultPageHeight,
            width:
              decompressedState?.width ||
              props?.reportDetails?.attributes?.width ||
              defaultPageWidth,
          },
        };
        // if (isPresentation) {
        //   // fill report details with single page
        //   const newSinglePageReport = Object.values(
        //     orderBy(
        //       pickBy(
        //         decompressedState?.pages,
        //         (page) => page._destroy === undefined || page._destroy === false
        //       ),
        //       ["position"]
        //     )
        //   )[currentPageIndex];

        //   setEditorStateBasedOnSource({
        //     ...decompressedState,
        //     pages: { newSinglePageReport: newSinglePageReport },
        //   });
        // }
        // else{
        setEditorStateBasedOnSource(decompressedState);
        // }
        fitPagetoScreen(defaultPage.current.width, defaultPage.current.height);
      }
    } else {
      const paramId: any = params.id || "";
      setReportId(paramId.split("_").at(-1) || "");
    }
  }, [isPublic, props.reportDetails]);

  const dispatch = useAppDispatch();
  const selectedElement = useAppSelector(
    (state) => state.selectedDesignElement.item
  );

  const isTextEditorActive = useAppSelector(
    (state) => state.selectedDesignElement.isTextEditorActive
  );
  const isSettingEditActive = useAppSelector(
    (state) => state.selectedDesignElement.isSettingEditActive
  );

  const isTableEditorActive = useAppSelector(
    (state) => state.selectedDesignElement.isTableEditorActive
  );

  const [isPublicManuallySet, setIsPublicManuallySet] = useState(false);

  const [clipAble, setClipAble] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (reportId && !!!isPublic)
      getReport(reportId).then((resp: any) => {
        if (resp) {
          defaultPage.current = {
            ...defaultPage.current,
            ...{
              height: resp?.attributes?.height || defaultPageHeight,
              width: resp?.attributes?.width || defaultPageWidth,
            },
          };
          let _editorData = Object.keys(resp.data).length
            ? {
              ...resp.data,
              height: resp?.attributes?.height || defaultPageHeight,
              width: resp?.attributes?.width || defaultPageWidth,
            }
            : {
              pages: {
                [init_page_id.current]: {
                  ...defaultPage.current,
                  id: init_page_id.current,
                },
              },
              source: "user",
              height: defaultPage.current.height,
              width: defaultPage.current.width,
            };
          setEditorStateBasedOnSource(_editorData);
          setReportName(resp.name);
          setReportData(resp);
          if (!resp.is_editable) {
            setIsPublic(true);
            setIsPublicManuallySet(true);
          }
          setIsEditable(resp.is_editable);
          setIsShareable(resp.is_shareable);
          setIsExportable(resp.is_exportable);
          onLoadElementsSnapBounds(_editorData);
          setActivePageId();
          fitPagetoScreen(
            defaultPage.current.width,
            defaultPage.current.height
          );
          // TODO: MONKEY PATCHING. As few elements updates its properties
          // like H/W post render on load, Editor select on update element.
          // On first load, we force unselect it.
          // setTimeout(() => resetSelection(), 2000);
        } else {
          navigate("/impact-report");
        }
      });
  }, [reportId]);

  const [frame, setFrame] = useState({
    translate: [0, 0],
    rotate: 0,
    transformOrigin: "50% 50%",
    clipStyle: "inset",
  });
  const groupManager = useMemo<GroupManager>(() => new GroupManager([]), []);
  const [targets, setTargets] = useState<any>([]);
  const [saving, setSaving] = useState(false);
  const moveRef = useRef<Moveable>(null);
  const selectoRef = useRef<Selecto>(null);
  let lastRef = useRef<any>([]);
  const [transitionSave, setTransitionSave] = useState(false);
  let transitionSaveRef = useRef(null as any);
  let lastEle = useRef({} as any);

  const [eleChangeCounter, { inc: incEleChangeCounter }] = useCounter(0);
  const _editorRef = useRef(null as any);
  _editorRef.current = editorState;
  let pageBounds = useRef({} as any);

  const [elementSnapBounds, setElementSnapBounds] = useState({} as any);
  let _elementSnapBoundsRef = useRef({} as any);
  const _setElementSnapBounds = (snapBounds: any) => {
    _elementSnapBoundsRef.current = snapBounds;
    setElementSnapBounds(snapBounds);
  };

  const [groupEditInProgress, setGroupEditInProgress] = useState(false);
  const setSelectedTargets = useCallback((nextTargets: any) => {
    selectoRef.current && selectoRef.current!.setSelectedTargets(deepFlat(nextTargets));
    setTargets(nextTargets);
    if(nextTargets.length > 1 || nextTargets.length == 0) {
      resetSelectedSettings();
    }

    if (nextTargets.length == 1 && !isArray(nextTargets[0])) {
      let nextSelectedElement = get(_editorRef.current, [
        "pages",
        nextTargets[0]?.dataset?.pageid,
        "elements",
        nextTargets[0]?.dataset?.elementid,
      ]);
      setTimeout(() => {
        dispatch(setSelectedDesignElement(nextSelectedElement));
        dispatch(setCurrentFormat({ format: nextSelectedElement.attributes }));
      }, 100);
    }
  }, []);
  const updateSelectedTargets = useCallback(() => {
    if (lastRef.current && !groupEditInProgress) {
      setSelectedTargets(isArray(lastRef.current) ? lastRef.current : [lastRef.current]);
    }
  }, [groupEditInProgress, targets]);

  useEffect(() => {
    if (lastRef && selectoRef && selectoRef.current) {
      updateSelectedTargets();
    }
  }, [eleChangeCounter, groupEditInProgress])

  if (moveRef && moveRef.current && moveRef.current?.getTargets().length > 0) {
    const moveableArea = document.getElementsByClassName("moveable-area");
    if (moveableArea && moveableArea.length > 0) {
      moveableArea[0].addEventListener("contextmenu", (e: any) => {
        handleContextMenu(e);
      });
    }
  }


  useEffect(() => {
    setActivePageId();
  }, [zoom]);

  let pageLength = Object.keys(
    pickBy(
      editorState?.pages,
      (page) => page._destroy === undefined || page._destroy === false
    )
  ).length;

  useEffect(() => {
    setActivePageId();
  }, [pageLength]);

  const setPageBounds = useCallback((id: any, bound: any) => {
    pageBounds.current = { ...pageBounds.current, [id]: bound };
  }, []);

  const nextStatePostDeletion = useCallback((elementIds: Array<{ id: string; pageId: string }>) => {
    let nextState = JSON.parse(JSON.stringify(_editorRef.current));
    map(elementIds, ({ pageId, id }) => {
      set(nextState, ['pages', pageId, 'elements', id, '_destroy'], true);
    });

    return nextState;
  }, []);

  const deleteMultipleElementsById = useCallback((elementIds: Array<{ id: string; pageId: string }>) => {
    lastRef.current = [];
    lastEle.current = {};
    let nextState = nextStatePostDeletion(elementIds);
    setEditorStateBasedOnSource(nextState);
    resetSelection();
  }, []);

  const deleteElementById = useCallback((id: any, pageId: any, updateState: boolean = true) => {
    lastRef.current = [];
    lastEle.current = {};
    let nextState = nextStatePostDeletion([{ id: id, pageId: pageId }]);
    if (updateState) {
      setEditorStateBasedOnSource(nextState);
      resetSelection();
    }
  }, []);

  const persistElementOnLoadDelta = (e: any) => {
    return (e = {
      ...e,
      ...{
        attributes: {
          ...e.attributes,
          onLoadDelta: e.delta,
        },
      },
    });
  };

  const persistOnLoadDelta = (pages: any) => {
    return chain(pages)
      .mapValues((page) => {
        let y = chain(page.elements)
          .mapValues((e) => {
            return persistElementOnLoadDelta(e);
          })
          .value();
        return { ...page, elements: y };
      })
      .value();
  };

  const clearClipBoard = useCallback(() => {
    copyItem("");
  }, []);

  const pasteElement = useCallback((position: any = {}) => {
    let elementCopied = JSON.parse(clipBoardItemRef.current.value || "{}");
    setGroupEditInProgress(true);
    if (Array.isArray(elementCopied)) {
      let pastedElements: any[] = [];
      const lowestXandY = getLowestXandY(elementCopied);
      pastedElements = elementCopied.map(
        (element: { id: any; pageId: any; posX: number; posY: number }) => {
          return generateElementPositionAndAdd(element, position, lowestXandY, false);
        }
      )
      .filter(Boolean);
      updateGroupEditorState(pastedElements);

      setTimeout(() => {
        if (pastedElements.length > 0) {
          lastRef.current = pastedElements
            .filter(el => el && el.pageId && el.id)
            .map(el => document.querySelector(`.element_${el.pageId}_${el.id}`))
            .filter(Boolean);
          const definedElements = pastedElements.filter(el => el && el.pageId && el.id);
          if (definedElements.length > 0) {
            const lastElement = definedElements[definedElements.length - 1];
            lastEle.current = lastElement;
          }
        }
        setGroupEditInProgress(false);
      })
    } else {
      let pastedElement = generateElementPositionAndAdd(elementCopied, position);
      setTimeout(() => {
        if (pastedElement) {
          lastRef.current = [document.querySelector(`.element_${pastedElement.pageId}_${pastedElement.id}`)];
          lastEle.current = pastedElement;
        }
        setGroupEditInProgress(false);
      });
    }
  }, []);

  const getLowestXandY = (elements: any) => {
    const lxO = minBy(elements, "posX");
    const lyO = minBy(elements, "posY");
    return {
      x: lxO,
      y: lyO,
    };
  };

  const generateElementPositionAndAdd = (
    element: { id: any; pageId: any; posX: number; posY: number },
    position: { x: any; y: any },
    lowestXandY?: any,
    updateState: boolean = true
  ) => {
    if (element && element.id && element.pageId) {
      element = persistElementOnLoadDelta(element);
      let _inViewPageBound = pageBounds.current[inViewPageId.current];
      
      // If it's a group element, i.e. lowestXandY is defined
      // and negative we shift the posY for all by that numbers
      if (lowestXandY?.x?.posX && lowestXandY.x.posX < 0) {
        element.posX -= lowestXandY.x.posX;
      } else {
        element.posX = position.x ? element.posX : Math.max(0, element.posX);
      }

      element.posX = position?.x
        ? lowestXandY
          ? position.x + (element.posX - lowestXandY.x.posX) * zoomRef.current
          : position.x
        : element.posX * zoomRef.current +
        _inViewPageBound.left -
        pageContainerRef.current.scrollLeft
        + (inViewPageId.current === element.pageId ? 100 * zoomRef.current : 0);


      // If it's a group element, i.e. lowestXandY is defined
      // and negative we shift the posY for all by that numbers
      if (lowestXandY?.y?.posY && lowestXandY.y.posY < 0) {
        element.posY -= lowestXandY.y.posY;
      } else {
        element.posY = position.y ? element.posY : Math.max(0, element.posY);
      }

      element.posY = position?.y
        ? lowestXandY
          ? position.y + (element.posY - (lowestXandY.y.posY)) * zoomRef.current
          : position.y
        : element.posY * zoomRef.current +
        _inViewPageBound.top -
        pageContainerRef.current.scrollTop
        + (inViewPageId.current === element.pageId ? 100 * zoomRef.current : 0);

      return addElement(element, { left: element.posX, top: element.posY }, "drop", "", true, updateState);
    }

    return {}
  };

  const duplicateElement = useCallback((e: any, element: any) => {
    copyItem(element);
    setTimeout(() => {
      pasteElement();
    }, 0);
    e.stopPropagation();
  }, []);

  const onPrint = useCallback(
    (e: any) => {
      setProgress(10);
      openPrintStatusPopover();
      let printPages = Object.values(_editorRef.current.pages || {}).filter(
        (x: any) => x._destroy === undefined || x._destroy === false
      ).length;

      const timer = setInterval(() => {
        setProgress((prevProgress) =>
          prevProgress >= 90 ? prevProgress : prevProgress + 1
        );
      }, 50 + printPages * 20);

      setIsPrinting(true);
      if (mode === "public") {
        createPublicReportPdf(props.publicReportId, printPages)
          .then((resp) => {
            if (resp && resp.status === 200) {
              setProgress(100);
              setReportPDF(resp.data);
              clearInterval(timer);
            }
          })
          .finally(() => {
            setIsPrinting(false);
          });
      } else {
        createReportPdf(reportId, printPages)
          .then((resp) => {
            if (resp && resp.status === 200) {
              setProgress(100);
              setReportPDF(resp.data);
              clearInterval(timer);
            }
          })
          .finally(() => {
            setIsPrinting(false);
          });
      }
    },
    [props.publicReportId, reportId]
  );

  const openPrintStatusPopover = () => {
    setPrintStatusPopover(true);
  };

  const closePrintStatusPopover = () => {
    if (reportPDF) {
      Object.assign(document.createElement("a"), {
        target: "_blank",
        rel: "noreferrer",
        href: URL.createObjectURL(reportPDF),
      }).click();
    }
    setPrintStatusPopover(false);
  };

  const _handleKeyDown = (e: any) => {
    switch (e.code) {
      case "Delete":
      case "Backspace":
        if (targets && targets.length === 1) {
          if (
            targets[0].dataset &&
            targets[0].dataset.elementtype &&
            (targets[0].dataset.elementtype !== "TextEditor" || !isTextEditorActive) &&
            (targets[0].dataset.elementtype !== "TableEditor" || !isTableEditorActive) &&
            !isSettingEditActive
          ) {
            return deleteElementById(
              targets[0].dataset.elementid,
              targets[0].dataset.pageid
            );
          }
        } else if (targets && targets.length > 1) {
          const elementsToDelete = map(targets, (target) => ({
            id: target.dataset.elementid,
            pageId: target.dataset.pageid
          }));

          deleteMultipleElementsById(elementsToDelete);
        }
        return null;
      case "Escape":
        return resetSelection();
      case "KeyC":
        if (e.metaKey || e.ctrlKey) {
          if (!isTextEditorActive && !isTableEditorActive) {
            if (selectedElement?.id) {
              copyItem(JSON.stringify(selectedElement));
            } else {
              if (targets && targets.length > 0) {
                let elements = targets.map(
                  (target: { dataset: { pageid: any; elementid: any } }) => {
                    return get(_editorRef.current, [
                      "pages",
                      target.dataset.pageid,
                      "elements",
                      target.dataset.elementid,
                    ]);
                  }
                );
                copyItem(JSON.stringify(elements));
              }
            }
          }
        }

        return false;
      case "KeyV":
        if ((e.metaKey || e.ctrlKey) && clipBoardItemRef.current.value) {
          if (
            !isTextEditorActive &&
            !isTableEditorActive &&
            !isSettingEditActive
          ) {
            pasteElement();
            e.preventDefault();
          }
        }

        return false;
      case "Equal":
        if (e.metaKey || e.ctrlKey) {
          e.preventDefault();
          updateZoom(Math.min(zoom + 0.1, 5));
        }

        return false;
      case "Minus":
        if (e.metaKey || e.ctrlKey) {
          e.preventDefault();
          updateZoom(Math.max(zoom - 0.1, 0.1));
        }

        return false;
      case "KeyP":
        if (e.metaKey || e.ctrlKey) {
          onPrint(true);
          e.preventDefault();
        }
        return false;

      case "KeyZ":
        if (e.metaKey || e.ctrlKey) {
          if (e.shiftKey) {
            onRedo();
          } else {
            onUndo();
          }
          e.preventDefault();
        }
        return false;

      default:
        break;
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", _handleKeyDown);

    return () => {
      document.removeEventListener("keydown", _handleKeyDown);
    };
  }, [
    targets,
    selectedElement,
    isTextEditorActive,
    isTableEditorActive,
    zoom,
    isSettingEditActive,
  ]);

  const getPageContainerScrollBy = (
    nextZoom: number,
    point: any = undefined
  ) => {
    let currentZoom = zoomRef.current;
    if (!point) {
      point = {
        x: pageContainerRef.current
          ? pageContainerRef.current.offsetWidth / 2
          : window.innerWidth / 2,
        y: pageContainerRef.current
          ? pageContainerRef.current.offsetHeight / 2
          : window.innerHeight / 2,
      };
    }

    let pageNotinViewSize = filter(pageBounds.current, (i) => {
      return i.top < pageBounds.current[inViewPageId.current].top;
    }).length;
    let scrollByY = 0;
    let scrollByYFactor =
      pageNotinViewSize * defaultPage.current.height +
      (1 / currentZoom) *
      (pageContainerRef.current.scrollTop +
        point.y -
        pageBounds.current[inViewPageId.current].top);
    let scrollByX = 0;
    if (
      defaultPage.current.width * nextZoom >
      pageContainerRef.current.offsetWidth
    ) {
      scrollByX =
        ((nextZoom - currentZoom) / currentZoom) *
        (pageContainerRef.current.scrollLeft + point.x);
    }
    scrollByY = (nextZoom - currentZoom) * scrollByYFactor;
    return {
      x: scrollByX,
      y: scrollByY,
    };
  };

  useEffect(() => {
    const _handleMouseWheel = (e: any) => {
      if ((e.metaKey || e.ctrlKey) && (zoom > 0.1 || zoom < 5)) {
        e.preventDefault();
        let nextZoom = zoomRef.current,
          currentZoom = zoomRef.current;
        let delta = e.wheelDelta || -e.detail;
        let factor = +(
          0.01 +
          (0.05 * (Math.abs(delta) * 10 - 300)) / 3000
        ).toFixed(2);

        if (delta > 0) {
          nextZoom = Math.min(+(currentZoom + factor).toFixed(2), 5);
        } else {
          nextZoom = Math.max(+(currentZoom - factor).toFixed(2), 0.1);
        }
        let pageContainerBounds = getElementBounds(pageContainerRef.current);
        updateZoom(
          nextZoom,
          pageContainerBounds
            ? {
              x: e.clientX - pageContainerBounds.left,
              y: e.clientY - pageContainerBounds.top,
            }
            : undefined
        );
      }
    };
    // Note: by default listeners are set passive: false except for some events like wheel.
    // Setting passive false explicitly
    document.addEventListener("wheel", _handleMouseWheel, { passive: false });

    return () => {
      document.removeEventListener("wheel", _handleMouseWheel);
    };
  }, [zoom]);

  useEffect(() => {
    const _handleMouseWheel = (e: any) => {
      if (e.metaKey || e.ctrlKey) return;
      if (isPresentation) {
        e.preventDefault();
        let delta = e.wheelDelta || -e.detail;
        const totalLength = Object.values(editorState?.pages || {}).filter(
          (x: any) => x._destroy === undefined || x._destroy === false
        ).length;
        if (delta < 0) {
          setCurrentPageIndex(Math.min(currentPageIndex + 1, totalLength - 1));
        } else {
          setCurrentPageIndex(Math.max(currentPageIndex - 1, 0));
        }
      }
    };
    // Note: by default listeners are set passive: false except for some events like wheel.
    // Setting passive false explicitly
    document.addEventListener("wheel", _handleMouseWheel, { passive: false });

    return () => {
      document.removeEventListener("wheel", _handleMouseWheel);
    };
  }, [isPresentation, currentPageIndex, editorState?.pages]);

  // const [origEditorState, setOrigEditorState] = useState(editorState);

  // useEffect(() => {
  //   setOrigEditorState(editorState)
  // }, [editorState])

  // useEffect(() => {

  // }, [isPresentation])

  useEffect(() => {
    if (!!isPublic) return;
    if (transitionSave) {
      clearTimeout(transitionSaveRef.current);
    }
    setTransitionSave(true);
    transitionSaveRef.current = setTimeout(() => {
      setTransitionSave(false);
      if (reportData)
        saveReport(
          editorState,
          reportId,
          reportName,
          setSaving,
          !!reportData.attributes.templateId
        );
    }, 1000);
  }, [editorState]);

  const setEditorStateBasedOnSource = (state: any, source = "user") => {
    let nextState = { ...state, source: source };
    // NOTE: This implementation is by design. Before
    // trigger of state change ref needs to be updated.
    _editorRef.current = nextState;
    onSaveChanges(source);
    setEditorState(nextState);
  };

  const persistDeltaForTextEditor = (_editorState: any) => {
    let temp = { ..._editorState };
    Object.keys(temp.pages).map((pageId: any, index: any) => {
      let page = temp.pages[pageId];
      Object.keys(page.elements).map((id: any) => {
        if (page.elements[id]?._destroy) {
          delete page.elements[id];
        } else {
          if (page.elements[id]["type"] === "TextEditor") {
            page.elements[id] = {
              ...page.elements[id],
              ...{
                attributes: {
                  ...page.elements[id].attributes,
                  onLoadDelta: page.elements[id].delta,
                },
              },
            };
          }
        }
      });
    });

    return temp;
  };

  const onSaveChanges = (source: string = "user") => {
    let _history = clone(historyRef.current.stateHistory);
    let currentEditorState = clone(_editorRef.current);
    if (
      historyRef.current.stateHistory.length &&
      historyRef.current.historyIndex <
      historyRef.current.stateHistory.length - 1 &&
      source === "user"
    ) {
      _history.splice(historyRef.current.historyIndex + 1);
      historyRef.current.stateHistory = _history;
      historyRef.current.historyIndex = _history.length - 1;
    } else if (source === "post-render") {
      _history[historyRef.current.historyIndex] =
        persistDeltaForTextEditor(currentEditorState);
      historyRef.current.stateHistory = _history;

      return;
    }

    let _hist = [..._history];
    // _hist.splice(0, 10 - _hist.length);
    let nextState = _hist[_hist.length - 1];
    if (isEqual(nextState, currentEditorState)) {
      return;
    }

    if (source === "user") {
      if (historyRef.current.stateHistory.length <= 10) {
        _hist.push(persistDeltaForTextEditor(currentEditorState));
        historyRef.current.stateHistory = _hist;
        historyRef.current.historyIndex += 1;
      } else {
        let _stateHistory = [...historyRef.current.stateHistory];
        _stateHistory.splice(0, 1);
        _stateHistory.push(persistDeltaForTextEditor(currentEditorState));

        historyRef.current.stateHistory = _stateHistory;
        historyRef.current.historyIndex = _stateHistory.length - 1;
      }

      updateHistoryAvailable();
    }
  };

  const updateHistoryAvailable = () => {
    if (historyRef.current.stateHistory.length === 1) {
      setHistoryAvailable({ undo: false, redo: false });
    } else if (
      historyRef.current.historyIndex <
      historyRef.current.stateHistory.length - 1
    ) {
      if (historyRef.current.historyIndex === 0) {
        setHistoryAvailable({ undo: false, redo: true });
      } else {
        setHistoryAvailable({ undo: true, redo: true });
      }
    } else if (
      historyRef.current.historyIndex ===
      historyRef.current.stateHistory.length - 1
    ) {
      setHistoryAvailable({ undo: true, redo: false });
    }
  };

  const onUndo = useCallback(() => {
    if (isSettingEditActive) return;
    //Checking if anything is available to perform undo.
    if (historyRef.current.historyIndex === 0) {
      return;
    }

    let nextState = clone(
      historyRef.current.stateHistory[historyRef.current.historyIndex - 1]
    );

    if (selectedElement?.type === "TextEditor") {
      if (
        nextState &&
        nextState.pages &&
        nextState.pages[selectedElement?.pageId] &&
        Object.keys(nextState.pages[selectedElement?.pageId]["elements"])
          .length > 0
      ) {
        nextState.pages = {
          ...nextState.pages,
          ...persistOnLoadDelta({
            [selectedElement?.pageId]: nextState.pages[selectedElement?.pageId],
          }),
        };
      }
    }

    setEditorStateBasedOnSource(nextState, "history");
    if (
      nextState.pages[selectedElement?.pageId] &&
      Object.keys(nextState.pages[selectedElement?.pageId]["elements"]).length >
      0
    ) {
      dispatch(
        setSelectedDesignElement(
          nextState.pages[selectedElement?.pageId].elements[selectedElement?.id]
        )
      );
    }

    historyRef.current.historyIndex -= 1;
    updateHistoryAvailable();
  }, [selectedElement]);

  const onRedo = useCallback(() => {
    if (
      historyRef.current.historyIndex ===
      historyRef.current.stateHistory.length - 1
    ) {
      return;
    }
    let tempIndex = historyRef.current.historyIndex + 1;
    let nextState = clone(historyRef.current.stateHistory[tempIndex]);

    if (selectedElement?.type === "TextEditor") {
      if (
        nextState &&
        nextState.pages &&
        nextState.pages[selectedElement?.pageId] &&
        Object.keys(nextState.pages[selectedElement?.pageId]["elements"])
          .length > 0
      ) {
        nextState.pages = {
          ...nextState.pages,
          ...persistOnLoadDelta({
            [selectedElement?.pageId]: nextState.pages[selectedElement?.pageId],
          }),
        };
      }
    }
    setEditorStateBasedOnSource(nextState, "history");
    if (
      nextState.pages[selectedElement?.pageId] &&
      Object.keys(nextState.pages[selectedElement?.pageId]["elements"]).length
    ) {
      dispatch(
        setSelectedDesignElement(
          nextState.pages[selectedElement?.pageId].elements[selectedElement?.id]
        )
      );
    }
    historyRef.current.historyIndex = tempIndex;
    updateHistoryAvailable();
  }, [selectedElement]);

  const toggleCustomAbles = useCallback((type: string, state: boolean) => {
    switch (type) {
      case "clip":
        setClipAble(state);
        break;
      default:
        break;
    }
  }, []);

  useEffect(() => {
    if (selectedElement) {
      let _clipStyles = getClipStylesFromBounds(
        selectedElement?.clipStyleBounds,
        1, //ZOOMTODO
        "style"
      );
      setFrame({
        translate: [selectedElement?.posX, selectedElement?.posY],
        rotate: selectedElement?.rotate || 0,
        transformOrigin: "50% 50%",
        clipStyle: typeof _clipStyles === "string" ? _clipStyles : "inset",
      });
      if (_editorRef.current) {
        let elements = _editorRef.current;
        if (
          elements &&
          selectedElement &&
          elements.pages &&
          elements.pages[selectedElement.pageId]
        ) {
          let _snapElements = map(
            filter(
              keys(elements.pages[selectedElement.pageId].elements),
              (i: any) => {
                return i !== selectedElement.id;
              }
            ),
            (i: any) => `.element_${selectedElement.pageId}_${i}`
          );
          setSnapElements([
            ..._snapElements,
            `.page_${selectedElement.pageId}`,
          ]);
        }
      }
    }
  }, [selectedElement]);


  const resetSelectedSettings = () => {
    setSelectionMade(false);
    setClipAble(false);
    setIsElementOnMove(false);
    dispatch(setSelectedDesignElement({}));
    dispatch(setCurrentFormat({ format: null }));
    dispatch(setIsTextEditorActive(false));
    dispatch(setIsTableEditorActive(false));
    dispatch(setSettingEditActive(false));
  };
  // On click on anywhere outside element, reset the active element
  const resetSelection = useCallback((unsetTarget: boolean = true) => {
    if (!!isPublic) return;
    setSelectedTargets([]);
  }, []);
  const _addPageCreateNewEditorState = useCallback(
    (newPage: any, oldEditorState: any, nextPages, atPosition) => {
      let _id = nanoid(10);
      if (newPage.elements && Object.keys(newPage.elements).length > 0) {
        let nextElements = chain(newPage.elements)
          .mapValues((e) => {
            e.pageId = _id;
            return e;
          })
          .value();

        newPage = {
          ...newPage,
          ...{ elements: nextElements },
        };
      }

      oldEditorState.pages = { ...oldEditorState.pages, ...nextPages };
      set(oldEditorState.pages, [_id], {
        ...newPage,
        position: atPosition,
        id: _id,
      });
      return { pageId: _id, oldEditorState };
    },
    []
  );
  const [dragContainer, setDragContainer] = useState<any>(null);
  useEffect(() => {
    setDragContainer(pageContainerRef.current);
  }, []);

  const addPage = useCallback(
    (atPosition?: number, newPages?: any) => {
      let oldEditorState = { ..._editorRef.current };
      if (newPages) {
        if (!atPosition)
          atPosition = oldEditorState.pages[inViewPageId.current].position;
      } else {
        newPages = clone(defaultPage.current);
      }
      if (!Array.isArray(newPages)) newPages = [newPages];

      let nextPages = {};
      if (atPosition !== undefined) {
        nextPages = persistOnLoadDelta(
          chain(oldEditorState.pages)
            .pickBy(
              (page) =>
                atPosition &&
                page.position >= atPosition &&
                (page._destroy === false || page._destroy === undefined)
            )
            .mapValues((p) => {
              p.position += newPages.length;
              return p;
            })
            .value()
        );
      } else {
        atPosition = Object.keys(oldEditorState.pages).length + 1;
      }
      let firstAddedPageId: string = "";
      for (const [i, newPage] of newPages.entries()) {
        let nextEditor = _addPageCreateNewEditorState(
          newPage,
          oldEditorState,
          nextPages,
          atPosition
        );
        oldEditorState = nextEditor.oldEditorState;
        if (i === 0) firstAddedPageId = nextEditor.pageId;
        atPosition += 1;
      }
      setEditorStateBasedOnSource(oldEditorState);
      scrollToPage(firstAddedPageId);
      setDragContainer(pageContainerRef.current);
    },
    [_editorRef, inViewPageId]
  );

  const copyPageById = useCallback((pageId: any) => {
    let newPage = clone(_editorRef.current.pages[pageId]);
    if (newPage) {
      newPage = persistOnLoadDelta({ [pageId]: newPage })[pageId];
      addPage(newPage.position + 1, newPage);
    }
  }, []);

  const deletePageById = useCallback((pageId: any) => {
    let nextState = clone(_editorRef.current);
    if (
      Object.keys(
        pickBy(
          nextState?.pages,
          (page) => page._destroy === undefined || page._destroy === false
        )
      ).length > 1
    ) {
      nextState.pages[pageId]._destroy = true;
      let nextPages = persistOnLoadDelta(
        chain(nextState.pages).pickBy(
          (page) =>
            page.position >= nextState.pages[pageId].position &&
            (page._destroy === false || page._destroy === undefined)
        )
      );
      nextState.pages = { ...nextState.pages, ...nextPages };
      setEditorStateBasedOnSource(nextState);
      delete pageBounds.current[pageId];
      lastRef.current = [];
      lastEle.current = {};
    }
  }, []);

  const updatePageById = useCallback((pageId: any, data: any) => {
    let nextState = clone(_editorRef.current);

    if (nextState?.pages?.[pageId]) {
      nextState.pages[pageId] = {
        ...nextState.pages[pageId],
        ...data,
      };
      setEditorStateBasedOnSource(nextState, "user");
    }
  }, []);

  const updatePagePosition = useCallback((pageId: any, moveBy: number) => {
    let nextState = clone(_editorRef.current);
    let page = nextState.pages[pageId];

    let affectedPages = sortBy(
      entries(
        pickBy(
          nextState.pages,
          (p) =>
            (moveBy > 0
              ? p.position >= page.position
              : p.position <= page.position) && p.id !== page.id
        )
      ),
      (i) => (moveBy > 0 ? i[1].position : -1 * i[1].position)
    );

    if (affectedPages && affectedPages.length > 0) {
      let prevPosition = page.position,
        affectedPage = affectedPages[0];

      // persist onLoadDelta with latest delta
      nextState.pages = {
        ...nextState.pages,
        ...persistOnLoadDelta({
          [page.id]: page,
          [affectedPage[0]]: nextState.pages[affectedPage[0]],
        }),
      };

      set(nextState, ["pages", pageId, "position"], affectedPage[1].position);
      set(nextState, ["pages", affectedPage[0], "position"], prevPosition);

      setEditorStateBasedOnSource(nextState);
      scrollToPage(pageId);
    }
  }, []);

  const scrollToPage = (pageId: string) => {
    setTimeout(() => {
      let ele = document.getElementById(`page_${pageId}`);
      if (ele) {
        pageContainerRef.current.scrollBy({
          top: ele.getBoundingClientRect().top - 100,
          behavior: "smooth",
        });
      }
    }, 200);
  };

  useEffectOnce(() => {
    window.onpopstate = (e: any) => {
      resetSelection();
    };
  });

  const updateElementById = useCallback(
    (
      id: any,
      data: any,
      pageId: any,
      _selectedElementID: any,
      action?: string
    ) => {
      if (isPresentation || !!isPublic) return;
      const nextState = clone(_editorRef.current);
      if (
        nextState &&
        nextState.pages &&
        nextState.pages[pageId] &&
        Object.keys(nextState.pages[pageId].elements).length > 0
      ) {
        nextState.pages[pageId].elements[id] = {
          ...nextState.pages[pageId].elements[id],
          ...data,
        };

        if (_selectedElementID && _selectedElementID === id) {
          dispatch(
            setSelectedDesignElement(nextState.pages[pageId].elements[id])
          );
          dispatch(
            setCurrentFormat({ format: nextState.pages[pageId].elements[id].attributes })
          );
        }
        setEditorStateBasedOnSource(nextState, action);
      }
    },
    []
  );

  const onLoadElementsSnapBounds = (data: any) => {
    if (data && data.pages && Object.keys(data.pages).length > 0) {
      Object.values(data.pages).map((p: any) => {
        Object.values(p.elements).map((e: any) => { });
      });
    }
  };

  const getPageBasedOnPosition = (
    dropPosition: any,
    currPageId: string = ""
  ) => {
    let _bounds = pageBounds.current;
    let bothBoundPageId, oneBoundPageId, maxBoundPageId;
    let { top, left } = dropPosition,
      height = (selectedElement?.height || 0) * zoomRef.current,
      width = (selectedElement?.width || 0) * zoomRef.current,
      bottom = top + height,
      right = left + width,
      maxOverlap = 0;
    // midTop = top + (bottom - top) / 2,
    // midLeft = left + (right - left) / 2,

    for (let i = 0; i < Object.keys(_bounds).length; i++) {
      let id = Object.keys(_bounds)[i];
      if (
        (top <= _bounds[id].bottom &&
          top >= _bounds[id].top &&
          left <= _bounds[id].right &&
          left >= _bounds[id].left) ||
        (bottom <= _bounds[id].bottom &&
          bottom >= _bounds[id].top &&
          right <= _bounds[id].right &&
          right >= _bounds[id].left)
      ) {
        bothBoundPageId = id;
      }

      let overlap = getRectOverlap(
        { x: left, y: top, height: height, width: width },
        {
          x: _bounds[id].left,
          y: _bounds[id].top,
          height: defaultPage.current.height * zoomRef.current,
          width: defaultPage.current.width * zoomRef.current,
        }
      );
      if (overlap > maxOverlap) {
        maxOverlap = overlap;
        maxBoundPageId = id;
      }
    }

    if (bothBoundPageId) {
      return {
        id: bothBoundPageId,
        top: _bounds[bothBoundPageId].top,
        left: _bounds[bothBoundPageId].left,
      };
    } else if (maxBoundPageId) {
      return {
        id: maxBoundPageId,
        top: _bounds[maxBoundPageId].top,
        left: _bounds[maxBoundPageId].left,
      };
    }

    if (currPageId) {
      return {
        id: currPageId,
        top: _bounds[currPageId].top,
        left: _bounds[currPageId].left,
      };
    }

    return false;
  };

  const selectCallback = useCallback((e: any, ele: any) => {
    if (ele.id === selectedElement?.id || e.shiftKey) return;
    setTargets([e.currentTarget]);
    dispatch(setSelectedDesignElement(ele));
    dispatch(setCurrentFormat({ format: ele.attributes }));
    e.stopPropagation();
  }, [selectedElement?.id]);

  const addElementOnPage = (element: any, dropPosition: any, page: any, updateState: boolean = true) => {
    element.posY = (dropPosition.top - page.top) / zoomRef.current;
    element.posX = (dropPosition.left - page.left) / zoomRef.current;
    let nextState = clone(_editorRef.current);
    element = {
      ...element,
      id: nanoid(10),
      pageId: page.id,
      zIndex: getZIndex(nextState.pages[page.id].elements) || 0,
    };
    if (updateState) {
      nextState.pages[page.id].elements[element.id] = element;
      setEditorStateBasedOnSource(nextState);

      lastEle.current = element;
      incEleChangeCounter();
      if (lastRef) {
        setTimeout(() => {
          lastRef.current = [document.querySelector(`.element_${element.pageId}_${element.id}`)];
        });
        dispatch(setSelectedDesignElement(lastEle.current));
        dispatch(setCurrentFormat({ format: lastEle.current.attributes }));
        dispatch(setIsTextEditorActive(false));
        dispatch(setIsTableEditorActive(false));
      }
    }
    return element;
  };

  const addElementonCurrPage = useCallback(
    (element: any, isPage: boolean = false) => {
      // As we are updating in view page id based on demand, enforce to get
      // current page view id
      setActivePageId();
      // if current in view page is partially above or below the screen,
      //      get the visible page mid point as drop point
      // else if top and bottom of page is beyond screen take window mid point as drop point
      let x = wh / 2;
      if (
        pageBounds.current[inViewPageId.current] &&
        pageBounds.current[inViewPageId.current].top -
        pageContainerRef.current.scrollTop <
        0 &&
        pageBounds.current[inViewPageId.current].bottom -
        pageContainerRef.current.scrollTop >
        wh
      ) {
        x = wh / 2;
      } else if (
        pageBounds.current[inViewPageId.current] &&
        pageBounds.current[inViewPageId.current].bottom -
        pageContainerRef.current.scrollTop >
        wh / 2 &&
        pageBounds.current[inViewPageId.current].bottom -
        pageContainerRef.current.scrollTop <
        wh
      ) {
        x = Math.max(
          pageBounds.current[inViewPageId.current].top +
          (defaultPage.current.height * zoomRef.current) / 2 -
          pageContainerRef.current.scrollTop,
          (pageBounds.current[inViewPageId.current].bottom -
            pageContainerRef.current.scrollTop) /
          2
        );
      } else {
        let pageTop = pageBounds.current[inViewPageId.current]
          ? pageBounds.current[inViewPageId.current].top
          : 100;
        x = Math.min(
          pageTop +
          (defaultPage.current.height * zoomRef.current) / 2 -
          pageContainerRef.current.scrollTop,
          pageTop -
          pageContainerRef.current.scrollTop +
          (pageContainerRef.current.scrollTop + wh - pageTop) / 2
        );
      }

      if (!isPage) {
        addElement(
          element,
          {
            left:
              ww / 2 > pageBounds.current[inViewPageId.current].left
                ? ww / 2
                : pageBounds.current[inViewPageId.current].left +
                (defaultPage.current.width / 2 - 150) * zoomRef.current,
            top: x,
          },
          "drop"
        );
      } else {
        updatePageBg(
          element,
          {
            left:
              ww / 2 > pageBounds.current[inViewPageId.current].left
                ? ww / 2
                : pageBounds.current[inViewPageId.current].left +
                (defaultPage.current.width / 2 - 150) * zoomRef.current,
            top: x,
          },
          "drop"
        );
      }
    },
    []
  );

  const addElement = useCallback(
    (
      element: any,
      dropPosition = { left: 100, top: 100 },
      type = "drop",
      currPageId: string = "",
      dnd: boolean = true,
      updateState: boolean = true
    ) => {
      if (!dnd) {
        return addElementonCurrPage(element);
      }

      if (type === "drop") {
        dropPosition = {
          left: dropPosition.left + pageContainerRef.current.scrollLeft,
          top: dropPosition.top + pageContainerRef.current.scrollTop,
        };
      }

      let page = getPageBasedOnPosition(dropPosition, currPageId);
      if (page) {
        return addElementOnPage(element, dropPosition, page, updateState);
      }
    },
    []
  );
  const getSelectedBox = (element: any) => {
    return new SAT.Box(
      new SAT.Vector(element.posX, element.posY),
      element.width,
      element.height
    ).toPolygon();
  };

  const updateZIndex = useCallback(
    (elementId: any, pageId: any, action = "move_front") => {
      const element = _editorRef.current.pages[pageId].elements[elementId];
      const V = SAT.Vector;
      const B = SAT.Box;
      switch (action) {
        case "move_front":
          return updateElementById(
            elementId,
            {
              zIndex: getZIndex(
                _editorRef.current.pages[pageId].elements,
                "omax"
              ),
            },
            pageId,
            selectedElement.id
          );
        case "move_back":
          return updateElementById(
            elementId,
            {
              zIndex: getZIndex(
                _editorRef.current.pages[pageId].elements,
                "omin"
              ),
            },
            pageId,
            selectedElement.id
          );
        case "move_backwards":
          const iteratees_rev = (obj: any) => -obj[1].zIndex; // for desc order
          forEach(
            sortBy(
              entries(_editorRef.current.pages[pageId].elements),
              iteratees_rev
            ),
            ([eid, ele]: any) => {
              if (ele.zIndex >= element.zIndex) {
                // skip to the next of current element
                return true;
              }
              const boxToCompare = new B(
                new V(ele.posX, ele.posY),
                ele.width,
                ele.height
              ).toPolygon();
              if (
                SAT.testPolygonPolygon(getSelectedBox(element), boxToCompare)
              ) {
                // switch element zindexes
                updateElementById(
                  eid,
                  { zIndex: element.zIndex },
                  pageId,
                  selectedElement.id
                );
                updateElementById(
                  elementId,
                  { zIndex: ele.zIndex },
                  pageId,
                  selectedElement.id
                );
                return false;
              }
            }
          );
          return;
        case "move_forwards":
          const iteratees = (obj: any) => obj[1].zIndex; // for asc order
          forEach(
            sortBy(
              entries(_editorRef.current.pages[pageId].elements),
              iteratees
            ),
            ([eid, ele]: any) => {
              if (ele.zIndex <= element.zIndex) {
                // skip to the next of current element
                return true;
              }
              const boxToCompare = new B(
                new V(ele.posX, ele.posY),
                ele.width,
                ele.height
              ).toPolygon();
              if (
                SAT.testPolygonPolygon(getSelectedBox(element), boxToCompare)
              ) {
                // switch element zindexes
                updateElementById(
                  eid,
                  { zIndex: element.zIndex },
                  pageId,
                  selectedElement.id
                );
                updateElementById(
                  elementId,
                  { zIndex: ele.zIndex },
                  pageId,
                  selectedElement.id
                );
                return false;
              }
            }
          );
          return;
        default:
        // do nothing
      }
    },
    [_editorRef]
  );

  const updatePageBg = useCallback(
    (
      element: any,
      dropPosition = { left: 100, top: 100 },
      type = "drop",
      currPageId: string = "",
      dnd: boolean = true
    ) => {
      if (!dnd) {
        addElementonCurrPage(element, true);
        return;
      }

      if (type === "drop") {
        dropPosition = {
          left: dropPosition.left + pageContainerRef.current.scrollLeft,
          top: dropPosition.top + pageContainerRef.current.scrollTop,
        };
      }

      let page = getPageBasedOnPosition(dropPosition, currPageId);
      if (page) {
        updatePageById(page.id, element);
      }
    },
    []
  );

  const getZIndex = (elements: Object, type = "omax") => {
    let _elements = Object.values(elements).filter(
      (e) => (e._destroy || false) === false
    );
    switch (type) {
      case "omax":
        return Math.max(49, ..._elements.map((e) => e.zIndex)) + 1;
      case "omin":
        return Math.min(49, ..._elements.map((e) => e.zIndex)) - 1;
      default:
        return 50;
    }
  };

  const updateGroupPositionAndSize = (type = "drag", targetEvents: any) => {
    const _bounds = pageBounds.current;
    const deletedElements: any[] = [];
    setGroupEditInProgress(true);
    let groupNextSelectedElements = targetEvents
      .filter(({ lastEvent }: any) => lastEvent)
      .map(({ lastEvent }: any) => {
        const { pageid: _currpageid, elementid } = lastEvent.target.dataset;
        let nextSelectedElement = get(_editorRef.current, ["pages", _currpageid, "elements", elementid]);

        let data = {};
        if (type === "resize") {
          data = {
            height: lastEvent.height,
            width: lastEvent.width,
            posX: lastEvent.drag.beforeTranslate[0],
            posY: lastEvent.drag.beforeTranslate[1],
          };

          nextSelectedElement = {
            ...nextSelectedElement,
            ...data
          }
        } else if (type !== "rotate") {
          const trackPosY = lastEvent.beforeTranslate[1] * zoom + _bounds[_currpageid].top;
          const isWithinCurrentPage =
            (trackPosY <= _bounds[_currpageid].bottom && trackPosY >= _bounds[_currpageid].top) ||
            (trackPosY + (lastEvent?.height || 0) * zoom >= _bounds[_currpageid].top &&
              trackPosY + (lastEvent?.height || 0) * zoom <= _bounds[_currpageid].bottom);

          if (isWithinCurrentPage) {
            data = {
              posX: lastEvent.beforeTranslate[0],
              posY: lastEvent.beforeTranslate[1],
            };
            nextSelectedElement = {
              ...nextSelectedElement,
              ...data
            }
          } else {
            const page = getPageBasedOnPosition({
              top: trackPosY,
              left: lastEvent.beforeTranslate[0] * zoom + _bounds[_currpageid].left,
            }, _currpageid);

            if (page && page.id !== _currpageid) {
              deletedElements.push({
                ...nextSelectedElement,
                _destroy: true
              });
              let newElement = addElementOnPage(
                {
                  ...nextSelectedElement,
                  attributes: { ...nextSelectedElement.attributes, onLoadDelta: nextSelectedElement.delta },
                  height: lastEvent.height,
                  width: lastEvent.width,
                },
                {
                  top: trackPosY,
                  left: lastEvent.beforeTranslate[0] * zoom + _bounds[_currpageid].left,
                },
                page,
                false //updatestate
              );
              if (newElement && newElement.id) {
                nextSelectedElement = newElement;
              } else {
                nextSelectedElement = null;
              }
            }
          }
        }

        return nextSelectedElement;
      })
      .filter(Boolean);

    groupNextSelectedElements = [...groupNextSelectedElements, ...deletedElements];
    if (groupNextSelectedElements.length) {
      updateGroupEditorState(groupNextSelectedElements);
      setTimeout(() => {
        if (groupNextSelectedElements.length > 0) {
          lastRef.current = groupNextSelectedElements
            .filter((el: any) => el && el.pageId && el.id)
            .map((el: any) => document.querySelector(`.element_${el.pageId}_${el.id}`))
            .filter(Boolean);
          const definedElements = groupNextSelectedElements.filter((el: any) => el && el.pageId && el.id);
          if (definedElements.length > 0) {
            const lastElement = definedElements[definedElements.length - 1];
            lastEle.current = lastElement;
          }
        }
        setGroupEditInProgress(false);
      });
    } else {
      setGroupEditInProgress(false);
    }
  };

  const updatePositionAndSize = (
    type = "drag",
    position: any,
    size: any = {},
    currentElement: any = null
  ) => {
    // Note: Position sent to this function to relative to parent page
    // it orginally belongs to.
    let _bounds = pageBounds.current;
    currentElement = currentElement || selectedElement;
    let _currpageid = currentElement?.pageId;
    if (_currpageid) {
      let trackPosY = position.posY * zoom + _bounds[_currpageid].top;
      if (type === "resize" && size?.height && size?.width) {
        updateEditorState(
          {
            ...size,
            ops: "resize",
            posX: position.posX,
            posY: position.posY,
          },
          currentElement
        );
      } else {
        if (
          (trackPosY <= _bounds[_currpageid].bottom &&
            trackPosY >= _bounds[_currpageid].top) ||
          (trackPosY + (currentElement?.height || 0) * zoom >=
            _bounds[_currpageid].top &&
            trackPosY + (currentElement?.height || 0) * zoom <=
            _bounds[_currpageid].bottom)
        ) {
          updateEditorState(
            {
              posX: position.posX,
              posY: position.posY,
              ops: "resize",
            },
            currentElement
          );
        } else {
          let page = getPageBasedOnPosition(
            {
              top: trackPosY,
              left: position.posX * zoom + _bounds[_currpageid].left,
            },
            _currpageid
          );
          if (page && page?.id === _currpageid) {
            updateEditorState(
              {
                posX: position.posX,
                posY: position.posY,
                ops: "resize"
              },
              currentElement
            );
          } else {
            deleteElementById(currentElement?.id, _currpageid);
            let _element = { ...currentElement };
            _element.attributes = {
              ..._element.attributes,
              onLoadDelta: _element.delta
            };
            addElementOnPage(
              { ..._element, ...size, ops: "resize" },
              {
                top: trackPosY,
                left: position.posX * zoom + _bounds[_currpageid].left,
              },
              page
            );
          }
        }
      }
    }
  };

  const updateGroupEditorState = (nextSelectedElements: any = []) => {
    let nextState = clone(_editorRef.current);
    nextSelectedElements.forEach(
      (nextSelectedElement: {
        pageId: string | number;
        id: string | number;
      }) => {
        set(nextState, ["pages", nextSelectedElement.pageId, "elements", nextSelectedElement.id], nextSelectedElement);
      }
    );
    setEditorStateBasedOnSource(nextState);
  };

  const updateEditorState = (data = {}, currentElement = null) => {
    currentElement = currentElement || selectedElement;
    let nextSelectedElement = clone(currentElement);
    nextSelectedElement = {
      ...nextSelectedElement,
      ...data,
    };
    let nextState = clone(_editorRef.current);
    nextState.pages[nextSelectedElement.pageId].elements[
      nextSelectedElement.id
    ] = nextSelectedElement;
    dispatch(setSelectedDesignElement(nextSelectedElement));
    setEditorStateBasedOnSource(nextState);
  };

  const getPrivateToolbar = () => (
    <EditorToolbar
      resetSelection={resetSelection}
      reportId={reportId}
      reportName={reportName}
      saving={saving}
      historyAvailable={historyAvailable}
      onUndo={onUndo}
      onRedo={onRedo}
      onPrint={onPrint}
      progress={progress}
      openedPrintStatusPopover={openedPrintStatusPopover}
      closePrintStatusPopover={closePrintStatusPopover}
      isPrinting={isPrinting}
      onZoomChange={updateZoom}
      zoom={zoom}
      is_editable={isEditable}
      is_exportable={isExportable}
      is_shareable={isShareable}
      reportDataRef={_editorRef}
      reportAttributes={reportData?.attributes}
      onResetSelection={resetSelection}
    />
  );

  const getPublicToolbar = () => {
    if (isPresentation) {
      return (
        <ImpactReportPresentationToolbar
          reportName={reportName}
          onZoomChange={updateZoom}
          themeIsDark={isThemeDark}
          setThemeIsDark={setIsThemeDark}
          fullScreenHandle={handle}
          zoom={zoom}
          isPublicManuallySet={isPublicManuallySet}
          reportId={props.reportDetails?.uid || reportId}
          is_exportable={isExportable}
          is_shareable={isShareable}
          publicReportId={props.publicReportId}
          mode={mode}
          pages={
            Object.values(editorState?.pages || {}).filter(
              (x: any) => x._destroy === undefined || x._destroy === false
            ).length || 1
          }
        />
      );
    }
    return (
      <ImpactReportPublicToolbar
        reportName={reportName}
        onZoomChange={updateZoom}
        isPublicManuallySet={isPublicManuallySet}
        zoom={zoom}
        reportId={props.reportDetails?.uid || reportId}
        is_exportable={isExportable}
        is_shareable={isShareable}
        publicReportId={props.publicReportId}
        mode={mode}
        pages={
          Object.values(editorState?.pages || {}).filter(
            (x: any) => x._destroy === undefined || x._destroy === false
          ).length || 1
        }
      />
    );
  };
  const [keepRatio, setKeepRatio] = useState(true);

  const onDrop = (e: any) => {
    e.preventDefault();
  };

  const allowDrop = (ev: any) => {
    ev.preventDefault();
  };

  const backgroundColorSetter = () => {
    if (isPresentation) {
      if (isThemeDark) {
        return "#1B1B1F";
      }

      return "white";
    }
    return theme.impactReportingColors.headerColor;
  };

  const handleContextMenu = (event: any) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX + 2,
          mouseY: event.clientY - 6,
        }
        : null
    );
    event.stopPropagation();
  };
  const handleClose = () => {
    setContextMenu(null);
  };

  const getPageAttributes = useMemo(() => {
    return {
      height: _editorRef.current.height,
      width: _editorRef.current.width,
    };
  }, [_editorRef.current.height, _editorRef.current.width]);
  const handle = useFullScreenHandle();

  return (
    <ThemeProvider
      theme={isPresentation ? (isThemeDark ? darkTheme : theme) : theme}
    >
      <FullScreen handle={handle}>
        {
          <style>
            {`
            @media print {
              @page {
                size: ${defaultPage.current.width}px ${defaultPage.current.height}px;
              }
            }
          `}
          </style>
        }

        <Box sx={{ display: "flex" }}>
          <CssBaseline />
          {!disableToolbar && (
            <AppBar
              position="fixed"
              sx={{
                zIndex: (theme) => theme.zIndex.drawer + 10000,
                background: backgroundColorSetter(),
              }}
            >
              <Toolbar>
                {isPublic ? getPublicToolbar() : getPrivateToolbar()}
              </Toolbar>
            </AppBar>
          )}
          {isPublic ? (
            ""
          ) : (
            <LibraryandSetting
              reportUID={reportId}
              pageAttributes={getPageAttributes}
              addElement={addElement}
              addElementonCurrPage={addElementonCurrPage}
              clearClipBoard={clearClipBoard}
              targets={targets}
              selectedElement={selectedElement}
              updatePageBg={updatePageBg}
              addPage={addPage}
              updateElement={updateElementById}
              deleteElement={deleteElementById}
              toggleCustomAbles={toggleCustomAbles}
              onResetSelection={resetSelection}
            />
          )}
          {isPresentation ? (
            <Box
              sx={{
                minHeight: handle.active ? "100vh" : "93vh",
                backgroundColor: isThemeDark ? "#252525" : "#f7f7f7",
                mt: handle.active ? 8 : 0,
              }}
            >
              <PresentationLeftPanel
                isThemeDark={isThemeDark}
                setCurrentPageIndex={setCurrentPageIndex}
                currentPageIndex={currentPageIndex}
                pages={Object.values(
                  orderBy(
                    pickBy(
                      editorState?.pages,
                      (page) =>
                        page._destroy === undefined || page._destroy === false
                    ),
                    ["position"]
                  )
                ).map((page: any, index: any, { length }) => {
                  return {
                    pageTitle: page.attributes?.pageTitle,
                    pageIndex: index,
                  };
                })}
              />
            </Box>
          ) : (
            ""
          )}
          <Box
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              zIndex: 1,
              WebkitUserSelect: "none" /* Safari */,
              msUserSelect: "none" /* IE 10 and IE 11 */,
              userSelect: "none" /* Standard syntax */,
            }}
            sx={{ mt: handle.active ? 8 : 0 }}
          >
            <Box
              className={"ir-static-container"}
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                overflow: "hidden",
                minHeight: "calc(100vh - 64px)",
                top: "-64px",
              }}
              mt={8}
              mb={5}
            >
              <Box
                component="main"
                sx={{
                  flexGrow: 1,
                  ".container::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
                className={"pebble-moveable-container"}
                ref={pageContainerRef}
                style={{
                  position: "relative",
                  backgroundColor:
                    isPresentation && isThemeDark ? "#252525" : "#f7f7f7",
                  overflow: isPresentation ? "hidden" : "auto",
                  height: "100%",
                  width: "100%",
                  paddingTop: "24px"
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onClick={handleMouseUp}
              >
                {/* TODO: Ideally this should be inside main container.
          Fix is to make moveable guidlines work inside main container box */}
                {isPublic ? (
                  ""
                ) : (
                  <>
                    <Moveable
                      ref={moveRef}
                      targets={targets}
                      useResizeObserver={true}
                      useMutationObserver={true}
                      onClickGroup={(e: any) => {
                        if (!e.moveableTarget) {
                          setSelectedTargets([]);
                          return;
                        }
                        if (e.isDouble) {
                          const childs = groupManager.selectSubChilds(
                            targets,
                            e.moveableTarget
                          );

                          setSelectedTargets(childs.targets());
                          return;
                        }
                        if (e.isTrusted) {
                          selectoRef.current!.clickTarget(
                            e.inputEvent,
                            e.moveableTarget
                          );
                        }
                      }}
                      onRenderGroup={(e: any) => {
                        e.events.forEach((ev: any) => {
                          ev.target.style.cssText += ev.cssText;
                        });
                      }}
                      resizable={true}
                      scrollable={true}
                      // className={"pebble-moveable"}
                      scalable={true}
                      keepRatio={
                        selectedElement?.type === "TextEditor" ||
                          selectedElement?.type === "ShapesEditor" ||
                          selectedElement?.type === "KPIEditor" ||
                          selectedElement?.type === "InteractiveElementEditor" ||
                          selectedElement?.type === "TabularReportEditor"
                          ? keepRatio
                          : true
                      }
                      renderDirections={
                        ["TextEditor", "TabularReportEditor"].includes(selectedElement?.type)
                          ? ["nw", "ne", "se", "sw", "e", "w"]
                          : ["n", "nw", "ne", "s", "se", "sw", "e", "w"]
                      }
                      originDraggable={true}
                      originRelative={true}
                      snappable={true}
                      snapThreshold={5}
                      isDisplaySnapDigit={true}
                      isDisplayInnerSnapDigit={false}
                      snapGap={true}
                      snapDirections={{
                        top: true,
                        right: true,
                        bottom: true,
                        left: true,
                        center: true,
                        middle: true,
                      }}
                      elementSnapDirections={{
                        top: true,
                        right: true,
                        bottom: true,
                        left: true,
                        middle: true,
                        center: true,
                      }}
                      draggable={true}
                      origin={false}
                      throttleDrag={0}
                      // startDragRotate={0}
                      // throttleDragRotate={0}
                      zoom={1}
                      padding={{ left: 0, top: 0, right: 0, bottom: 0 }}
                      rotatable={targets && targets.length > 1 ? false : true}
                      // throttleRotate={0}
                      // rotationPosition={"left"}
                      onDragStart={(e) => {
                        setIsElementOnMove(true);
                      }}
                      onDragGroupStart={(e) => {
                        setIsElementOnMove(true);
                      }}
                      onDrag={(e) => {
                        e.target.style.transform = e.transform;
                      }}
                      onDragGroup={(e) => {
                        e.events.forEach((e) => {
                          e.target.style.transform = e.transform;
                        });
                      }}
                      onDragEnd={({ lastEvent }) => {
                        if (lastEvent) {
                          updatePositionAndSize(
                            "drag",
                            {
                              posX: lastEvent.beforeTranslate[0],
                              posY: lastEvent.beforeTranslate[1],
                            },
                            {
                              height: lastEvent.height,
                              width: lastEvent.width,
                            },
                            get(_editorRef.current, [
                              "pages",
                              lastEvent.target.dataset.pageid,
                              "elements",
                              lastEvent.target.dataset.elementid,
                            ])
                          );
                        }
                        setIsElementOnMove(false);
                      }}
                      onDragGroupEnd={(e: any) => {
                        updateGroupPositionAndSize("drag", e.events);
                        setIsElementOnMove(false);
                      }}
                      onResizeStart={(e) => {
                        if (e.direction[1] * e.direction[0] === 0) {
                          setKeepRatio(false);
                        } else {
                          setKeepRatio(true);
                        }
                        setIsElementOnMove(true);
                      }}
                      onResizeGroupStart={(e) => {
                        setKeepRatio(true);
                        setIsElementOnMove(true);
                      }}
                      onResize={(e) => {
                        if (e?.width !== 0 && e?.height !== 0) {
                          e.target.style.width = `${e.width}px`;
                          e.target.style.height = `${e.height}px`;
                          e.target.style.transform = e.drag.transform;
                        }
                      }}
                      onResizeGroup={(events) => {
                        events.events.forEach((e) => {
                          e.target.style.width = `${e.width}px`;
                          e.target.style.height = `${e.height}px`;
                          e.target.style.transform = e.drag.transform;
                        });
                      }}
                      onResizeEnd={({ lastEvent }) => {
                        if (lastEvent) {
                          if (lastEvent.height === 0 && lastEvent.width === 0) {
                            return;
                          }
                          updatePositionAndSize(
                            "resize",
                            {
                              posX: lastEvent.drag.beforeTranslate[0],
                              posY: lastEvent.drag.beforeTranslate[1],
                            },
                            {
                              height: lastEvent.height,
                              width: lastEvent.width,
                            },
                            get(_editorRef.current, [
                              "pages",
                              lastEvent.target.dataset.pageid,
                              "elements",
                              lastEvent.target.dataset.elementid,
                            ])
                          );
                        }
                        setIsElementOnMove(false);
                      }}
                      onResizeGroupEnd={(e: any) => {
                        updateGroupPositionAndSize("resize", e.events);
                        setIsElementOnMove(false);
                      }}
                      onRotateStart={(e) => {
                        setIsElementOnMove(true);
                      }}
                      // onRotateGroupStart={(e) => {
                      //   setIsElementOnMove(true);
                      // }}
                      onRotate={(e) => {
                        e.target.style.transform = e.drag.transform;
                      }}
                      // onRotateGroup={(e) => {
                      //   e.events.forEach((e) => {
                      //     e.target.style.transform = e.drag.transform;
                      //   });
                      // }}
                      onRotateEnd={({ lastEvent }) => {
                        if (lastEvent) {
                          updateEditorState({
                            rotate: lastEvent.rotate,
                          });
                        }
                        setIsElementOnMove(false);
                      }}
                      // onRotateGroupEnd={(e: any) => {
                      //   updateGroupPositionAndSize("rotate", e.events);
                      //   setIsElementOnMove(false);
                      // }}
                      clippable={clipAble}
                      clipRelative={true}
                      clipArea={true}
                      onClip={(e) => {
                        e.target.style.clipPath = e.clipStyle;
                        frame.clipStyle = e.clipStyle;
                      }}
                      clipTargetBounds={true}
                      clipVerticalGuidelines={[]}
                      clipHorizontalGuidelines={[]}
                      elementGuidelines={snapElements}
                      onClipEnd={({ lastEvent }) => {
                        if (lastEvent) {
                          updateEditorState({
                            clipStyleBounds: getClipStylesFromBounds(
                              lastEvent.clipStyles,
                              1, //ZOOMTODO
                              "bounds"
                            ),
                          });
                        }
                      }}
                      dragTargetSelf={true}
                    />
                    {reportData && editorState && editorState.pages && (
                      <Selecto
                        ref={selectoRef}
                        dragContainer={dragContainer}
                        selectableTargets={[".elementContainer"]}
                        hitRate={0}
                        selectByClick={true}
                        selectFromInside={false}
                        toggleContinueSelect={["shift"]}
                        // continueSelect={false}
                        ratio={0}
                        onDragStart={(e: any) => {
                          // TODO: Dragstart of moveable is not
                          // a synthetic event hence we are not able
                          // to stop the event from propagating to selecto
                          // Currently we are using isElementOnMove to check
                          // if moveable is on move or not. But this is wrong as
                          // this is state update which is async update. Might fail.
                          // if (isElementOnMove) {
                          //   e.stop();
                          //   return;
                          // }
                          // resetSelection();
                          const moveable = moveRef.current!;
                          const targts = e.inputEvent.target;
                          // Must have use deep flat
                          const flatted = targets?.flat(3) as Array<
                            HTMLElement | SVGElement
                          >;
                          if (
                            moveable.isMoveableElement(targts) ||
                            flatted.some(
                              (t) => t === targts || t.contains(targts)
                            )
                          ) {
                            e.stop();
                          }
                        }}
                        onSelect={(e) => {
                          if (e.isDragStartEnd) {
                            return;
                          }
                          setTargets(e.selected);
                        }}
                        onSelectEnd={(e) => {
                          if (e.isDragStartEnd) {
                            e.inputEvent.preventDefault();
                            moveRef.current!.waitToChangeTarget().then(() => {
                              moveRef.current!.dragStart(e.inputEvent);
                            });
                          }
                          if (e.selected!.length > 0) {
                            lastRef.current = e.selected;
                            setSelectedTargets(e.selected);
                          }
                          setSelectionMade(e.selected.length > 0);
                        }}
                      />
                    )}
                    <Menu
                      style={{
                        zIndex: 3001,
                        display: isPublic ? "none" : "block",
                      }}
                      sx={{
                        "& .MuiMenu-paper": {
                          padding: "18px 12px 18px 12px",
                          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.12)",
                          borderRadius: "6px",
                        },
                        "& .MuiMenuItem-root": {
                          margin: "6px",
                        },
                      }}
                      open={contextMenu !== null}
                      onClose={handleClose}
                      anchorReference="anchorPosition"
                      anchorPosition={
                        contextMenu !== null
                          ? {
                            top: contextMenu.mouseY,
                            left: contextMenu.mouseX,
                          }
                          : undefined
                      }
                    >
                      <MenuItem
                        onClick={() => {
                          let elements = targets.map(
                            (target: {
                              dataset: { pageid: any; elementid: any };
                            }) => {
                              return get(_editorRef.current, [
                                "pages",
                                target.dataset.pageid,
                                "elements",
                                target.dataset.elementid,
                              ]);
                            }
                          );
                          copyItem(JSON.stringify(elements));
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <img
                            src={CopyElementIcon}
                            alt="Copy Element"
                            style={{ maxHeight: "18px" }}
                          />
                        </ListItemIcon>
                        <ListItemText>Copy</ListItemText>
                      </MenuItem>
                      <MenuItem
                        onClick={(e: any) => {
                          pasteElement({ x: e.pageX, y: e.pageY });
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <img
                            src={CopyElementIcon}
                            alt="Paste Element"
                            style={{ maxHeight: "18px" }}
                          />
                        </ListItemIcon>
                        <ListItemText>Paste</ListItemText>
                      </MenuItem>
                      {/* <MenuItem onClick={(e: any) => groupSelectedItems(e)}>
                        <ListItemIcon>
                          <CallSplitIcon
                            sx={{
                              color: theme.custom.primaryDarkColor,
                              cursor: "pointer",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText>Group</ListItemText>
                      </MenuItem> */}
                      {/* {targets && targets.length == 1 && isArray(targets[0]) && (
                        <MenuItem onClick={(e: any) => ungroupSelectedItems(e)}>
                          <ListItemIcon>
                            <MergeIcon
                              sx={{
                                color: theme.custom.primaryDarkColor,
                                cursor: "pointer",
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText>Ungroup</ListItemText>
                        </MenuItem>
                      )} */}
                      <Divider
                        sx={{ borderColor: theme.custom.menuDividerColor }}
                      />
                      <MenuItem
                        onClick={() => {
                          deleteMultipleElementsById(map(targets, (target) => ({
                            id: target.dataset.elementid,
                            pageId: target.dataset.pageid,
                          })));
                          handleClose();
                        }}
                      >
                        <ListItemIcon>
                          <img
                            src={ElementDeleteIcon}
                            alt="Delete"
                            style={{ maxHeight: "18px" }}
                          />
                        </ListItemIcon>
                        <ListItemText>Delete</ListItemText>
                      </MenuItem>
                    </Menu>
                  </>
                )}

                {reportData &&
                  editorState &&
                  editorState.pages &&
                  Object.values(
                    orderBy(
                      pickBy(
                        editorState?.pages,
                        (page) =>
                          page._destroy === undefined || page._destroy === false
                      ),
                      ["position"]
                    )
                  ).map((page: any, index: any, { length }) => {
                    let pageId = page.id;

                    return (
                      <Grid
                        className={"page_container selecto-area"}
                        container
                        direction={"column"}
                        alignItems={"center"}
                        // mt={currentPageIndex === 0 ? 10 : 5}
                        // mb={10}
                        zIndex={0}
                        key={pageId+'grid'}
                        sx={{
                          display: "flex",
                          visibility:
                            isPresentation ? currentPageIndex === index
                              ? "visible" : "hidden" : "visible",
                          height: isPresentation ? currentPageIndex === index
                            ? "auto" : "0px" : "auto",
                          width: isPresentation ? currentPageIndex === index
                            ? "auto" : "0px" : "auto",
                        }}
                      >
                        <Page
                          isPresentation={isPresentation}
                          setCurrentPageIndex={setCurrentPageIndex}
                          groupTargets={targets && targets.length > 1 ? true : false}
                          currentPageIndex={currentPageIndex}
                          isTemplateEdit={!!reportData?.attributes?.templateId}
                          reportUID={reportId}
                          key={pageId}
                          page={page}
                          keepRatio={keepRatio}
                          selectedElement={selectedElement}
                          moveRef={moveRef}
                          selectCallback={selectCallback}
                          toggleCustomAbles={toggleCustomAbles}
                          isElementOnMove={isElementOnMove}
                          lastEle={lastEle}
                          lastRef={lastRef}
                          zoomRef={zoomRef}
                          updateElementById={updateElementById}
                          deleteElementById={deleteElementById}
                          updateZIndex={updateZIndex}
                          copyItem={copyItem}
                          id={pageId}
                          inPrint={inPrint}
                          index={index + 1}
                          height={page.height}
                          width={page.width}
                          zoom={zoom}
                          addElement={addElement}
                          onMove={updatePagePosition}
                          updatePage={updatePageById}
                          pageAttributes={page?.attributes}
                          fill={"#fff"}
                          setPageBounds={setPageBounds}
                          deletePage={deletePageById}
                          copyPage={copyPageById}
                          addPage={addPage}
                          isPublic={isPublic}
                          position={page.position}
                          pageColor={page?.attributes?.color || "#ffffffff"}
                          pageTitle={page?.attributes?.pageTitle || ""}
                          pageBackground={
                            page?.attributes?.backgroundImage || ""
                          }
                          pageBackgroundFilter={page?.attributes?.filter || ""}
                          pagesLength={length}
                          clipBoardItem={clipBoardItemRef.current}
                          pasteElement={pasteElement}
                          editorSource={editorState.source}
                          duplicateElement={duplicateElement}
                        ></Page>
                      </Grid>
                    );
                  })}
              </Box>
            </Box>
          </Box>
        </Box>
      </FullScreen>
    </ThemeProvider>
  );
}

export default memo(ImpactReportEditor);
