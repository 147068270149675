import React, { useEffect } from "react";
import Pagination from "@mui/material/Pagination";

type Props = {
  pagination: any;
  onPaginationChange: Function;
};

const TablePagination = (props: Props) => {
  const { pagination, onPaginationChange } = props;
  const [page, setPage] = React.useState(1);

  useEffect(() => {
    setPage(pagination["current_page"]);
  }, [pagination]);

  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value);
    onPaginationChange(value);
  };

  return (
    <div>
      <Pagination
        count={pagination["total_pages"]}
        page={page}
        onChange={handleChange}
      />
    </div>
  );
};

export default TablePagination;
