import { CSSProperties, FC, useState } from "react";
import React from "react";
import { useDrop } from "react-dnd";
import { theme } from "../../theme";
import { DraggableItemType, DraggableResultType } from "./DraggableItem";
import AddIcon from "../../assets/add.png";
import "./DropTarget.scss";

const defaultStyle: CSSProperties = {
  minHeight: "10rem",
  width: "20rem",
  color: "black",
  padding: "1rem",
  fontSize: "1rem",
  lineHeight: "normal",
  backgroundColor: `${theme.custom.chipGroupBackground}`,
  border: `1px solid ${theme.custom.borderColor}`,
  borderRadius: "0.5rem",
};

interface DropTargetProps {
  id: any;
  name: string;
  sx?: any;
  style?: any;
  item?: any;
  extraProps?: any;
  children?: React.ReactNode;
  isEmpty: boolean;
  accepts: string | string[];
  onDrop?: any;
  onHover?: any;
}

interface DropResultType {
  id: any;
  name: string;
  extraProps: any;
}

const DropTarget = React.memo(function (target: DropTargetProps) {
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: target.accepts,
      drop: (item: DraggableItemType, monitor) => {
        var targetResult: DropResultType = {
          ...target.item,
          id: target.id,
          name: target.name,
        };
        if (target.onDrop) {
          let itemResult: DraggableResultType = {
            ...item.item,
            id: item.id,
            name: item.name,
            type: item.type,
          };
          target.onDrop(itemResult, targetResult);
        }
        return targetResult;
      },
      hover: (item: DraggableItemType, monitor) => {
        var targetResult: DropResultType = {
          ...target.item,
          id: target.id,
          name: target.name,
        };

        if (target.onHover) {
          let itemResult: DraggableResultType = {
            ...item.item,
            id: item.id,
            name: item.name,
          };
          target.onHover(itemResult, targetResult);
        }
        return targetResult;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [target]
  );

  return (
    <div
      ref={drop}
      style={{
        ...(target.style ? target.style : defaultStyle),
        position: "relative",
      }}
      data-testid={`droptarget_${target.id}`}
      className={`dropTarget ${
        isOver ? (canDrop ? "active" : "cannotDrop") : ""
      }`}
      {...target?.extraProps}
    >
      {target.isEmpty ? (
        <div
          style={{
            position: "absolute",
            top: "calc(50% - 12px)",
            left: "calc(50% - 12px)",
          }}
        >
          <img
            src={AddIcon}
            alt="Add Item"
            style={{
              maxHeight: "24px",
              maxWidth: "24px",
            }}
          />
        </div>
      ) : (
        <></>
      )}
      {target.children}
    </div>
  );
});

export { DropTarget };
export type { DropTargetProps, DropResultType };
