import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./store";
import { Paper } from "@mui/material";
import { ThemeProvider } from "@mui/system";
import { theme } from "./theme";
import { BrowserRouter } from "react-router-dom";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import {
  requestInterceptor,
  responseInterceptor,
} from "./utils/app.interceptor";
import Highcharts from "highcharts";
import HC_exporting from "highcharts/modules/exporting";
import HC_exportdata from "highcharts/modules/export-data.js"
import HC_rounded from "./utils/rounded-corner.helper";
import HC_fullscreen from 'highcharts/modules/full-screen.js';
import * as Sentry from "@sentry/react";
import { environment } from "./environments/environment-dev";
HC_fullscreen(Highcharts);
HC_rounded(Highcharts);
HC_exporting(Highcharts);
HC_exportdata(Highcharts);
if (["UAT", "PROD"].includes(environment.env || "")) { 
  Sentry.init({
        dsn: environment.sentryDsn || "",
        integrations: [
          new Sentry.BrowserTracing({
            tracePropagationTargets: [environment.authUrl || "", environment.connectorApiUrl || ""],
          }),
          new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: parseFloat(environment.sentryTracesSampleRate || "0.1"),
        // // Session Replay
        replaysSessionSampleRate: parseFloat(environment.sentryReplaySessionSampleRate || "0.1"),
        replaysOnErrorSampleRate: parseFloat(environment.sentryReplayErrorSampleRate || "1.0"),
        environment: environment.env || ""
  });
}
requestInterceptor();
responseInterceptor();
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Paper elevation={0}>
          <BrowserRouter>
            <DndProvider backend={HTML5Backend}>
              <App />
            </DndProvider>
          </BrowserRouter>
        </Paper>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
