import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { clone } from "lodash";
import { memo, useEffect, useRef, useState } from "react";
import { ConnectorMappingConfig } from "../../services/user-mapping-service";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import {
  setSaving,
  setUMDirty,
  setActiveUMId,
  updateMapping,
} from "../../store/user-mapping-store";
import { createMappingEntry } from "../../utils/user-mapping-utils";
import UMQueryBuilder from "./UserMapping/UMQueryBuilder";
import { NameLabelPair, OptionGroup } from "react-querybuilder";

interface Props {
  UMData: ConnectorMappingConfig;
}

const QuickbooksConditionalMappingsListItem = ({ UMData }: Props) => {
  const UMDataRef = useRef(null as any);
  const [conditions, setConditions] = useState(
    UMData.user_mapping?.mapping?.["conditions"] || []
  );
  const [isDirty, setisDirty] = useState(false);

  const allPossibleKeys = useAppSelector((state) => {
    return state.userMapping.allPossibleKeys;
  });

  const handleChange = (event: SelectChangeEvent) => {
    // clear selection
    dispatch(
      updateMapping({
        meta_data_id: UMData.id,
        mapping: createMappingEntry("direct", undefined, event.target.value),
      })
    );
  };

  const [readyForUpdate, setReadyForUpdate] = useState(false);

  useEffect(() => {
    setReadyForUpdate(true);
  }, [UMData.user_mapping]);

  const dispatch = useAppDispatch();

  const updateUM = (item: any) => {
    if (item && item.length) {
      setisDirty(true);
      dispatch(setUMDirty(true));
      dispatch(setActiveUMId(UMData.user_mapping.id));
    }
    UMDataRef.current = item;
  };

  const save = (item: any) => {
    if (!readyForUpdate) return;
    dispatch(setSaving(true));
    setisDirty(false);
    dispatch(setUMDirty(false));
    dispatch(setActiveUMId(null));
    dispatch(
      updateMapping({
        meta_data_id: UMData.id,
        mapping: createMappingEntry(
          "conditions",
          undefined,
          undefined,
          [],
          UMDataRef.current
        ),
      })
    );
  };

  const cancel = () => {
    setisDirty(false);
    dispatch(setUMDirty(false));
    dispatch(setActiveUMId(null));
    setConditions(clone(UMData.user_mapping?.mapping?.["conditions"] || []));
  };

  const dateOperators: NameLabelPair[] | OptionGroup<NameLabelPair>[] = [
    { name: "gt", label: "Greater than" },
    { name: "gte", label: "Greter than equal to" },
    { name: "lt", label: "Less than" },
    { name: "lte", label: "Less than equal to" },
  ];

  const fields = () => {
    return Object.keys(allPossibleKeys).map((k) => {
      if (k === "date")
        return {
          name: k,
          label: allPossibleKeys[k],
          operators: dateOperators,
          defaultOperator: "gt",
        };
      else {
        return {
          name: k,
          label: allPossibleKeys[k],
        };
      }
    });
  };

  return (
    <Grid container justifyContent="space-between">
      <Grid item sx={{ mt: 1 }} xs={4}>
        <Typography variant="inter60016">{UMData.title}</Typography>
      </Grid>
      <Grid item xs={8}>
        <Box style={{ position: "relative" }}>
          {UMData.is_multi_step ? (
            <>
              <ButtonGroup
                variant="text"
                disabled={!isDirty}
                style={{
                  position: "absolute",
                  right: "0",
                  top: "0",
                  zIndex: "1",
                }}
              >
                <Button onClick={save}>Save</Button>
                <Button onClick={cancel}>Cancel</Button>
              </ButtonGroup>
              <UMQueryBuilder
                umId={UMData.user_mapping.id}
                umItem={conditions}
                onChange={updateUM}
                fields={fields()}
              />
            </>
          ) : (
            <Select
              size="small"
              fullWidth
              value={UMData.user_mapping.mapping?.conditions[0].value_slug}
              onChange={(e) => handleChange(e)}
            >
              {Object.entries(UMData.options).map(([key, value]) => (
                <MenuItem key={key} value={key}>
                  <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                    {value}
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};

export default memo(QuickbooksConditionalMappingsListItem);
