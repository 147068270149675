import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Typography,
  TableBody,
  TableRow,
  Grid,
  Box,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  addStaffingItem,
  deleteStaffingItem,
  editStaffingItem,
} from "../../services/budget-service";
import { theme } from "../../theme";
import { StaffingItemsObject } from "../../Types/BudgetDetailsItem";
import { EmployeeObject } from "../../Types/EmployeeObject";
import AddStaffingValuesForm from "../molecules/AddStaffingValuesForm";
import DeleteIcon from "../../assets/deleteIcon.png";
import PencilIcon from "../../assets/pencil.png";
import { numberFormatter } from "../../utils/dataFormatters";
import LoaderIcon from "../../assets/loader.png";
import { useAppDispatch } from "../../store/hook";
import { showMessage } from "../../store/error-handler-store";
type Props = {
  employeeList: EmployeeObject[];
  budget_uid: string;
  staffing_details: StaffingItemsObject[];
  getLatest: Function;
  is_editable: boolean;
  loading: boolean;
  index: number;
};

const TOTAL_INIT_STATE = {
  regular_pay: 0,
  budgeted_regular_pay: 0,
  fte_r: 0,
  fringe_rate: 0,
  annual_pay: 0,
  benifits: 0,
};

const StaffingTableComplex = (props: Props) => {
  let staffing_details = props.staffing_details;
  let index = props.index;
  let getLatestData = props.getLatest;
  let editable = props.is_editable;
  const dispatch = useAppDispatch();

  if (staffing_details) {
    staffing_details.forEach((ele) => {
      ele.annual_pay =
        Math.min(ele.regular_pay, ele.budgeted_regular_pay) * ele.fte_r;
      ele.benifits =
        Math.min(ele.regular_pay, ele.budgeted_regular_pay) *
        ele.fte_r *
        ele.fringe_rate;
      return ele;
    });
  }

  const budget_uid = props.budget_uid;
  const [staffingTable, setStaffingTable] =
    useState<StaffingItemsObject[]>(staffing_details);
  const [staffingTotal, setStaffingTotal] = useState(TOTAL_INIT_STATE);
  const [showProgress, setShowProgress] = useState<boolean>(props.loading);
  useEffect(() => {
    if (staffingTable && staffingTable.length > 0) {
      setStaffingTable(
        staffingTable.map((ele) => {
          return { ...ele, edit: false };
        })
      );
      staffingTotalUpdateHelper(staffingTable);
    }
  }, [staffing_details]);

  const staffingTotalUpdateHelper = (table: StaffingItemsObject[]) => {
    setStaffingTotal((prev) => {
      return {
        ...prev,
        regular_pay: calculateTotal(table.map((ele) => ele.regular_pay)),
        budgeted_regular_pay: calculateTotal(
          table.map((ele) => ele.budgeted_regular_pay)
        ),
        fte_r: calculateTotal(table.map((ele) => ele.fte_r)),
        fringe_rate: calculateTotal(table.map((ele) => ele.fringe_rate)),
        annual_pay: calculateTotal(table.map((ele) => ele.annual_pay)),
        benifits: calculateTotal(table.map((ele) => ele.benifits)),
      };
    });
  };

  const calculateTotal = (arr: any) => {
    return arr.reduce((total: any, obj: any) => +obj + total, 0);
  };

  const saveStaffingItem = (staffingItem: StaffingItemsObject) => {
    setShowProgress(true);
    let staffingDataForAPI = staffingDataFormatter(staffingItem);
    addStaffingItem(budget_uid, staffingDataForAPI).then((res) => {
      if (res.status === 403) {
        dispatch(showMessage({ message: "You don't have the access to add." }));
        setShowProgress(false);
        return;
      }
      if (res.status === 500) {
        setShowProgress(false);
        return;
      }
      staffingItem["id"] = res.data?.budget_staffing_item_id;
      staffingItem["fringe_rate"] = staffingItem["fringe_rate"] / 100;
      getLatestData(index);
    });
  };

  const staffingDataFormatter = (staffingItem: StaffingItemsObject) => {
    return {
      regular_pay: staffingItem.regular_pay,
      budgeted_regular_pay: staffingItem.budgeted_regular_pay,
      fte_r: staffingItem.fte_r,
      fringe_rate: staffingItem.fringe_rate / 100,
      employee_name: staffingItem.employee.name,
    };
  };

  const onDeleteItem = (id: number) => {
    if (!editable) {
      return;
    }
    let confirmDelete = window.confirm("Are you sure ?");
    setShowProgress(true);
    if (confirmDelete) {
      deleteStaffingItem(budget_uid, id).then((res) => {
        if (res.status === 403) {
          setShowProgress(false);
          dispatch(
            showMessage({ message: "You don't have access to delete." })
          );
          return;
        }
        if (res.status === 404) {
          setShowProgress(false);
          dispatch(showMessage({ message: "Something went wrong." }));
          return;
        }
        if (res.status === 500) {
          setShowProgress(false);
          return;
        }
        getLatestData(index);
      });
    } else {
      setShowProgress(false);
    }
  };

  const onEditRow = (index: number) => {
    if (!editable) {
      return;
    }
    let temp = [...staffingTable];
    temp[index]["edit"] = true;
    temp[index]["fringe_rate"] = temp[index]["fringe_rate"] * 100;
    setStaffingTable(temp);
  };

  const onUpdateData = (staffingEditedItem: StaffingItemsObject) => {
    setShowProgress(true);
    let staffingFormatterForAPI = staffingDataFormatter(staffingEditedItem);
    editStaffingItem(
      budget_uid,
      staffingEditedItem.id,
      staffingFormatterForAPI
    ).then((res) => {
      if (res.status === 403) {
        dispatch(
          showMessage({ message: "You don't have the permission to edit" })
        );
        setShowProgress(false);
        return;
      }
      if (res.status === 500) {
        setShowProgress(false);
        return;
      }
      staffingEditedItem.edit = false;
      staffingEditedItem.fringe_rate = staffingEditedItem.fringe_rate / 100;
      getLatestData(index);
    });
  };

  const onCancelEdit = (id: number) => {
    let temp = [...staffingTable];
    temp.forEach((ele) => {
      if (ele.id === id) {
        ele.edit = false;
      }
    });
    setStaffingTable(temp);
    setShowProgress(false);
  };

  return (
    <React.Fragment>
      <TableContainer
        sx={{
          border: `1px solid ${theme.dataTableColors.borderColor}`,
          borderRadius: "6px",
          position: "relative",
        }}
      >
        <Table>
          <TableHead sx={{ background: theme.dataTableColors.headerBgColor }}>
            <TableCell>
              <Typography variant="inter_p_600_16">Employee Name</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="inter_p_600_16">Regular Pay</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="inter_p_600_16">
                Budgeted Regular Pay
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="inter_p_600_16">FTE-R</Typography>
            </TableCell>
            <TableCell>
              <Typography variant="inter_p_600_16">Fringe Rate</Typography>
            </TableCell>
            <TableCell></TableCell>
            <TableCell
              sx={{ background: theme.dataTableColors.headerDarkBgColor }}
            >
              <Typography variant="inter_p_600_16">Annual Pay</Typography>
            </TableCell>
            <TableCell
              sx={{ background: theme.dataTableColors.headerDarkBgColor }}
            >
              <Typography variant="inter_p_600_16">Benefits</Typography>
            </TableCell>
            <TableCell
              sx={{ background: theme.dataTableColors.headerDarkBgColor }}
            >
              <Typography variant="inter_p_600_16">Total</Typography>
            </TableCell>
          </TableHead>
          <TableBody>
            {editable && (
              <AddStaffingValuesForm
                extraSX={{ backgroundColor: theme.custom.chipGroupBackground }}
                addStaffingItem={saveStaffingItem}
                employeeList={props.employeeList}
                className="budget_employee_add"
              />
            )}
            {staffingTable &&
              staffingTable.length > 0 &&
              staffingTable.map((ele, index) => {
                return (
                  <React.Fragment key={index}>
                    {!ele?.edit && (
                      <TableRow key={"non_edit_mode"}>
                        <TableCell>
                          {" "}
                          <Typography variant="inter_400_14">
                            {ele?.employee?.name}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="inter_400_14">
                            {numberFormatter(ele.regular_pay, "$")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="inter_400_14">
                            {numberFormatter(ele.budgeted_regular_pay, "$")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="inter_400_14">
                            {numberFormatter(ele.fte_r, " ")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="inter_400_14">
                            {" "}
                            {numberFormatter(ele.fringe_rate * 100, "%")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Tooltip
                            title={
                              editable
                                ? "Delete"
                                : "You are not allowed to perform this action"
                            }
                          >
                            <img
                              onClick={() => onDeleteItem(ele.id)}
                              style={{
                                marginRight: 20,
                                cursor: "pointer",
                                opacity: editable ? 1 : 0.5,
                              }}
                              height={24}
                              width={20}
                              alt="delete"
                              src={DeleteIcon}
                            />
                          </Tooltip>
                          <Tooltip
                            title={
                              editable
                                ? "Edit"
                                : "You are not allowed to perform this action"
                            }
                          >
                            <img
                              onClick={() => onEditRow(index)}
                              style={{
                                cursor: "pointer",
                                opacity: editable ? 1 : 0.5,
                              }}
                              height={24}
                              width={20}
                              alt="edit"
                              src={PencilIcon}
                            />
                          </Tooltip>
                        </TableCell>
                        <TableCell>
                          <Typography variant="inter_400_14">
                            {numberFormatter(
                              Math.min(
                                +ele.regular_pay,
                                +ele.budgeted_regular_pay
                              ) * ele.fte_r,
                              "$"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="inter_400_14">
                            {numberFormatter(
                              Math.min(
                                +ele.regular_pay,
                                +ele.budgeted_regular_pay
                              ) *
                                ele.fte_r *
                                ele.fringe_rate,
                              "$"
                            )}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="inter_400_14">
                            {numberFormatter(
                              Math.min(
                                +ele.regular_pay,
                                +ele.budgeted_regular_pay
                              ) *
                                ele.fte_r *
                                ele.fringe_rate +
                                Math.min(
                                  +ele.regular_pay,
                                  +ele.budgeted_regular_pay
                                ) *
                                  ele.fte_r,
                              "$"
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {ele.edit && (
                      <AddStaffingValuesForm
                        extraSX={{
                          backgroundColor: theme.custom.chipGroupBackground,
                        }}
                        editMode={true}
                        values={ele}
                        addStaffingItem={saveStaffingItem}
                        employeeList={props.employeeList}
                        editItemCallback={onUpdateData}
                        cancelEditCallBack={onCancelEdit}
                      />
                    )}
                  </React.Fragment>
                );
              })}
            <TableRow>
              <TableCell>
                <Typography variant="inter_p_600_16">Total</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="inter_p_600_16">
                  {numberFormatter(staffingTotal.regular_pay, "$")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="inter_p_600_16">
                  {numberFormatter(staffingTotal.budgeted_regular_pay, "$")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="inter_p_600_16">
                  {numberFormatter(staffingTotal.fte_r, " ")}
                </Typography>
              </TableCell>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>
                <Typography variant="inter_p_600_16">
                  {numberFormatter(staffingTotal.annual_pay, "$")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="inter_p_600_16">
                  {numberFormatter(staffingTotal.benifits, "$")}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="inter_p_600_16">
                  {numberFormatter(
                    staffingTotal.benifits + staffingTotal.annual_pay,
                    "$"
                  )}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        <Box sx={{ display: showProgress ? "block" : "none" }}>
          <Grid
            className="show-loader"
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item>
              <img src={LoaderIcon} height="100" width="100" alt="Loader" />
            </Grid>
          </Grid>
        </Box>
      </TableContainer>
    </React.Fragment>
  );
};

export default React.memo(StaffingTableComplex);
