import { Chip, Theme, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CachedIcon from "@mui/icons-material/Cached";
import { theme } from "../../theme";
import { SxProps } from "@mui/system";

interface Props {
  width?: string;
  borderRadius?: string;
  height?: string;
  extraSX?: any;
  extraProps?: any;
  labelProps?: any;
  closeIconProps?: any;
  action?: string;
}

const CustomChip = (props: Props) => {
  return (
    <Chip
      size="medium"
      deleteIcon={
        props.action !== "reset" ? (
          <CloseIcon
            sx={{
              fill: theme.CustomChip.closeIconColor,
              ...(props?.closeIconProps || {}),
            }}
          />
        ) : (
          <CachedIcon
            sx={{
              fill: theme.CustomChip.closeIconColor,
              ...(props?.closeIconProps || {}),
            }}
          />
        )
      }
      sx={{
        color: theme.CustomChip.fontColor,
        backgroundColor: theme.CustomChip.backgroundColor,
        ...(props?.extraSX || {}),
      }}
      {...props?.extraProps}
      label={
        <Typography
          variant="inter_p_500_14"
          sx={{ color: props.extraSX?.color }}
          {...props?.labelProps}
        >
          {props?.extraProps?.labelText}
        </Typography>
      }
    />
  );
};

export default CustomChip;
