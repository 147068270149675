import {
  Box,
  Card,
  CardContent,
  Grid,
  Stack, Tooltip,
  Typography,
} from "@mui/material";
import React, { memo, useRef, useState } from "react";
import { theme } from "../../../theme";
import {Swiper, SwiperSlide} from "swiper/react/swiper-react";
import {Navigation, Pagination, Scrollbar} from "swiper";
import {Circle} from "@mui/icons-material";
import TargetIcon from "../../../assets/target.png";
import {useNavigate} from "react-router";
import {dateFormatter} from "../../../utils/dataFormatters";

type Props = {
  budgetAlerts: any
  selectedBudget: any
  setSelectedBudget: Function
};

const BudgetAlertsCard = (props: Props) => {
  let budgetAlerts = props.budgetAlerts;
  let selectedBudget = props.selectedBudget;
  let setSelectedBudget = props.setSelectedBudget;
  let budgetTypePriority = ["Revenue", "Staffing Costs", "Other Operating Expense"];
  const navigate = useNavigate();

  const navigateToBudgetTracker = (budget: any) => {
    let filterData: any = {
      program_single: {
        action: "delete",
        label: budget.program_name,
        value: budget.program_id,
      },
      contract: {
        action: "delete",
        label: budget.program_name+':'+budget.contract_name,
        program: budget.program_id,
        value: budget.contract_id,
      },
    };

    navigate(
      `/budgets-monitoring?encoded_filter_data=${btoa(
        JSON.stringify(filterData)
      )}#contracts`
    );
  };

  const orderBudgetHeads= (budgetHeads: any) => {
    // Create a dictionary to store the order of elements in sortList
    const orderDict: any = {};
    budgetTypePriority.forEach((value, index) => {
      orderDict[value] = index;
    });

    // Define a function to get the sort key for each object in objectsArray
    function sortKey(obj: any) {
      // If the object's key value is in the orderDict, return its index, else return a large number
      return orderDict.hasOwnProperty(obj["label"]) ? orderDict[obj["label"]] : Infinity;
    }

    // Sort objectsArray using the sortKey function
    return budgetHeads.slice().sort((a: any, b: any) => sortKey(a) - sortKey(b));
  }

  return (
    <Grid
      container
      p={3}
      className={"carousel-container"}
      sx={{ display: budgetAlerts.length ? "block" : "none" }}
    >
      <Swiper
        slidesPerView={"auto"}
        spaceBetween={20}
        navigation
        updateOnWindowResize
        modules={[Navigation, Pagination, Scrollbar]}
      >
        {budgetAlerts.map((budget: any, index: number) => {
          return (
            <SwiperSlide style={{ width: "auto", cursor: "pointer" }}>
              <Card
                sx={{
                  overflowY: "hidden",
                  height: 150,
                  maxWidth: 400,
                  border: selectedBudget.budget_uid === budget.budget_uid
                    ? `2px solid ${theme.custom.primaryDarkColor}`
                    : `1px solid ${theme.custom.filterBorderColor}`,
                  borderRadius: "6px",
                  boxShadow: "none",
                }}
                onClick={(event) => {
                  setSelectedBudget(budget)
                }}
                key={budget.budget_uid}
                elevation={selectedBudget.budget_uid === budget.budget_uid ? 20 : 4}
              >
                <CardContent sx={{ padding: 0 }}>
                  <Grid sx={{ p:2, pb:0 }} container>
                    <Grid item sm={11}>
                      <Typography variant="inter_p_600_16">
                        {budget.name || ""}
                      </Typography>
                    </Grid>
                    <Grid item sm={1}>
                      <Tooltip title={"Track budget"} placement="top">
                        <img
                          height="30px"
                          width="30px"
                          style={{
                            maxHeight: "20px",
                            marginLeft: "5px",
                            cursor: "pointer",
                            width: "auto",
                          }}
                          onClick={() => {
                            navigateToBudgetTracker(budget);
                          }}
                          src={TargetIcon}
                          alt="Target"
                        />
                      </Tooltip>
                    </Grid>
                    <Grid pb={1} item sm={12}>
                      <Typography
                        sx={{ color: theme.custom.primaryGreyColor }}
                        variant="inter_500_12"
                      >
                        {dateFormatter(budget?.start_date)} - {dateFormatter(budget?.end_date)}
                      </Typography>
                    </Grid>
                    <Stack direction={"row"} pt={1} useFlexGap flexWrap="wrap">
                      {
                        orderBudgetHeads(budget?.json_agg).map((type: any) => {
                          return (
                            <Typography style={{display: "flex", justifyContent: "center", alignItems: "center"}} pr={1} variant={"caption"}>
                              <Circle style={{fontSize: "0.75rem"}} color={type?.in_alert ? "error" : "success"} />&nbsp;{type.label}
                            </Typography>
                          )
                        })
                      }
                    </Stack>
                    <Grid item sm={12} sx={{position: "absolute", top: "125px"}}>
                      <Typography
                        sx={{ color: theme.custom.primaryGreyColor }}
                        variant="inter_500_12"
                        style={{display: "flex", justifyContent: "left", alignItems: "flex-start"}}
                      >
                        Actuals as on {dateFormatter(budget?.as_of_date)}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </Grid>
  )
};

export default memo(BudgetAlertsCard);
