import {
  Grid,
  List,
  ListItem,
  ListItemButton,
  Typography,
} from "@mui/material";
import { useState } from "react";
import BackIconCircular from "../../../assets/back_icon_circular.png";
import BackIconCircularDark from "../../../assets/back_icon_circular_dark.png";
import HamburgerRound from "../../../assets/hamburger_round.png";

type Props = {
  pages: any[];
  setCurrentPageIndex: Function;
  currentPageIndex: number;
  isThemeDark: boolean;
};

const PresentationLeftPanel = ({
  setCurrentPageIndex,
  currentPageIndex,
  pages,
  ...props
}: Props) => {
  const [minimized, setMinimized] = useState(false);

  const renderMinimized = () => {
    return (
      <Grid container ml={6.5} mt={6.5}>
        <img
          src={HamburgerRound}
          height={40}
          width={40}
          style={{ cursor: "pointer" }}
          onClick={() => setMinimized(false)}
        />
      </Grid>
    );
  };

  const renderMaximized = () => {
    return (
      <Grid
        container
        ml={3}
        mt={3}
        width={260}
        p={1.5}
        sx={{
          backgroundColor: props.isThemeDark ? "#4D4D4D" : "#E2E2E2",
          color: props.isThemeDark ? "#fff" : "inherit",
          borderRadius: "6px",
        }}
      >
        <Grid xs={12} pl={2} pt={2}>
          <img
            src={props.isThemeDark ? BackIconCircularDark : BackIconCircular}
            height={40}
            width={40}
            style={{ cursor: "pointer" }}
            onClick={() => setMinimized(true)}
          />
        </Grid>
        <Grid xs={12}>
          <List
            sx={{
              maxHeight: "70vh",
              overflow: "auto",
              "& .MuiListItemButton-root.Mui-selected": {
                backgroundColor: props.isThemeDark ? "#6D6D6D" : "#CFCFCF",
              },
            }}
          >
            {pages.map((page) => (
              <ListItem key={page.pageIndex} disableGutters>
                <ListItemButton
                  sx={{ mr: 1 }}
                  selected={page.pageIndex === currentPageIndex}
                  onClick={() => setCurrentPageIndex(page.pageIndex)}
                >
                  <Typography variant="inter_400_14">
                    {page.pageIndex + 1} &nbsp;{" "}
                    {page.pageTitle || `Page ${page.pageIndex + 1}`}
                  </Typography>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>
    );
  };
  return <>{minimized ? renderMinimized() : renderMaximized()}</>;
};

export default PresentationLeftPanel;
