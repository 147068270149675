import {
  Box,
  Grid,
  Typography,
  Divider,
  Stack,
  TextField,
  Button,
  Autocomplete,
  styled,
  Tooltip,
  Badge,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import CustomSwitch from "../../components/atoms/CustomSwitch";
import { theme, fontInter, chartColors } from "../../theme";
import { useAppDispatch } from "../../store/hook";
import { showMessage } from "../../store/error-handler-store";
import {
  getAllContractsForProgram,
  updateBudget,
} from "../../services/budget-service";
import DateRangePickerCustomComponent from "../atoms/DateRangePickerCustomComponent";
import { ProgramFilterItem } from "../../Types/ProgramFilterItem";
import { numberFormatter } from "../../utils/dataFormatters";
import pencil from "../../assets/pencil.png";
import moment from "moment";
import DocumentsIcon from "../../assets/fi-rr-document.png";
import { ContractObject } from "../../Types/ContractObject";
import BudgetAnalysisModal from "../molecules/BudgetManagement/BudgetAnalysisModal";
import MagicStick from "../../../src/assets/magic_wand_multi_color.png";

interface Props {
  filters: any;
  program: ProgramFilterItem[];
  budgetTotal: any;
  is_editable: boolean;
  contract: ContractObject[];
  toggleDocumentsPane: Function;
  budgetId: string;
}

const INIT_STATE = {
  active: false,
  budget_uid: "",
  expense_total: 0,
  name: "",
  revenue_total: 0,
  staffing_total: 0,
  daterange: {
    start: "",
    end: "",
  },
  program: {
    name: "",
    id: "",
  },
  contract: {
    name: "",
    id: "",
  },
};

const EDIT_STATE = {
  is_editable: false,
  active: false,
  name: "",
  daterange: {
    start: "",
    end: "",
  },
  program: {
    name: "",
    id: "",
  },
  contract: {
    name: "",
    id: "",
  },
};

const AddBudgetItemFilter: React.FC<Props> = (data) => {
  let dispatch = useAppDispatch();
  let state = data.filters;
  let editable = data.is_editable;
  let budgetTotal: any = data.budgetTotal;
  const [editMode, setEditMode] = useState<boolean>(false);

  const AutoCompleteComponent = styled(Autocomplete)({
    ".MuiAutocomplete-inputRoot": {
      color: theme.custom.dropdownPrimaryColor,
      fontWeight: 500,
      borderRadius: 8,
      fontSize: "14px",
      fontFamily: fontInter,
      cursor: !editMode ? "not-allowed" : "pointer",
    },
  });

  const [budgetState, setBudgetState] = useState<any>(INIT_STATE);
  const [editState, setEditState] = useState<any>(EDIT_STATE);
  const [errMsg, setErrMsg] = useState<string>("");
  const [contracts, setContracts] = useState([] as ContractObject[]);
  const [openBudgetAnalysis, setOpenBudgetAnalysis] = useState(false);

  useEffect(() => {
    setContracts(data.contract);
  }, [data.contract]);
  useEffect(() => {
    setBudgetState((prev: any) => {
      return {
        ...prev,
        active: state.active,
        budget_uid: state.budget_uid,
        expense_total: 0,
        name: state.name,
        revenue_total: 0,
        staffing_total: 0,
        daterange: {
          start: state.start_date,
          end: state.end_date,
        },
        program: {
          name: state.ref_program_name,
          id: state.ref_program_id,
        },
        contract: {
          id: state.ref_contract_id,
          name: state.ref_contract_title,
        },
      };
    });

    setEditState((prev: any) => {
      return {
        ...prev,
        name: state.name,
        active: state.active,
        daterange: {
          start: moment.utc(state.start_date),
          end: moment.utc(state.end_date),
        },
        program: {
          name: state.ref_program_name,
          id: state.ref_program_id,
        },
        contract: {
          id: state.ref_contract_id,
          name: state.ref_contract_title,
        },
      };
    });
  }, [state]);

  const renderOption = (props: any, option: any) => {
    return (
      <Grid item component="li" {...props}>
        <Stack direction="column">
          <Typography py={1} variant="inter_p_500_14">
            {option?.name}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  const handleChangeAutoComplete = (value: any, key: string) => {
    if (key === "program") {
      setEditState((prev: any) => {
        return { ...prev, contract: { name: "", id: "" } };
      });
      if (value) {
        getAllContractsForProgram(value["id"]).then((res) => {
          if (res.status === 200) {
            setContracts(res.data?.data?.contract || []);
          }
        });
      }
    }
    setEditState((prev: any) => {
      return { ...prev, [key]: value };
    });
  };

  const onCancel = () => {
    setEditState({
      active: budgetState.active,
      name: budgetState.name,
      daterange: budgetState.daterange,
      program: budgetState.program,
      contract: budgetState.contract,
    });
    setErrMsg("");
    setEditMode(false);
  };

  const onSave = () => {
    if (editState.name === "") {
      setErrMsg("Budget Name is required");
    } else if (
      editState.daterange.start === "" ||
      editState.daterange.end === ""
    ) {
      setErrMsg("Date Range is mandatory");
    } else if (
      !editState.program ||
      editState.program?.id === "" ||
      editState.program?.name === ""
    ) {
      setErrMsg("Program is mandatory");
    } else if (
      !editState.contract ||
      editState.contract?.id === "" ||
      editState.contract?.name === ""
    ) {
      setErrMsg("Contract is mandatory");
    } else {
      setErrMsg("");
      formSubmitHelper();
    }
  };
  const closeBudgetAnalysis = () => {
    setOpenBudgetAnalysis(false);
  };

  const generateAnalysis = (e: any) => {
    setOpenBudgetAnalysis(true);
  };

  const formSubmitHelper = () => {
    let finalObj: Object = {
      active: editState.active,
      name: editState?.name,
      ref_program_id: editState?.program?.id,
      ref_program_name: editState?.program?.name,
      start_date: moment(editState?.daterange?.start).format("YYYY-MM-DD"),
      end_date: moment(editState?.daterange?.end).format("YYYY-MM-DD"),
      ref_contract_id: editState?.contract?.id,
      ref_contract_title: editState?.contract?.name,
    };

    updateBudget(finalObj, budgetState.budget_uid).then((res: any) => {
      if (res.status === 400) {
        setErrMsg(res?.data?.message);
        setEditState((prev: any) => {
          return {
            ...prev,
            active: !editState.active,
          };
        });
        return;
      }
      if (res.status === 403) {
        setErrMsg(
          "You don't have permission for editing the budget. Please contact admin for access"
        );
        setEditState((prev: any) => {
          return {
            ...prev,
            active: false,
          };
        });
        setEditMode(false);
        return;
      }
      setBudgetState((prev: any) => {
        return {
          ...prev,
          active: editState?.active,
          name: editState?.name,
          program: {
            id: editState?.program?.id,
            name: editState?.program?.name,
          },
          daterange: {
            start: editState.daterange?.start,
            end: editState?.daterange?.end,
          },
          contract: {
            id: editState?.contract?.id,
            name: editState?.contract?.name,
          },
        };
      });
      setEditMode(false);
      dispatch(
        showMessage({
          message: "Budget Saved Successfully",
          variant: "success",
        })
      );
    });
  };

  const onBudgetNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let regex = /^[\w \_\-\(\)\s]+$/gi;
    if (regex.test(event.target.value)) {
      setErrMsg("");
    } else {
      if (event.target.value.length > 0) {
        setErrMsg(
          "Only allowed character alphanumeric, space, -, _, (, ) for Budget Name"
        );
      }
    }
    setEditState((prev: any) => {
      return { ...prev, name: event.target.value };
    });
  };

  const onChange = useCallback((dateRange: any) => {
    setEditState((prev: any) => {
      return { ...prev, daterange: dateRange };
    });
  }, []);

  const toggleEditMode = () => {
    if (editable) {
      setEditMode(true);
    }
  };

  const onToggleSwitch = (isActive: boolean) => {
    setEditState((prev: any) => {
      return {
        ...prev,
        active: isActive,
      };
    });
  };

  return (
    <React.Fragment>
      <Box>
        <Grid container>
          <Grid item xs={12}>
            <Box
              m={2}
              py={3}
              sx={{
                background: theme.custom.filterBackgroundColor,
                height: "auto",
                border: 1,
                borderColor: theme.custom.filterBorderColor,
                borderRadius: "6px",
              }}
            >
              <Box pt={1} pb={2} px={2}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item sm={8.5}>
                    <Grid container spacing={2}>
                      <Grid item sm={3}>
                        <TextField
                          disabled={!editMode}
                          onChange={onBudgetNameChange}
                          value={editState.name}
                          fullWidth
                          inputProps={{
                            maxLength: 30,
                            style: {
                              fontFamily: fontInter,
                              fontSize: "14px",
                              color: theme.custom.primaryDarkColor,
                              borderRadius: "10px",
                              cursor: !editMode ? "not-allowed" : "pointer",
                            },
                          }}
                          id="budget-name"
                          label={
                            <Typography variant="inter_p_500_14">
                              Budget Name
                            </Typography>
                          }
                          variant="outlined"
                        />
                      </Grid>
                      <Grid sx={{ pb: 1 }} item sm={3}>
                        <AutoCompleteComponent
                          id={"program_filter"}
                          disabled={!editMode}
                          options={data.program || []}
                          value={editState.program}
                          renderOption={(props: any, option: any) =>
                            renderOption(props, option)
                          }
                          getOptionLabel={(option: any) => {
                            return option ? option.name : "";
                          }}
                          onChange={(e: any, v: any, r: any, opt: any) => {
                            handleChangeAutoComplete(v, "program");
                          }}
                          filterSelectedOptions={true}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                style: {
                                  marginLeft: 7,
                                },
                              }}
                              label={
                                <Typography variant="inter_p_500_10">
                                  Program
                                </Typography>
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid sx={{ pb: 1 }} item sm={3}>
                        <AutoCompleteComponent
                          id={"contract_filter"}
                          disabled={!editMode}
                          options={contracts || []}
                          value={editState.contract}
                          renderOption={(props: any, option: any) =>
                            renderOption(props, option)
                          }
                          getOptionLabel={(option: any) => {
                            return option ? option.name : "";
                          }}
                          onChange={(e: any, v: any, r: any, opt: any) => {
                            handleChangeAutoComplete(v, "contract");
                          }}
                          filterSelectedOptions={true}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                style: {
                                  marginLeft: 7,
                                },
                              }}
                              label={
                                <Typography variant="inter_p_500_10">
                                  Contract
                                </Typography>
                              }
                            />
                          )}
                        />
                      </Grid>
                      <Grid item sm={3}>
                        <Box
                          sx={{
                            border: `1px solid ${theme.custom.filterBorderColor}`,
                            borderRadius: "6px",
                            background: editMode ? "#fff" : "#F2F2F2",
                          }}
                        >
                          <DateRangePickerCustomComponent
                            onChange={onChange}
                            dateRangeValue={editState.daterange}
                            menuOptions={{}}
                            disabled={!editMode}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item alignItems={"center"}>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Grid item>
                        <Stack direction={"row"} alignItems={"center"}>
                          <Grid item>
                            <CustomSwitch
                              extraSX={{
                                mx: 1,
                                cursor: !editMode ? "not-allowed" : "pointer",
                              }}
                              backgroundColor={theme.palette.primary.main}
                              trackColor={theme.customSwitch.inactiveColor}
                              active={editState.active}
                              onToggle={onToggleSwitch}
                              disabled={!editMode}
                            />
                          </Grid>

                          <Grid item>
                            <Typography variant="inter_p_600_16">
                              {editState.active ? "Active" : "Inactive"}
                            </Typography>
                          </Grid>
                          <Grid item ml={2}>
                            <Tooltip
                              title={"Analyze budget via Pebble AI"}
                              placement="top"
                            >
                              <img
                                height="30px"
                                width="30px"
                                style={{
                                  maxHeight: "20px",
                                  cursor: "pointer",
                                  width: "auto",
                                }}
                                onClick={(e) => {
                                  generateAnalysis(e);
                                }}
                                src={MagicStick}
                                alt="magic_stick"
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item sx={{ ml: 3, mt: 1 }}>
                            <Badge
                              badgeContent={state.budget_documents_count}
                              color="primary"
                              anchorOrigin={{
                                vertical: "top",
                                horizontal: "left",
                              }}
                            >
                              <img
                                src={DocumentsIcon}
                                alt="Documents"
                                height={"35px"}
                                width={"20px"}
                                onClick={() => data.toggleDocumentsPane()}
                                style={{
                                  cursor: "pointer",
                                  paddingBottom: "15px",
                                }}
                              />
                            </Badge>
                          </Grid>
                        </Stack>
                      </Grid>
                      <Grid item sx={{ ml: 3 }}>
                        {editMode && (
                          <Grid textAlign={"center"} container>
                            <Grid textAlign={"right"} item sm={6}>
                              <Button onClick={onCancel} variant="text">
                                <Typography
                                  variant="poppins_p_600_14"
                                  sx={{
                                    color: theme.custom.primaryGreyColor,
                                  }}
                                >
                                  Cancel
                                </Typography>
                              </Button>
                            </Grid>
                            <Grid item sm={6}>
                              <Button onClick={onSave} variant="outlined">
                                <Typography
                                  variant="poppins_p_600_14"
                                  sx={{
                                    color: theme.custom.primaryGreyColor,
                                  }}
                                >
                                  Save
                                </Typography>
                              </Button>
                            </Grid>
                          </Grid>
                        )}
                        {!editMode && (
                          <Grid textAlign={"right"} item>
                            <Tooltip
                              title={
                                editable
                                  ? "Edit"
                                  : "You don't have access for this action"
                              }
                            >
                              <img
                                src={pencil}
                                alt="Edit"
                                height={"35px"}
                                width={"20px"}
                                onClick={toggleEditMode}
                                style={{
                                  marginTop: "15px",
                                  cursor: "pointer",
                                  paddingBottom: "15px",
                                  opacity: editable ? 1 : 0.5,
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        )}
                      </Grid>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
              {errMsg && errMsg.length > 0 && (
                <Box pb={2} px={2}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography
                        variant="inter_p_400_16"
                        sx={{ color: theme.custom.errorColor }}
                      >
                        {errMsg}
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
              <Divider />
              <Box mt={3} px={2}>
                <Grid container justifyContent={"left"}>
                  {budgetTotal &&
                    budgetTotal.length &&
                    budgetTotal.map((ele: any, index: number) => {
                      return (
                        <Grid item mb={2} mr={3}>
                          <Typography variant="inter_p_400_16">
                            {ele.label}
                            <span
                              style={{
                                borderRadius: "6px",
                                padding: "5px",
                                marginLeft: "10px",
                                background: chartColors[index] + "60",
                                fontWeight: 600,
                                fontFamily: fontInter,
                              }}
                            >
                              {numberFormatter(ele.total, "$")}
                            </span>
                          </Typography>
                        </Grid>
                      );
                    })}
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {openBudgetAnalysis && (
        <BudgetAnalysisModal
          open={openBudgetAnalysis}
          close={closeBudgetAnalysis}
          budgetId={data.budgetId}
        />
      )}
    </React.Fragment>
  );
};
export default React.memo(AddBudgetItemFilter);
