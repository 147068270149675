import { Button, Typography } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback, useLayoutEffect } from "react";
function InteractiveElementEditor(props: any) {
  const { element } = props;

  const onClick = () => {
    if (props.isPublic) {
      window.open(element?.linkurl, "_blank");
    }
  };
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [fontSize, setFontSize] = useState<string>('16px'); // Default font size

  useLayoutEffect(() => {
    const calculateFontSize = () => {
      if (buttonRef.current) {
        const buttonWidth = buttonRef.current.clientWidth;
        const buttonHeight = buttonRef.current.clientHeight;
        const text = element?.attributes?.text || '';

        // Create a temporary div to measure text dimensions
        const div = document.createElement('div');
        div.className = 'interactive-element-button-temp';
        div.style.visibility = 'hidden';
        div.style.position = 'absolute';
        div.style.width = `${buttonWidth}px`;
        div.style.whiteSpace = 'normal';
        document.body.appendChild(div);

        // Binary search to find the largest font size that fits
        let low = 1;
        let high = Math.min(buttonWidth, buttonHeight);
        let bestFit = low;

        while (low <= high) {
          const mid = Math.floor((low + high) / 2);
          div.style.fontSize = `${mid}px`;
          div.textContent = text;

          if (div.offsetHeight <= buttonHeight) {
            bestFit = mid;
            low = mid + 1;
          } else {
            high = mid - 1;
          }
        }

        // Remove the specific temporary div
        const tempDiv = document.querySelector('.interactive-element-button-temp');
        if (tempDiv) {
          document.body.removeChild(tempDiv);
        }
        setFontSize(`${bestFit / 1.5}px`);
      }
    };

    calculateFontSize();

    // Create ResizeObserver
    const resizeObserver = new ResizeObserver(calculateFontSize);

    // Observe the button
    if (buttonRef.current) {
      resizeObserver.observe(buttonRef.current);
    }

    // Cleanup
    return () => {
      if (buttonRef.current) {
        resizeObserver.unobserve(buttonRef.current);
      }
      resizeObserver.disconnect();
    };
  }, [element?.attributes?.text]);

  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          ref={buttonRef}
          sx={{
            backgroundColor: element?.attributes?.fill,
            color: element?.attributes?.strokeColor,
            width: "100%",
            height: "100%",
            fontSize: fontSize,
            "&:hover": {
              backgroundColor: element?.attributes?.fill,
            },
          }}
          onClick={() => onClick()}
        >
          {element?.attributes?.text}
        </Button>
      </div>
    </>
  );
}

export default React.memo(InteractiveElementEditor);
