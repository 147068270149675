import {
  capitalize,
  every,
  each,
  filter,
  isEmpty,
  map,
  set,
  omit,
  get,
} from "lodash";
import { ConfigsTypes } from "../Types/KPIBuilderChartType";

export const filterOperatorsForSelectedDataType = (
  configsArr: ConfigsTypes[],
  data_type: string
) => {
  let filteredConfig = filter(
    configsArr,
    (filter: any) => filter["datatype_slug"] === data_type
  );
  return filteredConfig;
};

export const operatorOptionsTransformer = (list: Array<string>) => {
  return list.map((ele: any) => {
    if (ele.includes("_")) {
      return {
        value: ele,
        label: ele.split("_").map(capitalize).join(" "),
      };
    } else {
      return {
        value: ele,
        label: ele.split(" ").map(capitalize).join(" "),
      };
    }
  });
};

export const isNestedEmpty = (_obj: any, keysToBeOmitted: string[] = []) => {
  if (keysToBeOmitted.length) {
    return every(_obj, isEmpty(omit(_obj, keysToBeOmitted)));
  }
  return every(_obj, isEmpty);
};

export const previewFormatter = (data: any, isPreview: boolean = true) => {
  let finalObj: any = {
    chart_info: {
      chart_type: data?.chart_type?.chart_type || "",
      id: data?.chart_type?.series_type_info?.position || 0,
      unit: data?.chart_type?.unit || "",
    },
    kpi_title: data?.chart_type?.kpi_title || "",
  };

  if (data?.chart_type?.aggregation_type.length) {
    set(
      finalObj,
      ["chart_info", "aggregation"],
      data?.chart_type?.aggregation_type
    );
  }

  let formattedSeries: any = {};
  let series = { ...data?.data_source?.series } || {};
  let filterColumns = [...(data?.filter_columns || [])];

  if (series && Object.keys(series).length) {
    each(series, (value, key) => {
      formattedSeries[value.slug] = map(value.data, (v) => {
        let baseObj = {
          connection_id: get(v, "data_source.workbookId", ""),
          table: get(v, "data_source.sheetId", ""),
          column_name: get(v, "data_source.columnId", ""),
          operator: v.operator === "none" ? "" : get(v, "operator", ""),
          alias: get(v, "axis_name", ""),
          data_type: get(v, "data_type_selected", { name: "", value: "" }),
        };

        if (!isNestedEmpty(v?.["base_column"])) {
          set(baseObj, "x_joining_column", v?.["base_column"]);
        }
        if (!isNestedEmpty(v?.["joining_column"])) {
          set(baseObj, "y_joining_column", v?.["joining_column"]);
        }

        return baseObj;
      });
    });
  }

  each(formattedSeries, (v, k) => {
    if (v.length === 0) {
      delete formattedSeries[k];
    }
  });

  if (!isNestedEmpty(data?.data_source?.order_by)) {
    finalObj["order_by"] = {
      axis: data?.data_source?.order_by || {},
      order_by_type: data?.data_source?.order_by["order_by_type"] || "asc",
    };
  } else {
    finalObj["order_by"] = { axis: "", order_by_type: "asc" };
  }
  finalObj["chart_data"] = formattedSeries;

  if (filterColumns.length && !isPreview) {
    finalObj["filter_columns"] = filterColumns.map(
      (filter: any, index: number) => {
        return {
          connection_id: filter["workbookId"] || "",
          table: filter["sheetId"] || "",
          column_name: filter["columnId"] || "",
          filter_type: filter["filterType"] || "",
          position: index + 1,
        };
      }
    );
  }
  return finalObj;
};
