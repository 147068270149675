import {
  Box,
  Divider,
  Grid,
  Modal,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../theme";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import MagicWandAnimation from "../../../../src/assets/magic_wand_animation.gif";
import MagicWand from "../../../../src/assets/magic_wand_multi_color.png";
import CircleIcon from "@mui/icons-material/Circle";
import BorderLinearProgress from "../../atoms/BorderLinearProgress";
import MarkdownRenderer from "../../atoms/MarkdownRenderer";
import { dateFormatter } from "../../../utils/dataFormatters";
import {
  getBudgetAnalysis
} from "../../../services/budget-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import AIBox from "../../atoms/AIBox";

interface Props {
  open: boolean;
  budgetId: string;
  close: Function;
}
const BudgetAnalysisModal = (props: Props) => {
  const [open, setOpen] = useState(props.open);
  const [analysisData, setAnalysisData] = useState(null as any);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const handleClose = () => {
    setOpen(false);
    props.close();
  };

  useEffect(() => {
    setOpen(open);
    _getBudgetAnalysis();
  }, [open]);

  function _getBudgetAnalysis() {
    setIsLoading(true);
    getBudgetAnalysis(props.budgetId)
      .then(
        (res) => {
          setAnalysisData(res.data);
        },
        () => {
          dispatch(
            showMessage({
              message: "Something went wrong. Please try again later.",
            })
          );
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "65%",
    bgcolor: "background.paper",
    boxShadow: 24,
    outline: 0,
    p: 4,
  };

  return (
    <Modal
      open={true}
      style={{ zIndex: "10500" }}
      onClose={handleClose}
      aria-labelledby="budget-analysis"
      aria-describedby="budget-analysis"
    >
      <Box sx={style}>
        <Grid container mb={2}>
          <Grid textAlign={"left"} item xs={11}>
            <Stack direction="row" alignItems="center" gap={1}>
              <img
                height="30px"
                width="30px"
                style={{
                  padding: "5px",
                  marginRight: "10px",
                }}
                src={MagicWand}
                alt="magic_stick"
              />
              <Typography
                sx={{ color: `${theme.custom.primaryDarkColor}` }}
                variant="inter_p_400_16"
              >
                AI Analysis of budget
              </Typography>
            </Stack>
          </Grid>
          <Grid textAlign="right" item xs={1}>
            <CancelOutlinedIcon
              onClick={handleClose}
              sx={{
                color: `${theme.custom.primaryGreyColor}`,
                cursor: "pointer",
              }}
            />
          </Grid>
        </Grid>
        <Divider />
        {analysisData && (
          <>
            <Box>
              <Typography
                sx={{ color: `${theme.custom.primaryDarkColor}` }}
                variant="poppins_h3_600_24"
                mt={2}
              >
                {analysisData?.title}
              </Typography>
            </Box>
            <Grid container mt={2}>
              <Grid item xs={5} alignContent={"left"}>
                <Typography variant="inter_p_400_16">
                  {dateFormatter(analysisData?.start_date)} -
                  {dateFormatter(analysisData?.end_date)}
                  <CircleIcon
                    sx={{
                      color: `${theme.custom.primaryGreyColor}`,
                      height: "10px",
                    }}
                  />{" "}
                  {analysisData?.total_period} months
                </Typography>
              </Grid>
              <Grid item xs={2} ml={4} mr={2}>
                <BorderLinearProgress
                  variant="primary"
                  value={
                    (analysisData?.elapsed_period /
                      analysisData?.total_period) *
                    100
                  }
                />
              </Grid>
              <Grid item xs={3} ml={1}>
                <Typography variant="inter_p_400_16">
                  {analysisData?.elapsed_period} months elapsed{" "}
                </Typography>
              </Grid>
            </Grid>
            <Box mt={1}>
              <Typography
                variant="inter_p_500_10"
                color={theme.custom.grayBgColor}
              >
                Below analysis is done based on actuals calculated as of date{" "}
                {dateFormatter(analysisData.as_of_date)}
              </Typography>
            </Box>
          </>
        )}
        <AIBox>
          <Box
            style={{
              overflowY: "auto",
              borderRadius: "6px",
              padding: "30px",
              height: "500px",
            }}
          >
            {isLoading ? (
              <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                height={"100%"}
                width={"100%"}
              >
                <img
                  height="30px"
                  width="30px"
                  src={MagicWandAnimation}
                  alt="magic_stick"
                />
                <Typography
                  variant="inter_p_400_16"
                  color={theme.custom.primaryGreyColor}
                  mt={2}
                >
                  Analyzing the budget..
                </Typography>
              </Stack>
            ) : (
              <MarkdownRenderer markdown={decodeURI(analysisData?.analysis)} />
            )}
          </Box>
        </AIBox>
        <Box textAlign={"center"} mt={1}>
          <Typography variant="inter_p_500_10" color={theme.custom.grayBgColor}>
            Pebble AI can make mistakes. Consider checking important
            information.
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};
export default React.memo(BudgetAnalysisModal);
