import LeftIcon from "@mui/icons-material/ArrowCircleLeft";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Skeleton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import { environment } from "../../environments/environment-dev";
import { getConnectorSyncStatus } from "../../services/connector-service";
import {
  deleteUserMappingsContracts,
  getUserMappingsContractsFromAPI,
  getUserMappingsFromAPI,
  saveContractMappings,
  saveUserMappings,
} from "../../services/user-mapping-service";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import {
  setConfigs,
  setContractConfigs,
  setHasAtLeastOneMapping,
  setModifiedUMMetaId,
} from "../../store/user-mapping-store";
import { theme } from "../../theme";
import { generateStatusDisplay } from "../../utils/ConnectorUIUtils";
import PebbleBgImage from "../atoms/PebbleBgImage";
import QuickbooksConditionalMappings from "./QuickbooksConditionalMappings";
import Popover from "@mui/material/Popover";
import range from "lodash/range";
import InfoIcon from "../../assets/info.png";
import logo from "../../assets/Logo.png";
import { showMessage } from "../../store/error-handler-store";
import QuickbooksRestrictionMappings from "./QuickbooksRestrictionMappings";
import QuickbooksSystemDefinedMappings from "./QuickbooksSystemDefinedMappings";
import { Sidebar } from "./Sidebar";
import QuickbooksContractsMappings from "./QuickbooksContractsMappings";
import DrillDownModal from "../molecules/DrillDownModal";
import LinkOffIcon from "@mui/icons-material/LinkOff";

const QuickbooksUserMapping = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [completed, setCompleted] = useState(false);
  const dispatch = useAppDispatch();
  const [syncStatus, setSyncStatus] = useState("");
  const [authorized, setAutorized] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);

  const [openDrilldownModal, setOpenDrilldownModal] = useState(false);

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const enrichedUMDatawithMeta = useAppSelector((state) => {
    return state.userMapping.enrichedUMDatawithMeta;
  });
  const unsavedUMDatawithMeta = useAppSelector((state) => {
    return state.userMapping.unsavedUMDatawithMeta;
  });
  const actionType = useAppSelector((state) => {
    return state.userMapping.actionType;
  });

  const hasAtLeastOneMapping = useAppSelector((state) => {
    return state.userMapping.hasAtLeastOneMapping;
  });
  const modifiedUMMetaId = useAppSelector((state) => {
    return state.userMapping.modifiedUMMetaId;
  });
  const modifiedContractId = useAppSelector((state) => {
    return state.userMapping.modifiedContractId;
  });
  const invalidMappingCount = useAppSelector((state) => {
    return state.userMapping.invalidMappingCount;
  });

  const isSaving = useAppSelector((state) => {
    return state.userMapping.saving;
  });

  const isUMDirty = useAppSelector((state) => {
    return state.userMapping.UMDirty;
  });

  const [contractsMeta, setContractsMeta] = useState<any>(null);

  useEffect(() => {
    document.title = "Quickbooks Usermapping - Pebble";
    const socket = io(environment.socketUrl?.toString() || "");
    socket.emit("join", { room: environment.socketRoom });
    getConnectorSyncStatus("quickbooks").then((resp) => {
      if (resp && resp.status === 200) {
        setSyncStatus(resp.data["status"]);
        socket.on("my_response", function (msg) {
          if (
            msg &&
            typeof msg.data === "object" &&
            msg.data.hasOwnProperty("connector_id")
          ) {
            try {
              if (
                msg.data.connector_id === resp.data["external_reference_slug"]
              ) {
                setSyncStatus(msg.data.event_status);
              }
            } catch (err) {
              console.log("in error", msg.data);
            }
          }
        });
      }
    });
    setLoading(true);
    // todo: this is sequential. change it to parallel calls.
    getUserMappingsFromAPI("quickbooks").then((getUserMappingsFromAPIResp) => {
      if (getUserMappingsFromAPIResp.status === 403) {
        setAutorized(false);
        setLoading(false);
        return;
      }
      if (
        getUserMappingsFromAPIResp &&
        getUserMappingsFromAPIResp.status === 200 &&
        getUserMappingsFromAPIResp.data &&
        getUserMappingsFromAPIResp.data.data &&
        getUserMappingsFromAPIResp.data.data.length > 0
      ) {
        dispatch(setConfigs(getUserMappingsFromAPIResp.data.data));
        setContractsMeta(
          getUserMappingsFromAPIResp.data.data.find(
            (data) => data.slug === "contract"
          )
        );
        getContracts();
      }
      setLoading(false);
    });
  }, []);
  const steps = [1, 2, 3, 4];
  const [currentStep, setCurrentStep] = useState(1);
  const loadNextPage = () => {
    if (currentStep === steps[steps.length - 1]) {
      setCompleted(true);
    } else {
      setCurrentStep(currentStep + 1);
    }
  };

  const getContracts = () => {
    getUserMappingsContractsFromAPI("quickbooks").then((contractsResp) => {
      if (contractsResp.status === 403) {
        setAutorized(false);
        setLoading(false);
        return;
      }
      if (
        contractsResp &&
        contractsResp.status === 200 &&
        contractsResp.data &&
        contractsResp.data.data
      ) {
        setLoading(false);
        dispatch(setContractConfigs(contractsResp.data.data));
      }
    });
  };

  useEffect(() => {
    if (!modifiedUMMetaId) return;

    if (!!modifiedContractId) {
      if (actionType === "add" || actionType === "edit") {
        let contractUM = null;
        if (actionType === "add") {
          contractUM = unsavedUMDatawithMeta.find(
            (v) => v.slug === "contract" && v.user_mapping.alt_slug === null
          )?.user_mapping;
        } else {
          contractUM = unsavedUMDatawithMeta.find(
            (v) => v.user_mapping.id === modifiedContractId
          )?.user_mapping;
        }
        saveContractMappings(
          "quickbooks",
          modifiedUMMetaId,
          contractUM.mapping,
          contractUM.ref_program_id,
          contractUM.ref_program_name,
          contractUM.alt_title,
          contractUM.alt_slug
        )
          .then((contractsResp) => {
            if (contractsResp.status === 201) {
              dispatch(
                setHasAtLeastOneMapping(
                  contractsResp.data.has_at_least_one_mapping
                )
              );
              dispatch(setContractConfigs(contractsResp.data.data));
              dispatch(
                showMessage({
                  message: "Mapping Saved Successfully",
                  variant: "success",
                  extraProps: {
                    anchorOrigin: { vertical: "bottom", horizontal: "center" },
                  },
                })
              );
            } else {
              alert("Some error occurred");
            }
            dispatch(setModifiedUMMetaId(null));
          })
          .catch((err) => {
            dispatch(setModifiedUMMetaId(null));
            alert("Some error occurred");
          });
      } else {
        const contractUm = unsavedUMDatawithMeta.find(
          (v) => v.user_mapping.id === modifiedContractId
        )?.user_mapping;
        deleteUserMappingsContracts("quickbooks", contractUm.alt_slug).then(
          (resp) => {
            if (resp && resp.status === 204) {
              dispatch(setModifiedUMMetaId(null));
              dispatch(
                showMessage({
                  message: "Contract Mapping Deleted Successfully",
                  variant: "success",
                  extraProps: {
                    anchorOrigin: { vertical: "bottom", horizontal: "center" },
                  },
                })
              );
              getContracts();
            }
          }
        );
      }
    } else {
      let mapping = unsavedUMDatawithMeta.find((v) => v.id === modifiedUMMetaId)
        ?.user_mapping.mapping;
      saveUserMappings("quickbooks", modifiedUMMetaId, mapping)
        .then((resp) => {
          if (resp.status === 201) {
            dispatch(
              setHasAtLeastOneMapping(resp.data.has_at_least_one_mapping)
            );
            dispatch(setConfigs(resp.data.data));
            dispatch(
              showMessage({
                message: "Mapping Saved Successfully",
                variant: "success",
                extraProps: {
                  anchorOrigin: { vertical: "bottom", horizontal: "center" },
                },
              })
            );
            getContracts();
          } else {
            alert("Some error occurred");
          }
          dispatch(setModifiedUMMetaId(null));
        })
        .catch((err) => {
          dispatch(setModifiedUMMetaId(null));
          alert("Some error occurred");
        });
    }
  }, [modifiedUMMetaId]);

  const generateStepCanonicalName = () => {
    switch (currentStep) {
      case 1:
        return "system-defined";
      case 2:
        return "contract";
      case 3:
        return "conditional";
      case 4:
        return "restriction";
      default:
        break;
    }
  };

  const renderItems = () => {
    if (currentStep === 1) {
      return (
        <QuickbooksSystemDefinedMappings configItems={enrichedUMDatawithMeta} />
      );
    } else if (currentStep === 2) {
      return (
        <QuickbooksContractsMappings
          meta={contractsMeta}
          configItems={enrichedUMDatawithMeta.filter((e) => e.step === 2)}
        />
      );
    } else if (currentStep === 3) {
      return (
        <QuickbooksConditionalMappings configItems={enrichedUMDatawithMeta} />
      );
    } else {
      return (
        <QuickbooksRestrictionMappings configItems={enrichedUMDatawithMeta} />
      );
    }
  };
  const leftViewLogic = () => {
    if (syncStatus === "") {
      return "";
    }
    if (syncStatus === "success") {
      return (
        <Stack>
          <Button
            variant="contained"
            disabled={!hasAtLeastOneMapping}
            href="/panorama/"
          >
            Go to KPIs
          </Button>
          <Typography
            color={theme.custom.primaryGreyColor}
            variant="inter_500_10"
            sx={{
              display: hasAtLeastOneMapping ? "none" : "block",
              mt: 1,
            }}
          >
            Save at least one mapping to enable
          </Typography>
        </Stack>
      );
    } else {
      return (
        <Typography variant="poppins_h5_600_18">
          Map fields while we connect
        </Typography>
      );
    }
  };
  return (
    <Stack direction="row">
      <Sidebar />
      <Divider orientation="vertical" flexItem />
      {completed ? (
        navigate("/connectors/quickbooks/status")
      ) : (
        <Grid container>
          <Grid
            sx={{ pl: 8, pr: 16, pt: 12, pb: 4 }}
            style={{ width: "100%" }}
            item
          >
            <Typography variant="h4">
              <a href="/connectors/list">
                <LeftIcon color="primary" />
              </a>{" "}
              Map fields to Pebble
              <img
                alt="img"
                style={{
                  verticalAlign: "middle",
                  cursor: "pointer",
                  height: "20px",
                  paddingLeft: "0.2em",
                }}
                src={InfoIcon}
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
              />
            </Typography>
            <Typography sx={{ mt: 1 }} variant="inter_p_400_16">
              Pebble uses technology to align and segment data from other
              systems to make sense for nonprofit leaders.
            </Typography>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: "none",
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Box
                p={3}
                sx={{
                  maxHeight: 350,
                  width: 400,
                  position: "relative",
                  borderRadius: "6px",
                }}
              >
                <Typography sx={{ my: 1 }} variant="inter_p_400_16">
                  Don't worry if that sounds complicated, all you have to do is
                  identify if you track any of the items on the left within the
                  system we're connecting.
                </Typography>

                <Typography
                  sx={{ my: 1 }}
                  variant="inter_p_400_16"
                  paddingTop={2}
                >
                  For example, if you track funding source by "Parent Customer"
                  in Qb, simply select "Parent Customer" in the drop down menu
                  to align these fields within Pebble.
                </Typography>

                <Typography
                  sx={{ my: 1 }}
                  variant="inter_p_400_16"
                  paddingTop={2}
                >
                  Confused? Don't worry - we're here to help! Drop us a line via
                  the "Help!" button and we'll work through your organization's
                  unique data architecture together.
                </Typography>
              </Box>
            </Popover>
            {authorized && (
              <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                sx={{
                  backgroundColor: theme.custom.mapFieldStatusBg,
                  p: 3,
                  mt: 4,
                  borderRadius: "6px",
                  display: loading ? "none" : "",
                }}
              >
                <Grid item>{leftViewLogic()}</Grid>
                <Grid item>
                  {generateStatusDisplay(syncStatus, { showSyncMessage: true })}
                </Grid>
              </Grid>
            )}
            {authorized && !loading && (
              <Box my={3}>
                <Button
                  variant="text"
                  onClick={() => {
                    setOpenDrilldownModal(true);
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <LinkOffIcon />
                    <Typography
                      variant="inter_p_500_14"
                      sx={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      {" "}
                      Check unmapped transactions
                    </Typography>
                  </Box>
                </Button>
                <DrillDownModal
                  openModal={openDrilldownModal}
                  onCloseModal={() => {
                    setOpenDrilldownModal(false);
                  }}
                  slug="um-not-linked"
                  payload={{ pQoz9ykBE7: "Yke5G9WinP" }}
                />
              </Box>
            )}
            {authorized && (
              <Grid
                sx={{ mt: 4, mb: 3 }}
                justifyContent="space-between"
                container
                alignItems="center"
              >
                <Grid item xs={9}>
                  <Stack direction="row" alignItems="center">
                    <>
                      <Grid container xs={12} justifyContent="left">
                        <Grid item>
                          <Avatar
                            src={logo}
                            sx={{
                              p: 1,
                              width: 40,
                              height: 40,
                              border: `1px solid ${theme.custom.borderColor}`,
                              borderRadius: "5px",
                            }}
                          />
                        </Grid>
                        <Grid item sx={{ pt: 1 }}>
                          <span
                            style={{
                              display: "inline-block",
                              width: "100px",
                              borderBottom: `2px dashed ${theme.custom.borderColor}`,
                              lineHeight: 0,
                            }}
                          >
                            &nbsp;
                          </span>
                        </Grid>
                        <Grid item>
                          <Avatar
                            src={
                              "https://cdn.zappy.app/bba0ffdc260ef23e78fd9d277e07648b.png"
                            }
                            sx={{
                              p: 1,
                              marginRight: 1,
                              width: 40,
                              height: 40,
                              border: `1px solid ${theme.custom.borderColor}`,
                              borderRadius: "5px",
                            }}
                          />
                        </Grid>
                        ̦
                      </Grid>
                    </>
                  </Stack>
                </Grid>
                <Grid item xs={3}>
                  <Stepper activeStep={currentStep - 1}>
                    {steps.map((label, index) => {
                      const stepProps: { completed?: boolean } = {};
                      const labelProps: {
                        optional?: React.ReactNode;
                      } = {};
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}></StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Grid>
              </Grid>
            )}
            {loading ? (
              <>
                {range(3).map((num) => (
                  <Skeleton
                    key={num}
                    sx={{ my: 2, bgcolor: "grey.100" }}
                    variant="rectangular"
                    animation="wave"
                    height={120}
                  />
                ))}
              </>
            ) : (
              <div
                className={`quickbooks usermapping ${generateStepCanonicalName()}`}
              >
                {authorized ? (
                  renderItems()
                ) : (
                  <div>
                    <Box py={3}>
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          background: theme.custom.bgImageColor,
                          minHeight: "250px",
                          borderRadius: 3,
                        }}
                      >
                        <Grid item>
                          <Typography variant="inter_p_500_14">
                            You don’t have access to user mapping. Please reach
                            out to admin to get access.
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </div>
                )}
              </div>
            )}

            {authorized && (
              <Grid
                container
                justifyContent={currentStep === 1 ? "end" : "space-between"}
                sx={{ mt: 3 }}
              >
                <Button
                  sx={{ display: currentStep === 1 ? "none" : "block" }}
                  onClick={(e) => setCurrentStep(currentStep - 1)}
                >
                  <Typography variant="poppins_p_600_14">&lt; Back </Typography>
                </Button>
                <LoadingButton
                  variant="contained"
                  sx={{ py: 1.5 }}
                  loading={loading || isSaving}
                  disabled={
                    invalidMappingCount !== 0 || !!modifiedUMMetaId || isSaving || isUMDirty
                  }
                  onClick={(e) => {
                    loadNextPage();
                  }}
                >
                  {modifiedUMMetaId || isSaving ? "Loading" : "Continue"}
                </LoadingButton>
              </Grid>
            )}
          </Grid>
          {/* <Grid item xs={3} sx={{ display: { md: "none", lg: "block" } }}>
            <PebbleBgImage url="https://pebble-fe-assets.s3.us-west-1.amazonaws.com/Group+65-min.png" />
          </Grid> */}
        </Grid>
      )}
    </Stack>
  );
};

export default QuickbooksUserMapping;
