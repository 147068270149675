import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useDeepCompareEffect } from "react-use";
import {
  BoardCollectionMetaData,
  BoardCollectionPermissionDetails,
  BoardKpiMeta,
  BoardKpiPermissionMeta,
  FormattedMemberDetails,
  KpiList,
} from "../../../Types/UserManagement";
import { getPermissionsMeta } from "../../../services/user-management-service";
import { theme } from "../../../theme";
import CustomizedCheckbox from "../../atoms/CustomizedCheckbox";
import MemberBoardPermissionItem from "./MemberBoardPermissionItem";

type Props = {
  updateMemberStateAndNext: CallableFunction;
  loadPreviousPage: CallableFunction;
  formattedMemberDetails: FormattedMemberDetails;
  updateFormattedMemberDetails: CallableFunction;
};

export const enum CheckboxType {
  SELECT_ALL,
  ALL_KPIS,
}

export const enum SelectionType {
  CHECKBOX,
  DROPDOWN,
}

function MemberBoardPermissionForm(props: Props) {
  const [boardKpiPermissions, setBoardKpiPermissions] =
    useState<BoardKpiMeta>();
  const [formattedMemberDetailsDirty, setFormattedMemberDetailsDirty] =
    useState<FormattedMemberDetails>(props.formattedMemberDetails);

  const [isLoading, setIsLoading] = useState(true);

  useDeepCompareEffect(() => {
    props.updateFormattedMemberDetails(formattedMemberDetailsDirty);
  }, [formattedMemberDetailsDirty]);

  useEffect(() => {
    setIsLoading(true);
    // get permission meta
    getPermissionsMeta(
      "board_kpi_permissions",
      formattedMemberDetailsDirty.role_slug
    ).then((resp) => {
      if (resp && resp.data.data) {
        setBoardKpiPermissions(resp.data);

        Object.entries(resp.data?.data || {}).map(
          ([boardCollectionName, allBoardsMeta], _) =>
            setFormattedMemberDetailsDirty((state) => {
              let newFormattedMemberDetails: any = { ...state };
              let board_collection_permissions = setBaseKeys(
                newFormattedMemberDetails,
                allBoardsMeta as BoardKpiPermissionMeta[],
                boardCollectionName
              );

              newFormattedMemberDetails[
                newFormattedMemberDetails.role_slug
              ].board_kpi_permissions[boardCollectionName] =
                board_collection_permissions;
              return newFormattedMemberDetails;
            })
        );

        setIsLoading(false);
      }
    });
  }, [formattedMemberDetailsDirty.role_slug]);

  const setBaseKeys = (
    newFormattedMemberDetails: any,
    allBoardsMeta: BoardKpiPermissionMeta[],
    boardCollectionName: string
  ) => {
    // Get or populate all keys

    if (!newFormattedMemberDetails[newFormattedMemberDetails.role_slug])
      newFormattedMemberDetails[newFormattedMemberDetails.role_slug] = {};

    if (
      !newFormattedMemberDetails[newFormattedMemberDetails.role_slug]
        .board_kpi_permissions
    )
      newFormattedMemberDetails[
        newFormattedMemberDetails.role_slug
      ].board_kpi_permissions = {};

    let _board_collection =
      newFormattedMemberDetails[newFormattedMemberDetails.role_slug]
        .board_kpi_permissions;

    let board_collection_permissions: BoardCollectionPermissionDetails =
      _board_collection[boardCollectionName];

    if (!board_collection_permissions) {
      board_collection_permissions = {
        has_full_access: false,
        boards: [],
      };
    }

    // Get or populate boards.
    board_collection_permissions.boards = allBoardsMeta.map((item) => {
      const existing = board_collection_permissions.boards?.find(
        (b) => b.board_slug === item.board_slug
      );
      if (existing) return existing;
      return {
        board_slug: item.board_slug,
        board_title: item.board_title,
        has_full_access: board_collection_permissions.has_full_access,
        authorized_kpis: [],
      };
    });

    return board_collection_permissions;
  };

  const handleCheckboxes = (
    board_collection_permissions: BoardCollectionPermissionDetails,
    checkboxType: CheckboxType,
    isChecked: boolean,
    allBoardsMeta: BoardKpiPermissionMeta[],
    metaItem?: BoardKpiPermissionMeta
  ) => {
    // In place modifications for board collection permissions
    if (checkboxType === CheckboxType.SELECT_ALL) {
      board_collection_permissions.has_full_access = isChecked;
      board_collection_permissions.boards =
        board_collection_permissions.boards.map((b) => {
          return { ...b, has_full_access: isChecked, authorized_kpis: [] };
        });
    }
    if (checkboxType === CheckboxType.ALL_KPIS) {
      let _board_permission = board_collection_permissions.boards.find(
        (b) => b.board_slug === metaItem?.board_slug
      );
      if (_board_permission) {
        if (isChecked) {
          _board_permission.has_full_access = true;
          _board_permission.authorized_kpis = [];
          // check if all boards are checked
          const selected_board_slugs = new Set(
            board_collection_permissions.boards
              .filter((i) => i.has_full_access)
              .map((i) => i.board_slug)
          );
          if (
            allBoardsMeta.every((i) => selected_board_slugs.has(i.board_slug))
          ) {
            board_collection_permissions.has_full_access = true;
          }
        } else {
          _board_permission.has_full_access = false;
          board_collection_permissions.has_full_access = false;
        }
      }
    }
  };

  const handleDropdown = (
    board_collection_permissions: BoardCollectionPermissionDetails,
    metaItem?: BoardKpiPermissionMeta,
    dropDownValues?: KpiList[]
  ) => {
    let _board_permission = board_collection_permissions.boards.find(
      (b) => b.board_slug === metaItem?.board_slug
    );
    if (_board_permission && dropDownValues) {
      _board_permission.authorized_kpis = dropDownValues;
    }
  };

  const setSelected = (
    boardCollectionName: keyof BoardCollectionMetaData,
    allBoardsMeta: BoardKpiPermissionMeta[],
    selectionType: SelectionType,
    checkboxType: CheckboxType,
    isChecked: boolean,
    prevFormattedMemberDetails: FormattedMemberDetails,
    metaItem?: BoardKpiPermissionMeta,
    dropDownValues?: KpiList[]
  ) => {
    // Get or populate all keys
    let newFormattedMemberDetails: any = { ...prevFormattedMemberDetails };

    let board_collection_permissions = setBaseKeys(
      newFormattedMemberDetails,
      allBoardsMeta,
      boardCollectionName
    );

    if (selectionType === SelectionType.CHECKBOX)
      handleCheckboxes(
        board_collection_permissions,
        checkboxType,
        isChecked,
        allBoardsMeta,
        metaItem
      );
    else {
      handleDropdown(board_collection_permissions, metaItem, dropDownValues);
    }

    newFormattedMemberDetails[
      newFormattedMemberDetails.role_slug
    ].board_kpi_permissions[boardCollectionName] = board_collection_permissions;

    return newFormattedMemberDetails;
  };
  const setSelectedWrapper = (
    boardCollectionName: keyof BoardCollectionMetaData,
    allBoardsMeta: BoardKpiPermissionMeta[],
    selectionType: SelectionType,
    checkboxType: CheckboxType,
    isChecked: boolean,
    boardMetaItem?: BoardKpiPermissionMeta,
    dropDownValues?: KpiList[]
  ) => {
    setFormattedMemberDetailsDirty((state: FormattedMemberDetails) =>
      setSelected(
        boardCollectionName,
        allBoardsMeta,
        selectionType,
        checkboxType,
        isChecked,
        state,
        boardMetaItem,
        dropDownValues
      )
    );
  };

  const renderBoardPermissionItem = (
    boardCollectionName: keyof BoardCollectionMetaData,
    metaItem: BoardKpiPermissionMeta,
    allBoardsMeta: BoardKpiPermissionMeta[]
  ) => {
    return (
      <MemberBoardPermissionItem
        boardCollectionName={boardCollectionName}
        allBoardsMeta={allBoardsMeta}
        metaItem={metaItem}
        key={metaItem.board_slug}
        setSelected={setSelectedWrapper}
        memberDetailsForItem={formattedMemberDetailsDirty?.[
          formattedMemberDetailsDirty.role_slug
        ]?.board_kpi_permissions?.[boardCollectionName]?.boards?.find(
          (item) => metaItem.board_slug === item.board_slug
        )}
        hasTopLevelFullAccess={
          formattedMemberDetailsDirty?.[formattedMemberDetailsDirty.role_slug]
            ?.board_kpi_permissions?.[boardCollectionName]?.has_full_access ||
          false
        }
      />
    );
  };

  const handleNext = () => {
    props.updateMemberStateAndNext({});
  };

  return (
    <>
      <DialogContent sx={{ height: "100%" }}>
        <Grid container direction={"column"} justifyContent={"space-between"}>
          <Grid item>
            <Typography variant="poppins_h4_600_20">
              Board Kpi Permissions
            </Typography>
          </Grid>
          {isLoading && (
            <Skeleton
              sx={{ bgcolor: "grey.100", mt: 4, borderRadius: 3 }}
              variant="rectangular"
              animation="wave"
              height={350}
            />
          )}
          {!isLoading &&
            Object.entries(boardKpiPermissions?.data || {}).map(
              ([boardCollectionName, allBoardsMeta], _) => (
                <Grid
                  container
                  mt={3}
                  direction={"column"}
                  justifyContent={"space-between"}
                  key={boardCollectionName}
                >
                  <Grid item>
                    <Grid item>
                      <Typography variant="poppins_h5_600_18">
                        {boardCollectionName
                          .split("_")
                          .map(
                            (word) =>
                              word.charAt(0).toUpperCase() + word.slice(1)
                          )
                          .join(" ")}
                      </Typography>
                    </Grid>
                    <Grid item mt={1}>
                      <CustomizedCheckbox
                        isChecked={
                          formattedMemberDetailsDirty[
                            formattedMemberDetailsDirty.role_slug
                          ]?.board_kpi_permissions?.[
                            boardCollectionName as keyof BoardCollectionMetaData
                          ]?.has_full_access || false
                        }
                        onChange={(value: boolean) => {
                          if (
                            !value ||
                            (value &&
                              window.confirm(
                                "Are you sure you want to select all? Existing selections will be overridden and all kpis of all boards will be selected."
                              ))
                          ) {
                            setSelectedWrapper(
                              boardCollectionName as keyof BoardCollectionMetaData,
                              allBoardsMeta,
                              SelectionType.CHECKBOX,
                              CheckboxType.SELECT_ALL,
                              value
                            );
                          }
                        }}
                        labelProps={{
                          title: "Select All",
                          extraSx: {
                            variant: "inter_p_600_16",
                            color: theme.custom.primaryDarkColor,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item justifyContent={"space-between"}>
                      <Grid container justifyContent={"space-between"}>
                        {allBoardsMeta.map((item: BoardKpiPermissionMeta) =>
                          renderBoardPermissionItem(
                            boardCollectionName as keyof BoardCollectionMetaData,
                            item,
                            allBoardsMeta
                          )
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )
            )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Button
              sx={{ height: "50px", px: 4 }}
              onClick={() => props.loadPreviousPage()}
              variant="outlined"
              type="submit"
            >
              <Typography variant="poppins_p_600_14">Back</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{ height: "50px", px: 12 }}
              onClick={handleNext}
              variant="contained"
              type="submit"
            >
              <Typography variant="poppins_p_600_14">Continue</Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
}

export default memo(MemberBoardPermissionForm);
