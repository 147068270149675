export const environment = {
  authUrl: process.env.REACT_APP_AUTH_URL,
  spotlightUrl: process.env.REACT_APP_SPOTLIGHT_URL,
  connectorApiUrl: process.env.REACT_APP_CONNECTOR_API_URL,
  mockApiUrl: process.env.REACT_APP_MOCK_API_URL,
  socketUrl: process.env.REACT_APP_SOCKET_URL,
  emptyChartDataString: "No Data Available, Check Filter",
  socketRoom: process.env.REACT_APP_SOCKET_ROOM,
  cmsUrl: process.env.REACT_APP_CMS_URL,
  unsplashClientId: "9kXt-ozN0QVMZ9rehyWDJV6cJuQ0JK9ApCkyVJJdRxg",
  companionUrl: process.env.REACT_APP_COMPANION_URL,
  sentryDsn: process.env.REACT_APP_SENTRY_DSN,
  sentryTracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
  env: process.env.REACT_APP_ENV,
  sentryReplaySessionSampleRate: process.env.REACT_APP_SENTRY_REPLAY_SESSION_SAMPLE_RATE,
  sentryReplayErrorSampleRate: process.env.REACT_APP_SENTRY_REPLAY_ERROR_SAMPLE_RATE,
  s3AssetUrl: process.env.REACT_APP_S3_ASSET_URL,
  suprsendWorkspaceKey: process.env.REACT_APP_SUPRSEND_WORKSPACE_KEY
};
