import React from "react";
import { NavigationMenuItem } from "../molecules/NavigationMenuItem";
import { NavigationMenuItemObject } from "../../Types/NavigationMenuItemObject";
import { Box, Typography } from "@mui/material";
import Logo from "../../assets/Pebble_Logo_Icon.png";
import LogoFull from "../../assets/Pebble_Logo.png";
import { useNavigate } from "react-router-dom";

interface Props {
  menus: NavigationMenuItemObject[];
  isExpanded: boolean;
  menuItemClicked: Function;
  onHoverMenuItem: Function;
  onMenuHoverOut: Function;
}

const Navigation: React.FC<Props> = (data) => {
  const navigate = useNavigate();
  let menus = data.menus;

  const menuClicked = () => {
    data.menuItemClicked();
  };

  const menuHovered = () => {
    data.onHoverMenuItem();
  };

  const menuHoveredOut = () => {
    data.onMenuHoverOut();
  };

  const navigationHelper = () => {
    navigate("/panorama/");
  };

  return (
    <React.Fragment>
      <Box px={2}>
        <Box
          onClick={navigationHelper}
          px={3}
          mt={3}
          mb={8}
          sx={{ display: "flex" }}
        >
          <Box sx={{ cursor: "pointer" }} mr={4}>
            <img
              style={{ height: 33, width: 33 }}
              src={Logo}
              alt="Pebble"
            ></img>
          </Box>
          <Box sx={{ cursor: "pointer" }}>
            <img style={{ height: 33 }} src={LogoFull} alt="Pebble"></img>
          </Box>
        </Box>
        {menus.map((item, key) => (
          <Box>
            <NavigationMenuItem
              onMenuHover={menuHovered}
              menuClicked={menuClicked}
              key={key}
              item={item}
            />
          </Box>
        ))}
      </Box>
    </React.Fragment>
  );
};

export default Navigation;
