import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { memo, useRef, useState } from "react";
import { theme } from "../../../theme";
import { capitalize, map, orderBy } from "lodash";
import { dateFormatter, numberFormatter } from "../../../utils/dataFormatters";
import moment from "moment";
import CustomLinearProgress from "../../atoms/CustomLinearProgress";
import HtmlTooltip from "../../atoms/HTMLTooltip";
import BudgetTrackerFilterDate from "./BudgetTrackerFilterDate";
import EditIcon from "../../../assets/pencil.png";
import { getBudgetTrackerCards } from "../../../services/budget-service";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

type Props = {
  setBudgetIdInFilters: Function;
  cardItem: any;
  selected: boolean;
  filter: any;
};

const BudgetTrackerCard = (props: Props) => {
  let item = props.cardItem;
  let setBudgetIdInFilters = props.setBudgetIdInFilters;

  const [dateFilterOpen, setDateFilterOpen] = useState(false);
  const [budgetCardData, setBudgetCardData] = useState(
    orderBy(
      map(item.data, (v, k) => {
        return { ...v, slug: k };
      }),
      ["position"],
      ["asc"]
    )
  );

  const [count, setCount] = useState(3);

  const [toggle, setToggle] = useState(false);

  const closeDateFilter = () => {
    setDateFilterOpen(false);
  };

  const openDateFilter = () => {
    setDateFilterOpen(true);
  };

  const date = useRef(
    props.cardItem.as_of_date
      ? props.cardItem.as_of_date
      : props.cardItem.default_filter_end_date
  );

  const onChangeDate = (budgetDetails: any, dt: string) => {
    date.current = moment(dt).format("yyyy-MM-DD");

    let _filter = {
      program: props.filter.ref_program_id,
      budget_uid: item.budget_uid,
      daterange: {
        start: item.start_date,
        end: moment(dt).format("yyyy-MM-DD"),
      },
    };
    getBudgetTrackerCardDetails(_filter, budgetDetails, dt);
  };

  const getBudgetTrackerCardDetails = (
    filter: any,
    budgetDetails: any,
    dt: any
  ) => {
    getBudgetTrackerCards("", filter).then((res) => {
      if (res.status === 200) {
        if (res.data.data.length) {
          setBudgetIdInFilters(
            budgetDetails,
            res.data.data[0],
            moment(dt).format("yyyy-MM-DD")
          );
        }
      }
    });
  };

  const progressDisplayHelper = (actual: number, budgeted: number) => {
    return (actual / budgeted) * 100;
  };

  const popupTextGenerator = (
    title: string,
    current: number,
    overallBudget: number,
    budgetedToDate: number
  ) => {
    return (
      <Grid container p={1}>
        <Grid item>
          <Typography color="inherit" variant="inter_500_12">
            {title}
          </Typography>
        </Grid>
        <Grid item mt={2}>
          <Typography color="inherit" variant="inter_500_10">
            Current:{" "}
            {current != undefined
              ? numberFormatter(current, "$")
              : "No data available"}
          </Typography>
        </Grid>
        <Grid item mt={1}>
          <Typography color="inherit" variant="inter_500_10">
            Overall Budget:{" "}
            {overallBudget != undefined
              ? numberFormatter(overallBudget, "$")
              : "No data available"}
          </Typography>
        </Grid>
        <Grid item mt={1}>
          <Typography color="inherit" variant="inter_500_10">
            Budgeted To Date:{" "}
            {budgetedToDate != undefined
              ? numberFormatter(budgetedToDate, "$")
              : "No data available"}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const getProgressColor = (cardData: any) => {
    if (cardData?.slug === "revenue") {
      return cardData.actual >= cardData.budgeted
        ? "successAlternate"
        : "errorAlternate";
    } else {
      return cardData.actual <= cardData.budgeted
        ? "successAlternate"
        : "errorAlternate";
    }
  };
  return (
    <>
      <Card
        sx={{
          overflowY: "auto",
          width: 350,
          minHeight: 350,
          maxHeight: 500,
          border: props.selected
            ? `2px solid ${theme.custom.primaryDarkColor}`
            : `1px solid ${theme.custom.filterBorderColor}`,
          borderRadius: "6px",
          boxShadow: "none",
        }}
        onClick={(event) => {
          setBudgetIdInFilters(item, "", event);
        }}
        key={item.budget_uid}
        elevation={4}
      >
        <CardContent sx={{ padding: 0 }}>
          <Grid sx={{ px: 3, py: 2 }} container>
            <Grid sx={{ py: 1 }} item sm={12}>
              <Typography variant="inter_p_600_16">
                {item.name || ""}
              </Typography>
            </Grid>
            <Grid item sm={12}>
              <Typography
                sx={{ color: theme.custom.primaryGreyColor }}
                variant="inter_500_12"
              >
                {item.contract_title || ""} - {item.program_name || ""}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
          <Box px={3} py={2}>
            <Grid container justifyContent={"space-between"} mt={1}>
              <Grid item>
                <Typography variant="inter_p_600_16">
                  {dateFormatter(item.start_date)} -{" "}
                  {dateFormatter(item.end_date)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{ color: item.status === "EXPIRED" ? "red" : "green" }}
                  variant="inter_500_10"
                >
                  {capitalize(item.status)}
                </Typography>
              </Grid>
            </Grid>

            {props.selected && (
              <Grid
                onClick={(event) => {
                  event.stopPropagation();
                }}
                sx={{ position: "relative" }}
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                mt={3}
              >
                <Grid item>
                  <Stack alignItems={"center"} direction={"row"}>
                    <Grid item>
                      <div
                        onClick={openDateFilter}
                        style={{ cursor: "pointer" }}
                      >
                        <Typography variant="inter_500_12">
                          Showing below KPIs as of:&nbsp;
                          {props.selected ? (
                            <span
                              style={{
                                textDecoration: "underline",
                                textDecorationStyle: "dotted",
                                color: theme.custom.popoverLinkColor,
                              }}
                            >
                              {moment(date.current).format("DD-MMMM-yy")}
                              <img
                                style={{ marginLeft: 5 }}
                                height="10px"
                                src={EditIcon}
                                alt="Edit"
                              />
                            </span>
                          ) : (
                            <span>
                              {moment(date.current).format("DD-MMMM-yy")}
                            </span>
                          )}
                        </Typography>
                      </div>
                      <BudgetTrackerFilterDate
                        open={dateFilterOpen}
                        handleClose={closeDateFilter}
                        date={date.current}
                        onDateChange={(date: any) => {
                          onChangeDate(item, date);
                        }}
                        minDate={item.start_date}
                        maxDate={item.end_date}
                      />
                    </Grid>
                  </Stack>
                </Grid>
              </Grid>
            )}
            <Box mt={2}>
              <Typography
                variant="inter_500_12"
                color={theme.custom.primaryDarkColor}
              >
                Overview:
              </Typography>
            </Box>

            {budgetCardData &&
              budgetCardData.length &&
              budgetCardData
                .slice(0, count)
                .map((cardData: any, index: number) => {
                  let progress = progressDisplayHelper(
                    cardData?.actual,
                    cardData?.budgeted
                  );
                  return (
                    <div>
                      <HtmlTooltip
                        arrow
                        placement="top"
                        title={popupTextGenerator(
                          cardData["label"],
                          cardData?.actual,
                          cardData?.budgeted,
                          cardData?.budgeted_to_date
                        )}
                      >
                        <Grid item mt={2}>
                          <CustomLinearProgress
                            extraProps={{
                              variant: "determinate",
                              value: Math.min(100, progress) || 0,
                              color: getProgressColor(cardData),
                            }}
                            extra={{
                              width: "100%",
                              borderRadius: "4px",
                            }}
                          />

                          <Typography
                            sx={{ color: theme.custom.primaryDarkColor }}
                            variant="inter_500_12"
                            mt={1}
                          >
                            {cardData["label"]}: &nbsp;
                            {cardData
                              ? numberFormatter(cardData.actual, "$")
                              : "No data available"}
                          </Typography>
                        </Grid>
                      </HtmlTooltip>
                    </div>
                  );
                })}

            {budgetCardData && budgetCardData.length > 3 && (
              <Grid
                container
                sx={{ width: "40%", marginTop: "20px" }}
                alignContent={"center"}
              >
                <Grid item>
                  {toggle ? (
                    <KeyboardArrowUpIcon
                      sx={{ color: theme.custom.popoverLinkColor }}
                    />
                  ) : (
                    <KeyboardArrowDownIcon
                      sx={{ color: theme.custom.popoverLinkColor }}
                    />
                  )}
                </Grid>
                <Grid item>
                  <Typography
                    sx={{ mt: 0.3 }}
                    variant="poppins_p_600_14"
                    onClick={(event) => {
                      setCount(!toggle ? budgetCardData.length : 3);
                      setToggle(!toggle);
                      event.stopPropagation();
                    }}
                    style={{
                      cursor: "pointer",
                      color: theme.custom.popoverLinkColor,
                    }}
                  >
                    View {!toggle ? "more" : "less"}
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default memo(BudgetTrackerCard);
