import {
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { set } from "lodash";
import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import DeleteIcon from "../../../assets/deleteIcon.png";
import MenuIcon from "../../../assets/fi-rr-menu-dots.png";
import LoaderIcon from "../../../assets/loader.png";
import { environment } from "../../../environments/environment-dev";
import {
  createConnection,
  deleteAllExcelFiles,
  deleteExcelFileBasedOnId,
  listAllExcelFiles,
  updateExcelFile,
} from "../../../services/file-upload-helper-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import { theme } from "../../../theme";
import { FileMetaType } from "../../../Types/FileMetaType";
import { LatestUploadMeta } from "../../../Types/LatestUploadMeta";
import ExcelConnectorFileListItem from "./ExcelConnectorFileListItem";
import ExcelConnectorFileUploader from "./ExcelConnectorFileUploader";

type Props = {};

const ExcelConnectorFileList = (props: Props) => {
  const dispatch = useAppDispatch();
  const [fileList, setFileList] = useState<FileMetaType[]>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openUploadModal, setOpenUploadModal] = useState(false);
  const [isUpdateOperation, setIsUpdateOperation] = useState({
    replaceFile: false,
    fileReplaceId: "" as string,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [connectionSyncStatus, setConnectionSyncStatus] = useState({} as any);
  const [authorized, setAuthorized] = useState(true);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const socket = io(environment.socketUrl || "");
    socket.on("connect", () => {
      console.log("Connected to Socket!!!");
    });
    socket.emit("join", { room: environment.socketRoom });
    socket.on("my_response", function (msg) {
      if (
        msg.data.hasOwnProperty("connector_id") &&
        msg.data.hasOwnProperty("event_status")
      ) {
        let temp = { ...connectionSyncStatus };
        set(temp, [msg.data.connector_id], {
          connector_id: msg.data.connector_id,
          status: msg.data.event_status,
          last_successful_sync_time: msg.data.event_time,
        });
        setConnectionSyncStatus(temp);
      }
    });
  }, []);

  useEffect(() => {
    fetchAllExcelFiles();
  }, []);

  //To fetch all the files.
  const fetchAllExcelFiles = () => {
    setIsLoading(true);
    listAllExcelFiles().then((response) => {
      if (response.status === 403) {
        setAuthorized(false);
        setIsLoading(false);
        return;
      }
      if (response.data) {
        setFileList(response.data.data);
        setIsLoading(false);
      }
    });
  };

  const deleteExcelFile = (fileId: string) => {
    deleteExcelFileBasedOnId(fileId).then((response) => {
      if (response.data && response.data.status === "success") {
        let temp = [...fileList];
        setFileList(temp.filter((ele) => ele.uid !== fileId));
        dispatch(
          showMessage({
            message: "File deleted successfully",
            variant: "success",
          })
        );
      }
    });
  };

  const onUploadSuccess = (uploadUrl: string, fileName: string) => {
    if (uploadUrl) {
      if (isUpdateOperation.replaceFile) {
        updateExcelFile(
          isUpdateOperation.fileReplaceId,
          uploadUrl,
          fileName
        ).then((res) => {
          fetchAllExcelFiles();
          setIsUpdateOperation({ replaceFile: false, fileReplaceId: "" });
          dispatch(
            showMessage({
              message: "File uploaded successfully",
              variant: "success",
            })
          );
        });
      } else {
        createConnection(uploadUrl, fileName).then((result) => {
          fetchAllExcelFiles();
          dispatch(
            showMessage({
              message: "File added successfully",
              variant: "success",
            })
          );
        });
      }
      handleClose();
    }
  };

  const deleteAllFiles = () => {
    let sure = window.confirm(
      "Are you sure you want to delete all files? Please note all linked KPIs will be deleted."
    );
    if (sure) {
      deleteAllExcelFiles().then((resp) => {
        if (resp && resp.data && resp.data.status === "success") {
          fetchAllExcelFiles();
          dispatch(
            showMessage({
              message: "All files deleted successfully",
              variant: "success",
            })
          );
        }
      });
    }
  };

  const onUploadLatest = (data: LatestUploadMeta) => {
    setIsUpdateOperation(data);
    setOpenUploadModal(true);
  };

  return (
    <>
      <Grid
        mb={2}
        textAlign={"right"}
        item
        md={12}
        sx={{ position: "relative" }}
      >
        {authorized && fileList.length > 0 && (
          <Grid container flexDirection={"row"} justifyContent={"flex-end"}>
            <Grid item mr={2}>
              <Stack direction="row" alignItems={"center"} spacing={0.5}>
                <Button
                  onClick={() => {
                    setOpenUploadModal(true);
                  }}
                  size="large"
                  variant="outlined"
                >
                  <Typography variant="poppins_600_12">Upload new</Typography>
                </Button>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Grid>
      {authorized && (
        <div
          style={{
            border: `1px solid ${theme.custom.borderColor}`,
            borderRadius: "6px",
            position: "relative",
          }}
        >
          {fileList.length > 0 ? (
            <Box p={4}>
              <Box>
                <Typography
                  variant="inter_p_600_14"
                  sx={{ color: theme.custom.primaryDarkColor }}
                >
                  {fileList.length} workbooks
                </Typography>
              </Box>
              <Box sx={{ overflowY: "auto" }}>
                {fileList &&
                  fileList.length > 0 &&
                  fileList.map((file, index) => {
                    return (
                      <>
                        <ExcelConnectorFileListItem
                          key={file.uid}
                          fileMeta={file}
                          deleteFile={deleteExcelFile}
                          onUploadLatest={onUploadLatest}
                          connectionSyncStatus={
                            connectionSyncStatus[file.external_reference_slug]
                          }
                        />
                        {!(fileList.length === index + 1) && (
                          <Divider
                            orientation="horizontal"
                            sx={{ width: "100%", height: 2 }}
                          />
                        )}
                      </>
                    );
                  })}
              </Box>
            </Box>
          ) : (
            <>
              <Box sx={{ height: 400, display: isLoading ? "block" : "none" }}>
                <Grid
                  className="show-loader"
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Grid item>
                    <img
                      src={LoaderIcon}
                      height="100"
                      width="100"
                      alt="Loader"
                    />
                  </Grid>
                </Grid>
              </Box>
              {!isLoading && (
                <Box
                  sx={{
                    height: 400,
                    borderRadius: "6px",
                    width: "100%",
                  }}
                >
                  <Grid
                    container
                    spacing={0}
                    height="75%"
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Typography
                      variant="poppins_h4_600_20"
                      sx={{
                        color: theme.custom.primaryDarkColor,
                        lineHeight: "40px",
                      }}
                    >
                      Add files to sync data
                    </Typography>
                    <Typography
                      variant="inter_400_14"
                      sx={{
                        color: theme.custom.primaryDarkColor,
                        lineHeight: "25px",
                      }}
                    >
                      You can start uploading the files. The uploaded files will
                      appear here
                    </Typography>
                    <Button
                      onClick={() => setOpenUploadModal(true)}
                      variant="contained"
                      sx={{
                        borderRadius: "6px",
                        height: 50,
                        width: 180,
                        mt: 4,
                      }}
                      color="primary"
                    >
                      Upload file
                    </Button>
                  </Grid>
                </Box>
              )}
            </>
          )}

          <ExcelConnectorFileUploader
            open={openUploadModal}
            closeModal={() => {
              setOpenUploadModal(false);
            }}
            onSuccessfulUpload={onUploadSuccess}
          />
        </div>
      )}
      {!authorized && (
        <Box py={3}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              background: theme.custom.bgImageColor,
              minHeight: "250px",
              borderRadius: 3,
            }}
          >
            <Grid item>
              <Typography variant="inter_p_500_14">
                You don’t have access to manage this connection. Please reach
                out to admin to get access.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
};
export default React.memo(ExcelConnectorFileList);
