import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { SyntheticEvent } from "react";
import Summarize from "../../../assets/summarize.png";
import moreCasual from "../../../assets/more_casual.png";
import moreFormal from "../../../assets/more_formal.png";
import fixGrammerSpelling from "../../../assets/fix_grammer_spelling.png";
import { generateTextRewrite } from "../../../services/menu-service";
import BubbleMenu from "../../atoms/BubbleMenu";
import MagicStick from "../../../assets/magic_wand_multi_color.png";
import MagicWandAnimation from "../../../../src/assets/magic_wand_animation.gif";
import { theme } from "../../../theme";

function TextBubbleMenu(props: any) {
  const {
    updateText,
    setLoading,
    quillRef,
    loading,
    id,
    element,
    showSubMenu,
    handleWandClick,
    duplicateElement,
    deleteElement,
  } = props;

  const handleTextRewriteClick = (e:SyntheticEvent, text_rephrase: string) => {
    let textValue = quillRef.current.getText().trim();
    setLoading(true);
    handleWandClick();
    let requestTextRewriteObj = {
      template_slug: text_rephrase,
      context: { content: textValue },
    };
    generateTextRewrite(requestTextRewriteObj)
      .then((res) => {
        if (res?.data?.response) {
          updateText(res?.data?.response);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    e.stopPropagation();
  };

  return (
    <>
      {showSubMenu && (
        <Paper sx={{ maxWidth: "100%" }}>
          <MenuList>
            <MenuItem
              onClick={(e:SyntheticEvent) => handleTextRewriteClick(e, "text_rephrase_summarise")}
            >
              <ListItemIcon>
                <img
                  height="20px"
                  width="20px"
                  src={Summarize}
                  alt="summarize"
                />
              </ListItemIcon>{" "}
              <ListItemText>
                <Typography
                  variant="inter_400_14"
                  color={theme.custom.primaryDarkColor}
                >
                  Summarize
                </Typography>
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={(e:SyntheticEvent) => handleTextRewriteClick(e, "text_rephrase_more_casual")
              }
            >
              <ListItemIcon>
                <img
                  height="20px"
                  width="20px"
                  src={moreCasual}
                  alt="more_casual"
                />
              </ListItemIcon>{" "}
              <ListItemText>
                <Typography
                  variant="inter_400_14"
                  color={theme.custom.primaryDarkColor}
                >
                  More casual
                </Typography>{" "}
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={(e:SyntheticEvent) => handleTextRewriteClick(e, "text_rephrase_more_formal")
              }
            >
              <ListItemIcon>
                <img
                  height="20px"
                  width="20px"
                  src={moreFormal}
                  alt="more_formal"
                />
              </ListItemIcon>{" "}
              <ListItemText>
                <Typography
                  variant="inter_400_14"
                  color={theme.custom.primaryDarkColor}
                >
                  More formal
                </Typography>
              </ListItemText>
            </MenuItem>
            <MenuItem
              onClick={(e:SyntheticEvent) => handleTextRewriteClick(e, "text_rephrase_fix_spelling_and_grammer")
              }
            >
              <ListItemIcon>
                <img
                  height="20px"
                  width="20px"
                  src={fixGrammerSpelling}
                  alt="fix_grammer_spelling"
                />
              </ListItemIcon>{" "}
              <ListItemText>
                <Typography
                  variant="inter_400_14"
                  color={theme.custom.primaryDarkColor}
                >
                  Fix Grammar & Spelling
                </Typography>
              </ListItemText>
            </MenuItem>
          </MenuList>
        </Paper>
      )}
      <BubbleMenu
        id={id}
        element={element}
        duplicateElement={duplicateElement}
        deleteElement={deleteElement}
      >
        {loading ? (
          <img src={MagicWandAnimation} alt="Loader" />
        ) : (
          <img
              onClick={(e) => {
                handleWandClick(e);
                e.stopPropagation();
              }}
              src={MagicStick}
              alt="magic_stick"
            />
        )}
      </BubbleMenu>
    </>
  );
}

export default React.memo(TextBubbleMenu);
