import { Grid, Skeleton, Stack } from "@mui/material";
import Box from "@mui/material/Box";
import _, { map, orderBy } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
// Core modules imports are same as usual
import { Navigation, Pagination, Scrollbar } from "swiper";

import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import "swiper/swiper.scss"; // core Swiper
import "../../../styles/swipe-js.scss";

// Direct React component imports
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import bgImage from "../../../assets/Group-177-min.png";
import getChartsForBoard from "../../../services/board-service";
import { getBudgetTrackerCards } from "../../../services/budget-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import { theme } from "../../../theme";
import BudgetTrackerCard from "../../molecules/BudgetTracker/BudgetTrackerCard";
import AuthorizationErrorHandler from "../../molecules/Editor/AuthorizationErrorHandler";
import FilterListWithOverlay from "../FiltersListWithOverlay";
import KPIList from "../KPIList";

interface Props {
  data: any;
  filters: any;
  board_type: string;
}

const INIT_STATE = {
  data: [],
  filters: {},
  board_type: "grid",
};

type BudgetContractsMonitoringProps = {
  kpiSlug: string;
  isTabClicked: boolean;
};

const BudgetContractsMonitoring = ({
  kpiSlug,
  isTabClicked,
}: BudgetContractsMonitoringProps) => {
  const slug = "budget-tracker";

  const navigate = useNavigate();
  const [board, setBoard] = useState<Props>(INIT_STATE);
  const dispatch = useAppDispatch();
  const [budgetCards, setBudgetCards] = useState<any>([]);
  const [hasData, setHasData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [authorized, setAuthorized] = useState(false);
  const [selectedBudgetID, setSelectedBudgetID] = useState(null as any);
  const program = useRef({ ref_program_id: "", ref_program_name: "" });

  const fetchKPIsForBoard = (filters: any) => {
    setBoard((prev) => {
      return { ...prev, data: [] };
    });
    setLoading(true);
    getChartsForBoard(slug, "", filters)
      .then((res) => {
        if (res.status === 403) {
          setAuthorized(false);
          setLoading(false);
          return;
        }
        if (
          res &&
          res.data &&
          res.data.kpi &&
          Object.keys(res.data.kpi).length === 0
        ) {
          setAuthorized(false);
        }
        if (
          res &&
          res.data &&
          res.data.kpi &&
          Object.keys(res.data.kpi).length > 0
        ) {
          setLoading(false);
          setAuthorized(true);
          let data = res.data.kpi || [];
          data = map(data, (ele: any) => {
            if (!ele.is_position_applicable) {
              return {
                ...ele,
                position: 999,
                position_x: 999,
                position_y: 999,
                row: 3,
                column: 12,
              };
            } else {
              return ele;
            }
          });
          let orderedData = orderBy(data, ["position"], ["asc"]);
          setBoard((prev) => {
            return {
              ...prev,
              data: orderedData,
              filters: filters,
            };
          });
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        dispatch(showMessage({ message: "Error fetching data" }));
        navigate("/connectors/list");
      });
  };

  let [searchParams, setSearchParams] = useSearchParams();
  const [urlKpiFilters, setUrlKpiFilters] = useState({});

  useEffect(() => {
    if (searchParams?.get("encoded_filter_data")) {
      const data = JSON.parse(
        atob(searchParams?.get("encoded_filter_data") || "{}")
      );
      setUrlKpiFilters(data);
    }
  }, [searchParams]);

  useEffect(() => {
    if (kpiSlug !== "" && !loading) {
      setTimeout(() => {
        let ele = document.getElementById(kpiSlug);
        if (ele) {
          window.scrollTo({
            behavior: "smooth",
            top:
              ele.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              100,
          });
        }
      }, 1000);
    }
  }, [kpiSlug, loading]);

  const setBudgetIdInFilters = (
    budgetData: any,
    latestBudgetCardDetails: any,
    date?: string,
    event?: any
  ) => {
    setLoading(true);
    setSelectedBudgetID(budgetData.budget_uid);
    let _filter: any = {
      program: program.current.ref_program_id,
      budget_uid: budgetData.budget_uid,
      daterange: {
        start: budgetData.start_date,
        end: date && date.length ? date : budgetData.as_of_date,
      },
    };
    if (latestBudgetCardDetails) {
      let _obj =
        latestBudgetCardDetails["data"][
          Object.keys(latestBudgetCardDetails["data"])[0]
        ];
      _obj["budget_uid"] = Object.keys(latestBudgetCardDetails["data"])[0];
      _obj["as_of_date"] = date;

      var result = _(budgetCards)
        .keyBy("budget_uid")
        .set(_obj.budget_uid, _obj)
        .values()
        .value();

      setBudgetCards(result);
    }

    fetchKPIsForBoard(_filter);
  };

  const renderTrackerCards = () => {
    return (
      <Grid
        container
        p={3}
        className={"carousel-container"}
        sx={{ display: hasData ? "block" : "none" }}
      >
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={28}
          navigation
          updateOnWindowResize
          modules={[Navigation, Pagination, Scrollbar]}
        >
          {budgetCards.length
            ? budgetCards.map((item: any, index: number) => {
                return (
                  <SwiperSlide style={{ width: "auto" }}>
                    <BudgetTrackerCard
                      cardItem={item}
                      selected={item.budget_uid === selectedBudgetID}
                      setBudgetIdInFilters={setBudgetIdInFilters}
                      key={index}
                      filter={program.current}
                    />
                  </SwiperSlide>
                );
              })
            : ""}
        </Swiper>
      </Grid>
    );
  };

  const onFiltersApply = (_filters: any, allFilters: any = {}, respStatus: number = 200) => {
    if (respStatus === 403) {
      setAuthorized(false);
      setLoading(false);
      return;
    }
    setLoading(true);

    if (_filters && _filters.program_single) {
      let _obj = {
        program: _filters.program_single.value || "",
        contract: _filters?.contract?.value || null,
      };

      getBudgetTrackerCards("", _obj).then((resp: any) => {
        let itemForProgram = resp.data;
        if (!itemForProgram?.data.length) {
          setHasData(false);
          setLoading(false);
          setBoard((prev) => {
            return { ...prev, data: [] };
          });

          return;
        }
        setHasData(true);
        program.current.ref_program_id =
          itemForProgram.data[0]["ref_program_id"] || "";
        program.current.ref_program_name =
          itemForProgram.data[0]["ref_program_name"] || "";

        let _cards: any = [];
        Object.keys(itemForProgram.data[0].data).forEach((item: any) => {
          let re = {
            budget_uid: item,
            ...itemForProgram.data[0].data[item],
            as_of_date:
              itemForProgram.data[0].data[item]["default_filter_end_date"],
          };

          _cards.push(re);
        });

        setBudgetCards(_cards);
        let budgetData = _cards.find(
          (ele: any) =>
            ele.budget_uid === itemForProgram.data[0]["default_budget_uid"]
        );
        setLoading(false);
        setSelectedBudgetID(budgetData.budget_uid);
        let filter: any = {
          program: program.current.ref_program_id,
          contract: _obj.contract,
          budget_uid: budgetData.budget_uid,
          daterange: {
            start: budgetData.start_date,
            end: budgetData?.default_filter_end_date || budgetData.end_date,
          },
        };

        fetchKPIsForBoard(filter);
      });
    }
  };
  return (
    <React.Fragment>
      <Stack direction="row">
        <Grid container sx={{ height: "100%" }}>
          <Grid item md={12}>
            {
              <Box mt={3} px={6} mb={3}>
                <FilterListWithOverlay
                  onFiltersApply={onFiltersApply}
                  board_slug={slug}
                  urlKpiFilters={urlKpiFilters}
                />
              </Box>
            }

            {!authorized && !loading && (
              <Grid container px={6}>
                <AuthorizationErrorHandler
                  errorMessage="You don’t have access to see Budget Monitoring. Please reach out to
        admin to get access"
                />
              </Grid>
            )}
            {board.data.length > 0 && !loading && (
              <Box
                px={6}
                sx={{
                  backgroundImage: `url(${bgImage})`,
                  backgroundRepeat: "repeat",
                  position: "relative",
                  color: theme.custom.filterBackgroundColor,
                  zIndex: "auto",
                }}
              >
                {renderTrackerCards()}
                <KPIList
                  data={board.data}
                  filters={board.filters}
                  board_slug={slug}
                  board_type={board.board_type}
                />
              </Box>
            )}
            {board.data.length === 0 && !loading && authorized && (
              <Box px={6}>
                <AuthorizationErrorHandler
                  errorMessage="
                  Either there are no linked KPIs or you don't have permission
                  to view KPIs. Please reach out to admin to get
                  access or create budget. 
                "
                ></AuthorizationErrorHandler>
              </Box>
            )}
            {loading && (
              <>
                <Skeleton
                  sx={{ mx: 6, my: 2, bgcolor: "grey.100" }}
                  variant="rectangular"
                  animation="wave"
                  height={400}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Stack>
    </React.Fragment>
  );
};

export default BudgetContractsMonitoring;
