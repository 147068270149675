import React, { memo, useEffect, useState } from "react";

import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Autocomplete,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  styled,
  createFilterOptions,
  Tooltip,
} from "@mui/material";

import { fontInter, theme } from "../../theme";
import { CustomBudgetType } from "../../Types/CustomBudgetType";
import { useAppDispatch } from "../../store/hook";
import { showMessage } from "../../store/error-handler-store";

type Props = {
  saveBudgetType: Function;
  allSavedBudgetTypes: CustomBudgetType[];
  edit?: boolean;
  currentValue?: CustomBudgetType;
  tab?: string;
  cancelChange?: Function;
  index?: number;
  is_editable?: boolean;
};

const AutoCompleteComponent = styled(Autocomplete)({
  ".MuiAutocomplete-inputRoot": {
    color: theme.custom.dropdownPrimaryColor,
    fontWeight: 500,
    borderRadius: 8,
    fontSize: "14px",
    fontFamily: fontInter,
  },
});

const AddCustomBudgetType = (props: Props) => {
  const {
    saveBudgetType,
    allSavedBudgetTypes,
    edit,
    currentValue,
    tab,
    cancelChange,
    index,
    is_editable,
  } = props;
  const filter = createFilterOptions<any>();
  const [show, setShow] = useState(edit ? edit : false);
  const [allOptions, setAllOptions] =
    useState<CustomBudgetType[]>(allSavedBudgetTypes);

  const [value, setValue] = React.useState<CustomBudgetType | null>(
    currentValue ? currentValue : null
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (allSavedBudgetTypes) {
      setAllOptions(allSavedBudgetTypes);
    }
  }, [allSavedBudgetTypes]);
  const showAutoComplete = () => {
    setShow(true);
  };

  const hideAutoComplete = () => {
    setShow(false);
    setValue(null);
    if (cancelChange) {
      cancelChange(tab);
    }
  };

  const renderOption = (props: any, option: any) => {
    return (
      <Grid item component="li" {...props}>
        <Stack direction="column">
          <Typography py={1} variant="inter_p_500_14">
            {option.label}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  return (
    <div>
      {!show && (
        <Tooltip
          title={
            is_editable
              ? `Add custom budget type`
              : "You don't have permission to perform this action"
          }
        >
          <span>
            <Button
              disabled={!is_editable}
              onClick={showAutoComplete}
              id="add-custom-type-button"
            >
              + Add
            </Button>
          </span>
        </Tooltip>
      )}

      {show && (
        <Box sx={{ display: "flex", position: "absolute", left: "-170px" }}>
          <Box>
            <AutoCompleteComponent
              value={value}
              style={{
                maxWidth: 200,
              }}
              size="small"
              onChange={(event, newValue: any) => {
                if (typeof newValue === "string") {
                  setValue({
                    label: newValue,
                    slug: "",
                  });
                  setValue({
                    label: newValue,
                    slug: "",
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  if (newValue.inputValue.trim().length) {
                    setValue({
                      label: newValue.inputValue,
                      slug: "",
                    });
                    saveBudgetType(
                      {
                        label: newValue.inputValue,
                        slug: "",
                      },
                      tab ? tab : "",
                      index ? index : -1,
                      edit
                    );
                  } else {
                    dispatch(
                      showMessage({
                        message: "Invalid custom budget type name.",
                      })
                    );
                  }
                } else {
                  setValue(newValue);
                  saveBudgetType(
                    newValue,
                    tab ? tab : "",
                    index ? index : -1,
                    edit
                  );
                }
                hideAutoComplete();
              }}
              filterOptions={(options: any, params: any) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option: any) => inputValue === option.label
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    label: `Create "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="custom_type_list"
              options={allOptions}
              getOptionLabel={(option: any) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }
                return option.label;
              }}
              renderOption={(props, option) => renderOption(props, option)}
              sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => {
                let _params: any = { ...params };
                _params["inputProps"] = {
                  ..._params["inputProps"],
                  maxLength: 512,
                };
                return (
                  <TextField
                    {..._params}
                    onKeyDown={(e: any) => {
                      if (e.code === "Enter") {
                        if (value?.label) {
                          hideAutoComplete();
                          // Whitespaces validation
                          if (value.label.trim().length) {
                            saveBudgetType(
                              value,
                              tab ? tab : "",
                              index ? index : -1,
                              edit
                            );
                          } else {
                            dispatch(
                              showMessage({
                                message: "Invalid custom budget type name.",
                              })
                            );
                          }
                        }
                      }
                    }}
                    label=""
                  />
                );
              }}
            />
          </Box>

          <Box
            onClick={() => {
              hideAutoComplete();
            }}
            sx={{ cursor: "pointer" }}
            mt={0.6}
            ml={1}
          >
            <CloseIcon />
          </Box>
        </Box>
      )}
    </div>
  );
};

export default memo(AddCustomBudgetType);
