import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  InputAdornment,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { remove } from "lodash";
import { memo, useEffect, useState } from "react";
import {
  DataPermission,
  DataPermissionsMeta,
  FormattedMemberDetails,
  ProgramPermissionsMeta,
  Value,
} from "../../../Types/UserManagement";
import SearchIcon from "../../../assets/search.png";
import { getPermissionsMeta } from "../../../services/user-management-service";
import MemberDataPermissionCheckboxItem from "./MemberDataPermissionCheckboxItem";
import CustomizedCheckbox from "../../atoms/CustomizedCheckbox";
import { theme } from "../../../theme";
import { useDeepCompareEffect } from "react-use";
import AuthorizationErrorHandler from "../Editor/AuthorizationErrorHandler";
import { useAppDispatch } from "../../../store/hook";
import { showMessage } from "../../../store/error-handler-store";

type Props = {
  updateMemberStateAndNext: CallableFunction;
  loadPreviousPage: CallableFunction;
  formattedMemberDetails: FormattedMemberDetails;
  updateFormattedMemberDetails: CallableFunction;
};

enum ErrorTypes {
  NONE,
  CONNECTOR_NOT_LINKED,
  USER_MAPPING_NOT_DONE,
  NO_PROGRAM_USER_MAPPING,
}

function MemberDataPermissionForm(props: Props) {
  const [dataPermissions, setDataPermissions] = useState<DataPermissionsMeta>();
  const [formattedMemberDetailsDirty, setFormattedMemberDetailsDirty] =
    useState<FormattedMemberDetails>(props.formattedMemberDetails);
  const [isError, setIsError] = useState(false);
  useDeepCompareEffect(() => {
    props.updateFormattedMemberDetails(formattedMemberDetailsDirty);
  }, [formattedMemberDetailsDirty]);

  const [isLoading, setIsLoading] = useState(true);
  const [errorType, setErrorType] = useState<ErrorTypes>(ErrorTypes.NONE);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // get permission meta
    setIsLoading(true);
    getPermissionsMeta(
      "data_permissions",
      formattedMemberDetailsDirty.role_slug
    )
      .then((resp) => {
        if (resp.status === 406) {
          setErrorType(ErrorTypes.CONNECTOR_NOT_LINKED);
          setIsError(true);
          setIsLoading(false);

          return;
        }
        if (resp.status === 200) {
          if (
            resp &&
            resp.data &&
            resp.data.data.length === 0 &&
            !resp.data.is_um_done
          ) {
            setErrorType(ErrorTypes.USER_MAPPING_NOT_DONE);
            setIsError(true);
            setIsLoading(false);
            return;
          }
          if (
            resp &&
            resp.data &&
            resp.data.data.length === 0 &&
            resp.data.is_um_done
          ) {
            setErrorType(ErrorTypes.NO_PROGRAM_USER_MAPPING);
            setIsError(true);
            setIsLoading(false);
            return;
          }
        }

        if (resp && resp.data.data) {
          setDataPermissions(resp.data);
          let _newFormattedMemberDetails = { ...formattedMemberDetailsDirty };
          let existingProgramPermissions =
            _newFormattedMemberDetails[formattedMemberDetailsDirty.role_slug]
              ?.data_permissions?.program_permissions;
          if (existingProgramPermissions) {
            // verify against meta.
            const metaLabels = new Set(
              resp.data.data.program_permissions.values.map(
                (i: Value) => i.label
              )
            );
            existingProgramPermissions.values =
              existingProgramPermissions.values.filter((v) =>
                metaLabels.has(v.label)
              );
            setFormattedMemberDetailsDirty(_newFormattedMemberDetails);
          }
          if (resp && resp.data.data) {
            setDataPermissions(resp.data);
            let _newFormattedMemberDetails = { ...formattedMemberDetailsDirty };
            let existingProgramPermissions =
              _newFormattedMemberDetails[formattedMemberDetailsDirty.role_slug]
                ?.data_permissions?.program_permissions;
            if (existingProgramPermissions) {
              // verify against meta.
              const metaLabels = new Set(
                resp.data.data.program_permissions.values.map(
                  (i: Value) => i.label
                )
              );
              existingProgramPermissions.values =
                existingProgramPermissions.values.filter((v) =>
                  metaLabels.has(v.label)
                );
              setFormattedMemberDetailsDirty(_newFormattedMemberDetails);
            }
            setIsLoading(false);
          }
        }
      })
      .catch((error) => {
        dispatch(showMessage({ message: "Sorry something went wrong" }));
      });
  }, [formattedMemberDetailsDirty.role_slug]);

  const enum CheckboxType {
    SELECT_ALL,
    NORMAL,
  }

  const renderErrorMessage = () => {
    if (errorType === ErrorTypes.CONNECTOR_NOT_LINKED) {
      return (
        <Box mx={2}>
          <Grid item xs={12}>
            <Box py={3}>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  background: theme.custom.bgImageColor,
                  minHeight: "150px",
                  borderRadius: 3,
                  textOverflow: "ellipsis",
                }}
              >
                <Grid sx={{ textAlign: "center" }} item px={2}>
                  <Typography
                    style={{
                      wordBreak: "break-word",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    variant="inter_p_500_14"
                    sx={{
                      color: theme.custom.dropdownPrimaryColor,
                      marginBottom: 1,
                      textAlign: "center",
                    }}
                  >
                    Seems like Quickbooks is not connected. If you want to give
                    data permission to this user please create a connection or
                    ask owner to do so.
                  </Typography>
                  {
                    <Button
                      sx={{ mt: 2, textAlign: "center" }}
                      variant="contained"
                      href="/connectors/list"
                    >
                      Go to Connectors
                    </Button>
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      );
    } else if (errorType === ErrorTypes.USER_MAPPING_NOT_DONE) {
      return (
        <Box mx={2}>
          <Grid item xs={12}>
            <Box py={3}>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  background: theme.custom.bgImageColor,
                  minHeight: "150px",
                  borderRadius: 3,
                  textOverflow: "ellipsis",
                }}
              >
                <Grid sx={{ textAlign: "center" }} item px={2}>
                  <Typography
                    style={{
                      wordBreak: "break-word",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    variant="inter_p_500_14"
                    sx={{
                      color: theme.custom.dropdownPrimaryColor,
                      marginBottom: 1,
                      textAlign: "center",
                    }}
                  >
                    We couldn’t find any programs, please ensure it is properly
                    mapped to a relevant attribute.
                  </Typography>
                  {
                    <Button
                      sx={{ mt: 2, textAlign: "center" }}
                      variant="contained"
                      href="/connectors/quickbooks/user-mapping"
                    >
                      Set Usermapping
                    </Button>
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      );
    } else if (errorType === ErrorTypes.NO_PROGRAM_USER_MAPPING) {
      return (
        <Box mx={2}>
          <Grid item xs={12}>
            <Box py={3}>
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  background: theme.custom.bgImageColor,
                  minHeight: "150px",
                  borderRadius: 3,
                  textOverflow: "ellipsis",
                }}
              >
                <Grid sx={{ textAlign: "center" }} item px={2}>
                  <Typography
                    style={{
                      wordBreak: "break-word",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                    }}
                    variant="inter_p_500_14"
                    sx={{
                      color: theme.custom.dropdownPrimaryColor,
                      marginBottom: 1,
                      textAlign: "center",
                    }}
                  >
                    We couldn’t find any programs, please ensure it is properly
                    mapped to a relevant attribute.
                  </Typography>
                  {
                    <Button
                      sx={{ mt: 2, textAlign: "center" }}
                      variant="contained"
                      href="/connectors/quickbooks/user-mapping"
                    >
                      Set Usermapping
                    </Button>
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Box>
      );
    }
  };

  const setSelected = (
    metaItem: ProgramPermissionsMeta,
    isChecked: boolean,
    value: Value,
    checkboxType: CheckboxType,
    oldFormattedMemberDetails: FormattedMemberDetails
  ) => {
    let _newFormattedMemberDetails: any = { ...oldFormattedMemberDetails };
    let _data_permissions: DataPermission =
      _newFormattedMemberDetails[_newFormattedMemberDetails.role_slug]
        ?.data_permissions || {};

    let _program_permissions = _data_permissions?.program_permissions;
    if (!_program_permissions) {
      _program_permissions = {
        value_slug: metaItem.value_slug,
        value_slug_type: metaItem.value_slug_type,
        has_full_access: false,
        values: [],
      };
    }

    if (checkboxType == CheckboxType.NORMAL) {
      if (!value) return;

      // update the values.
      if (isChecked) {
        _program_permissions.values.push(value);
      } else {
        // check if has_full_access was true. Then need to copy all values from meta.
        if (_program_permissions.has_full_access) {
          _program_permissions.values = [...metaItem.values];
        }
        // now pop the item from values
        remove(
          _program_permissions.values,
          (i: Value) => i.label === value.label
        );
      }
      // Check if all meta items are checked
      //// Create a hashset for quick lookups.
      const selected_program_labels = new Set(
        _program_permissions.values.map((i: Value) => i.label)
      );
      if (metaItem.values.every((i) => selected_program_labels.has(i.label))) {
        _program_permissions.has_full_access = true;
      } else {
        _program_permissions.has_full_access = false;
      }
    } else if (checkboxType === CheckboxType.SELECT_ALL) {
      _program_permissions.has_full_access = isChecked;
    }

    // Empty the values key if has_full_access as it's not needed
    if (_program_permissions.has_full_access) _program_permissions.values = [];
    _data_permissions["program_permissions"] = _program_permissions;

    if (!_newFormattedMemberDetails[_newFormattedMemberDetails.role_slug])
      _newFormattedMemberDetails[_newFormattedMemberDetails.role_slug] = {};

    _newFormattedMemberDetails[
      _newFormattedMemberDetails.role_slug
    ].data_permissions = _data_permissions;

    return _newFormattedMemberDetails;
  };

  const setSelectedWrapper = (
    metaItem: ProgramPermissionsMeta,
    isChecked: boolean,
    value?: Value,
    checkboxType: CheckboxType = CheckboxType.NORMAL
  ) => {
    setFormattedMemberDetailsDirty((state: FormattedMemberDetails) =>
      setSelected(
        metaItem,
        isChecked,
        value || { label: "", value: "" },
        checkboxType,
        state
      )
    );
  };

  const renderProgramPermissionItem = (
    metaItem: ProgramPermissionsMeta,
    item: Value
  ) => {
    return (
      <MemberDataPermissionCheckboxItem
        metaItem={metaItem}
        valueItem={item}
        key={item.label}
        setCheckboxSelected={setSelectedWrapper}
        memberDetailsForItem={
          formattedMemberDetailsDirty?.[formattedMemberDetailsDirty.role_slug]
            ?.data_permissions?.program_permissions
        }
      />
    );
  };

  const handleNext = () => {
    props.updateMemberStateAndNext({});
  };

  const renderDataPermissions = (
    item_type: string,
    item: ProgramPermissionsMeta
  ) => {
    switch (item_type) {
      case "program_permissions":
        return item.values
          .filter((item) =>
            item.label.toLowerCase().includes(searchTextItems.toLowerCase())
          )
          .map((value) => renderProgramPermissionItem(item, value));
    }
  };
  const [searchTextItems, setSearchTextItems] = useState("");

  return (
    <>
      <DialogContent sx={{ height: "100%" }}>
        {isLoading && (
          <Skeleton
            sx={{ bgcolor: "grey.100", mt: 4, borderRadius: 3 }}
            variant="rectangular"
            animation="wave"
            height={350}
          />
        )}
        {!isLoading &&
          !isError &&
          Object.entries(dataPermissions?.data || {}).map(
            ([item_type, meta], _) => (
              <Grid
                container
                direction={"column"}
                justifyContent={"space-between"}
                key={meta.value_slug}
              >
                <Grid item my={2}>
                  <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                    <TextField
                      label=""
                      id="search-programs"
                      value={searchTextItems}
                      placeholder="Search"
                      fullWidth
                      onChange={(e: any) => setSearchTextItems(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              style={{ marginLeft: 5 }}
                              height="28px"
                              width="28px"
                              src={SearchIcon}
                              alt="Search"
                            />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="start">
                            <CloseIcon onClick={() => setSearchTextItems("")} />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Grid item>
                    <Typography variant="poppins_h4_600_20">
                      Program Permissions
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    sx={{ display: searchTextItems ? "none" : "block" }}
                  >
                    <CustomizedCheckbox
                      isChecked={
                        formattedMemberDetailsDirty[
                          formattedMemberDetailsDirty.role_slug
                        ]?.data_permissions?.program_permissions
                          .has_full_access || false
                      }
                      onChange={(value: boolean) => {
                        setSelectedWrapper(
                          meta,
                          value,
                          undefined,
                          CheckboxType.SELECT_ALL
                        );
                      }}
                      labelProps={{
                        title: "Select All",
                        extraSx: {
                          variant: "inter_p_600_16",
                          color: theme.custom.primaryDarkColor,
                        },
                      }}
                    />
                  </Grid>
                  <Grid item mt={1} justifyContent={"space-between"}>
                    <Grid container justifyContent={"space-between"}>
                      {renderDataPermissions(item_type, meta)}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )
          )}
        {!isLoading && isError && renderErrorMessage()}
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Button
              sx={{ height: "50px", px: 4 }}
              onClick={() => props.loadPreviousPage()}
              variant="outlined"
              type="submit"
            >
              <Typography variant="poppins_p_600_14">Back</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{ height: "50px", px: 12 }}
              onClick={handleNext}
              variant="contained"
              type="submit"
            >
              <Typography variant="poppins_p_600_14">Continue</Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
}

export default memo(MemberDataPermissionForm);
