import {
  Modal,
  Typography,
  Box,
  Backdrop,
  Grid,
  Fade,
  InputAdornment,
  TextField,
  Skeleton,
} from "@mui/material";
import { useEffect, useState } from "react";

import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { NonRatableItem } from "../../../Types/NonRatableItemObject";
import { dateFormatter, numberFormatter } from "../../../utils/dataFormatters";
import NumberTextFieldComponent from "../../atoms/NumberTextFieldComponent";
import { theme, fontInter } from "../../../theme";
import "./NonRatableBudgetModal.scss";
import { reduce } from "lodash";
import {
  getNonRatableBudgetItem,
  updateNonRatableBudgetItem,
} from "../../../services/non-ratable-budget-service";
import { LoadingButton } from "@mui/lab";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";

type Props = {
  openModal: boolean;
  closeModal: Function;
  payload: any;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "75%",
  height: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
  outline: 0,
  p: 4,
};

const INIT_STATE = {
  nonratable: [] as NonRatableItem[],
  budget_item_id: 0 as number,
  budget_uid: "" as string,
  category_name: "" as string,
};
const NonRatableBudgetModal = (props: Props) => {
  const { openModal, closeModal, payload } = props;
  const [nonRatableBudgetItem, setNonRatableBudgetItem] =
    useState<any>(INIT_STATE);
  const [loading, setLoading] = useState<boolean>(true);
  const [total, setTotal] = useState<number>(0);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  useEffect(() => {
    findTotal();
  }, [nonRatableBudgetItem.nonratable]);

  useEffect(() => {
    if (openModal) {
      getSplitData();
    }
  }, [openModal]);

  const getSplitData = () => {
    setLoading(true);
    getNonRatableBudgetItem(payload["budget_uid"], payload["id"])
      .then((res) => {
        if (res.status === 200) {
          setNonRatableBudgetItem(res.data.data);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const findTotal = () => {
    if (
      isNaN(
        reduce(
          nonRatableBudgetItem.nonratable,
          (sum, n) => {
            return sum + +n.amount;
          },
          0
        )
      )
    )
      return;

    setTotal(
      +reduce(
        nonRatableBudgetItem.nonratable,
        (sum, n) => {
          return sum + +n.amount;
        },
        0
      ).toFixed(2)
    );
  };

  const handleClose = (event: any, reason: string) => {
    if (reason && reason === "backdropClick") return;
    closeModal();
    setNonRatableBudgetItem(INIT_STATE);
  };
  const updateValue = (value: number, index: number) => {
    let _nonRatable = [...nonRatableBudgetItem.nonratable];
    _nonRatable[index]["amount"] = value;
    setNonRatableBudgetItem((prev: any) => {
      return { ...prev, nonratable: _nonRatable };
    });
  };

  const calculateCumulative = (index: number) => {
    let sum = 0;
    for (let i = 0; i <= index; i++) {
      sum += +nonRatableBudgetItem.nonratable[i]["amount"];
    }

    return sum;
  };

  const onSave = () => {
    setSaveLoading(true);
    updateNonRatableBudgetItem(
      payload["budget_uid"],
      payload["id"],
      nonRatableBudgetItem.nonratable
    )
      .then((res) => {
        if (res && res.status === 200) {
          setSaveLoading(false);
          closeModal(true);
        }
      })
      .finally(() => {
        setSaveLoading(false);
      });
  };

  const returnSymbolBasedOnDifference = () => {
    if (total !== +payload.amount) {
      return total >= +payload.amount ? (
        <KeyboardDoubleArrowUpIcon sx={{ height: 15, width: 15 }} />
      ) : (
        <KeyboardDoubleArrowDownIcon sx={{ height: 15, width: 15 }} />
      );
    } else {
      return <span></span>;
    }
  };
  return (
    <Modal
      sx={{ zIndex: 10000 }}
      open={openModal}
      onClose={(e) => {
        return handleClose(e, "backdropClick");
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Grid className="non-ratable-modal-wrapper" container>
            <Grid textAlign="center" item xs={11}>
              <Typography
                variant="poppins_h3_600_24"
                sx={{ color: theme.custom.primaryDarkColor, pl: 12 }}
              >
                Split budget across months
              </Typography>
            </Grid>
            <Grid textAlign="right" item xs={1}>
              <CancelOutlinedIcon
                onClick={(e: any) => {
                  handleClose(e, "cancel");
                }}
                sx={{
                  color: `${theme.custom.primaryGreyColor}`,
                  cursor: "pointer",
                }}
              />
            </Grid>
            {!loading && (
              <Box overflow={"auto"}>
                <Box my={2}>
                  <Typography
                    variant="poppins_h3_600_24"
                    sx={{ color: theme.custom.primaryDarkColor }}
                  >
                    {nonRatableBudgetItem?.category_name || ""}
                  </Typography>
                </Box>
                <Box px={1} sx={{ width: "100%" }}>
                  <Box>
                    <table
                      style={{
                        border: `1px solid ${theme.dataTableColors.borderColor}`,
                        borderRadius: "6px",
                        background: theme.custom.whiteColor,
                      }}
                    >
                      <tbody>
                        <tr>
                          <td
                            style={{
                              position: "sticky",
                              left: 0,
                              background: "#fff",
                              minWidth: 100,
                              zIndex: 1,
                              height: 50,
                              borderBottom: `1px solid ${theme.custom.borderColor}`,
                              borderRight: `1px solid ${theme.custom.borderColor}`,
                            }}
                          >
                            <Box px={1.5}>
                              <Typography
                                variant="inter_p_600_14"
                                sx={{ color: theme.custom.primaryDarkColor }}
                              >
                                {" "}
                                Month
                              </Typography>
                            </Box>
                          </td>
                          {nonRatableBudgetItem.nonratable &&
                            nonRatableBudgetItem.nonratable.map(
                              (splitEle: NonRatableItem) => {
                                return (
                                  <td
                                    style={{
                                      background: "#fff",
                                      borderBottom: `1px solid ${theme.custom.borderColor}`,

                                      minWidth: 120,
                                    }}
                                  >
                                    <Box px={1}>
                                      <Typography
                                        variant="inter_p_600_14"
                                        sx={{
                                          color: theme.custom.primaryDarkColor,
                                        }}
                                      >
                                        {dateFormatter(
                                          splitEle.budget_month,
                                          "MMM YYYY"
                                        )}
                                      </Typography>
                                    </Box>
                                  </td>
                                );
                              }
                            )}
                          <td
                            style={{
                              minWidth: 150,
                              position: "sticky",
                              right: 0,
                              background: "#fff",
                              borderBottom: `1px solid ${theme.custom.borderColor}`,
                              borderLeft: `1px solid ${theme.custom.borderColor}`,
                            }}
                          >
                            <Box px={1}>
                              <Typography
                                variant="inter_p_600_14"
                                sx={{ color: theme.custom.primaryDarkColor }}
                              >
                                Total
                              </Typography>
                            </Box>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              height: 50,
                              position: "sticky",
                              left: 0,
                              background: "#F8F8F8",
                              zIndex: 1,
                              borderBottom: `1px solid ${theme.custom.borderColor}`,
                              borderRight: `1px solid ${theme.custom.borderColor}`,
                            }}
                          >
                            <Box px={1}>
                              <Typography
                                variant="inter_p_600_14"
                                sx={{ color: theme.custom.primaryDarkColor }}
                              >
                                Budget
                              </Typography>
                            </Box>
                          </td>
                          {nonRatableBudgetItem.nonratable &&
                            nonRatableBudgetItem.nonratable.map(
                              (splitEle: NonRatableItem, index: number) => {
                                return (
                                  <td
                                    style={{
                                      background: "#F8F8F8",
                                      borderBottom: `1px solid ${theme.custom.borderColor}`,
                                      padding: "3px 5px",
                                      minWidth: 150,
                                    }}
                                  >
                                    <TextField
                                      placeholder="Budget"
                                      InputProps={{
                                        startAdornment: (
                                          <InputAdornment position="start">
                                            <Typography
                                              sx={{
                                                color:
                                                  theme.custom.primaryDarkColor,
                                              }}
                                              variant="inter_p_500_14"
                                            >
                                              $
                                            </Typography>
                                          </InputAdornment>
                                        ),
                                        inputComponent:
                                          NumberTextFieldComponent,
                                      }}
                                      inputProps={{
                                        decimal: 2,
                                        min: 0,
                                        style: {
                                          fontFamily: fontInter,
                                          fontSize: "14px",
                                          fontWeight: 500,
                                          background: theme.custom.whiteColor,
                                        },
                                      }}
                                      className="curved-input"
                                      value={+splitEle.amount}
                                      onChange={(e: any) => {
                                        return updateValue(
                                          e.target.value === " "
                                            ? 0
                                            : e.target.value,
                                          index
                                        );
                                      }}
                                      size="small"
                                      fullWidth
                                      id="budget-value"
                                    />
                                  </td>
                                );
                              }
                            )}
                          <td
                            style={{
                              position: "sticky",
                              right: 0,
                              background: "#fff",
                              borderBottom: `1px solid ${theme.custom.borderColor}`,
                              borderLeft: `1px solid ${theme.custom.borderColor}`,
                            }}
                          >
                            <Box
                              px={1}
                              sx={{ display: "flex", alignItems: "center" }}
                            >
                              <Typography
                                variant="inter_400_14"
                                sx={{ color: theme.custom.primaryDarkColor, mr: 1 }}
                              >
                                {numberFormatter(total, "$", false)}
                              </Typography>
                              ( {returnSymbolBasedOnDifference()}
                              <Typography
                                variant="inter_400_14"
                                sx={{ color: theme.custom.primaryDarkColor }}
                              >
                                {numberFormatter(
                                  Math.abs(total - +payload.amount),
                                  "$",
                                  false
                                )}
                              </Typography>{" "}
                              )
                            </Box>
                          </td>
                        </tr>
                        <tr>
                          <td
                            style={{
                              position: "sticky",
                              left: 0,
                              background: "#fff",
                              zIndex: 1,
                              height: 50,
                              borderRight: `1px solid ${theme.custom.borderColor}`,
                            }}
                          >
                            <Box px={1}>
                              <Typography
                                variant="inter_p_600_14"
                                sx={{ color: theme.custom.primaryDarkColor }}
                              >
                                Cumulative
                              </Typography>
                            </Box>
                          </td>
                          {nonRatableBudgetItem.nonratable &&
                            nonRatableBudgetItem.nonratable.map(
                              (item: NonRatableItem, index: number) => {
                                return (
                                  <td
                                    style={{
                                      background: "#fff",
                                      minWidth: 120,
                                    }}
                                  >
                                    <Box px={1}>
                                      <Typography
                                        variant="inter_p_600_14"
                                        sx={{
                                          color: theme.custom.primaryDarkColor,
                                        }}
                                      >
                                        {numberFormatter(
                                          calculateCumulative(index),
                                          "$",
                                          false,
                                          2
                                        )}
                                      </Typography>
                                    </Box>
                                  </td>
                                );
                              }
                            )}
                          <td
                            style={{
                              position: "sticky",
                              right: 0,
                              background: "#fff",
                              borderLeft: `1px solid ${theme.custom.borderColor}`,
                            }}
                          >
                            <Box px={1}>
                              <Typography
                                variant="inter_400_14"
                                sx={{
                                  color: theme.custom.primaryGreyColor,
                                }}
                              >
                                Previous: &nbsp;
                                {numberFormatter(+payload.amount, "$", false)}
                              </Typography>
                            </Box>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Box>
                </Box>
              </Box>
            )}
            {!loading && (
              <Grid container mt={3} justifyContent={"right"}>
                <Grid item>
                  <LoadingButton
                    onClick={onSave}
                    loading={saveLoading}
                    sx={{ height: "50px", width: 150, textAlign: "right" }}
                    variant="contained"
                  >
                    Done
                  </LoadingButton>
                </Grid>
              </Grid>
            )}
            {loading && (
              <Skeleton
                sx={{ bgcolor: "grey.100", my: 1.5, mx: 2, borderRadius: 3 }}
                variant="rectangular"
                animation="wave"
                height={300}
                width={"100%"}
              />
            )}
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default NonRatableBudgetModal;
