import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment, memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";
import {
  defaultReportSize,
  getAudiencePurpose,
  reportSizeOptions,
} from "../../../services/impact-report-service";
import { getCompanyId } from "../../../services/userinfo-service";
import { useAppDispatch } from "../../../store/hook";
import { fontInter, theme } from "../../../theme";

interface Props {
  selectedTemplate: any;
  nextPageTrigger: Function;
  audience: string;
  setAudience: Function;
  purpose: string;
  setPurpose: Function;
  query: string;
  setQuery: Function;
}

const CreateNewImpactReport = ({
  selectedTemplate,
  nextPageTrigger,
  audience,
  setAudience,
  purpose,
  setPurpose,
  query,
  setQuery,
}: Props) => {
  const dispatch = useAppDispatch();
  const INIT_STATE = {
    name: "",
    company_uid: getCompanyId(),
    template_id: 0,
    attributes: defaultReportSize,
  };

  const [reportData, setReportData] = useState(INIT_STATE);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [reportSize, setReportSize] = useState(defaultReportSize.slug);

  const [audienceOptions, setAudienceOptions] = useState([]);
  const [purposeOptions, setPurposeOptions] = useState([]);
  const [reportThumbnail, setReportThumbnail] = useState(
    defaultReportSize.thumbnail
  );

  const handleClose = (event: any, reason: any = undefined) => {
    if (reason === "backdropClick") return;
    setErrMsg("");
  };

  useEffectOnce(() => {
    getAudiencePurpose().then((resp) => {
      if (resp && resp.data) {
        const audience =
          resp.data.filter((i: any) => i.slug === "audience") || [];
        const purpose =
          resp.data.filter((i: any) => i.slug === "purpose") || [];
        setAudienceOptions(audience);
        setPurposeOptions(purpose);
      }
    });
  });

  useEffect(() => {
    if (
      selectedTemplate &&
      selectedTemplate.id &&
      selectedTemplate.attributes
    ) {
      setReportSize(selectedTemplate.attributes.attributes.slug);
      let reportAttributes = reportSizeOptions.filter(
        (i) => i.slug == selectedTemplate.attributes.attributes.slug
      )[0];
      if (reportAttributes && reportAttributes.thumbnail) {
        setReportThumbnail(reportAttributes.thumbnail);
      }
    }
  }, [selectedTemplate]);

  const onReportSizeChange = (e: any) => {
    let reportAttributes = reportSizeOptions.filter(
      (i) => i.slug == e.target.value
    )[0];
    if (reportAttributes && reportAttributes.thumbnail) {
      setReportThumbnail(reportAttributes.thumbnail);
    }
    setReportData((prev) => {
      return {
        ...prev,
        attributes: reportAttributes || defaultReportSize,
      };
    });
    setReportSize(e.target.value);
  };

  return (
    <Fragment>
      <Box sx={{ backgroundColor: "white", p: 3 }}>
        <Grid container>
          <Grid mb={2} textAlign={"center"} item sm={12}>
            <img
              style={{ maxHeight: 170, maxWidth: 150 }}
              src={reportThumbnail}
              alt="thumbnail"
            />
          </Grid>
          <Grid mb={2} textAlign={"center"} item sm={12}>
            <Typography
              sx={{ color: theme.custom.primaryDarkColor }}
              variant="inter_400_14"
            >
              {selectedTemplate?.attributes?.title || "Blank Template"}
            </Typography>
          </Grid>
          <Grid mb={2} item sm={12}>
            <Select
              fullWidth
              value={reportSize}
              onChange={onReportSizeChange}
              disabled={
                selectedTemplate &&
                selectedTemplate.id &&
                selectedTemplate.attributes
              }
            >
              {reportSizeOptions.map((option) => {
                let _value: any = {
                  height: option.height,
                  width: option.width,
                };
                return (
                  <MenuItem key={option.slug} value={option.slug}>
                    <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                      {option.label}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid mb={2} item sm={12}>
            <Grid my={2} item sm={12}>
              <TextField
                select
                fullWidth
                label="Audience"
                value={audience}
                onChange={(e) => setAudience(e.target.value)}
                disabled={
                  selectedTemplate &&
                  selectedTemplate.id &&
                  selectedTemplate.attributes
                }
              >
                <MenuItem key={""} value={""}>
                  <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                    -----
                  </Typography>
                </MenuItem>
                {audienceOptions.map((meta: any) => {
                  return (
                    <MenuItem key={meta.option_value} value={meta.option_value}>
                      <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                        {meta.option_label}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <Grid my={2} item sm={12}>
              <TextField
                fullWidth
                select
                label="Purpose"
                value={purpose}
                onChange={(e) => setPurpose(e.target.value)}
                disabled={
                  selectedTemplate &&
                  selectedTemplate.id &&
                  selectedTemplate.attributes
                }
              >
                <MenuItem key={""} value={""}>
                  <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                    -----
                  </Typography>
                </MenuItem>
                {purposeOptions.map((meta: any) => {
                  return (
                    <MenuItem key={meta.option_value} value={meta.option_value}>
                      <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                        {meta.option_label}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </TextField>
            </Grid>
            <TextField
              fullWidth
              label="Query"
              variant="outlined"
              multiline
              size="medium"
              value={query}
              rows={3}
              sx={{
                borderRadius: "6px",
                mb: 3,
              }}
              onKeyPress={(ev: any) => {
                if (ev.key === "Enter") {
                  ev.preventDefault();
                }
              }}
              onChange={(e) => setQuery(e.target.value)}
              inputProps={{
                style: {
                  background: theme.custom.whiteColor,
                  fontFamily: fontInter,
                  fontSize: "14px",
                  fontWeight: 500,
                },
                maxLength: 200,
              }}
            />
            {errMsg && errMsg.length > 0 && (
              <Typography
                variant="inter_400_14"
                sx={{ color: theme.custom.errorColor }}
              >
                {errMsg}
              </Typography>
            )}
          </Grid>

          <Grid mb={2} item sm={12}>
            <LoadingButton
              sx={{ height: 50 }}
              disabled={errMsg.length > 0}
              variant="contained"
              fullWidth
              loading={loading}
              onClick={() => nextPageTrigger()}
            >
              Create
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Fragment>
  );
};

export default memo(CreateNewImpactReport);
