import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  axisLabelStyle,
  chartColors,
  chartLabelsStyle,
  theme,
  tooltipColors,
} from "../../theme";
import {
  numberFormatter,
  labelNumberFormatter,
  numberFormatterWithoutDecimals,
} from "../../utils/dataFormatters";
import { returnFileName } from "../../utils/file-name-helper";
import { ShadowColumnDataObject } from "../../Types/ShadowColumnDataObject";
import moment from "moment";
import { ChartProps } from "../../Types/ChartProps";

const returnShadowColumnChartData = (
  array: ShadowColumnDataObject[],
  name: string
) => {
  return array.map((ele: any, index: number) => {
    return {
      name: ele["x-axis-label"],
      color: name === "prev-value" ? "grey" : chartColors.reverse()[index],
      y: ele[name],
    };
  });
};

const returnCategoriesForShadowChart = (
  seriesArr: ShadowColumnDataObject[]
) => {
  return seriesArr.map((ele) => {
    return ele["x-axis-label"];
  });
};

const ShadowColumnChart: React.FC<ChartProps> = (chartData) => {
  let data = chartData.chartData;
  let meta_data = chartData.chartMeta;
  let data_type = meta_data?.chart?.currencytype || "$";
  let date_range = chartData.dateRange;
  const height = chartData.height;
  const width = chartData.width;
  const editorMode = chartData.editorMode;
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  let disableDataLabels = meta_data.chart["data-label-disabled"] || false;
  let removeDecimal = meta_data.chart["remove-decimal"] || false;

  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "column",
      zoomType: editorMode ? "" : "xy",
      events: {
        render: function () {
          chartData.kpiRendered(true);
          chartData.chartReference(chartComponentRef);
          if (chartData.customKPI === false) {
            chartComponentRef.current?.chart.reflow();
          }
        },
      },
    },
    title: {
      text: "",
    },
    tooltip: {
      shared: true,
      backgroundColor: tooltipColors.bgColor,
      borderColor: tooltipColors.borderColor,
      color: tooltipColors.dataFormatColor,
      padding: 10,
      borderRadius: 5,
      borderWidth: 1,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        let str = "";
        str =
          str +
          `<span style="color: ${theme.custom.whiteColor}">${this.x}</span><br/>`;
        this.points?.forEach((ele) => {
          str =
            str +
            `<span style="color: ${theme.custom.whiteColor}">
         ${ele["series"]["name"]}:  ${labelNumberFormatter(ele.point.y || 0, "$")}
         </span></br>`;
        });

        return str;
      },
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    xAxis: {
      categories: returnCategoriesForShadowChart(data),
      labels: {
        formatter: function (this: any) {
          return `<span style="${chartLabelsStyle}">${this.value}</span>`;
        },
      },
      title: {
        text: meta_data.chart?.["x-axis-text"] || "",
        style: axisLabelStyle,
        margin: 20,
      },
    },
    yAxis: [
      {
        title: {
          text: meta_data.chart?.["y-axis-text"] || "",
          style: axisLabelStyle,
          margin: 20,
        },
        showFirstLabel: false,
        labels: {
          formatter: function (this: any) {
            return `<span style="${chartLabelsStyle}">${labelNumberFormatter(
              this.value,
              data_type,
              true
            )}</span>`;
          },
        },
      },
    ],
    plotOptions: {
      series: {
        grouping: false,
        borderWidth: 0,
        borderRadiusTopLeft: "10%",
        borderRadiusTopRight: "10%",
      },
    },
    legend: {
      enabled: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      filename: returnFileName(
        meta_data?.chart?.title || "",
        date_range
          ? date_range
          : {
              start: moment().format("YYYY-MM-DD"),
              end: moment().format("YYYY-MM-DD"),
            }
      ),
    },
    series: [
      {
        pointPlacement: -0.2,
        linkedTo: "main",
        data: returnShadowColumnChartData(data, "prev-value"),
        name: meta_data.chart?.["previous-value-label"] || "",
      },
      {
        name: meta_data.chart?.["current-value-label"] || "",
        id: "main",
        dataLabels: [
          {
            enabled: !disableDataLabels,
            formatter: function (this: any) {
              return removeDecimal
                ? `<span style="font-size: 10px">${numberFormatter(
                    this.y,
                    data_type,
                    true
                  )}</span>`
                : `<span style="font-size: 10px">${numberFormatterWithoutDecimals(
                    this.y,
                    data_type,
                    true
                  )}</span>`;
            },
            inside: true,
            style: {
              fontSize: "16px",
            },
          },
        ],
        data: returnShadowColumnChartData(data, "curr-value"), //getData(tempData, "curr-value")
      },
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{
        style: {
          width: "100%",
          height: "calc(100% - 1px)",
        },
      }}
      options={
        editorMode
          ? {
              ...options,
              ...{ chart: { ...options.chart, height: height, width: width } },
            }
          : options
      }
      ref={chartComponentRef}
    ></HighchartsReact>
  );
};

export default ShadowColumnChart;
