import { createSlice } from "@reduxjs/toolkit";

const chipResetHandler = createSlice({
  name: "filter-reset-state-handler",
  initialState: {
    reset: false,
  },
  reducers: {
    resetFilter(state) {
      state.reset = !state.reset;
    },
  },
});

export const { resetFilter } = chipResetHandler.actions;
export default chipResetHandler.reducer;