import styled from "@emotion/styled";
import { List } from "@mui/material";
import { theme } from "../theme";

export const StyledList = styled(List)({
    // selected and (selected + hover) states
    "&& .Mui-selected, && .Mui-selected:hover": {
      backgroundColor: theme.sidebar.menuItemBackgroundColor,
      color: theme.palette.primary.main,
    },
    // hover states
    "& .MuiListItemButton-root:hover": {
      backgroundColor: theme.sidebar.menuItemBackgroundColor,
    },
  });