import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { LoadingButton } from "@mui/lab";
import range from "lodash/range";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  Icon,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Modal,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { listGoogleSheetsFromDrive } from "../../../services/connector-service";
import { addNewGoogleSheet } from "../../../services/googlesheets-connection-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import { theme } from "../../../theme";
import GoogleDriveIcon from "../../../assets/google_drive_icon.png";
import GoogleSheetIcon from "../../../assets/googlesheet.png";
import ExcelIcon from "../../../assets/excel.png";
import moment from "moment";
import { useDebounce } from "react-use";
import SearchIcon from "../../../assets/search.png";
import { useNavigate } from "react-router-dom";

type Props = {
  extraSx: any;
  refresh: Function;
  owner: string;
};

const style = {
  position: "absolute" as "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -30%)",
  width: 850,
  height: 680,
  bgcolor: theme.custom.whiteColor,
  border: `1px solid ${theme.custom.KPIMoleculeBorderColor}`,
  borderRadius: "6px",
  px: 4,
  py: 6,
};

const GoogleSheetConnectorFileAdd = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { extraSx, refresh } = props;
  const [open, setOpen] = useState(false);
  const [googleSheetId, setGoogleSheetId] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [sheetsLoaded, setSheetsLoaded] = useState(false);
  const [nextPageToken, setNextPageToken] = useState("");
  const [sheetList, setSheetList] = useState<any[]>([]);
  const [searchText, setSearchText] = useState("");

  const closeModal = () => {
    setGoogleSheetId("");
    setOpen(false);
    setSearchText("");
  };

  useEffect(() => {
    loadPages();
  }, []);

  const openModal = () => {
    setOpen(true);
  };

  const [, cancel] = useDebounce(
    () => {
      setSearchText(searchText);
      searchAndLoad(searchText);
    },
    1000,
    [searchText]
  );

  const searchAndLoad = (searchText: string) => {
    setNextPageToken("");
    setSheetList([]);
    setSheetsLoaded(false);
    loadPages("", searchText, true);
  };

  const addWorkbook = () => {
    setLoading(true);
    if (googleSheetId.length > 0) {
      let requestBody = {
        file_id: googleSheetId,
      };
      addNewGoogleSheet(requestBody).then((res) => {
        if (res.status === 200) {
          dispatch(
            showMessage({
              message: "Google sheet added successfully",
              variant: "success",
            })
          );
          refresh();
          closeModal();
        }
        if (res.status === 400) {
          dispatch(showMessage({ message: res.data.message }));
        }
        setLoading(false);
      });
    }
  };

  const loadPages = (
    _nextPageToken: string = nextPageToken,
    searchText: string = "",
    initSheets: boolean = false
  ) => {
    listGoogleSheetsFromDrive("googlesheet", _nextPageToken, searchText).then(
      (resp: any) => {
        if (resp.status === 200 && resp.data) {
          if (initSheets) {
            setSheetList(resp.data.files || []);
          } else {
            setSheetList([...sheetList, ...(resp.data.files || [])]);
          }
          setNextPageToken(resp.data.nextPageToken || "");
          setSheetsLoaded(true);
        } else if (resp.status === 403) {
          dispatch(
            showMessage({ message: "You need to reconnect to Googlesheet" })
          );
          navigate("/connectors/list");
        } else {
          dispatch(showMessage({ message: "Sorry something went wrong" }));
        }
      }
    );
  };

  return (
    <>
      <Button
        variant={extraSx.variant}
        sx={{ ...extraSx.sx }}
        onClick={openModal}
      >
        Add new
      </Button>
      <Modal
        sx={{ position: "absolute", zIndex: 2500 }}
        open={open}
        onBackdropClick={closeModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Grid container mb={3.5} justifyContent={"space-between"}>
              <Grid item>
                <Typography
                  sx={{ color: `${theme.custom.primaryDarkColor}` }}
                  variant="poppins_h3_600_24"
                >
                  Import file
                </Typography>
              </Grid>
              <Grid item>
                <CancelOutlinedIcon
                  onClick={closeModal}
                  sx={{
                    color: `${theme.custom.primaryGreyColor}`,
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{
                backgroundColor: `${theme.custom.profileBackgroundColor}`,
                p: 2,
                borderRadius: "6px",
                mb: 2,
              }}
            >
              <Grid>
                <Stack direction={"row"} alignItems={"center"}>
                  <img
                    src={GoogleDriveIcon}
                    height="32px"
                    width="32px"
                    alt="google-drive-icon"
                  ></img>
                  <Typography sx={{ ml: 1 }} variant="inter60016">
                    Google Drive™
                  </Typography>
                </Stack>
              </Grid>
              <Grid>
                <Typography variant="inter_p_400_16">{props.owner}</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12}>
                <TextField
                  placeholder="Search"
                  focused
                  variant="outlined"
                  fullWidth
                  sx={{
                    "& label.Mui-focused": {
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "gray",
                      },
                      "&:hover fieldset": {
                        borderColor: "gray",
                        borderWidth: "0.15rem",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "gray",
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          style={{ marginLeft: 5 }}
                          height="24px"
                          width="24px"
                          src={SearchIcon}
                          alt="Search"
                        />
                      </InputAdornment>
                    ),
                  }}
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid container justifyContent={"space-between"} mx={2} mb={1}>
                <Grid item>
                  <Typography variant="inter_500_12">File name</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="inter_500_12">Owned by</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="inter_500_12">Last opened</Typography>
                </Grid>
              </Grid>

              <Box
                sx={{
                  width: "100%",
                  overflowY: "scroll",
                  height: "300px",
                }}
                id="scrollTarget"
              >
                <InfiniteScroll
                  dataLength={sheetList.length}
                  next={loadPages}
                  hasMore={nextPageToken !== ""}
                  loader={
                    <p style={{ textAlign: "center" }}>
                      {
                        <Typography
                          sx={{ color: theme.custom.primaryGreyColor }}
                          variant="inter_p_600_16"
                        >
                          Loading...
                        </Typography>
                      }
                    </p>
                  }
                  scrollableTarget="scrollTarget"
                  endMessage={
                    <p style={{ textAlign: "center" }}>
                      {sheetsLoaded && (
                        <Typography
                          sx={{ color: theme.custom.primaryGreyColor }}
                          variant="inter_p_600_16"
                        >
                          No more reports to load.
                        </Typography>
                      )}
                    </p>
                  }
                >
                  {!sheetsLoaded
                    ? range(6).map((num) => (
                        <Skeleton
                          key={num}
                          sx={{ m: 2, bgcolor: "grey.100" }}
                          variant="rectangular"
                          animation="wave"
                          height={40}
                        />
                      ))
                    : ""}
                  <Grid xs={12}>
                    <List>
                      {sheetList.map((file) => (
                        <ListItemButton
                          sx={{
                            "&.Mui-selected": {
                              backgroundColor: "#7F7CFF",
                            },
                            "&.Mui-focusVisible": {
                              backgroundColor: "#7F7CFF",
                            },
                          }}
                          key={file.id}
                          selected={googleSheetId === file.id}
                          onClick={() => setGoogleSheetId(file.id)}
                        >
                          <Grid
                            container
                            justifyContent={"space-between"}
                            alignItems="center"
                          >
                            <Grid item xs={4}>
                              <ListItemText>
                                <Tooltip title={file.name}>
                                  <Stack
                                    direction={"row"}
                                    alignItems={"center"}
                                  >
                                    <img
                                      alt="spreadsheet-icon"
                                      src={
                                        file.mimeType ===
                                        "application/vnd.google-apps.spreadsheet"
                                          ? GoogleSheetIcon
                                          : ExcelIcon
                                      }
                                      height={22}
                                      width={16}
                                    ></img>
                                    <Typography
                                      variant="inter_400_14"
                                      sx={{
                                        ml: 1,
                                        mt: 0.5,
                                        textOverflow: "ellipsis",
                                        maxWidth: "264px",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      {file.name}
                                    </Typography>
                                  </Stack>
                                </Tooltip>
                              </ListItemText>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: "center" }}>
                              <ListItemText>
                                <Typography variant="inter_400_14">
                                  {file.owners[0].displayName}
                                </Typography>
                              </ListItemText>
                            </Grid>
                            <Grid item xs={4} sx={{ textAlign: "end" }}>
                              <ListItemText>
                                <Typography variant="inter_400_14">
                                  {moment(file.viewedByMeTime).fromNow()}
                                </Typography>
                              </ListItemText>
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      ))}
                    </List>
                  </Grid>
                </InfiniteScroll>
              </Box>
            </Grid>
            <Divider />
            <Grid container mt={2} alignItems="center" justifyContent={"end"}>
              <Grid justifyContent={"center"}>
                <Button onClick={closeModal} sx={{ height: 50, width: 120 }}>
                  <Typography
                    variant="inter60016"
                    sx={{ color: "primary.main", cursor: "pointer" }}
                  >
                    Cancel
                  </Typography>
                </Button>
              </Grid>
              <Grid>
                <LoadingButton
                  loading={loading}
                  onClick={addWorkbook}
                  disabled={googleSheetId.length === 0 ? true : false}
                  sx={{ height: 50 }}
                  variant="contained"
                >
                  <img
                    src={GoogleDriveIcon}
                    height="32px"
                    width="32px"
                    alt="google-drive-icon"
                  ></img>
                  <Typography
                    variant="poppins_p_600_14"
                    sx={{ ml: 1, textTransform: "none" }}
                  >
                    Sync with Google Drive
                  </Typography>
                </LoadingButton>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default React.memo(GoogleSheetConnectorFileAdd);
