import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { theme } from "../theme";
import { Sidebar } from "../components/complexes/Sidebar";
import AddBudgetItemFilter from "../components/complexes/AddBudgetItemFilter";
import BudgetTableComplex from "../components/complexes/BudgetTableComplex";
import {
  editCustomBudgetType,
  fetchBudgetDetailsForBudgetId,
  fetchDataCarts,
  getAllBudgetTypes,
  getAllContractsForProgram,
  getEmployeeList,
} from "../services/budget-service";
import { BudgetItem } from "../Types/BudgetDetailsItem";
import { EmployeeObject } from "../Types/EmployeeObject";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { CustomBudgetType } from "../Types/CustomBudgetType";
import { find, findIndex, some, sortBy } from "lodash";
import { useAppDispatch } from "../store/hook";
import { showMessage } from "../store/error-handler-store";
import { ContractObject } from "../Types/ContractObject";
import BudgetDocumentsMolecule from "../components/molecules/BudgetDocumentsMolecule";
import SplitPane, { Pane, SashContent } from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';
import SliderIcon from "../assets/slider.png";

const FILTERS_INIT_STATE = {
  active: false,
  budget_uid: "",
  end_date: "",
  name: "",
  ref_program_id: "",
  ref_program_name: "",
  start_date: "",
  ref_contract_id: "",
  ref_contract_title: "",
};

const EditBudgetPage: React.FC<any> = () => {
  const navigate = useNavigate();
  const INIT_STATE = {
    is_editable: false,
    budget_uid: "",
    budgetTypes: [] as any,
  };

  const { pathname } = useLocation();
  const [dataCarts, setDataCarts] = useState<any>({});
  const [dataForFilters, setDataForFilters] = useState(FILTERS_INIT_STATE);
  const [budgetData, setBudgetData] = useState<any>(INIT_STATE);
  const [budgetTotal, setBudgetTotal] = useState([]);
  const [employeeList, setEmployeeList] = useState<EmployeeObject[]>([]);
  const [authorized, setAuthorized] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [allCustomBudgetTypes, setAllCustomBudgetTypes] = useState<
    CustomBudgetType[]
  >([]);
  const selectedTabRef = useRef<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDocumentsPane, setShowDocumentsPane] = useState<boolean>(false);

  const [contractsForProgram, setContractsForProgram] = useState(
    [] as ContractObject[]
  );
  const [sizes, setSizes] = useState<(number | string)[]>(["60%", "40%"]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    getBudgetDetails(pathname.split("_")[1]);
  }, [pathname]);

  useEffect(() => {
    getAllEmployees();
    getDataCarts();
    getBudgetTypes();
  }, []);

  const getBudgetTypes = () => {
    getAllBudgetTypes().then((res) => {
      if (res.status === 200) {
        if (res.data.data && res.data.data.length) {
          setAllCustomBudgetTypes(res.data.data);
        }
      }
    });
  };
  const getAllEmployees = () => {
    getEmployeeList().then((res) => {
      setEmployeeList(res?.data?.data);
    });
  };

  const getDataCarts = () => {
    fetchDataCarts().then((res) => {
      if (res.status === 403) {
        setAuthorized(false);
        return;
      }
      if (res?.data?.data) {
        setDataCarts(res?.data?.data);
      }
    });
  };

  const getContractsForProgram = (program: string) => {
    getAllContractsForProgram(program).then((res) => {
      if (res.status === 200) {
        setContractsForProgram(res.data?.data?.contract || []);
      }
    });
  };

  const getBudgetDetails = (budget_uid: string) => {
    fetchBudgetDetailsForBudgetId(budget_uid).then((res) => {
      setLoading(true);

      if (res.status === 403) {
        setAuthorized(false);
        setErrorMessage(
          "You don’t have access to this budget. Please reach out to admin to get access"
        );
        return;
      }
      if (res.status === 500) {
        setAuthorized(false);
        setErrorMessage(
          "Something went wrong. Please ensure you are opening a valid budget and you have access to edit it"
        );
        return;
      }

      document.title = res.data.data.name + " - Pebble";
      if (res?.data?.data) {
        let _budgetDetails = res.data.data;

        let _budgetData = {
          is_editable: res?.data?.data?.is_editable,
          budget_uid: res?.data?.data?.budget_uid,
          budgetTypes: [],
        };

        let arr: any = [];
        if (_budgetDetails["budget_type_details"]) {
          Object.keys(_budgetDetails["budget_type_details"]).forEach(
            (ele: any) => {
              arr.push({
                ..._budgetDetails["budget_type_details"][ele],
                slug: ele,
              });
            }
          );
        }

        _budgetData = {
          ..._budgetData,
          budgetTypes: sortBy(arr, "position") as any,
        };

        setBudgetData(_budgetData);
        // getContractsForProgram(res?.data?.data?.ref_program_id);
        setDataForFilters((prev: any) => {
          return {
            ...prev,
            name: res?.data?.data?.name,
            active: res?.data?.data?.active,
            budget_uid: res?.data?.data?.budget_uid,
            end_date: res?.data?.data?.end_date,
            start_date: res?.data?.data?.start_date,
            ref_program_id: res?.data?.data?.ref_program_id,
            ref_program_name: res?.data?.data?.ref_program_name,
            budget_documents_count: res?.data?.data?.budget_documents_count,
            ref_contract_id: res?.data?.data?.ref_contract_id,
            ref_contract_title: res?.data?.data?.ref_contract_title,
          };
        });

        let _budgetTypes: any = [..._budgetData.budgetTypes];
        if (!_budgetTypes.length) return;
        let _total: any = [];

        if (_budgetTypes && _budgetTypes.length) {
          _budgetTypes.forEach((type: any, index: number) => {
            _total.push({
              label: type["label"],
              total: calculateTotal(type.items),
            });
          });

          setBudgetTotal(_total);
        }
        setLoading(false);
      }
    });
  };

  const calculateTotal = (arr: BudgetItem[]) => {
    return arr.reduce((total, obj) => +obj.amount + total, 0);
  };

  const refreshBudget = (index: number) => {
    selectedTabRef.current = index;
    getBudgetDetails(budgetData.budget_uid);
  };

  const navigationHelper = () => {
    navigate("/budgets");
  };

  const updateCustomBudgetTypesList = (
    customBudgetTypeItem: CustomBudgetType,
    currentTabName: string,
    index: number,
    isEdit: boolean = false
  ) => {
    if (!customBudgetTypeItem) {
      return;
    }
    let _customBudgetTypes = [...allCustomBudgetTypes];
    if (!find(_customBudgetTypes, customBudgetTypeItem)) {
      _customBudgetTypes.push(customBudgetTypeItem);
      setAllCustomBudgetTypes(_customBudgetTypes);
    }

    let _budgetTypes = [...budgetData.budgetTypes];

    if (isEdit) {
      if (_budgetTypes) {
        if (
          _budgetTypes &&
          _budgetTypes[index] &&
          _budgetTypes[index]["items"] &&
          _budgetTypes[index]["items"].length
        ) {
          selectedTabRef.current = index;
          setLoading(true);
          editCustomBudgetType(
            { oldValue: currentTabName, newValue: customBudgetTypeItem.slug },
            budgetData.budget_uid
          ).then((res) => {
            if (res.status === 200) {
              getBudgetDetails(budgetData.budget_uid);
              dispatch(
                showMessage({
                  message: "Budget type updated successfully",
                  variant: "success",
                })
              );
            }
          });
        } else {
          _budgetTypes[index] = {
            ..._budgetTypes[index],
            slug: customBudgetTypeItem.slug,
            label: customBudgetTypeItem.label,
          };

          setBudgetData((prev: any) => {
            return { ...prev, budgetTypes: _budgetTypes };
          });
          dispatch(
            showMessage({
              message: "Budget type updated successfully",
              variant: "success",
            })
          );
          return;
        }
      }
    } else {
      if (
        !some(_budgetTypes, (ele) => ele.slug === customBudgetTypeItem.slug)
      ) {
        let sortedArr = sortBy(_budgetTypes, "position")[
          _budgetTypes.length - 1
        ]["position"];
        let _obj: any = {
          slug: customBudgetTypeItem.slug,
          label: customBudgetTypeItem.label,
          items: [],
          position: sortedArr + 1,
        };

        _budgetTypes.push(_obj);
        setBudgetData((prev: any) => {
          return { ...prev, budgetTypes: _budgetTypes };
        });
      } else {
        dispatch(
          showMessage({
            message: "Custom type already exists.",
          })
        );
        selectedTabRef.current = findIndex(
          _budgetTypes,
          (ele) => ele.slug === customBudgetTypeItem.slug
        );
        return;
      }

      selectedTabRef.current = _budgetTypes.length - 1;
      dispatch(
        showMessage({
          message: "Custom type added successfully",
          variant: "success",
        })
      );
    }

    setBudgetData((prev: any) => {
      return { ...prev, budgetTypes: _budgetTypes };
    });
  };

  const deleteCustomType = (index: number) => {
    if (index) {
      let _budgetTypes = [...budgetData.budgetTypes];
      let _total: any = [];
      _budgetTypes.splice(index, 1);

      setBudgetData((prev: any) => {
        return { ...prev, budgetTypes: _budgetTypes };
      });

      if (_budgetTypes && _budgetTypes.length) {
        _budgetTypes.forEach((type: any, index: number) => {
          _total.push({
            label: type["label"],
            total: calculateTotal(type.items),
          });
        });

        setBudgetTotal(_total);
      }
    }
    dispatch(
      showMessage({
        message: "Custom budget type deleted successfully",
        variant: "success",
      })
    );
  };

  const toggleDocumentsPane = () => {
    if (showDocumentsPane)
      setShowDocumentsPane(false)
    else
      setShowDocumentsPane(true)
  }

  const budgetPane =
    <Stack direction="row">
      <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
        <Sidebar />
      </Box>
      <Grid container xs={showDocumentsPane ? 11 : 12}>
        <Grid item xs={12}>
          <Box sx={{ display: "flex" }} mt={10} px={6}>
            <ArrowBackIcon
              onClick={navigationHelper}
              sx={{ mt: 0.4, mr: 1, cursor: "pointer" }}
            />
            <Typography variant="poppins_h4_600_20">Edit Budget</Typography>
          </Box>
          {authorized && (
            <Grid item xs={12}>
              <Box px={4}>
                <AddBudgetItemFilter
                  program={dataCarts?.program}
                  contract={contractsForProgram}
                  is_editable={budgetData.is_editable}
                  filters={dataForFilters}
                  budgetTotal={budgetTotal}
                  budgetId={budgetData.budget_uid}
                  toggleDocumentsPane={toggleDocumentsPane}
                />
              </Box>
            </Grid>
          )}

          {authorized && loading && (
            <Grid item sm={12}>
              <Box px={6}>
                <Skeleton
                  sx={{ bgcolor: "grey.200", borderRadius: 2 }}
                  variant="rectangular"
                  width="100%"
                  height={400}
                />
              </Box>
            </Grid>
          )}
          {authorized && !loading && (
            <Grid item xs={12}>
              <Box px={6}>
                <BudgetTableComplex
                  tabToBeSelected={selectedTabRef.current}
                  loading={loading}
                  customBudgetTypesList={allCustomBudgetTypes}
                  updateCustomBudgetTypesList={updateCustomBudgetTypesList}
                  employeeList={employeeList}
                  budgetDetails={budgetData.budgetTypes}
                  datacarts={dataCarts}
                  is_editable={budgetData.is_editable}
                  budget_uid={budgetData.budget_uid}
                  deleteCustomBudgetType={deleteCustomType}
                  refreshBudget={refreshBudget}
                />
              </Box>
            </Grid>
          )}

          {!authorized && (
            <Grid item xs={12}>
              <Box py={3} px={6}>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    background: theme.custom.bgImageColor,
                    minHeight: "250px",
                    borderRadius: 3,
                  }}
                >
                  <Grid item>
                    <Typography variant="inter_p_500_14">
                      {errorMessage}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Stack>

  return (
    <React.Fragment>
      { showDocumentsPane ?
        <div style={{height: "calc(100vh - 64px)"}}>
          <SplitPane
            split='vertical'
            sizes={sizes}
            onChange={setSizes}
            resizerSize={10}
            sashRender={() => (
              // @ts-ignore
              <SashContent className="action-sash-wrap">
                {/* @ts-ignore */}
                <span className="action" style={{
                  position: "absolute",
                  top: "50%",
                  left: "-22px"
                }}>
                  <img src={SliderIcon} draggable={false}/>
                </span>
              </SashContent>
            )}
          >
            <Pane minSize={750}>
              { budgetPane }
            </Pane>
            <Pane style={{marginTop: "125px"}} minSize={450}>
              <Box>
                <BudgetDocumentsMolecule
                  open={true}
                  close={() => { setShowDocumentsPane(false); }}
                  budget_uid={budgetData.budget_uid}
                  modal={false}
                />
              </Box>
            </Pane>
          </SplitPane>
        </div> : budgetPane
      }
    </React.Fragment>
  );
};
export default React.memo(EditBudgetPage);
