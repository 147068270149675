import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import ImageLibrary from "./ImageLibrary";
import VideoLibrary from "./VideoLibrary";

function MediaLibrary(props: any) {
  const [isVideoSelected, setIsVideoSelected] = React.useState<boolean | null>(
    false
  );
  const toggleVideo = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: boolean | null
  ) => {
    setIsVideoSelected(newAlignment);
  };
  return (
    <Box pt={2}>
      <ToggleButtonGroup
        value={isVideoSelected}
        exclusive
        fullWidth
        onChange={toggleVideo}
      >
        <ToggleButton value={false}>Image</ToggleButton>
        <ToggleButton value={true}>Video</ToggleButton>
      </ToggleButtonGroup>

      {isVideoSelected ? (
        <VideoLibrary addElement={props.addElement} clearClipBoard={props.clearClipBoard} />
      ) : (
        <ImageLibrary addElement={props.addElement} />
      )}
    </Box>
  );
}

export default React.memo(MediaLibrary);
