import {
  Typography,
  Box,
  Grid,
  Tooltip,
  Stack,
  IconButton,
} from "@mui/material";
import { theme } from "../../../theme";
import React, { useEffect, useRef, useState } from "react";
import { getBrandLibrary } from "../../../services/impact-report-service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import BrandColorLibrary from "../../molecules/Editor/BrandColorLibrary";
import BrandPopularMediaLibrary from "../../molecules/Editor/BrandPopularMediaLibrary";
import BrandMediaLibrary from "../../molecules/Editor/BrandMediaLibrary";

function BrandLibrary(props: any) {
  let pageRef = useRef({ popular: 1, search: 1, uploaded: 1 });
  const [brandImageList, setBrandImageList] = useState([] as any);
  const [brandColors, setBrandColors] = useState([] as any);
  const [brandLibraryType, setBrandLibraryType] = useState("");

  useEffect(() => {
    loadBrandLibrary();
  }, []);

  const pickBrandLibraryType = () => {
    switch (brandLibraryType) {
      case "Media":
        return (
          <>
            <BrandMediaLibrary addElement={props.addElement} />
          </>
        );
      default:
        break;
    }
  };

  const loadBrandLibrary = () => {
    getBrandLibrary().then((resp) => {
      if (resp.status === 201 || resp.status === 200) {
        if (pageRef.current.uploaded === 1) {
          setBrandImageList(resp.data.media);
          setBrandColors(resp.data.colors);
        } else {
          setBrandImageList([...brandImageList, ...resp.data.results]);
        }
      }
    });
  };

  const seeAllEvent = (type: string) => {
    setBrandLibraryType(type);
    if (type == "") loadBrandLibrary();
  };

  return (
    <>
      <Box pt={2}>
        {brandLibraryType ? (
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item alignItems={"center"} mb={1}>
              <Stack direction="row" alignItems="center">
                <IconButton onClick={() => seeAllEvent("")} aria-label="back">
                  <ArrowBackIcon
                    sx={{ color: theme.custom.primaryGreyColor }}
                  />
                </IconButton>
                <div>
                  <Tooltip title={brandLibraryType}>
                    <Typography
                      sx={{
                        color: theme.custom.primaryDarkColor,
                        maxWidth: 150,
                      }}
                      variant="inter_p_400_16"
                      noWrap
                    >
                      {brandLibraryType}
                    </Typography>
                  </Tooltip>
                </div>
              </Stack>
            </Grid>
            <Box width={"100%"}>{pickBrandLibraryType()}</Box>
          </Grid>
        ) : (
          <>
            <Grid container mt={2} alignItems="center">
              <BrandPopularMediaLibrary
                brandImageList={brandImageList}
                seeAllEvent={seeAllEvent}
                loadBrandImages={loadBrandLibrary}
                addElement={props.addElement}
              ></BrandPopularMediaLibrary>
              <BrandColorLibrary
                brandColors={brandColors}
                setBrandColors={setBrandColors}
              ></BrandColorLibrary>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
}

export default React.memo(BrandLibrary);
