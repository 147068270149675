import { Box } from "@mui/material";
import moment from "moment";
import { ReactElement } from "react";

/*
  This method returns the years 20 years lesser than current year and 3 years greater than current year
*/
export const returnYears = (): ReactElement[] => {
  let years = [];
  for (let i = moment().year() - 20; i <= moment().year() + 3; i++) {
    years.push(<option value={i}>{i}</option>);
  }
  return years;
};

export const renderMonthElement = ({
  month,
  onMonthSelect,
  onYearSelect,
}: any) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box>
        <select
          className="custom-date-dropdown"
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label, value) => {
            return <option value={value}>{label.slice(0, 3)}</option>;
          })}
        </select>
      </Box>
      <Box>
        <select
          className="custom-date-dropdown"
          value={month.year()}
          onChange={(e) => onYearSelect(month, e.target.value)}
        >
          {returnYears()}
        </select>
      </Box>
    </Box>
  );
};
