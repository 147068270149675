import React, { useState } from "react";
import { pdfjs, Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { Grid, Typography } from "@mui/material";
import { theme } from "../../theme";
import Back from "../../assets/fi-rr-arrow-small-left.png";
import Download from "../../assets/fi-rr-download 1.png";
import "../../styles/PdfViewer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

type Props = {
  file?: string;
  style?: object;
  name?: string;
  uid?: string;
  backButtonCallback: Function;
  downloadButtonCallback: Function;
  nonModal?: boolean;
};

const PdfViewer = (props: Props) => {
  const [file, setFile] = useState(props.file);
  const [name, setName] = useState(props.name);
  const [numPages, setNumPages] = useState(null);
  const { uid, backButtonCallback, downloadButtonCallback, nonModal } = props;

  const onDocumentLoadSuccess = ({ numPages: nextNumPages }: any) => {
    setNumPages(nextNumPages);
  };

  return name && file && uid ? (
    <Grid container mt={1} mb={1} style={props.style || {}}>
      {name ? (
        <>
          <Grid textAlign={"left"} item xs={1}>
            <Typography
              sx={{
                color: `${theme.custom.primaryDarkColor}`,
                verticalAlign: "middle",
              }}
              variant="h4"
            >
              <img
                style={{ cursor: "pointer" }}
                height={30}
                width={30}
                alt="Go back"
                src={Back}
                onClick={() => backButtonCallback()}
              />
            </Typography>
          </Grid>
          <Grid textAlign={"left"} mt={1} item xs={9}>
            <Typography
              title={name}
              sx={{ color: `${theme.custom.primaryDarkColor}` }}
              variant="inter_p_400_16"
              style={{
                maxWidth: "200px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                whiteSpace: "nowrap",
                display: "inline-block",
              }}
            >
              {name}
            </Typography>
          </Grid>
          <Grid textAlign={"right"} xs={2}>
            <img
              onClick={() => downloadButtonCallback(uid)}
              style={{ cursor: "pointer" }}
              height={20}
              width={20}
              alt="Download"
              src={Download}
            />
          </Grid>
        </>
      ) : (
        <></>
      )}
      <Grid
        item
        className={`${nonModal ? "" : "noscroll"}`}
        style={{ height: 700, overflowY: "scroll" }}
        textAlign="center"
        alignItems="center"
        justifyContent={"center"}
        xl={12}
      >
        <Document
          file={file}
          onLoadSuccess={onDocumentLoadSuccess}
          className={`${nonModal ? "nonmodal" : ""}`}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

export default PdfViewer;
