import {
  Grid,
  Typography,
  Box,
  Button,
  Stack,
  Divider,
  TextField,
  MenuItem,
} from "@mui/material";

import { useEffect, useState } from "react";
import { theme } from "../../../theme";
import DataSourceXAxisComponent from "../../molecules/KPIBuilder/DataSourceXAxisComponent";
import DataSourceYZAxisComponent from "../../molecules/KPIBuilder/DataSourceYZAxisComponent";
import CancelIcon from "../../../assets/cancel.png";

import { nanoid } from "nanoid";
import { ConfigsTypes } from "../../../Types/KPIBuilderChartType";
import { chain, each, filter, findIndex, flatMap, isEmpty, set } from "lodash";

type Props = {
  seriesInfo: any;
  updateDataSourceInfo: Function;
  updateOrderBy: Function;
  seriesTypeInfo: any;
  configs: any;
  updateFilterOptions: Function;
  isDirty: boolean;
  updateIsDirty: Function;
  validationMessage: string;
  chartTypeInfo: any;
  isValid: any;
  units: any;
  updateUnit: Function;
};

const DataSourceDefinition = (props: Props) => {
  const {
    seriesInfo,
    seriesTypeInfo,
    updateDataSourceInfo,
    updateOrderBy,
    configs,
    updateFilterOptions,
    isDirty,
    updateIsDirty,
    validationMessage,
    chartTypeInfo,
    isValid,
    units,
    updateUnit,
  } = props;

  const [seriesData, setSeriesData] = useState({} as any);
  const [dataTypeConfigs, setDataTypeConfigs] = useState([] as ConfigsTypes[]);

  useEffect(() => {
    setSeriesData(seriesInfo);
    setSelectedOrderBy(seriesInfo?.["order_by"]?.["label"] || "None");
    setArrangeBy(seriesInfo["order_by"]["order_by_type"] || "asc");
    generateOrderByOptions();
    generateAllFilterOptions();
    updateIsDirty(true);
  }, [JSON.stringify(seriesInfo)]);

  useEffect(() => {
    if (configs) {
      setDataTypeConfigs(configs || []);
    }
  }, [configs]);

  const removeSeriesIfIncomplete = (seriesData: any) => {
    return seriesData.filter((ele: any) => ele["isValid"] === true);
  };
  useEffect(() => {
    let _seriesInfo: any = {};
    if (seriesTypeInfo["series_info"]) {
      let _series = seriesTypeInfo["series_info"];
      each(_series, (ele: any) => {
        if (
          ["y", "z"].includes(ele["slug"]) &&
          chartTypeInfo["chart_type"] === "pie"
        ) {
          _seriesInfo[ele["slug"]] = {
            ...ele,
            data: removeSeriesIfIncomplete(
              seriesInfo?.["series"]?.[ele["slug"]]?.["data"] || []
            ),
          };
        } else {
          _seriesInfo[ele["slug"]] = {
            ...ele,
            data: seriesInfo?.["series"]?.[ele["slug"]]?.["data"].length
              ? seriesInfo?.["series"]?.[ele["slug"]]?.["data"]
              : [getInitSeries(ele["slug"])],
          };
        }
      });

      updateDataSourceInfo(_seriesInfo);
    }
  }, [JSON.stringify(seriesTypeInfo)]);

  const getInitSeries = (slug: string) => {
    if (["y", "z"].includes(slug)) {
      return {
        key: nanoid(10),
        data_source: {
          columnId: "",
          columnName: "",
          sheetId: "",
          sheetName: "",
          workbookId: "",
          workbookName: "",
          thumbnail: "",
        },
        data_type_selected: {
          name: "",
          value: "",
        },
        axis_name: "",
        joining_column: {
          connection_id: "",
          table: "",
          column_name: "",
        },
        base_column: {
          connection_id: "",
          table: "",
          column_name: "",
        },
        operator: "",
        isValid: false,
      };
    } else {
      return {
        key: nanoid(10),
        data_source: {
          columnId: "",
          columnName: "",
          sheetId: "",
          sheetName: "",
          workbookId: "",
          workbookName: "",
          thumbnail: "",
        },
        data_type_selected: {
          name: "",
          value: "",
        },
        axis_name: "",
        operator: "",
        isValid: false,
      };
    }
  };

  const [orderByOptions, setOrderByOptions] = useState([] as any);
  const [arrangeBy, setArrangeBy] = useState("asc");
  const [selectedOrderBy, setSelectedOrderBy] = useState<string>("");

  const generateOrderByOptions = () => {
    let _seriesForOrderBy = flatMap(
      seriesData["series"],
      (value) => value["data"]
    );

    let _orderByOptions: any = [];
    _orderByOptions = filter(
      _seriesForOrderBy,
      (s) => s["axis_name"] && s["data_source"]["columnId"]
    ).map((el: any) => {
      return {
        ...{
          column_name: el["data_source"]["columnId"],
          connection_id: el["data_source"]["workbookId"],
          table: el["data_source"]["sheetId"],
        },
        label: el["axis_name"],
        id: el["key"],
        order_by_type: el["order_by_type"] || "asc",
      };
    });
    setOrderByOptions(_orderByOptions);
  };

  const onOrderByTypeChange = (event: any) => {
    updateOrderBy(
      set({ ...seriesData.order_by }, "order_by_type", event.target.value)
    );
  };

  const generateAllFilterOptions = () => {
    updateFilterOptions(
      chain(seriesData.series)
        .flatMap("data")
        .filter(
          (el) =>
            !isEmpty(el["axis_name"] || !isEmpty(el["data_source"]["columnId"]))
        )
        .map((el) => ({ ...el.data_source, id: nanoid(10) }))
        .value()
    );
  };

  const onOrderByChange = (selectedOption: any) => {
    const defaultOrderBy = {
      column_name: "",
      connection_id: "",
      table: "",
      order_by_type: "",
      id: "",
      label: "",
    };

    updateOrderBy(
      !selectedOption || selectedOption === "None"
        ? defaultOrderBy
        : { ...selectedOption }
    );
  };

  const updateDataSourceSeries = (updatedData: any, slug: string) => {
    const updatedSeriesData = { ...seriesData };
    const seriesList = seriesData.series[slug]?.data || [];
    const updatedList = seriesList.map((item: any) =>
      item.key === updatedData.key ? { ...item, ...updatedData } : item
    );
    updatedSeriesData.series[slug].data = updatedList;
    setSeriesData(updatedSeriesData);
  };
  const addSeries = (slug: string) => {
    const updatedSeriesData = { ...seriesData };
    const seriesList = updatedSeriesData.series[slug]?.data || [];
    seriesList.push(getInitSeries(slug));
    updatedSeriesData.series[slug].data = seriesList;
    setSeriesData(updatedSeriesData);
  };

  const deleteSeries = (id: string, slug: string) => {
    let updatedSeriesData = { ...seriesData };
    let seriesList = [...updatedSeriesData["series"][slug]["data"]];
    seriesList.splice(findIndex(seriesList, { key: id }), 1);
    updatedSeriesData["series"][slug]["data"] = seriesList;
    setSeriesData(updatedSeriesData);
  };

  const validateAddMore = (seriesCount: number, slug: string) => {
    return seriesCount < seriesData?.["series"]?.[slug]?.["max"];
  };

  const validateDelete = (seriesCount: number, slug: string) => {
    return seriesCount > seriesData?.["series"]?.[slug]?.["min"];
  };

  const seriesRenderer = (seriesData: any, slug: string, xSeriesData?: any) => {
    let componentMap: any = {
      x: DataSourceXAxisComponent,
      y: DataSourceYZAxisComponent,
      z: DataSourceYZAxisComponent,
    };

    const DataSourceAxisComponent = componentMap[slug];
    return (
      <Grid container width={"100%"}>
        <Grid mt={4} item sm={12}>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <Stack direction={"row"} alignItems="center">
                <Grid item mr={2}>
                  <Typography
                    sx={{ color: theme.custom.primaryDarkColor }}
                    variant="inter_p_600_16"
                  >
                    {(seriesData && seriesData["label"]) || ""}
                  </Typography>
                </Grid>
                <Grid item sm={11}>
                  <Divider sx={{ height: 1 }} orientation="horizontal" />
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid my={1} item sm={12}>
          {seriesData && Object.keys(seriesData).length > 0 && (
            <Box mt={1}>
              {seriesData &&
                seriesData["data"] &&
                seriesData["data"].length > 0 &&
                seriesData["data"].map((ele: any, index: number) => {
                  return (
                    <>
                      <Grid
                        key={index}
                        container
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Grid item sm={11}>
                          <Box sx={{ my: 2 }} key={ele["key"]}>
                            {!["y", "z"].includes(slug) ? (
                              <DataSourceAxisComponent
                                id={ele["key"]}
                                seriesData={ele}
                                updateDataSource={updateDataSourceSeries}
                                supported_data_types={seriesData["data_types"]}
                                configs={dataTypeConfigs}
                                slug={seriesData["slug"]}
                              ></DataSourceAxisComponent>
                            ) : (
                              <DataSourceAxisComponent
                                id={ele["key"]}
                                configs={dataTypeConfigs}
                                updateDataSource={updateDataSourceSeries}
                                key={ele["key"]}
                                seriesData={ele}
                                supported_data_types={seriesData["data_types"]}
                                slug={slug}
                                xSeries={xSeriesData?.["data"] || []}
                              ></DataSourceAxisComponent>
                            )}
                          </Box>
                        </Grid>
                        {validateDelete(
                          seriesData["data"].length || 0,
                          slug
                        ) && (
                          <Grid item sm={1} textAlign={"center"}>
                            <img
                              style={{ cursor: "pointer" }}
                              onClick={() => deleteSeries(ele["key"], slug)}
                              src={CancelIcon}
                              alt="Delete"
                              height="12"
                              width="12"
                            />
                          </Grid>
                        )}
                      </Grid>
                    </>
                  );
                })}
            </Box>
          )}

          {validateAddMore(
            seriesData["data"].length || 0,
            seriesData["slug"]
          ) && (
            <Button
              onClick={() => addSeries(slug)}
              style={{ backgroundColor: "transparent" }}
            >
              <Typography
                sx={{
                  color: theme.custom.popoverLinkColor,
                  cursor: "pointer",
                }}
                variant="poppins_p_600_14"
              >
                Add More
              </Typography>
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <>
      <Box>
        <Typography
          variant="poppins_h5_600_18"
          sx={{ color: theme.custom.primaryDarkColor }}
        >
          Define data source
        </Typography>
      </Box>
      {seriesData &&
        Object.keys(seriesData).length > 0 &&
        seriesData["series"] &&
        seriesData["series"]["x"] &&
        seriesRenderer(seriesData["series"]["x"], "x")}

      {seriesData &&
        Object.keys(seriesData).length > 0 &&
        seriesData["series"] &&
        seriesData["series"]["y"] &&
        seriesRenderer(
          seriesData["series"]["y"],
          "y",
          seriesData["series"]["x"]
        )}

      {seriesData &&
        Object.keys(seriesData).length > 0 &&
        seriesData["series"] &&
        seriesData["series"]?.["z"] &&
        seriesRenderer(
          seriesData["series"]?.["z"],
          "z",
          seriesData?.["series"]?.["x"] || {}
        )}

      {chartTypeInfo &&
        chartTypeInfo.chart_type &&
        chartTypeInfo.chart_type.length && (
          <Grid mt={3} item sm={12}>
            <Grid container justifyContent={"space-between"} spacing={2}>
              <Grid item>
                <Stack direction={"row"}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <TextField
                        label={
                          <Typography
                            variant="inter_500_12"
                            sx={{ color: theme.custom.primaryGreyColor }}
                          >
                            Order By
                          </Typography>
                        }
                        id={"order_by"}
                        select
                        size="small"
                        value={selectedOrderBy}
                        fullWidth
                        sx={{ minWidth: 150 }}
                      >
                        <MenuItem
                          sx={{ height: 40 }}
                          onClick={() => onOrderByChange("None")}
                          key={"none"}
                          value={"None"}
                        >
                          <Typography variant="inter_p_500_14" mt={0.3}>
                            None
                          </Typography>
                        </MenuItem>
                        {orderByOptions &&
                          orderByOptions.length > 0 &&
                          orderByOptions.map((el: any) => {
                            return (
                              <MenuItem
                                onClick={() => onOrderByChange(el)}
                                sx={{ height: 40 }}
                                key={el.id}
                                value={el.label}
                              >
                                <Typography variant="inter_p_500_14" mt={0.3}>
                                  {el.label}
                                </Typography>
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    </Grid>
                    <Grid item>
                      <TextField
                        disabled={seriesInfo?.["order_by"]?.["label"] === ""}
                        label={
                          <Typography
                            variant="inter_500_12"
                            sx={{ color: theme.custom.primaryGreyColor }}
                          >
                            Arrange By
                          </Typography>
                        }
                        id={"Arrange By"}
                        select
                        size="small"
                        sx={{ width: 180 }}
                        value={arrangeBy}
                        fullWidth
                        onChange={(e) => onOrderByTypeChange(e)}
                      >
                        <MenuItem sx={{ height: 40 }} key={"asc"} value={"asc"}>
                          <Typography variant="inter_p_500_14" mt={0.3}>
                            Ascending order
                          </Typography>
                        </MenuItem>
                        <MenuItem
                          sx={{ height: 40 }}
                          key={"desc"}
                          value={"desc"}
                        >
                          <Typography variant="inter_p_500_14" mt={0.3}>
                            Descending order
                          </Typography>
                        </MenuItem>
                      </TextField>
                    </Grid>
                    <Grid item>
                      <TextField
                        label={
                          <Typography
                            variant="inter_500_12"
                            sx={{ color: theme.custom.primaryGreyColor }}
                          >
                            Units
                          </Typography>
                        }
                        id={"Units"}
                        select
                        size="small"
                        sx={{ width: 180 }}
                        value={chartTypeInfo?.unit || ""}
                        fullWidth
                        onChange={(e: any) => {
                          updateUnit(e.target.value);
                        }}
                      >
                        {units &&
                          units.length &&
                          units.map((ele: any, index: number) => {
                            return (
                              <MenuItem
                                sx={{ height: 40 }}
                                key={ele["datatype_slug"]}
                                value={ele["datatype_slug"]}
                              >
                                <Typography variant="inter_p_500_14" mt={0.3}>
                                  {ele["datatype"]}
                                </Typography>
                              </MenuItem>
                            );
                          })}
                      </TextField>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>

              <Grid item>
                <Button
                  disabled={
                    !isDirty ||
                    validationMessage.length > 0 ||
                    Object.values(isValid).filter((el: any) => el === false)
                      .length > 0
                  }
                  onClick={() => {
                    updateIsDirty(false);
                  }}
                  variant="contained"
                  sx={{ width: 200, height: 40 }}
                >
                  Save & Continue
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      <Box mt={3}>
        <Typography variant="inter_p_500_14" sx={{ color: "red" }}>
          {validationMessage}
        </Typography>
      </Box>
    </>
  );
};

export default DataSourceDefinition;
