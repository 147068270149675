import { Grid, Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useStartTyping } from "react-use";

function EmbedEditor(props: any) {
  const { attributes, height, width, updateElement, isElementSelected } = props;
  const videoRef = useRef<HTMLDivElement>(null as any);

  // Since its iframe, we can't track onLoad event on it directly.
  // Assuming a 2sec window to load element properly.
  let _updateElement = useCallback(() => {
    if (height || width) return;
    updateElement({
      height: videoRef?.current?.clientHeight,
      width: videoRef?.current?.clientWidth,
    });
  }, [videoRef, updateElement]);
  setTimeout(_updateElement, 2000);

  const [enableControl, setEnableControls] = useState<boolean>(false);

  useEffect(() => {
    setEnableControls(false);
  }, [isElementSelected])

  const handleControls = (e:any) => {
    if(e.detail % 2 === 0) {
      setEnableControls(true);
    }
  }

  return (
    <>
      <div
        ref={videoRef}
        style={{
          width: "100%",
          height: "100%",
        }}
        className={isElementSelected ? "" : "embed-container"}
      >
        <Grid
          container
          sx={{
            width: "100%",
            height: "100%",
            backgroundColor: "lightgray",
            p: 1,
            borderRadius: "6px",
          }}
        >
          <Tooltip title="Double click to interact"><div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              background: "white",
              opacity: 0.1,
              display: enableControl ? 'none' : 'block'
            }}
            onClick={handleControls}
          ></div></Tooltip>
          <Grid
            item
            dangerouslySetInnerHTML={{ __html: attributes?.embedHtml }}
            sx={{
              iframe: { width: "100%", height: "100%" },
              width: "100%",
              height: "100%",
            }}
          ></Grid>
        </Grid>
      </div>
    </>
  );
}

export default React.memo(EmbedEditor);
