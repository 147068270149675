import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { memo, useEffect, useState } from "react";
import { useEffectOnce } from "react-use";
import {
  FormattedMemberDetails,
  UserRoleAPIDataItem,
} from "../../../Types/UserManagement";
import {
  getAccessibleRoles,
  getMemberDetails,
} from "../../../services/user-management-service";
import { fontInter, theme } from "../../../theme";
import { sortBy } from "lodash";
interface Props {
  getStepsAndUpdateMemberDetails: CallableFunction;
  setExistingMemberDetails: CallableFunction;
  formattedMemberDetails: FormattedMemberDetails | undefined;
  isEditFlow: boolean;
  setRoles: CallableFunction;
}

const MemberProfileDetailsForm = (props: Props) => {
  const INIT_STATE = {
    first_name: "",
    last_name: "",
    email: "",
    role_slug: "",
  };

  const [errMsg, setErrMsg] = useState<string>("");
  const [errEmailMsg, setEmailErrMsg] = useState<string>("");
  const [userData, setUserData] = useState(INIT_STATE);
  const [loadingEmailCheck, setLoadingEmailCheck] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [permittedRoles, setPermittedRoles] = useState<UserRoleAPIDataItem[]>(
    []
  );

  useEffectOnce(() => {
    getAccessibleRoles().then((resp) => {
      if (resp && resp.data) {
        setPermittedRoles(resp.data.data);
        props.setRoles(resp.data.data.map((i) => i.slug));
      }
    });
  });

  useEffect(() => {
    if (props.formattedMemberDetails) {
      setUserData({
        first_name: props.formattedMemberDetails.first_name,
        last_name: props.formattedMemberDetails.last_name,
        email: props.formattedMemberDetails.email,
        role_slug: props.formattedMemberDetails.role_slug,
      });
    } else {
      setUserData({
        first_name: "",
        last_name: "",
        email: userData.email,
        role_slug: "",
      });
    }
    if (props.formattedMemberDetails?.email) {
      setEmailValid(true);
    }
  }, [props.formattedMemberDetails]);

  const onChange = (key: string, event: any) => {
    setErrMsg("");
    setUserData((prev) => {
      return {
        ...prev,
        [key]: event.target.value,
      };
    });
  };

  const onEmailChange = (event: any) => {
    setEmailErrMsg("");
    setEmailValid(false);
    setUserData({ ...INIT_STATE, email: event.target.value });
  };

  const verifyEmail = () => {
    setLoadingEmailCheck(true);
    if (
      !userData.email.match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
    ) {
      setEmailErrMsg("Please enter valid email");
      setLoadingEmailCheck(false);
      return;
    }
    getMemberDetails(userData.email, "email").then((resp) => {
      if (resp) {
        setLoadingEmailCheck(false);
        if (resp.status === 200) {
          setEmailValid(true);
          resp.data.role_slug = resp.data.role?.slug || "";
          props.setExistingMemberDetails(resp.data);
        } else if (resp.status === 403) {
          setEmailValid(false);
          setEmailErrMsg(
            "You do not have access to edit this user. Please reach out to the owner of this account."
          );
        } else if (resp.status === 404) {
          setEmailValid(true);
          props.setExistingMemberDetails(undefined);
        }
      }
    });
  };

  const handleNext = () => {
    let regex = /^[-_A-Za-z0-9\s]+$/;
    if (userData.first_name === "") {
      setErrMsg("First Name can't be empty");
      return;
    }
    if (userData.last_name === "") {
      setErrMsg("Last Name can't be empty");
      return;
    }
    if (userData.email === "") {
      setErrMsg("Email can't be empty");
      return;
    }
    if (!regex.test(userData.first_name)) {
      setErrMsg(
        `Invalid First Name. First Name can contain only Alphabets,
        - and _.`
      );
      return;
    }
    if (!regex.test(userData.last_name)) {
      setErrMsg(
        `Invalid Last Name. Last Name can contain only Alphabets,
        - and _.`
      );
      return;
    }

    if (userData.role_slug === "") {
      setErrMsg("Role Can't be empty");
      return;
    }

    if (
      userData.first_name &&
      userData.last_name &&
      userData.email &&
      userData.role_slug
    ) {
      setErrMsg("");
      props.getStepsAndUpdateMemberDetails({
        first_name: userData.first_name.trim(),
        last_name: userData.last_name.trim(),
        email: userData.email.trim(),
        role_slug: userData.role_slug,
      });
    }
  };

  return (
    <>
      <DialogContent sx={{ height: "100%" }}>
        <Grid mt={6} container>
          <Grid mb={3} item xs={12}>
            <TextField
              onChange={(e) => onEmailChange(e)}
              fullWidth
              value={userData.email}
              error={errEmailMsg !== ""}
              disabled={props.isEditFlow}
              helperText={errEmailMsg}
              inputProps={{
                maxLength: 200,
                style: {
                  fontFamily: fontInter,
                  fontSize: "14px",
                  color: theme.custom.primaryDarkColor,
                  cursor: props.isEditFlow ? "not-allowed" : "text",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LoadingButton
                      color="primary"
                      variant="contained"
                      loading={loadingEmailCheck}
                      disabled={userData?.email === ""}
                      onClick={() => verifyEmail()}
                      sx={{ display: props.isEditFlow ? "none" : "block" }}
                    >
                      <Typography variant="poppins_600_12">Continue</Typography>
                    </LoadingButton>
                  </InputAdornment>
                ),
              }}
              id="email"
              label={
                <Typography variant="inter_p_500_14" sx={{ mt: 0.4 }}>
                  Enter Email
                </Typography>
              }
              variant="outlined"
            />
          </Grid>
          <Grid
            sx={{ height: "50px", display: emailValid ? "block" : "none" }}
            mb={3}
            item
            xs={12}
          >
            <TextField
              onChange={(e) => onChange("first_name", e)}
              fullWidth
              disabled={!emailValid}
              value={userData.first_name}
              inputProps={{
                maxLength: 200,
                style: {
                  fontFamily: fontInter,
                  fontSize: "14px",
                  color: theme.custom.primaryDarkColor,
                },
              }}
              id="first_name"
              label={
                <Typography variant="inter_p_500_14" sx={{ mt: 0.4 }}>
                  First Name
                </Typography>
              }
              variant="outlined"
            />
          </Grid>
          <Grid
            sx={{ height: "50px", display: emailValid ? "block" : "none" }}
            mb={3}
            item
            xs={12}
          >
            <TextField
              onChange={(e) => onChange("last_name", e)}
              fullWidth
              disabled={!emailValid}
              inputProps={{
                maxLength: 200,
                style: {
                  fontFamily: fontInter,
                  fontSize: "14px",
                  color: theme.custom.primaryDarkColor,
                },
              }}
              id="last_name"
              value={userData.last_name}
              label={
                <Typography variant="inter_p_500_14" sx={{ mt: 0.4 }}>
                  Last Name
                </Typography>
              }
              variant="outlined"
            />
          </Grid>

          <Grid
            mb={3}
            item
            xs={12}
            sx={{ display: emailValid ? "block" : "none" }}
          >
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={userData.role_slug}
                disabled={!emailValid}
                label="Role"
                MenuProps={{
                  style: { zIndex: 3000 },
                }}
                onChange={(e) => onChange("role_slug", e)}
              >
                {permittedRoles.map((item) => (
                  <MenuItem
                    key={item.slug}
                    sx={{ height: 50 }}
                    value={item.slug}
                  >
                    <Typography variant="inter_p_500_14" sx={{ mt: 0.4 }}>
                      {item.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          {errMsg && errMsg.length && (
            <Grid mt={2} textAlign={"center"} item xs={12}>
              <Typography sx={{ color: theme.custom.errorColor }}>
                {errMsg}
              </Typography>
            </Grid>
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Grid container justifyContent={"end"}>
          <Grid item>
            <Button
              fullWidth
              sx={{ height: "50px" }}
              onClick={handleNext}
              disabled={!emailValid}
              variant="contained"
              type="submit"
            >
              <Typography variant="poppins_p_600_14">Continue</Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default memo(MemberProfileDetailsForm);
