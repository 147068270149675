import { Box, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { setNavigationData } from "../../../store/navigation-store";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store/hook";
import { getS3Image } from "../../../utils/ui-utils";
import { orderBy } from "lodash";

interface Props {
  boards: any;
}

const MatricesStatementMolecule = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const menuItemClickHandler = (item: any) => {
    dispatch(setNavigationData(item));
    navigate(`/board/${item.board_slug}`);
  };

  const constructStatement = (boards: any, title: string) => {
    if (!boards.length) return;

    let _boards = orderBy(boards, "position", "asc");
    return (
      <Box sx={{ marginTop: 2 }}>
        <Typography variant="inter_400_14">{title}</Typography>
        <Stack
          sx={{ marginTop: 2 }}
          direction="row"
          spacing={2}
          useFlexGap
          flexWrap="wrap"
        >
          {_boards.map((board: any, index: any) => (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => menuItemClickHandler(board)}
            >
              <img
                alt={board.board_slug}
                width="290"
                src={getS3Image(
                  `metrics/${
                    board.icon_handle || "statement-of-activities-detailed"
                  }.webp`
                )}
              />
            </div>
          ))}
        </Stack>
      </Box>
    );
  };

  return (
    <Box sx={{ marginLeft: 3 }}>
      {constructStatement(
        props.boards.filter((board: any) => board.category === "Summary"),
        "Summary"
      )}
      {constructStatement(
        props.boards.filter(
          (board: any) => board.category === "Segment Performance"
        ),
        "Segment Performance"
      )}
      {constructStatement(
        props.boards.filter(
          (board: any) => board.category === "SoA & Cash Flows"
        ),
        "SoA & Cash Flows"
      )}
      {constructStatement(
        props.boards.filter(
          (board: any) => board.category === "Balances & Working Capital"
        ),
        "Balances & Working Capital"
      )}
      {constructStatement(
        props.boards.filter(
          (board: any) =>
            board.category !== "Summary" &&
            board.category !== "Segment Performance" &&
            board.category !== "SoA & Cash Flows" &&
            board.category !== "Balances & Working Capital"
        ),
        "Others"
      )}
    </Box>
  );
};

export default MatricesStatementMolecule;
