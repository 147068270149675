import axios from "axios";
import { environment } from "../environments/environment-dev";

export const getFilters = (slug: string, budget_uid: string = "") => {
  let url = `${environment.connectorApiUrl}/boards/${slug}/filters`;
  if (budget_uid) {
    url = url+`?budget_uid=${budget_uid}`;
  }
  if (slug === "budget-tracker") {
    return axios.get(url, {
      params: {
        budget_tracker: true,
      },
    });
  }
  return axios.get(url);
};

export const saveFilters = (slug: string, filters: any) => {
  let temp = {
    data: filters,
  };
  const url = `${environment.connectorApiUrl}/boards/${slug}/save-filters`;
  return axios.post(url, temp);
};

export const getCustomKPIFilters = (kpi_slug: string) => {
  const url = `${environment.connectorApiUrl}/kpi/${kpi_slug}/filters`;
  return axios.get(url);
};
