import axios from "axios";
import { environment } from "../environments/environment-dev";

export const getAllMenus = () => {
  const url = `${environment.connectorApiUrl}/boards/all/sub-boards`;
  return axios.get(url);
};

export const getBoards = (category:string, search:string = "") => {
  const url = `${environment.connectorApiUrl}/boards/${category}/sub-boards`;
  let params:any = {category: category}
  if (search) {
    params['search'] = search
  }
  return axios.get(url, {params: params});
};

export const generateTextRewrite = (requestBody: any) => {
  const url = `${environment.connectorApiUrl}/conversations/generic`;
  return axios.post(url, requestBody);
};
