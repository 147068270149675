export function qbtoum(rules: any, attrs: any) {
  function tranformRulesToConditions(rules: any) {
    let conditions = [] as any;
    let combinator = "";
    rules.map((rule: any) => {
      if (typeof rule === "object") {
        let is_not = false,
          operator = combinator;
        switch (rule.operator) {
          case "in":
            is_not = false;
            break;
          case "not_in":
            is_not = true;
            break;
        }
        switch (combinator) {
          case "or_not":
            operator = "or";
            is_not = !is_not;
            break;
          case "and_not":
            operator = "and";
            is_not = !is_not;
            break;
        }

        let current_rule = { operator: operator.toUpperCase(), is_not: is_not };
        let values;
        if (rule.value && Array.isArray(rule.value)) {
          values = rule.value;
        } else if (rule.value) {
          values = [rule.value];
        } else {
          values = [];
        }
        const valueSlugType = () => {
          if (rule?.field == "date") {
            return "date";
          }
          if (Object.keys(attrs).includes(rule?.field)) {
            return "attr";
          } else {
            return "dim";
          }
        };
        current_rule = {
          ...current_rule,
          ...{
            value_slug: rule?.field || "",
            values: values,
            value_slug_type: valueSlugType(),
            value_operator: rule?.field == "date" ? rule?.operator : "",
          },
        };
        if (Object.keys(rule).includes("rules")) {
          current_rule = {
            ...current_rule,
            ...{
              conditions: tranformRulesToConditions(rule.rules),
            },
          };
        }
        conditions.push(current_rule);
      } else if (typeof rule === "string") {
        combinator = rule;
      }
    });

    return conditions;
  }
  return tranformRulesToConditions(rules);
}

export function umtoqb(conditions: any) {
  function transformConditionsToRules(conditions: any) {
    let rules = [] as any;
    let current_operator = "";
    conditions.map((condition: any | null) => {
      current_operator = condition.operator.toLowerCase();
      let rule = { ...condition };
      // if nested condition
      if (
        condition.values.length === 0 &&
        condition.conditions &&
        condition.conditions.length > 0
      ) {
        switch (current_operator) {
          case "or":
            if (condition.is_not) {
              current_operator = "or_not";
              rule.is_not = false;
            }
            break;
          case "and":
            if (condition.is_not) {
              current_operator = "and_not";
              rule.is_not = false;
            }
            break;
        }
        if (current_operator !== "") {
          rules.push(current_operator);
        }
        rules.push({
          rules: transformConditionsToRules(condition.conditions),
          not: false,
        });
      } else {
        if (current_operator !== "") {
          rules.push(current_operator);
        }
        rules.push({
          field: rule.value_slug,
          value: rule.values,
          operator: condition.value_operator
            ? condition.value_operator
            : rule.is_not
            ? "not_in"
            : "in",
        });
      }
    });
    return rules;
  }
  return { rules: transformConditionsToRules(conditions) };
}
