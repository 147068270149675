import moment from "moment";
import React, { memo } from "react";
import LinearProgressWithLabels from "../LinearProgressWithLabels";

type Props = {
  row: any;
  cell: any;
  cellValue: any;
  onCellClick: Function;
};

const CustomRangeCell = (props: Props) => {
  const { row, cell, onCellClick, cellValue } = props;

  let value = 0;
  if (
    cellValue?.["value"]?.["primary_value"] &&
    cellValue?.["value"]?.["secondary_value"] &&
    cellValue?.["value"]?.["tertiary_value"]
  ) {
    const time_elapsed = cellValue?.["value"]?.["primary_value"];
    const endDate = moment(cellValue?.["value"]?.["tertiary_value"]);
    const startDate = moment(cellValue?.["value"]?.["secondary_value"]);
    value = (time_elapsed / (endDate.diff(startDate, "days") + 1)) * 100;
  }

  return (
    <div
      style={{
        width: "100%",
      }}
    >
      <>
        <div>
          {value !== 0 && (
            <LinearProgressWithLabels
              extraProps={{ style: { minWidth: "180px" } }}
              start_date={cellValue?.["value"]?.["secondary_value"] || ""}
              end_date={cellValue?.["value"]?.["tertiary_value"] || ""}
              time_elapsed={cellValue?.["value"]?.["primary_value"] || 0}
              unit={cellValue?.["value"]?.["quinary_value"] || ""}
              value={value}
            />
          )}
        </div>
      </>
    </div>
  );
};

export default memo(CustomRangeCell);
