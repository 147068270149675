import styled from "@emotion/styled";
import CloseIcon from "@mui/icons-material/Close";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  Grid,
  OutlinedInput,
  Popover,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { CustomPicker } from "react-color";
import { useDeepCompareDiffEffect } from "../../../hooks/useDeepCompareDiffEffect";
import {
  gradientPalette,
  gradientStylePalette,
  designPalette,
  theme,
} from "../../../theme";
import FontColorIcon from "../../../assets/font_color.png";
import FontBgColorIcon from "../../../assets/font_bg_color.png";
import AddBoxIcon from "../../../assets/add_box.png";
import RemoveCircleIcon from "../../../assets/remove_circle.png";
import {
  addAlphaToHexCode,
  checkVaildHexCode,
  gradientColorStyleCombined,
} from "../../../utils/color-picker-helper";
import pSBC from "shade-blend-color";
import { getBrandColors } from "../../../services/impact-report-service";

let { Saturation, Hue, Alpha } = require("react-color/lib/components/common");

const Swatch = styled("div")({
  width: "24px",
  height: "24px",
  boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.15)",
  border: "1px solid #fff",
  borderRadius: "6px",
  margin: "2px",
  display: "inline-block",
  cursor: "pointer",
});

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ThemeColorPickerIcon = React.memo(function ThemeColorPickerIcon(
  props: any
) {
  const [gradient, setGrandient] = useState(String);
  useEffect(() => {
    let _colors = Array.isArray(props.colors) ? props.colors : [props.colors];
    let gradient = "linear-gradient(to right, ";
    _colors.map((color: string, index: number) => {
      gradient =
        gradient +
        (index + 1 === _colors.length
          ? color +
            " " +
            (100 / _colors.length) * index +
            "%, " +
            color +
            " 100%)"
          : color +
            " " +
            (100 / _colors.length) * index +
            "%, " +
            color +
            " " +
            (100 / _colors.length) * (index + 1) +
            "%, ");
    });
    setGrandient(gradient);
  }, [props.colors]);

  return (
    <Box
      style={{
        display: "flex",
        background: props.variant === "background" ? gradient : "transparent",
        boxShadow:
          props.variant === "background"
            ? "0px 0px 4px rgba(0, 0, 0, 0.15)"
            : "",
        borderRadius: "6px",
        justifyContent: "left",
        alignItems: "center",
      }}
    >
      <div
        style={{
          position: "relative",
          cursor: "pointer",
          width: "100%",
          textAlign: "center",
          paddingBottom: props.variant === "background" ? "5px" : "0px",
          whiteSpace: "nowrap",
        }}
      >
        <span
          style={{
            display: "inline-block",
            height: "100%",
            verticalAlign: "middle",
          }}
        ></span>
        <img
          style={{
            verticalAlign: "middle",
            filter: props.variant === "background" ? "drop-shadow(2px 4px 6px black)" : ""
          }}
          src={props.variant === "background" ? FontBgColorIcon : FontColorIcon}
          alt="coloricon"
        />
        {props.variant === "background" ? (
          <></>
        ) : (
          <div
            style={{
              height: "5px",
              width: "70%",
              borderRadius: "5px",
              background: gradient,
              position: "absolute",
              bottom: "-5px",
              left: "15%",
            }}
          ></div>
        )}
      </div>
    </Box>
  );
});

const ThemeColorPicker = React.memo(
  CustomPicker(function ThemeColorPicker(props: any) {
    const [expanded, setExpanded] = useState(false);
    const [toggleColorPicker, setToggleColorPicker] = useState(false);
    const [hexValue, setHexValue] = useState("");
    const [isHexValid, setIsHexValid] = useState(true);
    const [currentSelectedColor, setCurrentSelectedColor] = useState("");
    const [currentGradientStyle, setCurrentGradientStyle] = useState("");
    const [brandColors, setBrandColors] = useState([] as any);
    const [tabValue, setTabValue] = React.useState(0);

    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
      if (newValue === 1) {
        let shapePercentage = props.color.toLowerCase().includes("#ffffff")
          ? -0.8
          : 0.8;
        let secondaryShadeColor = pSBC(shapePercentage, props.color);
        gradientPalette.splice(0, gradientPalette.length);
        gradientPalette.push(props.color);
        gradientPalette.push(secondaryShadeColor || "");
        props.onChange(props.color, {
          type: "gradient",
          action: "tab-change",
          style: "linear-gradient(to bottom ",
        });
        setCurrentGradientStyle("linear-gradient(to bottom ");
      } else {
        props.updateHexCode(gradientPalette[0]);
      }
      setTabValue(newValue);
    };
    useEffect(() => {
      if (props.gradientTab) {
        if (props.gradientApplied) {
          setTabValue(1);
          let selectedGradientPalette = props.gradientApplied.split(",");
          setCurrentGradientStyle(selectedGradientPalette[0]);
          gradientPalette.splice(0, gradientPalette.length);
          selectedGradientPalette.map((hexColor: string, index: number) => {
            if (index > 0) {
              if (selectedGradientPalette.length - 1 === index) {
                gradientPalette.push(hexColor.slice(0, -1).trim());
              } else {
                gradientPalette.push(hexColor.trim());
              }
            }
          });
        } else {
          let shapePercentage = props.color.toLowerCase().includes("#ffffff")
            ? -0.8
            : 0.8;
          let secondaryShadeColor = pSBC(shapePercentage, props.color);
          gradientPalette.splice(0, gradientPalette.length);
          gradientPalette.push(props.color);
          gradientPalette.push(secondaryShadeColor || "");
        }
      }
      loadBrandColors();
    }, []);

    const loadBrandColors = () => {
      getBrandColors().then((resp) => {
        if (resp.status === 403) {
          return;
        }
        if (resp.status === 201 || resp.status === 200) {
          setBrandColors(resp.data.colors);
        }
      });
    };

    useDeepCompareDiffEffect(
      (diff = {}) => {
        if (Object.keys(diff).length) {
          setHexValue(props.color);
          setCurrentSelectedColor(props.color);
        }
      },
      [props.color]
    );

    const validateHexCode = (event: any) => {
      const isValid = checkVaildHexCode(event.target.value);
      setIsHexValid(isValid);
      setHexValue(event.target.value);
      if (isValid) {
        props.updateHexCode(event.target.value);
      }
    };

    const updateHexInGradient = (event: any) => {
      const isValid = checkVaildHexCode(event.target.value);
      setIsHexValid(isValid);
      setHexValue(event.target.value);
      if (isValid) {
        if (currentSelectedColor) {
          props.onChange(event.target.value, {
            type: "gradient",
            selectedColor: currentSelectedColor,
            style: currentGradientStyle,
          });
        } else {
          props.onChange(event.target.value, {
            type: "gradient",
            action: "add_color",
            style: currentGradientStyle,
          });
        }
      }
    };

    const showColorPicker = (event: any, currentSelectedColor: string = "") => {
      if (currentSelectedColor && currentSelectedColor !== props.color) {
        setHexValue(currentSelectedColor);
        props.onChange(currentSelectedColor, {
          type: "gradient",
          selectedColor: currentSelectedColor,
          style: currentGradientStyle,
        });
      }
      setCurrentSelectedColor(currentSelectedColor);
    };

    const TitleContent = () => {
      return (
        <>
          <Box mt={2}>
            <Grid mb={1} justifyContent="space-between" container>
              <Grid item>
                <Typography
                  sx={{ color: theme.custom.primaryDarkColor }}
                  variant="inter_p_600_16"
                >
                  {props.colorPickerTitle || "Choose color"}
                </Typography>
              </Grid>
              <Grid item onClick={props.closePicker}>
                <div>
                  <CloseIcon />
                </div>
              </Grid>
            </Grid>
          </Box>
        </>
      );
    };

    const ColorSelectorContent = () => {
      return (
        <>
          <Box style={{ position: "relative", height: "100px" }}>
            <Saturation
              {...props}
              onChange={(e: any) => {
                if (props.gradientTab && tabValue === 1) {
                  props.onChange(e, {
                    type: "gradient",
                    action: "add_color",
                    selectedColor: currentSelectedColor,
                    style: currentGradientStyle,
                  });
                } else {
                  props.onChange(e, { type: "solid" });
                  setIsHexValid(true);
                }
              }}
            />
          </Box>
          <Box mt={1} style={{ position: "relative", height: "10px" }}>
            <Hue
              {...props}
              onChange={(e: any) => {
                if (props.gradientTab && tabValue === 1) {
                  props.onChange(e, {
                    type: "gradient",
                    action: "add_color",
                    selectedColor: currentSelectedColor,
                    style: currentGradientStyle,
                  });
                } else {
                  props.onChange(e, { type: "solid" });
                  setIsHexValid(true);
                }
              }}
              direction={"horizontal"}
            />
          </Box>
          {/* TODO: Not working. Need to debug */}
          {/* <Box mt={1} style={{ position: "relative", height: "10px" }}>
            <Alpha
              {...props}
              onChange={(e: any) => {
                if (props.gradientTab && tabValue === 1) {
                  props.onChange(e, {
                    type: "gradient",
                    action: "add_color",
                    selectedColor: currentSelectedColor,
                    style: currentGradientStyle,
                  });
                } else {
                  props.onChange(e, { type: "solid" });
                  setIsHexValid(true);
                }
              }}
            />
          </Box> */}
        </>
      );
    };

    const SolidContent = ({ hexValue, validateHexCode }: any) => {
      return (
        <>
          <Box>
            <Grid mb={1} justifyContent="space-between" container>
              <Grid item>
                <Typography
                  sx={{ color: theme.custom.primaryDarkColor }}
                  variant="inter_p_600_16"
                >
                  Popular Colors
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            {designPalette.map((color, index) => {
              return (
                <>
                  <Swatch
                    key={color}
                    style={{
                      background: color,
                      outline:
                        props.hex.toLowerCase() === color.toLowerCase()
                          ? `1px solid ${theme.custom.primaryDarkColor}`
                          : "",
                      border: `1px solid ${theme.custom.whiteColor}`,
                    }}
                    onClick={() => {
                      props.onChange({ hex: color }, { type: "solid" });
                      setIsHexValid(true);
                    }}
                  />
                </>
              );
            })}
          </Box>
          <Box>
            <Grid mb={1} justifyContent="space-between" container>
              <Grid item>
                <Typography
                  sx={{ color: theme.custom.primaryDarkColor }}
                  variant="inter_p_600_16"
                >
                  Brand Colors
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
          {brandColors.slice(0, expanded ? brandColors.length : 20).map((color: string, index: number) => {
              return (
                <>
                  <Swatch
                    key={color}
                    style={{
                      background: color,
                      outline:
                        props.hex.toLowerCase() === color.toLowerCase()
                          ? `1px solid ${theme.custom.primaryDarkColor}`
                          : "",
                      border: `1px solid ${theme.custom.whiteColor}`,
                    }}
                    onClick={() => {
                      props.onChange({ hex: color }, { type: "solid" });
                      setIsHexValid(true);
                    }}
                  />
                </>
              );
            })}
          </Box>
          <Box px={2} textAlign="center">
            {!expanded && (
              <div onClick={() => setExpanded(true)}>
                <Button variant="text" endIcon={<ExpandMoreIcon />}>
                  <Typography
                    variant="inter_500_12"
                    sx={{ color: theme.custom.primaryDarkColor }}
                  >
                    More
                  </Typography>
                </Button>
              </div>
            )}
          </Box>
        </>
      );
    };

    const GradientStyles = () => {
      return (
        <>
          <Box>
            <Grid mb={1} justifyContent="space-between" container>
              <Grid item>
                <Typography
                  sx={{ color: theme.custom.primaryDarkColor }}
                  variant="inter_p_600_16"
                >
                  Gradient style
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box>
            {gradientStylePalette.map((gradientStyle) => {
              return (
                <Swatch
                  key={gradientStyle}
                  style={{
                    outline:
                      gradientStyle === currentGradientStyle
                        ? `1px solid ${theme.custom.primaryDarkColor}`
                        : "",
                    border: `1px solid ${theme.custom.whiteColor}`,
                    backgroundImage: gradientColorStyleCombined(
                      gradientPalette,
                      gradientStyle
                    ),
                  }}
                  onClick={(event) => {
                    props.onChange(props.hex.toLowerCase(), {
                      type: "gradient",
                      selectedColor: props.hex.toLowerCase(),
                      style: gradientStyle,
                    });
                    setCurrentGradientStyle(gradientStyle);
                  }}
                />
              );
            })}
          </Box>
        </>
      );
    };

    function ScrollableTabPanel(props: TabPanelProps) {
      const { children, value, index, ...other } = props;
    
      return (
        <div
          role="tabpanel"
          hidden={value !== index}
          id={`simple-tabpanel-${index}`}
          aria-labelledby={`simple-tab-${index}`}
          {...other}
          style={{
            maxHeight: '400px', // Adjust this value as needed
            overflowY: 'auto',
          }}
        >
          {value === index && (
            <Box sx={{ pt: 1 }}>
              <Typography>{children}</Typography>
            </Box>
          )}
        </div>
      );
    }

    return (
      <>
        {props.gradientTab ? (
          <>
            <Popover
              id={props?.id}
              open={Boolean(props.anchorEl)}
              anchorEl={props.anchorEl}
              onClose={props.handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box m={2} sx={{ width: 290 }}>
                <TitleContent></TitleContent>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={tabValue}
                    onChange={handleChangeTab}
                    aria-label="tabs"
                  >
                    <Tab label="Solid" {...a11yProps(0)} />
                    <Tab label="Gradient" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <ScrollableTabPanel value={tabValue} index={0}>
                  <SolidContent
                    hexValue={hexValue}
                    validateHexCode={validateHexCode}
                  ></SolidContent>
                  <Box px={2} mb={2}>
                    {expanded && (
                      <>
                        <ColorSelectorContent></ColorSelectorContent>
                        <Box mt={2} mb={2}>
                          <Grid
                            container
                            alignItems={"center"}
                            textAlign={"center"}
                            justifyContent={"center"}
                          >
                            <Grid item md={3}>
                              <Typography>Hex</Typography>
                            </Grid>
                            <Grid item md={7}>
                              <OutlinedInput
                                size="small"
                                value={hexValue}
                                onChange={(event) => {
                                  validateHexCode(event);
                                }}
                                placeholder="FFFFFF"
                                inputProps={{ maxLength: 9 }}
                              />
                              {!isHexValid && (
                                <Typography
                                  variant="inter_500_10"
                                  sx={{ color: theme.custom.errorColor }}
                                >
                                  Invalid Hexcode
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                        <Box px={2} mb={2} textAlign="center">
                          <div onClick={() => setExpanded(false)}>
                            <Button variant="text" endIcon={<ExpandLessIcon />}>
                              <Typography>Less</Typography>
                            </Button>
                          </div>
                        </Box>
                      </>
                    )}
                  </Box>
                </ScrollableTabPanel>
                <ScrollableTabPanel value={tabValue} index={1}>
                  <Box>
                    <Grid mb={1} justifyContent="space-between" container>
                      <Grid item>
                        <Typography
                          sx={{ color: theme.custom.primaryDarkColor }}
                          variant="inter_p_600_16"
                        >
                          Gradient colors
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    {gradientPalette.map((color, index) => {
                      return (
                        <>
                          <Swatch
                            key={color}
                            style={{
                              background: color,
                              cursor: "pointer",
                              outline:
                                props.hex.toLowerCase() === color.toLowerCase()
                                  ? `1px solid ${theme.custom.primaryDarkColor}`
                                  : "",
                            }}
                            onClick={(event) => {
                              showColorPicker(event, color);
                              if (
                                props.hex.toLowerCase() === color.toLowerCase()
                              ) {
                                setToggleColorPicker(!toggleColorPicker);
                              } else {
                                setToggleColorPicker(true);
                              }
                            }}
                          />

                          {gradientPalette.length > 2 &&
                          gradientPalette.length - 2 === index ? (
                            <span style={{ position: "relative" }}>
                              <img
                                src={RemoveCircleIcon}
                                alt="Remove Color"
                                style={{
                                  maxHeight: "22px",
                                  position: "absolute",
                                  left: "15px",
                                  bottom: "20px",
                                }}
                                onClick={(event) => {
                                  setToggleColorPicker(false);
                                  props.onChange(event, {
                                    type: "gradient",
                                    action: "remove_color",
                                    style: currentGradientStyle,
                                  });
                                }}
                              />
                            </span>
                          ) : (
                            ""
                          )}
                        </>
                      );
                    })}
                    <Tooltip title="Add Color">
                      <img
                        src={AddBoxIcon}
                        alt="Add Color"
                        style={{ maxHeight: "28px", cursor: "pointer" }}
                        onClick={(event) => {
                          setHexValue("#f7f7f7");
                          props.onChange("#f7f7f7", {
                            type: "gradient",
                            style: currentGradientStyle,
                            action: "add_color",
                          });
                          setCurrentSelectedColor("#f7f7f7");
                          setToggleColorPicker(true);
                        }}
                      />
                    </Tooltip>
                  </Box>
                  <GradientStyles></GradientStyles>
                  {toggleColorPicker && (
                    <Box m={2}>
                      <ColorSelectorContent></ColorSelectorContent>
                      <Box mt={2} mb={2}>
                        <Grid
                          container
                          alignItems={"center"}
                          textAlign={"center"}
                          justifyContent={"center"}
                        >
                          <Grid item md={3}>
                            <Typography>Hex</Typography>
                          </Grid>
                          <Grid item md={7}>
                            <OutlinedInput
                              size="small"
                              value={hexValue}
                              onChange={updateHexInGradient}
                              placeholder="FFFFFF"
                              inputProps={{ maxLength: 9 }}
                            />
                            {!isHexValid && (
                              <Typography
                                variant="inter_500_10"
                                sx={{ color: theme.custom.errorColor }}
                              >
                                Invalid Hexcode
                              </Typography>
                            )}
                          </Grid>
                        </Grid>
                      </Box>
                    </Box>
                  )}
                </ScrollableTabPanel>
              </Box>
            </Popover>
          </>
        ) : (
          <>
            <div
              style={{
                position: "fixed",
                height: "100%",
                width: "100%",
                background: "white",
                top: "0",
                bottom: "0",
                left: "0",
                right: "0",
                zIndex: "1",
                opacity: "0.01",
              }}
              onClick={props.closePicker}
            ></div>
            <Box
              className={props.isShapes ? "" : "themeColorPicker"}
              style={{
                zIndex: 2,
                position: "relative",
                maxHeight: "400px",
                overflowY: "auto",
              }}
            >
              {!props.colorSelector ? (
                <>
                  <TitleContent></TitleContent>
                  <SolidContent
                    hexValue={hexValue}
                    validateHexCode={validateHexCode}
                  ></SolidContent>
                  <Box px={2} mb={2}>
                    {expanded && (
                      <>
                        <ColorSelectorContent></ColorSelectorContent>
                        <Box mt={2} mb={2}>
                          <Grid
                            container
                            alignItems={"center"}
                            textAlign={"center"}
                            justifyContent={"center"}
                          >
                            <Grid item md={3}>
                              <Typography>Hex</Typography>
                            </Grid>
                            <Grid item md={7}>
                              <OutlinedInput
                                size="small"
                                value={hexValue}
                                onChange={(event) => {
                                  validateHexCode(event);
                                }}
                                placeholder="FFFFFF"
                                inputProps={{ maxLength: 9 }}
                              />
                              {!isHexValid && (
                                <Typography
                                  variant="inter_500_10"
                                  sx={{ color: theme.custom.errorColor }}
                                >
                                  Invalid Hexcode
                                </Typography>
                              )}
                            </Grid>
                          </Grid>
                        </Box>
                        <Box px={2} mb={2} textAlign="center">
                          <div onClick={() => setExpanded(false)}>
                            <Button variant="text" endIcon={<ExpandLessIcon />}>
                              <Typography>Less</Typography>
                            </Button>
                          </div>
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              ) : (
                <>
                  <Box px={2} mb={2}>
                    <ColorSelectorContent></ColorSelectorContent>
                    <Box mt={2} mb={2}>
                      <Grid
                        container
                        alignItems={"center"}
                        textAlign={"center"}
                        justifyContent={"center"}
                      >
                        <Grid item md={3}>
                          <Typography>Hex</Typography>
                        </Grid>
                        <Grid item md={7}>
                          <OutlinedInput
                            size="small"
                            value={hexValue}
                            onChange={(event) => {
                              validateHexCode(event);
                            }}
                            placeholder="FFFFFF"
                            inputProps={{ maxLength: 9 }}
                          />
                          {!isHexValid && (
                            <Typography
                              variant="inter_500_10"
                              sx={{ color: theme.custom.errorColor }}
                            >
                              Invalid Hexcode
                            </Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </>
    );
  })
);

export { ThemeColorPicker, ThemeColorPickerIcon };
