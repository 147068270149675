import React from "react";
import { theme } from "../../../theme";
import { Grid, Box, Typography } from "@mui/material";

type Props = {
  errorMessage: string;
};

const AuthorizationErrorHandler = (props: Props) => {
  const { errorMessage } = props;
  return (
    <>
      <Grid item xs={12}>
        <Box py={3}>
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{
              background: theme.custom.bgImageColor,
              minHeight: "150px",
              borderRadius: 3,
            }}
          >
            <Grid item px={2}>
              <Typography variant="inter_p_500_14">{errorMessage}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default AuthorizationErrorHandler;
