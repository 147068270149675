import { CircularProgress, Grid } from "@mui/material";
import React, { ReactElement, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getAccessToken } from "../services/authentication-service";
import {
  fetchUserInfo,
  getCompanyDataFromLocalStorage,
  getCompanyId,
  setCompanyDataToLocalStorage,
} from "../services/userinfo-service";
import { setLoggedIn } from "../store/authentiction-store";
import { useAppDispatch, useAppSelector } from "../store/hook";
import useLocalStorageState from "use-local-storage-state";
import { USER_INFO_LOCAL_STORAGE_KEY } from "../constants/constants";

interface Props {}

export const navLogic = (
  loaded: boolean,
  children: any,
  comingFromLogin: boolean,
  invalidRoute: boolean
) => {
  if (!loaded) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <CircularProgress />
      </Grid>
    );
  } else {
    return comingFromLogin || invalidRoute ? (
      <Navigate to={"/panorama/"} replace />
    ) : (
      children
    );
  }
};

function AuthNavGuard({ children, invalidRoute = false }: any): ReactElement {
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);
  const isLoggedIn = useAppSelector((state) => {
    return state.auth.isLoggedIn;
  });
  const dispatch = useAppDispatch();
  const [localStorageInfo, setLocalStorageInfo] = useLocalStorageState(
    USER_INFO_LOCAL_STORAGE_KEY,
    {}
  );

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserInfo().then((info) => {
        setLocalStorageInfo(info?.data);
        setLoaded(true);
        if (!getCompanyDataFromLocalStorage()) {
          setCompanyDataToLocalStorage(
            JSON.stringify(info.data?.default_company_details)
          );
        }
      });
    }
  }, [isLoggedIn]);
  const isLoginPage = () => {
    return location.pathname === "/login" || location.pathname === "/login/";
  };

  const returnLogic: any = () => {
    if (getAccessToken() && getAccessToken().length > 0 && !!getCompanyId()) {
      if (!isLoggedIn) {
        dispatch(setLoggedIn({}));
      }
      if (isLoginPage()) {
        return navLogic(loaded, children, true, invalidRoute);
      } else {
        return navLogic(loaded, children, false, invalidRoute);
      }
    } else {
      if (isLoginPage()) {
        return children;
      } else {
        return (
          <Navigate to="/login" replace state={{ path: location.pathname }} />
        );
      }
    }
  };

  return returnLogic();
}

export default AuthNavGuard;
