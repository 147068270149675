import { createSlice } from "@reduxjs/toolkit";

const filterStateHandler = createSlice({
  name: "filter-state-handler",
  initialState: {
    apply: {},
    filtersLoaded: false,
    company_start_date: "",
    kpiStatus: 200,
    selectedKPI: "",
    selectedKPITitle: "",
    selectedKPICurrentFilters: [],
  },
  reducers: {
    filterApply(state, action) {
      state.apply = action.payload;
    },
    setKpiStatus: (state, action) => {
      state.kpiStatus = action.payload.kpiStatus;
    },
    setFiltersLoaded(state, action) {
      state.filtersLoaded = action.payload;
    },
    setCompanyStartDate(state, action) {
      state.company_start_date = action.payload;
    },
    setSelectedKPI(state, action) {
      state.selectedKPI = action.payload;
    },
    setSelectedKPICurrentFilters(state, action) {
      state.selectedKPICurrentFilters = action.payload;
    },
    setSelectedKPITitle(state, action) {
      state.selectedKPITitle = action.payload;
    },
  },
});

export const {
  filterApply,
  setFiltersLoaded,
  setCompanyStartDate,
  setKpiStatus,
  setSelectedKPI,
  setSelectedKPICurrentFilters,
  setSelectedKPITitle,
} = filterStateHandler.actions;
export default filterStateHandler.reducer;
