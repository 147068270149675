import React from 'react'

interface Props {
    
}

const NotFoundPage = (props: Props) => {
    return (
        <div>
            Not found
        </div>
    )
}

export default NotFoundPage
