import {
  Box,
  ImageList,
  ImageListItem,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getKPILibrary } from "../../../services/impact-report-service";
import { fontInter, theme } from "../../../theme";
import ThumbnailComponent from "../../atoms/ThumbnailComponent";
import SearchIcon from "../../../assets/search.png";
import { useDebounce } from "react-use";
import AuthorizationErrorHandler from "./AuthorizationErrorHandler";
import { TagObject } from "../../../Types/TagObject";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import TagComponent from "../TagComponent";
import "./KPIStyles.scss";
import { filter, find, set } from "lodash";
import { DEFAULT_KPI_SIZE } from "../../../constants/constants";

function KPILibrary(props: any) {
  let mouseOrigin = useRef({ left: 0, top: 0 });
  let cursorPosition = useRef({ left: 0, top: 0 });
  const [kpiEditorLib, setKpiEditorLib] = useState([] as any);
  const [isLoading, setIsLoading] = useState(false);
  let pageRef = useRef({ page: 1 });
  const [hasMoreKPI, setHasMoreKPI] = useState(false);
  const [debouncedValue, setDebouncedValue] = useState("");
  const [searchStr, setSearchStr] = useState("");
  const [authorized, setAuthorized] = useState(true);
  const [kpiTags, setKPITags] = useState([] as TagObject[]);
  const [tagLoading, setTagLoading] = useState(false);
  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(searchStr);
    },
    600,
    [searchStr]
  );

  useEffect(() => {
    fetchKPIs(1, debouncedValue);
  }, [debouncedValue]);

  const fetchKPIs = (page: number, searchString?: string, tags?: string[]) => {
    setIsLoading(true);
    getKPILibrary("kpi", page, searchString, tags, props.reportUID)
      .then((resp) => {
        if (resp.status === 403) {
          setAuthorized(false);
          return;
        }
        if (resp.status === 200) {
          if (resp.data) {
            if (!tags) {
              if (resp.data && resp.data.tags && resp.data.tags.length > 0) {
                setKPITags(
                  resp.data.tags.map((ele: TagObject) => {
                    return { ...ele, selected: false };
                  })
                );
              }
            }

            if (page === 1) {
              setKpiEditorLib([...resp.data.results]);
            } else {
              setKpiEditorLib([...kpiEditorLib, ...resp.data.results]);
            }
            if (resp.data.next) {
              pageRef.current.page = page + 1;
              setHasMoreKPI(true);
              return;
            }
            setHasMoreKPI(false);
          } else {
            setHasMoreKPI(false);
          }
        } else {
          setHasMoreKPI(false);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const loadMore = () => {
    setTagLoading(false);
    fetchKPIs(pageRef.current.page);
  };

  const onSearch = (event: ChangeEvent<any>) => {
    setSearchStr(event.target.value);
    setTagLoading(false);
    setKPITags([]);
  };

  const addElem = (e: any, element: any, dnd: boolean = true) => {
    const { addElement, zoom } = props;
    // This zoom is decided based on experimentation
    // which will render the KPI in most readable format
    // This is used, as func says, for first time render
    // element.attributes.height = element.attributes.height * 0.25
    // element.attributes.width = element.attributes.width * 0.25
    let magicScalefactor = props?.pageAttributes?.height === 3508 ? 0.4 : 0.8;
    const _element = {
      type: "KPIEditor",
      transparency: 1,
      zIndex: 0,
      posX: 100,
      posY: 100,
      height: DEFAULT_KPI_SIZE.height / magicScalefactor,
      width: DEFAULT_KPI_SIZE.width / magicScalefactor,
      rotate: 0,
      scale: 1 / magicScalefactor,
      attributes: {
        ...element.attributes,
        commKey: element.communication_key,
        height: DEFAULT_KPI_SIZE.height,
        width: DEFAULT_KPI_SIZE.width,
      },
    };

    addElement(
      _element,
      {
        left: cursorPosition.current.left,
        top: cursorPosition.current.top,
      },
      "drop",
      "",
      dnd
    );
    e.preventDefault();
  };
  const onDrag = (e: any) => {
    //TODO: On DragEnd page coordinates are coming wrong.
    // Monkey patch to force correct it. Heavy ops issue here.
    cursorPosition.current.top = e.pageY;
    cursorPosition.current.left = e.pageX;
  };

  const onDragStart = (e: any) => {
    let _bounds = e.currentTarget.getBoundingClientRect();
    mouseOrigin.current.top = e.clientY - _bounds.top;
    mouseOrigin.current.left = e.clientX - _bounds.left;
  };

  const onTagSelected = (selected: boolean, slug: string) => {
    let _kpiTags: TagObject[] = [...kpiTags];
    setTagLoading(true);
    if (_kpiTags && _kpiTags.length > 0) {
      set(find(_kpiTags, ["slug", slug]) || {}, ["selected"], selected);
      setKPITags(_kpiTags);
      let _selectedTags = filter(_kpiTags, ["selected", true]).map(
        (ele) => ele.slug
      );
      if (tagLoading) {
        fetchKPIs(1, searchStr, _selectedTags);
      }
    }
  };

  return (
    <>
      {authorized && (
        <Box sx={{
          position: "relative"
        }}>
          <Box sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1000,
            backgroundColor: 'white',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          }}
            mx={-2} mt={-2} px={1} pt={4} pb={1}>
            <TextField
              onChange={onSearch}
              placeholder="Search KPIs"
              fullWidth
              inputProps={{
                style: {
                  fontFamily: fontInter,
                  fontSize: "14px",
                  fontWeight: 500,
                  background: theme.custom.whiteColor,
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <img src={SearchIcon} alt="Search" height={20} />
                  </InputAdornment>
                ),
              }}
            ></TextField>
            <Box my={2} className="kpi-styles-wrapper">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                slidesPerView={3}
                spaceBetween={10}
                className="mySwiper"
              >
                {kpiTags &&
                  kpiTags.map((ele, index) => {
                    return (
                      <SwiperSlide>
                        <TagComponent
                          isSelected={ele.selected}
                          tagName={ele.name}
                          slug={ele.slug}
                          onSelect={onTagSelected}
                        />
                      </SwiperSlide>
                    );
                  })}
              </Swiper>
            </Box>
          </Box>
          <Box mt={3}>
            <InfiniteScroll
              dataLength={kpiEditorLib.length}
              next={loadMore}
              hasMore={hasMoreKPI}
              loader={
                <p style={{ textAlign: "center" }}>
                  <Typography
                    sx={{ color: theme.custom.primaryGreyColor }}
                    variant="inter_p_600_16"
                  >
                    Loading...
                  </Typography>
                </p>
              }
              scrollableTarget="scrollableDiv"
              endMessage={
                <p style={{ textAlign: "center" }}>
                  <Typography
                    sx={{ color: theme.custom.primaryGreyColor }}
                    variant="inter_p_600_16"
                  >
                    {isLoading ? `Loading KPIs` : `No more KPIs to load`}
                  </Typography>
                </p>
              }
            >
              <ImageList variant="standard" cols={2} gap={12}>
                {kpiEditorLib.map((item: any) => (
                  <Tooltip
                    key={item.id}
                    title={item.attributes.title || "Unnamed"}
                    placement="top"
                    PopperProps={{
                      modifiers: [
                        {
                          name: "offset",
                          options: {
                            offset: [0, -28],
                          },
                        },
                      ],
                    }}
                  >
                    <ImageListItem
                      draggable={true}
                      onDrag={onDrag}
                      onDragStart={onDragStart}
                      onDragEnd={(e) => addElem(e, item)}
                      onClick={(e) => addElem(e, item, false)}
                      sx={{ height: 100, width: 100, cursor: "pointer" }}
                    >
                      <ThumbnailComponent
                        imageSrc={item.attributes.thumbnail_url}
                        kpiName={item.attributes.title}
                      />
                    </ImageListItem>
                  </Tooltip>
                ))}
              </ImageList>
            </InfiniteScroll>
          </Box>
        </Box>
      )}
      {!authorized && (
        <AuthorizationErrorHandler
          errorMessage="You don’t have access to see the list of KPI's. Please reach out to
        admin to get access"
        />
      )}
    </>
  );
}

export default React.memo(KPILibrary);
