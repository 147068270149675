import axios from "axios";
import { USER_INFO_API_URL } from "../endpoints/auth-endpoints";
import { environment } from "../environments/environment-dev";
import { isJson } from "../utils/textFunctions";

const userinfokey: string = "userinfo";
const selectedCompanyKey: string = "selected-company-id";

export const fetchUserInfo = () => {
  const url = USER_INFO_API_URL;
  return axios.get(url);
};

export const getCompanyId = () => {
  let userData = localStorage.getItem(userinfokey);
  if (userData === undefined || userData === "undefined") {
    return "";
  } else {
    let data;
    let companyData = getCompanyDataFromLocalStorage();
    if (companyData && isJson(companyData)) {
      data = JSON.parse(companyData);
      return data.company_id;
    } else {
      data = JSON.parse(localStorage.getItem(userinfokey) || "{}");
      return data?.default_company_details?.company_id || "";
    }
  }
};

export const setSelectedCompanyToLocalStorage = (company_id: string) => {
  localStorage.setItem(selectedCompanyKey, company_id);
};

export const getSelectedCompanyIdFromLocalStorage = () => {
  return localStorage.getItem(selectedCompanyKey);
};

export const getCompanies = () => {
  let url = `${environment.authUrl}/companies/`;
  return axios.get(url);
};

export const setCompanyDataToLocalStorage = (company: string) => {
  localStorage.setItem("company", company);
};

export const getCompanyDataFromLocalStorage = () => {
  let company = localStorage.getItem("company");
  if (company && isJson(company)) {
    return company;
  } else {
    let data;
    data = JSON.parse(localStorage.getItem(userinfokey) || "{}");
    return JSON.stringify(data?.default_company_details || {});
  }
};
