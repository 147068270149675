import { chartColors } from "../theme";

export const colorPickerHelper = (index: number) => {
  return chartColors[index % chartColors.length || 0];
};

export const addAlphaToHexCode = (color: string, opacity: number) => {
  const _opacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
  return _opacity > 16 ? color : color + _opacity.toString(16).toUpperCase();
};

export const gradientColorStyleCombined = (
  gradientPalette: any,
  gradientStyle: string
) => {
  gradientPalette.map((color: string) => {
    gradientStyle =
      gradientStyle + (gradientPalette.length >= 2 ? ", " + color : "");
  });
  return gradientStyle + ")";
};

export const checkVaildHexCode = (colorHex: string) => {
  let regex = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{8})$/i;
  const isValid: boolean = regex.test(colorHex);
  return isValid;
};
