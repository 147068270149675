import axios from "axios";
import { environment } from "../environments/environment-dev";
import { ResetPasswordObject } from "../Types/ResetPasswordObject";

export const forgotPassword = (obj: Object) => {
  const url = `${environment.authUrl}/forgot-password/`;
  return axios.post(url, obj);
};

export const resetPassword = (obj: ResetPasswordObject) => {
  const url = `${environment.authUrl}/reset-password/`;
  return axios.post(url, obj);
};
