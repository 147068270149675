import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  returnChartColors,
  theme,
  tooltipColors,
  fontInter,
} from "../../theme";
import { numberFormatter } from "../../utils/dataFormatters";
import { returnFileName } from "../../utils/file-name-helper";
import { ChartProps } from "../../Types/ChartProps";

const returnPieChartData = (data: any, name: string) => {
  let res: any = Object.keys(data).map((ele, index) => {
    return {
      name: name,
      type: "pie",
      colors: returnChartColors(data[ele].length),
      data: data[ele].map((e: any) => {
        return { name: e["primary_label_name"], y: e["value"] };
      }),
    };
  });

  res[0]["center"] = ["25%", "50%"];
  res[1]["center"] = ["75%", "50%"];
  return res;
};

const DoublePieChart: React.FC<ChartProps> = (chartData) => {
  let data: any = chartData.chartData;
  let meta_data = chartData.chartMeta;
  let data_type = meta_data?.chart?.currencytype || "%";
  let date_range = chartData.dateRange;
  const height = chartData.height;
  const width = chartData.width;
  const editorMode = chartData.editorMode;
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  let disableDataLabels = meta_data.chart["data-label-disabled"] || false;
  const options = {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      events: {
        render: function () {
          chartData.kpiRendered(true);
          chartData.chartReference(chartComponentRef);
          if (chartData.customKPI === false) {
            chartComponentRef.current?.chart.reflow();
          }
        },
      },
    },
    title: {
      text: `${meta_data.chart?.["pie-1-title"] || "PIE 1"} and ${
        meta_data.chart?.["pie-2-title"] || "PIE 2"
      } `,
      style: {
        fontFamily: fontInter,
        fontSize: 15,
        fontWeight: 600,
      },
    },
    tooltip: {
      backgroundColor: tooltipColors.bgColor,
      borderColor: tooltipColors.borderColor,
      color: tooltipColors.dataFormatColor,
      padding: 10,
      borderRadius: 5,
      borderWidth: 1,
      formatter: function (this: Highcharts.TooltipFormatterContextObject) {
        if (this.series.userOptions.index === 0) {
          return `<span style="color: ${theme.custom.whiteColor}">${
            this.key
          }: ${numberFormatter(
            this.percentage || 0,
            data_type
          )} (${numberFormatter(
            this.y || 0,
            meta_data.chart?.firstpienumbertype || ""
          )}) </span>`;
        } else {
          return `<span style="color: ${theme.custom.whiteColor}">${
            this.key
          }: ${numberFormatter(
            this.percentage || 0,
            data_type
          )} (${numberFormatter(
            this.y || 0,
            meta_data.chart?.secondpienumbertype || ""
          )}) </span>`;
        }
      },
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: !disableDataLabels,
          formatter: function (this: any) {
            return Math.round(this.percentage * 100) / 100 + " %";
          },

          style: {
            fontWeight: 500,
            fontFamily: fontInter,
            color: theme.custom.primaryDarkColor,
          },
          distance: -40,
          color: "white",
        },
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
      pie: {
        innerSize: "40%",
        dataLabels: {
          enabled: true,
          style: {
            fontWeight: "bold",
          },
        },
        size: "65%",
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      filename: returnFileName(meta_data?.chart?.title || "", date_range),
    },
    series: returnPieChartData(data, meta_data?.chart?.title || ""),
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{
        style: {
          width: "100%",
          height: "calc(100% - 1px)",
        },
      }}
      options={
        editorMode
          ? {
              ...options,
              ...{ chart: { ...options.chart, height: height, width: width } },
            }
          : options
      }
      ref={chartComponentRef}
    ></HighchartsReact>
  );
};

export default DoublePieChart;
