import { createSlice } from "@reduxjs/toolkit";

const initChartTypeSelection = {
  chartType: "",
  chartTitle: "",
  aggregationType: "",
  series_type_info: {} as any,
  id: 0,
};

const initialState = {
  isChartDataValid: false,
  chartTypeInfo: initChartTypeSelection,
  boardSlug: "",
  validationMessage: "",
  kpiSlug: "",
};

export const KpiBuilderChartTypeSlice = createSlice({
  name: "kpi-builder-chart-type",
  initialState,
  reducers: {
    updateChartTypeInfo(action, payload) {
      action.chartTypeInfo = payload.payload;
    },
    updateBoardSlug(action, payload) {
      action.boardSlug = payload.payload;
    },
    updateChartDataValid(action, payload) {
      action.isChartDataValid = payload.payload;
    },
    setValidationMessage(action, payload) {
      action.validationMessage = payload.payload;
    },
    updateKPISlug(state, action) {
      state.kpiSlug = action.payload;
    },
  },
});

export const {
  updateChartTypeInfo,
  updateBoardSlug,
  setValidationMessage,
  updateChartDataValid,
  updateKPISlug,
} = KpiBuilderChartTypeSlice.actions;

export default KpiBuilderChartTypeSlice.reducer;
