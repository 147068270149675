import { Box, Dialog, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { BudgetDrilldownPayload } from "../../Types/BudgetDrilldownPayload";
import { theme } from "../../theme";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import Board from "../../pages/Board";

type Props = {
  openModal: boolean;
  onCloseModal: Function;
  payload?: any;
  slug: string;
  title?: string;
};

const DrillDownModal = (props: Props) => {
  const [open, setOpen] = React.useState(props.openModal);
  const { openModal, onCloseModal, payload, slug, title } = props;

  useEffect(() => {
    if (openModal) {
      handleOpen();
    }
  }, [openModal]);

  const handleOpen = () => setOpen(true);
  const handleClose = (event: any, reason: any) => {
    if (reason && (reason === "backdropClick" || reason === "escapeKeyDown"))
      return;
    setOpen(false);
    onCloseModal();
  };

  return (
    <Dialog
      sx={{ zIndex: 8000 }}
      maxWidth="xl"
      fullWidth
      open={open}
      onClose={(e: any) => {
        handleClose(e, "backdropClick");
      }}
      aria-labelledby="budget-drill-down-modal"
      aria-describedby="budget-drill-down-description"
    >
      <Box py={5} px={6}>
        <Grid mb={4} alignItems={"center"} container>
          <Grid textAlign="right" item xs={12}>
            <CancelOutlinedIcon
              onClick={(e: any) => handleClose(e, "")}
              sx={{
                color: `${theme.custom.primaryGreyColor}`,
                cursor: "pointer",
              }}
            />
          </Grid>
        </Grid>
        <Board
          showSideBar={true}
          filtersForBudgetDrillDown={payload}
          slugFromProps={slug}
          titleFromProps={title || ""}
        />
      </Box>
    </Dialog>
  );
};

export default DrillDownModal;
