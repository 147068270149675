import {
  Box,
  Button,
  Grid,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Typography,
} from "@mui/material";
import { orderBy } from "lodash";
import { memo, useEffect, useState } from "react";
import { getTemplateLibrary } from "../../../services/impact-report-service";
import AuthorizationErrorHandler from "./AuthorizationErrorHandler";
import { Add } from "@mui/icons-material";
import PreviewTemplatePopup from "./PreviewTemplatePopup";
import { theme } from "../../../theme";

type Props = {
  addElement: any;
  addPage: any;
  pageAttributes: any;
};

function TemplateLibrary({ addPage, pageAttributes }: Props) {
  const [templateLib, setTemplateLib] = useState<Array<Object>>([]);
  const [authorized, setAuthorized] = useState(true);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    let filterOptions = {
      height: pageAttributes.height,
      width: pageAttributes.width,
    };
    getTemplateLibrary(pageNumber, filterOptions).then((resp) => {
      if (resp.status === 403) {
        setAuthorized(false);
        return;
      }
      setTemplateLib(resp.data.results);
    });
  }, []);
  const [activeElement, setActiveElement] = useState<any | null>(null);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    item: any
  ) => {
    setActiveElement(item);
  };

  const handlePopoverClose = () => {
    setActiveElement(null);
  };

  const addTemplateToEditor = () => {
    let pagesToAdd: any[] = [];
    orderBy(activeElement.attributes.pages, ["position"]).forEach((i) =>
      pagesToAdd.push(i)
    );
    addPage(null, pagesToAdd);
  };

  return (
    <>
      {authorized && (
        <ImageList
          variant={templateLib.length > 2 ? "masonry" : "standard"}
          cols={2}
          gap={12}
        >
          {templateLib.map((item: any) => (
            <ImageListItem
              key={item.id}
              onMouseEnter={(e) => handlePopoverOpen(e, item)}
              onMouseLeave={handlePopoverClose}
            >
              <img
                style={{
                  borderRadius: "5px",
                  boxShadow: "3px 4px 4px rgb(0 0 0 / 12%)",
                }}
                src={item.attributes.attributes.thumbnail}
                alt={item.attributes.title}
                loading="lazy"
              />
              <Box
                sx={{
                  width: "100%",
                  minHeight: `calc(100% - 42px)`,
                  position: "absolute",
                  top: `0`,
                  margin: "0 auto",
                  background: "rgba(0,0,0,0.4)",
                  p: 0,
                  display: activeElement === item ? "block" : "none",
                }}
              >
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{ minHeight: "100%" }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "white",
                      color: "#3937A5",
                      ":hover": { backgroundColor: "#C7C7C7" },
                    }}
                    onClick={addTemplateToEditor}
                  >
                    <Typography variant="inter_500_10">Use this</Typography>
                  </Button>
                </Grid>
              </Box>
              <ImageListItemBar
                sx={{
                  maxWidth: "100px",
                }}
                title={
                  <Typography noWrap variant="inter_500_12">
                    {item.attributes.title}
                  </Typography>
                }
                position="below"
                // actionIcon={
                //   <Button
                //     aria-label={`select ${item.title}`}
                //     onClick={addTemplateToEditor}
                //   >
                //     <PreviewTemplatePopup
                //       extraSx={{
                //         color: theme.palette.primary.main,
                //         fontSize: "12px",
                //       }}
                //       templateObj={item}
                //     />
                //   </Button>
                // }
              />
              {/* <Grid container mt={2}>
                <Grid item>
                  <Typography variant="inter_500_12">
                    {item.attributes.title}
                  </Typography>
                </Grid>
              </Grid> */}
            </ImageListItem>
          ))}
        </ImageList>
      )}
      {!authorized && (
        <AuthorizationErrorHandler
          errorMessage="You don’t have access to see the list of templates. Please reach out to
        admin to get access"
        />
      )}
    </>
  );
}

export default memo(TemplateLibrary);
