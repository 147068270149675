import axios, { AxiosError } from "axios";
import { Navigate } from "react-router-dom";
import {
  getToken,
  refreshToken,
  removeToken,
  setToken,
} from "../services/authentication-service";

import { store } from "../store";
import { showMessage } from "../store/error-handler-store";
import { getCompanyId } from "../services/userinfo-service";
import { DEFAULT_ERROR_MESSAGE } from "../constants/constants";

export const requestInterceptor = () => {
  axios.interceptors.request.use((request) => {
    if (!request.url?.includes("iconify")) {
      request.headers = getExtraHeaders();
      return request;
    } else {
      return request;
    }
  });
};

export const responseInterceptor = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error: AxiosError) => {
      if (error.message != "canceled"){
        return handleErrors(error.toJSON(), error.response);
      }
    }
  );
};

const handleErrors = (err: any, res: any) => {
  const errors: any = {
    400: () => {
      return res;
    },
    406: () => {
      return res;
    },
    404: () => {
      return res;
    },
    401: () => {
      if (
        err &&
        err.status === 401 &&
        err.config.url.includes("/token/refresh")
      ) {
        removeToken();
        <Navigate to="/login"></Navigate>;
        return;
      }
      return handleTokenExpiry(err);
    },
    403: () => {
      return res;
    },
    417: () => {
      return res;
    },
    413: () => {
      store.dispatch(
        showMessage({
          message: "Request file is too large! Make sure it's under 10mb",
        })
      );
      return res;
    },
    500: () => {
      store.dispatch(
        showMessage({ message: res?.data?.message || DEFAULT_ERROR_MESSAGE })
      );
      return err;
    },
    default: () => {
      store.dispatch(showMessage({ message: DEFAULT_ERROR_MESSAGE }));
      return res;
    },
  };
  let state = errors[err && err.status] || errors["default"];
  return state();
};

const getExtraHeaders = () => {
  const token = getToken();
  const companyId = getCompanyId();
  return {
    Authorization: "Bearer " + token?.access || "",
    "company-id": companyId || "",
    "content-type": "application/json",
  };
};

const handleTokenExpiry = (error: AxiosError) => {
  return refreshToken().then((resp) => {
    if (resp) {
      let tokenInfo = getToken();
      tokenInfo.access = resp.data.access;
      setToken(tokenInfo);
      return axios.request(error.config);
    } else {
      <Navigate to="/login"></Navigate>;
    }
  });
};
