import { useEffect, useState } from "react";
import { theme } from "../../theme";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import CustomChip from "../atoms/CustomChip";
import { closeFilter } from "../../store/chip-state-store";
import { Grid, Tooltip } from "@mui/material";
import { setDate } from "../../store/date-range-setter-store";
import { has } from "lodash";

interface Props {
  currentFilters: any;
  onFilterApply: Function;
  allFilters: any;
  savedFilters: any;
  slug?: string;
}

const ChipsList = (props: Props) => {
  const selectedKPICurrentFilters: any = useAppSelector(
    (state) => state.filterState.selectedKPICurrentFilters
  );
  const dispatch = useAppDispatch();
  const { currentFilters, savedFilters, onFilterApply } = props;
  interface ChipData {
    key: number;
    label: string;
    filter: string;
    action: string;
  }

  const [chipList, setChipList] = useState<ChipData[]>([]);

  useEffect(() => {
    createChips();
  }, [selectedKPICurrentFilters]);

  const createChips = () => {
    let appliedFilters: any = { ...selectedKPICurrentFilters.data };
    setChipList([]);

    Object.keys(appliedFilters).forEach((filter: any, index: number) => {
      if (
        appliedFilters[filter] === "" ||
        appliedFilters[filter]?.label === "" ||
        !appliedFilters[filter] ||
        appliedFilters[filter].length === 0
      ) {
        delete appliedFilters[filter];
      }

      if (!appliedFilters[filter]) {
        return;
      }
      if (appliedFilters[filter] && appliedFilters[filter].length > 0) {
        if (appliedFilters[filter] instanceof Array) {
          appliedFilters[filter].forEach((ele: any, idx: number) => {
            createChipHelper(
              index + "_" + idx,
              ele["label"],
              filter,
              ele["action"],
              ele["value"]
            );
          });
        }
      } else if (
        filter === "daterange" ||
        filter.includes("Date") ||
        (has(appliedFilters[filter], "start") &&
          has(appliedFilters[filter], "end"))
      ) {
        let label =
          appliedFilters[filter]["start"] +
          " - " +
          appliedFilters[filter]["end"];

        createChipHelper(
          index,
          label,
          filter,
          appliedFilters[filter]["action"]
        );
      } else {
        if (appliedFilters[filter]) {
          createChipHelper(
            index,
            appliedFilters[filter]["label"],
            filter,
            appliedFilters[filter]["action"],
            appliedFilters[filter]["value"]
          );
        }
      }
    });
  };
  let chips: any = [];
  const createChipHelper = (
    key: any,
    label: string,
    filter: string,
    action?: string,
    value?: string
  ) => {
    chips.push({
      key: key,
      label: label,
      filter: filter,
      action: action,
      value: value,
    });

    setChipList(chips);
  };

  const reset = (key: string = "all") => {
    if (key === "all") {
      return savedFilters;
    } else {
      return savedFilters[key];
    }
  };

  const handleDelete = (filterToDelete: any) => {
    let _currentFilters: any = { ...currentFilters };
    if (filterToDelete?.action === "delete") {
      if (_currentFilters[filterToDelete["filter"]].length > 0) {
        let tempArr = _currentFilters[filterToDelete["filter"]];
        _currentFilters[filterToDelete["filter"]] = tempArr.filter(
          (ele: any) => ele.value !== filterToDelete.value
        );
      } else {
        delete _currentFilters[filterToDelete["filter"]];
      }
      setChipList(
        chipList.filter((chip) => chip.label !== filterToDelete.label)
      );
    } else {
      //reset the value.
      _currentFilters[filterToDelete?.filter] = reset(filterToDelete?.filter);
    }
    onFilterApply(_currentFilters);
  };

  const resetAll = () => {
    onFilterApply(reset("all"));
    setChipList([]);
  };

  return (
    <Grid container xs={12}>
      {chipList.map((ele, index) => {
        return (
          <Grid key={index} item>
            <CustomChip
              key={index}
              extraSX={{ marginRight: 2, marginBottom: 2 }}
              extraProps={{
                key: ele.key,
                onDelete: () => {
                  handleDelete(ele);
                },
                labelText: ele.label,
              }}
              closeIconProps={{
                fill: theme.CustomChip.closeIconColor,
                fontSize: "18px !important",
                marginRight: "12px !important",
              }}
              action={ele?.action || "delete"}
            />
          </Grid>
        );
      })}

      <Tooltip
        placement="top"
        title="Resets the filters to previously saved one"
        arrow
      >
        <Grid sx={{ marginLeft: "5px", marginBottom: 2 }} key={"reset"} item>
          <CustomChip
            extraProps={{
              onClick: resetAll,
              labelText: "Reset",
            }}
            labelProps={{ vairant: "inter_p_600_16" }}
            extraSX={{
              backgroundColor: theme.custom.whiteColor,
              color: theme.CustomChip.primaryBlue,
            }}
          />
        </Grid>
      </Tooltip>
    </Grid>
  );
};
export default ChipsList;
