import {
  Box,
  Grid,
  Typography,
  Backdrop,
  Fade,
  Modal,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../theme";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

const ExcelConnectorFileReplaceConfirm = (props: any) => {
  const { open, closeModal, confirmToReWriteTheFile } = props;

  const style = {
    position: "absolute" as "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -30%)",
    width: 500,
    height: 380,
    bgcolor: "#fff",
    border: `1px solid ${theme.custom.KPIMoleculeBorderColor}`,
    borderRadius: "6px",
    px: 4,
    py: 6,
    outline: "none",
  };

  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (open) {
      setOpenModal(open);
    }
  }, [open]);

  const onCloseModal = () => {
    confirmToReWriteTheFile(false);
    setOpenModal(false);
    closeModal();
  };

  const onConfirm = () => {
    confirmToReWriteTheFile(true);
    setOpenModal(false);
    closeModal();
  };
  return (
    <Modal
      sx={{ position: "absolute", zIndex: 2500 }}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openModal}
      onBackdropClick={onCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Grid container>
            <Grid textAlign={"center"} item xs={11}>
              <Typography
                sx={{ color: `${theme.custom.primaryDarkColor}` }}
                variant="poppins_h3_600_24"
              >
                All KPI's to All related KPI's will be replaced
              </Typography>
            </Grid>
            <Grid textAlign="right" item xs={1}>
              <CancelOutlinedIcon
                onClick={onCloseModal}
                sx={{
                  color: `${theme.custom.primaryGreyColor}`,
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
          <Grid justifyContent={"center"} mt={3} item xs={12}>
            <Typography
              sx={{ color: theme.custom.primaryDarkColor }}
              variant="inter_400_14"
            >
              Please not that if you update the file, all KPIs in the older file
              will be replaced by the ones in the new file. Are you sure that
              you want to continue?
            </Typography>
          </Grid>
          <Grid mt={6} container>
            <Grid item xs={12}>
              <Button
                onClick={onConfirm}
                sx={{ height: "50px" }}
                fullWidth
                variant="contained"
              >
                Yes, Continue
              </Button>
            </Grid>
            <Grid mt={3} textAlign={"center"} item xs={12}>
              <Typography
                onClick={onCloseModal}
                sx={{ cursor: "pointer", color: theme.custom.popoverLinkColor }}
                variant="poppins_p_600_14"
              >
                Cancel
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default React.memo(ExcelConnectorFileReplaceConfirm);
