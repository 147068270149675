import React, { useEffect, useState, useRef, useCallback } from "react";
function ShapesEditor(props: any) {
  const { element, updateElement } = props;
  const [dimension, setDimension] = useState({
    height: element.attributes.height,
    width: element.attributes.width,
  });

  useEffect(() => {
    setDimension({ height: element.height, width: element.width });
    updateElement(
      { height: element.height, width: element.width },
      "post-render"
    );
  }, [element.height]);

  const renderGradient = (id: string, gradient: string) => {
    let gradientStyle = gradient.slice(0, gradient.indexOf("#") - 3);
    let colorStops = gradient.slice(gradient.indexOf("#"), -1).split(",");
    let positions = {
      x1: "0%",
      x2: "0%",
      y1: "0%",
      y2: "0%",
    };
    switch (gradientStyle) {
      case "linear-gradient(to bottom":
        positions.y2 = "100%";
        break;
      case "linear-gradient(to top":
        positions.y1 = "100%";
        break;
      case "linear-gradient(to right":
        positions.x2 = "100%";
        break;
      case "linear-gradient(to left":
        positions.x1 = "100%";
        break;
      case "linear-gradient(to top left":
        positions.x1 = "100%";
        positions.y1 = "50%";
        break;
      case "linear-gradient(to top right":
        positions.x2 = "100%";
        positions.y1 = "50%";
        break;
      case "linear-gradient(to bottom left":
        positions.x1 = "100%";
        positions.y2 = "50%";
        break;
      case "linear-gradient(to bottom right":
        positions.x2 = "50%";
        positions.y2 = "100%";
        break;
      default:
        break;
    }

    const renderColorStop = (colorStop: string, index: number) => {
      const key = `color-stop-${index}`;
      const offset = (index / (colorStops.length - 1)) * 100 + "%";
      let stopColor = colorStop;
      let stopOpacity = 1.0;
      let stopProps = {
        key,
        offset,
        stopColor,
        stopOpacity,
      };
      return <stop {...stopProps} />;
    };

    return (
      <defs>
        {gradientStyle === "radial-gradient(circle" ? (
          <radialGradient id={id}>
            {colorStops.map(renderColorStop)}
          </radialGradient>
        ) : (
          <linearGradient
            id={id}
            x1={positions.x1}
            y1={positions.y1}
            x2={positions.x2}
            y2={positions.y2}
          >
            {colorStops.map(renderColorStop)}
          </linearGradient>
        )}
      </defs>
    );
  };

  return (
    <>
      <div
        style={{
          height: "100%",
          width: "100%",
        }}
      >
        <svg
          height="100%"
          width="100% "
          viewBox={`0 0 ${element.attributes?.viewBoxWidth || 100} ${
            element.attributes?.viewBoxHeight || 100
          }`}
          preserveAspectRatio="none"
        >
          {element?.attributes?.gradient
            ? renderGradient(
                `gradient${element.id}`,
                element?.attributes?.gradient
              )
            : null}
          <path
            stroke={element.attributes.strokeColor}
            strokeWidth={element.attributes.strokeWidth}
            fill={
              element?.attributes?.gradient
                ? `url(#gradient${element.id})`
                : element.attributes.fill
            }
            d={element.attributes.path}
            strokeDasharray={element.attributes.strokeDashArray}
            paintOrder="fill"
          ></path>
        </svg>
      </div>
    </>
  );
}

export default React.memo(ShapesEditor);
