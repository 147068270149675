import { Url } from "url";
import { environment } from "../environments/environment-dev";

export const IMPACT_REPORTS_GET_UPDATE_DELETE_URL = (uid: string) =>
  `${environment.connectorApiUrl}/impact-reporting/reports/${uid}`;

export const IMPACT_REPORTS_GET_PUBLIC_REPORT = (uid: string) =>
  `${environment.connectorApiUrl}/impact-reporting/public/reports/${uid}`;

export const IMPACT_REPORTS_SEND = (uid: string) =>
  `${environment.connectorApiUrl}/impact-reporting/reports/${uid}/send`;

export const IMPACT_REPORTS_SAVE_TEMPLATE = `${environment.connectorApiUrl}/impact-reporting/templates`;

export const IMPACT_REPORTS_PROMPT_RESOLVE = `${environment.connectorApiUrl}/impact-reporting/resolve-prompt`;

export const IMPACT_REPORTS_TEMPLATE_DETAILS = (templateId: string) =>
  `${environment.connectorApiUrl}/impact-reporting/templates/${templateId}`;

export const IMPACT_REPORTS_LINK = (uid: string) =>
  `${environment.connectorApiUrl}/impact-reporting/reports/${uid}/links`;

export const IMPACT_REPORTS_PDF = (report_link_uid: string) =>
  `${environment.connectorApiUrl}/impact-reporting/reports/${report_link_uid}/pdf`;

export const IMPACT_REPORTS_PUBLIC_PDF = (public_report_link_uid: string) =>
  `${environment.connectorApiUrl}/impact-reporting/reports/${public_report_link_uid}/public-pdf`;

export const IMPACT_REPORTS_GET_LIBRARY = (
  componentType?: string,
  page: number = 1,
  filterOptions?: any
) => {
  let url = `${environment.connectorApiUrl}/impact-reporting/library?page=${page}`;
  if (componentType) {
    url = `${url}&component_type=${componentType}`;
  }
  if (filterOptions) {
    let filterQueryParameters = [];
    for (const [k, v] of Object.entries(filterOptions)) {
      filterQueryParameters.push(`${k}=${v}`);
    }
    if (filterQueryParameters)
      url = `${url}&page_size=15&${filterQueryParameters.join("&")}`;
  }
  return url;
};

export const IMPACT_REPORTS_GET_LIBRARY_SETTINGS = (
  componentType?: string,
  page: number = 1
) => {
  let url = `${environment.connectorApiUrl}/impact-reporting/settings?page=${page}`;
  if (componentType) {
    url = `${url}&component_type=${componentType}`;
  }
  return url;
};

export const IMPACT_REPORTS_LIST_CREATE_URL = `${environment.connectorApiUrl}/impact-reporting/reports/`;

export const IMPACT_REPORTS_COMPONENT_LIBRARY_META = (componentType: string) =>
  `${environment.connectorApiUrl}/impact-reporting/${componentType}/meta`;

export const IMPACT_REPORTS_LIST_CREATE_UPLOADED_MEDIA = (
  page: number = 1,
  media_type: string = "IMAGE"
) => {
  return `${environment.connectorApiUrl}/impact-reporting/uploaded-media?page=${page}&media_type=${media_type}`;
};

export const IMPACT_REPORTS_LIST_CREATE_BRAND_MEDIA = (
  page: number = 1,
  media_type: string = "IMAGE"
) => {
  return `${environment.connectorApiUrl}/impact-reporting/brand-media?page=${page}&media_type=${media_type}`;
};

export const IMPACT_REPORTS_LIST_CREATE_BRAND_COLORS = () => {
  return `${environment.connectorApiUrl}/impact-reporting/brand-colors`;
};

export const IMPACT_REPORTS_LIST_CREATE_BRAND_LIBRARY = () => {
  return `${environment.connectorApiUrl}/impact-reporting/brand`;
};

export const IMPACT_REPORTS_UNSPLASH_SEARCH = (
  query: string,
  page: number,
  per_page: number = 30
) =>
  `${environment.connectorApiUrl}/impact-reporting/unsplash-search?query=${query}&per_page=${per_page}&page=${page}`;

export const IMPACT_REPORTS_UNSPLASH_POPULAR = (
  page: number,
  per_page: number = 30
) =>
  `${environment.connectorApiUrl}/impact-reporting/unsplash-popular?per_page=${per_page}&page=${page}`;

export const IMPACT_REPORTS_UNSPLASH_ANALYTICS = (url: String) =>
  `${environment.connectorApiUrl}/impact-reporting/unsplash-analytics?url=${url}`;
