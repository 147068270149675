import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { capitalize } from "lodash";
import { ChangeEvent, memo, useEffect, useState } from "react";
import { fontInter, theme } from "../../../theme";
import SelectDataSource from "../../complexes/KpiBuilder/SelectDataSource";
import { useDebounce } from "react-use";
import {
  ConfigsTypes,
  SupportedDataTypes,
} from "../../../Types/KPIBuilderChartType";
import {
  isNestedEmpty,
  filterOperatorsForSelectedDataType,
  operatorOptionsTransformer,
} from "../../../utils/kpi-builder-utils";

type Props = {
  updateDataSource: Function;
  id: string;
  seriesData: any;
  supported_data_types: SupportedDataTypes[];
  configs: ConfigsTypes[];
  slug: string;
};

const DataSourceXAxisComponent = (props: Props) => {
  const {
    id,
    seriesData,
    updateDataSource,
    supported_data_types,
    configs,
    slug,
  } = props;

  const [dataTypes, setDataTypes] = useState(
    supported_data_types as SupportedDataTypes[]
  );

  const init_state = {
    data_source: {
      columnId: "",
      columnName: "",
      sheetId: "",
      sheetName: "",
      workbookId: "",
      workbookName: "",
      thumbnail: "",
    },
    data_type_selected: {
      name: "",
      value: "",
    },
    axis_name: "",
    operator: "",
  };
  const [xAxisSeries, setXAxisSeries] = useState(init_state);
  const [debouncedAxisName, setDebouncedAxisName] = useState(xAxisSeries.axis_name);
  const [operatorOptions, setAllOperatorOptions] = useState([] as any);
  const [isSeriesValid, setIsSeriesValid] = useState<boolean>(false);

  const [, cancel] = useDebounce(
    () => {
      updateDataSource(xAxisSeries, slug);
    },
    1000,
    [debouncedAxisName]
  );

  useEffect(() => {
    setXAxisSeries(seriesData);
    populateOperatorOptions(
      seriesData?.["data_type_selected"]?.["value"] || ""
    );

    validateSeries();
  }, [seriesData]);

  useEffect(() => {
    let _xSeries = { ...seriesData };
    _xSeries.isValid = isSeriesValid;
    updateDataSource(_xSeries, slug);
  }, [isSeriesValid]);

  const validateSeries = () => {
    let _xSeries = { ...seriesData };
    if (
      isNestedEmpty(_xSeries["data_type_selected"]) ||
      isNestedEmpty(_xSeries["data_source"]) ||
      !_xSeries["axis_name"]
    ) {
      
      setIsSeriesValid(false);
    } else {
      setIsSeriesValid(true);
    }
  };

  const updateDataSourceSelection = (data: any) => {
    let _xSeries = { ...xAxisSeries };
    _xSeries.data_source = data;
    updateDataSource(_xSeries, slug);
  };

  const handleChangeAxisName = (event: ChangeEvent<HTMLInputElement>) => {
    let _xSeries = { ...xAxisSeries };
    _xSeries.axis_name = event.target.value;
    setDebouncedAxisName(event.target.value);
    setXAxisSeries(_xSeries);
  };

  const onDataTypeChange = (event: any) => {
    let value = event.target.value;
    let _xSeries: any = { ...xAxisSeries };

    if (!value) {
      _xSeries.data_type_selected = {
        name: "",
        value: "",
      };
    } else {
      _xSeries.data_type_selected = {
        name: value.split(" ").map(capitalize).join(" "),
        value: value,
      };
    }
    _xSeries["operator"] = "none";

    updateDataSource(_xSeries, slug);
    if (value) {
      populateOperatorOptions(value);
    }
  };

  const populateOperatorOptions = (value: string) => {
    if (!value) {
      return;
    }
    let filteredConfigOptions = filterOperatorsForSelectedDataType(
      configs,
      value
    );
    if (filteredConfigOptions.length) {
      setAllOperatorOptions(
        operatorOptionsTransformer(filteredConfigOptions[0]["operator_list"]) ||
          []
      );
    }
  };

  const onOperatorChange = (event: any) => {
    let value = event.target.value;
    let _xSeries: any = { ...xAxisSeries };

    if (!value) {
      _xSeries.operator = "";
    } else {
      _xSeries.operator = value;
    }
    updateDataSource(_xSeries, slug);
  };

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} width={"100%"}>
        <Grid item sm={3}>
          <SelectDataSource
            savedData={xAxisSeries.data_source}
            updateDataSourceSelection={updateDataSourceSelection}
          />
        </Grid>
        <Grid item sm={1.7}>
          <TextField
            size="small"
            select
            label={
              <Typography
                variant="inter_500_12"
                sx={{ color: theme.custom.primaryGreyColor }}
              >
                Data Type
              </Typography>
            }
            value={xAxisSeries.data_type_selected?.value || ""}
            fullWidth
            onChange={(e) => onDataTypeChange(e)}
          >
            {dataTypes &&
              dataTypes.length > 0 &&
              dataTypes.map((ele: any) => {
                return (
                  <MenuItem sx={{ height: 40 }} key={ele.slug} value={ele.slug}>
                    <Typography variant="inter_p_500_14" mt={0.3}>
                      {ele.name}
                    </Typography>
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
        <Grid item sm={1.7}>
          <TextField
            inputProps={{
              maxLength: 50,
              style: {
                fontFamily: fontInter,
                fontSize: "14px",
                fontWeight: 500,
                color: theme.custom.primaryDarkColor,
              },
            }}
            value={xAxisSeries.axis_name || ""}
            size="small"
            label={
              <Typography
                variant="inter_500_12"
                sx={{ color: theme.custom.primaryGreyColor }}
              >
                Axis name
              </Typography>
            }
            fullWidth
            onChange={handleChangeAxisName}
          />
        </Grid>
        <Grid item sm={1.7}>
          <TextField
            label={
              <Typography
                variant="inter_500_12"
                sx={{ color: theme.custom.primaryGreyColor }}
              >
                Operator
              </Typography>
            }
            select
            id={"operator" + id}
            size="small"
            value={xAxisSeries.operator}
            fullWidth
            onChange={(e) => onOperatorChange(e)}
          >
            <MenuItem value="none">
              <Typography variant="inter_p_500_14" mt={0.3}>
                None{" "}
              </Typography>
            </MenuItem>
            {operatorOptions &&
              operatorOptions.length > 0 &&
              operatorOptions.map((ele: any) => {
                return (
                  <MenuItem
                    sx={{ height: 40 }}
                    key={ele.value || ""}
                    value={ele.value}
                  >
                    <Typography variant="inter_p_500_14" mt={0.3}>
                      {ele.label}
                    </Typography>
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
      </Grid>
    </>
  );
};

export default memo(DataSourceXAxisComponent);
