import {
  Stack,
  Box,
  Grid,
  Typography,
  TextField,
  MenuItem,
  Button,
  Fade,
  Modal,
  Divider,
  CircularProgress,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { Sidebar } from "../components/complexes/Sidebar";
import { fontInter, theme } from "../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SelectChartType from "../components/complexes/KpiBuilder/SelectChartType";
import {
  addBoard,
  getAllChartTypes,
  getAllCustomKPIBoards,
  getConfigs,
  getKPIDataForSlug,
  saveCustomKPI,
} from "../services/kpi-builder-service";
import DataSourceDefinition from "../components/complexes/KpiBuilder/DataSourceDefinition";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch, useAppSelector } from "../store/hook";
import {
  each,
  every,
  filter,
  find,
  findIndex,
  flatten,
  isEmpty,
  map,
  orderBy,
  reject,
  set,
  some,
} from "lodash";
import DataSourceThumbnail from "../assets/datasource_thumbnail.png";
import CustomizedCheckbox from "../components/atoms/CustomizedCheckbox";
import { SubMenuItem } from "../Types/SubMenuItem";
import { updateKPISlug } from "../store/kpi-builder-store";
import AddIcon from "@mui/icons-material/Add";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { previewFormatter } from "../utils/kpi-builder-utils";
import { LoadingButton } from "@mui/lab";
import { showMessage } from "../store/error-handler-store";
import { nanoid } from "nanoid";

import CustomKPISelectFilter from "../components/molecules/KPIBuilder/CustomKPISelectFilter";
import { filterTypeObject } from "../Types/FilterTypeObject";
import AuthorizationErrorHandler from "../components/molecules/Editor/AuthorizationErrorHandler";
import { KPIBuilderChartType } from "../Types/KPIBuilderChartType";
import KPIPreview from "../components/molecules/KPIBuilder/KPIPreview";
import { DataSource } from "../Types/KPIBuilderSeriesTypes";
import { resetFilters } from "../store/datasource-store";

type Props = {};

const INIT_FILTER = {
  id: "",
  workbookId: "",
  workbookName: "",
  sheetId: "",
  sheetName: "",
  columnId: "",
  columnName: "",
  thumbnail: "",
  filterType: "",
};

const INIT_KPI_BUILDER_STATE = {
  chart_type: {
    kpi_title: "",
    chart_type: "",
    aggregation_type: "",
    chart_type_title: "",
    series_type_info: {},
    unit: "",
  },
  data_source: {
    series: {} as any,
    order_by: {
      column_name: "",
      connection_id: "",
      table: "",
      order_by_type: "",
      id: "",
      label: "",
    },
  },
  filter_columns: [] as any,
  board_slug: "",
};

const KPIBuilderPage = (props: Props) => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isDirty, setIsDirty] = useState(true);
  const [validationMessage, setValidationMessage] = useState<string>("");
  const [allChartTypes, setAllChartTypes] = useState([] as any);
  const [showBoardOptions, setShowBoardOptions] = useState(false);
  const [boardList, setBoardList] = useState([] as SubMenuItem[]);
  const [selectedBoard, setSelectedBoard] = useState("");
  const [isAddLoading, setIsAddLoading] = useState(false);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [allFilters, setAllFilters] = useState([] as any);
  const [seriesLength, setSeriesLength] = useState({} as any);
  const [kpiBuilderState, setKPIBuilderState] = useState(
    INIT_KPI_BUILDER_STATE
  );

  const [authorized, setAuthorized] = useState(false);
  const [loading, setLoading] = useState(false);

  const [filterTypes, setFilterTypes] = useState([] as filterTypeObject[]);
  const [allConfigs, setAllConfigs] = useState({} as any);
  const [dataForPreview, setDataForPreview] = useState({} as any);
  const [isValid, setIsValid] = useState({
    seriesValid: false,
    chartTypeValid: false,
    uniqueAxisNameValid: false,
  } as any);

  const [availableFilters, setAvailableFilters] = useState([] as any);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, 50%)",
    width: 500,
    bgcolor: "#fff",
    borderRadius: "6px",
    border: `1px solid #ccc`,
    p: 4,
    outline: "none",
  };

  const [open, setOpen] = useState(false);
  const [boardName, setBoardName] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [alignment, setAlignment] = React.useState("chart");
  const [showTable, setShowTable] = useState(false);

  // useEffect(() => {
  //   setAvailableFilters(allFilters);
  // }, [allFilters]);
  useEffect(() => {
    dispatch(resetFilters({}));
    setAuthorized(true);
    setLoading(false);
    let _kpiBuilderState = { ...kpiBuilderState };
    if (_kpiBuilderState["filter_columns"].length > 0) {
      return;
    } else {
      _kpiBuilderState["filter_columns"] = [{ ...INIT_FILTER, id: nanoid(10) }];
      setKPIBuilderState(_kpiBuilderState);
    }
  }, []);

  const updateAllFilterOptions = (options: any) => {
    setAllFilters(options);
  };

  const updateValidationMessage = (message: string) => {
    setValidationMessage(message);
  };

  const formatSavedData = (saved_data: any) => {
    let _kpiBuilderState = { ...kpiBuilderState };
    _kpiBuilderState.board_slug = saved_data.board.board_slug;
    let _chartType = {
      kpi_title: saved_data.kpi_title || "",
      chart_type: saved_data.chart_type.chart_type || "",
      aggregation_type: saved_data.aggregation || "",
      chart_type_title: saved_data.chart_type.label || "",
      series_type_info: saved_data.chart_type.series_type_info || "",
      unit: saved_data?.unit || "",
    };
    _kpiBuilderState.chart_type = _chartType;

    let _series: any = {};

    each(saved_data.chart_data, (value, key) => {
      set(
        _series,
        [key, "data"],
        value.map((_val: any) => {
          return formatSeriesData(_val, key);
        })
      );

      let _seriesType = _chartType.series_type_info["series_info"];

      each(_seriesType, (ele: any) => {
        _series[ele["slug"]] = {
          ...ele,
          data: _series[ele["slug"]]?.data || [],
        };
      });
    });
    _kpiBuilderState.data_source.series = _series;
    let _orderBy;
    if (!isEmpty(saved_data["order_by_column"]["axis"] || {})) {
      _orderBy = { ...saved_data["order_by_column"]["axis"], id: nanoid(10) };
      _kpiBuilderState.data_source.order_by = _orderBy;
    }
    if (saved_data["filter_columns"].length > 0) {
      let _filters = saved_data["filter_columns"].map((element: any) => {
        return {
          id: nanoid(10),
          workbookId: element["workbook"]["value"] || "",
          workbookName: element["workbook"]["label"] || "",
          sheetId: element["sheet"]["value"] || "",
          sheetName: element["sheet"]["label"] || "",
          columnId: element["column"]["value"] || "",
          columnName: element["column"]["label"] || "",
          filterType: element["filter_type"] || "",
        };
      });
      _kpiBuilderState.filter_columns = _filters;

      // filterUnselected(_filters);
    } else {
      _kpiBuilderState.filter_columns = [{ ...INIT_FILTER, id: nanoid(10) }];
    }
    setKPIBuilderState(_kpiBuilderState);
  };

  useEffect(() => {
    filterUnselected(kpiBuilderState.filter_columns);
  }, [kpiBuilderState.filter_columns, allFilters]);

  useEffect(() => {
    let _series = { ...kpiBuilderState.data_source.series };
    let _len: any = {};

    each(_series, (value, key) => {
      if (["y", "z"].includes(key)) {
        set(_len, [key], value.data.length);
      }
    });
    setSeriesLength(_len);
  }, [JSON.stringify(kpiBuilderState.data_source.series)]);

  const formatSeriesData = (_seriesData: any, slug: string) => {
    if (["y", "z"].includes(slug)) {
      return {
        key: nanoid(10),
        data_source: {
          columnId: _seriesData["column"]["value"] || "",
          columnName: _seriesData["column"]["label"] || "",
          sheetId: _seriesData["sheet"]["value"] || "",
          sheetName: _seriesData["sheet"]["label"] || "",
          workbookId: _seriesData["workbook"]["value"] || "",
          workbookName: _seriesData["workbook"]["label"] || "",
          thumbnail: _seriesData["thumbnail"] || "",
        },
        data_type_selected: _seriesData["data_type"] || "",
        axis_name: _seriesData["alias"] || "",
        joining_column: {
          connection_id:
            _seriesData?.["y_joining_column"]?.["workbook"]?.["value"] || "",
          table: _seriesData?.["y_joining_column"]?.["sheet"]?.["value"] || "",
          column_name:
            _seriesData?.["y_joining_column"]?.["column"]?.["value"] || "",
        },
        base_column: {
          connection_id:
            _seriesData?.["x_joining_column"]?.["workbook"]?.["value"] || "",
          table: _seriesData?.["x_joining_column"]?.["sheet"]?.["value"] || "",
          column_name:
            _seriesData?.["x_joining_column"]?.["column"]?.["value"] || "",
        },
        operator: _seriesData["operator"] || "",
      };
    } else {
      return {
        key: nanoid(10),
        data_source: {
          columnId: _seriesData["column"]["value"] || "",
          columnName: _seriesData["column"]["label"] || "",
          sheetId: _seriesData["sheet"]["value"] || "",
          sheetName: _seriesData["sheet"]["label"] || "",
          workbookId: _seriesData["workbook"]["value"] || "",
          workbookName: _seriesData["workbook"]["label"] || "",
          thumbnail: _seriesData["thumbnail"] || "",
        },
        data_type_selected: _seriesData["data_type"] || "",
        axis_name: _seriesData["alias"] || "",
        operator: _seriesData["operator"] || "",
      };
    }
  };

  useEffect(() => {
    let title = "";
    dispatch(updateKPISlug(""));
    if (params.slug_name) {
      getKPIData(params.slug_name);
      title = "Edit custom KPI - Pebble";
    } else {
      title = "Create custom KPI - Pebble";
      clearData();
    }
    document.title = title || "Pebble";
  }, []);

  useEffect(() => {
    getAllChartTypes().then((res) => {
      if (res.status === 200) {
        setAllChartTypes(
          orderBy(res.data.data, (item) => item.series_type_info.position, [
            "asc",
          ])
        );
      }
    });
    getAllConfigs();
  }, []);

  useEffect(() => {
    if (showBoardOptions) {
      getAllBoards();
    } else {
      setSelectedBoard("");
    }
  }, [showBoardOptions]);

  const clearData = () => {
    setKPIBuilderState({
      chart_type: {
        kpi_title: "",
        chart_type: "",
        aggregation_type: "",
        chart_type_title: "",
        series_type_info: {},
        unit: "",
      },
      data_source: {
        series: {} as any,
        order_by: {
          column_name: "",
          connection_id: "",
          table: "",
          order_by_type: "",
          id: "",
          label: "",
        },
      },
      filter_columns: [{ ...INIT_FILTER, id: nanoid(10) }],
      board_slug: "",
    });
  };

  const updateChartTypeInfo = (chartTypeInfo: KPIBuilderChartType) => {
    let _kpiBuilderState = { ...kpiBuilderState };
    _kpiBuilderState["chart_type"] = chartTypeInfo;
    setKPIBuilderState(_kpiBuilderState);
  };

  const getAllBoards = () => {
    getAllCustomKPIBoards().then((res) => {
      if (res.status === 200) {
        if (res.data.menu.length > 0) {
          let submenus = res.data.menu[0]["sub_menu"];
          setBoardList(
            submenus.map((ele: any) => {
              return {
                value: ele["board_slug"],
                label: ele["title"],
              };
            })
          );
        }
      }
    });
  };

  const getKPIData = (slug: string) => {
    setLoading(true);
    getKPIDataForSlug(slug).then((res: any) => {
      if (res.status === 403) {
        setAuthorized(false);
        setLoading(false);
        return;
      }
      if (res.status === 200) {
        setAuthorized(true);
        setLoading(false);

        let data = res.data.data;
        formatSavedData(data);

        setSelectedBoard(data.board.board_slug || "");
        setShowBoardOptions(data?.board?.board_slug ? true : false);
      }
    });
  };

  const getAllConfigs = () => {
    getConfigs().then((res) => {
      if (res.status === 200) {
        setAllConfigs(res.data.data);
        let _configs = res.data.data.filter_datatype_mapping;
        if (_configs) {
          setFilterTypes(
            _configs.map((el: any) => {
              return {
                label: el["datatype"],
                value: el["datatype_slug"],
              };
            })
          );
        }
      }
    });
  };

  const addNewBoard = () => {
    setIsAddLoading(true);
    addBoard(boardName).then((res) => {
      if (res.status === 201) {
        getAllBoards();
        handleClose();
        setSelectedBoard(res.data.board_slug);
        setSelectedBoard(res.data.board_slug);
        let _kpiBuilderState = { ...kpiBuilderState };
        _kpiBuilderState.board_slug = res.data.board_slug;
        setKPIBuilderState(_kpiBuilderState);
        dispatch(
          showMessage({ message: "New board added.", variant: "success" })
        );
      }
      if (res.status === 400) {
        dispatch(showMessage({ message: res.data.message }));
      }
      setIsAddLoading(false);
    });
  };

  useEffect(() => {
    let _series = { ...kpiBuilderState.data_source.series };
    let _chartType = { ...kpiBuilderState.chart_type };
    let _allSeriesData: any = [];
    let _isValid = { ...isValid };

    if (
      (_chartType["chart_type"] === "pie" &&
        _series?.["y"]?.data &&
        _series?.["y"]?.data.length > 1) ||
      (_series?.["z"]?.data && _series?.["z"]?.data.length > 1)
    ) {
      isValid["seriesValid"] = false;
      updateValidationMessage("Pie Chart Supports minimum 0 y axis and maximum 1 y axis");
      return;
    } else {
      _isValid["seriesValid"] = true;
      updateValidationMessage("");
    }

    each(_series, (v, k) => {
      _allSeriesData = [..._allSeriesData, ...v["data"]];
    });

    if (!find(_allSeriesData, (ele: any) => ele["isValid"] === false)) {
      _isValid["seriesValid"] = true;
      updateValidationMessage("");
    } else {
      _isValid["seriesValid"] = false;
      updateValidationMessage("Select valid data series to see the preview");
    }
    if (
      _allSeriesData &&
      _allSeriesData.length > 0 &&
      new Set(map(_allSeriesData, (ele: any) => ele["axis_name"])).size <
        _allSeriesData.length
    ) {
      _isValid["uniqueAxisNameValid"] = false;
      updateValidationMessage(
        "Duplicates are not allowed while entering the axis names"
      );
    } else {
      _isValid["uniqueAxisNameValid"] = true;
      updateValidationMessage("");
    }

    if (!_chartType.chart_type) {
      updateValidationMessage("");
      return;
    }
    if (!_chartType.kpi_title) {
      _isValid["chartTypeValid"] = false;
      updateValidationMessage("KPI Title cannot be blank.");
    } else {
      _isValid["chartTypeValid"] = true;
      updateValidationMessage("");
    }

    setIsValid(_isValid);
  }, [
    JSON.stringify(kpiBuilderState.data_source.series),
    JSON.stringify(kpiBuilderState.chart_type),
  ]);

  const onSave = () => {
    if (selectedBoard.length === 0) {
      dispatch(showMessage({ message: "Please select a board to save" }));
      return;
    }
    if (checkDataValid() && selectedBoard !== "") {
      let dataToBeSaved = previewFormatter(kpiBuilderState, false);

      dataToBeSaved.board_slug = selectedBoard;

      let isFiltersValid = true;
      let isFilterTypeValid = true;

      if (
        dataToBeSaved["filter_columns"] &&
        dataToBeSaved["filter_columns"].length > 0
      ) {
        dataToBeSaved["filter_columns"].forEach(
          (element: any, index: number) => {
            if (!some(element, isEmpty)) {
              let _filterCols = [...dataToBeSaved["filter_columns"]];
              _filterCols.splice(index, 1);

              if (_filterCols.length) {
                dataToBeSaved["filter_columns"] = _filterCols;
              } else {
                delete dataToBeSaved["filter_columns"];
              }
              return;
            }
            if (element.filter_type === "") {
              isFilterTypeValid = false;
            } else {
              isFilterTypeValid = true;
            }
          }
        );
        each(dataToBeSaved["filter_columns"], (ele: any) => {
          if (Object.values(ele).some((o) => o === "")) {
            isFiltersValid = false;
          } else {
            isFiltersValid = true;
          }
        });
      }

      if (!isFiltersValid) {
        dispatch(showMessage({ message: "Please select a filter" }));
        return;
      }

      if (!isFilterTypeValid) {
        dispatch(showMessage({ message: "Please select filter type" }));
        return;
      }

      if (params?.slug_name) {
        dataToBeSaved["kpi_slug"] = params.slug_name;
      }

      setIsSaveLoading(true);
      saveCustomKPI(dataToBeSaved).then((res) => {
        if (res.status === 200) {
          setIsSaveLoading(false);
          dispatch(
            showMessage({
              message: "KPI Saved successfully",
              variant: "success",
            })
          );
          clearData();
          backButtonNavigation();
        } else {
          setIsSaveLoading(false);
          dispatch(
            showMessage({
              message: "Failed to save the KPI",
            })
          );
        }
      });
    }
  };

  const backButtonNavigation = () => {
    let url = `/metrics/custom-kpis#kpi`;
    navigate(url);
  }

  const handleChangeBoardSelected = (board: string) => {
    setSelectedBoard(board);
    let _kpiBuilderState = { ...kpiBuilderState };
    _kpiBuilderState.board_slug = board;
    setKPIBuilderState(_kpiBuilderState);
  };

  const onSelectFilter = (id: string, filter: DataSource) => {
    let _kpiBuilderState: any = { ...kpiBuilderState };
    let _filters = [..._kpiBuilderState.filter_columns];
    let newList = _filters.map((_data: DataSource) => {
      if (_data.id === id) {
        return { ..._data, ...filter };
      }
      return { ..._data };
    });
    _kpiBuilderState.filter_columns = newList;
    filterUnselected(newList);
    setKPIBuilderState(_kpiBuilderState);
  };

  const filterUnselected = (filters: any) => {
    const filtered = allFilters.filter(
      (obj1: any) =>
        !filters.find(
          (obj2: any) =>
            obj1.workbookId === obj2.workbookId &&
            obj1.sheetId === obj2.sheetId &&
            obj1.columnId === obj2.columnId
        )
    );
    if (filtered.length) {
      setAvailableFilters(filtered);
    } else {
      setAvailableFilters(allFilters);
    }
  };

  const addNewFilter = () => {
    let _kpiBuilderState: any = { ...kpiBuilderState };
    _kpiBuilderState.filter_columns = [
      ..._kpiBuilderState.filter_columns,
      { ...INIT_FILTER, id: nanoid(10) },
    ];
    setKPIBuilderState(_kpiBuilderState);
  };

  const deleteFilter = (filterId: string) => {
    let _kpiBuilderState: any = { ...kpiBuilderState };
    let _filters = [..._kpiBuilderState.filter_columns];
    let deletedFilter = _filters[findIndex(_filters, { id: filterId })];
    let _availableFilters = [...availableFilters];
    _availableFilters.push(
      find(allFilters, (ele) => {
        if (
          ele["columnId"] === deletedFilter["columnId"] &&
          ele["sheetId"] === deletedFilter["sheetId"] &&
          ele["workbookId"] === deletedFilter["workbookId"]
        ) {
          return ele;
        }
      })
    );
    setAvailableFilters(_availableFilters);
    _filters.splice(findIndex(_filters, { id: filterId }), 1);
    _kpiBuilderState.filter_columns = _filters;

    setKPIBuilderState(_kpiBuilderState);
  };

  const updateIsDirty = (_isDirty: boolean) => {
    setIsDirty(_isDirty);
  };

  const updateDataSource = (data_source: any) => {
    let _kpiBuilderState = { ...kpiBuilderState };
    _kpiBuilderState["data_source"]["series"] = {
      ...data_source,
    };
    setKPIBuilderState(_kpiBuilderState);
  };

  const deleteAllTables = () => {
    let tables = document.getElementsByClassName("highcharts-data-table");
    if (tables && tables.length) {
      for (var i = 0; i < tables.length; i++) {
        tables[i].remove();
      }
    }
  };

  useEffect(() => {
    setDataForPreview(previewFormatter(kpiBuilderState));
    setAlignment("chart");
    setShowTable(false);
    deleteAllTables();
  }, [
    JSON.stringify(kpiBuilderState.data_source),
    JSON.stringify(kpiBuilderState.chart_type),
  ]);

  const checkDataValid = () => {
    return Object.values(isValid).filter((el) => el === false).length > 0
      ? false
      : true;
  };

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    setAlignment(newAlignment);
    if (newAlignment === "table") {
      setShowTable(true);
    } else {
      setShowTable(false);
    }
  };

  return (
    <React.Fragment>
      <Stack direction="row">
        <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
          <Sidebar />
        </Box>
        <Box sx={{ width: "100%" }} p={8}>
          <Grid container>
            <Grid
              onClick={backButtonNavigation}
              item
            >
              <ArrowBackIcon
                sx={{ color: theme.custom.primaryDarkColor, cursor: "pointer" }}
              />
            </Grid>
            <Grid item>
              <Typography variant="poppins_h5_600_18" ml={2} mb={3}>
                Add KPI
              </Typography>
            </Grid>
          </Grid>
          {authorized && !loading && (
            <Box>
              <Grid container justifyContent={"center"}>
                <Grid item sm={6}>
                  <Grid item sm={12} mb={2}>
                    <Typography variant="inter_p_600_16">
                      Select chart type
                    </Typography>
                  </Grid>
                  <Grid key={"select-chart-type"} item sm={12} mb={2}>
                    <SelectChartType
                      seriesLength={seriesLength}
                      updateChartType={updateChartTypeInfo}
                      savedChartTypeData={kpiBuilderState.chart_type || {}}
                      allChartTypes={allChartTypes}
                    />
                  </Grid>
                </Grid>
                <Grid item sm={6}>
                  <Grid container>
                    <Grid item sm={7} mb={3}>
                      <Typography variant="inter_p_600_16">Preview</Typography>
                    </Grid>
                    <Grid item sm={5} mb={1.5} textAlign={"right"}>
                      {kpiBuilderState &&
                        kpiBuilderState?.chart_type?.chart_type !== "table" &&
                        kpiBuilderState?.chart_type?.chart_type !== "pivot" && (
                          <Grid item>
                            <ToggleButtonGroup
                              color="primary"
                              value={alignment}
                              exclusive
                              className="rounded-toggle"
                              onChange={handleChange}
                              aria-label="Switch"
                            >
                              <ToggleButton
                                sx={{
                                  "&.Mui-selected, &.Mui-selected:hover": {
                                    color: "white",
                                    backgroundColor: "#6E6E81",
                                  },
                                }}
                                value="chart"
                              >
                                <Typography variant={"inter_p_600_14"} ml={1}>
                                  Chart
                                </Typography>
                              </ToggleButton>
                              <ToggleButton
                                sx={{
                                  "&.Mui-selected, &.Mui-selected:hover": {
                                    color: "white",
                                    backgroundColor: "#6E6E81",
                                  },
                                }}
                                value="table"
                              >
                                Table
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>
                        )}
                    </Grid>
                  </Grid>

                  <Grid item sm={12}>
                    <Grid
                      className={
                        showTable
                          ? "kpi-preview-container-hide"
                          : "kpi-preview-container-table-hide"
                      }
                      p={
                        kpiBuilderState?.chart_type?.chart_type === "table" ||
                        kpiBuilderState?.chart_type?.chart_type === "pivot"
                          ? 0
                          : 3
                      }
                      sx={{
                        height: 400,
                        width: "100%",
                        borderRadius: "6px",
                        position: "relative",
                        border: `1px solid ${theme.custom.menuDividerColor}`,
                        overflow:
                          showTable ||
                          kpiBuilderState?.chart_type?.chart_type === "table" ||
                          kpiBuilderState?.chart_type?.chart_type === "pivot"
                            ? "auto"
                            : "hidden",
                      }}
                      item
                      sm={12}
                    >
                      <KPIPreview
                        updateValidationMessage={updateValidationMessage}
                        previewData={dataForPreview}
                        showTable={showTable}
                        builderState={kpiBuilderState}
                        isValid={isValid}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          )}
          {authorized && !loading && (
            <Box
              p={3}
              mt={4}
              sx={{
                position: "relative",
                height:
                  kpiBuilderState &&
                  kpiBuilderState.chart_type &&
                  kpiBuilderState.chart_type.chart_type.length
                    ? "auto"
                    : 400,
                border: `1px solid ${theme.custom.menuDividerColor}`,
                borderRadius: "6px",
              }}
            >
              <Box>
                <DataSourceDefinition
                  updateDataSourceInfo={updateDataSource}
                  updateOrderBy={(order_by: any) => {
                    let _kpiBuilderState = { ...kpiBuilderState };
                    _kpiBuilderState["data_source"]["order_by"] = {
                      ...order_by,
                    };
                    setKPIBuilderState(_kpiBuilderState);
                  }}
                  seriesInfo={kpiBuilderState.data_source}
                  seriesTypeInfo={kpiBuilderState.chart_type.series_type_info}
                  configs={allConfigs?.["datatype_operator_mapping"] || []}
                  updateFilterOptions={updateAllFilterOptions}
                  isDirty={isDirty}
                  validationMessage={validationMessage}
                  updateIsDirty={updateIsDirty}
                  chartTypeInfo={kpiBuilderState?.chart_type || {}}
                  isValid={isValid}
                  units={allConfigs?.["units_mapping"] || []}
                  updateUnit={(value: string) => {
                    let _kpiBuilderState = { ...kpiBuilderState };
                    _kpiBuilderState["chart_type"]["unit"] = value;
                    setKPIBuilderState(_kpiBuilderState);
                  }}
                />
              </Box>
              {kpiBuilderState &&
                kpiBuilderState.chart_type &&
                kpiBuilderState.chart_type.chart_type.length === 0 && (
                  <Grid
                    sx={{
                      textAlign: "center",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                    item
                  >
                    <img src={DataSourceThumbnail} height="60" alt="" />
                    <Typography
                      sx={{ color: theme.custom.primaryGreyColor }}
                      variant="inter_500_12"
                      mt={1}
                    >
                      Select a chart to add data sources
                    </Typography>
                  </Grid>
                )}
            </Box>
          )}
          {!isDirty && authorized && !loading && checkDataValid() && (
            <Box mt={3}>
              <Stack direction={"row"}>
                <Box>
                  <CustomizedCheckbox
                    isChecked={showBoardOptions}
                    onChange={(value: boolean) => {
                      setShowBoardOptions(value);
                    }}
                    labelProps={{
                      title: "Add to Board",
                      extraSx: {
                        variant: "inter_p_600_16",
                        color: theme.custom.primaryDarkColor,
                      },
                    }}
                  />
                </Box>
                {showBoardOptions && (
                  <Box>
                    <TextField
                      size="small"
                      select
                      sx={{ minWidth: 150 }}
                      label={
                        <Typography
                          variant="inter_500_12"
                          sx={{ color: theme.custom.primaryGreyColor }}
                        >
                          Linked board
                        </Typography>
                      }
                      value={kpiBuilderState.board_slug || ""}
                      fullWidth
                      onChange={(event) =>
                        handleChangeBoardSelected(event.target.value)
                      }
                    >
                      <MenuItem
                        onClick={handleOpen}
                        sx={{
                          height: 40,
                          borderBottom: `1px solid ${theme.custom.borderColor}`,
                        }}
                        key={"add"}
                        value={""}
                      >
                        <Stack alignItems={"center"} direction={"row"}>
                          <AddIcon sx={{ marginRight: 1 }} />
                          <Typography variant="inter_p_500_14" mt={0.3}>
                            Add new
                          </Typography>
                        </Stack>
                      </MenuItem>
                      {boardList &&
                        boardList.length > 0 &&
                        boardList.map((ele: SubMenuItem, index: number) => {
                          return (
                            <MenuItem
                              sx={{
                                height: 40,
                                borderBottom: `1px solid ${theme.custom.borderColor}`,
                              }}
                              key={index}
                              value={ele.value}
                            >
                              <Typography variant="inter_p_500_14" mt={0.3}>
                                {ele.label}
                              </Typography>
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Box>
                )}{" "}
                <br />
              </Stack>
              <Box mt={2}>
                <Box mb={2}>
                  <Typography
                    sx={{ color: theme.custom.primaryDarkColor }}
                    variant="inter_p_600_16"
                  >
                    Add Filters
                  </Typography>
                </Box>
                {kpiBuilderState.filter_columns &&
                  kpiBuilderState.filter_columns.length > 0 &&
                  kpiBuilderState.filter_columns.map(
                    (_filter: any, index: number) => {
                      return (
                        <Box my={2}>
                          <CustomKPISelectFilter
                            id={_filter["id"]}
                            savedFilter={_filter}
                            filtersList={availableFilters}
                            onSelectFilter={onSelectFilter}
                            filterTypes={filterTypes}
                            onDeleteFilter={deleteFilter}
                          />
                        </Box>
                      );
                    }
                  )}

                {kpiBuilderState.filter_columns.length !==
                  allFilters.length && (
                  <Button
                    onClick={addNewFilter}
                    style={{ backgroundColor: "transparent" }}
                  >
                    <Typography
                      sx={{
                        color: theme.custom.popoverLinkColor,
                        cursor: "pointer",
                      }}
                      variant="poppins_p_600_14"
                    >
                      Add More Filters
                    </Typography>
                  </Button>
                )}
              </Box>
              <Box sx={{ width: "100%", mt: 5 }}>
                <Divider
                  orientation="horizontal"
                  sx={{ height: 1, width: "100%" }}
                />
              </Box>
              <Box sx={{ width: "100%", mt: 3, textAlign: "right" }}>
                <LoadingButton
                  loading={isSaveLoading}
                  disabled={isDirty}
                  sx={{ width: 120, height: 40 }}
                  variant="contained"
                  onClick={onSave}
                >
                  <Typography variant="poppins_p_600_14">Save</Typography>
                </LoadingButton>
              </Box>
              <div>
                <Modal
                  hideBackdrop={true}
                  disableEnforceFocus
                  sx={{
                    position: "absolute",
                    zIndex: 2500,
                    background: "transparent",
                  }}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                >
                  <Fade in={open}>
                    <Box sx={style}>
                      <Grid container>
                        <Grid textAlign={"center"} item xs={11}>
                          <Typography
                            sx={{ color: `${theme.custom.primaryDarkColor}` }}
                            variant="poppins_h3_600_24"
                          >
                            Add new board
                          </Typography>
                        </Grid>
                        <Grid textAlign="right" item xs={1}>
                          <CancelOutlinedIcon
                            onClick={handleClose}
                            sx={{
                              color: `${theme.custom.primaryGreyColor}`,
                              cursor: "pointer",
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Box sx={{ marginTop: 3 }}>
                        <TextField
                          fullWidth
                          key={"add"}
                          onChange={(event) => {
                            setBoardName(event.target.value);
                          }}
                          label={
                            <Typography
                              variant="inter_500_12"
                              sx={{ color: theme.custom.primaryGreyColor }}
                            >
                              Board name
                            </Typography>
                          }
                          inputProps={{
                            maxLength: 50,
                            style: {
                              fontFamily: fontInter,
                              fontSize: "14px",
                              fontWeight: 500,
                              color: theme.custom.primaryDarkColor,
                            },
                          }}
                        />
                        <LoadingButton
                          loading={isAddLoading}
                          sx={{ marginTop: 4, height: 50 }}
                          variant="contained"
                          fullWidth
                          onClick={addNewBoard}
                        >
                          <Typography
                            style={{ textTransform: "none" }}
                            variant="poppins_p_600_14"
                          >
                            Add board
                          </Typography>
                        </LoadingButton>
                        <Button
                          onClick={handleClose}
                          sx={{ marginTop: 1, height: 50 }}
                          fullWidth
                        >
                          <Typography
                            variant="poppins_p_600_14"
                            sx={{
                              cursor: "pointer",
                              color: theme.custom.popoverLinkColor,
                            }}
                          >
                            Cancel
                          </Typography>
                        </Button>
                      </Box>
                    </Box>
                  </Fade>
                </Modal>
              </div>
            </Box>
          )}
          {!authorized && !loading && (
            <AuthorizationErrorHandler errorMessage="You don't have access to this page. Please contact admin for the access" />
          )}
          {loading && (
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ minHeight: "100vh" }}
            >
              <CircularProgress />
            </Grid>
          )}
        </Box>
      </Stack>
    </React.Fragment>
  );
};

export default React.memo(KPIBuilderPage);
function formatOrderByDataSource(arg0: any): any {
  throw new Error("Function not implemented.");
}
