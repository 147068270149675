import React, { useCallback, useRef } from "react";

function ImageEditor(props: any) {
  const {
    attributes,
    alt,
    toggleCustomAbles,
    updateElement,
    elementHeight,
    elementWidth,
    elementBorderRadius,
    elementClipPath,
  } = props;
  const imageRef = useRef<HTMLDivElement>(null as any);

  const _onLoad = useCallback(() => {
    const height = imageRef?.current?.clientHeight;
    const width = imageRef?.current?.clientWidth;
    if (height != elementHeight || width != elementWidth) {
      updateElement(
        {
          height: imageRef?.current?.clientHeight,
          width: imageRef?.current?.clientWidth,
        },
        "post-render"
      );
    }
  }, []);

  const getFilterSetting = (filters: any) => {
    let _filters =
      (filters.blur ? " blur(10px) " : "") +
      (filters.saturation ? " saturate(2) " : "") +
      (filters.grayscale ? " grayscale(100%) " : "") +
      (filters.sepia ? " sepia(2) " : "");

    return _filters;
  };

  return (
    <>
      <div ref={imageRef}>
        <img
          src={attributes.url}
          alt={alt}
          onDoubleClick={() => toggleCustomAbles("clip", true)}
          style={{
            height: "100%",
            width: "100%",
            borderRadius: `${elementBorderRadius}%`,
            clipPath: `${elementClipPath ? elementClipPath : ""}`,
            filter: getFilterSetting(attributes),
          }}
          onLoad={_onLoad}
        />
        {attributes.type === "library" && (
          <span
            style={{
              textDecoration: "none",
              color: "#ccc",
              fontSize: "10px",
              position: "relative",
              top: -10,
            }}
          >
            Photo by{" "}
            <a
              rel="noreferrer"
              style={{ textDecoration: "none", color: "#ccc" }}
              href={
                `${attributes?.credits?.userLink}?utm_source=Pebble&utm_medium=referral` ||
                ""
              }
              target="_blank"
            >
              {(attributes?.credits?.firstName || "") +
                " " +
                (attributes?.credits?.lastName || "")}
            </a>
            <span style={{ color: "#ccc" }}>{" / "}</span>
            <a
              style={{
                textDecoration: "none",
                color: "#ccc",
              }}
              href={
                "https://www.unsplash.com?utm_source=Pebble&utm_medium=referral"
              }
              target="_blank"
            >
              Unsplash
            </a>
          </span>
        )}
      </div>
    </>
  );
}

export default React.memo(ImageEditor);
