import React, { memo, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import { fontPoppins, theme } from "../../theme";
import { getInfoForKpi } from "../../services/kpi-service";
type Props = {
  kpiTitle: string;
  slug: string;
  closeDrawer: Function;
};

const KPIDrawer = (props: Props) => {
  const [richText, setRichText] = useState<any>("");

  const onDrawerClose = () => {
    props.closeDrawer();
  };

  useEffect(() => {
    getKPIInformation();
  }, [props.slug]);

  const getKPIInformation = () => {
    getInfoForKpi(props.slug).then((res) => {
      if (res?.data?.body) {
        setRichText(res?.data?.body);
      }
    });
  };
  return (
    <React.Fragment>
      <Box p={3}>
        <Grid mb={3} alignItems={"center"} container>
          <Grid item sm={11}>
            <Typography
              variant="poppins_h3_600_24"
              color={theme.custom.primaryDarkColor}
            >
              {props.kpiTitle}
            </Typography>
          </Grid>
          <Grid textAlign={"right"} mt={1} item sm={1}>
            <CloseIcon onClick={onDrawerClose} sx={{ cursor: "pointer" }} />
          </Grid>
        </Grid>
        <div style={{ fontFamily: fontPoppins }}>
          <div
            dangerouslySetInnerHTML={{ __html: `${richText}` }}
            id="richtext-code"
          ></div>
        </div>
      </Box>
    </React.Fragment>
  );
};

export default memo(KPIDrawer);
