import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import EditorKPIActiveIcon from "../../../assets/editor_kpi_active_icon.png";
import EditorKPIIcon from "../../../assets/editor_kpi_icon.png";
import EditorMediaActiveIcon from "../../../assets/editor_media_active_icon.png";
import EditorMediaIcon from "../../../assets/editor_media_icon.png";
import EditorShapeActiveIcon from "../../../assets/editor_shape_active_icon.png";
import EditorShapeIcon from "../../../assets/editor_shape_icon.png";
import EditorBrandActiveIcon from "../../../assets/editor_brand_active_icon.png";
import EditorBrandIcon from "../../../assets/editor_brand_icon.png";
import EditorTableActiveIcon from "../../../assets/editor_table_active_icon.png";
import EditorTableIcon from "../../../assets/editor_table_icon.png";
import EditorTabularReportActiveIcon from "../../../assets/editor_tabular_report_active_icon.png";
import EditorTabularReportIcon from "../../../assets/editor_tabular_report_icon.png";
import EditorTextActiveIcon from "../../../assets/editor_text_active_icon.png";
import EditorTextIcon from "../../../assets/editor_text_icon.png";
import { theme } from "../../../theme";
import KPILibrary from "../../molecules/Editor/KPILibrary";
import MediaLibrary from "../../molecules/Editor/MediaLibrary";
import TableLibrary from "../../molecules/Editor/TableLibrary";
import TabularReportLibrary from "../../molecules/Editor/TabularReportLibrary";
import TemplateLibrary from "../../molecules/Editor/TemplateLibrary";
import TextEditorLibrary from "../../molecules/Editor/TextEditorLibrary";
import "./Design.scss";
import SettingsDrawer from "./SettingsDrawer";
import ElementsLibrary from "../../molecules/Editor/ElementsLibrary";
import BrandLibrary from "./BrandLibrary";
import { usePrevious } from "react-use";
import { styled } from "@mui/material/styles";

const CustomScrollbarBox = styled(Box)(({ theme }) => ({
  "&" : {
    boxSizing: "content-box",
  },
  "&::-webkit-scrollbar": {
    width: "3px !important",
    height: "3px !important",
  },
  "&::-webkit-scrollbar-track": {
    background: "#e0e0e0 !important",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "#c7c7c7 !important",
    borderRadius: "3px !important",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "#a8a8a8 !important",
  },
}));

const drawerItems = [
  {
    icon: EditorTextIcon,
    iconActive: EditorTextActiveIcon,
    text: "Text",
    slug: "textEditor",
  },
  {
    icon: EditorShapeIcon,
    iconActive: EditorShapeActiveIcon,
    text: "Elements",
    slug: "elements",
  },
  {
    icon: EditorKPIIcon,
    iconActive: EditorKPIActiveIcon,
    text: "KPI",
    slug: "kpi",
  },
  {
    icon: EditorMediaIcon,
    iconActive: EditorMediaActiveIcon,
    text: "Media",
    slug: "media",
  },
  {
    icon: EditorTabularReportIcon,
    iconActive: EditorTabularReportActiveIcon,
    text: "Tabular Report",
    slug: "tabularReport",
  },
  {
    icon: EditorTableIcon,
    iconActive: EditorTableActiveIcon,
    text: "Table",
    slug: "table",
  },
  {
    icon: EditorBrandIcon,
    iconActive: EditorBrandActiveIcon,
    text: "Brand",
    slug: "brand",
  },
];

function LibraryandSetting(props: any) {
  const {
    addElement,
    addElementonCurrPage,
    addPage,
    updatePageBg,
    clearClipBoard,
    toggleCustomAbles,
    updateElement,
    selectedElement,
    targets,
    deleteElement,
    onResetSelection,
    pageAttributes,
    reportUID,
  } = props;

  const [selectedDrawer, setSelectedDrawer] = useState<string>("textEditor");
  const selectedDrawerPrev = useRef<string>(selectedDrawer);
  const drawerMenuWidth = "80px",
    drawerWidth = "300px";
  const [showSettings, setShowSettings] = useState(false);
  const onChangeDrawerItem = (item_slug: string) => {
    setSelectedDrawer(item_slug);
    setShowSettings(false);
    onResetSelection();
    setEnableOverlay(false);
  };

  useEffect(() => {
    if (targets.length === 0 && !selectedDrawer) {
      setSelectedDrawer(selectedDrawerPrev.current);
    } else {
      selectedDrawerPrev.current = selectedDrawer;
    }
  }, [targets]);

  useEffect(() => {
    if (selectedElement && selectedElement.id) {
      setShowSettings(true);
    } else {
      setShowSettings(false);
    }
  }, [selectedElement]);

  const pickLibraryByType = (type: string) => {
    switch (type) {
      case "textEditor":
        return (
          <TextEditorLibrary
            addElement={addElement}
            addElementonCurrPage={addElementonCurrPage}
          />
        );
      case "media":
        return (
          <MediaLibrary
            clearClipBoard={clearClipBoard}
            addElement={addElement}
          />
        );
      case "kpi":
        return (
          <KPILibrary
            addElement={addElement}
            reportUID={reportUID}
            pageAttributes={pageAttributes}
          />
        );
      case "tabularReport":
        return (
          <TabularReportLibrary addElement={addElement} reportUID={reportUID} />
        );
      case "elements":
        return (
          <ElementsLibrary
            addElement={addElement}
            updatePageBg={updatePageBg}
          />
        );
      case "table":
        return <TableLibrary addElement={addElement} />;
      case "templates":
        return (
          <TemplateLibrary
            pageAttributes={pageAttributes}
            addElement={addElement}
            addPage={addPage}
          />
        );
      case "brand":
        return <BrandLibrary addElement={addElement} />;
      default:
        return (
          <>
            <Typography></Typography>
          </>
        );
    }
  };
  const [enableOverlay, setEnableOverlay] = useState(false);
  const updateOverlay = useCallback((overlay:boolean) => setEnableOverlay(overlay), []);

  return (
    <>
      <Drawer
        sx={{
          flexShrink: 0,
          width: drawerMenuWidth,
          "& .MuiDrawer-paper": {
            width: drawerMenuWidth,
            backgroundColor: theme.custom.designBgColor,
            borderRight: "none",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <Box pt={2}>
          <List className="designDrawerList">
            {drawerItems.map((item) => (
              <ListItem
                button
                key={item.slug}
                className="designDrawerListItem"
                style={{
                  textAlign: "center",
                  backgroundColor:
                    selectedDrawer === item.slug
                      ? "white"
                      : theme.custom.designBgColor,
                  borderTop:
                    item.slug === "setting"
                      ? `1px solid ${theme.impactReportingColors.menuBorderColor}`
                      : "none",
                }}
                onClick={() => onChangeDrawerItem(item.slug)}
              >
                <img
                  src={
                    selectedDrawer === item.slug ? item.iconActive : item.icon
                  }
                  alt={item.text}
                />
                <ListItemText
                  sx={{
                    fontSize: 10,
                    "& .MuiListItemText-primary": {
                      fontSize: 10,
                    },
                  }}
                  primary={
                    <Typography
                      variant="inter_500_10"
                      sx={{
                        color:
                          selectedDrawer === item.slug
                            ? theme.custom.primaryDarkColor
                            : theme.custom.menuDimColor,
                      }}
                    >
                      {item.text}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      <Drawer
        sx={{
          flexShrink: 0,
          width: drawerWidth,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            marginLeft: drawerMenuWidth,
            overflow: enableOverlay ? "visible" : "auto"
          },
          "& img": {
            cursor: "pointer",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Toolbar />
        <CustomScrollbarBox id="scrollableDiv" sx={{
          height: "calc(100% - 64px)",
          overflowY: enableOverlay ? "visible" : "auto",
        }} px={2}>
          <Box>
            {showSettings ? (
              <SettingsDrawer
                updateElement={updateElement}
                deleteElement={deleteElement}
                toggleCustomAbles={toggleCustomAbles}
                onResetSelection={() => setShowSettings(false)}
                selectedElement={selectedElement}
                updateOverlay={updateOverlay}
              />
            ) : (
              pickLibraryByType(selectedDrawer)
            )}
          </Box>
        </CustomScrollbarBox>
      </Drawer>
    </>
  );
}

export default React.memo(LibraryandSetting);
