import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { fontInter, theme } from "../../theme";
import { ProgramFilterItem } from "../../Types/ProgramFilterItem";
import { getAllContractsForProgram } from "../../services/budget-service";
import { ContractObject } from "../../Types/ContractObject";
import SingleDatePickerComponent from "../atoms/SingleDatePickerComponent";
import ChipsForBudgeting from "../complexes/ChipsForBudgeting";

interface Props {
  programFilters: ProgramFilterItem[];
  filtersChange: any;
}

const AutoCompleteComponent = styled(Autocomplete)({
  ".MuiAutocomplete-inputRoot": {
    color: theme.custom.dropdownPrimaryColor,
    fontWeight: 500,
    borderRadius: 8,
    fontSize: "14px",
    fontFamily: fontInter,
  },
});

const BudgetFilters: React.FC<Props> = (data): any => {
  const programFilters = data.programFilters;
  const onFiltersChange = data.filtersChange;
  const SELECTED_FILTERS_INIT_STATE = {
    ref_program_id: "",
    end_date: "",
    active: null,
    ref_contract_id: "",
  };
  const [selectedFilters, setSelectedFilters] = useState(
    SELECTED_FILTERS_INIT_STATE
  );
  const [displayChips, setDisplayChips] = useState<any>(null);
  const [contracts, setContracts] = useState([] as ContractObject[]);
  let options = [
    {
      name: "All",
      value: "",
    },
    {
      name: "Active",
      value: "true",
    },
    {
      name: "Inactive",
      value: "false",
    },
  ];

  const [hideDeleted, setHideDeleted] = useState(true);

  const renderOption = (props: any, option: any, key: any = "") => {
    if (key === "Program" && props["data-option-index"] === 0) {
      return (
        <Grid container justifyContent={"end"}>
          <Grid
            item
            component="li"
            {...props}
            onClick={() => {}}
            sx={{
              background: "none !important",
            }}
          >
            <Checkbox
              checked={hideDeleted}
              onClick={(e) => {
                setHideDeleted(!hideDeleted);
              }}
            />
            <Typography py={1} variant="inter_p_500_14">
              Hide deleted
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item component="li" {...props}>
        <Stack direction="column">
          <Typography py={1} variant="inter_p_500_14">
            {option?.name}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  const getContractsForSelectedProgram = (program: string) => {
    getAllContractsForProgram(program).then((res) => {
      if (res.status === 200) {
        setContracts(res.data?.data?.contract || []);
      }
    });
  };

  const resetFilters = () => {
    setSelectedFilters(SELECTED_FILTERS_INIT_STATE);
    onFiltersChange(SELECTED_FILTERS_INIT_STATE);
  };

  const handleChangeAutoComplete = (value: any, id: string) => {
    if (!value) {
      setSelectedFilters((prev) => {
        return { ...prev, [id]: "" };
      });
      return;
    }
    if (id === "active") {
      setSelectedFilters((prev) => {
        return { ...prev, active: value?.value };
      });
      return;
    } else if (id === "ref_program_id") {
      getContractsForSelectedProgram(value["id"]);
      setSelectedFilters((prev) => {
        return { ...prev, ref_contract_id: "" };
      });
    }
    setSelectedFilters((prev) => {
      return { ...prev, [id]: value };
    });
  };

  const onFilterApply = () => {
    onFiltersChange(selectedFilters);
    setDisplayChips(null);
    setDisplayChips(selectedFilters);
  };

  const updateSelectedFilters = (key: string, value?: any) => {
    let temp: any = { ...selectedFilters };
    temp[key] = "";
    setSelectedFilters(temp);
    onFiltersChange(temp);
  };

  const onDateChange = useCallback((date: any) => {
    setSelectedFilters((prev) => {
      return { ...prev, end_date: moment(date).format("YYYY-MM-DD") };
    });
  }, []);

  const getActiveValue = () => {
    if (selectedFilters.active === null) {
      return { name: "All", value: "" };
    }
    if (selectedFilters.active === "true") {
      return { name: "Active", value: "true" };
    }
    if (selectedFilters.active === "false") {
      return { name: "Inactive", value: "false" };
    } else {
      return { name: "All", value: "" };
    }
  };

  return (
    <React.Fragment>
      <Box
        py={4}
        sx={{
          background: theme.custom.filterBackgroundColor,
          height: "auto",
          width: "100%",
          border: 1,
          borderColor: theme.custom.filterBorderColor,
          borderRadius: "6px",
        }}
      >
        <Box mx={1} px={2}>
          <Grid container justifyContent={"space-between"} xs={12}>
            <Grid container xs={10} spacing={2}>
              <Grid item xs={3}>
                <Box
                  sx={{
                    border: `1px solid ${theme.custom.filterBorderColor}`,
                    borderRadius: "6px",
                    padding: "1px",
                  }}
                >
                  <SingleDatePickerComponent
                    date={
                      selectedFilters.end_date.length > 0
                        ? selectedFilters.end_date
                        : undefined
                    }
                    onDateChange={onDateChange}
                  />
                </Box>
              </Grid>
              <Grid key={"Program"} item xs={3}>
                <AutoCompleteComponent
                  id={"program_filter"}
                  options={[
                    {},
                    ...(programFilters.filter((i: any) =>
                      hideDeleted ? !i.name.includes("(deleted)") : true
                    ) || []),
                  ]}
                  disableClearable={false}
                  filterOptions={(options, state) => {
                    return [
                      {},
                      ...(options
                        .filter((i: any) =>
                          i.name
                            ?.toLowerCase()
                            .trim()
                            .includes(state.inputValue.toLowerCase().trim())
                        )
                        .filter((i: any) =>
                          hideDeleted ? !i.name.includes("(deleted)") : true
                        ) || []),
                    ];
                  }}
                  value={selectedFilters["ref_program_id"] || ""}
                  renderOption={(props: any, option: any) =>
                    renderOption(props, option, "Program")
                  }
                  getOptionLabel={(option: any) => {
                    return option ? option.name : "";
                  }}
                  onChange={(e: any, v: any, r: any, opt: any) => {
                    handleChangeAutoComplete(v, "ref_program_id");
                  }}
                  filterSelectedOptions={true}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        style: {
                          marginLeft: 7,
                        },
                      }}
                      label={
                        <Typography variant="inter_p_500_10">
                          Program
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid key={"Contract"} item xs={3}>
                <AutoCompleteComponent
                  id={"contract_filter"}
                  options={contracts}
                  value={selectedFilters["ref_contract_id"]}
                  renderOption={(props: any, option: any) =>
                    renderOption(props, option)
                  }
                  getOptionLabel={(option: any) => {
                    return option ? option.name : "";
                  }}
                  onChange={(e: any, v: any, r: any, opt: any) => {
                    handleChangeAutoComplete(v, "ref_contract_id");
                  }}
                  filterSelectedOptions={true}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        style: {
                          marginLeft: 7,
                        },
                      }}
                      label={
                        <Typography variant="inter_p_500_10">
                          Contract
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid key={"isActive"} item xs={3}>
                <AutoCompleteComponent
                  id={"Active"}
                  disableClearable
                  options={options}
                  value={getActiveValue()}
                  renderOption={(props: any, option: any) =>
                    renderOption(props, option)
                  }
                  getOptionLabel={(option: any) => {
                    return option ? option.name : "";
                  }}
                  onChange={(e: any, v: any, r: any, opt: any) => {
                    handleChangeAutoComplete(v, "active");
                  }}
                  filterSelectedOptions={true}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        style: {
                          marginLeft: 7,
                        },
                      }}
                      label={
                        <Typography variant="inter_p_500_10">
                          Active/Inactive
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container xs={2}>
              <Grid container justifyContent={"flex-end"}>
                <Grid item xs={8}>
                  <Button
                    sx={{
                      borderRadius: "6px",
                      height: "50px",
                    }}
                    fullWidth
                    variant="outlined"
                    onClick={onFilterApply}
                  >
                    <Typography variant="poppins_p_600_14">Apply</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box mt={4} px={2} ml={1}>
          <ChipsForBudgeting
            seletedFilters={displayChips}
            updateSelectedFilters={updateSelectedFilters}
            resetFilters={resetFilters}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default BudgetFilters;
