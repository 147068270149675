import { styled } from "@mui/system";

const BgImage = styled("img")({
  display: "block",
  height: "100vh",
  width: "100%",
  objectFit: "cover",
  backgroundColor: "#FAFAFA",
});

interface Props {
  url: string;
  extra?: any;
}

const PebbleBgImage = (props: Props) => {
  return (
    <div style={{position: "fixed"}}>
      <BgImage src={props.url} sx={{ ...(props?.extra || {}) }} />
    </div>
  );
};

export default PebbleBgImage;
