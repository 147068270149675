import {
  Box,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { CSSProperties, memo, useEffect, useState } from "react";
import {
  getFonts,
  getFontSizes,
} from "../../../services/impact-report-service";
import { theme } from "../../../theme";
import CustomSwitch from "../../atoms/CustomSwitch";
import { ThemeColorPicker, ThemeColorPickerIcon } from "./ThemeColorPicker";
import { setSettingEditActive } from "../../../store/selected-design-element-store";
import { useAppDispatch } from "../../../store/hook";
import { addAlphaToHexCode } from "../../../utils/color-picker-helper";

const colorPickerStyleMinWH: CSSProperties = {
  position: "absolute",
  padding: "10px",
  background: "#FFFFFF",
  boxShadow: "rgba(0, 0, 0, 0.12) 4px 4px 10px 10px",
  borderRadius: "6px",
  zIndex: 1,
  minWidth: "300px",
  minHeight: "200px",
};

const TableEditorSettings = (props: any) => {
  const {
    updateElement,
    tableConfig,
    toggleCustomAbles,
    elementId,
    attributes,
  } = props;

  const [displayPicker, setDisplayPicker] = useState<any>({});
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);

  const updateDisplayPicker = (e: any, data: any) => {
    if (data === false) {
      setAnchorEl(null);
      props.updateOverlay(false);
    } else {
      setAnchorEl(anchorEl);
      props.updateOverlay(true);
    }

    dispatch(setSettingEditActive(data ? true : false));
    setDisplayPicker(data);
  }

  const updateTableConfig = (key: string, val: any, field?: string) => {
    if (!field) {
      updateElement(elementId, {
        tableConfig: { ...(tableConfig || {}), [key]: val },
      });
    } else {
      updateElement(elementId, {
        attributes: { showHeader: val },
      });
    }
  };

  return (
    <Box>
      <Box>
        <Grid container justifyContent={"space-between"} alignContent="center">
          <Typography>Header </Typography>
          <CustomSwitch
            extraSX={{
              mx: 2,
              cursor: "pointer",
              mt: 0.5,
            }}
            backgroundColor={theme.palette.primary.main}
            trackColor={theme.customSwitch.inactiveColor}
            active={
              tableConfig.displayHead === undefined
                ? attributes.showHeader
                : tableConfig.displayHead
            }
            onToggle={(checked: any) =>
              updateTableConfig("displayHead", checked, "switch")
            }
          />
        </Grid>

        <Select
          disabled={!attributes.showHeader}
          sx={{ height: 38, mt: 1 }}
          fullWidth
          labelId="text-select-label"
          id="text-select"
          value={tableConfig.headerFont}
          onChange={(e) => updateTableConfig("headerFont", e.target.value)}
        >
          {getFonts().map((font, index) => (
            <MenuItem key={`${font}_${index}`} value={font.value}>
              <Typography
                sx={{ color: theme.custom.primaryGreyColor }}
                mt={0.4}
                variant="inter_p_500_14"
              >
                <span className={`font-${font.value}`}>{font.label}</span>
              </Typography>
            </MenuItem>
          ))}
        </Select>

        <Grid mt={2} mb={4} container>
          <Grid item md={2}>
            <Box
              sx={{
                opacity: !attributes.showHeader ? 0.5 : 1,
                pointerEvents: !attributes.showHeader ? "none" : "all",
              }}
              onClick={(e) => {
                return updateDisplayPicker(e, { section: "header", variant: "font" });
              }}
            >
              <ThemeColorPickerIcon
                colors={tableConfig.headerFontColor || "black"}
              />
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box
              sx={{
                opacity: !attributes.showHeader ? 0.5 : 1,
                pointerEvents: !attributes.showHeader ? "none" : "all",
              }}
              onClick={(e) =>
                updateDisplayPicker(e, { section: "header", variant: "background" })
              }
            >
              <ThemeColorPickerIcon
                variant="background"
                colors={tableConfig.headerBgColor || "black"}
              />
            </Box>
          </Grid>
          <Grid item md={3} />
          {/* <Grid item md={4}>
            <Box>
              <Select
                sx={{ height: 34, width: 100 }}
                size="small"
                labelId="font-size-select-label"
                id="font-size-select"
                disabled={!attributes.showHeader}
                value={tableConfig.headerFontSize}
                fullWidth={true}
                onChange={(e) =>
                  updateTableConfig("headerFontSize", e.target.value)
                }
              >
                {getFontSizes().map((item, index) => (
                  <MenuItem key={`${item}_${index}`} value={item.label}>
                    <Typography
                      sx={{ color: theme.custom.primaryGreyColor }}
                      mt={0.4}
                      variant="inter_p_500_14"
                    >
                      {item.value}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid> */}
        </Grid>
        {displayPicker.section === "header" ? (
          <Box style={colorPickerStyleMinWH}>
            <ThemeColorPicker
              closePicker={(e: any) => updateDisplayPicker(e, false)}
              anchorEl={anchorEl}
              isShapes={true}
              align={"top"}
              onChange={(color: any) =>
                displayPicker.variant === "font"
                  ? updateTableConfig("headerFontColor", addAlphaToHexCode(color.hex, color.rgb["a"]))
                  : updateTableConfig("headerBgColor", addAlphaToHexCode(color.hex, color.rgb["a"]))
              }
              color={
                (displayPicker.variant === "font"
                  ? tableConfig.headerFontColor
                  : tableConfig.headerBgColor) || "black"
              }
              updateHexCode={(hexCode: any) => displayPicker.variant === "font"
                ? updateTableConfig("headerFontColor", hexCode)
                : updateTableConfig("headerBgColor", hexCode)}
              colorPickerTitle={displayPicker.variant === "font" && displayPicker.section === "header" ? "Header Font color" : "Header Background color"}
            />
          </Box>
        ) : null}
      </Box>

      <Divider sx={{ my: 2, mx: -2 }} />
      <Box mt={2}>
        <Typography
          variant="inter_p_600_16"
          sx={{ color: theme.custom.primaryDarkColor }}
          mb={2}
        >
          Body
        </Typography>
        <Select
          sx={{ height: 38, mt: 1 }}
          size="small"
          fullWidth
          labelId="text-select-label"
          id="text-select"
          value={tableConfig.bodyFont}
          onChange={(e) => updateTableConfig("bodyFont", e.target.value)}
        >
          {getFonts().map((font, index) => (
            <MenuItem key={`${font}_${index}`} value={font.value}>
              <Typography
                sx={{ color: theme.custom.primaryGreyColor }}
                mt={0.4}
                variant="inter_p_500_14"
              >
                <span className={`font-${font.value}`}>{font.label}</span>
              </Typography>
            </MenuItem>
          ))}
        </Select>
        <Grid mt={2} mb={4} container>
          <Grid item md={2}>
            <Box
              onClick={(e) =>
                updateDisplayPicker(e, { section: "body", variant: "font" })
              }
            >
              <ThemeColorPickerIcon
                colors={tableConfig.bodyFontColor || "black"}
              />
            </Box>
          </Grid>
          <Grid item md={2}>
            <Box
              ml={1}
              sx={{ flex: 1 }}
              onClick={(e) =>
                updateDisplayPicker(e, { section: "body", variant: "background" })
              }
            >
              <ThemeColorPickerIcon
                variant="background"
                colors={tableConfig.bodyBgColor || "black"}
              />
            </Box>
          </Grid>
          <Grid item md={3} />
          {/* <Grid item md={4}>
            <Box>
              <Select
                sx={{ height: 34, width: 100 }}
                size="small"
                labelId="font-size-select-label"
                id="font-size-select"
                value={tableConfig.bodyFontSize}
                fullWidth={true}
                onChange={(e) =>
                  updateTableConfig("bodyFontSize", e.target.value)
                }
              >
                {getFontSizes().map((item, index) => (
                  <MenuItem key={`${item}_${index}`} value={item.label}>
                    <Typography
                      sx={{ color: theme.custom.primaryGreyColor }}
                      mt={0.4}
                      variant="inter_p_500_14"
                    >
                      {item.value}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Grid> */}
        </Grid>
        {displayPicker.section === "body" ? (
          <Box style={colorPickerStyleMinWH}>
            <ThemeColorPicker
              closePicker={(e: any) => updateDisplayPicker(e, false)}
              anchorEl={anchorEl}
              isShapes={true}
              align={"top"}
              onChange={(color: any) =>
                displayPicker.variant === "font"
                  ? updateTableConfig("bodyFontColor", addAlphaToHexCode(color.hex, color.rgb["a"]))
                  : updateTableConfig("bodyBgColor", addAlphaToHexCode(color.hex, color.rgb["a"]))
              }
              updateHexCode={(hexCode: any) => {
                displayPicker.variant === "font"
                  ? updateTableConfig("bodyFontColor", hexCode)
                  : updateTableConfig("bodyBgColor", hexCode)
              }}
              color={
                (displayPicker.variant === "font"
                  ? tableConfig.bodyFontColor
                  : tableConfig.bodyBgColor) || "black"
              }
              colorPickerTitle={displayPicker.variant === "font" && displayPicker.section === "body" ? "Body Font color" : "Body Background color"}
            />
          </Box>
        ) : null}
      </Box>
      <Divider sx={{ my: 2, mx: -2 }} />
      <Grid container sx={{ my: 3 }}>
        <Grid item xs={12}>
          <Grid container alignItems="center" justifyContent="space-between">
            <Typography>Border </Typography>
            <CustomSwitch
              extraSX={{
                mx: 2,
                cursor: "pointer",
              }}
              backgroundColor={theme.palette.primary.main}
              trackColor={theme.customSwitch.inactiveColor}
              active={!!tableConfig.displayBorder}
              onToggle={(checked: any) =>
                updateTableConfig("displayBorder", checked)
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(TableEditorSettings);
