import {Menu, MenuItem, Typography} from "@mui/material";
import React, { useState } from "react";
import Delete from "../../assets/delete_red.png";
import {theme} from "../../theme";
import MenuOption from "../../assets/fi-rr-menu-dots.png";

type Props = {
  uid: string;
  deleteDocument: Function;
};

const BudgetDocumentsMenuItem = (props: Props) => {
  const { uid, deleteDocument } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Typography
        variant="inter_400_14"
        sx={{ color: theme.custom.primaryDarkColor, paddingLeft: 5 }}
      >
        <img
          style={{ cursor: "pointer" }}
          height={20}
          width={20}
          alt="Menu"
          src={MenuOption}
          id="positioned-button"
          aria-controls={open ? 'positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        />
      </Typography>
      <Menu
        id={'positioned-menu'}
        aria-labelledby="positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleDropdownClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{zIndex: 15000}}
      >
        <MenuItem onClick={() => {deleteDocument(uid); handleDropdownClose();}} style={{color: "black", width: "150px"}}>
          <img
            style={{ cursor: "pointer" }}
            height={20}
            width={20}
            alt="Delete"
            src={Delete}
          />
          <span style={{padding: 10}}>Delete</span>
        </MenuItem>
      </Menu>
    </>
  );
};

export default React.memo(BudgetDocumentsMenuItem);
