import axios from "axios";
import {
  DELETE_USER_MAPPINGS_CONTRACTS,
  GET_ALL_ATTR_DIM,
  GET_ATTR_DIM,
  GET_CONNECTOR_MAPPING_CONFIG,
  GET_USER_MAPPINGS,
  GET_USER_MAPPINGS_CONTRACTS,
  POST_SAVE_USER_MAPPINGS,
} from "../endpoints/user-mapping-endpoints";
import { UserMappingAPIModel } from "../store/user-mapping-store";

export interface ConnectorMappingConfig {
  id: number;
  title: string;
  slug: string;
  options: { [key: string]: string };
  default_selected: string;
  step: number;
  category: string;
  connector: number;
  is_multi_step: boolean;
  is_mandatory: boolean;
  user_mapping: any;
}

export interface QBDataBase {
  id: string;
  name: string;
  is_sub_entry: boolean;
  parent_account_id?: string;
  parent_account_name?: string;
  children?: string[];
}

export interface Account extends QBDataBase {
  classification: string;
  account_type: string;
}
export interface Class extends QBDataBase {}
export interface Department extends QBDataBase {}
export interface Customer extends QBDataBase {}

export interface QBDataApiResponse {
  Account: Account[];
  Class: Class[];
  Department: Department[];
  Customer: Customer[];
}

export const getConnectorMappingConfig = (connectorSlug: string) => {
  return axios.get<ConnectorMappingConfig[]>(
    GET_CONNECTOR_MAPPING_CONFIG(connectorSlug)
  );
};

export const saveUserMappings = (
  connectorSlug: string,
  meta_data: number,
  body: any
) => {
  return axios.post<{
    data: ConnectorMappingConfig[];
    has_at_least_one_mapping: boolean;
  }>(POST_SAVE_USER_MAPPINGS(connectorSlug), {
    meta_data: meta_data,
    mapping: body,
  });
};

export const getUserMappingsFromAPI = (connectorSlug: string) => {
  return axios.get<{
    data: ConnectorMappingConfig[];
    has_at_least_one_mapping: boolean;
  }>(GET_USER_MAPPINGS(connectorSlug));
};
export const getUserMappingsContractsFromAPI = (connectorSlug: string) => {
  return axios.get<{
    data: ConnectorMappingConfig[];
    has_at_least_one_mapping: boolean;
  }>(GET_USER_MAPPINGS_CONTRACTS(connectorSlug));
};
export const deleteUserMappingsContracts = (
  connectorSlug: string,
  altSlug: string
) => {
  return axios.delete<{}>(
    DELETE_USER_MAPPINGS_CONTRACTS(connectorSlug, altSlug)
  );
};

export const saveContractMappings = (
  connectorSlug: string,
  meta_data: number,
  body: any,
  ref_program_id: string,
  ref_program_name: string,
  alt_title: string,
  alt_slug: string | null
) => {
  let data: any = {
    meta_data: meta_data,
    mapping: body,
    ref_program_id,
    ref_program_name,
    alt_title,
  };
  if (!!alt_slug) {
    data["alt_slug"] = alt_slug;
  }
  return axios.post<{
    data: ConnectorMappingConfig[];
    has_at_least_one_mapping: boolean;
  }>(GET_USER_MAPPINGS_CONTRACTS(connectorSlug), data);
};

export const getDimAttr = (connectorSlug: string, field: string) => {
  return axios.get<QBDataBase[]>(GET_ATTR_DIM(connectorSlug, field));
};
export const getAllDimAttr = (connectorSlug: string) => {
  return axios.get<{ compressed_data: any }>(GET_ALL_ATTR_DIM(connectorSlug));
};
