import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  chartLabelsStyle,
  returnChartColors,
  theme,
  tooltipColors,
} from "../../theme";
import { labelNumberFormatter } from "../../utils/dataFormatters";
import { returnFileName } from "../../utils/file-name-helper";
import { ChartProps } from "../../Types/ChartProps";
import { useState, useEffect } from "react";
const returnPieChartData = (data: any, name: string) => {
  return [
    {
      name: name,
      colorByPoint: true,
      data: data.map((e: any) => {
        return { name: e["primary_label_name"], y: e["value"] };
      }),
    },
  ];
};

const PieChart: React.FC<ChartProps> = (chartData) => {
  let data = chartData.chartData;
  let meta_data = chartData.chartMeta;
  let data_type = meta_data?.chart?.currencytype || "%";
  let date_range = chartData.dateRange;
  const height = chartData.height;
  const width = chartData.width;
  const editorMode = chartData.editorMode;
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const [options, setOptions] = useState({} as any);
  let showTable = chartData.showTable;

  useEffect(() => {
    buildOptions(data, height);
  }, [data, height]);

  useEffect(() => {
    if (chartComponentRef.current) {
      if (showTable) {
        chartComponentRef.current?.chart.viewData();
      } else {
        chartComponentRef.current.chart.hideData();
      }
    }
  }, [showTable]);

  const buildOptions = (data: any, height: number | undefined) => {
    setOptions({
      chart: {
        type: "pie",
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,

        events: {
          render: function () {
            chartData.kpiRendered(true);
            chartData.chartReference(chartComponentRef);
            if (chartData.customKPI === false) {
              chartComponentRef.current?.chart.reflow();
            }
          },
        },
      },
      title: {
        text: "",
      },
      tooltip: {
        backgroundColor: tooltipColors.bgColor,
        borderColor: tooltipColors.borderColor,
        color: tooltipColors.dataFormatColor,
        padding: 10,
        borderRadius: 5,
        borderWidth: 1,
        formatter: function (this: Highcharts.TooltipFormatterContextObject) {
          return `<span style="color: ${theme.custom.whiteColor}">${
            this.key
          }: ${labelNumberFormatter(
            this.percentage || 0,
            data_type
          )}</span><br/> <span style="color: ${
            theme.custom.whiteColor
          }">Value: ${this.y}</span>
          `;
        },
      },
      accessibility: {
        point: {
          valueSuffix: "%",
        },
      },
      plotOptions: {
        pie: {
          showInLegend: meta_data.legend.enabled,
          allowPointSelect: true,
          states: {
            inactive: {
              opacity: 1,
            },
          },
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format:
              `<span style="${chartLabelsStyle}">` + "{point.name}</span>",
          },
        },
      },
      navigation: {
        buttonOptions: {
          enabled: false,
        },
      },
      credits: {
        enabled: false,
      },
      exporting: {
        filename: returnFileName(meta_data?.chart?.title || "", date_range),
      },
      colors: returnChartColors(data && data.length),
      series: returnPieChartData(data, meta_data?.chart?.["y-axis-text"] || ""),
    });
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{
        className: "chart",
        style: {
          width: "100%",
          height: "calc(100% - 1px)",
        },
      }}
      options={
        editorMode
          ? {
              ...options,
              ...{ chart: { ...options.chart, height: height, width: width } },
            }
          : options
      }
      ref={chartComponentRef}
    ></HighchartsReact>
  );
};

export default PieChart;
