import {
  Select,
  MenuItem,
  Typography,
  SelectChangeEvent,
  Button,
} from "@mui/material";
import { ComponentType, memo } from "react";
import {
  Field,
  formatQuery,
  QueryBuilder as QB,
  RuleGroupTypeIC,
  NameLabelPair,
  OptionGroup,
  ValueEditorProps,
  ValueSelectorProps,
  OperatorSelectorProps,
  ActionProps,
  ActionWithRulesProps,
  CombinatorSelectorProps,
} from "react-querybuilder";
import "react-querybuilder/dist/query-builder.scss";
import RemoveIcon from "../../assets/cancel_light.png";
import RemovePrimaryIcon from "../../assets/cancel_primary.png";
import { theme } from "../../theme";
import "./QueryBuilder.scss";

interface QBProps {
  query: RuleGroupTypeIC;
  onChange: Function;
  fields: Field[];
  operators?: NameLabelPair[] | OptionGroup<NameLabelPair>[];
  combinators?: NameLabelPair[] | OptionGroup<NameLabelPair>[];
  // There is conflict b/w react-dom and ts Element type,
  // monkey patching by allowing any data type while original for doc purpose
  valueEditor?: React.ComponentType<ValueEditorProps> | any;
  className?: string;
  disabled?: boolean;
  id?: number | string | null;
}

function fieldSelector(props: ValueSelectorProps) {
  return (
    <>
      <Select
        size="small"
        value={props.value}
        disabled={props.disabled}
        onChange={({
          target: { value: v },
        }: SelectChangeEvent<string | string[]>) => {
          props.handleOnChange(v);
        }}
      >
        {props.options.map((option) => {
          // react-qb not handling namelabelpair typing correctly
          let _option: any = option;
          return (
            <MenuItem value={_option.name}>
              <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                {_option.label}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}

function operatorSelector(props: OperatorSelectorProps) {
  return (
    <>
      <Select
        size="small"
        value={props.value}
        disabled={props.disabled}
        disableUnderline={true}
        sx={{
          background: theme.custom.chipGroupBackground,
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          color: theme.custom.activeColor,
        }}
        onChange={({
          target: { value: v },
        }: SelectChangeEvent<string | string[]>) => {
          props.handleOnChange(v);
        }}
      >
        {props.options.map((option) => {
          // react-qb not handling namelabelpair typing correctly
          let _option: any = option;
          return (
            <MenuItem value={_option.name}>
              <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                {_option.label}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}

function combinatorSelector(props: CombinatorSelectorProps) {
  let options = props.options;
  if (
    props.rules &&
    !Object.keys(props.rules[props.path[props.level] + 1]).includes("rules")
  ) {
    // Its a rule.
    options = [
      { name: "and", label: "AND" },
      { name: "or", label: "OR" },
    ];
  }
  return (
    <>
      <Select
        size="small"
        value={props.value}
        disableUnderline={true}
        disabled={props.disabled}
        sx={{
          background: theme.custom.chipGroupBackground,
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          color: theme.custom.activeColor,
        }}
        onChange={({
          target: { value: v },
        }: SelectChangeEvent<string | string[]>) => {
          props.handleOnChange(v);
        }}
      >
        {options.map((option: any) => {
          return (
            <MenuItem value={option.name}>
              <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                {option.label}
              </Typography>
            </MenuItem>
          );
        })}
      </Select>
    </>
  );
}

// TODO: Breaking change by lib.
// On upgrade of react or this lib props needs to be updated
// to ActionWithRulesAndAddersProps
interface AddRuleOrGroupActionsProps extends ActionWithRulesProps {
  title: string;
}
function addRuleOrGroupAction(props: AddRuleOrGroupActionsProps) {
  return (
    <Button
      onClick={props.handleOnClick}
      disabled={props.disabled}
      sx={{
        background: theme.custom.chipGroupBackground,
        border: 0,
        color: theme.custom.popoverLinkColor,
      }}
    >
      + {props.title}
    </Button>
  );
}

function removeRuleOrGroupAction(props: ActionProps) {
  return (
    <Button
      variant="text"
      onClick={props.handleOnClick}
      disabled={props.disabled}
    >
      <img
        src={RemoveIcon}
        alt={"removeRule"}
        style={{
          height: "12px",
          width: "12px",
        }}
      />
    </Button>
  );
}

function removeGroupAction(props: ActionProps) {
  return (
    <Button
      variant="text"
      onClick={props.handleOnClick}
      disabled={props.disabled}
      style={{
        backgroundColor: theme.custom.chipGroupBackground,
        position: "absolute",
        top: "calc(-12px - 4px - 12px)",
        right: 0,
      }}
    >
      <img
        src={RemovePrimaryIcon}
        alt={"removeGroup"}
        style={{
          height: "12px",
          width: "12px",
        }}
      />
    </Button>
  );
}

function QueryBuilder(props: QBProps) {
  const disabled = props?.disabled === undefined ? false : props.disabled;
  const defaultOperators: NameLabelPair[] | OptionGroup<NameLabelPair>[] = [
    { name: "in", label: "IN" },
    { name: "not_in", label: "NOT IN" },
  ];
  const defaultCombinators: NameLabelPair[] | OptionGroup<NameLabelPair>[] = [
    { name: "and", label: "AND" },
    { name: "and_not", label: "AND NOT" },
    { name: "or", label: "OR" },
    { name: "or_not", label: "OR NOT" },
  ];

  const fields: Field[] = props.fields;

  const operators: NameLabelPair[] | OptionGroup<NameLabelPair>[] =
    props?.operators || defaultOperators;

  const combinators: NameLabelPair[] | OptionGroup<NameLabelPair>[] =
    props?.combinators || defaultCombinators;

  const ValueEditor: ComponentType<ValueEditorProps> = props.valueEditor;

  const updateQuery = (query: RuleGroupTypeIC) => {
    let formattedQuery = formatQuery(query, "json_without_ids");
    props.onChange(formattedQuery);
  };

  return (
    <div className={props?.className}>
      <QB
        independentCombinators={true}
        fields={fields}
        query={props.query}
        listsAsArrays={true}
        onQueryChange={updateQuery}
        operators={operators}
        combinators={combinators}
        controlElements={{
          ...{
            fieldSelector: (props: ValueSelectorProps) =>
              fieldSelector({ ...props, disabled: disabled }),
            operatorSelector: (props: OperatorSelectorProps) =>
              operatorSelector({ ...props, disabled: disabled }),
            combinatorSelector: (props: CombinatorSelectorProps) =>
              combinatorSelector({ ...props, disabled: disabled }),
            removeRuleAction: (props: ActionProps) =>
              removeRuleOrGroupAction({ ...props, disabled: disabled }),
            removeGroupAction: (props: ActionProps) =>
              removeGroupAction({ ...props, disabled: disabled }),
            addRuleAction: (props: ActionWithRulesProps) =>
              addRuleOrGroupAction({
                ...props,
                title: "Rule",
                disabled: disabled,
              }),
            addGroupAction: (props: ActionWithRulesProps) =>
              addRuleOrGroupAction({
                ...props,
                title: "Groups",
                disabled: disabled,
              }),
          },
          ...(props.valueEditor ? { valueEditor: (props: ValueEditorProps) => <ValueEditor {...{...props, disabled:disabled}} /> } : {}),
          ...{}
        }}
      />
    </div>
  );
}
const isEqual = (prevProps: QBProps, nextProps: QBProps) => {
  return (
    JSON.stringify({ ...prevProps, valueEditor: {} }) ===
    JSON.stringify({ ...nextProps, valueEditor: {} })
  );
};

export default memo(QueryBuilder, isEqual);
