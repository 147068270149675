import { Grid, Slider, Typography } from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import { gradientPalette, theme } from "../../../theme";
import styled from "@emotion/styled";

import { ThemeColorPicker } from "./ThemeColorPicker";
import { getComponentSettings } from "../../../services/impact-report-service";
import {
  addAlphaToHexCode,
  checkVaildHexCode,
  gradientColorStyleCombined,
} from "../../../utils/color-picker-helper";
import { Box } from "@mui/system";
import { useAppDispatch } from "../../../store/hook";
import { setSettingEditActive } from "../../../store/selected-design-element-store";

const ShapesEditorSettings = (props: any) => {
  const Swatch = styled("div")({
    width: "24px",
    height: "24px",
    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.15)",
    border: "1px solid #fff",
    borderRadius: "6px",
    margin: "2px",
    display: "inline-block",
    cursor: "pointer",
  });

  const colorPickerStyle: CSSProperties = {
    position: "absolute",
    padding: "10px",
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.12) 4px 4px 10px 10px",
    borderRadius: "6px",
    zIndex: 1,
    minWidth: "300px",
    minHeight: "200px",
  };


  const { attributes, updateElement, id } = props;
  const fill = attributes?.fill;
  const stroke = attributes?.strokeColor;
  const [displayPicker, setDisplayPicker] = useState(false);
  const [color, setColor] = useState("");
  const [strokeColor, setStrokeColor] = useState("");
  const [strokeWidth, setStrokeWidth] = useState(attributes?.strokeWidth);
  const [settings, setSettings] = useState<any>([]);
  const [displayStrokeColorPicker, setDisplayStrokeColorPicker] =
    useState(false);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  useEffect(() => {
    setColor(attributes?.fill);
    setStrokeColor(attributes?.strokeColor);
  }, [fill, stroke]);

  const togglePicker = (e: any) => {
    if (!displayPicker) {
      setAnchorEl(e.currentTarget);
    }
    dispatch(setSettingEditActive(!displayPicker));
    setDisplayPicker(!displayPicker);
  };

  const handlePickerClose = () => {
    setAnchorEl(null);
    setDisplayPicker(false);
    dispatch(setSettingEditActive(false));
  };

  const handleColorChange = (color: any, gradient: any) => {
    if (color.hex === "transparent") {
      color = "#fff";
    } else {
      color = !checkVaildHexCode(color)
        ? addAlphaToHexCode(color.hex, color.rgb["a"])
        : color;
    }
    if (gradient.type !== "solid") {
      if (gradient.selectedColor && checkVaildHexCode(gradient.selectedColor)) {
        let index = gradientPalette.indexOf(gradient.selectedColor);
        if (~index) {
          gradientPalette[index] = color;
        }
      } else if (gradient.action === "add_color") {
        gradientPalette.push(color);
      } else if (gradient.action === "remove_color") {
        gradientPalette.pop();
      }
      let gradientColorStyle = gradientColorStyleCombined(
        gradientPalette,
        gradient.style
      );
      updateGradient(gradientPalette[0], gradientColorStyle);
      setColor(color);
    } else {
      updateFillColor(color, setColor);
    }
  };

  const toggleStrokeColorPicker = () => {
    if (!displayStrokeColorPicker) {
      setAnchorEl(anchorEl);
      props.updateOverlay(true);
    } else {
      setAnchorEl(null);
      props.updateOverlay(false);
    }
    dispatch(setSettingEditActive(!displayStrokeColorPicker));
    setDisplayStrokeColorPicker(!displayStrokeColorPicker);
  };
  useEffect(() => {
    getComponentSettings("shapes").then((res: any) => {
      if (res.status === 200) {
        if (res?.data?.results.length > 0) {
          setSettings(res?.data?.results[0]["settings"]);
        }
      }
    });
  }, []);

  const updateFillColor = (value: any, callBack: any) => {
    let nextElement = {
      ...attributes,
      fill: value,
      gradient: "",
    };

    updateElement(id, {
      attributes: nextElement,
    });
    callBack(value);
  };

  const updateGradient = (fillValue: any, value: any) => {
    let nextElement = { ...attributes, fill: fillValue, gradient: value };

    updateElement(id, {
      attributes: nextElement,
    });
  };

  const updateBorder = (e: any) => {
    setStrokeWidth(e.target.value as number);
    let nextElement = { ...attributes, strokeWidth: e.target.value as number };

    updateElement(id, {
      attributes: nextElement,
    });
  };

  const updateHexCode = (hexCode: string) => {
    updateFillColor(hexCode, setColor);
  };

  const updateStrokeHexCode = (hexCode: string) => {
    let nextElement = { ...attributes, strokeColor: hexCode };

    updateElement(id, {
      attributes: nextElement,
    });
    setStrokeColor(hexCode);
  };

  const onChangeStrokeColor = (colour: any) => {
    updateStrokeHexCode(addAlphaToHexCode(colour.hex, colour.rgb["a"]));
  };

  return (
    <Grid container>
      <Grid sx={{ zIndex: 100 }} item sm={12}>
        <Grid container>
          <Grid item xs={3}>
            <label>
              <Typography
                variant="inter_p_600_16"
                sx={{ color: theme.custom.primaryDarkColor }}
              >
                Fill
              </Typography>
            </label>
            <div aria-describedby={"fill-popper"} onClick={togglePicker}>
              <Swatch style={{ background: color }} />
            </div>
            {displayPicker && (
              <Box style={colorPickerStyle}>
                <ThemeColorPicker
                  anchorEl={anchorEl}
                  handleClose={handlePickerClose}
                  closePicker={togglePicker}
                  isShapes={true}
                  gradientTab={true}
                  onChange={handleColorChange}
                  updateHexCode={updateHexCode}
                  color={color}
                  colorPickerTitle={"Fill color"}
                  gradientApplied={attributes?.gradient}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={3}>
            <label>
              <Typography
                variant="inter_p_600_16"
                sx={{ color: theme.custom.primaryDarkColor }}
              >
                Stroke
              </Typography>
            </label>
            <div
              aria-describedby={"stroke-popper"}
              onClick={toggleStrokeColorPicker}
            >
              <Swatch style={{ background: strokeColor }} />
            </div>
            {displayStrokeColorPicker && (
              <Box style={colorPickerStyle}>
                <ThemeColorPicker
                  anchorEl={anchorEl}
                  closePicker={toggleStrokeColorPicker}
                  isShapes={true}
                  align={"top"}
                  onChange={(colour: any) => onChangeStrokeColor(colour)}
                  updateHexCode={updateStrokeHexCode}
                  color={strokeColor}
                  colorPickerTitle={"Stroke color"}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm={12}>
        <Grid
          container
          mt={2}
          mb={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item md={12}>
            <Typography
              variant="inter_p_600_16"
              sx={{ color: theme.custom.primaryDarkColor }}
            >
              Stroke Width
            </Typography>
          </Grid>
          <Grid item md={11}>
            <Slider
              aria-label="Stroke Width"
              size="small"
              sx={{
                color: theme.custom.primaryGreyColor,
                "& .MuiSlider-rail": {
                  color: theme.custom.borderColor,
                },
              }}
              value={strokeWidth}
              getAriaValueText={(value: number) => `${value}px`}
              step={1}
              onChange={updateBorder}
              min={0}
              max={20}
              valueLabelDisplay="auto"
              marks={settings["strokeWidth"] ? settings["strokeWidth"] : []}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ShapesEditorSettings;
