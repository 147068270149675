import axios from "axios";
import { environment } from "../environments/environment-dev";

const getKPIData = (
  slug: string,
  filters: any,
  getNugget: boolean = false,
  isPublic = false,
  commKey: string = "",
  board_slug: string = "",
  source: string = "",
  kpi_type: string = "",
) => {
  const requestBody = {
    slug: slug,
    data: { ...filters },
    nuggets_required: getNugget,
    communication_key: commKey,
    board: board_slug,
    source: source,
    kpi_type: kpi_type,
  };
  let url = "";
  if (isPublic) {
    url = `${environment.connectorApiUrl}/public-charts`;
  } else {
    url = `${environment.connectorApiUrl}/charts`;
  }
  return axios.post(url, requestBody);
};

export const getInfoForKpi = (slug: string) => {
  const url = `${environment.cmsUrl}/kpi-info/${slug}`;
  return axios.get(url);
};

export const generateSmartStatementForKpi = (
  slug: string,
  requestBody: any
) => {
  const url = `${environment.connectorApiUrl}/conversations/kpis/${slug}`;
  return axios.post(url, requestBody);
};

export default getKPIData;
