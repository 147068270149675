import { Stack, Box, Typography, Grid, Badge } from "@mui/material";
import { theme } from "../../theme";
import AIBadge from "../../assets/magic_stick_rounded.png";
import { QuickActions } from "../../Types/QuickActionItem";
type Props = {
  item: QuickActions;
  onClick: Function;
};

const QuickActionsItem = (props: Props) => {
  const { item, onClick } = props;
  return (
    <Badge
      overlap="rectangular"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      badgeContent={
        <img
          src={item?.meta?.badgeIconURL ? item?.meta?.badgeIconURL : AIBadge}
          alt={item?.label || ""}
          height={30}
          width={30}
        />
      }
      invisible={item.type !== "generate-content" && item.type !== "search"}
    >
      <Box
        sx={{
          maxWidth: 200,
          minWidth: 200,
          height: 90,
          paddingLeft: 1,
          py: 3,
          pr: 3,
          borderRadius: "6px",
          boxShadow: "0px 2px 10px 0px #00000026",
          cursor: "pointer",
        }}
        onClick={() => onClick(item.type, item.meta)}
      >
        <Stack alignItems={"center"} spacing={1.5} direction={"row"}>
          <Grid item>
            <img src={item?.icon} height={39} width={39} alt={item?.label} />
          </Grid>
          <Grid item>
            {" "}
            <Typography
              variant="inter_p_600_14"
              sx={{
                color: theme.custom.primaryDarkColor,
                wordBreak: "break-word",
                maxWidth: 200,
              }}
            >
              {item?.label || ""}
            </Typography>
          </Grid>
        </Stack>
      </Box>
    </Badge>
  );
};

export default QuickActionsItem;
