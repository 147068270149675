import {
  Stack,
  Typography,
  Box,
  Grid,
  TextField,
  Button,
  InputAdornment,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip, ToggleButton, ToggleButtonGroup,
} from "@mui/material";

import React, { memo, useEffect, useState } from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { Sidebar } from "./Sidebar";
import { fontInter, theme } from "../../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "../../assets/search.png";
import {
  deleteCustomKPI,
  getCustomKPIs,
} from "../../services/kpi-builder-service";
import { CustomKPIObject } from "../../Types/CustomKPIObject";
import LinkIcon from "../../assets/linkto.png";
import EditIcon from "../../assets/pencil.png";
import DeleteIcon from "../../assets/deleteIcon.png";
import { dateFormatter } from "../../utils/dataFormatters";
import LoaderIcon from "../../assets/loader.png";
import { useAppDispatch } from "../../store/hook";
import { showMessage } from "../../store/error-handler-store";
import { useDebounce } from "react-use";
import { remove } from "lodash";
import { setNavigationData } from "../../store/navigation-store";
import {getBoards} from "../../services/menu-service";
import CustomKpiListView from "../molecules/CustomKpi/CustomKpiListView";
import CustomKpiBoardView from "../molecules/CustomKpi/CustomKpiBoardView";

interface Props {
  category?: string
}

const CustomKPIComplex = (props: Props) => {
  const {category} = props
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchStr, setSearchStr] = useState("");
  const [debouncedValue, setDebouncedValue] = useState(searchStr);
  const [tableData, setTableData] = useState([] as CustomKPIObject[]);
  const [creatable, setCreatable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(searchStr);
    },
    600,
    [searchStr]
  );
  const [boards, setBoards] = React.useState([]);
  const [displayType, setDisplayType] = React.useState("kpi");

  useEffect(() => {
    if (location.hash !== "") {
      setDisplayType(location.hash.slice(1));
    }
  }, [location])

  const menuItemClickHandler = (item: any) => {
    let url = `/board/${item.board_slug}`;
    dispatch(setNavigationData(item));
    navigate(url);
  };

  const getAllBoardItems = (category: string, search="") => {
    setIsLoading(true);
    if (search) {
      setBoards(boards.filter((board: any) => board?.title?.includes(search)))
      setIsLoading(false);
    } else {
      getBoards(category).then((res) => {
        if (res.status === 200) {
          setBoards(res.data?.menu[0].sub_menu)
          setIsLoading(false);
        }
      });
    }
  };

  const toggleDisplayType = () => {
    let new_value = displayType === "board" ? "kpi" : "board"
    setDisplayType(new_value)
    setSearchStr("")
    if (category && displayType && new_value === "board")
      getAllBoardItems(category);
    else if (category && displayType && new_value === "kpi")
      getAllKPI();
  }

  useEffect(() => {
    if (category && displayType === "board")
      getAllBoardItems(category, debouncedValue);
    else
      getAllKPI(debouncedValue);
  }, [debouncedValue, category, displayType]);

  useEffect(() => {
    dispatch(
      setNavigationData({
        title: "Custom KPIs",
      })
    );
    document.title = "Custom KPIs - Pebble";
  }, []);

  const getAllKPI = (key: string = "") => {
    setTableData([]);
    setIsLoading(true);
    getCustomKPIs(key).then((res) => {
      if (res.status === 200) {
        setTableData(res.data.data);
        setCreatable(res.data?.is_creatable || false);
        setIsLoading(false);
      }
    });
  };

  const onDeleteKPI = (uuid: any, deletable: boolean) => {
    if (deletable) {
      let confirm = window.confirm("Are you sure to delete the KPI?");
      if (confirm) {
        deleteCustomKPI(uuid).then((res) => {
          if (res.status === 200) {
            setTableData(
              remove(tableData, (n: any) => {
                return n.uuid !== uuid;
              })
            );
            dispatch(
              showMessage({
                message: "KPI deleted successfully.",
                variant: "success",
              })
            );
          }
        });
      }
    }
  };

  const navigateToBoard = (boardSlug: string, kpiSlug: string) => {
    navigate(`/board/${boardSlug}#${kpiSlug}`);
  };

  const onEditKPI = (kpi_slug: string, editable: boolean) => {
    if (editable) {
      navigate(`/kpi-builder/${kpi_slug}`);
    }
  };
  return (
    <>
      <Stack direction="row">
        {!category &&
        (<Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
          <Sidebar />
        </Box>)}
        <Box sx={{ width: "100%" }} p={!category ? 8 : 0}>
          {!category &&
          (<Grid container>
            <Grid
              onClick={() => {
                navigate("/panorama");
              }}
              item
            >
              <ArrowBackIcon
                sx={{ color: theme.custom.primaryDarkColor, cursor: "pointer" }}
              />
            </Grid>
            <Grid item>
              <Typography variant="poppins_h5_600_18" ml={2}>
                Custom KPIs
              </Typography>
            </Grid>
          </Grid>)}
          <Grid mt={3} container justifyContent={"space-between"}>
            <Grid item>
              <TextField
                placeholder="Search by keyword"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img height="24" src={SearchIcon} alt="Search" />
                    </InputAdornment>
                  ),
                }}
                inputProps={{
                  style: {
                    height: "35px",
                    fontFamily: fontInter,
                    fontSize: "14px",
                    fontWeight: 500,
                    background: theme.custom.whiteColor,
                  },
                }}
                value={searchStr}
                onChange={(event) => {
                  setSearchStr(event.target.value);
                }}
                size="small"
                fullWidth
                id="budget-value"
              />
            </Grid>
            {displayType &&
            (<Grid item sm={8} textAlign={"right"}>
              <Grid item>
                <ToggleButtonGroup
                  color="primary"
                  value={displayType}
                  exclusive
                  className="rounded-toggle"
                  onChange={() => {toggleDisplayType && toggleDisplayType()}}
                  aria-label="Switch"
                >
                  <ToggleButton
                    sx={{
                      "&.Mui-selected, &.Mui-selected:hover": {
                        color: "white",
                        backgroundColor: "#7F7CFF",
                      },
                    }}
                    value="board"
                  >
                    <Typography variant={"inter_p_600_14"} ml={1}>
                      Board
                    </Typography>
                  </ToggleButton>
                  <ToggleButton
                    sx={{
                      "&.Mui-selected, &.Mui-selected:hover": {
                        color: "white",
                        backgroundColor: "#7F7CFF",
                      },
                    }}
                    value="kpi"
                  >
                    KPI
                  </ToggleButton>
                </ToggleButtonGroup>
              </Grid>
            </Grid>)}
            <Grid item sx={{ position: "relative" }}>
              <Button
                disabled={!creatable}
                onClick={() => {
                  navigate("/kpi-builder");
                }}
                sx={{ height: 50, width: 150 }}
                fullWidth
                variant="contained"
              >
                Add KPI
              </Button>
            </Grid>
          </Grid>
          {displayType === "kpi" ?
            (<Box mt={4}>
              <CustomKpiListView tableData={tableData} navigateToBoard={navigateToBoard} onEditKPI={onEditKPI} onDeleteKPI={onDeleteKPI}/>
            </Box>) :
            (<Box mt={4}>
              <CustomKpiBoardView boards={boards} menuItemClickHandler={menuItemClickHandler}/>
            </Box>)
          }
          {isLoading &&
          <Box
              sx={{
                height: 100,
                display: isLoading ? "block" : "none",
              }}
          >
              <Grid
                  className="show-loader"
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
              >
                  <Grid item>
                      <img
                          src={LoaderIcon}
                          height="100"
                          width="100"
                          alt="Loader"
                      />
                  </Grid>
              </Grid>
          </Box>}
        </Box>
      </Stack>
    </>
  );
};

export default memo(CustomKPIComplex);
