import {
  Typography,
  Box,
  Grid,
  Slider,
  Divider,
  Button,
  Tooltip,
  ImageList,
  ImageListItem,
  Popover,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useRef, useState, useEffect } from "react";
import { theme } from "../../../theme";
import AcceptIcon from "../../../assets/accept.png";
import CancelIcon from "../../../assets/cancel.png";
import CropIcon from "../../../assets/crop.png";
import GrayScaleIcon from "../../../assets/filter_grayscale.png";
import BlurIcon from "../../../assets/filter_blur.png";
import SepiaIcon from "../../../assets/filter_sepia.png";
import SaturationIcon from "../../../assets/filter_saturation.png";
import GrayScaleAppliedIcon from "../../../assets/filter_grayscale_applied.png";
import BlurAppliedIcon from "../../../assets/filter_blur_applied.png";
import SepiaAppliedIcon from "../../../assets/filter_sepia_applied.png";
import SaturationAppliedIcon from "../../../assets/filter_saturation_applied.png";
import { maskLibrary } from "../../../services/impact-report-service";

function ImageEditorSettings(props: any) {
  const [inEdit, setInEdit] = useState(false);
  const { updateElement, element: selectedElement, toggleCustomAbles } = props;
  const _clipStyleBounds = useRef(selectedElement.clipStyleBounds);
  const [blur, setBlur] = useState(selectedElement.attributes?.blur || false);
  const [grayscale, setGrayscale] = useState(
    selectedElement.attributes?.grayscale || false
  );
  const [saturation, setSaturation] = useState(
    selectedElement.attributes?.saturation || false
  );
  const [sepia, setSepia] = useState(
    selectedElement.attributes?.sepia || false
  );
  const [borderRadius, setBorderRadius] = useState<number>(0);
  const [selectedMask, setSelectedMask] = useState(
    selectedElement?.clipPath || null
  );

  const [maskData, setMaskData] = useState<any>([]);
  const [authorized, setAuthorized] = useState(true);

  const [maskPopover, setMaskPopover] = useState(false);

  const toggleMaskPopover = () => {
    setMaskPopover(!maskPopover);
  };

  useEffect(() => {
    maskLibrary().then((res: any) => {
      if (res.status === 403) {
        setAuthorized(false);
        return;
      }

      if (res.status === 200) {
        setMaskData(res.data.results);
      }
    });
  }, []);

  useEffect(() => {
    setBorderRadius(selectedElement?.borderRadius || 0);
    setSelectedMask(selectedElement?.clipPath || "");
    setGrayscale(selectedElement.attributes?.grayscale || false);
    setBlur(selectedElement.attributes?.blur || false);
    setSaturation(selectedElement.attributes?.saturation || false);
    setSepia(selectedElement.attributes?.sepia || false);
  }, [selectedElement]);

  const updateFilter = (key: any, value: any, callBack: any) => {
    let nextElement = { ...selectedElement };
    nextElement.attributes = {
      ...nextElement.attributes,
      [key]: value,
    };

    updateElement(selectedElement.id, { attributes: nextElement.attributes });
    callBack(value);
  };

  const updateBorderRadius = (e: any) => {
    updateElement(selectedElement.id, { borderRadius: e.target.value });
  };

  const resetFilter = () => {
    let nextElement = { ...selectedElement };
    nextElement.attributes = {
      ...nextElement.attributes,
      blur: false,
      grayscale: false,
      saturation: false,
      sepia: false,
    };
    setBlur(false);
    setGrayscale(false);
    setSaturation(false);
    setSepia(false);

    updateElement(selectedElement.id, { attributes: nextElement.attributes });
  };

  const cancelClipStyle = (e: any) => {
    if (selectedElement.clipStyleBounds !== _clipStyleBounds.current) {
      updateElement(selectedElement.id, {
        clipStyleBounds: _clipStyleBounds.current,
      });
    }
    toggleCustomAbles("clip", false);
    setInEdit(false);
  };

  const resetClipStyle = (e: any) => {
    toggleCustomAbles("clip", false);
    updateElement(selectedElement.id, {
      clipStyleBounds: [0, 0, 0, 0],
    });
  };

  const enableClipAble = () => {
    toggleCustomAbles("clip", true);
    setInEdit(!inEdit);
  };

  const saveClipStyle = (e: any) => {
    toggleCustomAbles("clip", false);
    setInEdit(false);
    e.stopPropagation();
  };

  const maskItem = (ele: any, index: number) => {
    return (
      <ImageListItem
        sx={{
          height: 35,
          width: 35,
          padding: 0.2,
        }}
        key={index}
      >
        <div
          style={{
            padding: 2,
            borderRadius: 2,
            border:
              selectedMask && selectedMask === ele?.attributes?.value
                ? `1px solid ${theme.palette.primary.main}`
                : "",
          }}
        >
          <div
            onClick={() => selectMask(ele)}
            style={{
              height: 30,
              clipPath: ele?.attributes?.value,
              backgroundColor:
                selectedMask && selectedMask === ele?.attributes?.value
                  ? theme.palette.primary.main
                  : theme.custom.grayBgColor,
            }}
          ></div>
        </div>
      </ImageListItem>
    );
  };

  const selectMask = (image: any) => {
    if (image.attributes.value === selectedMask) {
      setSelectedMask(null);
      updateElement(selectedElement.id, { clipPath: `` });
    } else {
      setSelectedMask(image.attributes.value);
      updateElement(selectedElement.id, {
        clipPath: image.attributes.value,
      });
    }
  };

  return (
    <>
      <Box>
        <Box>
          <Grid
            container
            mb={2}
            justifyContent={"space-between"}
            alignItems="center"
          >
            {!inEdit && (
              <>
                <Grid item>
                  <img
                    style={{ cursor: "pointer" }}
                    onClick={() => enableClipAble()}
                    src={CropIcon}
                    alt="crop"
                    height="25"
                  />
                </Grid>
                <Grid item>
                  <Button variant="text" onClick={resetClipStyle}>
                    Reset
                  </Button>
                </Grid>{" "}
              </>
            )}
            <Grid
              item
              sm={12}
              mb={2}
              textAlign="right"
              justifyContent={"right"}
            >
              {inEdit && (
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Grid item>
                    <Typography variant="inter_p_600_16">Crop</Typography>
                  </Grid>
                  <Grid item textAlign={"right"}>
                    <img
                      style={{ cursor: "pointer", marginRight: 15 }}
                      onClick={saveClipStyle}
                      src={AcceptIcon}
                      alt="Done"
                      height="15"
                    />

                    <img
                      style={{ cursor: "pointer" }}
                      onClick={cancelClipStyle}
                      src={CancelIcon}
                      alt="Cancel"
                      height="15"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item md={12}>
              <Divider sx={{ mt: 2, mb: 2, ml: -2, mr: -2 }} />
            </Grid>
          </Grid>
          <Grid container justifyContent={"space-between"} alignItems="center">
            <Grid item md={12}>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems="center"
                mb={2}
              >
                <Grid item>
                  <Typography
                    variant="inter_p_600_16"
                    sx={{ color: theme.custom.primaryDarkColor }}
                  >
                    Filters
                  </Typography>
                </Grid>
                <Grid item>
                  <Button variant="text" onClick={resetFilter}>
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <div
                onClick={() =>
                  updateFilter("grayscale", !grayscale, setGrayscale)
                }
                style={{
                  cursor: "pointer",
                }}
              >
                {grayscale ? (
                  <>
                    <Tooltip title="Remove Grayscale">
                      <img src={GrayScaleAppliedIcon} alt="Apply Grayscale" />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title="Apply Grayscale">
                      <img src={GrayScaleIcon} alt="Apply Grayscale" />
                    </Tooltip>
                  </>
                )}
              </div>
            </Grid>
            <Grid item>
              <div
                onClick={() => updateFilter("blur", !blur, setBlur)}
                style={{
                  cursor: "pointer",
                }}
              >
                {blur ? (
                  <>
                    <Tooltip title="Remove Blur">
                      <img src={BlurAppliedIcon} alt="Apply Blur" />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title="Apply Blur">
                      <img src={BlurIcon} alt="Apply Blur" />
                    </Tooltip>
                  </>
                )}
              </div>
            </Grid>
            <Grid item>
              <div
                onClick={() =>
                  updateFilter("saturation", !saturation, setSaturation)
                }
                style={{
                  cursor: "pointer",
                }}
              >
                {saturation ? (
                  <>
                    <Tooltip title="Remove Saturation">
                      <img src={SaturationAppliedIcon} alt="Apply Saturation" />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title="Apply Saturation">
                      <img src={SaturationIcon} alt="Apply Saturation" />
                    </Tooltip>
                  </>
                )}
              </div>
            </Grid>
            <Grid item>
              <div
                onClick={() => updateFilter("sepia", !sepia, setSepia)}
                style={{
                  cursor: "pointer",
                }}
              >
                {sepia ? (
                  <>
                    <Tooltip title="Remove Sepia">
                      <img src={SepiaAppliedIcon} alt="Apply Sepia" />
                    </Tooltip>
                  </>
                ) : (
                  <>
                    <Tooltip title="Apply Sepia">
                      <img src={SepiaIcon} alt="Apply Sepia" />
                    </Tooltip>
                  </>
                )}
              </div>
            </Grid>
          </Grid>
          <Divider sx={{ my: 2, mx: -2 }} />
          {authorized && (
            <>
              <Grid container mt={2} alignItems="center">
                <Grid item md={12}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        variant="inter_p_600_16"
                        sx={{ color: theme.custom.primaryDarkColor }}
                      >
                        Mask
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={10}>
                  <ImageList
                    sx={{
                      gridAutoFlow: "column",
                    }}
                  >
                    {maskData &&
                      maskData.length > 0 &&
                      maskData
                        .slice(0, 5)
                        .map((ele: any, index: number) => maskItem(ele, index))}
                  </ImageList>
                </Grid>
                <Grid item md={2}>
                  <Button variant="text" onClick={toggleMaskPopover}>
                    <Typography
                      variant="inter_500_12"
                      sx={{ color: theme.custom.primaryDarkColor }}
                    >
                      More
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
              <Divider sx={{ my: 2, mx: -2 }} />

              <Popover
                open={maskPopover}
                anchorReference="anchorPosition"
                anchorPosition={{ top: 630, left: 230 }}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
              >
                <Box m={2}>
                  <Box mt={2}>
                    <Grid mb={1} justifyContent="space-between" container>
                      <Grid item>
                        <Typography
                          sx={{ color: theme.custom.primaryDarkColor }}
                          variant="inter_p_600_16"
                        >
                          Choose Mask
                        </Typography>
                      </Grid>
                      <Grid item onClick={toggleMaskPopover}>
                        <div>
                          <CloseIcon />
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item md={12}>
                      <ImageList cols={5} sx={{ width: 220, height: 125 }}>
                        {maskData &&
                          maskData.length > 0 &&
                          maskData.map((ele: any, index: number) =>
                            maskItem(ele, index)
                          )}
                      </ImageList>
                    </Grid>
                  </Box>
                </Box>
              </Popover>
            </>
          )}
          <Grid
            container
            mt={2}
            mb={2}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item md={12}>
              <Typography
                variant="inter_p_600_16"
                sx={{ color: theme.custom.primaryDarkColor }}
              >
                Border Radius
              </Typography>
            </Grid>
            <Grid item md={11}>
              <Slider
                aria-label="BorderRadius"
                getAriaValueText={(value: number) => `${value}%`}
                value={borderRadius}
                step={1}
                size="small"
                sx={{
                  color: theme.custom.primaryGreyColor,
                  "& .MuiSlider-rail": {
                    color: theme.custom.borderColor,
                  },
                  "& .MuiSlider-markLabel": {
                    color: theme.custom.primaryGreyColor,
                    fontSize: "12px",
                  },
                }}
                min={0}
                max={50}
                onChange={updateBorderRadius}
                valueLabelDisplay="auto"
              />
            </Grid>
          </Grid>
          <Divider sx={{ my: 2, mx: -2 }} />
        </Box>
      </Box>
    </>
  );
}

export default React.memo(ImageEditorSettings);
