import { Divider, Grid, Stack, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PebbleBgImage from "../components/atoms/PebbleBgImage";
import ConnectorsList from "../components/complexes/ConnectorsList";
import { Sidebar } from "../components/complexes/Sidebar";
import { useAppDispatch, useAppSelector } from "../store/hook";
import Popover from "@mui/material/Popover";
import InfoIcon from "../assets/info.png";
import { Box } from "@mui/system";
import { setMoveToMapping } from "../store/connector-store";
import { setNavigationData } from "../store/navigation-store";

export const ConnectorListPage: React.FC = () => {
  let navigate = useNavigate();
  let dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    dispatch(
      setNavigationData({
        title: "Connectors List",
      })
    );
    document.title = "Connectors List - Pebble"
  }, []);
  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const moveToMapping = useAppSelector(
    (state) => state.connector.moveToMapping
  );
  if (moveToMapping !== "") {
    if (["quickbooks"].includes(moveToMapping)) {
      navigate(`/connectors/${moveToMapping}/user-mapping/`);
    } else if (["googlesheet"].includes(moveToMapping)) {
      navigate(`/connectors/googlesheet/list`);
    } else {
      navigate(`/connectors/${moveToMapping}/status`);
    }
    dispatch(setMoveToMapping({ connectorSlug: "" }));
  }
  return (
    <Stack direction="row">
      <Sidebar />
      <Divider orientation="vertical" flexItem />
      <Grid container>
        <Grid sx={{ pl: 8, pr: 16, pt: 12 }} item lg={9} md={12}>
          <Typography variant="h4">
            Connect your systems
            <img
              alt="img"
              style={{
                verticalAlign: "middle",
                cursor: "pointer",
                height: "20px",
                paddingLeft: "0.2em",
              }}
              src={InfoIcon}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            />
          </Typography>
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              style: { width: "50%" },
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Box p={2}>
              <Typography sx={{ my: 1 }} variant="inter_p_400_16">
                Don't worry - connecting your systems won't overrwrite any data
                in these other systems, it will just allow Pebble to build more
                insightful analyses into your organization's performance and
                help you take control of your nonprofit's future.
              </Typography>
              <Typography sx={{ my: 1 }} variant="inter_p_400_16">
                Please connect Quickbooks to see analytics built for leaders,
                not just accountants...
              </Typography>
            </Box>
          </Popover>
          <Typography sx={{ my: 1 }} variant="inter_p_400_16">
            Connecting your systems allows Pebble to build robust KPIs, budgets,
            and graphics for impact and financial reports.
          </Typography>
          <ConnectorsList />
        </Grid>
        <Grid item md={3} sx={{ display: { md: "none", lg: "block" } }}>
          <PebbleBgImage url="https://pebble-fe-assets.s3.us-west-1.amazonaws.com/Group+65-min.png" />
        </Grid>
      </Grid>
    </Stack>
  );
};
