import * as React from "react";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { theme } from "../../theme";
import { Stack } from "@mui/material";

interface Props {
  value: number;
  variant: "primary" | "grey";
}

const BorderLinearProgress = (props: Props) => {
  return (
    <Stack direction="column" justifyContent="center" style={{height: "100%"}}>
      <div>
        <LinearProgress
          variant="determinate"
          value={props.value}
          sx={{
            height: "10px",
            borderRadius: 5,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: `${theme.custom.progressBgGhostColor}`,
            },
            [`& .${linearProgressClasses.bar}`]: {
              borderRadius: 5,
              backgroundColor:
                props.variant == "primary"
                  ? `${theme.custom.activeColor}`
                  : `${theme.custom.primaryGreyColor}`,
            },
          }}
        />
      </div>
    </Stack>
  );
};

export default BorderLinearProgress;
