import React, { useState } from "react";
import {
  logIn,
  setToken,
  removeToken,
} from "../services/authentication-service";
import { useAppDispatch } from "../store/hook";
import { login } from "../store/authentiction-store";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
import LoadingButton from "@mui/lab/LoadingButton";
import { theme } from "../theme";
import Banner from "../components/complexes/Banner";
import {
  fetchUserInfo,
  setCompanyDataToLocalStorage,
} from "../services/userinfo-service";
import useLocalStorageState from "use-local-storage-state";
import { USER_INFO_LOCAL_STORAGE_KEY } from "../constants/constants";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const font = theme.typography.subtitle1.fontFamily;

  const BgImage = styled("img")({
    height: "100%",
    width: "100%",
    objectFit: "cover",
    bgcolor: theme.custom.bgImageColor,
  });

  const INIT_STATE = {
    username: "",
    password: "",
    isLoginSuccess: true,
  };
  const [userDetails, setUserDetails] = useState(INIT_STATE);
  const [loading, setLoading] = useState(false);
  const [localStorageInfo, setLocalStorageInfo] = useLocalStorageState(
    USER_INFO_LOCAL_STORAGE_KEY,
    {}
  );

  const userNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserDetails((prev) => {
      return { ...prev, username: event.target.value };
    });
  };

  const passwordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserDetails((prev) => {
      return { ...prev, password: event.target.value };
    });
  };

  const submitHandler = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();
    removeToken();
    setLoading(true);
    logIn(userDetails)
      .then((res) => {
        dispatch(login(JSON.stringify(res["data"]["data"]["token_info"])));
        setToken(res["data"]["data"]["token_info"]);
        setUserDetails((prev) => {
          return { ...prev, isLoginSuccess: true };
        });
        fetchUserInfo().then((userInfo) => {
          setLocalStorageInfo(userInfo?.data);
          setCompanyDataToLocalStorage(
            JSON.stringify(userInfo?.data?.default_company_details)
          );
          if (
            userInfo &&
            userInfo.data &&
            userInfo.data?.connector_status === "SUCCESS"
          ) {
            navigate("/");
          } else {
            navigate("/connectors/list");
          }
        });
      })
      .catch((err) => {
        setUserDetails((prev) => {
          return { ...prev, isLoginSuccess: false };
        });
        setLoading(false);
      });
  };

  const forgotPasswordHelper = () => {
    navigate("/forgot-password");
  };

  return (
    <React.Fragment>
      <Grid container sx={{ height: "100vh" }} justifyContent="space-around">
        <Grid item sm={4.3} sx={{ position: "relative", height: "100%" }}>
          <Grid
            px={8}
            item
            sm={12}
            sx={{ position: "relative", top: "5%", zIndex: 1 }}
          >
            <Typography color="primary" sx={{ fontSize: 22 }} variant="h4">
              Pebble
            </Typography>
          </Grid>
          <Grid
            px={8}
            item
            sm={12}
            sx={{ position: "relative", top: "18%", zIndex: 1 }}
          >
            <Typography
              variant="poppins_h2_700_30"
              color={theme.custom.primaryDarkColor}
              mb={3}
            >
              Sign in to Pebble
            </Typography>
            <Typography
              variant="inter_p_400_16"
              color={theme.custom.primaryGreyColor}
            >
              Sign in to to access your dashboard
            </Typography>
          </Grid>
          <Grid
            px={7}
            item
            sm={12}
            sx={{ position: "relative", top: "20%", zIndex: 1 }}
          >
            <form onSubmit={submitHandler}>
              <TextField
                id="username"
                required
                name="username"
                value={userDetails.username}
                type="email"
                sx={{ my: 3 }}
                variant="outlined"
                placeholder="Username"
                inputProps={{
                  maxLength: 256,
                  style: {
                    fontFamily: font,
                    fontSize: "14px",
                    color: theme.custom.placeholderColor,
                  },
                }}
                fullWidth
                onChange={userNameHandler}
              />
              <TextField
                id="password"
                name="password"
                required
                value={userDetails.password}
                type="password"
                sx={{ mb: 1 }}
                fullWidth
                variant="outlined"
                placeholder="Password"
                inputProps={{
                  maxLength: 30,
                  style: {
                    fontSize: "14px",
                    fontFamily: font,
                    color: theme.custom.placeholderColor,
                  },
                }}
                onChange={passwordHandler}
              />

              <LoadingButton
                sx={{ width: "100%", mt: 2.5, mb: 2, height: 50 }}
                variant="contained"
                loading={loading}
                type="submit"
              >
                <Typography variant="poppins_p_600_14">Sign In</Typography>
              </LoadingButton>
            </form>
            {!userDetails.isLoginSuccess && (
              <Typography
                align="center"
                paragraph={true}
                sx={{ color: theme.custom.errorColor }}
                mt={2}
              >
                Invalid Credentials
              </Typography>
            )}
            <Box onClick={forgotPasswordHelper}>
              <Typography
                color="primary"
                variant="poppins_p_600_14"
                mt={1}
                sx={{ cursor: "pointer" }}
              >
                Forgot Password?
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          sx={{
            backgroundColor: theme.custom.bgImageColor,
          }}
          sm={7.7}
        >
          <Banner slug={"login"} extra={{ fullHeight: true }}></Banner>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Login;
