import { Box } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { getTextLibrary } from "../../../services/impact-report-service";
import AuthorizationErrorHandler from "./AuthorizationErrorHandler";
import TextPromptEditor from "./TextPromptEditor";

function TextEditorLibrary(props: any) {
  let mouseOrigin = useRef({ left: 0, top: 0 });
  let cursorPosition = useRef({ left: 0, top: 0 });
  const [textEditorLib, setTextEditorLib] = useState<Array<Object>>([]);
  const [authorized, setAuthorized] = useState(true);
  const [isPromptActive, setIsPromptActive] = useState(false);

  useEffect(() => {
    getTextLibrary().then((resp) => {
      if (resp.status === 403) {
        setAuthorized(false);
        return;
      }
      setTextEditorLib(resp.data.results);
    });
  }, []);

  const addElement = (
    e: any,
    { delta, attributes }: any,
    dnd: boolean = true
  ) => {
    if (
      (cursorPosition.current.left <= 0 || cursorPosition.current.top <= 0) &&
      dnd
    ) {
      return;
    }

    // On drag, align is not set or empty, so set it to center
    if (attributes?.align === "") {
      attributes.align = "center";
    }

    const { addElement } = props;
    const element = {
      type: "TextEditor",
      transparency: 1,
      zIndex: 0,
      posX: 100,
      posY: 100,
      rotate: 0,
      scale: 1,
      delta: delta,
      attributes: { ...attributes, onLoadDelta: delta },
    };

    addElement(
      element,
      {
        left: cursorPosition.current?.left,
        top: cursorPosition.current?.top,
      },
      "drop",
      "",
      dnd
    );
  };

  const onDrag = (e: any) => {
    //TODO: On DragEnd page coordinates are coming wrong.
    // Monkey patch to force correct it. Heavy ops issue here.
    cursorPosition.current.top = e.pageY;
    cursorPosition.current.left = e.pageX;
  };
  const onDragStart = (e: any) => {
    let _bounds = e.currentTarget.getBoundingClientRect();
    mouseOrigin.current.top = e.clientY - _bounds.top;
    mouseOrigin.current.left = e.clientX - _bounds.left;
  };

  const onPromptActiveChange = (_isPromptActive: boolean = false) => {
    setIsPromptActive(_isPromptActive);
  };

  return (
    <>
      {authorized && (
        <Box pt={2} mb={1}>
          <TextPromptEditor
            onDrag={onDrag}
            onDragStart={onDragStart}
            onDragEnd={addElement}
            onActiveChange={onPromptActiveChange}
          />
          {isPromptActive && (
            <div
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                background: "white",
                opacity: "0.8",
                zIndex: 1,
              }}
            ></div>
          )}
          {textEditorLib.length
            ? textEditorLib.map((item: any) => (
                <div
                  key={item.id}
                  draggable={true}
                  onDrag={onDrag}
                  onDragStart={onDragStart}
                  onDragEnd={(e) => addElement(e, item)}
                  onClick={(e) => addElement(e, item, false)}
                >
                  <img
                    style={{
                      maxWidth: "100%",
                      border: "1px dashed #000",
                      borderRadius: "5px",
                    }}
                    // TODO: Move this to api with s3 urls
                    src={`https://cdn-pebble.bookr.inc/FE+assets/impact-report/text/${item.handle}.png`}
                    alt={item.attributes.text}
                  />
                </div>
              ))
            : ""}
        </Box>
      )}
      {!authorized && (
        <AuthorizationErrorHandler
          errorMessage="You don’t have access to see the list of texts. Please reach out to
        admin to get access"
        />
      )}
    </>
  );
}

export default React.memo(TextEditorLibrary);
