import { PaletteOptions, ThemeOptions, createTheme } from "@mui/material";
import React, { useMemo } from "react";

export const fontPoppins = "'Poppins', sans-serif";
export const fontInter = "'Inter', sans-serif";

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    poster: true;
    h3: true;
  }
}

declare module "@mui/material/styles" {
  interface Theme {
    custom: {
      buttonWidth: string;
      bgImageColor: string;
      placeholderColor: string;
      designBgColor: string;
      errorColor: string;
      KPIMoleculeBorderColor: string;
      KPIMoleculeBackgroundColor: string;
      ConnectorsListPageBgColor: string;
      CustomCheckboxColor: string;
      CustomCheckboxBgColor: string;
      CustomCheckboxBoxShadow: string;
      activeColor: string;
      blackColor: string;
      whiteColor: string;
      dropdownSecondaryColor: string;
      dropdownPrimaryColor: string;
      filterBorderColor: string;
      filterBackgroundColor: string;
      progressBarColor: string;
      menuDimColor: string;
      mapFieldStatusBg: string;
      primaryDarkColor: string;
      primaryGreyColor: string;
      chipGroupBackground: string;
      borderColor: string;
      disabledColor: string;
      menuDividerColor: string;
      progressBgGhostColor: string;
      profileBackgroundColor: string;
      resetMsgTextBgColor: string;
      successColor: string;
      popoverLinkColor: string;
      opaqueWhite: string;
      accentBgColor: string;
      grayBgColor: string;
      yellowButtonColor: string;
      toolTipBg: string;
      secondaryYellowColor: string;
      primaryGreen: string;
      secondaryGreen: string;
    };
    CustomChip: {
      closeIconColor: string;
      backgroundColor: string;
      fontColor: string;
      primaryBlue: string;
      backgroundLightGray: string;
    };
    kpiMoleculeColors: {
      backgroundColor: string;
      borderColor: string;
      leftDescriptionItemBgColor: string;
      rightDescriptionItemBgColor: string;
      descriptionItemHeader: string;
    };
    dataTableColors: {
      bgColor: string;
      headerBgColor: string;
      borderColor: string;
      headerDarkBgColor: string;
    };
    stories: {
      kpiConnectorColor: string;
    };
    sidebar: {
      borderColor: string;
      menuItemBackgroundColor: string;
    };
    customSwitch: {
      inactiveColor: string;
    };
    budgetingRelatedColors: {
      expenseBgColor: string;
      revenueBGColor: string;
      staffingBGColor: string;
    };
    impactReportingColors: {
      headerColor: string;
      shareTextColor: string;
      zoomBgColor: string;
      activeColor: string;
      menuBorderColor: string;
      bgColor: string;
      guidelineColor: string;
      toggleBtnBgColor: string;
    };
    kpiSmartReportColors: {
      popoverBackgroundColor: string;
      popoverTitleBackgroundColor: string;
    };
    kpiBuilderColors: {
      chartTypeBackgroundColor: string;
    };
  }

  interface Palette {
    ghost: Palette["primary"];
  }
  interface PaletteOptions {
    ghost: PaletteOptions["primary"];
  }
  interface ThemeOptions {
    custom: {
      buttonWidth: string;
      bgImageColor: string;
      designBgColor: string;
      placeholderColor: string;
      errorColor: string;
      KPIMoleculeBorderColor: string;
      KPIMoleculeBackgroundColor: string;
      ConnectorsListPageBgColor: string;
      CustomCheckboxColor: string;
      CustomCheckboxBgColor: string;
      CustomCheckboxBoxShadow: string;
      activeColor: string;
      blackColor: string;
      whiteColor: string;
      dropdownSecondaryColor: string;
      dropdownPrimaryColor: string;
      filterBorderColor: string;
      filterBackgroundColor: string;
      progressBarColor: string;
      menuDimColor: string;
      mapFieldStatusBg: string;
      primaryDarkColor: string;
      primaryGreyColor: string;
      chipGroupBackground: string;
      borderColor: string;
      disabledColor: string;
      menuDividerColor: string;
      progressBgGhostColor: string;
      profileBackgroundColor: string;
      resetMsgTextBgColor: string;
      successColor: string;
      popoverLinkColor: string;
      opaqueWhite: string;
      accentBgColor: string;
      grayBgColor: string;
      errorTextColor: string;
      yellowButtonColor: string;
      toolTipBg: string;
      secondaryYellowColor: string;
      primaryGreen: string;
      secondaryGreen: string;
    };
    CustomChip: {
      closeIconColor: string;
      backgroundColor: string;
      fontColor: string;
      primaryBlue: string;
      backgroundLightGray: string;
    };
    kpiMoleculeColors: {
      backgroundColor: string;
      borderColor: string;
      leftDescriptionItemBgColor: string;
      rightDescriptionItemBgColor: string;
      descriptionItemHeader: string;
    };
    dataTableColors: {
      bgColor: string;
      headerBgColor: string;
      borderColor: string;
      headerDarkBgColor: string;
    };
    stories: {
      kpiConnectorColor: string;
    };
    sidebar: {
      borderColor: string;
      menuItemBackgroundColor: string;
    };
    customSwitch: {
      inactiveColor: string;
    };
    budgetingRelatedColors: {
      expenseBgColor: string;
      revenueBGColor: string;
      staffingBGColor: string;
    };
    impactReportingColors: {
      headerColor: string;
      shareTextColor: string;
      zoomBgColor: string;
      activeColor: string;
      menuBorderColor: string;
      bgColor: string;
      guidelineColor: string;
      toggleBtnBgColor: string;
    };
    kpiSmartReportColors: {
      popoverBackgroundColor: string;
      popoverTitleBackgroundColor: string;
    };
    kpiBuilderColors: {
      chartTypeBackgroundColor: string;
    };
  }
  interface Palette {
    successAlternate: Palette["primary"];
    errorAlternate: Palette["error"];
  }
  interface PaletteOptions {
    successAlternate: PaletteOptions["primary"];
    errorAlternate: PaletteOptions["error"];
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    poppins_h2_700_30: React.CSSProperties;
    inter_p_400_16: React.CSSProperties;
    inter_p_500_14: React.CSSProperties;
    poppins_p_600_14: React.CSSProperties;
    inter60016: React.CSSProperties;
    poppins_h5_600_18: React.CSSProperties;
    poppins_h6_600_16: React.CSSProperties;
    inter_p_600_14: React.CSSProperties;
    poppins_h3_600_24: React.CSSProperties;
    inter_500_10: React.CSSProperties;
    poppins_h4_600_20: React.CSSProperties;
    inter_p_500_10: React.CSSProperties;
    inter_p_600_16: React.CSSProperties;
    poppins_700_22: React.CSSProperties;
    inter_400_14: React.CSSProperties;
    poppins_600_12: React.CSSProperties;
    inter_400_12: React.CSSProperties;
    inter_400_10: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    poppins_h2_700_30?: React.CSSProperties;
    inter_p_400_16: React.CSSProperties;
    inter_p_500_14: React.CSSProperties;
    poppins_p_600_14: React.CSSProperties;
    inter60016: React.CSSProperties;
    poppins_h5_600_18: React.CSSProperties;
    poppins_h6_600_16: React.CSSProperties;
    inter_p_600_14: React.CSSProperties;
    poppins_h3_600_24: React.CSSProperties;
    inter_500_12: React.CSSProperties;
    inter_500_10: React.CSSProperties;
    poppins_h4_600_20: React.CSSProperties;
    inter_p_500_10: React.CSSProperties;
    inter_p_600_16: React.CSSProperties;
    poppins_700_22: React.CSSProperties;
    inter_400_14: React.CSSProperties;
    poppins_600_12: React.CSSProperties;
    inter_400_12: React.CSSProperties;
    inter_400_10: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    poppins_h2_700_30: true;
    inter_p_400_16: true;
    inter_p_500_14: true;
    poppins_p_600_14: true;
    inter60016: true;
    poppins_h5_600_18: true;
    poppins_h6_600_16: true;
    inter_p_600_14: true;
    poppins_h3_600_24: true;
    inter_500_12: true;
    inter_500_10: true;
    poppins_h4_600_20: true;
    inter_p_500_10: true;
    inter_p_600_16: true;
    poppins_700_22: true;
    inter_400_14: true;
    poppins_600_12: true;
    inter_400_12: true;
    inter_400_10: true;
  }
}

export const themeValues: ThemeOptions = {
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "#fff",
            color: "#010033",
          },
        },
      },
    },
  },
  palette: {
    mode: "light",
    successAlternate: {
      main: "#8FD491",
    },
    errorAlternate: {
      main: "#F05B3A",
    },
    primary: {
      main: "#3937A5",
    },
    text: {
      primary: "#010033",
    },
    ghost: {
      main: "#6E6E81",
      contrastText: "#fff",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    fontFamily: fontPoppins,
    subtitle1: {
      fontFamily: fontInter,
    },
    subtitle2: {
      fontFamily: fontInter,
    },
    h3: {
      fontWeight: 600,
      fontSize: 24,
      lineHeight: "36px",
    },
    h4: {
      fontWeight: 700,
    },
    h6: {
      fontWeight: 600,
    },
    poppins_h2_700_30: {
      fontFamily: fontPoppins,
      fontWeight: 700,
      fontSize: "30px",
      lineHeight: "45px",
      display: "block",
    },
    inter_p_400_16: {
      fontFamily: fontInter,
      fontWeight: 400,
      fontSize: "16px",
      lineHeight: "19.36px",
      display: "block",
    },
    inter_p_500_14: {
      fontFamily: fontInter,
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16.94px",
      display: "block",
    },
    inter_500_12: {
      color: "#6E6E81",
      fontFamily: fontInter,
      fontWeight: 500,
      fontSize: "12px",
      display: "block",
    },
    poppins_p_600_14: {
      fontFamily: fontPoppins,
      fontWeight: 600,
      fontSize: "14px",
      lineHeight: "21px",
      display: "block",
      textTransform: "capitalize",
    },
    inter60016: {
      fontWeight: 600,
      fontFamily: fontInter,
      fontSize: 16,
    },
    poppins_h5_600_18: {
      fontFamily: fontPoppins,
      fontWeight: 600,
      fontSize: 18,
      lineHeight: "27px",
      display: "block",
    },
    poppins_h6_600_16: {
      fontFamily: fontPoppins,
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "24px",
      display: "block",
    },
    inter_p_600_14: {
      fontWeight: 600,
      fontFamily: fontInter,
      fontSize: 14,
    },
    inter_500_10: {
      fontWeight: 500,
      fontFamily: fontInter,
      fontSize: 10,
    },
    poppins_h3_600_24: {
      fontFamily: fontPoppins,
      fontWeight: 600,
      fontSize: 24,
      lineHeight: "36px",
      display: "block",
    },
    poppins_h4_600_20: {
      fontFamily: fontPoppins,
      fontWeight: 600,
      fontSize: 20,
      lineHeight: "30px",
      display: "block",
    },
    inter_p_500_10: {
      fontFamily: fontInter,
      fontSize: 14,
      lineHeight: "12.1px",
    },
    inter_p_600_16: {
      fontWeight: 600,
      fontFamily: fontInter,
      fontSize: 16,
      lineHeight: "19.36px",
    },
    poppins_700_22: {
      fontWeight: 700,
      fontSize: "22px",
      fontFamily: fontPoppins,
    },
    poppins_600_12: {
      fontWeight: 600,
      fontSize: "12px",
      fontFamily: fontPoppins,
    },
    inter_400_14: {
      fontWeight: 400,
      fontFamily: fontInter,
      lineHeight: "16.94px",
      fontSize: "14px",
    },
    inter_400_12: {
      fontWeight: 400,
      fontFamily: fontInter,
      lineHeight: "normal",
      fontSize: "12px",
    },
    inter_400_10: {
      fontWeight: 400,
      fontFamily: fontInter,
      lineHeight: "normal",
      fontSize: "10px",
    },
  },
  custom: {
    primaryDarkColor: "#010033",
    primaryGreyColor: "#6E6E81",
    buttonWidth: "200px",
    designBgColor: "#F9F9F8",
    bgImageColor: "#FAFAFA",
    placeholderColor: "#6E6E81",
    activeColor: "#7F7CFF",
    errorColor: "red",
    KPIMoleculeBorderColor: "#C7C7C7",
    KPIMoleculeBackgroundColor: "#fff",
    ConnectorsListPageBgColor: "#FAFAFA",
    CustomCheckboxColor: "#f5f8fa",
    CustomCheckboxBgColor: "#3937A5",
    CustomCheckboxBoxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    blackColor: "#000000",
    whiteColor: "#FFFFFF",
    dropdownSecondaryColor: "#6E6E81",
    dropdownPrimaryColor: "#010033",
    filterBorderColor: "#C7C7C7",
    filterBackgroundColor: "#FFFFFF",
    progressBarColor: "#5E5D80",
    menuDimColor: "#5E5D80",
    mapFieldStatusBg: "#F6F6F6",
    chipGroupBackground: "#F8F8F8",
    borderColor: "#C7C7C7",
    disabledColor: "#f2f2f2",
    menuDividerColor: "#E1E1E1",
    progressBgGhostColor: "#E1E1E1",
    profileBackgroundColor: "#F3F3F3",
    resetMsgTextBgColor: "#FFF0DB",
    successColor: "#00D100",
    popoverLinkColor: "#3533B9",
    opaqueWhite: "rgba(255, 255, 255, 0.85)",
    accentBgColor: "#C6B5F2",
    grayBgColor: "#A3A3A3",
    errorTextColor: "red",
    yellowButtonColor: "#FFCC84",
    toolTipBg: "#ebebff",
    secondaryYellowColor: "#FFE3B8",
    primaryGreen: "#8FD491B2",
    secondaryGreen: "#eef9ef",
  },
  CustomChip: {
    closeIconColor: "#FFCC84",
    backgroundColor: "#5D5D80",
    fontColor: "#ffffff",
    primaryBlue: "#3937A5",
    backgroundLightGray: "#E1E1E1",
  },
  kpiMoleculeColors: {
    backgroundColor: "#fff",
    borderColor: "#6E6E81",
    leftDescriptionItemBgColor: "#FFEEEA",
    rightDescriptionItemBgColor: "#FFF2DF",
    descriptionItemHeader: "#010033",
  },
  dataTableColors: {
    bgColor: "#ffffff",
    headerBgColor: "#FFF8ED",
    borderColor: "#6E6E81",
    headerDarkBgColor: "#FFEBCB",
  },
  stories: {
    kpiConnectorColor: "#584FBD",
  },
  sidebar: {
    borderColor: "#DCDCDC",
    menuItemBackgroundColor: "#E8E8FF",
  },
  customSwitch: {
    inactiveColor: "#D5D6D9",
  },
  budgetingRelatedColors: {
    expenseBgColor: "#F2EDFF",
    revenueBGColor: "#FFE1DA",
    staffingBGColor: "#FFEFD9",
  },
  impactReportingColors: {
    headerColor: "#7F7CFF",
    shareTextColor: "#fff",
    zoomBgColor: "#262641",
    activeColor: "#7F7CFF",
    menuBorderColor: "#E9E9E9",
    bgColor: "#F9F9F9",
    guidelineColor: "#FF8E75",
    toggleBtnBgColor: "#F4F4F4",
  },
  kpiSmartReportColors: {
    popoverBackgroundColor: "#FFFBF5",
    popoverTitleBackgroundColor: "#FFC269",
  },
  kpiBuilderColors: {
    chartTypeBackgroundColor: "#E1E0FF",
  },
};
export const theme = createTheme(themeValues);

const darkPalette: PaletteOptions = {
  ...themeValues.palette,
  mode: "dark",
  ghost: {
    main: "#fff",
    contrastText: "#fff",
  },
  primary: {
    main: "#fff",
    dark: "#fff",
  },
  successAlternate: themeValues.palette?.successAlternate,
  errorAlternate: themeValues.palette?.errorAlternate,
};

export const darkTheme = createTheme({
  ...themeValues,
  palette: darkPalette,
});

/* export const returnChartColors = (length: number) => {
  return chartColors.slice(0, Math.max(length, 2) || 999);
}; */

export const returnChartColors = (length: number) => {
  switch (length) {
    case 1:
      return ["#584FBD"];
    case 2:
      return ["#584FBD", "#FF8E75"];
    case 3:
      return ["#584FBD", "#FFCC84", "#FF8E75"];
    case 4:
      return ["#584FBD", "#C6B5F2", "#FFCC84", "#FF8E75"];
    case 5:
      return ["#584FBD", "#9670FF", "#C6B5F2", "#FFCC84", "#FF8E75"];
    case 6:
      return ["#584FBD", "#9670FF", "#C6B5F2", "#FFCC84", "#FF8E75", "#FF8E75"];
    default:
      return [
        "#584FBD",
        "#9670FF",
        "#C6B5F2",
        "#FFCC84",
        "#FFAF75",
        "#FF8E75",
        "#FF6A6A",
        "#D45252",
        "#62A2DD",
        "#62D6DD",
        "#59A99B",
        "#5979A9",
        "#4F57A0",
      ];
  }
};
export const chartColors = [
  "#4F57A0",
  "#5979A9",
  "#59A99B",
  "#62D6DD",
  "#62A2DD",
  "#D45252",
  "#FF6A6A",
  "#FF8E75",
  "#FFAF75",
  "#FFCC84",
  "#C6B5F2",
  "#9670FF",
  "#584FBD",
];

export const designPalette = [...chartColors, "#FFFFFF", "#000000"];

export const gradientPalette = ["#ffffff", "#333333"];

export const gradientStylePalette = [
  "linear-gradient(to bottom ",
  "linear-gradient(to top ",
  "linear-gradient(to right ",
  "linear-gradient(to left ",
  "radial-gradient(circle ",
  "linear-gradient(to top left ",
  "linear-gradient(to top right ",
  "linear-gradient(to bottom left ",
  "linear-gradient(to bottom right ",
];

export const areaChartsColor = {
  color: "#584FBD",
  lineColor: "#0071CE",
};

export const tooltipColors = {
  bgColor: "#5E5D80",
  borderColor: "#5E5D80",
  dataFormatColor: "#ffffff",
};

export const chartLabelsStyle =
  "color: #010033; font-size: 12px; font-family: 'Inter', sans-serif; font-weight: 500";

export const axisLabelStyle = {
  color: theme.custom.primaryDarkColor,
  fontWeight: 500,
  fontFamily: fontInter,
};
export const CustomTheme = typeof theme;
