import { Divider, List, ListItem } from "@mui/material";
import {
  ConnectorMappingConfig
} from "../../services/user-mapping-service";
import QuickbooksConditionalMappingsListItem from "../molecules/QuickbooksConditionalMappingsListItem";

interface Props {
  configItems: ConnectorMappingConfig[];
}

const QuickbooksRestrictionMappings = (props: Props) => {
  const totLen = props.configItems.filter((e) => e.step === 4).length - 1;
  const renderList = () => {
    return (
      <List sx={{ border: 1, borderRadius: "6px" }}>
        {props.configItems
          .filter((e) => e.step === 4)
          .map((item, idx) => (
            <div key={item.id}>
              <ListItem>
                <QuickbooksConditionalMappingsListItem UMData={item} />
              </ListItem>
              <Divider
                variant="middle"
                sx={{ display: idx === totLen ? "none" : "block" }}
              />
            </div>
          ))}
      </List>
    );
  };
  return <>{renderList()}</>;
};

export default QuickbooksRestrictionMappings;
