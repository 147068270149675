import {ALERTS_ENDPOINT} from "../endpoints/alerts-endpoints";
import axios from "axios";

export const getBudgetsAlertsCount = () => {
  let url = `${ALERTS_ENDPOINT}/budgets/count`;
  return axios.get(url);
};

export const getBudgetsAlertsList = () => {
  let url = `${ALERTS_ENDPOINT}/budgets`;
  return axios.get(url);
};