import { Box, Grid, Popover, Stack, Typography, lighten } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import CancelCircle from "../../assets/cancel_circle.png";
import { theme } from "../../theme";
import AIBox from "./AIBox";
import MagicWandAnimation from "../../assets/magic_wand_animation.gif";
import MarkdownRenderer from "./MarkdownRenderer";

type Props = {
  slug: string;
  closePopup: Function;
  id: string;
  smartStatementText: string;
  loadingSmartStatementText: string;
  loadingSmartStatement: boolean;
  open: boolean;
  anchorEl: any;
};

const KPISmartStatementPopover = (props: Props) => {
  const handleClose = () => {
    props.closePopup();
  };

  return (
    <React.Fragment>
      <Popover
        sx={{ zIndex: "10000 !important" }}
        id={props.id}
        open={props.open}
        anchorEl={props.anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      >
        <AIBox>
          <Box
            p={2}
            sx={{
              backgroundColor: lighten(theme.custom.yellowButtonColor, 0.8),
            }}
          >
            <Grid
              container
              alignItems={"center"}
              justifyContent={"space-between"}
              px={2}
            >
              <Stack
                direction="row"
                alignItems={"center"}
                justifyItems={"start"}
              >
                <Typography
                  noWrap
                  sx={{
                    mr: 1,
                  }}
                  variant="poppins_h5_600_18"
                  color={theme.custom.primaryDarkColor}
                >
                  AI Analysis of KPI
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems="center" justifyItems={"end"}>
                <img
                  onClick={handleClose}
                  style={{
                    marginTop: 2,
                    cursor: "pointer",
                  }}
                  height="24px"
                  width="24px"
                  src={CancelCircle}
                  alt="shine"
                />
              </Stack>
            </Grid>
          </Box>
          <Box
            p={4}
            marginBottom={2}
            sx={{
              // backgroundColor:
              //   theme.kpiSmartReportColors.popoverBackgroundColor,
              maxHeight: 620,
              width: 500,
              height: 420,
              overflow: "auto",
            }}
            display="flex"
            justifyContent="center"
          >
            {!props.loadingSmartStatement ? (
              <Typography
                sx={{ color: theme.custom.primaryDarkColor }}
                variant="inter_400_14"
              >
                <MarkdownRenderer
                  extraSx={{ lineHeight: "1.3em" }}
                  markdown={props.smartStatementText}
                />
              </Typography>
            ) : (
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={1}
                // sx={{ width: 145 }}
              >
                <div>
                  <img
                    height="30px"
                    width="30px"
                    src={MagicWandAnimation}
                    alt="magic_stick"
                  />
                </div>
                <div>
                  <Typography
                    sx={{
                      mr: 1,
                    }}
                    style={{ wordWrap: "break-word", textAlign: "center" }}
                    variant="inter_p_400_16"
                    color={theme.custom.primaryDarkColor}
                  >
                    {props.loadingSmartStatementText}
                  </Typography>
                </div>
              </Stack>
            )}
          </Box>
        </AIBox>
      </Popover>
    </React.Fragment>
  );
};

export default memo(KPISmartStatementPopover);
