import { createSlice } from "@reduxjs/toolkit";

const dateRangeStateChangeHandler = createSlice({
  name: "date-range-setter",
  initialState: {
    start: "",
    end: "",
    time: 0,
  },
  reducers: {
    setDate(state, action) {
      state.start = action?.payload?.start;
      state.end = action?.payload?.end;
      state.time = new Date().getSeconds();
    },
  },
});

export const { setDate } = dateRangeStateChangeHandler.actions;
export default dateRangeStateChangeHandler.reducer;
