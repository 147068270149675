import {
  AppBar,
  Button,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../assets/back_button.png";
import CreateImpactReportAIForm from "../components/molecules/Editor/CreateImpactReportAIForm";
import CreateImpactReportAIPreview from "../components/molecules/Editor/CreateImpactReportAIPreview";
import { theme } from "../theme";

function CreateImpactReportAIPage() {
  const navigate = useNavigate();

  const onBackButtonClick = () => {
    navigate("/create-impact-report");
  };
  const [selectedTemplate, setSelectedTemplate] = useState<any | null>(null);
  const [audience, setAudience] = useState<string>("");
  const [purpose, setPurpose] = useState<string>("");
  const [query, setQuery] = useState<string>("");

  const handleTemplateClick = (item: any) => {
    setSelectedTemplate(item);
  };

  const nextPageTrigger = () => {
    setSelectedTemplate(null);
    setCurrentStep(2);
  };

  const [currentStep, setCurrentStep] = useState(1);
  const [subTitle, setSubTitle] = useState("");

  useEffect(() => {
    if (currentStep === 1) {
      setSubTitle("Enter the details");
    } else {
      setSubTitle("Choose the report that you prefer");
    }
  }, [currentStep]);

  return (
    <Grid
      container
      sx={{
        background: theme.impactReportingColors.bgColor,
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: theme.impactReportingColors.headerColor,
        }}
      >
        <Toolbar>
          <Grid
            container
            alignItems={"center"}
            px={2}
            sx={{ position: "relative" }}
          >
            <Grid item sm={5}>
              <img
                onClick={onBackButtonClick}
                src={BackButton}
                alt="Back"
                style={{
                  maxHeight: "20px",
                  cursor: "pointer",
                  position: "relative",
                }}
              ></img>
            </Grid>
            <Grid item sm={6}>
              <Typography variant="inter_p_600_16">
                Create Report with AI
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <Grid container justifyContent={"space-between"} mt={5} px={4}>
        <Grid item xs={2}></Grid>
        <Typography variant="poppins_h3_600_24">{subTitle}</Typography>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            sx={{ display: currentStep === 2 ? "block" : "none" }}
            onClick={() => setCurrentStep(1)}
          >
            <Typography variant="poppins_600_12">Edit prompt</Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        sx={{ justifyContent: "center", height: "100vh" }}
        alignContent={"start"}
      >
        <Grid item xs={3} mt={4}>
          <Stepper activeStep={currentStep - 1}>
            {[1, 2].map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: {
                optional?: React.ReactNode;
              } = {};
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}></StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Grid>
        <Grid container sx={{ justifyContent: "center", my: 4 }}>
          {currentStep === 1 ? (
            <CreateImpactReportAIForm
              selectedTemplate={selectedTemplate}
              nextPageTrigger={nextPageTrigger}
              audience={audience}
              setAudience={setAudience}
              purpose={purpose}
              setPurpose={setPurpose}
              query={query}
              setQuery={setQuery}
            />
          ) : (
            <CreateImpactReportAIPreview
              audience={audience}
              purpose={purpose}
              userQuery={query}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default memo(CreateImpactReportAIPage);
