import {
  Box,
  Button,
  Grid,
  Tab,
  Tabs,
  Typography,
  Tooltip,
  Skeleton,
} from "@mui/material";
import React, { useState, useEffect, useRef, memo } from "react";
import { theme } from "../../theme";
import { EmployeeObject } from "../../Types/EmployeeObject";
import AddBudgetDataTableMolecule from "../molecules/AddBudgetDataTableMolecule";
import StaffinigTableComplex from "./StaffingTableComplex";
import AddCustomBudgetType from "../molecules/AddCustomBudgetType";
import { CustomBudgetType } from "../../Types/CustomBudgetType";
import {
  deleteCustomBudgetType,
  saveBudgetType,
} from "../../services/budget-service";
import { last, unionBy } from "lodash";
import DeleteIcon from "../../assets/deleteIcon.png";
import PencilIcon from "../../assets/pencil.png";
import { sortObjects } from "../../utils/data-utils";
import DeleteCustomBudgetTypeModal from "../atoms/DeleteCustomBudgetTypeModal";
interface Props {
  datacarts?: any;
  employeeList: EmployeeObject[];
  customBudgetTypesList: CustomBudgetType[];
  updateCustomBudgetTypesList: Function;
  budgetDetails: any;
  is_editable: boolean;
  budget_uid: string;
  deleteCustomBudgetType: Function;
  loading: boolean;
  refreshBudget: Function;
  tabToBeSelected: number;
}

const BudgetTableComplex: React.FC<Props> = (data) => {
  let budgetDetails = data.budgetDetails;
  let loading = data.loading;
  let is_editable = data.is_editable;
  let budget_uid = data.budget_uid;
  let tabToBeSelected = data.tabToBeSelected;
  let updateCustomBudgetTypesList = data.updateCustomBudgetTypesList;
  let allCustomBudgetTypes = data.customBudgetTypesList;
  let datacarts = data.datacarts;
  let onDeleteCustomBudgetType = data.deleteCustomBudgetType;

  const [value, setValue] = useState(0);
  const [showTable, setShowTable] = useState<any>({});
  const [editBudgetType, setEditBudgetType] = useState<any>({});
  const [latestPosition, setLatestPosition] = useState(0);
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState(false);
  const [tabToBeDeleted, setTabToBeDeleted] = useState({
    slug: "",
    label: "",
    index: 0,
  });

  useEffect(() => {
    setValue(tabToBeSelected);
  }, [tabToBeSelected]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue !== undefined) {
      setValue(newValue);
    }
    let _tables: any = {};
    Object.keys(budgetDetails).forEach((ele: string) => {
      _tables[ele] = false;
    });
    setEditBudgetType(_tables);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`budget-tabpanel-${index}`}
        aria-labelledby={`budget-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  };

  const a11yProps = (index: number) => {
    return {
      id: `budget-tab-${index}`,
      "aria-controls": `budget-tabpanel-${index}`,
    };
  };

  const saveType = (
    value: CustomBudgetType,
    tab: string,
    index: number,
    edit: boolean = false
  ) => {
    if (value && value.label && !value.slug) {
      saveBudgetType(value.label).then((res) => {
        updateCustomBudgetTypesList(res.data.data, tab, index, edit);
        setValue(budgetDetails.length);
      });
    } else {
      updateCustomBudgetTypesList(value, tab, index, edit);
      if (edit) {
        setValue(index);
      }
    }
  };

  useEffect(() => {
    let _tables: any = { ...showTable };
    if (budgetDetails && budgetDetails.length) {
      budgetDetails.forEach((ele: any) => {
        _tables[ele["slug"]] = false;
      });
      setShowTable(_tables);
      setEditBudgetType(_tables);

      let sortedData: any[] = sortObjects(budgetDetails, "position");
      if (sortedData && sortedData.length) {
        setLatestPosition(last(sortedData)["position"]);
      }
    }
  }, [budgetDetails]);

  const onEditBudgetType = (budget_type: string) => {
    if (!is_editable) {
      return;
    }
    let _editBudgetType = { ...editBudgetType };
    _editBudgetType[budget_type] = true;
    setEditBudgetType(_editBudgetType);
  };

  const accountsFilterHelper = (accountType: string) => {
    if (datacarts && Object.keys(datacarts).length) {
      switch (accountType) {
        case "expense":
          return datacarts["expense_account"];
        case "revenue":
          return datacarts["revenue_account"];
        case "staffing":
          return datacarts["staffing_account"];
        default:
          return unionBy(
            [
              ...datacarts["expense_account"],
              ...datacarts["revenue_account"],
              ...datacarts["staffing_account"],
            ],
            "id"
          );
      }
    } else {
      return [];
    }
  };

  const onDeleteBudgetType = (tab: any) => {
    if (!is_editable) {
      return;
    }
    setShowDeleteConfirmPopup(true);
    setTabToBeDeleted(tab);
  };

  const onCancelCustomBudgetTypeChange = (budget_type: string) => {
    let _editType = { ...editBudgetType };
    _editType[budget_type] = false;
    setEditBudgetType(_editType);
  };

  const returnTab = (slug: string, detail: any, index: number) => {
    if (
      editBudgetType[slug] &&
      !["revenue", "staffing", "expense"].includes(slug)
    ) {
      return (
        <AddCustomBudgetType
          edit={true}
          tab={slug}
          allSavedBudgetTypes={allCustomBudgetTypes}
          saveBudgetType={saveType}
          cancelChange={onCancelCustomBudgetTypeChange}
          currentValue={{ label: detail["label"], slug: "" }}
          index={index}
          is_editable={is_editable}
        />
      );
    } else if (
      !editBudgetType[slug] &&
      ["revenue", "staffing", "expense"].includes(slug)
    ) {
      return (
        <Box sx={{ display: "flex" }}>
          <Box sx={{ marginRight: "10px" }}>
            <Typography variant="inter_p_600_16">{detail.label}</Typography>
          </Box>
          <Box>
            {!["revenue", "staffing", "expense"].includes(slug) && (
              <Grid container>
                <Grid item mt={0.5} mr={1}>
                  <img
                    onClick={() => onEditBudgetType(slug)}
                    height={15}
                    width={15}
                    src={PencilIcon}
                    alt="Edit"
                  />
                </Grid>
                <Grid item mt={0.5}>
                  <img
                    onClick={() => {
                      onDeleteBudgetType({
                        slug: slug,
                        label: detail.label,
                        index: index,
                      });
                    }}
                    src={DeleteIcon}
                    height={15}
                    width={15}
                    alt="Delete"
                  />
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      );
    } else {
      return (
        <Box sx={{ display: "flex" }}>
          <Box sx={{ marginRight: "10px" }}>
            <Typography variant="inter_p_600_16">{detail.label}</Typography>
          </Box>
          <Box>
            {!["revenue", "staffing", "expense"].includes(slug) && (
              <Grid container>
                <Grid item mt={0.5} mr={1}>
                  <Tooltip
                    title={
                      is_editable
                        ? `Edit budget type`
                        : "You don't have permission to perform this action"
                    }
                  >
                    <img
                      aria-disabled={!is_editable}
                      onClick={() => onEditBudgetType(slug)}
                      height={15}
                      width={15}
                      src={PencilIcon}
                      alt="Edit"
                    />
                  </Tooltip>
                </Grid>
                <Grid item mt={0.5}>
                  <Tooltip
                    title={
                      is_editable
                        ? `Delete budget type`
                        : "You don't have permission to perform this action"
                    }
                  >
                    <img
                      aria-disabled={!is_editable}
                      onClick={() => {
                        onDeleteBudgetType({
                          slug: slug,
                          label: detail.label,
                          index: index,
                        });
                      }}
                      src={DeleteIcon}
                      height={15}
                      width={15}
                      alt="Delete"
                    />
                  </Tooltip>
                </Grid>
              </Grid>
            )}
          </Box>
        </Box>
      );
    }
  };

  const closeModal = () => {
    setShowDeleteConfirmPopup(false);
  };

  const deleteCustomType = (tabToBeDeleted: CustomBudgetType) => {
    if (tabToBeDeleted["index"] && tabToBeDeleted["index"] >= 0) {
      if (budgetDetails[tabToBeDeleted["index"]]["items"].length) {
        deleteCustomBudgetType(tabToBeDeleted.slug, budget_uid).then((res) => {
          if (res.status === 200) {
            onDeleteCustomBudgetType(tabToBeDeleted.index);
            setValue(tabToBeDeleted.index ? tabToBeDeleted.index - 1 : 0);
          }
        });
      } else {
        onDeleteCustomBudgetType(tabToBeDeleted.index);
        setValue(tabToBeDeleted.index ? tabToBeDeleted.index - 1 : 0);
      }
    }
  };

  return (
    <React.Fragment>
      <Box>
        <Box
          sx={{ borderBottom: 1, borderColor: "divider", position: "relative" }}
        >
          <Tabs
            sx={{
              "& .MuiTabs-flexContainer": {
                alignItems: "center",
              },
              width: "calc(100% - 60px)",
            }}
            variant="scrollable"
            value={value}
            onChange={handleChange}
            aria-label="Budget Tabs"
          >
            {budgetDetails &&
              budgetDetails.length &&
              budgetDetails.map((detailObj: any, index: number) => {
                return (
                  <Tab
                    label={returnTab(detailObj["slug"], detailObj, index)}
                    {...a11yProps(index)}
                  />
                );
              })}
          </Tabs>
          <Box
            style={{ position: "absolute", right: 0, top: 5, width: "60px" }}
          >
            <AddCustomBudgetType
              allSavedBudgetTypes={allCustomBudgetTypes}
              saveBudgetType={saveType}
              is_editable={is_editable}
            />
          </Box>
        </Box>

        {budgetDetails &&
          budgetDetails.map((detail: any, index: number) => {
            return (
              <TabPanel value={value} index={index}>
                <Grid
                  container
                  className={`budget_${detail["slug"] || ""}_list`}
                >
                  {detail["slug"] === "staffing" && (
                    <Box sx={{width: "100%"}}>
                      <StaffinigTableComplex
                        loading={loading}
                        index={index}
                        staffing_details={detail["staffing_items"]}
                        budget_uid={budget_uid}
                        employeeList={data.employeeList}
                        getLatest={data.refreshBudget}
                        is_editable={is_editable}
                      />

                      <Box my={2}>
                        <Typography variant="poppins_h5_600_18">
                          Staffing Expenses
                        </Typography>
                      </Box>
                    </Box>
                  )}

                  <Grid item sm={12}>
                    {detail.items.length === 0 && !showTable[detail["slug"]] && (
                      <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          background: theme.custom.bgImageColor,
                          minHeight: "250px",
                          borderRadius: 3,
                        }}
                      >
                        <Grid item>
                          <Tooltip
                            title={
                              is_editable
                                ? `Add ${detail["label"]}`
                                : "You don't have permission to perform this action"
                            }
                          >
                            <span>
                              <Button
                                disabled={!is_editable}
                                onClick={() => {
                                  let _table: any = {
                                    ...showTable,
                                  };
                                  _table[detail["slug"]] = true;
                                  setShowTable(_table);
                                }}
                                variant="outlined"
                                className="budget_revenue_add_cta"
                              >
                                Add {detail["label"]}
                              </Button>
                            </span>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    )}
                    {(detail.items.length > 0 || showTable[detail["slug"]]) && (
                      <AddBudgetDataTableMolecule
                        index={index}
                        loading={loading}
                        accounts={accountsFilterHelper(detail["slug"])}
                        tableData={detail}
                        latestPosition={latestPosition}
                        budget_uid={budget_uid}
                        type={detail["slug"]}
                        is_editable={is_editable}
                        getLatest={data.refreshBudget}
                      />
                    )}
                  </Grid>
                </Grid>
              </TabPanel>
            );
          })}
        {!budgetDetails ||
          (budgetDetails.length === 0 && (
            <Grid p={3} item md={12}>
              <Skeleton
                sx={{ bgcolor: "grey.200", borderRadius: 2 }}
                variant="rectangular"
                width="100%"
                height={500}
              />
            </Grid>
          ))}
        {showDeleteConfirmPopup && (
          <DeleteCustomBudgetTypeModal
            budgetType={tabToBeDeleted}
            openModal={showDeleteConfirmPopup}
            closeModal={closeModal}
            deleteCustomType={deleteCustomType}
          />
        )}
      </Box>
    </React.Fragment>
  );
};
export default memo(BudgetTableComplex);
