import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Fragment, memo, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { useDebounce, useEffectOnce } from "react-use";
import { ReportObject } from "../Types/ReportObject";
import SearchIcon from "../assets/search.png";
import DefaultA4Thumbnail from "../assets/thumbnailA4.png";
import DefaultPPTThumbnail from "../assets/thumbnailPPT.png";
import TemplateThumbnailComponent from "../components/atoms/TemplateThumbnailComponent";
import { Sidebar } from "../components/complexes/Sidebar";
import AuthorizationErrorHandler from "../components/molecules/Editor/AuthorizationErrorHandler";
import CreateNewImpactReport from "../components/molecules/Editor/CreateImpactReport";
import ImpactReportItem from "../components/molecules/Editor/ImpactReportItem";
import BlankReport from "../assets/blank_report.png";

import {
  createReport,
  defaultReportSize,
  getAllReports,
  getDefaultThumbnail,
  getTemplateLibrary,
} from "../services/impact-report-service";
import { getCompanyId } from "../services/userinfo-service";
import { showMessage } from "../store/error-handler-store";
import { useAppDispatch } from "../store/hook";
import { theme } from "../theme";

function ImpactReportPage() {
  const [allReports, setAllReports] = useState([] as ReportObject[]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  let pageRef = useRef({ page: 1 });
  const [hasMoreReports, sethasMoreReports] = useState(false);
  const [reportsLoaded, setReportsLoaded] = useState(false);
  const [creatable, setCreatable] = useState(false);
  const [authorized, setAuthorized] = useState(true);

  useEffectOnce(() => {
    fetchReports(1);
    document.title = "Impact Reports list - Pebble";
  });

  const fetchReports = (page: number, searchText: string = "") => {
    getAllReports(page, searchText).then((res) => {
      if (res.status === 403) {
        setAuthorized(false);
        return;
      }
      setReportsLoaded(true);
      if (res.status === 200) {
        setCreatable(res.data?.is_creatable || false);
        if (res?.data?.results) {
          if (page === 1) {
            setAllReports([...res?.data?.results]);
          } else {
            setAllReports([...allReports, ...res?.data?.results]);
          }
          if (res.data.next) {
            pageRef.current.page = page + 1;
            sethasMoreReports(true);
            setIsLoading(true);
          } else {
            sethasMoreReports(false);
            setIsLoading(false);
          }
        } else {
          sethasMoreReports(false);
        }
      } else {
        sethasMoreReports(false);
      }
    });
  };

  const loadMore = () => {
    if (hasMoreReports) {
      fetchReports(pageRef.current.page);
    }
  };

  const [debouncedValue, setDebouncedValue] = useState("");

  const viewTemplates = () => {
    navigate(`/create-impact-report`);
  };

  const editReport = (reportName: string, reportId: string) => {
    navigate(`/impact-report/${reportName}_${reportId}`);
  };

  const triggerReload = () => {
    fetchReports(1);
  };

  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    fetchReports(1, searchText);
  }, [debouncedValue]);

  useDebounce(
    () => {
      setDebouncedValue(searchText);
    },
    1000,
    [searchText]
  );

  const [templateList, setTemplateList] = useState<Array<Object>>([]);
  const [loading, setLoading] = useState(true);

  useEffectOnce(() => {
    const _filters = {
      search_query: "",
      audience: "",
      purpose: "",
      category: "",
    };
    getTemplateLibrary(1, _filters).then((resp) => {
      setLoading(false);
      if (resp && resp.data && resp.data.results) {
        setTemplateList(resp.data.results.slice(0, 5) || []);
      }
    });
  });

  const [selectedTemplate, setSelectedTemplate] = useState<any | null>(null);
  const [openPopup, setOpenPopup] = useState<boolean>(false);

  const handleTemplateClick = (item: any) => {
    setSelectedTemplate(item);
    setOpenPopup(true);
  };

  const onPopupClose = (popupOpen: boolean) => {
    setOpenPopup(popupOpen);
    setSelectedTemplate(null);
  };
  const dispatch = useAppDispatch();

  const setEditTemplate = (template: any) => {
    let reportData = {
      name: template?.attributes?.title,
      template_id: template?.id,
      is_template_edit_flow: true,
      company_uid: getCompanyId(),
      attributes: defaultReportSize,
    };
    createReport(reportData).then((res) => {
      if (res.status === 403) {
        dispatch(
          showMessage({
            message:
              "You don’t have access to edit this template. Please reach out to admin to get access",
            variant: "error",
          })
        );
        return;
      }
      if (res.status === 201) {
        navigate(`/impact-report/${res["data"]["name"]}_${res["data"]["uid"]}`);
      }
    });
  };

  return (
    <Fragment>
      <Stack direction="row">
        <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
          <Sidebar />
        </Box>
        <Box sx={{ width: "100%" }}>
          {authorized && (
            <InfiniteScroll
              dataLength={allReports.length}
              next={loadMore}
              hasMore={hasMoreReports}
              loader={
                <p style={{ textAlign: "center" }}>
                  {hasMoreReports && reportsLoaded && (
                    <Typography
                      sx={{ color: theme.custom.primaryGreyColor }}
                      variant="inter_p_600_16"
                    >
                      Loading...
                    </Typography>
                  )}
                </p>
              }
              scrollableTarget="scrollableDiv"
              endMessage={
                <p style={{ textAlign: "center" }}>
                  {reportsLoaded && (
                    <Typography
                      sx={{ color: theme.custom.primaryGreyColor }}
                      variant="inter_p_600_16"
                    >
                      No more reports to load.
                    </Typography>
                  )}
                </p>
              }
            >
              <Grid pt={6} px={8} container justifyContent={"space-between"}>
                <Grid item>
                  <Box>
                    <Typography variant="poppins_h3_600_24">Reports</Typography>
                  </Box>
                </Grid>
              </Grid>
              <Grid pt={4} px={8} container justifyContent={"space-between"}>
                <Grid item>
                  <Box mt={2}>
                    <Typography variant="poppins_h5_600_18">
                      Create a new report
                    </Typography>
                  </Box>
                </Grid>
                <Grid alignItems={"end"} container sx={{ width: "auto" }}>
                  <Grid textAlign={"right"} item>
                    <Box>
                      <Tooltip
                        placement="top-start"
                        title={
                          creatable
                            ? "View Templates"
                            : "You don't have permission for this action"
                        }
                      >
                        <span>
                          <Button
                            disabled={!creatable}
                            sx={{
                              height: "50px",
                              borderRadius: "6px",
                            }}
                            variant="outlined"
                            onClick={viewTemplates}
                          >
                            <Typography
                              variant="poppins_p_600_14"
                              sx={{ textTransform: "none" }}
                            >
                              View all templates
                            </Typography>
                          </Button>
                        </span>
                      </Tooltip>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container px={8} pt={4} justifyContent={"flex-start"}>
                <Grid item sx={{ cursor: "pointer", mr: 1 }} sm={1.8}>
                  <Box>
                    <Grid
                      onClick={() => setOpenPopup(true)}
                      container
                      sx={{
                        border: "1px solid #e1e1e1",
                        borderRadius: "6px",
                        overflow: "hidden",
                        height: 200,
                      }}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      <img src={BlankReport} alt="blank_report" width={"92"} />
                    </Grid>
                    <Box
                      sx={{
                        height: "42px",
                        mt: 1,
                      }}
                    >
                      <Typography
                        sx={{
                          wordWrap: "break-word",
                          color: theme.custom.primaryDarkColor,
                        }}
                        variant="inter_p_600_16"
                      >
                        Blank report
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {templateList.map((i: any) => (
                  <Grid sx={{ cursor: "pointer", mx: 1 }} key={i.id} sm={1.8}>
                    {/* <Grid container> */}
                    {/* <Grid> */}
                    <TemplateThumbnailComponent
                      onDeleteTemplate={() => {}}
                      templateId={i.id}
                      templateObj={i}
                      handleSelect={() => handleTemplateClick(i)}
                      template_icon={
                        i.thumbnail ||
                        i.attributes.attributes.thumbnail ||
                        getDefaultThumbnail(i.attributes.attributes.slug)
                      }
                      templateIsMutable={i.is_mutable}
                      onEditTemplate={() => setEditTemplate(i)}
                      pageLen={Object.keys(i.attributes.pages).length}
                      title={i.attributes.title}
                      style={{}}
                    />
                    {/* </Grid> */}
                    {/* </Grid> */}
                  </Grid>
                ))}
              </Grid>

              <Grid
                pt={6}
                px={8}
                container
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Grid item>
                  <Box>
                    <Typography variant="poppins_h5_600_18">
                      Recent reports
                    </Typography>
                  </Box>
                </Grid>
                <Grid>
                  <TextField
                    placeholder="Search reports"
                    variant="outlined"
                    size="small"
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "white",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "white",
                      },
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            style={{ marginLeft: 5 }}
                            height="24px"
                            width="24px"
                            src={SearchIcon}
                            alt="Search"
                          />
                        </InputAdornment>
                      ),
                    }}
                    fullWidth
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Grid pt={4} px={8} container spacing={2}>
                {allReports && allReports.length === 0 && !reportsLoaded && (
                  <Grid mt={3} container spacing={3}>
                    <Grid item sm={3}>
                      <Skeleton
                        sx={{ bgcolor: "grey.100", borderRadius: "6px" }}
                        variant="rectangular"
                        width={"100%"}
                        height={250}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Skeleton
                        sx={{ bgcolor: "grey.100", borderRadius: "6px" }}
                        variant="rectangular"
                        width={"100%"}
                        height={250}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Skeleton
                        sx={{ bgcolor: "grey.100", borderRadius: "6px" }}
                        variant="rectangular"
                        width={"100%"}
                        height={250}
                      />
                    </Grid>
                    <Grid item sm={3}>
                      <Skeleton
                        sx={{ bgcolor: "grey.100", borderRadius: "6px" }}
                        variant="rectangular"
                        width={"100%"}
                        height={250}
                      />
                    </Grid>
                  </Grid>
                )}

                {allReports &&
                  allReports.length > 0 &&
                  allReports.map((report) => {
                    return (
                      <Grid item sm={2.4}>
                        <Box
                          onClick={() => editReport(report.name, report.uid)}
                        >
                          <ImpactReportItem
                            triggerReload={triggerReload}
                            uid={report.uid}
                            report_created={report.created_at}
                            report_name={report.name}
                            report_owner={report.owner_name}
                            report_updated_at={report.updated_at}
                            // pageLen={Object.keys(report.attributes.pages).length}
                            report_updated_by={report.last_modified_by_name}
                            thumbnail={
                              report.thumbnail.length
                                ? report.thumbnail
                                : report.attributes?.thumbnail
                            }
                            is_editable={report.is_editable}
                          />
                        </Box>
                      </Grid>
                    );
                  })}
              </Grid>
            </InfiniteScroll>
          )}
          {!authorized && (
            <Box px={6}>
              {" "}
              <AuthorizationErrorHandler
                errorMessage="You don’t have access to see the list of Impact reports. Please reach out to
     admin to get access"
              />
            </Box>
          )}
        </Box>
      </Stack>
      <CreateNewImpactReport
        close={onPopupClose}
        open={openPopup}
        selectedTemplate={selectedTemplate}
      />
    </Fragment>
  );
}

export default memo(ImpactReportPage);
