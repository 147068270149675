import axios from "axios";
import { GET_SPOTLIGHT_SEARCH_POPULAR, GET_SPOTLIGHT_SEARCH_RESULTS } from "../endpoints/spotlight-endpoints";

export const splotlightSearch = (searchPayload: any) => {
  return axios.get<{ results: any[] }>(
    GET_SPOTLIGHT_SEARCH_RESULTS(searchPayload)
  );
};

export const splotlightPopularItems = () => {
  return axios.get<{ results: any[] }>(
    GET_SPOTLIGHT_SEARCH_POPULAR()
  );
};
