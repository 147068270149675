import {
  Dialog,
  DialogTitle,
  Skeleton,
  Typography,
  Grid,
  DialogContent,
  TextField,
  DialogActions, Button, InputAdornment
} from "@mui/material";
import React, {memo, useEffect, useState} from "react";
import {
  MemberDetailsAPIResponse,
} from "../../Types/UserManagement";
import {getUserProfile, saveUserProfile} from "../../services/user-management-service";
import {showMessage} from "../../store/error-handler-store";
import {useAppDispatch} from "../../store/hook";
import {fontInter, theme} from "../../theme";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";

interface Props {
  showPopup: boolean;
  setShowPopup?: React.Dispatch<React.SetStateAction<boolean>>;
  addUserCallback?: Function;
}

const EditMyProfileModal = (props: Props) => {
  const INIT_STATE = {
    first_name: "",
    last_name: "",
    email: "",
    role_slug: "",
  };

  const dispatch = useAppDispatch();
  const setShowModal = props.setShowPopup;
  const [open, setOpen] = useState(false);
  const [memberDetailsLoading, setMemberDetailsLoading] = useState(false);
  const [memberDetails, setMemberDetails] = useState<MemberDetailsAPIResponse>();
  const [userData, setUserData] = useState(INIT_STATE);

  useEffect(() => {
    if (memberDetails) {
      setUserData({
        first_name: memberDetails.first_name,
        last_name: memberDetails.last_name,
        email: memberDetails.email,
        role_slug: memberDetails.role_slug,
      });
    } else {
      setUserData({
        first_name: "",
        last_name: "",
        email: userData.email,
        role_slug: "",
      });
    }
  }, [memberDetails]);

  useEffect(() => {
    if (open) {
      setMemberDetailsLoading(true);
      getUserProfile()
        .then((resp) => {
          if (resp && resp.status === 200) {
            if (resp.data) {
              // patch role_slug
              resp.data.role_slug = resp.data.role.slug;
              setMemberDetails(resp.data);
            }
            setMemberDetailsLoading(false);
          }
        })
        .catch((err) => {
          dispatch(
            showMessage({
              message:
                "Sorry something went wrong. Please close this popup and try again later.",
            })
          );
        });
    }
  }, [open]);

  useEffect(() => {
    setOpen(props.showPopup);
  }, [props.showPopup]);

  const onChange = (key: string, event: any) => {
    setUserData((prev) => {
      return {
        ...prev,
        [key]: event.target.value,
      };
    });
  };

  const handleClose = (event?: any, reason?: any) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setOpen(false);
    setMemberDetails(undefined);
    if (setShowModal) {
      setShowModal(false);
    }
  };

  const handleSave = () => {
    saveUserProfile(userData).then((resp) => {
      if (resp && resp.status === 200) {
        if (props.addUserCallback) {
          props.addUserCallback();
        }
      }
    })
  }
  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={open}
      keepMounted={false}
      onClose={handleClose}
      sx={{
        zIndex: (th: any) => th.zIndex.drawer + 1000,
      }}
      PaperProps={{
        sx: {
          width: "760px",
          maxWidth: "760px",
          height: "670px",
          maxHeight: "670px",
          p: 1,
        },
      }}
    >
      <DialogTitle>
        <Grid container>
          <Grid textAlign={"center"} item xs={11}>
            <Typography
              sx={{
                color: `${theme.custom.primaryDarkColor}`,
                textAlign: "center",
              }}
              variant="poppins_h3_600_24"
            >
              Edit My Profile
            </Typography>
          </Grid>
          <Grid textAlign="right" item xs={1}>
            <CancelOutlinedIcon
              onClick={handleClose}
              sx={{
                color: `${theme.custom.primaryGreyColor}`,
                cursor: "pointer",
              }}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      {!memberDetailsLoading ? (
        <>
          <DialogContent sx={{height: "100%"}}>
            <Grid mt={6} container>
              <Grid
                sx={{height: "50px", display: "block"}}
                mb={3}
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  onChange={(e) => onChange("first_name", e)}
                  value={userData?.first_name}
                  inputProps={{
                    maxLength: 200,
                    style: {
                      fontFamily: fontInter,
                      fontSize: "14px",
                      color: theme.custom.primaryDarkColor,
                    },
                  }}
                  id="first_name"
                  label={
                    <Typography variant="inter_p_500_14" sx={{mt: 0.4}}>
                      First Name
                    </Typography>
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid
                sx={{height: "50px", display: "block"}}
                mb={3}
                item
                xs={12}
              >
                <TextField
                  fullWidth
                  inputProps={{
                    maxLength: 200,
                    style: {
                      fontFamily: fontInter,
                      fontSize: "14px",
                      color: theme.custom.primaryDarkColor,
                    },
                  }}
                  id="last_name"
                  onChange={(e) => onChange("last_name", e)}
                  value={userData?.last_name}
                  label={
                    <Typography variant="inter_p_500_14" sx={{mt: 0.4}}>
                      Last Name
                    </Typography>
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid mb={3} item xs={12}>
                <TextField
                  fullWidth
                  value={userData?.email}
                  disabled={true}
                  inputProps={{
                    maxLength: 200,
                    style: {
                      fontFamily: fontInter,
                      fontSize: "14px",
                      color: theme.custom.primaryDarkColor
                    },
                  }}
                  id="email"
                  label={
                    <Typography variant="inter_p_500_14" sx={{ mt: 0.4 }}>
                      Email
                    </Typography>
                  }
                  variant="outlined"
                />
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions>
            <Grid container justifyContent={"end"}>
              <Grid item>
                <Button
                  fullWidth
                  onClick={handleSave}
                  sx={{height: "50px"}}
                  variant="contained"
                  type="submit"
                >
                  <Typography variant="poppins_p_600_14">Save</Typography>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </>
      ) : (
        <Skeleton
          sx={{bgcolor: "grey.100", my: 1.5, mx: 2, borderRadius: 3}}
          variant="rectangular"
          animation="wave"
          height={250}
        />
      )}
    </Dialog>
  );
};

export default memo(EditMyProfileModal);
