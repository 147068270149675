import moment from "moment";

export const labelNumberFormatter = (
  value: number,
  formatKey?: string,
  compact?: boolean,
  totalDecimals: number = 0,  // default for tooltip and axis labels
  absolute?: boolean
) =>{
  return numberFormatter(
    value,
    formatKey,
    compact,
    totalDecimals,
    absolute
  );
}

export const numberFormatter = (
  value: number,
  formatKey?: string,
  compact?: boolean,
  totalDecimals?: number,
  absolute?: boolean
) => {
  switch (formatKey) {
    case "$":
      return numberFormatterWithoutDecimals(
        value,
        formatKey,
        compact,
        totalDecimals,
        absolute
      );
    default:
      return Math.round(value * 100) / 100 + (formatKey || "");
  }
};

export const numberFormatterWithoutDecimals = (
  value: number,
  formatKey?: string,
  compact?: boolean,
  totalDecimals?: number,
  absolute?: boolean,
) => {
  value = absolute ? Math.abs(value) : value
  switch (formatKey) {
    case "$":
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        currencyDisplay: "narrowSymbol",
        notation: compact ? "compact" : "standard",
        maximumFractionDigits: totalDecimals ?? (compact && value > 1000000) ? 2: 0,
      }).format(value);
    default:
      return Math.round(value * 100) / 100 + (formatKey || "");
  }
};

export const dateFormatter = (date: string, format: string = "DD MMM YYYY") => {
  return moment.utc(date).format(format);
};

export const dateFormatterWithTime = (date: string) => {
  let tempDate = new Date(date);
  return moment(tempDate, "YYY-MM-DD").format("DD MMM YYYY, HH:mm:ss A"); // 24H clock
};

export const returnAccountsString = (acct: any, key: string) => {
  let arr = acct.map((ele: any) => {
    return ele[key];
  });
  return arr.join(", ");
};

export const hideEmail = (target: string) => {
  var email = target;
  var hiddenEmail = "";
  for (let i = 0; i < email.length; i++) {
    if (i > 3 && i < email.indexOf("@")) {
      hiddenEmail += "*";
    } else {
      hiddenEmail += email[i];
    }
  }
  return hiddenEmail;
};
