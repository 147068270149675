import {
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import magicWand from "../../../assets/magic_wand.jpeg";
import { fontInter, theme } from "../../../theme";
import { getConversations } from "../../../services/ai";
import magicWandActive from "../../../assets/magic_wand_active.jpeg";

function TextPromptEditor(props: any) {
  const [active, setActive] = useState(false);
  const [generatedText, setGeneratedText] = useState("");
  const [prompt, setPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const abortControllerRef = useRef<AbortController | null>(null);

  const generateText = () => {
    setIsLoading(true);
    abortControllerRef.current = new AbortController();
    getConversations({ text: prompt }, abortControllerRef.current.signal).then((res) => {
      setGeneratedText(res.data.response);
      setIsLoading(false);
    });
  };

  const onPromptActive = () => {
    setActive(true);
    props.onActiveChange(true);
  };

  function getItem() {
    return {
      attributes: {
        font: "inter",
        list: false,
        size: 64,
        text: generatedText,
        align: "left",
        italic: false,
        underline: false,
        blockquote: false,
      },
      delta: {
        ops: [
          {
            insert: generatedText,
            attributes: {
              bold: false,
              font: "inter",
              size: "64px",
              color: "#000000",
              width: "150px",
            },
          },
        ],
      },
    };
  }
  const onDragEnd = (e: any) => {
    props.onDragEnd(e, getItem());
    cancelPrompt();
  };

  const addEle = (e: any) => {
    props.onDragEnd(e, getItem(), false);
  };

  const cancelPrompt = () => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    setActive(false);
    setPrompt("");
    setGeneratedText("");
    setIsLoading(false);
    props.onActiveChange(false);
  };

  useEffect(() => {
    let keyDownFunc = (e: any) => {
      if (e.code === "Escape" && active) {
        cancelPrompt();
      }
    };

    document.addEventListener("keydown", keyDownFunc);

    return () => {
      document.removeEventListener("keydown", keyDownFunc);
    };
  }, [active]);

  return (
    <>
      <Box mt={1} mb={2}>
        {!active ? (
          <Button
            onClick={onPromptActive}
            sx={{
              color: theme.custom.primaryGreyColor,
            }}
            style={{
              justifyContent: "flex-start",
              padding: "10px",
              borderColor: theme.custom.borderColor,
              height: "50px",
            }}
            variant="outlined"
            fullWidth
          >
            <img src={magicWand} height={"20px"} style={{marginRight: "10px"}} />
            Generate text with AI
          </Button>
        ) : (
          <Box style={{ position: "relative", height: "50px" }}>
            <Box
              style={{
                position: "absolute",
                width: "100%",
                borderRadius: "6px",
                border: `1px solid ${theme.custom.borderColor}`,
                background: theme.custom.whiteColor,
                zIndex: 2,
              }}
            >
              <Stack>
                <Box m={1}>
                  <TextareaAutosize
                    value={prompt}
                    onChange={(e: any) => setPrompt(e.target.value)}
                    autoFocus
                    style={{
                      width: "100%",
                      color: theme.custom.primaryGreyColor,
                      border: `0px`,
                      borderRadius: "0",
                      boxShadow: "none",
                      outline: "none",
                      resize: "none",
                      fontFamily: fontInter,
                      fontSize: "16px",
                    }}
                    minRows={3}
                    maxRows={10}
                  />
                </Box>
                <Divider
                  style={{ backgroundColor: theme.custom.menuDividerColor }}
                />
                <Box
                  style={{
                    borderRadius: "0 0 6px 6px",
                    backgroundColor: theme.custom.bgImageColor,
                  }}
                >
                  <Stack
                    m={1}
                    direction={"row"}
                    justifyContent={"space-between"}
                  >
                    <Button
                      variant="contained"
                      onClick={generateText}
                      disabled={isLoading || prompt === ""}
                    >
                      Generate
                    </Button>
                    <Button variant="text" onClick={cancelPrompt}>
                      Cancel
                    </Button>
                  </Stack>
                </Box>
                {(generatedText != "" || isLoading) && (
                  <>
                    <Divider
                      style={{
                        backgroundColor: theme.custom.menuDividerColor,
                      }}
                    />
                    <Box minHeight={"300px"} m={2}>
                      {isLoading ? (
                        <Stack
                          display={"flex"}
                          justifyContent={"center"}
                          alignItems={"center"}
                          minHeight={"300px"}
                        >
                          <img src={magicWandActive} height={"30px"} />
                          <Typography mt={1} variant="inter_p_500_10">
                            Generating text with Pebble AI
                          </Typography>
                        </Stack>
                      ) : (
                        <>
                          <Box
                            sx={{
                              "&::-webkit-scrollbar": {
                                width: "4px !important",
                              },
                            }}
                            style={{
                              border: `1px dashed #000`,
                              padding: "5px",
                              maxHeight: "400px",
                              overflowY: "auto",
                            }}
                            key={"custom"}
                            draggable={true}
                            onDrag={props.onDrag}
                            onDragStart={props.onDragStart}
                            onDragEnd={onDragEnd}
                          >
                            {generatedText}
                          </Box>
                          <Button onClick={addEle} variant="text">
                            Add this text
                          </Button>
                        </>
                      )}
                    </Box>
                  </>
                )}
              </Stack>
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}

export default memo(TextPromptEditor);
