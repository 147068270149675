import { Skeleton } from "@mui/material";
import React from "react";
import { useEffectOnce } from "react-use";
import { getResolvedPrompt } from "../../../services/impact-report-service";

type Props = {
  prompt: string;
  height: number;
  width: number;
  reportUID: string;
  updateElement: CallableFunction;
  type: string;
  extraData: any;
  attributes: any;
};

function GenAISkeleton({
  prompt,
  height,
  width,
  reportUID,
  type,
  updateElement,
  extraData,
  attributes,
}: Props) {
  
  useEffectOnce(() => {
    const data = {
      report_uid: reportUID,
      text_prompt: prompt,
      type: type,
    };
    getResolvedPrompt(data).then((resp) => {
      if (resp && resp.data) {
        let newDelta = { ...extraData.delta };
        newDelta.ops[0].insert = resp.data.resolved_value;
        updateElement({
          attributes: {
            ...attributes,
            text: resp.data.resolved_value,
            onLoadDelta: newDelta,
          },
          textPrompt: "",
          delta: newDelta,
        });
      }
    });
  });
  return (
    <Skeleton
      sx={{ my: 2, bgcolor: "grey.100" }}
      variant="rectangular"
      animation="wave"
      height={height}
      width={width}
    />
  );
}

export default GenAISkeleton;
