import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import authReducer from "./authentiction-store";
import userMappingReducer from "./user-mapping-store";
import errorHandlerReducer from "./error-handler-store";
import connectorReducter from "./connector-store";
import { stateSyncMiddleware } from "./redux-state-sync-middleware";
import filterOverlay from "./filter-overlay-store";
import filterStateHandler from "./filter-state-store";
import chipStateHandler from "./chip-state-store";
import chipResetHandler from "./chip-reset-store";
import dateRangeStateChangeHandler from "./date-range-setter-store";
import navigationHandler from "./navigation-store";
import selectedDesignElement from "./selected-design-element-store";
import kpiBuilderChartTypeHandler from "./kpi-builder-store";
import dataSourceHandler from "./datasource-store";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    errorHandler: errorHandlerReducer,
    connector: connectorReducter,
    filterOverlay: filterOverlay,
    selectedDesignElement: selectedDesignElement,
    userMapping: userMappingReducer,
    filterState: filterStateHandler,
    chipState: chipStateHandler,
    chipReset: chipResetHandler,
    setDateRange: dateRangeStateChangeHandler,
    menuData: navigationHandler,
    kpiBuilderChartType: kpiBuilderChartTypeHandler,
    data_source: dataSourceHandler,
  },
  middleware: [stateSyncMiddleware],
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
