import React, { useEffect } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  axisLabelStyle,
  chartLabelsStyle,
  fontInter,
  theme,
  tooltipColors,
} from "../../theme";
import {
  labelNumberFormatter,
  numberFormatter,
  numberFormatterWithoutDecimals,
} from "../../utils/dataFormatters";
import {
  returnCategoriesForAreaOrLineCharts,
  returnMultipleAreaOrLineChartData,
} from "../../utils/CommonKPIResponseFormatterHelper";
import { returnFileName } from "../../utils/file-name-helper";
import { ChartProps } from "../../Types/ChartProps";

const MultipleLineChart: React.FC<ChartProps> = (chartData) => {
  let data = chartData.chartData;
  let meta_data = chartData.chartMeta;
  let data_type = meta_data?.chart?.currencytype || "$";
  let date_range = chartData.dateRange;
  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const height = chartData.height;
  const width = chartData.width;
  const editorMode = chartData.editorMode;

  let showTable = chartData.showTable;
  let disableDataLabels = meta_data.chart["data-label-disabled"] || false;
  let removeDecimal = meta_data.chart["remove-decimal"] || false;

  useEffect(() => {
    if (chartComponentRef.current) {
      if (showTable) {
        chartComponentRef.current?.chart.viewData();
      } else {
        chartComponentRef.current.chart.hideData();
      }
    }
  }, [showTable]);

  const options = {
    chart: {
      type: "spline",
      zoomType: editorMode ? "" : "xy",
      events: {
        render: function () {
          chartData.kpiRendered(true);
          chartData.chartReference(chartComponentRef);
          if (chartData.customKPI === false) {
            chartComponentRef.current?.chart.reflow();
          }
        },
      },
    },
    accessibility: {
      description: "",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    xAxis: {
      categories: returnCategoriesForAreaOrLineCharts(data),
      title: {
        text: meta_data.chart?.["x-axis-text"] || "",
        style: axisLabelStyle,
      },
      labels: {
        formatter: function (this: any) {
          return `<span style="${chartLabelsStyle}">${this.value}</span>`;
        },
      },
    },
    yAxis: meta_data.chart["remove-multiple-y-axis"]
      ? {
          title: {
            text: meta_data.chart["y-axis-text"],
            style: axisLabelStyle,
          },
          labels: {
            formatter: function (this: any) {
              return `<span style="${chartLabelsStyle}">${labelNumberFormatter(
                this.value,
                data_type,
                true
              )}</span>`;
            },
          },
        }
      : [
          {
            // Primary yAxis
            labels: {
              formatter: function (this: any) {
                return `<span style="${chartLabelsStyle}">${labelNumberFormatter(
                  this.value,
                  meta_data.chart?.["primary-y-axis-data-format"] || "",
                  true
                )}</span>`;
              },
            },
            title: {
              text: meta_data.chart?.["primary-y-axis-text"] || "",
              style: axisLabelStyle,
            },
          },
          {
            // Secondary yAxis
            labels: {
              formatter: function (this: any) {
                return `<span style="${chartLabelsStyle}">${labelNumberFormatter(
                  this.value,
                  meta_data.chart?.["secondary-y-axis-data-format"] || "",
                  true
                )}</span>`;
              },
            },
            title: {
              text: meta_data.chart?.["secondary-y-axis-text"] || "",
              style: axisLabelStyle,
            },
            gridLineWidth: 1,
            opposite: true,
          },
        ],
    legend: {
      enabled: true,
    },
    tooltip: {
      backgroundColor: tooltipColors.bgColor,
      borderColor: tooltipColors.borderColor,
      color: tooltipColors.dataFormatColor,
      enabled: true,
      formatter: function (this: any) {
        if (this.series.userOptions.index === 0) {
          data_type = meta_data.chart["primary-y-axis-data-format"] || "";
        } else {
          data_type = meta_data.chart["secondary-y-axis-data-format"] || "";
        }

        return `<span style="color: ${theme.custom.whiteColor}">${
          this.key
        }: ${labelNumberFormatter(this.y, data_type)}</span>`;
      },
    },
    plotOptions: {
      series: {
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
      spline: {
        dataLabels: {
          inside: true,
          enabled: !disableDataLabels,
          formatter: function (this: any) {
            return removeDecimal
              ? numberFormatterWithoutDecimals(
                  this.y,
                  meta_data?.chart?.currencytype || meta_data?.["chart"]?.["primary-y-axis-data-format"] || " ",
                  true,
                )
              : numberFormatter(this.y, meta_data?.chart?.currencytype || meta_data?.["chart"]?.["primary-y-axis-data-format"] || " ", true);
          },
          style: {
            fontWeight: 500,
            fontFamily: fontInter,
            color: theme.custom.primaryDarkColor,
          },
        },
      },
      areaspline: {
        series: {
          fillOpacity: 0.9,
        },
        stacking: "normal",
        lineWidth: 0.5,
        marker: {
          enabled: false,
        },
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      filename: returnFileName(meta_data?.chart?.title || "", date_range),
    },
    series: returnMultipleAreaOrLineChartData(
      data,
      "multiple-line",
      meta_data.chart?.["remove-multiple-y-axis"]
    ),
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{
        className: "chart",
        style: {
          width: "100%",
          height: "calc(100% - 1px)",
        },
      }}
      options={
        editorMode
          ? {
              ...options,
              ...{ chart: { ...options.chart, height: height, width: width } },
            }
          : options
      }
      ref={chartComponentRef}
    ></HighchartsReact>
  );
};

export default MultipleLineChart;
