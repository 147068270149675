import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { io } from "socket.io-client";
import logo from "../assets/Logo.png";
import Banner from "../components/complexes/Banner";
import { Sidebar } from "../components/complexes/Sidebar";
import { environment } from "../environments/environment-dev";
import {
  getConnectorsList,
  getConnectorSyncStatus,
} from "../services/connector-service";
import { setMoveToMapping } from "../store/connector-store";
import { useAppDispatch } from "../store/hook";
import { theme } from "../theme";
import { ListConnectorAPIResponseModel } from "../Types/ListConnectorAPIResponseModel";
import { validateConnectorSlug } from "../utils/connector-utils";
import { generateStatusDisplay } from "../utils/ConnectorUIUtils";

interface Props {}

const MappingCompleted = (props: Props) => {
  const { connectorSlug } = useParams();
  const navigate = useNavigate();
  validateConnectorSlug(navigate, connectorSlug);
  const [syncStatus, setSyncStatus] = useState("");
  const [connectors, setConnectors] = useState<ListConnectorAPIResponseModel[]>(
    []
  );

  const dispatch = useAppDispatch();

  dispatch(setMoveToMapping({ connectorSlug: "" }));

  useEffect(() => {
    const socket = io(environment.socketUrl?.toString() || "");
    socket.emit("join", { room: environment.socketRoom });
    getConnectorsList().then((getConnectorsListResp) => {
      if (getConnectorsListResp && getConnectorsListResp.status === 200) {
        setConnectors(getConnectorsListResp.data);
      }
    });
    if (connectorSlug) {
      getConnectorSyncStatus(connectorSlug).then((resp) => {
        if (resp && resp.status === 200) {
          setSyncStatus(resp.data["status"]);
          socket.on("my_response", function (msg) {
            if (
              msg &&
              typeof msg.data === "object" &&
              msg.data.hasOwnProperty("connector_id")
            ) {
              try {
                if (
                  msg.data.connector_id === resp.data["external_reference_slug"]
                ) {
                  setSyncStatus(msg.data.event_status);
                }
              } catch (err) {
              }
            }
          });
        }
      });
    }
  }, []);

  return (
    <Stack direction="row">
      <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
        <Sidebar />
      </Box>
      <Grid container  sx={{ mt: 12, ml:2, pl: 6, width: `calc(100% - 100px - 16px - 1px)`  }}>
        <Grid
          sx={{
            border: `1px solid ${theme.custom.borderColor}`,
            borderRadius: "5px",
          }}
          item
          sm={10}
        >
          <Grid container direction="row" sx={{ px: 4, mt: 4 }}>
            <Grid item xs={7}>
              <Typography variant="h4">
                Please wait while we create your dashboard
              </Typography>
            </Grid>
            <Grid item xs={5}>
              <Grid container xs={12} justifyContent="right">
                <Grid item>
                  <Avatar
                    src={connectors
                      .find((c) => c.slug === connectorSlug)
                      ?.logo.toString()}
                    sx={{
                      p: 1,
                      width: 40,
                      height: 40,
                      border: `1px solid ${theme.custom.borderColor}`,
                      borderRadius: "5px",
                    }}
                  />
                </Grid>
                <Grid item sx={{ pt: 1 }}>
                  <span
                    style={{
                      display: "inline-block",
                      width: "100px",
                      borderBottom: `2px dashed ${theme.custom.borderColor}`,
                      lineHeight: 0,
                    }}
                  >
                    &nbsp;
                  </span>
                </Grid>
                <Grid item>
                  <Avatar
                    src={logo}
                    sx={{
                      p: 1,
                      marginRight: 1,
                      width: 40,
                      height: 40,
                      border: `1px solid ${theme.custom.borderColor}`,
                      borderRadius: "5px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Stack direction="row" sx={{ ml: 4, mt: 1 }} alignItems="center">
            <Grid item sx={{ mr: 2 }}>
              {generateStatusDisplay(syncStatus)}
            </Grid>
            <Typography
              variant="poppins_p_600_14"
              sx={{
                display: connectorSlug === "quickbooks" ? "block" : "none",
              }}
            >
              <Link
                href={`/connectors/${connectorSlug}/user-mapping`}
                sx={{ textDecoration: "none" }}
              >
                Edit Mapping
              </Link>
            </Typography>
          </Stack>
          <Typography
            variant="inter_500_10"
            sx={{
              ml: 4,
              mt: 2,
              color: `${theme.custom.primaryGreyColor}`,
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: "18px",
              lineHeight: "27px",
              display: syncStatus === "running" ? "block" : "none",
            }}
          >
            Completes in{" "}
            <b style={{ color: `${theme.custom.dropdownPrimaryColor}` }}>
              20 mins
            </b>
          </Typography>

          <Typography
            variant="inter_500_10"
            sx={{ ml: 4, mt: 6, display: "block" }}
          >
            Connect more tools
          </Typography>
          <Stack
            direction="row"
            sx={{ ml: 3, mt: 1, mb: 2 }}
            alignItems="center"
          >
            {connectors
              .filter((c) => c.slug !== connectorSlug)
              .map((c) => (
                <Box sx={{ ml: 1 }}>
                  <Link
                    href={`/connectors/list`}
                    sx={{ textDecoration: "none" }}
                  >
                    <Avatar
                      sx={{ width: 30, height: 30 }}
                      src={c.logo.toString()}
                    />
                  </Link>
                </Box>
              ))}
            <Grid mr={4.5} container justifyContent={"right"}>
              <Grid item>
                <Button sx={{ marginBottom: 2 }} variant="contained" href="/">
                  Go to KPIs
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Grid>
        <Grid sx={{ mt: 5 }} item sm={10} >
          <Banner slug={`${connectorSlug}-connector`}></Banner>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default MappingCompleted;
