import { Navigation, Pagination, Scrollbar, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";

interface Props {
  data: any;
  extra?: any;
}

const CustomCarousel = (props: Props) => {
  const data = props.data;
  return (
    <Swiper
      speed={1500}
      slidesPerView={1}
      updateOnWindowResize
      pagination={{ clickable: true }}
      modules={[Navigation, Pagination, Scrollbar, Autoplay]}
      autoplay={{
        delay: 1500,
        disableOnInteraction: false,
      }}
      {...props.extra}
    >
      {data &&
        data.length > 0 &&
        data.map((item: any) => (
          <SwiperSlide>
            {props?.extra?.fullHeight ? (
              <div
                style={{
                  backgroundImage: `url(${item.url})`,
                  backgroundPosition: "center center",
                  height: "100vh",
                  backgroundSize: "100vh",
                }}
              ></div>
            ) : (
              <img src={item.url} style={{ height: "100%", width: "100%" }} />
            )}
          </SwiperSlide>
        ))}
    </Swiper>
  );
};

export default CustomCarousel;
