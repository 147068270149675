import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { uniqBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import SaveIcon from "../../assets/save_icon.png";
import { useDeepCompareDiffEffect } from "../../hooks/useDeepCompareDiffEffect";
import {
  getCustomKPIFilters,
  saveFilters,
} from "../../services/filter-service";
import { setDate } from "../../store/date-range-setter-store";
import { showMessage } from "../../store/error-handler-store";
import { showOverlay } from "../../store/filter-overlay-store";
import {
  setFiltersLoaded,
  setSelectedKPICurrentFilters,
} from "../../store/filter-state-store";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { fontInter, theme } from "../../theme";
import DateRangePickerCustomComponent from "../atoms/DateRangePickerCustomComponent";
import ChipsList from "../molecules/ChipsList";
const AutoCompleteComponent = styled(Autocomplete)({
  ".MuiAutocomplete-inputRoot": {
    color: theme.custom.dropdownPrimaryColor,
    fontWeight: 500,
    borderRadius: 8,
    fontSize: "14px",
    fontFamily: fontInter,
  },
});

interface Props {
  board_slug?: string;
  onFiltersApply?: Function;
  urlKpiFilters: any;
}

const CustomFilterPanel = (props: Props) => {
  let slug = useParams();
  let { board_slug, onFiltersApply } = props;
  const isFiltersLoaded = useAppSelector(
    (state) => state.filterState.filtersLoaded
  );
  const selectedKPISlug = useAppSelector(
    (state) => state.filterState.selectedKPI
  );

  const selectedKPITitle = useAppSelector(
    (state) => state.filterState.selectedKPITitle
  );
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const chipState = useAppSelector((state) => state.chipState);
  const [showMore, setShowMore] = useState(false);
  const [allFilters, setAllFilters] = useState<any>({});
  const [currentFilters, setCurrentFilters] = useState<any>({});
  const [savedFilters, setSavedFilters] = useState<any>({});
  const overlay = useAppSelector((state) => state.filterOverlay.overlay);
  const [isDateRangeValid, setDateRangeValid] = useState(true);

  useEffect(() => {
    getAllFilters();
  }, [location, board_slug]);

  useEffect(() => {
    if (overlay === false) {
      setShowMore(false);
    }
  }, [overlay]);

  useDeepCompareDiffEffect(
    (diff) => {
      if (Object.keys(diff).length > 0) {
        if (selectedKPISlug && selectedKPISlug.length > 0) {
          getCustomKPIFilters(selectedKPISlug).then((res) => {
            if (res.status === 200) {
              setAllFilters(res?.data?.data);
              let savedFilterObj: any = {};
              Object.keys(res?.data?.data).forEach((obj) => {
                if (res?.data?.data?.[obj]?.saved_data) {
                  savedFilterObj[obj] = res?.data?.data?.[obj]?.saved_data;
                }
              });

              Object.keys(savedFilterObj).forEach((ele: string) => {
                if (ele === "aggregation") {
                  savedFilterObj[ele] = {
                    ...savedFilterObj[ele],
                    action: "reset",
                  };
                }
                if (ele === "daterange") {
                  savedFilterObj[ele] = {
                    ...savedFilterObj[ele],
                    action: "delete",
                  };
                }
              });

              dispatch(setDate(savedFilterObj["daterange"]));
              setSavedFilters(savedFilterObj);
              // override if filters coming from url.
              if (props.urlKpiFilters) {
                if (props.urlKpiFilters.program_name) {
                  const program_name = props.urlKpiFilters.program_name.toLowerCase();
                  // try to match with program
                  const selectedProgram = res?.data?.data.program.data.find(
                    (i: any) => i.label.toLowerCase() === program_name
                  );
                  if (
                    window.confirm(
                      `Applying filter with program ${selectedProgram.label}. Proceed?`
                    )
                  ) {
                    props.urlKpiFilters.program = [
                      { ...selectedProgram, action: "delete" },
                    ];
                  }
                }
                savedFilterObj = { ...savedFilterObj, ...props.urlKpiFilters };
              }
              setCurrentFilters(savedFilterObj);
              onFilterApply(savedFilterObj);
              dispatch(setFiltersLoaded(true));
            }
          });
        }
      }
    },
    [selectedKPISlug]
  );

  const getAllFilters = () => {
    if (onFiltersApply) {
      onFiltersApply({});
    }
  };

  useEffect(() => {
    if (chipState.close === true) {
      closeFilter();
      onFilterApply(currentFilters);
    }
  }, [chipState]);

  const showMoreFilters = () => {
    setShowMore(!showMore);
    dispatch(showOverlay(!showMore));
  };

  const onFilterApply = (obj: any) => {
    setCurrentFilters(obj);
    closeFilter();
    dispatch(
      setSelectedKPICurrentFilters({ data: obj, kpi_slug: selectedKPISlug })
    );
  };

  const onFilterSave = () => {
    onFilterApply(currentFilters);
    saveFilters(slug?.slug_name || "", currentFilters).then((res) => {
      dispatch(
        showMessage({
          message: "Filters Saved Successfully",
          variant: "success",
        })
      );
      setSavedFilters(currentFilters);
    });
  };

  const closeFilter = () => {
    if (showMore === true) {
      setShowMore(false);
      dispatch(showOverlay(false));
    }
  };

  const [hideDeleted, setHideDeleted] = useState(false);

  const renderOption = (props: any, option: any, ele: any) => {
    if (
      allFilters[ele]["name"].toLowerCase().includes("program") &&
      props["data-option-index"] === 0
    ) {
      return (
        <Grid container justifyContent={"end"}>
          <Grid item component="li" {...props} onClick={() => {}}>
            <Typography py={1} variant="inter_p_500_14">
              Hide deleted
            </Typography>
            <Checkbox
              checked={hideDeleted}
              onClick={(e) => {
                setHideDeleted(!hideDeleted);
              }}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item component="li" {...props}>
        <Stack direction="column">
          <Typography py={1} variant="inter_p_500_14">
            {option?.label}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  const getAutocompleteValue = (key: any) => {
    if (currentFilters && currentFilters[key]) {
      if (
        currentFilters[key] &&
        currentFilters[key].length === 0 &&
        !allFilters[key]["multi_select"]
      ) {
        return { label: "", value: "" };
      } else {
        return currentFilters[key];
      }
    }
    return allFilters[key]["multi_select"] ? [] : { label: "", value: "" };
  };
  const handleChangeAutocomplete = (
    element: any,
    opt: any,
    multiple?: boolean
  ) => {
    let _currentFilters: any = { ...currentFilters };
    if (!multiple) {
      _currentFilters[element] = opt?.option;
      if (element === "aggregation") {
        if (!_currentFilters[element]["action"]) {
          _currentFilters[element]["action"] = "reset";
        }
      }
    } else {
      if (
        _currentFilters[element] &&
        !_currentFilters[element].some(
          (ele: any) => ele.value === opt?.option.value
        )
      ) {
        _currentFilters[element] = [
          ..._currentFilters[element],
          { ...opt?.option, action: "delete" },
        ];
      } else {
        _currentFilters[element] = [{ ...opt?.option, action: "delete" }];
      }
      let tmp = allFilters[element]["data"];
      let filtered = tmp.filter(
        (ele: any) => ele["value"] !== opt.option.value
      );

      let _allFilters = { ...allFilters };
      _allFilters[element]["data"] = filtered;
      setAllFilters(_allFilters);
    }
    setCurrentFilters(_currentFilters);
  };

  const handleDelete = (key: any, option: any, index?: number) => {
    let _currentFilters = { ...currentFilters };
    let _allFilters = { ...allFilters };
    let arrToUpdate = [..._currentFilters[key]];
    arrToUpdate = arrToUpdate.filter((ele: any) => ele.value !== option.value);
    _currentFilters[key] = arrToUpdate;
    setCurrentFilters(_currentFilters);
    let exists = _allFilters[key]["data"].some(
      (ele: any) => ele.value === option.value
    );
    if (!exists) {
      _allFilters[key]["data"].unshift(option);
      uniqBy(_allFilters[key]["data"], "value");
    }
    setAllFilters(_allFilters);
  };

  const onChange = (dateRange: any, id: string) => {
    let _currentFilters: any = { ...currentFilters };
    _currentFilters[id] = dateRange;

    setCurrentFilters(_currentFilters);
  };

  const getOptions = (ele: any) => {
    if (allFilters[ele]["name"].toLowerCase().includes("program")) {
      return [
        {},
        ...(allFilters[ele]?.data?.filter((i: any) =>
          hideDeleted ? !i.label.includes("(deleted)") : true
        ) || []),
      ];
    }

    return allFilters[ele]?.data || [];
  };

  const getFilterOptions = (ele: any, options: any, searchTerm: string) => {
    const _options = options.filter((i: any) =>
      i.label.toLowerCase().trim().includes(searchTerm)
    );
    if (allFilters[ele]["name"].toLowerCase().includes("program")) {
      return [
        {},
        ...(_options.filter((i: any) =>
          hideDeleted ? !i.label.includes("(deleted)") : true
        ) || []),
      ];
    }

    return _options || [];
  };

  return (
    <React.Fragment>
      <Box
        pt={3}
        sx={{
          background: theme.custom.filterBackgroundColor,
          height: "auto",
          width: "100%",
          border: 1,
          borderColor: theme.custom.filterBorderColor,
          borderRadius: "6px",
        }}
      >
        <Box
          mb={2}
          sx={{ visibility: isFiltersLoaded ? "visible" : "hidden" }}
          mx={1}
          px={2}
        >
          <Box my={2}>
            <Typography
              sx={{ color: theme.custom.primaryDarkColor }}
              variant="poppins_h5_600_18"
            >
              {selectedKPITitle ? selectedKPITitle : ""}
            </Typography>
          </Box>
          <Grid container justifyContent={"space-between"}>
            <Grid item justifyContent={"flex-start"} xs={9.5}>
              <Grid container spacing={1}>
                {allFilters &&
                  Object.keys(allFilters).length > 0 &&
                  Object.keys(allFilters)
                    .slice(0, 4)
                    .map((ele: any, index: number) => {
                      if (allFilters[ele].type === "daterange") {
                        return (
                          <Grid
                            key={index}
                            item
                            xs={Object.keys(allFilters).length >= 4 ? 3 : 4}
                          >
                            <Box
                              sx={{
                                border: `1px solid ${theme.custom.borderColor}`,
                                borderRadius: "6px",
                              }}
                            >
                              <DateRangePickerCustomComponent
                                onChange={onChange}
                                isCustomBoard={selectedKPISlug.length > 0}
                                menuOptions={allFilters[ele]["multi_filter"]}
                                dateRangeValue={currentFilters[ele]}
                                id={ele}
                              />
                            </Box>
                          </Grid>
                        );
                      }

                      return (
                        <Grid
                          key={index}
                          item
                          xs={Object.keys(allFilters).length >= 4 ? 3 : 4}
                        >
                          <AutoCompleteComponent
                            disableClearable
                            multiple={allFilters[ele]["multi_select"]}
                            limitTags={2}
                            fullWidth
                            id={ele + "autocomplete"}
                            options={getOptions(ele)}
                            renderOption={(props, option) =>
                              renderOption(props, option, ele)
                            }
                            filterOptions={(options, state) =>
                              getFilterOptions(
                                ele,
                                options,
                                state.inputValue.toLowerCase().trim()
                              )
                            }
                            getOptionLabel={(option: any) => {
                              return option ? option.label : "";
                            }}
                            filterSelectedOptions={true}
                            onChange={(e: any, v: any, r: any, opt: any) => {
                              if (!v) return;
                              handleChangeAutocomplete(
                                ele,
                                opt,
                                allFilters[ele]["multi_select"]
                              );
                            }}
                            value={getAutocompleteValue(ele)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                InputLabelProps={{
                                  style: {
                                    marginLeft: 7,
                                  },
                                }}
                                label={
                                  <Typography variant="inter_p_500_10">
                                    {allFilters[ele]["name"]}
                                  </Typography>
                                }
                              />
                            )}
                            renderTags={(tagValue, getTagProps) => {
                              return tagValue.map((option: any, index) => (
                                <Chip
                                  sx={{
                                    background: theme.custom.whiteColor,
                                    border: `1px solid ${theme.custom.primaryDarkColor}`,
                                    marginRight: "2px",
                                  }}
                                  label={
                                    <Typography variant="inter_500_12">
                                      {option?.label}
                                    </Typography>
                                  }
                                  onDelete={() => {
                                    handleDelete(ele, option, index);
                                  }}
                                  deleteIcon={<CloseIcon />}
                                  size={"small"}
                                />
                              ));
                            }}
                          />
                        </Grid>
                      );
                    })}
              </Grid>
            </Grid>
            <Grid item justifyContent={"flex-end"} xs={2.5}>
              <Grid container justifyContent={"right"}>
                <Grid item>
                  <Button
                    sx={{
                      height: "50px",
                      marginLeft: 2,
                      marginRight: 1,
                    }}
                    size={"medium"}
                    variant="contained"
                    onClick={() => onFilterApply(currentFilters)}
                    disabled={!isDateRangeValid}
                  >
                    <Typography variant="poppins_p_600_14"> Apply </Typography>
                  </Button>
                </Grid>
                <Grid sx={{ marginRight: 1 }} item>
                  <Button
                    onClick={onFilterSave}
                    sx={{
                      height: "50px",
                    }}
                    disabled={!isDateRangeValid}
                    fullWidth
                    variant="outlined"
                  >
                    <img src={SaveIcon} height={25} alt="save" />
                  </Button>
                </Grid>
                {Object.keys(allFilters).length > 4 && (
                  <Grid item>
                    <Button
                      fullWidth
                      onClick={showMoreFilters}
                      sx={{
                        height: "50px",
                      }}
                      variant="outlined"
                    >
                      {!showMore && <KeyboardArrowDownIcon />}
                      {showMore && <KeyboardArrowUpIcon />}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>

        {showMore && <Divider sx={{ my: 4 }} />}
        {showMore && (
          <Box my={2} mx={1} px={2} mb={4}>
            <Grid container spacing={2}>
              {allFilters &&
                Object.keys(allFilters).length > 0 &&
                Object.keys(allFilters)
                  .slice(4, Object.keys(allFilters).length)
                  .map((ele: any, index: number) => {
                    if (ele === "daterange") {
                      return (
                        <Grid item mt={2} pl={3} xs={3}>
                          <DateRangePickerCustomComponent
                            onChange={onChange}
                            isCustomBoard={selectedKPISlug.length > 0}
                            menuOptions={allFilters[ele]["multi_filter"]}
                            dateRangeValue={
                              currentFilters[allFilters[ele].type]
                            }
                          />
                        </Grid>
                      );
                    }
                    return (
                      <Grid key={index} item xs={2.5}>
                        <AutoCompleteComponent
                          disableClearable
                          limitTags={2}
                          multiple={allFilters[ele]["multi_select"]}
                          fullWidth
                          id={ele + "autocomplete"}
                          options={
                            allFilters[ele]?.data &&
                            allFilters[ele]?.data.length > 0
                              ? allFilters[ele]?.data
                              : []
                          }
                          filterSelectedOptions
                          filterOptions={(options, state) =>
                            getFilterOptions(
                              ele,
                              options,
                              state.inputValue.toLowerCase().trim()
                            )
                          }
                          onChange={(e: any, v: any, r: any, opt: any) =>
                            handleChangeAutocomplete(
                              ele,
                              opt,
                              allFilters[ele]["multi_select"]
                            )
                          }
                          value={getAutocompleteValue(ele)}
                          getOptionLabel={(option: any) =>
                            option ? option.label : ""
                          }
                          renderOption={(props, option) =>
                            renderOption(props, option, ele)
                          }
                          renderTags={(tagValue, getTagProps) => {
                            return tagValue.map((option: any, index) => (
                              <Chip
                                sx={{
                                  background: theme.custom.whiteColor,
                                  border: `1px solid ${theme.custom.primaryDarkColor}`,
                                  marginRight: "2px",
                                }}
                                label={
                                  <Typography variant="inter_500_12">
                                    {option?.label}
                                  </Typography>
                                }
                                onDelete={() => {
                                  handleDelete(ele, option, index);
                                }}
                                deleteIcon={<CloseIcon />}
                                size={"small"}
                              />
                            ));
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputLabelProps={{
                                style: {
                                  marginLeft: 7,
                                },
                              }}
                              label={
                                <Typography variant="inter_p_500_10">
                                  {allFilters[ele]["name"]}
                                </Typography>
                              }
                            />
                          )}
                        />
                      </Grid>
                    );
                  })}
            </Grid>
          </Box>
        )}
        <Box
          sx={{ visibility: isFiltersLoaded ? "visible" : "hidden" }}
          mt={4}
          px={2}
          ml={1}
        >
          <ChipsList
            currentFilters={currentFilters}
            onFilterApply={onFilterApply}
            allFilters={allFilters}
            savedFilters={savedFilters}
            slug={slug.slug_name}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default CustomFilterPanel;
