import { Tooltip } from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";

function VideoEditor(props: any) {
  const { isElementSelected, attributes, alt, updateElement, height, width } =
    props;
  const videoRef = useRef<HTMLDivElement>(null as any);

  const _onLoad = useCallback(() => {
    if (height || width) return;
    updateElement(
      {
        height: videoRef?.current?.clientHeight,
        width: videoRef?.current?.clientWidth,
      },
      "post-render"
    );
  }, []);

  const [enableControl, setEnableControls] = useState<boolean>(false);

  useEffect(() => {
    setEnableControls(false);
  }, [isElementSelected]);

  const handleControls = (e: any) => {
    if (e.detail % 2 === 0) {
      setEnableControls(true);
    }
  };

  return (
    <>
      <div ref={videoRef}>
        <Tooltip title="Double click to interact">
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              background: "white",
              opacity: 0.2,
              zIndex: 2,
              display: enableControl ? "none" : "block",
            }}
            onClick={handleControls}
          ></div>
        </Tooltip>
        <video width="100%" controls preload="metadata" onLoadedData={_onLoad}>
          <source src={attributes.url} type="video/mp4" />
          <p>{alt}</p>
        </video>
      </div>
    </>
  );
}

export default React.memo(VideoEditor);
