import axios from "axios";
import { environment } from "../environments/environment-dev";

export const getAllChartTypes = () => {
  const url = `${environment.connectorApiUrl}/chart-types`;
  return axios.get(url);
};

export const getAllWorkspaces = (page: number) => {
  const url = `${environment.connectorApiUrl}/datasources?page=${page}`;
  return axios.get(url);
};

export const getAllWorkbookSheetsColumn = (data: any) => {
  const url = `${
    environment.connectorApiUrl
  }/data-carts/datasource/workbook-sheet-column?data=${JSON.stringify(data)}`;
  return axios.get(url);
};

export const searchByColumn = (data: any) => {
  const url = `${
    environment.connectorApiUrl
  }/data-carts/datasource/workbook?data=${JSON.stringify(data)}`;
  return axios.get(url);
};

export const getConfigs = () => {
  const url = `${environment.connectorApiUrl}/kpi-builder/configs`;
  return axios.get(url);
};

export const getKPIDataForSlug = (slug: string) => {
  const url = `${environment.connectorApiUrl}/kpi-builder/kpi/${slug}`;
  return axios.get(url);
};

export const preview = (data: any) => {
  const url = `${environment.connectorApiUrl}/kpi-builder/preview`;
  return axios.put(url, data);
};

export const getAllCustomKPIBoards = () => {
  const url = `${environment.connectorApiUrl}/boards/custom-sub-boards`;
  return axios.get(url);
};

export const addBoard = (boardName: string) => {
  const url = `${environment.connectorApiUrl}/boards/custom`;
  return axios.post(url, { name: boardName });
};

export const saveCustomKPI = (data: any) => {
  const url = `${environment.connectorApiUrl}/kpi-builder/kpi`;
  return axios.put(url, data);
};

export const getAllFilters = (data: any) => {
  const url = `${
    environment.connectorApiUrl
  }/data-carts/datasource/workbook?data=${JSON.stringify({
    datasource_list: data,
  })}`;
  return axios.get(url);
};

export const getCustomKPIs = (searchStr: string = "") => {
  const url = `${environment.connectorApiUrl}/kpi-builder/kpi`;
  let obj = {
    search: searchStr,
  };
  return axios.post(url, obj);
};

export const deleteCustomKPI = (uuid: string) => {
  const url = `${environment.connectorApiUrl}/kpi-builder/kpi/${uuid}`;
  return axios.delete(url);
};
