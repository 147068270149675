import {
  Button,
  DialogActions,
  DialogContent,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import {
  OtherPermissionsMetaData,
  FormattedMemberDetails,
  OtherPermissionsMeta,
  PermissionsDetail,
} from "../../../Types/UserManagement";
import { getPermissionsMeta } from "../../../services/user-management-service";
import MemberOtherPermissionCheckboxItem from "./MemberOtherPermissionCheckboxItem";
import MemberOtherPermissionDropdrown from "./MemberOtherPermissionDropdrown";
import { useDeepCompareEffect } from "react-use";

type Props = {
  updateMemberStateAndNext: CallableFunction;
  loadPreviousPage: CallableFunction;
  formattedMemberDetails: FormattedMemberDetails;
  updateFormattedMemberDetails: CallableFunction;
};

const MemberOtherPermissionForm = (props: Props) => {
  const [otherPermissionsData, setOtherPermissionsData] =
    useState<OtherPermissionsMeta>();

  const [formattedMemberDetailsDirty, setFormattedMemberDetailsDirty] =
    useState(props.formattedMemberDetails);

  const [isLoading, setIsLoading] = useState(true);

  useDeepCompareEffect(() => {
    props.updateFormattedMemberDetails(formattedMemberDetailsDirty);
  }, [formattedMemberDetailsDirty]);

  useEffect(() => {
    setIsLoading(true);
    // get permission meta
    getPermissionsMeta(
      "other_module_permissions",
      formattedMemberDetailsDirty.role_slug
    ).then((resp) => {
      if (resp && resp.data.data) {
        setOtherPermissionsData(resp.data);
        setIsLoading(false);
      }
    });
  }, [formattedMemberDetailsDirty.role_slug]);

  const setSelected = (
    item: OtherPermissionsMetaData,
    value: PermissionsDetail,
    currentFormattedMemberDetails: FormattedMemberDetails
  ) => {
    let _newFormattedMemberDetails: any = {
      ...currentFormattedMemberDetails,
    };
    let _other_module_permissions =
      _newFormattedMemberDetails[_newFormattedMemberDetails.role_slug]
        ?.other_module_permissions || {};
    _other_module_permissions[item.permission_slug] = value;

    if (!_newFormattedMemberDetails[_newFormattedMemberDetails.role_slug])
      _newFormattedMemberDetails[_newFormattedMemberDetails.role_slug] = {};

    _newFormattedMemberDetails[
      _newFormattedMemberDetails.role_slug
    ].other_module_permissions = _other_module_permissions;
    return _newFormattedMemberDetails;
  };

  const setSelectedWrapper = (
    item: OtherPermissionsMetaData,
    value: PermissionsDetail
  ) => {
    setFormattedMemberDetailsDirty((state) => setSelected(item, value, state));
  };

  const renderOtherPermissionItem = (item: OtherPermissionsMetaData) => {
    if (!formattedMemberDetailsDirty) return <></>;
    if (item.type === "checkbox") {
      return (
        <MemberOtherPermissionCheckboxItem
          metaItem={item}
          setCheckboxSelected={setSelectedWrapper}
          memberDetailsForItem={
            formattedMemberDetailsDirty?.[formattedMemberDetailsDirty.role_slug]
              ?.other_module_permissions?.[item.permission_slug]
          }
        />
      );
    } else if (item.type === "multi_select") {
      return (
        <MemberOtherPermissionDropdrown
          metaItem={item}
          setAutoCompleteSelected={setSelectedWrapper}
          memberDetailsForItem={
            formattedMemberDetailsDirty?.[formattedMemberDetailsDirty.role_slug]
              ?.other_module_permissions?.[item.permission_slug]
          }
        />
      );
    }
  };

  const handleNext = () => {
    props.updateMemberStateAndNext({});
  };

  return (
    <>
      <DialogContent sx={{ height: "100%" }}>
        <Grid container direction={"column"} justifyContent={"space-between"}>
          <Grid item>
            <Grid item>
              <Typography variant="poppins_h4_600_20">
                Other Permissions
              </Typography>
            </Grid>
            <Grid item mt={1} justifyContent={"space-between"}>
              {isLoading && (
                <Skeleton
                  sx={{ bgcolor: "grey.100", mt: 4, borderRadius: 3 }}
                  variant="rectangular"
                  animation="wave"
                  height={350}
                />
              )}
              {!isLoading && (
                <Grid container justifyContent={"space-between"}>
                  {otherPermissionsData?.data.map((item) =>
                    renderOtherPermissionItem(item)
                  )}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"space-between"}>
          <Grid item>
            <Button
              sx={{ height: "50px", px: 4 }}
              onClick={() => props.loadPreviousPage()}
              variant="outlined"
              type="submit"
            >
              <Typography variant="poppins_p_600_14">Back</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              sx={{ height: "50px", px: 12 }}
              onClick={handleNext}
              variant="contained"
              type="submit"
            >
              <Typography variant="poppins_p_600_14">Save</Typography>
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </>
  );
};

export default memo(MemberOtherPermissionForm);
