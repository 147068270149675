import {Grid, Typography} from "@mui/material";
import {theme} from "../../../theme";
import React from "react";

interface Props {
  boards: any
  menuItemClickHandler: Function
}

const CustomKpiBoardView = (props: Props) => {
  const {boards, menuItemClickHandler} = props
  return (
    <Grid container spacing={{ xs: 3, md: 4 }} columns={{ xs: 4, sm: 8, md: 12 }}>
      {boards.map((board: any, index: any) => (
        <Grid item sx={{cursor: "pointer"}} xs={3} sm={4} md={4} key={index} onClick={() => menuItemClickHandler(board)}>
          <Grid container sx={{justifyContent: "center", alignItems: "center", border: `1px solid ${theme.custom.borderColor}`, borderRadius: "6px", padding: 2}}>
            <Grid item xs={11}>
              <Typography gutterBottom noWrap={true} variant="poppins_h6_600_16" component="div">
                {board.title}
              </Typography>
              <Typography variant="inter_400_14" color="text.secondary">
                {board.kpi_count > 1 ? `${board.kpi_count} KPIS` : `${board.kpi_count} KPI`}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <img alt={board.board_slug} width="auto" src={require(`../../../assets/right-arrow-line.png`).default} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
};

export default CustomKpiBoardView;