import { MultiLevelMenuItem } from "../atoms/MultiLevelMenuItem";
import { SingleLevelMenuItem } from "../atoms/SingleLevelMenuItem";

export const NavigationMenuItem = ({
  item,
  menuClicked,
  onMenuHover,
  menuHoveredOut,
}: any) => {
  return hasChildren(item) ? (
    <MultiLevelMenuItem
      onHoverMenuItem={onMenuHover}
      menuClicked={menuClicked}
      item={item}
    />
  ) : (
    <SingleLevelMenuItem
      onHoverMenuItem={onMenuHover}
      menuClicked={menuClicked}
      item={item}
    />
  );
};
function hasChildren(item: any) {
  const { sub_menu: children } = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  if (children.length === 0) {
    return false;
  }

  return true;
}
