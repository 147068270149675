import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  ListItem,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import LZString from "lz-string";
import { useEffect, useState } from "react";
import {
  ConnectorMappingConfig,
  getAllDimAttr,
} from "../../services/user-mapping-service";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import {
  addContract,
  setAllResolvedAttrsDims,
} from "../../store/user-mapping-store";
import QuickbooksContractsMappingsListItem from "../molecules/QuickbooksContractsMappingsListItem";

interface Props {
  configItems: ConnectorMappingConfig[];
  meta: ConnectorMappingConfig;
}

const QuickbooksContractsMappings = (props: Props) => {
  const [loadedDimAttrs, setLoadedDimAttrs] = useState(false);
  const [selectedProgram, setSelectedProgram] = useState<string | null>(null);
  const dispatch = useAppDispatch();
  const [contractName, setContractName] = useState("");
  const [contractNameError, setContractNameError] = useState("");

  const allResolvedAttrsDims = useAppSelector((state) => {
    return state.userMapping.allResolvedAttrsDims;
  });

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setContractName("");
    setContractNameError("");
  };

  useEffect(() => {
    getAllDimAttr("quickbooks").then((resp) => {
      if (resp.status === 200) {
        const data: any = JSON.parse(
          LZString.decompressFromEncodedURIComponent(resp.data.compressed_data) || "{}"
        ).data;
        dispatch(setAllResolvedAttrsDims(data));
        setLoadedDimAttrs(true);
      } else {
        alert("something wrong happened");
      }
    });
  }, []);

  const renderList = () => {
    return (
      <>
        {props.configItems
          .filter((e) => e.user_mapping.ref_program_id === selectedProgram)
          .map((item, idx, arr) => (
            <div key={item.id}>
              <ListItem
                disableGutters
                style={{
                  backgroundColor: idx % 2 === 0 ? "#fffff" : "#f9f9f957",
                }}
                className={`umitem_${item.slug}`}
              >
                <QuickbooksContractsMappingsListItem
                  UMData={item}
                  existingContractNames={[
                    ...existingContractNames.filter(
                      (i) => i !== item.user_mapping.alt_title.toLowerCase()
                    ),
                  ]}
                />
              </ListItem>
              <Divider
                variant="fullWidth"
                sx={{ display: idx === arr.length - 1 ? "none" : "block" }}
              />
            </div>
          ))}
      </>
    );
  };

  const [existingContractNames, setExistingContractNames] = useState<any[]>(
    props.configItems
      .filter((e) => e.user_mapping.ref_program_id === selectedProgram)
      .map((e) => e.user_mapping.alt_title)
  );

  useEffect(() => {
    setExistingContractNames(
      props.configItems
        .filter((e) => e.user_mapping.ref_program_id === selectedProgram)
        .map((e) => e.user_mapping.alt_title.toLowerCase())
    );
  }, [props.configItems, selectedProgram]);

  const onAddContract = () => {
    if (contractName.length === 0) {
      setContractNameError("Name should be at least one character");
    } else if (contractName.length > 128) {
      setContractNameError("Name should be at most 128 characters");
    } else if (existingContractNames.includes(contractName.toLowerCase())) {
      setContractNameError("Contract name already exists");
    } else {
      dispatch(
        addContract({
          meta_data_id: props.meta.id, // all contract meta ids are same
          alt_title: contractName,
          alt_slug: null,
          ref_program_title: allResolvedAttrsDims["program"].find(
            (p) => p.id === selectedProgram
          ).name,
          ref_program_id: selectedProgram,
          mapping: {
            groups: [],
            conditions: [],
            mapping_type: "conditions",
          },
        })
      );
      handleClose();
    }
  };
  const isSaving = useAppSelector((state) => {
    return state.userMapping.saving;
  });

  const isUMDirty = useAppSelector((state) => {
    return state.userMapping.UMDirty;
  });

  return (
    <>
      {!loadedDimAttrs ? (
        <Skeleton
          sx={{ my: 2, bgcolor: "grey.100" }}
          variant="rectangular"
          animation="wave"
          height={120}
        />
      ) : (
        <>
          <Grid
            container
            sx={{ border: 1, borderRadius: "6px", p: 2 }}
            justifyContent="space-between"
          >
            <Grid item sx={{ mt: 1 }} xs={4}>
              <Typography variant="inter60016">Select Program</Typography>
            </Grid>
            <Grid item xs={8}>
              <Select
                size="small"
                fullWidth
                disabled={isSaving || isUMDirty}
                value={selectedProgram}
                onChange={(e) => setSelectedProgram(e.target.value)}
              >
                {allResolvedAttrsDims["program"].map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                      {item.name}
                    </Typography>
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={12} mt={2}>
              <Divider
                variant="fullWidth"
                sx={{ display: selectedProgram === null ? "none" : "block" }}
              />
            </Grid>
            <Grid item xs={12}>
              {selectedProgram !== null && renderList()}
            </Grid>
            <Grid
              item
              xs={12}
              sx={{ display: selectedProgram === null ? "none" : "block" }}
            >
              <Button
                variant="contained"
                sx={{ mt: 2 }}
                disabled={isSaving || isUMDirty}
                onClick={handleClickOpen}
              >
                <Typography variant="poppins_600_12">Add Contract</Typography>
              </Button>
              <Dialog open={open} onClose={handleClose} sx={{ zIndex: 10000 }}>
                <DialogTitle>New Contract</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Contract Name"
                    type="text"
                    fullWidth
                    error={contractNameError !== ""}
                    helperText={contractNameError}
                    variant="standard"
                    onChange={(e) => setContractName(e.target.value)}
                    value={contractName}
                  />
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button onClick={onAddContract} type="submit">
                    Create
                  </Button>
                </DialogActions>
              </Dialog>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default QuickbooksContractsMappings;
