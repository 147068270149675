import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, Backdrop, Fade, Modal } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { theme } from "../../../theme";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FileUploader from "../FileUploader";
import Uppy from "@uppy/core";
type Props = {
  open: boolean;
  closeModal: Function;
  onSuccessfulUpload: Function;
};

const style = {
  position: "absolute" as "absolute",
  top: "30%",
  left: "50%",
  transform: "translate(-50%, -30%)",
  width: 750,
  height: 750,
  bgcolor: "#fff",
  border: `1px solid ${theme.custom.KPIMoleculeBorderColor}`,
  borderRadius: "6px",
  px: 4,
  py: 6,
};
const ExcelConnectorFileUploader = (props: Props) => {
  const { open, closeModal, onSuccessfulUpload } = props;
  const [openModal, setOpenModal] = useState<boolean>(open);

  useEffect(() => {
    if (open) {
      setOpenModal(open);
    }
  }, [open]);

  const close = () => {
    setOpenModal(false);
    closeModal();
  };

  return (
    <Modal
      sx={{ position: "absolute", zIndex: 2500 }}
      open={openModal}
      onBackdropClick={close}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <Box sx={style}>
          <Grid container>
            <Grid textAlign={"center"} item xs={11}>
              <Typography
                sx={{ color: `${theme.custom.primaryDarkColor}` }}
                variant="poppins_h3_600_24"
              >
                Upload MS Excel Workbook
              </Typography>
            </Grid>
            <Grid textAlign="right" item xs={1}>
              <CancelOutlinedIcon
                onClick={close}
                sx={{
                  color: `${theme.custom.primaryGreyColor}`,
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
          <Grid justifyContent={"center"} mt={3} item xs={12}>
            <div style={{ textAlign: "center" }}>
              <FileUploader
                height={500}
                closeModal={close}
                onUploadSuccess={onSuccessfulUpload}
              />
            </div>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default React.memo(ExcelConnectorFileUploader);
