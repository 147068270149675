import { useEffect, useState } from "react";
import { getCompanyId } from "../../../services/userinfo-service";
import {
  createReport,
  createReportLink,
} from "../../../services/impact-report-service";
import { useAppDispatch } from "../../../store/hook";
import { showMessage } from "../../../store/error-handler-store";
import { Box, Button, Grid, Skeleton, Typography } from "@mui/material";
import ImpactReportPublic from "../../../pages/ImpactReportPublic";
import { theme } from "../../../theme";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CreateNewImpactReport from "./CreateImpactReport";

type Props = {
  templateObj: any;
  onClose: Function;
  userQuery?: string;
  audience?: string;
  purpose?: string;
};

const PreviewTemplate = (props: Props) => {
  const { templateObj, onClose } = props;
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  const [reportDetails, setReportDetails] = useState({} as any);

  useEffect(() => {
    create();
  }, []);

  const previewReport = () => {
    if (reportDetails && Object.keys(reportDetails).length) {
      return (
        <>
          <Box>
            <Grid
              sx={{ position: "relative", zIndex: 6000, mb: 10, px: 2 }}
              container
            >
              <Grid item xs={12}>
                <Grid container justifyContent={"space-between"}>
                  <Grid item>
                    <Typography
                      sx={{ color: `${theme.custom.primaryDarkColor}` }}
                      variant="poppins_h3_600_24"
                    >
                      {templateObj?.attributes?.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems={"center"}>
                      <Grid item>
                        <Button
                          sx={{ height: 50 }}
                          onClick={() => setOpen(true)}
                          variant="contained"
                        >
                          <Typography
                            sx={{ textTransform: "none" }}
                            variant="poppins_p_600_14"
                          >
                            Use this template
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item>
                        <CancelOutlinedIcon
                          onClick={() => onClose()}
                          sx={{
                            mt: 0.5,
                            ml: 5,
                            color: `${theme.custom.primaryGreyColor}`,
                            cursor: "pointer",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {reportDetails && Object.keys(reportDetails).length && (
              <ImpactReportPublic
                reportData={reportDetails}
                disableToolbar={true}
              />
            )}
            <CreateNewImpactReport
              close={() => setOpen(false)}
              open={open}
              selectedTemplate={templateObj}
              closePreviewModal={() => onClose()}
            />
          </Box>
        </>
      );
    } else {
      return (
        <Skeleton
          sx={{
            my: 2,
            bgcolor: "grey.200",
          }}
          variant="rectangular"
          animation="wave"
          height={400}
        />
      );
    }
  };
  const create = () => {
    let reportData = {
      name: templateObj?.attributes?.title,
      template_id: templateObj?.id,
      is_template_edit_flow: true,
      company_uid: getCompanyId(),
      user_query: props.userQuery,
      audience: props.audience,
      purpose: props.purpose
    };

    createReport(reportData).then((res) => {
      if (res.status === 403) {
        dispatch(
          showMessage({
            message:
              "You don’t have access to edit this template. Please reach out to admin to get access",
            variant: "error",
          })
        );
        return;
      }
      setReportDetails(res.data);
    });
  };

  return <div>{previewReport()}</div>;
};

export default PreviewTemplate;
