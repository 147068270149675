import { Box, Grid, Popover, Typography, styled } from "@mui/material";
import React, { memo, useState } from "react";
import { theme } from "../../theme";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" && theme.custom.activeColor,
  },
}));

type Props = {
  id: string;
  open: boolean;
  progress: number;
  closePopup: Function;
  anchorEl: any;
};

const PrintStatusPopover = (props: Props) => {
  const handleClose = () => {
    props.closePopup();
  };

  return (
    <React.Fragment>
      <Popover
        id={props.id}
        open={props.open}
        anchorEl={props.anchorEl}
        transformOrigin={{
          vertical: -50,
          horizontal: "right",
        }}
      >
        <Box
          p={3}
          sx={{
            maxHeight: 350,
            width: 400,
            position: "relative",
            borderRadius: "6px",
          }}
        >
          {props.progress === 100 ? (
            <Grid container>
              <Grid mb={1} item sm={1}>
                <CheckCircleIcon
                  sx={{
                    color: theme.custom.successColor,
                  }}
                />
              </Grid>
              <Grid mb={1} item sm={10}>
                <Typography
                  variant="inter_p_600_16"
                  color={theme.custom.primaryDarkColor}
                >
                  Report is now ready to be printed
                </Typography>
              </Grid>
              <Grid ml={4} item sm={12}>
                <Typography
                  onClick={handleClose}
                  sx={{ cursor: "pointer" }}
                  variant="inter_p_600_16"
                  color={theme.custom.popoverLinkColor}
                >
                  Print report
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid container>
              <Grid mb={1} item sm={12}>
                <Typography
                  variant="inter_p_600_16"
                  color={theme.custom.primaryDarkColor}
                >
                  Report is getting ready to be printed
                </Typography>
              </Grid>
              <Grid item sm={12}>
                <BorderLinearProgress
                  variant="determinate"
                  value={props.progress}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      </Popover>
    </React.Fragment>
  );
};

export default memo(PrintStatusPopover);
