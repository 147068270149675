import React, { FunctionComponent } from "react";
import Uppy from "@uppy/core";
import { Dashboard } from "@uppy/react";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import Box from "@uppy/box"
import Dropbox from '@uppy/dropbox'
import GoogleDrive from "@uppy/google-drive";
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
import { getFileNameWithoutExtension } from "../../utils/file-utils";
import { environment } from "../../environments/environment-dev";
import { Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { theme } from "../../theme";

interface Props {
  width?: number|string;
  height: number;
  closeModal?: Function;
  onUploadSuccess?: Function;
  variant?: string;
  UploadActions?: FunctionComponent<any>;
  allowedFileTypes?: any;
}

const FileUploader = ({
  width = 1000,
  height,
  closeModal,
  onUploadSuccess,
  UploadActions,
  variant,
  allowedFileTypes = [".xlsx", ".csv", ".xls"],
}: Props) => {
  const uppy = new Uppy({
    id: "FileUploader",
    restrictions: {
      maxTotalFileSize: 104857600, // Will change later
      maxNumberOfFiles: 1,
      allowedFileTypes: allowedFileTypes,
    },
    onBeforeFileAdded: (currentFile, files) => {
      let modifiedFile = {
        ...currentFile,
        name:
          getFileNameWithoutExtension(currentFile.name) +
          "." +
          currentFile.extension,
      };
      return modifiedFile;
    },
    autoProceed: false,
  });

  uppy.use(AwsS3Multipart, {
    limit: 6,
    companionUrl: environment.companionUrl,
    retryDelays: [0, 1000, 3000, 5000],
  });

  uppy.use(Box, {
    companionUrl: environment.companionUrl || '',
  });

  uppy.use(Dropbox, {
    companionUrl: environment.companionUrl || '',
  });

  uppy.use(GoogleDrive, {
    companionUrl: environment.companionUrl || '',
  });

  uppy.on("complete", (result) => {
    if (onUploadSuccess && closeModal) {
      if (result["successful"].length > 0) {
        onUploadSuccess(result["successful"][0]["uploadURL"], result["successful"][0]["name"]);
        closeModal();
        return;
      }
    }
  });

  const onUpload = () => {
    uppy.upload();
  };

  const close = () => {
    uppy.close({ reason: "unmount" });
    if (closeModal) {
      closeModal();
    }
  };

  const DefaultUploadActions = ({ onUpload, onClose }: any) => {
    return (
      <>
        <Grid item xs={12}>
          <LoadingButton
            onClick={onUpload}
            sx={{ height: "50px" }}
            fullWidth
            variant="contained"
          >
            Upload
          </LoadingButton>
        </Grid>
        <Grid mt={3} textAlign={"center"} item xs={12}>
          <Typography
            onClick={onClose}
            sx={{ cursor: "pointer", color: theme.custom.popoverLinkColor }}
            variant="poppins_p_600_14"
          >
            Cancel
          </Typography>
        </Grid>
      </>
    );
  };

  return (
    <>
      <div>
        <Dashboard
          width={width}
          height={height}
          hideUploadButton={false}
          uppy={uppy}
          className={`uppy-${variant}`}
          proudlyDisplayPoweredByUppy={false}
          plugins={['Box', 'Dropbox', 'GoogleDrive']}
        />
      </div>
      <div>
        <Grid mt={4} container>
          {UploadActions ? (
            <UploadActions onUpload={onUpload} onClose={close} />
          ) : (
            <DefaultUploadActions onUpload={onUpload} onClose={close} />
          )}
        </Grid>
      </div>
    </>
  );
};

export default React.memo(FileUploader);
