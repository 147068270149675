import React from "react";
import CustomMonthPicker from "../CustomMonthPicker";
import moment from "moment";

type Props = {
  row: any;
  cell: any;
  cellValue: any;
  onCellClick: Function;
  isReport: boolean;
  onCellUpdate: Function;
  extraProps?: any;
};

const CustomMonthCalanderCell = (props: Props) => {
  const {
    row,
    cell,
    cellValue,
    onCellClick,
    isReport,
    onCellUpdate,
    extraProps,
  } = props;
  const onUpdateCell = (value: string) => {
    // row.toggleSelected(true);
    onCellUpdate({ ...row.original, newValue: value });
  };

  const formatMinMaxValue = (date: string) => {
    if (date.length === 10) {
      return date.slice(0, 7);
    }
    return "";
  };

  return (
    <CustomMonthPicker
      onChange={onUpdateCell}
      value={cellValue?.value?.primary_value?.slice(0, 7) || ""}
      extraProps={{
        style: { height: "30px", fontSize: "0.7rem" },
      }}
      minValue={formatMinMaxValue(extraProps?.filters?.daterange?.start_date)}
      maxValue={formatMinMaxValue(extraProps?.filters?.daterange?.end_date)}
    />
  );
};

export default CustomMonthCalanderCell;
