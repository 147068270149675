import {
  TextField,
  InputAdornment,
  ImageList,
  ImageListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState, useRef } from "react";
import { getIcons } from "../../../services/impact-report-service";
import { useDebounce } from "react-use";
import { Icon } from "@iconify/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { fontInter, theme } from "../../../theme";
import SearchIcon from "../../../assets/search.png";
import AuthorizationErrorHandler from "./AuthorizationErrorHandler";

const IconsLibrary = (props: any) => {
  const { addElement } = props;
  const [searchStr, setSearchStr] = useState<string>("all");
  const [debouncedValue, setDebouncedValue] = useState(searchStr);
  const [allIcons, setAllIcons] = useState([] as any);
  const [authorized, setAuthorized] = useState(true);

  let pageRef = useRef({ limit: 100 });
  let mouseOrigin = useRef({ left: 0, top: 0 });
  let cursorPosition = useRef({ left: 0, top: 0 });

  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(searchStr);
    },
    600,
    [searchStr]
  );
  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchStr(event.target.value === "" ? "all" : event.target.value);
  };

  useEffect(() => {
    getIcons(debouncedValue).then((res: any) => {
      if (res.status === 403) {
        setAuthorized(false);
        return;
      }
      setAllIcons([]);
      if (res.status === 200) {
        if (res.data && res.data.icons && res.data.icons.length > 0) {
          let formatted = res.data.icons.map((icon: string) => {
            let temp = icon.split(":");
            return {
              prefix: temp[0],
              iconName: temp[1],
            };
          });
          setAllIcons(formatted);
        }
      }
    });
  }, [debouncedValue]);

  const addElem = (e: any, element: any, dnd:boolean = true) => {
    let _element = null;
    _element = {
      type: "IconEditor",
      transparency: 1,
      zIndex: 0,
      posX: 100,
      posY: 100,
      rotate: 0,
      height: 50,
      width: 50,
      attributes: {
        prefix: element?.prefix,
        title: element?.iconName,
        height: 50,
        width: 50,
        color: theme.custom.primaryDarkColor,
      },
    };

    addElement(
      _element,
      {
        left: cursorPosition.current.left,
        top: cursorPosition.current.top,
      },
      "drop",
      "",
      dnd
    );
    e.preventDefault();
  };
  const onDrag = (e: any) => {
    //TODO: On DragEnd page coordinates are coming wrong.
    // Monkey patch to force correct it. Heavy ops issue here.
    cursorPosition.current.top = e.pageY;
    cursorPosition.current.left = e.pageX;
  };
  const onDragStart = (e: any) => {
    let _bounds = e.currentTarget.getBoundingClientRect();
    mouseOrigin.current.top = e.clientY - _bounds.top;
    mouseOrigin.current.left = e.clientX - _bounds.left;
  };

  return (
    <>
      {authorized && (
        <div>
          <TextField
            onChange={onSearch}
            placeholder="Search Icons"
            id="search-icon"
            sx={{ mt: 1, height: "50px", width: "100%" }}
            inputProps={{
              style: {
                background: theme.custom.whiteColor,
                fontFamily: fontInter,
                fontSize: "14px",
                fontWeight: 500,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    height="24px"
                    width="24px"
                    src={SearchIcon}
                    alt="Search"
                  />
                </InputAdornment>
              ),
            }}
          />
          <InfiniteScroll
            dataLength={allIcons.length}
            next={allIcons}
            hasMore={false}
            loader={
              <h5 style={{ color: "grey", textAlign: "center" }}>Loading...</h5>
            }
            scrollableTarget="scrollableDiv"
            endMessage={
              <Typography
                variant="inter_500_12"
                style={{ color: "grey", textAlign: "center" }}
              >
                No more Icons to load
              </Typography>
            }
          >
            <ImageList variant="standard" cols={5} gap={2}>
              {allIcons.map((iconElement: any, index: number) => (
                <Tooltip
                  key={iconElement.iconName + "-" + index}
                  title={iconElement.iconName}
                  placement="top"
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -10],
                        },
                      },
                    ],
                  }}
                >
                  <ImageListItem>
                    <div
                      style={{ cursor: "pointer" }}
                      draggable={true}
                      onDrag={onDrag}
                      onDragStart={onDragStart}
                      onDragEnd={(e) => addElem(e, iconElement)}
                      onClick={(e) => addElem(e, iconElement, false)}
                    >
                      <Icon
                        icon={`${iconElement.prefix}:${iconElement.iconName}`}
                        height="30px"
                        width={"30px"}
                      ></Icon>
                    </div>
                  </ImageListItem>
                </Tooltip>
              ))}
            </ImageList>
          </InfiniteScroll>
        </div>
      )}
      {!authorized && (
        <AuthorizationErrorHandler
          errorMessage="You don’t have access to see the list of icons. Please reach out to
        admin to get access"
        />
      )}
    </>
  );
};

export default IconsLibrary;
