import { Quill } from "react-quill";
const ListItem = Quill.import("formats/list/item");

export class CustomListItem extends ListItem {
    optimize(context: any) {
      super.optimize(context);

      if (this.children.length === 1) {
        const child = this.children.head;
        const attributes = child.attributes;

        if (attributes && attributes.attributes.color) {
          const color = attributes.attributes.color.value(child.domNode);
          super.format("custom-color", color);
        }
      } else {
        if (this.attributes.attributes.hasOwnProperty("custom-color")) {
          super.format("custom-color", null);
        }
      }
    }
  }