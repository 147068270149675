import {
  Modal,
  Typography,
  Box,
  Backdrop,
  Grid,
  Fade,
  Button,
} from "@mui/material";
import React from "react";
import { theme } from "../../theme";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { CustomBudgetType } from "../../Types/CustomBudgetType";

type Props = {
  openModal: boolean;
  budgetType: CustomBudgetType;
  closeModal: Function;
  deleteCustomType: Function;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 360,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
  p: 4,
};

const DeleteCustomBudgetTypeModal = (props: Props) => {
  const [open, setOpen] = React.useState(props.openModal);
  const { closeModal, deleteCustomType, budgetType } = props;
  const handleClose = () => {
    setOpen(false);
    closeModal();
  };

  const deleteConfirm = () => {
    deleteCustomType(budgetType);
    handleClose();
  };
  return (
    <Modal
      sx={{ zIndex: 10000 }}
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Grid container>
            <Grid textAlign="right" item xs={12}>
              <CancelOutlinedIcon
                onClick={handleClose}
                sx={{
                  color: `${theme.custom.primaryGreyColor}`,
                  cursor: "pointer",
                }}
              />
            </Grid>
            <Grid mt={1} item sm={12}>
              <Typography
                id="modal-modal-title"
                sx={{ color: theme.custom.primaryDarkColor }}
                variant="poppins_h3_600_24"
              >
                Are you sure you want to delete '{budgetType.label}' ?
              </Typography>
            </Grid>
            <Grid mt={4} item sm={12}>
              {" "}
              <Typography
                variant="inter_400_14"
                sx={{ color: theme.custom.primaryDarkColor }}
              >
                You are about to delete the budget type {budgetType.label}. This
                action can not be undone. Are you sure that you want to
                continue?
              </Typography>
            </Grid>
            <Grid mt={4} item sm={12}>
              <Button
                onClick={deleteConfirm}
                sx={{ height: "50px" }}
                fullWidth
                variant="contained"
              >
                <Typography variant="poppins_p_600_14">Yes, Delete</Typography>
              </Button>
              <Button
                onClick={handleClose}
                sx={{ mt: 2 }}
                fullWidth
                variant="text"
              >
                <Typography variant="poppins_p_600_14">Cancel</Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
};

export default DeleteCustomBudgetTypeModal;
