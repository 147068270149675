import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { reduce } from "lodash";
import { SyntheticEvent, memo, useEffect, useState } from "react";
import {
  Action,
  ActionItem,
  OtherPermissionsMetaData,
  PermissionsDetail,
} from "../../../Types/UserManagement";
import CustomChip from "../../atoms/CustomChip";

type Props = {
  metaItem: OtherPermissionsMetaData;
  setAutoCompleteSelected: CallableFunction;
  memberDetailsForItem: PermissionsDetail | undefined;
};

function MemberOtherPermissionDropdrown({
  metaItem,
  setAutoCompleteSelected,
  memberDetailsForItem,
}: Props) {
  const [selectedValues, setSelectedValues] = useState<Action[]>([]);

  useEffect(() => {
    if (memberDetailsForItem === undefined) {
      let _selectedValues;
      _selectedValues = metaItem.default.map(
        (i) =>
          metaItem.actions.find((v) => i === v.slug) || {
            label: "",
            slug: "",
          }
      );
      handleOnChange(_selectedValues);
    } else if (memberDetailsForItem.has_full_access) {
      setSelectedValues(metaItem.actions);
    } else {
      let _selectedValues: Action[] = [];
      for (const [k, v] of Object.entries(memberDetailsForItem.actions)) {
        if (v.permitted) {
          _selectedValues.push(
            metaItem.actions.find((v) => v.slug === k) || {
              label: "",
              slug: "",
            }
          );
        }
      }
      setSelectedValues(_selectedValues);
    }
  }, [memberDetailsForItem]);

  const handleOnChange = (values: Action[]) => {
    let _actions = reduce(
      metaItem.actions,
      (result: { [x: string]: ActionItem }, item) => {
        result[item.slug] = {
          permitted: values.includes(item),
        };
        return result;
      },
      {}
    );
    setSelectedValues(values);
    setAutoCompleteSelected(metaItem, {
      has_full_access: values.length === metaItem.actions.length,
      actions: _actions,
    });
  };
  return (
    <Grid
      container
      alignItems={"center"}
      mb={1}
      justifyContent={"space-between"}
    >
      <Grid item>
        <Grid>
          <Typography variant="inter_p_600_16">
            {metaItem.permission_name}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Autocomplete
          multiple
          size={"small"}
          sx={{ width: "300px" }}
          options={metaItem.actions}
          getOptionLabel={(option) => option.label}
          value={selectedValues}
          filterSelectedOptions
          onChange={(e: SyntheticEvent, values) => handleOnChange(values)}
          renderInput={(params) => <TextField {...params} />}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => {
              if (option) {
                return (
                  <CustomChip
                    key={option.slug}
                    labelProps={{ variant: "inter_500_10" }}
                    extraProps={{
                      ...getTagProps({ index }),
                      labelText: option.label,
                      size: "small",
                    }}
                  ></CustomChip>
                );
              }
            });
          }}
        />
      </Grid>
    </Grid>
  );
}

export default memo(MemberOtherPermissionDropdrown);
