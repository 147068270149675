import { Stack, styled, CSSObject, Theme } from "@mui/material";
import Navigation from "./Navigation";
import MuiDrawer from "@mui/material/Drawer";
import React, { useEffect, useState } from "react";
import { NavigationMenuItemObject } from "../../Types/NavigationMenuItemObject";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const transaction_menus = [
  {
    id: 1,
    sub_menu: [],
    title: "Panorama",
    board_slug: "panorama",
    is_sub_board: false,
    type: "transaction",
    position: 1,
    icon_handle: "Command_Center",
    parent_board: null,
  },
  {
    id: 23,
    sub_menu: [],
    title: "Metrics",
    board_slug: "matrices",
    is_sub_board: false,
    type: "transaction",
    position: 2,
    icon_handle: "matrices",
    parent_board: null,
  },
  {
    title: "Budgeting",
    board_slug: "budgeting",
    is_sub_board: false,
    type: "transaction",
    position: 3,
    icon_handle: "Budget_management",
    parent_board: null,
    id: 20,
    sub_menu: [
      {
        id: 25,
        sub_menu: [],
        title: "Budget Management",
        board_slug: "budgets",
        is_sub_board: false,
        type: "transaction",
        position: 7,
        icon_handle: null,
        parent_board: "budgeting",
      },
      {
        id: 24,
        sub_menu: [],
        title: "Budget Monitoring",
        board_slug: "budgets-monitoring",
        is_sub_board: true,
        type: "budget",
        position: 7,
        icon_handle: null,
        parent_board: "budgeting",
      },
    ],
  },
  {
    id: 22,
    sub_menu: [],
    title: "Reporting",
    board_slug: "impact-report",
    is_sub_board: false,
    type: "transaction",
    position: 4,
    icon_handle: "Impact_Report",
    parent_board: null,
  },
  {
    id: 21,
    sub_menu: [],
    title: "Connectors List",
    board_slug: "connectors-list",
    is_sub_board: false,
    type: "transaction",
    position: 5,
    icon_handle: "Connectors_List",
    parent_board: null,
  },
];

export const Sidebar = () => {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [menus, setMenus] = useState<any>([]);
  useEffect(() => {
    getAllMenuItems();
  }, []);

  const returnNestedMenuItems = (subMenuArr: NavigationMenuItemObject[]) => {
    let orderedData = _.orderBy(subMenuArr, ["position"], ["asc"]);
    return orderedData.map((ele: NavigationMenuItemObject, index: number) => {
      return {
        icon_handle: ele.icon_handle,
        type: ele.type,
        board_slug: ele.board_slug,
        title: ele.title,
        sub_menu: [],
      };
    });
  };
  const getAllMenuItems = () => {
    let finalArr = [...transaction_menus];
    let orderedData = _.orderBy(finalArr, ["position"], ["asc"]);

    let menuResponse = orderedData.map(
      (item: any, index: number) => {
        if (item.sub_menu.length > 0) {
          return {
            icon_handle: item.icon_handle,
            type: item.type,
            board_slug: item.board_slug,
            title: item.title,
            sub_menu: returnNestedMenuItems(item.sub_menu),
          };
        } else {
          return {
            icon_handle: item.icon_handle,
            type: item.type,
            board_slug: item.board_slug,
            title: item.title,
            sub_menu: [],
          };
        }
      }
    );
    setMenus(menuResponse);
  };

  const openedMixin = (theme: Theme): CSSObject => ({
    width: 400,
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.enteringScreen,
      easing: theme.transitions.easing.easeIn,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create("all", {
      duration: theme.transitions.duration.leavingScreen,
      easing: theme.transitions.easing.easeIn,
    }),
    overflowX: "hidden",
    width: `100px`,
    [theme.breakpoints.up("sm")]: {
      width: `100px`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => {
    return {
      width: 400,
      flexShrink: 0,
      whiteSpace: "nowrap",
      boxSizing: "border-box",
      ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      }),
      ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      }),
    };
  });

  const onMenuItemClicked = () => {
    setOpenDrawer(false);
  };

  const onHoverMenuItem = () => {
    setOpenDrawer(true);
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      sx={{ height: "100vh", width: "100px", boxShadow: 2 }}
    >
      <Drawer
        onMouseLeave={onMenuItemClicked}
        variant="permanent"
        hideBackdrop={true}
        sx={{ position: "absolute", top: 0, left: 0, zIndex: 1800 }}
        anchor={"left"}
        open={openDrawer}
      >
        <Navigation
          onMenuHoverOut={onMenuItemClicked}
          onHoverMenuItem={onHoverMenuItem}
          menuItemClicked={onMenuItemClicked}
          isExpanded={openDrawer}
          menus={menus}
        />
      </Drawer>
    </Stack>
  );
};
