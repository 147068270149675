import React from "react";

import { theme } from "../../theme";
import { BudgetItemData } from "../../Types/QuickBudgetItemTypes";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";

type Props = {
  budgetData: any;
  value: number;
};

const BudgetCircularProgressBar = (props: Props) => {
  const { budgetData, value } = props;

  const getColor = (ele: BudgetItemData) => {
    let { actual, budgeted_to_date, is_credit_account, total_budget } = ele;
    if (is_credit_account) {
      return actual < budgeted_to_date
        ? "red"
        : actual < total_budget
        ? "orange"
        : "green";
    } else {
      return actual < budgeted_to_date
        ? "green"
        : actual < total_budget
        ? "orange"
        : "red";
    }
  };

  return (
    <>
      <CircularProgressbar
        strokeWidth={18}
        background
        styles={buildStyles({
          pathColor: getColor(budgetData),
          trailColor: theme.custom.menuDividerColor,
          backgroundColor: "#fff",
        })}
        value={value}
      />
    </>
  );
};

export default BudgetCircularProgressBar;
