import React, { useEffect, useState } from "react";
import {
  TableRow,
  TableCell,
  Grid,
  TextField,
  Button,
  Autocomplete,
  styled,
  Stack,
  Typography,
  Theme,
  InputAdornment,
  IconButton,
  Tooltip,
} from "@mui/material";
import { theme, fontInter } from "../../theme";

import CustomChip from "../atoms/CustomChip";
import { ProgramFilterItem } from "../../Types/ProgramFilterItem";
import AddIcon from "@mui/icons-material/Add";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { SxProps } from "@mui/system";
import { BudgetItem } from "../../Types/BudgetDetailsItem";
import { useAppDispatch } from "../../store/hook";
import { showMessage } from "../../store/error-handler-store";
import NumberTextFieldComponent from "../atoms/NumberTextFieldComponent";
import { concat, differenceBy, orderBy, reduce } from "lodash";
import EditBudgetValueIcon from "../../assets/Line_arrows.png";

interface Props {
  accounts?: ProgramFilterItem[];
  onAdd?: any;
  extraSX?: SxProps<Theme>;
  values?: BudgetItem;
  editMode?: boolean;
  updateCancel?: any;
  className?: string;
  tableData?: BudgetItem[];
  position: number;
  is_non_ratable?: boolean;
}

const AddBudgetValuesForm: React.FC<Props> = (data) => {
  let updateCancel = data.updateCancel;
  let position = data.position;
  let selectedData = data.tableData ? data.tableData : [];
  const dispatch = useAppDispatch();
  const allAccounts = data.accounts || [];
  const is_non_ratable = data.is_non_ratable || false;

  const optionsFormatter = (opts: any) => {
    return (
      opts &&
      opts.map((ele: any) => {
        return {
          id: ele["ref_account_id"],
          name: ele["ref_account_name"],
        };
      })
    );
  };
  const INIT_STATE = {
    position: position,
    category: data.values?.category_name || "",
    accounts: optionsFormatter(data.values?.accounts) || [],
    budget: data.values?.amount || "",
    id: data.values?.id || 0,
    remarks: data.values?.remarks || "",
    is_non_ratable: data,
  };

  const AutoCompleteComponent = styled(Autocomplete)({
    ".MuiAutocomplete-inputRoot": {
      color: theme.custom.dropdownPrimaryColor,
      fontWeight: 500,
      borderRadius: 8,
      fontSize: "14px",
      fontFamily: fontInter,
    },
  });

  let onAdd = data.onAdd;
  let editMode = data.editMode || false;
  const [tableData, setTableData] = useState(INIT_STATE);
  const [errMsg, setErrMsg] = useState<string>("");
  const [accounts, setAccounts] = useState(allAccounts as any);

  useEffect(() => {
    let selectedAccounts = reduce(
      selectedData,
      (prev: any, next: any) => {
        return concat(prev, next.accounts);
      },
      []
    );

    selectedAccounts = selectedAccounts.map((ele: any) => {
      return {
        name: ele.ref_account_name,
        id: ele.ref_account_id,
      };
    });

    setAccounts(
      orderBy(differenceBy(accounts, selectedAccounts, "id"), ["name"], ["asc"])
    );
  }, [selectedData]);

  const renderOption = (props: any, option: any) => {
    return (
      <Grid item component="li" {...props}>
        <Stack direction="column">
          <Typography py={1} variant="inter_p_500_14">
            {option?.name}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  const handleDelete = (index: number, option: any) => {
    let tempData: any = { ...tableData };
    setTableData((prev) => {
      return {
        ...prev,
        accounts: tempData.accounts.filter(
          (ele: ProgramFilterItem, idx: number) => idx !== index
        ),
      };
    });
    let _accounts = accounts;

    let exists = _accounts.find((ele: any) => {
      if (ele.id === option.id) {
        return ele;
      }
    });

    if (!exists) {
      _accounts.push(option);
      setAccounts(_accounts);
    }
  };

  const handleChangeAutocomplete = (option: any) => {
    setTableData((prev) => {
      return { ...prev, accounts: option };
    });
  };

  const handleChange = (key: string, event: React.ChangeEvent<any>) => {
    if (key === "category") {
      let regex = /^[\w \_\-\(\)\s]+$/gi;
      if (!regex.test(event.target.value)) {
        if (event.target.value.length > 0) {
          setErrMsg(
            "Only allowed character alphanumeric, space, -, _, (, ) for Budget Name"
          );
        }
      } else {
        setTableData((prev) => {
          return { ...prev, [key]: event.target.value };
        });
        setErrMsg("");
      }
    }

    setTableData((prev) => {
      return { ...prev, [key]: event.target.value };
    });
  };

  const getAutocompleteValue = (val: any) => {
    return val.accounts;
  };

  const addToTable = () => {
    if (tableData.category === "") {
      dispatch(showMessage({ message: "Please Enter Category" }));
      return;
    }
    if (tableData.accounts.length === 0) {
      dispatch(showMessage({ message: "Please select atleast one account" }));
      return;
    }
    if (tableData.budget === "") {
      dispatch(showMessage({ message: "Please Enter Budget" }));
      return;
    }
    if (
      tableData.category !== "" &&
      tableData.accounts.length > 0 &&
      tableData.budget !== ""
    ) {
      onAdd(tableData);
    }
  };

  const updateData = () => {
    let temp: any = { ...tableData };
    temp["edit"] = true;
    onAdd(temp);
  };

  const onCancel = () => {
    updateCancel(data.values?.id);
  };
  return (
    <React.Fragment>
      <TableRow
        sx={{
          ...(data?.extraSX || {}),
        }}
        key={"edit_mode"}
        className={data.className}
      >
        <TableCell component="th" scope="row">
          <Grid container>
            <Grid item sm={12}>
              <TextField
                placeholder="Category"
                inputProps={{
                  style: {
                    background: theme.custom.whiteColor,
                    fontFamily: fontInter,
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                }}
                className="curvy-borders"
                onChange={(e) => handleChange("category", e)}
                value={tableData.category}
                size="small"
                fullWidth
                id="category"
              />
            </Grid>
          </Grid>
          <Typography
            variant="inter_p_500_10"
            sx={{ color: theme.custom.errorColor }}
          >
            {errMsg}
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid container>
            <Grid item sm={12}>
              <AutoCompleteComponent
                style={{
                  minWidth: 350,
                }}
                size="small"
                disableCloseOnSelect
                disableClearable
                multiple={true}
                limitTags={2}
                fullWidth
                id={"autocomplete"}
                options={accounts ? accounts : []}
                renderOption={(props, option) => renderOption(props, option)}
                getOptionLabel={(option: any) => {
                  return option ? option.name : "";
                }}
                filterSelectedOptions
                onChange={(e: any, v: any, r: any, opt: any) =>
                  handleChangeAutocomplete(v)
                }
                value={getAutocompleteValue(tableData)}
                renderInput={(params) => (
                  <TextField
                    placeholder="Accounts"
                    {...params}
                    InputLabelProps={{
                      shrink: false,
                      style: {
                        marginLeft: 7,
                      },
                    }}
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  return tagValue.map((option: any, index) => (
                    <CustomChip
                      extraSX={{
                        background: theme.CustomChip.backgroundColor,
                        border: `1px solid ${theme.custom.primaryDarkColor}`,
                        marginRight: "2px",
                        marginBottom: "5px",
                      }}
                      extraProps={{
                        onDelete: () => {
                          handleDelete(index, option);
                        },
                        labelText: option?.name,
                      }}
                      closeIconProps={{
                        fill: theme.CustomChip.closeIconColor,
                        fontSize: "18px !important",
                        marginRight: "12px !important",
                      }}
                    />
                  ));
                }}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid container>
            <Grid item sm={12}>
              <TextField
                placeholder="Budget"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography
                        sx={{ color: theme.custom.primaryDarkColor }}
                        variant="inter_p_500_14"
                      >
                        $
                      </Typography>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Tooltip
                        title={
                          editMode && is_non_ratable
                            ? "You can edit the non-ratable split after saving this"
                            : ""
                        }
                      >
                        <img
                          src={EditBudgetValueIcon}
                          height={20}
                          width={20}
                          alt="Edit budget value"
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                  inputComponent: NumberTextFieldComponent,
                }}
                inputProps={{
                  decimal: 2,
                  min: 0,
                  style: {
                    fontFamily: fontInter,
                    fontSize: "14px",
                    fontWeight: 500,
                    background: theme.custom.whiteColor,
                  },
                }}
                disabled={editMode && is_non_ratable}
                className="curvy-borders"
                value={tableData.budget}
                onChange={(e) => handleChange("budget", e)}
                size="small"
                fullWidth
                id="budget-value"
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid container>
            <Grid item sm={12}>
              <TextField
                placeholder="Remarks"
                inputProps={{
                  maxLength: 1000,
                  style: {
                    background: theme.custom.whiteColor,
                    fontFamily: fontInter,
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                }}
                className="curvy-borders"
                onChange={(e) => handleChange("remarks", e)}
                value={tableData.remarks}
                size="small"
                fullWidth
                id="remarks"
              />
            </Grid>
          </Grid>
        </TableCell>
        {!editMode && (
          <TableCell component="th" scope="row">
            <Button
              disabled={
                tableData.category !== "" &&
                tableData.accounts.length > 0 &&
                tableData.budget !== "" &&
                errMsg.length === 0
                  ? false
                  : true
              }
              sx={{ background: theme.custom.whiteColor }}
              onClick={addToTable}
              variant="outlined"
            >
              <AddIcon />
            </Button>
          </TableCell>
        )}
        {editMode && (
          <TableCell width={150} component="th" scope="row">
            <Grid spacing={2} container>
              <Grid item xs={6}>
                <Button
                  sx={{ background: theme.custom.whiteColor }}
                  onClick={updateData}
                  variant="outlined"
                >
                  <DoneIcon />
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{ background: theme.custom.whiteColor }}
                  onClick={onCancel}
                  variant="outlined"
                >
                  <CloseIcon />
                </Button>
              </Grid>
            </Grid>
          </TableCell>
        )}
        <TableCell></TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default AddBudgetValuesForm;
