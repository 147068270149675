import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "../../assets/search.png";
import { fontInter, theme } from "../../theme";

type Props = {
  onApply: Function;
  filters: any;
  setFilters: Function;
  audienceOptions: any[];
  purposeOptions: any[];
};

function TemplateListFilter({
  onApply,
  filters,
  setFilters,
  audienceOptions,
  purposeOptions,
}: Props) {
  return (
    <Box
      pt={3}
      sx={{
        background: theme.custom.filterBackgroundColor,
        height: "auto",
        width: "100%",
        border: 1,
        borderColor: theme.custom.filterBorderColor,
        borderRadius: "6px",
      }}
    >
      <Box mb={2} mx={1} px={2}>
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item justifyContent={"flex-start"} xs={9.5}>
            <Grid container justifyContent={"space-between"}>
              <Grid xs={4.5}>
                <TextField
                  placeholder="Search by title"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <img height="24" src={SearchIcon} alt="Search" />
                      </InputAdornment>
                    ),
                  }}
                  inputProps={{
                    style: {
                      height: "40px",
                      fontFamily: fontInter,
                      fontSize: "14px",
                      fontWeight: 500,
                      background: theme.custom.whiteColor,
                    },
                  }}
                  value={filters?.search_query}
                  onChange={(event) => {
                    setFilters({
                      ...filters,
                      search_query: event.target.value,
                    });
                  }}
                  size="small"
                  fullWidth
                  id="budget-value"
                />
              </Grid>
              <Grid xs={3.5}>
                <TextField
                  select
                  fullWidth
                  label="Audience"
                  value={filters.audience}
                  onChange={(e) =>
                    setFilters({ ...filters, audience: e.target.value })
                  }
                >
                  <MenuItem key={""} value={""}>
                    <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                      -----
                    </Typography>
                  </MenuItem>
                  {audienceOptions.map((meta: any) => {
                    return (
                      <MenuItem
                        key={meta.option_value}
                        value={meta.option_value}
                      >
                        <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                          {meta.option_label}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
              <Grid xs={3.5}>
                <TextField
                  fullWidth
                  select
                  label="Purpose"
                  value={filters.purpose}
                  onChange={(e) =>
                    setFilters({ ...filters, purpose: e.target.value })
                  }
                >
                  <MenuItem key={""} value={""}>
                    <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                      -----
                    </Typography>
                  </MenuItem>
                  {purposeOptions.map((meta: any) => {
                    return (
                      <MenuItem
                        key={meta.option_value}
                        value={meta.option_value}
                      >
                        <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                          {meta.option_label}
                        </Typography>
                      </MenuItem>
                    );
                  })}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
          <Grid item justifyContent={"flex-end"} xs={2.5}>
            <Button
              sx={{
                height: "56px",
                marginLeft: 2,
                marginRight: 1,
              }}
              fullWidth
              variant="contained"
              onClick={() => onApply(filters)}
            >
              <Typography variant="poppins_p_600_14"> Apply</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default TemplateListFilter;
