import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { theme } from "../../theme";
import LoaderIcon from "../../assets/loader.png";
import Pdf from "../../assets/pdf.png";
import File from "../../assets/fi-rr-document.png";
import Open from "../../assets/fi-rr-arrow-small-right.png";
import { dateFormatter } from "../../utils/dataFormatters";
import Download from "../../assets/fi-rr-download 1.png";
import BudgetDocumentsMenuItem from "./BudgetDocumentsMenuItem";
import React from "react";

interface Props {
  tableData: Array<object>;
  isLoading: boolean;
  setShowActionId: Function;
  viewDocument: Function;
  downloadDocument: Function;
  deleteDocument: Function;
  showActionId: string;
}

const BudgetDocumentsTable = (props: Props) => {
  const {
    tableData,
    isLoading,
    setShowActionId,
    viewDocument,
    showActionId,
    downloadDocument,
    deleteDocument,
  } = props;

  return (
    <>
      {tableData.length ? (
        <Grid container mt={3}>
          <Grid mt={3} xs={12} item>
            <TableContainer
              sx={{
                position: "relative",
                border: `1px solid ${theme.dataTableColors.borderColor}`,
                borderRadius: "5px",
              }}
            >
              <Table style={{ overflow: "none", minWidth: 1000 }}>
                {isLoading && tableData.length === 0 && (
                  <caption>
                    <Box
                      sx={{
                        height: 100,
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <Grid
                        textAlign={"center"}
                        className="show-loader"
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item sm={12} textAlign={"center"}>
                          <img
                            src={LoaderIcon}
                            height="100"
                            width="100"
                            alt="Loader"
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </caption>
                )}
                {!isLoading && tableData.length > 0 && (
                  <TableBody sx={{ overflow: "none" }}>
                    {tableData.map((row: any) => (
                      <TableRow
                        key={row.uid}
                        onMouseEnter={() => setShowActionId(row.uid)}
                        onMouseLeave={() => setShowActionId("")}
                      >
                        <TableCell
                          align={"left"}
                          onClick={() => viewDocument(row.uid)}
                        >
                          <Typography
                            variant="inter_400_14"
                            sx={{ color: theme.custom.primaryDarkColor }}
                          >
                            <img
                              height={20}
                              width={20}
                              alt="File Type"
                              src={row.type == "pdf" ? Pdf : File}
                            />
                          </Typography>
                          <Typography
                            title={row.name}
                            variant="inter_400_14"
                            style={{
                              maxWidth: "200px",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              display: "inline-block",
                            }}
                            sx={{
                              color: theme.custom.primaryDarkColor,
                              paddingLeft: 2,
                            }}
                          >
                            {row.name}
                          </Typography>
                          {showActionId == row.uid ? (
                            <Typography
                              variant="inter_400_14"
                              sx={{
                                color: theme.custom.primaryDarkColor,
                                paddingLeft: 2,
                                verticalAlign: "middle",
                              }}
                            >
                              <img
                                style={{ cursor: "pointer" }}
                                height={20}
                                width={30}
                                alt="Open"
                                src={Open}
                              />
                            </Typography>
                          ) : (
                            <></>
                          )}
                        </TableCell>
                        <TableCell align={"right"}>
                          {" "}
                          <Typography
                            variant="inter_400_14"
                            sx={{ color: theme.custom.primaryDarkColor }}
                          >
                            {dateFormatter(row.created_at)}
                          </Typography>
                        </TableCell>
                        <TableCell align={"right"}>
                          {" "}
                          <Typography
                            variant="inter_400_14"
                            sx={{ color: theme.custom.primaryDarkColor }}
                          >
                            {row.user}
                          </Typography>
                        </TableCell>
                        <TableCell align={"right"}>
                          <Typography
                            variant="inter_400_14"
                            sx={{ color: theme.custom.primaryDarkColor }}
                          >
                            <img
                              onClick={() => downloadDocument(row.uid)}
                              style={{ cursor: "pointer" }}
                              height={20}
                              width={20}
                              alt="Download"
                              src={Download}
                            />
                          </Typography>
                          <BudgetDocumentsMenuItem
                            uid={row.uid}
                            deleteDocument={deleteDocument}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                )}
                {!isLoading && tableData.length === 0 && (
                  <caption>
                    <Box
                      sx={{
                        height: 100,
                        width: "100%",
                        position: "relative",
                      }}
                    >
                      <Grid
                        textAlign={"center"}
                        className="show-loader"
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Typography
                          variant="inter_p_400_16"
                          sx={{ color: theme.custom.primaryDarkColor }}
                        >
                          No records found.
                        </Typography>
                      </Grid>
                    </Box>
                  </caption>
                )}
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      ) : (
        ""
      )}
    </>
  );
};

export default React.memo(BudgetDocumentsTable);
