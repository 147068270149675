import { Alert, Snackbar } from "@mui/material";
import delay from "lodash/delay";
import React, { useEffect } from "react";
import { useAppSelector } from "../../store/hook";
import { theme } from "../../theme";

export default function ToastMessage() {
  const [open, setOpen] = React.useState(false);

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setOpen(false);
  };

  let errorState: any = useAppSelector((state) => {
    return state.errorHandler.error;
  });

  useEffect(() => {
    setOpen(true);
    delay(() => setOpen(false), 8000);
  }, [errorState]);

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        open={open}
        autoHideDuration={8000}
        onClose={handleClose}
        sx={{ zIndex: theme.zIndex.drawer + 10001 }}
        {...errorState.extraProps}
      >
        <Alert
          onClose={handleClose}
          severity={errorState.variant ? errorState.variant : "error"}
        >
          {errorState.message}
        </Alert>
      </Snackbar>
    </React.Fragment>
  );
}
