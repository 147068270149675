import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Modal,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { theme } from "../../../theme";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import SearchIcon from "../../../assets/search.png";
import BackIcon from "../../../assets/back_button.png";
import {
  getAllWorkbookSheetsColumn,
  getAllWorkspaces,
  searchByColumn,
} from "../../../services/kpi-builder-service";
import { useDebounce } from "react-use";
import { SelectedDataSourceObject } from "../../../Types/SelectedDataSourceObject";
import MoveDownArrowIcon from "../../../assets/movedown.png";
import InfiniteScroll from "react-infinite-scroll-component";

type Props = {
  updateDataSourceSelection: Function;
  savedData?: SelectedDataSourceObject;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  height: 600,
  bgcolor: "#fff",
  outline: "none",
  boxShadow: 24,
  p: 4,
  borderRadius: "6px",
};

const INIT_DATA_SOURCE = {
  workbookId: "",
  workbookName: "",
  sheetId: "",
  sheetName: "",
  columnId: "",
  columnName: "",
  thumbnail: "",
};

const SelectDataSource = (props: Props) => {
  const { updateDataSourceSelection, savedData } = props;
  const [open, setOpen] = useState(false);
  const [allWorkSpaces, setAllWorkSpaces] = useState([] as any);
  const [allSheets, setAllSheets] = useState([] as any);
  const [allColumns, setAllColumns] = useState([] as any);
  const [selectedDataSource, setSelectedDataSource] = useState(
    INIT_DATA_SOURCE as SelectedDataSourceObject
  );
  const [searchStr, setSearchStr] = useState<string>("");
  const [debouncedValue, setDebouncedValue] = useState(searchStr);
  const [searchResults, setSearchResults] = useState([] as any);
  const [hasMoreWorkbooks, setHasMoreWorkbooks] = useState(false);
  const [loading, setLoading] = useState(true);

  let pageRef = useRef({ page: 1 });

  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(searchStr);
    },
    600,
    [searchStr]
  );

  useEffect(() => {
    if (savedData) {
      setSelectedDataSource(savedData);
    }
  }, [savedData]);

  const handleOpen = () => {
    setOpen(true);
    if (
      selectedDataSource.columnId &&
      selectedDataSource.sheetId &&
      selectedDataSource.workbookId
    ) {
      listAllWorkSpaces(1);
      getAllSheetsForWorkbook(selectedDataSource.workbookId, false);
      let obj = {
        workbook: selectedDataSource.workbookId,
        sheet: selectedDataSource.sheetId,
        filter_type: "column",
      };
      getAllColumns(obj);
    }
  };
  const handleClose = () => {
    setOpen(false);
    if (savedData) {
      setSelectedDataSource(savedData);
    } else {
      setSelectedDataSource(INIT_DATA_SOURCE);
    }
    setAllWorkSpaces([]);
    setAllSheets([]);
    setAllColumns([]);
    setSearchResults([]);
  };

  useEffect(() => {
    if (open) {
      listAllWorkSpaces(1);
    }
  }, [open]);

  useEffect(() => {
    searchColumn();
  }, [debouncedValue]);

  const listAllWorkSpaces = (pageNumber: number) => {
    getAllWorkspaces(pageNumber).then((res) => {
      if (res.status === 200) {
        if (pageNumber === 1) {
          setAllWorkSpaces(res.data.results);
        } else {
          setAllWorkSpaces([...allWorkSpaces, ...res.data.results]);
        }
        setLoading(false);
        if (res.data.next) {
          pageRef.current.page = pageNumber + 1;
          setHasMoreWorkbooks(true);
          return;
        }
        setHasMoreWorkbooks(false);
      }
    });
  };

  const searchColumn = () => {
    if (searchStr) {
      let obj = { column_name: searchStr };
      searchByColumn(obj).then((res) => {
        if (res.status === 200) {
          setSearchResults(formatSearch(res.data.data));
        }
      });
    }
  };

  const formatSearch = (data: any) => {
    return data.map((ele: any, index: number) => {
      return {
        value: index,
        label:
          ele.workbook_label +
          " > " +
          ele.sheet_label +
          " > " +
          ele.column_label,
        obj: ele,
      };
    });
  };

  const getAllSheetsForWorkbook = (
    uid: string,
    isSearch: boolean = false,
    filterObject?: any
  ) => {
    let obj = {
      filter_type: "table",
      workbook: uid,
      sheet: "null",
    };

    getAllWorkbookSheetsColumn(obj).then((res) => {
      if (res.status === 200) {
        setAllSheets(res.data.data);
        if (isSearch) {
          getAllColumns(
            {
              workbook: filterObject["obj"]["workbook"],
              sheet: filterObject["obj"]["sheet"],
              filter_type: "column",
            },
            true,
            filterObject
          );
        }
      }
    });
  };

  const getAllColumns = (
    obj: any,
    isSearch: boolean = false,
    filterObj?: any
  ) => {
    getAllWorkbookSheetsColumn(obj).then((res) => {
      if (res.status === 200) {
        setAllColumns(res.data.data);
      }
    });
  };

  const onWorkbookSelected = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    workbook: any
  ) => {
    setAllSheets([]);
    setAllColumns([]);
    let temp = { ...selectedDataSource };
    temp["workbookId"] = workbook.uid;
    temp["workbookName"] = workbook.name;
    temp["thumbnail"] = workbook.connector_logo;
    setSelectedDataSource(temp);
    getAllSheetsForWorkbook(workbook.uid);
  };

  const onSheetSelected = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    item: any
  ) => {
    let temp = { ...selectedDataSource };
    temp["sheetId"] = item.value;
    temp.sheetName = item.label;
    setSelectedDataSource(temp);
    getAllColumns({
      workbook: selectedDataSource.workbookId,
      sheet: item.value,
      filter_type: "column",
    });
  };

  const populate = (obj: any) => {
    let temp = { ...selectedDataSource };
    temp = {
      workbookId: obj["obj"]["workbook"],
      workbookName: obj["obj"]["workbook_label"],
      sheetId: obj["obj"]["sheet"],
      sheetName: obj["obj"]["sheet_label"],
      columnId: obj["obj"]["column"],
      columnName: obj["obj"]["column_label"],
      thumbnail: obj["obj"]["connector_logo"],
    };

    setSelectedDataSource(temp);

    getAllSheetsForWorkbook(obj["obj"]["workbook"], true, obj);
  };

  const renderOption = (props: any, option: any) => {
    return (
      <div
        style={{
          background: option.obj.needs_relinking ? "#eee" : "#fff",
          pointerEvents: option.obj.needs_relinking ? "none" : "all",
        }}
        onClick={() => populate(option)}
      >
        <Grid item component="li" {...props}>
          <Stack direction="row" alignItems={"center"}>
            <img
              style={{ marginRight: 10 }}
              src={option.obj["connector_logo"]}
              height={25}
              alt="connector_Logo"
            ></img>
            <Typography py={1} variant="inter_p_500_14">
              {option?.label}
            </Typography>
          </Stack>
        </Grid>
      </div>
    );
  };

  const onColumnSelected = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    item: any
  ) => {
    let temp = { ...selectedDataSource };
    temp["columnId"] = item.value;
    temp["columnName"] = item.label;
    setSelectedDataSource(temp);
  };

  const onSearch = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      setSearchStr("");
      setSearchResults([]);
      setSelectedDataSource(INIT_DATA_SOURCE);
      return;
    }
    if (event.target.value) {
      setSearchStr(event.target.value);
    }
  };

  const onDataSourceSelect = () => {
    updateDataSourceSelection(selectedDataSource);
    setOpen(false);
  };

  const loadMore = () => {
    listAllWorkSpaces(pageRef.current.page);
  };

  return (
    <>
      <Button
        fullWidth
        variant="outlined"
        sx={{ height: 40, borderColor: "#ccc" }}
        onClick={() => handleOpen()}
      >
        <Grid alignItems={"center"} justifyContent={"space-between"} container>
          <Grid textAlign={"left"} item sm={11}>
            <Stack direction="row" alignItems={"center"}>
              {selectedDataSource.thumbnail && (
                <img
                  src={selectedDataSource.thumbnail}
                  style={{ marginRight: 10 }}
                  height={25}
                  alt="Connector logo"
                />
              )}
              <Typography
                variant="inter_p_500_14"
                sx={{ color: theme.custom.primaryDarkColor }}
                noWrap
              >
                {selectedDataSource.columnName &&
                  selectedDataSource.workbookName &&
                  selectedDataSource.sheetName &&
                  selectedDataSource.workbookName +
                    " > " +
                    selectedDataSource.sheetName +
                    " > " +
                    selectedDataSource.columnName}
                {!selectedDataSource.columnName &&
                  !selectedDataSource.workbookName &&
                  !selectedDataSource.sheetName &&
                  "Select Datasource"}
              </Typography>
            </Stack>
          </Grid>
          <Grid item sm={1}>
            <img
              src={MoveDownArrowIcon}
              style={{ marginTop: 8 }}
              alt="Arrrow"
            />
          </Grid>
        </Grid>
      </Button>

      <Modal
        sx={{ position: "absolute", zIndex: 2500 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid container justifyContent={"space-between"}>
            <Grid item>
              <Typography
                id="modal-modal-title"
                variant="poppins_h3_600_24"
                sx={{ color: theme.custom.primaryDarkColor }}
                component="h2"
              >
                Select data source
              </Typography>
            </Grid>
            <Grid item>
              <CancelOutlinedIcon
                onClick={handleClose}
                sx={{
                  color: `${theme.custom.primaryGreyColor}`,
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
          <Box mt={1}>
            <Autocomplete
              fullWidth
              freeSolo
              id="Search"
              disableClearable
              options={
                searchResults && searchResults.length > 0 ? searchResults : []
              }
              renderOption={(props, option) => renderOption(props, option)}
              renderInput={(params: any) => (
                <TextField
                  onChange={onSearch}
                  {...params}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    startAdornment: (
                      <InputAdornment position="start">
                        <img
                          style={{ marginLeft: 5 }}
                          height="18px"
                          width="18px"
                          src={SearchIcon}
                          alt="Search"
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          </Box>

          <Box>
            <Grid mt={3} sx={{ height: "100%", width: "100%" }} container>
              <Grid
                sx={{
                  borderRight: `1px solid ${theme.custom.menuDividerColor}`,
                  height: 400,
                }}
                item
                sm={4}
              >
                <Typography sx={{ marginLeft: 2 }} variant="inter_p_600_14">
                  Workbook
                </Typography>

                <Box
                  id="worbook-scrollable"
                  style={{ height: 350, overflow: "auto" }}
                >
                  <InfiniteScroll
                    dataLength={allWorkSpaces.length}
                    next={loadMore}
                    hasMore={hasMoreWorkbooks}
                    loader={
                      <p style={{ textAlign: "center" }}>
                        <Typography
                          sx={{ color: theme.custom.primaryGreyColor }}
                          variant="inter_p_600_16"
                        >
                          Loading...
                        </Typography>
                      </p>
                    }
                    scrollableTarget="worbook-scrollable"
                    endMessage={
                      <p style={{ textAlign: "center" }}>
                        {loading === false && hasMoreWorkbooks === false && (
                          <Typography
                            sx={{ color: theme.custom.primaryGreyColor }}
                            variant="inter_p_600_16"
                          >
                            No more workbooks to load.
                          </Typography>
                        )}
                      </p>
                    }
                  >
                    <List
                      sx={{
                        // selected and (selected + hover) states
                        "&& .Mui-selected, && .Mui-selected:hover": {
                          bgcolor: theme.CustomChip.closeIconColor,
                        },

                        "& .MuiListItemButton-root:focus": {
                          bgcolor: theme.CustomChip.closeIconColor,
                        },
                      }}
                    >
                      {allWorkSpaces &&
                        allWorkSpaces.length > 0 &&
                        allWorkSpaces.map((item: any, index: number) => {
                          return (
                            <Tooltip placement="top" title={item.name}>
                              <ListItemButton
                                sx={{
                                  borderRadius: "6px",
                                  background: item.needs_relinking
                                    ? "#ccc"
                                    : "#fff",
                                  pointerEvents: item.needs_relinking
                                    ? "none"
                                    : "all",
                                }}
                                selected={
                                  selectedDataSource.workbookId === item.uid
                                }
                                autoFocus={
                                  selectedDataSource.workbookId === item.uid
                                }
                                onClick={(event) =>
                                  onWorkbookSelected(event, index, item)
                                }
                              >
                                <img
                                  alt="Connector_logo"
                                  src={item.connector_logo}
                                  height={25}
                                  style={{ marginRight: "10px" }}
                                />
                                <ListItemText
                                  primary={
                                    <Typography noWrap variant="inter_500_12">
                                      {item.name}
                                    </Typography>
                                  }
                                />
                              </ListItemButton>
                            </Tooltip>
                          );
                        })}
                    </List>
                  </InfiniteScroll>
                </Box>
              </Grid>
              <Grid
                sx={{
                  borderRight: `1px solid ${theme.custom.menuDividerColor}`,
                  height: 400,
                }}
                item
                sm={4}
              >
                <Typography sx={{ marginLeft: 2 }} variant="inter_p_600_14">
                  Sheet
                </Typography>
                {allSheets && allSheets.length === 0 && (
                  <Box
                    textAlign={"center"}
                    sx={{ position: "relative", top: "30%" }}
                    px={4}
                  >
                    <img
                      src={BackIcon}
                      alt="Arrow"
                      height="15px"
                      width="24px"
                      style={{ marginBottom: 5, filter: "contrast(0%)" }}
                    />
                    <Typography
                      variant="inter_500_12"
                      sx={{ color: theme.custom.primaryGreyColor }}
                    >
                      Select the Workspace to see Sheets
                    </Typography>
                  </Box>
                )}

                <Box pl={2} pr={3} sx={{ maxHeight: 350, overflow: "auto" }}>
                  <List
                    sx={{
                      // selected and (selected + hover) states
                      "&& .Mui-selected, && .Mui-selected:hover": {
                        bgcolor: theme.CustomChip.closeIconColor,
                      },
                      // hover states
                      // "& .MuiListItemButton-root:hover": {
                      //   bgcolor: theme.custom.menuDividerColor,
                      // },
                      "& .MuiListItemButton-root:focus": {
                        bgcolor: theme.CustomChip.closeIconColor,
                      },
                    }}
                    component="nav"
                  >
                    {allSheets &&
                      allSheets.length > 0 &&
                      allSheets.map((item: any, index: number) => {
                        return (
                          <Tooltip placement="top" title={item.label}>
                            <ListItemButton
                              sx={{ borderRadius: "6px" }}
                              selected={
                                selectedDataSource.sheetId === item.value
                              }
                              autoFocus={
                                selectedDataSource.sheetId === item.value
                              }
                              onClick={(event) =>
                                onSheetSelected(event, index, item)
                              }
                            >
                              <ListItemText
                                primary={
                                  <Typography noWrap variant="inter_500_12">
                                    {item.label}
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          </Tooltip>
                        );
                      })}
                  </List>
                </Box>
              </Grid>
              <Grid item sm={4}>
                <Typography sx={{ marginLeft: 2 }} variant="inter_p_600_14">
                  Columns
                </Typography>

                {allColumns && allColumns.length === 0 && (
                  <Box
                    textAlign={"center"}
                    sx={{ position: "relative", top: "30%" }}
                    px={4}
                  >
                    <img
                      src={BackIcon}
                      alt="Arrow"
                      height="15px"
                      width="24px"
                      style={{ marginBottom: 5, filter: "contrast(0%)" }}
                    />
                    <Typography
                      variant="inter_500_12"
                      sx={{ color: theme.custom.primaryGreyColor }}
                    >
                      Select the Sheet to see Columns
                    </Typography>
                  </Box>
                )}
                <Box pl={2} pr={3} sx={{ maxHeight: 350, overflow: "auto" }}>
                  <List
                    sx={{
                      // selected and (selected + hover) states
                      "&& .Mui-selected, && .Mui-selected:hover": {
                        bgcolor: theme.CustomChip.closeIconColor,
                      },
                      // hover states
                      // "& .MuiListItemButton-root:hover": {
                      //   bgcolor: theme.custom.menuDividerColor,
                      // },
                      "& .MuiListItemButton-root:focus": {
                        bgcolor: theme.CustomChip.closeIconColor,
                      },
                    }}
                    component="nav"
                  >
                    {allColumns &&
                      allColumns.length > 0 &&
                      allColumns.map((item: any, index: number) => {
                        return (
                          <Tooltip placement="top" title={item.label}>
                            <ListItemButton
                              sx={{ borderRadius: "6px" }}
                              selected={
                                selectedDataSource.columnId === item.value
                              }
                              autoFocus={
                                selectedDataSource.columnId === item.value
                              }
                              onClick={(event) =>
                                onColumnSelected(event, index, item)
                              }
                            >
                              <ListItemText
                                primary={
                                  <Typography noWrap variant="inter_500_12">
                                    {item.label}
                                  </Typography>
                                }
                              />
                            </ListItemButton>
                          </Tooltip>
                        );
                      })}
                  </List>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Divider orientation="horizontal" />
          <Box mt={1}>
            <Grid container justifyContent={"flex-end"}>
              <Grid item mr={2}>
                <Button onClick={handleClose}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={onDataSourceSelect}>
                  Select
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default SelectDataSource;
