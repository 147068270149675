import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import { memo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import DeleteIcon from "../assets/deleteIcon.png";
import EditIcon from "../assets/pencil.png";
import MemberPermissionDetails from "../components/complexes/MemberPermissionDetails";
import { Sidebar } from "../components/complexes/Sidebar";
import {
  deleteUser,
  getMemberDetails,
} from "../services/user-management-service";
import { showMessage } from "../store/error-handler-store";
import { useAppDispatch } from "../store/hook";
import { theme } from "../theme";
import AddEditMemberModal from "../components/complexes/UserManagement/AddEditMemberModal";
import AuthorizationErrorHandler from "../components/molecules/Editor/AuthorizationErrorHandler";

type Props = {};

const MemberDetailsPage = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const [memberDetails, setMemberDetails] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [memberUUID, setMemberUUID] = useState("");
  const [authorized, setAuthorized] = useState(true);

  useEffect(() => {
    if (params.uuid) {
      getDetails(params.uuid);
    }
  }, [params.uuid]);

  const getDetails = (uuid: string) => {
    setLoading(true);
    getMemberDetails(uuid, "uuid")
      .then((res) => {
        if (res.status === 403) {
          setAuthorized(false);
          setLoading(false);
        }
        if (res.status === 200) {
          setAuthorized(true);
          setLoading(false);
          setMemberDetails(res.data);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const deleteMember = () => {
    if (params.uuid && window.confirm("Are you sure to delete the user??")) {
      deleteUser(params.uuid).then((res) => {
        if (res.status === 204) {
          dispatch(
            showMessage({
              message: "User Deleted Successfully",
              variant: "success",
            })
          );
          navigate("/members");
        }
      });
    }
  };

  const onEdit = () => {
    setOpenPopup(true);
    if (params.uuid) {
      setMemberUUID(params.uuid);
    }
  };

  const onEditSuccess = () => {
    if (params.uuid) {
      getDetails(params.uuid);
    }
  };
  return (
    <>
      <Stack direction="row">
        <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
          <Sidebar />
        </Box>
        <Box sx={{ width: "100%" }} p={8}>
          <Grid container>
            <Grid
              onClick={() => {
                navigate("/members");
              }}
              item
            >
              <ArrowBackIcon
                sx={{ color: theme.custom.primaryDarkColor, cursor: "pointer" }}
              />
            </Grid>
            <Grid item>
              <Typography
                variant="poppins_h4_600_20"
                sx={{ color: theme.custom.primaryDarkColor }}
                ml={2}
              >
                User Details
              </Typography>
            </Grid>
          </Grid>
          {loading && (
            <Skeleton
              sx={{ borderRadius: "6px", mt: 2, bgcolor: "grey.100" }}
              variant="rectangular"
              height={130}
            />
          )}
          {!loading && authorized && (
            <Box
              mt={3}
              sx={{
                width: "100%",
                background: theme.custom.chipGroupBackground,
                borderRadius: "6px",
              }}
            >
              <Stack p={3} alignItems={"center"} direction={"row"}>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <Grid container spacing={2} alignItems={"center"}>
                      <Grid item>
                        <Box
                          sx={{
                            padding: 2,
                            borderRadius: "50%",
                            background: theme.custom.accentBgColor,
                          }}
                        >
                          <Typography
                            sx={{ color: theme.custom.whiteColor }}
                            variant="poppins_h2_700_30"
                          >
                            {[
                              (memberDetails.first_name &&
                                memberDetails.first_name.length &&
                                memberDetails.first_name[0]) ||
                                "",
                              (memberDetails.last_name &&
                                memberDetails.last_name.length &&
                                memberDetails.last_name[0]) ||
                                "",
                            ].join("")}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box>
                          <Typography
                            color={theme.custom.primaryDarkColor}
                            variant="poppins_h3_600_24"
                          >
                            {`${
                              memberDetails.first_name
                                ? memberDetails.first_name
                                : ""
                            } ${
                              memberDetails.last_name
                                ? memberDetails.last_name
                                : ""
                            }`}
                          </Typography>
                          <Typography
                            variant="inter_400_14"
                            sx={{ color: theme.custom.primaryDarkColor }}
                          >
                            {memberDetails.email || ""}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container alignItems={"center"} spacing={7}>
                      <Grid item>
                        <Typography
                          variant="inter_400_14"
                          sx={{ color: theme.custom.primaryDarkColor }}
                        >
                          Role
                        </Typography>{" "}
                        <br />
                        <Typography
                          sx={{ color: theme.custom.primaryDarkColor }}
                          variant="inter_p_600_14"
                        >
                          {memberDetails?.role?.name || ""}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid container alignItems={"center"} spacing={3}>
                          <Grid item>
                            <Tooltip title="Edit">
                              <img
                                onClick={onEdit}
                                src={EditIcon}
                                style={{ cursor: "pointer" }}
                                alt="Edit"
                                height={22}
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            <Tooltip title="Delete">
                              <img
                                onClick={() => deleteMember()}
                                src={DeleteIcon}
                                style={{ cursor: "pointer" }}
                                alt="Delete"
                                height={22}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          )}
          {!loading && !authorized && (
            <Box>
              <AuthorizationErrorHandler errorMessage="You don't have access to this page. Please contact admin for access" />
            </Box>
          )}
          {authorized && (
            <Box mt={2}>
              <MemberPermissionDetails memberDetails={memberDetails} />
            </Box>
          )}
          <AddEditMemberModal
            showPopup={openPopup}
            addUserCallback={onEditSuccess}
            setShowPopup={setOpenPopup}
            memberUUID={memberUUID}
            setMemberUUID={setMemberUUID}
          />
        </Box>
      </Stack>
    </>
  );
};

export default memo(MemberDetailsPage);
