import { Button, Grid, Typography } from "@mui/material";
import React, { Fragment, useState, useRef, useCallback, memo } from "react";
import { useAppDispatch } from "../../store/hook";
import { setIsTableEditorActive } from "../../store/selected-design-element-store";

type Props = {
  text: string;
  callback: Function;
  id: string;
  onPaste: Function;
  isPublic?: boolean;
};

const TextFieldEditableComponent = (props: Props) => {
  const id = props.id;
  const inpRef = useRef<HTMLInputElement | null>(null);
  const [showTextField, setShowTextField] = useState(false);
  const [text, setText] = useState<string>(props.text || "");
  const dispatch = useAppDispatch();

  const onChange = useCallback(
    (e) => {
      setText(e.target.value);
    },
    [setText]
  );
  const focus = () => {
    if (inpRef) {
      inpRef.current?.focus();
    }
  };

  const enableTextEdit = () => {
    setShowTextField(true);
    dispatch(setIsTableEditorActive(true));
  };

  const blur = (event: any) => {
    if (event.code === "Enter" || event.type === "blur") {
      setShowTextField(false);
      dispatch(setIsTableEditorActive(false));
      props.callback(id, text);
    }
  };

  const onPaste = (event: any) => {
    const pastedValue = event.clipboardData.getData("text");
    if (pastedValue.includes("\n") || pastedValue.includes("\t")) {
      event.stopPropagation();
      event.preventDefault();
      if (
        window.confirm(
          "Copied value from other spreadsheet detected. Pasting will override existing values. Proceed?"
        )
      ) {
        const pastedValueList = pastedValue
          .split("\n")
          .map((i: any) => i.split("\t"));
        props.onPaste(id, pastedValueList);
      }
    }
  };

  return (
    <Fragment>
      <Grid container>
        {text.length === 0 && !showTextField && (
          <Button
            sx={{ display: props.isPublic ? "none" : "block" }}
            variant="text"
            onClick={enableTextEdit}
          >
            <Typography variant="inter_500_12"> Add name</Typography>
          </Button>
        )}
        {!showTextField && (
          <Grid item onClick={enableTextEdit}>
            {text}
          </Grid>
        )}
        {showTextField && (
          <Grid item>
            <input
              onKeyPress={blur}
              onPaste={onPaste}
              onBlur={blur}
              id={id}
              ref={inpRef}
              style={{ width: "60px" }}
              type={"text"}
              value={text}
              onClick={focus}
              onChange={onChange}
            />
          </Grid>
        )}
      </Grid>
    </Fragment>
  );
};

export default memo(TextFieldEditableComponent);
