import React, { useState, useEffect, MouseEvent } from "react";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { DateRangePicker } from "react-dates";
import moment from "moment";
import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
import { theme } from "../../theme";
import { renderMonthElement } from "../../utils/date-picker-helper-methods";
import SettingIcon from "../../assets/settings_icon.png";
interface Props {
  onChange?: any;
  menuOptions?: any;
  dateRangeValue?: any;
  disabled?: boolean;
  isSmall?: boolean;
  isCustomBoard?: boolean;
  id?: string;
  disableLabel?: boolean;
  updateOverlay?: Function;
}

const INIT_STATE = {
  startDate: null,
  endDate: null,
  focusedInput: null,
};

const DateRangePickerCustomComponent: React.FC<Props> = (data) => {
  let isSmall = data.isSmall;

  const isCustomBoard = data.isCustomBoard;
  const id = data.id;
  const onChange = data.onChange;
  const dateRangeFromParent = data.dateRangeValue;
  const disableLabel = data.disableLabel || false;

  const [dateRangeValue, setDateRangeValue] = useState<any>(INIT_STATE);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  useEffect(() => {
    setDateRangeValue((prev: any) => {
      return {
        ...prev,
        startDate: dateRangeFromParent?.start
          ? moment(dateRangeFromParent?.start)
          : null,
        endDate: dateRangeFromParent?.end
          ? moment(dateRangeFromParent?.end)
          : null,
      };
    });
  }, [dateRangeFromParent]);

  const setDateRangeHelper = (range: any) => {
    setAnchorEl(null);
    updateState(
      moment(data.menuOptions[range].start, "YYYY-MM-DD"),
      moment(data.menuOptions[range].end, "YYYY-MM-DD")
    );
  };

  const updateState = (
    startDate: moment.MomentInput,
    endDate: moment.MomentInput
  ) => {
    setDateRangeValue((prev: any) => {
      return {
        ...prev,
        startDate: startDate,
        endDate: endDate,
      };
    });

    if (moment(endDate).isValid() && moment(startDate).isValid()) {
      onChange(
        {
          start: moment(startDate).format("YYYY-MM-DD"),
          end: moment(endDate).format("YYYY-MM-DD"),
          action: isCustomBoard ? "delete" : "reset",
        },
        id ? id : ""
      );
    }
  };
  const isOutsideRange = (day: any) => {
    return false;
  };

  const onMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <Grid container>
        <Grid
          item
          sx={{ 
            position: "relative",
          }}
          xs={
            data?.menuOptions && Object.keys(data.menuOptions).length > 0
              ? 10
              : 11
          }
        >
          {!disableLabel && (
            <label
              style={{
                position: "absolute",
                zIndex: 10000,
                color: theme.custom.primaryGreyColor,
                top: isSmall ? "-45%" : "-25%",
                left: isSmall
                  ? "15%"
                  : data?.menuOptions &&
                    Object.keys(data.menuOptions).length > 0
                  ? "20%"
                  : "10%",
                background: "#fff",
                paddingLeft: "2%",
                paddingRight: "2%",
              }}
            >
              <Typography variant="inter_500_10"> Start Date</Typography>
            </label>
          )}
          {!disableLabel && (
            <label
              style={{
                position: "absolute",
                zIndex: 10000,
                color: theme.custom.primaryGreyColor,
                top: isSmall ? "-45%" : "-25%",
                left: "70%",
                background: "#fff",
                paddingLeft: "2%",
                paddingRight: "2%",
              }}
            >
              <Typography variant="inter_500_10"> End Date</Typography>
            </label>
          )}
          <DateRangePicker
            renderMonthElement={renderMonthElement}
            customArrowIcon={<p>|</p>}
            small={isSmall}
            numberOfMonths={1}
            disabled={data.disabled ? data.disabled : false}
            isOutsideRange={isOutsideRange}
            startDate={dateRangeValue.startDate}
            startDateId="daterangepicker_start_date"
            endDate={dateRangeValue.endDate}
            endDateId="daterangepicker_end_date"
            onDatesChange={({ startDate, endDate }) => {
              updateState(startDate, endDate);
            }}
            focusedInput={dateRangeValue.focusedInput}
            onFocusChange={(focusedInput) => {
              if (focusedInput) {
                data?.updateOverlay && data?.updateOverlay(true);
              } else {
                data?.updateOverlay && data?.updateOverlay(false);
              }
              setDateRangeValue((prev: any) => {
                return { ...prev, focusedInput: focusedInput };
              });
            }}
          />
        </Grid>
        {data?.menuOptions && Object.keys(data.menuOptions).length > 0 && (
          <Grid
            textAlign={"right"}
            mt={0.5}
            item
            xs={
              data?.menuOptions && Object.keys(data.menuOptions).length > 0
                ? 2
                : 0
            }
          >
            <IconButton aria-label="" component="span" onClick={handleClick}>
              <img
                src={SettingIcon}
                alt="Settings"
                height="25px"
                width={"20px"}
              ></img>
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={onMenuClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              {Object.keys(data.menuOptions).map(
                (range: string, index: number) => {
                  return (
                    <MenuItem onClick={() => setDateRangeHelper(range)}>
                      {data.menuOptions[range]["label"]}
                    </MenuItem>
                  );
                }
              )}
            </Menu>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default React.memo(DateRangePickerCustomComponent);
