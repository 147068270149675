import { Autocomplete, Box, TextField } from "@mui/material";
import { map } from "lodash";
import { memo, SyntheticEvent, useCallback, useEffect, useState } from "react";
import {
  Field,
  NameLabelPair,
  OptionGroup,
  ValueEditorProps,
} from "react-querybuilder";
import { useAppSelector } from "../../../store/hook";
import { qbtoum, umtoqb } from "../../../utils/um-adapters";
import CustomChip from "../../atoms/CustomChip";
import QueryBuilder from "../../atoms/QueryBuilder";
import { SingleDatePicker } from "react-dates";
import SingleDatePickerComponent from "../../atoms/SingleDatePickerComponent";
import moment from "moment";
import { theme } from "../../../theme";

interface UMQBProps {
  umItem: any;
  onChange?: Function;
  fields: any[];
  umId?: number | null;
}


const UMValueEditor = (valueProps: ValueEditorProps) => {
  let options = useAppSelector((state) => {
    if (state.userMapping.allResolvedAttrsDims) {
      return state.userMapping.allResolvedAttrsDims[valueProps.field];
    } else return [];
  });
  const onChange = (value: any) => {
    valueProps.handleOnChange(value);
  };
  let _value = Array.isArray(valueProps.value)
    ? valueProps.value
    : valueProps.value
    ? [valueProps.value]
    : [];

  if (valueProps.field === "date") {
    return (
      <Box
        sx={{
          border: `1px solid ${theme.custom.filterBorderColor}`,
          borderRadius: "6px",
          padding: "1px",
          ".SingleDatePickerInput__withBorder": {
            height: "36px !important",
          },
          ".DateInput__small": {
            marginTop: "3px !important",
          },
        }}
      >
        <SingleDatePickerComponent
          date={_value[0]}
          isSmall
          disabled={valueProps.disabled}
          displayLabel={false}
          onDateChange={(date: any) => {
            onChange(date.format("YYYY-MM-DD"));
          }}
        />
      </Box>
    );
  }

  if (!options) options = [];
  return (
    <Autocomplete
      multiple
      size={"small"}
      sx={{ minWidth: "200px" }}
      options={options}
      disabled={valueProps.disabled}
      getOptionLabel={(option) => option.name}
      value={_value.map((i) => options?.find((j) => j.id === i))}
      filterSelectedOptions
      onChange={(e: SyntheticEvent, value) => onChange(map(value, "id"))}
      renderInput={(params) => <TextField {...params} />}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => {
          if (option) {
            return (
              <CustomChip
                key={option.id}
                labelProps={{ variant: "inter_500_10" }}
                extraProps={{
                  ...getTagProps({ index }),
                  labelText: option.name,
                  size: "small",
                }}
              ></CustomChip>
            );
          }
        });
      }}
    />
  );
};

function UMQueryBuilder(props: UMQBProps) {
  const fields: Field[] = props.fields;
  const [query, setQuery] = useState<any>();
  const attrs = useAppSelector((state) => {
    return state.userMapping.attrs;
  });

  const activeUMId = useAppSelector((state) => {
    return state.userMapping.activeUMId;
  });

  useEffect(() => {
    setQuery(umtoqb(props.umItem));
  }, [props.umItem]);

  const onChange = (query: string) => {
    let parsedQuery = JSON.parse(query);
    setQuery(parsedQuery);
    if (props.onChange) {
      props.onChange(qbtoum(parsedQuery.rules, attrs));
    }
  };
  const disabled = !!activeUMId && (props.umId != activeUMId);

  return (
    <>
      <QueryBuilder
        fields={fields}
        disabled={disabled}
        query={query}
        id={props.umId}
        onChange={onChange}
        valueEditor={memo(UMValueEditor)}
        className={"umQueryBuilder"}
      />
    </>
  );
}

export default memo(UMQueryBuilder);
