import { Box, Tooltip, Typography } from "@mui/material";
import React from "react";
import CheckBox from "@mui/material/Checkbox";

type Props = {
  row: any;
  cell: any;
  cellValue: any;
  onAttachmentClick: Function;
  handleIgnoreList: Function;
  ignoredAttachments?: any;
};

const CustomAttachmentsCell = (props: Props) => {
  const { row, cell, cellValue, onAttachmentClick, handleIgnoreList, ignoredAttachments } = props;
  return (
    <Box>
      {cellValue?.["value"]?.["primary_value"] &&
        cellValue?.["value"]?.["primary_value"].length &&
        cellValue?.["value"]?.["primary_value"].map((ele: any) => {
          return (
            <div style={{ lineHeight: "2rem" }}>
              {
                row.getIsSelected() && (
                  <CheckBox
                    defaultChecked={true}
                    checked={!(ignoredAttachments != {} && (ignoredAttachments[ele['attachment_id']] === false))}
                    onChange={(event, checked) => handleIgnoreList(checked, row?.original?.gl_ref_id?.value?.primary_value, ele['attachment_id'])}
                    inputProps={{ 'aria-label': 'download' }}
                  />
                )
              }
              <Tooltip title={ele["filename"] || ""}>
                <Typography
                  noWrap
                  onClick={() => {
                    onAttachmentClick(ele['attachment_id'] || "")
                  }}
                  sx={{ cursor: "pointer", textDecoration: "underline" }}
                  variant="inter_500_10"
                >
                  {ele["filename"]}
                </Typography>
              </Tooltip>
            </div>
          );
        })}
    </Box>
  );
};

export default CustomAttachmentsCell;
