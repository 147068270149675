import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  ImageList,
  ImageListItem,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { extract } from "oembed-parser";
import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getUploadedVideos,
  uploadMedia,
} from "../../../services/impact-report-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import { theme } from "../../../theme";
import { isJsonString } from "../../../utils/data-utils";
import { validateURL } from "../../../utils/textFunctions";
import AuthorizationErrorHandler from "./AuthorizationErrorHandler";

function VideoLibrary(props: any) {
  let mouseOrigin = useRef({ left: 0, top: 0 });
  let cursorPosition = useRef({ left: 0, top: 0 });

  const [uploadedVideoList, setUploadedVideoList] = useState([] as any);
  const [videoListLoading, setVideoListLoading] = useState(false);
  const [mediaUploading, setMediaUploading] = useState(false);
  const [videoUrlInput, setVideoUrlInput] = useState("");
  const [videoUrlNameInput, setVideoUrlNameInput] = useState("");
  const [reportLinkAPILoading, setReportLinkAPILoading] = useState(false);
  const [uploadedVideoListHasMore, setUploadedVideoListHasMore] =
    useState(true);
  let pageRef = useRef({ popular: 1, search: 1, uploaded: 1 });
  const [authorized, setAuthorized] = useState(true);

  const addElement = (
    e: any,
    element: any,
    type: string,
    dnd: boolean = false
  ) => {
    let _element = null;
    if (type === "embed") {
      _element = {
        type: "EmbedEditor",
        transparency: 1,
        zIndex: 0,
        posX: 100,
        posY: 100,
        height: 800,
        width: 1300,
        rotate: 0,
        attributes: {
          embedHtml: element?.media?.html,
          type,
          title: element?.alt,
        },
      };
    } else {
      _element = {
        type: "VideoEditor",
        transparency: 1,
        zIndex: 0,
        posX: 100,
        posY: 100,
        rotate: 0,
        attributes: {
          url: element?.media,
          type,
          title: element?.alt,
        },
      };
    }

    props.addElement(
      _element,
      {
        left: cursorPosition.current.left,
        top: cursorPosition.current.top,
      },
      "drop",
      "",
      dnd
    );
    e.preventDefault();
  };
  const onDrag = (e: any) => {
    //TODO: On DragEnd page coordinates are coming wrong.
    // Monkey patch to force correct it. Heavy ops issue here.
    cursorPosition.current.top = e.pageY;
    cursorPosition.current.left = e.pageX;
  };
  const onDragStart = (e: any) => {
    let _bounds = e.currentTarget.getBoundingClientRect();
    mouseOrigin.current.top = e.clientY - _bounds.top;
    mouseOrigin.current.left = e.clientX - _bounds.left;
  };

  useEffect(() => {
    loadUploadedVideos();
  }, []);

  const loadUploadedVideos = () => {
    setVideoListLoading(true);
    getUploadedVideos(pageRef.current.uploaded).then((resp) => {
      if (resp.status === 403) {
        setAuthorized(false);
        return;
      }

      if (resp.status === 200) {
        if (pageRef.current.uploaded === 1) {
          setUploadedVideoList(resp.data.results);
        } else {
          setUploadedVideoList([...uploadedVideoList, ...resp.data.results]);
        }
        if (resp.data.next) {
          setUploadedVideoListHasMore(true);
        } else {
          setUploadedVideoListHasMore(false);
        }
      }
      setVideoListLoading(false);
    });
  };

  const loadMoreUploadedVideos = () => {
    pageRef.current.uploaded += 1;
    loadUploadedVideos();
  };
  const [progress, setProgress] = useState(0);
  const onFileChange = (event: any) => {
    let file = event.target.files[0];
    function isVideo(file: any) {
      return file["type"].split("/")[0] === "video"; //returns true or false
    }
    if (!file || !isVideo(file)) {
      dispatch(showMessage({ message: "Please select a valid video file!" }));
      return;
    }

    if (file.size > 10485760) {
      alert("File size cannot be greater than 10MB.");
      return;
    }

    setMediaUploading(true);

    const formData = new FormData();
    formData.append("file", file, file.name);
    formData.append("media_type", "VIDEO");
    formData.append("media_sub_type", "UPLOAD");
    uploadMedia(formData, {
      onUploadProgress: (data: any) => {
        //Set the progress value to show the progress bar
        setProgress(Math.round((100 * data.loaded) / data.total));
      },
    })
      .then((resp: any) => {
        if (resp && resp.status === 201) {
          // load fresh images from beginning
          pageRef.current.uploaded = 1;
          loadUploadedVideos();
          dispatch(
            showMessage({
              message: "Successfully added video",
              variant: "success",
            })
          );
        }
        setMediaUploading(false);
      })
      .catch((err: any) => {
      });
  };
  function isRawVideoLink(url: string) {
    const ext = [
      ".mp4",
      ".mpg",
      ".mpeg",
      ".ogv",
      ".ts",
      ".webm",
      ".3gp",
      ".3g2",
      ".avi",
      ".m4v",
      ".opus",
      ".flv",
    ];
    return ext.some((el) => url.endsWith(el));
  }
  const dispatch = useAppDispatch();
  const reportLinkSave = () => {
    // check link type
    if (!embed) {
      alert("Invalid video link");
      return;
    }
    if (!videoUrlInput.length) {
      setLinkError(`Video URL is mandatory`);
      return;
    }
    if (!videoUrlNameInput.length) {
      setLinkNameError(`Video name is mandatory`);
      return;
    }
    if (!validateURL(videoUrlInput)) {
      setLinkError("Please enter valid url");
      return;
    }
    setReportLinkAPILoading(true);
    const formData = new FormData();
    formData.append("media_name", videoUrlNameInput);
    if (isRawVideoLink(videoUrlInput)) {
      formData.append("media_url", videoUrlInput);
      formData.append("media_type", "VIDEO");
      formData.append("media_sub_type", "URL");
    } else {
      formData.append("media_embed", JSON.stringify(embed));
      formData.append("media_type", "VIDEO");
      formData.append("media_sub_type", "EMBED");
    }
    uploadMedia(formData).then((resp) => {
      if (resp.status === 201) {
        // load fresh images from beginning
        pageRef.current.uploaded = 1;
        setVideoUrlInput("");
        setVideoUrlNameInput("");
        loadUploadedVideos();
        dispatch(
          showMessage({
            message: "Successfully added video",
            variant: "success",
          })
        );
      }
      setReportLinkAPILoading(false);
      handleClose();
    });
  };

  const [open, setOpen] = React.useState(false);
  const [embed, setEmbed] = React.useState<any>({});
  const handleOpen = () => {
    props.clearClipBoard();
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setVideoUrlNameInput("");
    setVideoUrlInput("");
    setEmbed({});
  };
  const formRef = React.useRef<any>(null);

  const rawVideoPreviewGenerator = (videoUrl: string) =>
    `<video crossorigin="anonymous" width="100%" preload="metadata" controls>
      <source src="${videoUrl}#t=2" type="video/mp4" />
    </video>`;
  useEffect(() => {
    setEmbed("");
    if (!videoUrlInput.length) return;
    setLinkError("");
    if (isRawVideoLink(videoUrlInput)) {
      setEmbed({ html: rawVideoPreviewGenerator(videoUrlInput) });
      return;
    }

    extract(videoUrlInput, { maxheight: 1080, maxwidth: 360 })
      .then((oembed: any) => {
        setEmbed(oembed);
        setLinkNameError("");
        setVideoUrlNameInput(oembed.title);
      })
      .catch((err: any) => {
        console.trace(err);
        setEmbed({});
      });
  }, [videoUrlInput]);

  const getEmbedThumbnail = (item: any) => {
    const parsedItem = { ...item, media: JSON.parse(item.media) };
    return (
      <img
        width="100%"
        draggable={true}
        onDrag={onDrag}
        onDragStart={onDragStart}
        onDragEnd={(e) => addElement(e, parsedItem, "embed")}
        onClick={(e) => addElement(e, parsedItem, "embed", false)}
        src={parsedItem.media.thumbnail_url}
        alt={item.alt}
        loading="lazy"
      />
    );
  };
  const [linkNameError, setLinkNameError] = useState("");
  const [linkError, setLinkError] = useState("");
  const MAX_NAME_LENGTH = 200;
  const handleVideoName = (event: any) => {
    setVideoUrlNameInput(event.target.value);
  };

  useEffect(() => {
    setLinkNameError("");
    if (videoUrlNameInput.length > MAX_NAME_LENGTH) {
      setLinkNameError(`Max character limit is ${MAX_NAME_LENGTH}`);
    }
  }, [videoUrlNameInput]);

  const onDataPaste = (e: any) => {
    let clipboardData = e.clipboardData;
    let pastedData = clipboardData.getData("Text");
    if (typeof pastedData !== "string" || isJsonString(pastedData)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Grid container py={2} spacing={1} direction="column">
        <Grid item xs={5.5}>
          <LoadingButton
            color="primary"
            component="label"
            fullWidth
            variant="outlined"
            loading={mediaUploading}
            loadingIndicator={
              <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress
                  color="inherit"
                  size={26}
                  variant={progress === 100 ? "indeterminate" : "determinate"}
                  value={progress}
                />
                <Typography position="absolute" variant="inter_500_10">
                  {progress}
                </Typography>
              </Box>
            }
          >
            <input
              type="file"
              accept="video/*"
              hidden
              onChange={(e) => onFileChange(e)}
            />
            Upload Video
          </LoadingButton>
        </Grid>
        <Grid item xs={5.5}>
          <Button fullWidth variant="outlined" onClick={handleOpen}>
            Add video link
          </Button>
        </Grid>

        <Dialog
          sx={{
            ".MuiDialog-scrollPaper": {
              alignItems: "start",
              justifyContent: "start",
              ml: 8,
              mt: useMediaQuery(theme.breakpoints.down("xl")) ? 5 : 26,
            },
          }}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Add Video link</DialogTitle>
          {
            // width 408 = 360 video frame + 24 padding left + 24 padding right
          }
          <DialogContent sx={{ width: "408px" }}>
            <form ref={formRef}>
              <DialogContentText>
                Name of the media{" "}
                <TextField
                  autoFocus
                  margin="dense"
                  placeholder="abc"
                  value={videoUrlNameInput}
                  helperText={linkNameError}
                  onChange={(e) => handleVideoName(e)}
                  onPaste={onDataPaste}
                  fullWidth
                  error={linkNameError !== ""}
                  variant="outlined"
                />
              </DialogContentText>
              <DialogContentText>
                Paste the video link{" "}
                <TextField
                  autoFocus
                  margin="dense"
                  helperText={linkError}
                  error={linkError !== ""}
                  onPaste={onDataPaste}
                  placeholder="https://"
                  value={videoUrlInput}
                  onChange={(e) => setVideoUrlInput(e.target.value)}
                  fullWidth
                  variant="outlined"
                />
              </DialogContentText>
              <DialogContentText sx={{ textAlign: "center", mt: 3 }}>
                <Box dangerouslySetInnerHTML={{ __html: embed?.html }} />
              </DialogContentText>
            </form>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <LoadingButton
              disabled={
                linkNameError !== "" || linkError !== "" || !embed?.html
              }
              loading={reportLinkAPILoading}
              onClick={(e) => {
                return formRef.current.reportValidity() ? reportLinkSave() : "";
              }}
              variant="contained"
            >
              Add Video
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Grid>

      {!uploadedVideoList.length && !videoListLoading ? (
        <Typography variant="poppins_p_600_14">
          No videos found. Please upload or add video.
        </Typography>
      ) : (
        ""
      )}
      {authorized && (
        <InfiniteScroll
          dataLength={uploadedVideoList.length}
          next={loadMoreUploadedVideos}
          hasMore={uploadedVideoListHasMore}
          loader={
            <h5 style={{ color: "grey", textAlign: "center" }}>Loading...</h5>
          }
          scrollableTarget="scrollableDiv"
          endMessage={
            <Typography
              variant="inter_500_12"
              style={{ color: "grey", textAlign: "center" }}
            >
              No more videos to load
            </Typography>
          }
        >
          {
            // Hack to go around uploadedVideoList.length = 2 messing up masonry flow
          }
          <ImageList
            variant={uploadedVideoList.length > 2 ? "masonry" : "standard"}
            cols={2}
            gap={12}
          >
            {uploadedVideoList.map((item: any) => (
              <ImageListItem
                key={item.id}
                sx={{
                  padding: 1.5,
                  border: `1px solid ${theme.custom.dropdownSecondaryColor}`,
                  borderRadius: "6px",
                  cursor: "pointer",
                }}
              >
                <Tooltip
                  title={item.alt}
                  placement="top"
                  PopperProps={{
                    modifiers: [
                      {
                        name: "offset",
                        options: {
                          offset: [0, -28],
                        },
                      },
                    ],
                  }}
                >
                  <div>
                    {item.media_sub_type === "EMBED" ? (
                      getEmbedThumbnail(item)
                    ) : (
                      <video
                        draggable={true}
                        onDrag={onDrag}
                        onDragStart={onDragStart}
                        onDragEnd={(e) => addElement(e, item, "uploaded")}
                        onClick={(e) => addElement(e, item, "uploaded", false)}
                        width="100%"
                        preload="metadata"
                      >
                        <source src={`${item.media}#t=2`} type="video/mp4" />
                      </video>
                    )}
                  </div>
                </Tooltip>
              </ImageListItem>
            ))}
          </ImageList>
        </InfiniteScroll>
      )}
      {!authorized && (
        <AuthorizationErrorHandler
          errorMessage="You don’t have access to see the list of Videos. Please reach out to
        admin to get access"
        />
      )}
    </>
  );
}

export default React.memo(VideoLibrary);
