import { Quill } from "react-quill";

const Clipboard = Quill.import("modules/clipboard");
const Delta = Quill.import("delta");

export class CustomClipboard extends Clipboard {
  onPaste(e: any) {
    e.preventDefault();
    const clipboardData = e.clipboardData.getData("text/plain");
    // Check if the pasted content is Element data
    try {
      const parsedData = JSON.parse(clipboardData);
      if (
        typeof parsedData === 'object' &&
        parsedData !== null &&
        'type' in parsedData &&
        'id' in parsedData &&
        'posX' in parsedData &&
        'posY' in parsedData
      ) {
        console.log("Element data detected. Paste operation cancelled.");
        return;
      }
    } catch (error) {
      const range = this.quill.getSelection();

      const delta = new Delta()
        .retain(range.index)
        .delete(range.length)
        .insert(clipboardData, this.quill.getFormat());

      const index = clipboardData.length + range.index;
      const length = 0;
      this.quill.updateContents(delta, "silent");
      this.quill.setSelection(index, length, "silent");
    }

  }
}
