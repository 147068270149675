import { createSlice } from "@reduxjs/toolkit";

const errorHandlerSlice = createSlice({
  name: "error-handler",
  initialState: {
    error: {},
  },
  reducers: {
    showMessage(state, action) {
      state.error = action.payload;
    },
  },
});

export const { showMessage } = errorHandlerSlice.actions;
export default errorHandlerSlice.reducer;