import { Box, Divider, Grid, Typography } from "@mui/material";
import { memo } from "react";
import DeleteIcon from "../../../assets/deleteIcon.png";
import {
  default_thumbnail,
  deleteReport,
  ellipse,
} from "../../../services/impact-report-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import { theme } from "../../../theme";
import { dateFormatter } from "../../../utils/dataFormatters";
import ThumbnailSlider from "../../atoms/ThumbnailSlider";
import { has, isEmpty } from "lodash";

interface Props {
  report_name: string;
  report_created: string;
  report_owner: string;
  uid: string;
  report_updated_at: string;
  report_updated_by: string;
  thumbnail?: any;
  triggerReload: CallableFunction;
  is_editable: boolean;
  showOnlyTitle?: boolean;
  extraSx?: any;
}
const ImpactReportItem = (props: Props) => {
  const {
    uid,
    thumbnail,
    report_name,
    report_owner,
    report_updated_at,
    report_updated_by,
    report_created,
    triggerReload,
    is_editable,
    showOnlyTitle,
    extraSx,
  } = props;

  const dispatch = useAppDispatch();
  const deleteItem = (e: any) => {
    e.stopPropagation();
    if (window.confirm("Are you sure you want to delete this report?")) {
      deleteReport(uid).then((resp) => {
        if (resp.status === 204) {
          triggerReload();
          dispatch(
            showMessage({ message: "Successfully deleted", variant: "success" })
          );
        }
      });
    }
  };
  return (
    <Grid
      container
      direction={"column"}
      sx={{
        border: "1px solid #e1e1e1",
        borderRadius: "6px",
        cursor: "pointer",
      }}
    >
      <Box
        className={"report-thumbnail-container"}
        sx={{
          height:
            !isEmpty(extraSx) && has(extraSx, "style")
              ? extraSx?.style?.height
              : 200,
          pt: 2,
          overflow: "hidden",
        }}
      >
        {typeof thumbnail == "string" ? (
          <img
            src={thumbnail ? thumbnail : default_thumbnail}
            style={{
              maxWidth: "90%",
              maxHeight: "95%",
              borderRadius: "16px",
            }}
            alt=""
          />
        ) : (
          <div
            style={{
              maxWidth: "100%",
              maxHeight:
                !isEmpty(extraSx) && has(extraSx, "style") ? "auto" : "100%",
              borderRadius: "16px",
              display: "grid",
            }}
          >
            <ThumbnailSlider blurBackground={false} thumbnail={thumbnail} />
          </div>
        )}
      </Box>
      <Divider variant="fullWidth" />
      <Grid
        container
        justifyContent={"space-between"}
        sx={{ p: 2, height: 64 }}
      >
        <Grid item xs={10}>
          <Box>
            <Typography
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
                color: theme.custom.primaryDarkColor,
              }}
              variant="poppins_p_600_14"
            >
              {report_name}
            </Typography>
          </Box>
        </Grid>
        <Grid
          xs={1}
          item
          onClick={(e: any) => deleteItem(e)}
          sx={{ textAlign: "end", display: showOnlyTitle ? "none" : "block" }}
        >
          <Typography variant="poppins_h5_600_18" color="error">
            <img src={DeleteIcon} alt="Delete" style={{ maxHeight: "1em" }} />
          </Typography>
        </Grid>
      </Grid>

      <Box
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxWidth: "90px",
          ml: 2,
          mt: 2,
        }}
      >
        <Typography
          noWrap
          variant="inter_400_12"
          sx={{ color: theme.custom.primaryDarkColor }}
        >
          <span>
            Created on {dateFormatter(report_created)} &nbsp;
            <img src={ellipse} alt="ellipse"></img> &nbsp;Owned By&nbsp;
            {report_owner}
          </span>
        </Typography>
      </Box>
      <Box
        sx={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          maxWidth: "90%",
          ml: 2,
          mt: 1,
          mb: 2.5,
        }}
      >
        <Typography
          noWrap
          variant="inter_500_10"
          sx={{ color: theme.custom.primaryDarkColor }}
        >
          Last modified by {report_updated_by} on{" "}
          {dateFormatter(report_updated_at)}
        </Typography>
      </Box>
    </Grid>
  );
};

export default memo(ImpactReportItem);
