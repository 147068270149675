import { isEmpty } from "lodash";
import React, { memo } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowDownIcon from "../../../assets/arrow_down.png";
import ArrowRightIcon from "../../../assets/greater.png";
import { theme } from "../../../theme";
type Props = {
  row: any;
  cell: any;
  cellValue: any;
  onCellClick: Function;
  isReport: boolean;
};

const CustomTableCell = (props: Props) => {
  const { row, cellValue, cell, onCellClick, isReport } = props;

  let drilldown_meta = cellValue?.["drilldown_meta"] || {};

  const isDrilldownEnabled = () => {
    return !isEmpty(drilldown_meta) && !isReport;
  };

  const returnPadding = (depth: number) => {
    if (depth === 0 || depth === 1) {
      return 0;
    } else {
      return depth;
    }
  };

  return (
    <div
      style={{
        paddingLeft:
          row?.original?.type === "TOTAL_HEADER"
            ? `${(returnPadding(row.depth) - 0.4) * 1.5}rem`
            : `${row.depth * 2}rem`,
        display: "flex",
        alignItems: "center",
        color: row.original?.in_alert ? row.original?.in_alert?.value?.primary_value : 'inherit'
      }}
    >
      {row["subRows"] && row["subRows"].length > 0 && (
        <span
          onClick={row.getToggleExpandedHandler()}
          style={{
            cursor: "pointer",
            marginRight: "5px",
            pointerEvents: isReport ? "none" : "all",
          }}
        >
          {row.getIsExpanded() ? (
            <img
              style={{ height: "6px", width: "10px" }}
              src={ArrowDownIcon}
              alt="Arrow down"
              className="show-expand"
            />
          ) : (
            <img
              style={{ height: "10px" }}
              src={ArrowRightIcon}
              alt="Arrow down"
              className="show-expand"
            />
          )}
        </span>
      )}
      <span
        onClick={() => {
          if (isDrilldownEnabled()) {
            onCellClick(drilldown_meta["primary_value"]);
          }
        }}
        className={
          isDrilldownEnabled() ? "drilldown-enabled" : "drilldown-disabled"
        }
        style={{
          cursor: isDrilldownEnabled() ? "pointer" : "auto",
          fontWeight: cell?.column?.id === "Total" ? 500 : "inherit",
          color:
            cell?.column?.id === "Total"
              ? theme.custom.primaryDarkColor
              : "inherit",
        }}
      >
        {cellValue?.["value"]?.["primary_value"] || ""}
      </span>
    </div>
  );
};

export default CustomTableCell;
