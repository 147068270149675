import "ka-table/style.scss";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Button, Grid, TextField } from "@mui/material";
import { ITableProps, kaReducer, Table } from "ka-table";
import { deleteRow, insertRow } from "ka-table/actionCreators";
import { DataType, EditingMode, InsertRowPosition } from "ka-table/enums";
import { DispatchFunc } from "ka-table/types";
import { useDeepCompareDiffEffect } from "../../../hooks/useDeepCompareDiffEffect";
import range from "lodash/range";
import pickBy from "lodash/pickBy";
import CheckboxMenu from "../CheckboxMenu";
import "./TableEditor.scss";
import { theme } from "../../../theme";
import TextFieldEditableComponent from "../../atoms/TextFieldEditableComponent";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import {
  setIsTableEditorActive,
  setSettingEditActive,
} from "../../../store/selected-design-element-store";

const dataArrayInit = Array(3)
  .fill(undefined)
  .map((__, index) => ({
    col1: `col:1 row:${index}`,
    col2: `col:2 row:${index}`,
    col3: `col:3 row:${index}`,
    id: index,
  }));

const colInit = [
  {
    key: "col1",
    title: "Col 1",
    dataType: DataType.String,
    internalKey: false,
  },
  {
    key: "col2",
    title: "Col 2",
    dataType: DataType.String,
    internalKey: false,
  },
  {
    key: "col3",
    title: "Col 3",
    dataType: DataType.String,
    internalKey: false,
  },
];

const addExtraToCols = (cols: Array<any>) => {
  return [
    {
      key: "select",
      width: 5,
      internalKey: true,
      isEditable: false,
      title: "",
    },
    ...cols,
  ];
};

const TableEditor = (props: any) => {
  const {
    height,
    width,
    scaleValue,
    attributes,
    updateElement,
    tableConfig,
    elementPadding,
    isPublic,
    selectedElementID: selectedID,
    onLoad,
    id,
  } = props;
  // data for ka-table

  let pasteDetected = useRef(false as any);
  const [maxValue, setMaxValue] = useState(0);
  const dispatchToReduxStore = useAppDispatch();
  let actionSource = useRef("pre-render");

  const [selectedRowCol, setSelectedRowCol] = useState({
    row: "unselect",
    col: "unselect",
  });

  const tableRef = useRef<HTMLDivElement | null>(null);
  const [scale, setScale] = useState(scaleValue || 1);

  const [tableContainerDimension, setTableContainerDimension] = useState<any>({
    height: height / scale || "auto",
    width: width / scale || "auto",
  });

  const generateNewId = () => {
    const newMax = maxValue + 1;
    setMaxValue(newMax);
    return newMax;
  };

  useEffect(() => {
    if (tableConfig?.colArray) {
      const newTableProps = {
        ...tableProps,
        columns: addExtraToCols(tableConfig.colArray),
      };
      actionSource.current = "pre-render";
      changeTableProps(newTableProps);
    } else {
      const newTableProps = tablePropsInit;
      actionSource.current = "pre-render";
      changeTableProps(newTableProps);
    }
  }, [tableConfig?.colArray]);

  useEffect(() => {
    if (tableConfig?.data) {
      if (tableProps.data) {
        setMaxValue(Math.max(...tableProps.data.map((i: any) => i.id || 0)));
      }
      const newTableProps = {
        ...tableProps,
        data: tableConfig?.data,
        columns: addExtraToCols(tableConfig.colArray),
      };
      actionSource.current = "pre-render";
      changeTableProps(newTableProps);
    } else {
      const newTableProps = tablePropsInit;
      changeTableProps(newTableProps);
    }
  }, [tableConfig.data]);

  const colArrayNonInternal = () => {
    return [...tableProps.columns.filter((i: any) => !i.internalKey)] || [];
  };

  const addColumns = (numOfItems = 1, updateProps = true) => {
    // generate unique col name
    let newColKeyNumber =
      Math.max(
        ...colArrayNonInternal().map((i) =>
          parseInt(i.key.replace(/^\D+/g, ""))
        )
      ) + 1;
    let newColArrayItems: any[] = [];
    for (let i = 0; i < numOfItems; i++) {
      const newColArrayItem = {
        key: `col${newColKeyNumber}`,
        title: `Col ${newColKeyNumber}`,
        dataType: DataType.String,
        internalKey: false,
      };
      newColKeyNumber += 1;
      newColArrayItems.push(newColArrayItem);
    }

    if (updateProps) {
      changeTableProps({
        ...tableProps,
        columns: addExtraToCols([
          ...colArrayNonInternal(),
          ...newColArrayItems,
        ]),
      });
    }
    return [...colArrayNonInternal(), ...newColArrayItems];
  };

  const addColumnAtSpecificIndex = (
    id: string,
    numOfItems = 1,
    updateProps = true
  ) => {
    let newColKeyNumber =
      Math.max(
        ...colArrayNonInternal().map((i) =>
          parseInt(i.key.replace(/^\D+/g, ""))
        )
      ) + 1;
    const newColArrayItem = {
      key: `col${newColKeyNumber}`,
      title: `Col ${newColKeyNumber}`,
      dataType: DataType.String,
      internalKey: false,
    };

    let newColArrayItems: any[] = colArrayNonInternal();
    let _idx = newColArrayItems.findIndex((ele: any) => ele.key === id);
    newColArrayItems.splice(_idx + 1, 0, newColArrayItem);
    actionSource.current = "user";
    changeTableProps({
      ...tableProps,
      columns: addExtraToCols([...newColArrayItems]),
    });
  };

  const tablePropsInit: ITableProps = {
    columns: addExtraToCols(tableConfig?.colArray || colInit),
    data: tableConfig?.data || dataArrayInit,
    editingMode: EditingMode.Cell,
    rowKeyField: "id",
    rowReordering: true,
  };
  const tablePropsInitPublic: ITableProps = {
    columns: tableConfig?.colArray || colInit,
    data: tableConfig?.data || dataArrayInit,
    rowKeyField: "id",
  };

  // in this case *props are stored in the state of parent component
  const [tableProps, changeTableProps] = useState(
    isPublic ? tablePropsInitPublic : tablePropsInit
  );

  useEffect(() => {
    if (tableRef?.current?.clientHeight && tableRef?.current?.clientWidth) {
      let _clientWidth = tableRef?.current?.clientWidth;
      let _scale = 1;
      _scale = width / _clientWidth;
      setScale(_scale);
      updateElement({ scale: _scale }, actionSource.current);
    }
  }, [height, width]);

  useEffect(() => {
    calculateTableHeight();
  }, [attributes.showHeader]);

  useDeepCompareDiffEffect(
    (diff) => {
      if (Object.keys(diff).length) {
        calculateTableHeight();
      }
    },
    [tableProps.data]
  );

  useDeepCompareDiffEffect(
    (diff) => {
      if (Object.keys(diff).length) {
        if (tableRef.current) {
          let _clientHeight =
            tableRef?.current.children[0].children[0].children[0]?.clientHeight;
          let _clientWidth =
            tableRef?.current.children[0].children[0].children[0].clientWidth;

          if (_clientHeight && _clientWidth) {
            updateElement(
              {
                height: _clientHeight * scale,
                width: _clientWidth * scale,
                scale: scale,
                tableConfig: {
                  ...(tableConfig || {}),
                  colArray: colArrayNonInternal(),
                  data: tableProps.data,
                },
              },
              actionSource.current
            );
          }
        }
      }
    },
    [tableProps.columns]
  );

  const calculateTableHeight = () => {
    if (tableRef.current) {
      let _clientHeight =
        tableRef?.current.children[0].children[0].children[0]?.clientHeight;
      let _clientWidth =
        tableRef?.current.children[0].children[0].children[0].clientWidth;
      if (_clientHeight && _clientWidth) {
        updateElement(
          {
            height: _clientHeight * scale,
            width: _clientWidth * scale,
            scale: scale,
            tableConfig: {
              ...(tableConfig || {}),
              colArray: colArrayNonInternal(),
              data: tableProps.data,
            },
          },
          actionSource.current
        );
      }
    }
  };

  const dispatch: DispatchFunc = (action) => {
    if (action?.type === "OpenEditor") {
      dispatchToReduxStore(setSettingEditActive(true));
    }
    if (action?.type === "CloseEditor") {
      dispatchToReduxStore(setSettingEditActive(false));
    }
    if (pasteDetected.current) {
      if (action.type === "UpdateCellValue") {
        return;
      } else {
        pasteDetected.current = false;
      }
    }
    // dispatch has an *action as an argument
    // *kaReducer returns new *props according to previous state and *action, and saves new props to the state
    changeTableProps((prevState: ITableProps) => kaReducer(prevState, action));
  };

  const updatePastedValuesInHeader = (colId: string, pastedValues: any) => {
    const dataCols = tableProps.columns
      .filter((v: any) => v.internalKey === false)
      .map((i) => i.key);
    let colIndex = dataCols.indexOf(colId);
    const extraColsNeeded =
      Math.max(dataCols.length, pastedValues[0].length + colIndex) -
      dataCols.length;
    let newColKeyNumber =
      Math.max(
        ...colArrayNonInternal().map((i) =>
          parseInt(i.key.replace(/^\D+/g, ""))
        )
      ) + 1;
    let newColArrayItems: any[] = [];
    for (let i = 0; i < extraColsNeeded; i++) {
      const newColArrayItem = {
        key: `col${newColKeyNumber}`,
        title: `Col ${newColKeyNumber}`,
        dataType: DataType.String,
        internalKey: false,
      };
      newColKeyNumber += 1;
      newColArrayItems.push(newColArrayItem);
    }

    let temp: any[] = [...colArrayNonInternal(), ...newColArrayItems];
    for (let index = colIndex; index < temp.length; index++) {
      temp[index] = {
        ...temp[index],
        title: pastedValues[0][index - colIndex],
      };
    }

    actionSource.current = "user";
    if (pastedValues.slice(1).length > 1) {
      updatePastedValues(colId, 0, pastedValues.slice(1), true, temp);
    }
  };

  const updatePastedValues = (
    colId: any,
    rowId: any,
    pastedValue: any,
    fromHeader: boolean = false,
    cols?: any
  ) => {
    pastedValue.splice(-1); //To remove the last array element. The last element will always be [""] which would create one unnecessary row
    const clickedRowIndex =
      tableProps.data?.findIndex((i) => i.id === rowId) || 0;
    let newData: any = [];
    const dataCols = tableProps.columns
      .filter((v: any) => v.internalKey === false)
      .map((i) => i.key);
    let startOverridingRow = false;
    let startOverridingCol = false;
    let newDataRowIdx = 0;
    let newDataColIdx = 0;
    let clickedColIndex = 0;
    if (tableProps?.data) {
      clickedColIndex = dataCols.indexOf(colId);
    }

    const extraColsNeeded =
      Math.max(dataCols.length, pastedValue[0].length + clickedColIndex) -
      dataCols.length;
    const allCols = fromHeader ? cols : addColumns(extraColsNeeded, false);
    const allColKeys = allCols.map((i: any) => i.key);
    const extraRowsNeeded =
      Math.max(
        tableProps.data?.length || 0,
        pastedValue.length + clickedRowIndex
      ) - (tableProps.data?.length || 0);

    let extraRows: any[] = [];
    range(extraRowsNeeded).forEach((_, i) =>
      extraRows.push({ id: i + (tableProps.data?.length || 0) })
    );

    [...(tableProps?.data || []), ...extraRows].forEach((row, rowIdx) => {
      let newRow: any = {};
      allColKeys.forEach((colKey: any, colIdx: number) => {
        newRow["id"] = row.id;
        newRow[colKey] = row[colKey] || undefined;
        if (clickedColIndex === colIdx) {
          startOverridingCol = true;
        }
        if (rowIdx === clickedRowIndex) {
          startOverridingRow = true;
        }
        if (
          startOverridingRow &&
          startOverridingCol &&
          colIdx >= clickedColIndex
        ) {
          newDataRowIdx = rowIdx - clickedRowIndex;
          newDataColIdx = colIdx - clickedColIndex;
          if (newDataRowIdx === pastedValue.length) {
            startOverridingRow = false;
          }
          if (
            startOverridingRow &&
            newDataColIdx === pastedValue[newDataRowIdx].length
          ) {
            startOverridingCol = false;
          }
          if (startOverridingRow && startOverridingCol) {
            newRow[colKey] = pastedValue[newDataRowIdx][newDataColIdx];
          }
        }
      });
      newData.push(newRow);
    });
    changeTableProps({
      ...tableProps,
      data: newData,
      columns: addExtraToCols(allCols),
    });
  };
  const extraBoxProps = () => {
    // todo: probably better to add this in scss file?
    let sx: any = {
      ".ka-cell": { p: 1 },
      ".ka-cell-editor input": {
        borderRadius: "6px",
        minHeight: "28px",
        borderColor: theme.palette.primary,
      },
      height: tableContainerDimension.height,
      width: tableContainerDimension.width,
      transform: `scale(${scale})`,
      transformOrigin: "top left",
    };
    if (tableConfig?.displayBorder) {
      sx = {
        ...sx,
        ".ka": {
          borderRadius: "6px",
          outline: "1px solid #6E6E81",
        },
      };
    }
    if (tableConfig?.headerFont) {
      sx = {
        ...sx,
        ".ka-thead": { fontFamily: tableConfig.headerFont },
      };
    }
    if (tableConfig?.bodyFont) {
      sx = {
        ...sx,
        ".ka-tbody": { fontFamily: tableConfig.bodyFont },
      };
    }
    if (tableConfig?.headerFontSize) {
      sx = {
        ...sx,
        ".ka-thead": { fontSize: tableConfig.headerFontSize },
      };
    }
    if (tableConfig?.bodyFontSize) {
      sx = {
        ...sx,
        ".ka-tbody": { fontSize: tableConfig.bodyFontSize },
      };
    }

    sx = {
      ...sx,
    };
    return { sx: sx };
  };

  const deleteColumn = (colKey: string) => {
    const newTableProps = {
      ...tableProps,
      columns: addExtraToCols(
        tableProps.columns.filter(
          (col: any) => col.key !== colKey && !col.internalKey
        )
      ),
      data: tableProps.data?.map((o) => pickBy(o, (v, k) => k !== colKey)),
    };

    changeTableProps(newTableProps);
  };

  const addRowAtSpecificIndex = useCallback(
    (_id: number) => {
      actionSource.current = "user";
      const rowKeyValue = generateNewId();
      let temp = colArrayNonInternal();
      let obj: any = {};
      temp.forEach((ele: any) => {
        obj[ele["key"]] = "";
      });

      const saveNewData = () => {
        let _obj = {
          ...obj,
          id: rowKeyValue,
        };
        dispatch(
          insertRow(_obj, {
            rowKeyValue: _id,
            insertRowPosition: InsertRowPosition.after,
          })
        );
      };

      saveNewData();
    },
    [tableProps]
  );

  const deleteSpecificRow = useCallback((rowIndex: number) => {
    actionSource.current = "user";
    setSelectedRowCol((prev) => {
      return { ...prev, row: "unselect" };
    });
    dispatch(deleteRow(rowIndex));
  }, []);

  const deleteSpecificColumn = useCallback(
    (id: string) => {
      actionSource.current = "user";
      deleteColumn(id);
    },
    [tableProps]
  );

  const onUpdateColumnName = useCallback(
    (colId: string, newName: string) => {
      let _cols = colArrayNonInternal().map((ele: any) => {
        if (ele.key === colId) {
          return { ...ele, title: newName };
        } else {
          return ele;
        }
      });
      const newTableProps = {
        ...tableProps,
        columns: addExtraToCols(_cols),
      };
      actionSource.current = "user";
      changeTableProps(newTableProps);
      setSelectedRowCol((prev) => {
        return { ...prev, col: "unselect" };
      });
    },
    [tableProps]
  );

  const CustomColumn = useCallback(
    (columnProps: any) => {
      return (
        <div>
          <span
            style={{
              position: "fixed",
              textAlign: "center",
              top: "-50px",
              display: isPublic || !(id === selectedID) ? "none" : "block",
            }}
          >
            <CheckboxMenu
              id={columnProps.column.key}
              key={columnProps.column.key}
              menuType="column"
              deleteSpecificColumn={deleteSpecificColumn}
              addColumnAtSpecificIndex={addColumnAtSpecificIndex}
              returnSelectedRowCol={(rowId: string, colId: string) => {
                setSelectedRowCol((prev) => {
                  return { ...prev, col: colId, rowId: rowId };
                });
              }}
            />
          </span>
          <Grid
            container
            sx={{ visibility: columnProps.header ? "visible" : "hidden" }}
          >
            <Grid item>
              <TextFieldEditableComponent
                text={columnProps.column.title}
                id={columnProps.column.key}
                callback={onUpdateColumnName}
                onPaste={updatePastedValuesInHeader}
                isPublic={isPublic}
              />
            </Grid>
          </Grid>
        </div>
      );
    },
    [tableProps, selectedID]
  );

  return (
    <Box ref={tableRef} className="tableEditorWrapper" {...extraBoxProps()}>
      <Table
        {...tableProps} // ka-table UI is rendered according to props
        childComponents={{
          tableBody: {
            elementAttributes: (_) => {
              let style: any = {};
              const bgColor = tableConfig?.bodyBgColor;
              if (bgColor) style["backgroundColor"] = bgColor;
              const fontColor = tableConfig?.bodyFontColor;
              if (fontColor) style["color"] = fontColor;
              if (bgColor || fontColor)
                return {
                  style,
                  className: "font-" + tableConfig?.bodyFont,
                };
            },
          },

          tableHead: {
            elementAttributes: (headRowProps) => {
              let style: any = {};
              const bgColor = tableConfig?.headerBgColor;
              if (bgColor) style["backgroundColor"] = bgColor;
              const fontColor = tableConfig?.headerFontColor;
              if (fontColor) style["color"] = fontColor;
              if (bgColor || fontColor)
                return {
                  style,
                  className: "font-" + tableConfig?.headerFont,
                };
            },
          },

          headCell: {
            elementAttributes: (headRowProps) => {
              let style: any = {};
              const bgColor = tableConfig?.headerBgColor;
              if (bgColor) style["backgroundColor"] = bgColor;
              const fontColor = tableConfig?.headerFontColor;
              if (fontColor) style["color"] = fontColor;
              if (attributes?.showHeader) {
                return {
                  style,
                };
              } else {
                return {
                  className: "hide-header",
                  style,
                };
              }
            },
            content: (cellProps) => {
              const col: any = cellProps.column;
              if (!col.internalKey) {
                return (
                  <CustomColumn
                    {...cellProps}
                    header={attributes?.showHeader}
                  />
                );
              }
            },
          },
          cell: {
            elementAttributes: (cellProps) => {
              const col: any = cellProps.column;
              if (col.internalKey) return;
              let style: any = {};
              const bgColor = tableConfig?.bodyBgColor;
              if (bgColor) style["backgroundColor"] = bgColor;
              const fontColor = tableConfig?.bodyFontColor;
              if (fontColor) style["color"] = fontColor;

              if (
                selectedRowCol.col === "unselect" &&
                selectedRowCol.row === "unselect"
              ) {
                return { className: "", style: { color: style.color } };
              }
              if (cellProps.column.key === selectedRowCol.col) {
                return {
                  className: "selected-col-row-background",
                  style: { color: style.color },
                };
              } else if (cellProps.rowKeyValue === selectedRowCol.row) {
                return {
                  className: "selected-col-row-background",
                  style: { color: style.color },
                };
              } else {
                return {
                  style,
                };
              }
              // hack as column has inconsistent implementation. It has a defined type here, but otherwise it can have any key.
              // We are using `internalKey` attribute in column data to represent non data columns
            },

            content: (cellProps) => {
              const col = cellProps.column.key;

              if (col === "drag") {
                return <DragIndicatorIcon sx={{ cursor: "move", mt: 1 }} />;
              }
              if (col === "select") {
                let _id = cellProps.column.key + "" + cellProps.rowKeyValue;
                return (
                  <span
                    style={{
                      display:
                        isPublic || !(id === selectedID) ? "none" : "block",
                    }}
                  >
                    <CheckboxMenu
                      menuType="row"
                      key={_id}
                      id={_id}
                      rowIndex={cellProps.rowKeyValue}
                      addRowAtSpecificIndex={addRowAtSpecificIndex}
                      deleteSpecificRow={deleteSpecificRow}
                      returnSelectedRowCol={(rowId: string, colId: string) => {
                        setSelectedRowCol((prev) => {
                          return { ...prev, col: colId, row: rowId };
                        });
                      }}
                    />
                  </span>
                );
              }
            },
          },
          cellEditor: {
            elementAttributes: (cellProps) => {
              actionSource.current = "user";
              return {
                onPaste: (e: any) => {
                  const pastedValue = e.clipboardData.getData("text");
                  if (
                    pastedValue.includes("\n") ||
                    pastedValue.includes("\t")
                  ) {
                    pasteDetected.current = true;
                    e.stopPropagation();
                    e.preventDefault();
                    if (
                      window.confirm(
                        "Copied value from other spreadsheet detected. Pasting will override existing values. Proceed?"
                      )
                    ) {
                      const pastedValueList = pastedValue
                        .split("\n")
                        .map((i: any) => i.split("\t"));
                      updatePastedValues(
                        cellProps.field,
                        cellProps.rowKeyValue,
                        pastedValueList
                      );
                    }
                  }
                },
                onBlur: () => {
                  setSelectedRowCol((prev) => {
                    return { ...prev, col: "unselect" };
                  });
                },
              };
            },
          },
        }}
        dispatch={dispatch} // dispatch is required for obtain new actions from the UI
      />
    </Box>
  );
};

export default memo(TableEditor);
