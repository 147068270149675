import React, { memo } from "react";
import KPIIcon from "../../../assets/kpi-icon.png";
import LinkIcon from "../../../assets/link_icon.png";
import PageIcon from "../../../assets/page-icon.png";
import ExecutedKPIListItem from "./ExecutedKPIListItem";
import GenericListItem from "./GenericListItem";

type Props = { payload: any; onClick: Function, query?: string; };

const SearchedListItem = (props: Props) => {
  const { payload, onClick, query } = props;

  const iconSelector = (_type: string) => {
    switch (_type) {
      case "kpi":
        return KPIIcon;
      case "recent":
        return LinkIcon;
      default:
        return PageIcon;
    }
  };

  return (
    <>
      {!payload["trigger_kpi"] ? (
        <GenericListItem
          iconSelector={iconSelector}
          onClick={onClick}
          payload={payload}
        />
      ) : (
        <ExecutedKPIListItem
          onClick={onClick}
          payload={payload}
          iconSelector={iconSelector}
          query={query}
        />
      )}
    </>
  );
};

export default memo(SearchedListItem);
