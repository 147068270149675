import { Grid, Tooltip } from "@mui/material";
import { theme } from "../../theme";
import React, { useCallback, useEffect, useState } from "react";
import CustomChip from "../atoms/CustomChip";

interface Props {
  seletedFilters?: any;
  updateSelectedFilters?: any;
  resetFilters?: any;
}

const ChipsForBudgeting: React.FC<Props> = (data) => {
  let selectedFilters = data.seletedFilters;
  let resetFilters = data.resetFilters;
  let updateSelectedFilters = data.updateSelectedFilters;
  const [chipList, setChipList] = useState<any>([]);
  useEffect(() => {
    if (selectedFilters) {
      createChips();
    }
  }, [selectedFilters]);

  function createChips() {
    Object.keys(selectedFilters).forEach((filter: string, index: number) => {
      let filterItem: any = selectedFilters;
      if (!filterItem[filter] && filter !== "active") {
        return;
      }

      if (filter === "ref_program_id" || filter === "ref_contract_id") {
        createChipHelper(index, filterItem[filter]?.["name"], "delete", filter);
      } else if (filter === "end_date") {
        createChipHelper(index, filterItem[filter], "delete", filter);
      } else if (filter === "active") {
        let chipLabel;
        switch (filterItem[filter]) {
          case "true":
            chipLabel = "Active";
            break;
          case "false":
            chipLabel = "Inactive";
            break;
          default:
            chipLabel = "All";
        }
        createChipHelper(index, chipLabel, "delete", filter);
      }
    });
  }

  const reset = () => {
    setChipList([]);
    resetFilters();
  };

  let chips: any = [];
  const createChipHelper = (
    key: any,
    label: string,
    action: string,
    filter: string
  ) => {
    chips.push({
      key: key,
      label: label,
      action: action,
      filter: filter,
    });
    setChipList(chips);
  };

  const handleDelete = (
    filter: any,
    filterToDelete: string,
    list: any,
    updateChipList: any
  ) => {
    if (filter?.action === "delete") {
      list = list.filter((chip: any) => chip.label !== filter.label);
      updateChipList(list);
      updateSelectedFilters(filterToDelete, filter);
    }
  };

  return (
    <React.Fragment>
      <Grid container xs={12}>
        {chipList.map((ele: any, index: number) => {
          return (
            <Grid key={index} item>
              <CustomChip
                key={index}
                extraSX={{ marginRight: 2, marginBottom: 2 }}
                extraProps={{
                  key: ele.key,
                  onDelete: () => {
                    handleDelete(ele, ele["filter"], chipList, setChipList);
                  },
                  labelText: ele.label,
                }}
                closeIconProps={{
                  fill: theme.CustomChip.closeIconColor,
                  fontSize: "18px !important",
                  marginRight: "12px !important",
                }}
                action={ele?.action || "delete"}
              />
            </Grid>
          );
        })}
        <Grid sx={{ marginLeft: "5px" }} key={"reset"} item>
          <CustomChip
            extraProps={{
              onClick: reset,
              labelText: "Reset",
              title: "It will clear all applied filters",
            }}
            labelProps={{ vairant: "inter_p_600_16" }}
            extraSX={{
              backgroundColor: theme.custom.whiteColor,
              color: theme.CustomChip.primaryBlue,
              cursor: "pointer",
            }}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ChipsForBudgeting;
