import {
  Box,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { CSSProperties, useEffect, useState } from "react";
import TextBullet from "../../../assets/text_bullet.png";
import TextBulletActive from "../../../assets/text_bullet_active.png";
import QuoteIcon from "../../../assets/quote.png";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { useDeepCompareDiffEffect } from "../../../hooks/useDeepCompareDiffEffect";
import {
  getFonts,
  getFontSizes,
} from "../../../services/impact-report-service";
import { theme } from "../../../theme";
import { addAlphaToHexCode } from "../../../utils/color-picker-helper";
import "./Design.scss";
import { ThemeColorPicker, ThemeColorPickerIcon } from "./ThemeColorPicker";
// Import setting icons
import TextUnderline from "../../../assets/text_underline.png";
import TextUnderlineActive from "../../../assets/text_underline_active.png";
import TextBold from "../../../assets/text_bold.png";
import TextBoldActive from "../../../assets/text_bold_active.png";
import TextItalics from "../../../assets/text_italics.png";
import TextItalicsActive from "../../../assets/text_italics_active.png";
import TextLeftAligned from "../../../assets/text_left_aligned.png";
import TextLeftAlignedActive from "../../../assets/text_left_aligned_active.png";
import TextRightAligned from "../../../assets/text_right_aligned.png";
import TextRightAlignedActive from "../../../assets/text_right_aligned_active.png";
import TextCurve from "../../../assets/text_curve.png";
import TextCurveActive from "../../../assets/text_curve_active.png";
import TextCenterAligned from "../../../assets/text_center_aligned.png";
import TextCenterAlignedActive from "../../../assets/text_center_aligned_active.png";
import { InputSelect } from "../../atoms/Editor/InputSelect";
import { useAppDispatch } from "../../../store/hook";
import { setSettingEditActive } from "../../../store/selected-design-element-store";

function TextEditorSettings(props: any) {
  const {
    updateElement,
    zoom,
    element: selectedElement,
    format: selectedElementCurrentFormat,
  } = props;

  const [fontSize, setFontSize] = useState(
    (selectedElement?.attributes?.size || 23) * (selectedElement?.scale || 1)
  );
  
  const colorPickerStyle: CSSProperties = {
    position: "absolute",
    padding: "10px",
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.12) 4px 4px 10px 10px",
    borderRadius: "6px",
    zIndex: 1,
    minWidth: "300px",
    minHeight: "200px",
    left: "40px",
    top: "-150px",
  };

  useEffect(() => {
    setFontSize(
      (selectedElement?.attributes?.size || 23) * (selectedElement?.scale || 1)
    );
  }, [selectedElement?.scale, selectedElement?.attributes?.size]);

  const [color, setColor] = useState(selectedElement?.attributes?.color);
  const [bold, setBold] = useState(selectedElement?.attributes?.bold);
  const [italic, setItalic] = useState(selectedElement?.attributes?.italic);
  const [font, setFont] = useState(selectedElement?.attributes?.font);
  const [align, setAlign] = useState(selectedElement?.attributes?.align);
  const [blockquote, setBlockquote] = useState(
    selectedElement?.attributes?.blockquote
  );
  const [curve, setCurve] = useState(selectedElement?.attributes?.curve);
  const [list, setList] = useState(selectedElement?.attributes?.list);
  const [underline, setUnderline] = useState(
    selectedElement?.attributes?.underline
  );
  const [displayPicker, setDisplayPicker] = useState(false);
  const dispatch = useAppDispatch();

  const _resetFormat = () => {
    setBold(false);
    setItalic(false);
    setBlockquote(false);
    setList(false);
    setUnderline(false);
  };

  useDeepCompareDiffEffect(
    (diff = {}) => {
      // In case of selections/bounds containing multivariant formatting
      // we pick the latest applied.
      if ("size" in diff) {
        let _size = Array.isArray(selectedElementCurrentFormat.size)
          ? selectedElementCurrentFormat.size.slice(-1)[0]
          : selectedElementCurrentFormat.size;
        if (_size && typeof _size === "string") {
          setFontSize(+_size?.replace("px", ""));
        }
      }

      if ("color" in diff) {
        setColor(selectedElementCurrentFormat.color);
      }

      if ("font" in diff) {
        setFont(
          Array.isArray(selectedElementCurrentFormat.font)
            ? selectedElementCurrentFormat.font.slice(-1)[0]
            : selectedElementCurrentFormat.font
        );
      }

      if ("bold" in diff) {
        setBold(
          Array.isArray(selectedElementCurrentFormat.bold)
            ? selectedElementCurrentFormat.bold.slice(-1)[0]
            : selectedElementCurrentFormat.bold
        );
      }

      if ("italic" in diff) {
        setItalic(
          Array.isArray(selectedElementCurrentFormat.italic)
            ? selectedElementCurrentFormat.italic.slice(-1)[0]
            : selectedElementCurrentFormat.italic
        );
      }

      if ("underline" in diff) {
        setUnderline(
          Array.isArray(selectedElementCurrentFormat.underline)
            ? selectedElementCurrentFormat.underline.slice(-1)[0]
            : selectedElementCurrentFormat.underline
        );
      }

      if ("align" in diff) {
        setAlign(selectedElementCurrentFormat.align);
      }

      if ("blockquote" in diff) {
        setBlockquote(selectedElementCurrentFormat.blockquote);
      }

      if ("curve" in diff) {
        setCurve(selectedElementCurrentFormat.curve);
      }

      if ("list" in diff) {
        setList(selectedElementCurrentFormat.list);
      }
    },
    [selectedElementCurrentFormat]
  );

  const onFontSizeChange = (value: any) => {
    if (value && +value > 0) {
      setFontSize(value);
      let nextElement = { ...selectedElement };
      updateElement(
        selectedElement.id,
        { scale: ((nextElement?.scale || 1) * value) / fontSize, ops: "fontSizeChange" },
        "user"
      );
    }
  };

  const updateStyle = (key: any, value: any, callBack: any) => {
    let nextElement = { ...selectedElement };
    nextElement.attributes = {
      ...nextElement.attributes,
      [key]: value,
    };

    updateElement(
      selectedElement.id,
      { attributes: nextElement.attributes },
      "user"
    );
    callBack(value);
  };

  const updateHexCode = (hexCode: string) => {
    updateStyle("color", hexCode, setColor);
  };

  const togglePicker = (e: any) => {
    if(!displayPicker){
      props.updateOverlay(true);
    } else {
      props.updateOverlay(false);
    }

    dispatch(setSettingEditActive(!displayPicker));
    setDisplayPicker(!displayPicker);
  };

  return (
    <>
      <Box>
        <Typography
          variant="inter_p_600_16"
          sx={{ color: theme.custom.primaryDarkColor }}
          mb={2}
        >
          Text style
        </Typography>
        <Grid container mt={2} mb={2} justifyContent={"center"}>
          <Grid item md={12}>
            <Select
              sx={{ height: 34 }}
              size="small"
              labelId="text-select-label"
              id="text-select"
              value={font}
              fullWidth={true}
              onChange={(e) => updateStyle("font", e.target.value, setFont)}
            >
              {getFonts().map((font, index) => (
                <MenuItem key={`${font}_${index}`} value={font.value}>
                  <Typography
                    sx={{ color: theme.custom.primaryGreyColor }}
                    mt={0.4}
                    variant="inter_p_500_14"
                  >
                    <span className={`font-${font.value}`}>{font.label}</span>
                  </Typography>
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
        <Grid
          container
          mt={2}
          mb={2}
          justifyContent={"center"}
          alignItems="center"
        >
          <Grid item md={2.5}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => updateStyle("underline", !underline, setUnderline)}
              src={underline ? TextUnderlineActive : TextUnderline}
              alt="underline"
              height="30px"
            ></img>
          </Grid>
          <Grid item md={2.5}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => updateStyle("bold", !bold, setBold)}
              src={bold ? TextBoldActive : TextBold}
              alt="bold"
              height="30px"
            ></img>
          </Grid>
          <Grid item md={2.5}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => updateStyle("italic", !italic, setItalic)}
              src={italic ? TextItalicsActive : TextItalics}
              alt="italic"
              height="30px"
            ></img>
          </Grid>
          <Grid item md={4.5}>
            <InputSelect
              value={fontSize}
              variant="outlined"
              size="small"
              onFocus={() => dispatch(setSettingEditActive(true))}
              onBlur={() => dispatch(setSettingEditActive(false))}
              onChange={onFontSizeChange}
              options={getFontSizes()}
            />
          </Grid>
        </Grid>
      </Box>
      <Divider sx={{ my: 2, mx: -2 }} />
      <Box mt={2}>
        <Typography
          variant="inter_p_600_16"
          sx={{ color: theme.custom.primaryDarkColor }}
          mb={2}
        >
          Alignment
        </Typography>
        <Grid container mt={2} mb={2}>
          <Grid item md={2.5}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => updateStyle("align", "", setAlign)}
              src={
                "" === align
                  ? TextLeftAlignedActive
                  : TextLeftAligned
              }
              alt="alignLeft"
              height="30px"
            ></img>
          </Grid>
          <Grid item md={2.5}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => updateStyle("align", "center", setAlign)}
              src={
                "center" === align || undefined === align ? TextCenterAlignedActive : TextCenterAligned
              }
              alt="alignCenter"
              height="30px"
            ></img>
          </Grid>
          <Grid item md={2.5}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => updateStyle("align", "right", setAlign)}
              src={
                "right" === align ? TextRightAlignedActive : TextRightAligned
              }
              alt="alignRight"
              height="30px"
            ></img>
          </Grid>
          {/* <Grid item md={2.5}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() => updateStyle("curve", !curve, setCurve)}
              src={curve ? TextCurveActive : TextCurve}
              alt="curve"
              height="30px"
            ></img>
          </Grid> */}
        </Grid>
      </Box>
      <Divider sx={{ my: 2, mx: -2 }} />
      <Box mt={2}>
        <Typography
          variant="inter_p_600_16"
          sx={{ color: theme.custom.primaryDarkColor }}
          mb={2}
        >
          Style
        </Typography>
        <Grid container mt={2} mb={2}>
          <Grid item md={2.5}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() =>
                updateStyle("list", "bullet" === list ? "" : "bullet", setList)
              }
              src={"bullet" === list ? TextBulletActive : TextBullet}
              alt="quote"
              height="30px"
            ></img>
          </Grid>
          <Grid item md={2.5}>
            <Box
              onClick={() =>
                updateStyle(
                  "list",
                  "ordered" === list ? "" : "ordered",
                  setList
                )
              }
            >
              {"ordered" === list ? (
                <IconButton
                  aria-label="FormatListNumberedIcon"
                  sx={{
                    color: theme.custom.whiteColor,
                    background: theme.custom.primaryGreyColor,
                    borderRadius: "3px",
                    padding: "2px",
                    "&:hover": {
                      background: theme.custom.primaryGreyColor,
                    },
                  }}
                >
                  <FormatListNumberedIcon />
                </IconButton>
              ) : (
                <IconButton
                  aria-label="FormatListNumberedIcon"
                  sx={{
                    color: theme.custom.primaryGreyColor,
                    borderRadius: "3px",
                    padding: "2px",
                    "&:hover": {
                      background: theme.custom.whiteColor,
                    },
                  }}
                >
                  <FormatListNumberedIcon />
                </IconButton>
              )}
            </Box>
          </Grid>
          <Grid item md={2.5}>
            <img
              style={{ cursor: "pointer" }}
              onClick={() =>
                updateStyle("blockquote", !blockquote, setBlockquote)
              }
              src={QuoteIcon}
              alt="quote"
              height="30px"
            ></img>
          </Grid>
          <Grid item md={2} style={{ position: "relative" }}>
            <div onClick={togglePicker}>
              <ThemeColorPickerIcon colors={color} />
            </div>
            {displayPicker ? (
              <Box style={colorPickerStyle}>
                <ThemeColorPicker
                  closePicker={togglePicker}
                  isShapes={true}
                  align={"top"}
                  onChange={(colour: any) =>
                    updateStyle(
                      "color",
                      addAlphaToHexCode(colour.hex, colour.rgb["a"]),
                      setColor
                    )
                  }
                  updateHexCode={updateHexCode}
                  color={color}
                />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default React.memo(TextEditorSettings);
