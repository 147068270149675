import { Box, Stack, Grid, Typography } from "@mui/material";
import React, { memo, useEffect, useState } from "react";
import CustomChip from "../CustomChip";
import { theme } from "../../../theme";
import { numberFormatter } from "../../../utils/dataFormatters";
import {
  SpotLightChartDataObj,
  SpotLightSearchKPIResponseObj,
} from "../../../Types/spotlight/SpotLightSearchKPIResponseObj";
import { some } from "lodash";
import { filterDataFormatter } from "../../../utils/filterDataFormatter";
import getKPIData from "../../../services/kpi-service";
import { ChartMeta } from "../../../Types/ChartMeta";
import GenericListItem from "./GenericListItem";

type Props = {
  onClick: Function;
  payload: any;
  iconSelector: Function;
  query?: string;
};

const INIT_STATE = {
  data: [] as SpotLightChartDataObj[],
  meta_data: {} as ChartMeta,
  slug: "",
  type: "",
};

const ExecutedKPIListItem = (props: Props) => {
  const { onClick, payload, iconSelector, query} = props;
  const [loading, setLoading] = useState<boolean>(true);

  const [chartData, setChartData] =
    useState<SpotLightSearchKPIResponseObj>(INIT_STATE);

  useEffect(() => {
    if (!some(payload)) {
      return;
    }
    getChartData();
  }, [payload]);

  const getChartData = () => {
    let obj: any = {};
    if (payload["type"] === "kpi" && payload["trigger_kpi"]) {
      if (payload["nav_url"].includes("encoded_filter_data=")) {
        let temp = payload["nav_url"].split(/(\w?encoded_filter_data=)/g);
        temp = temp.filter((ele: string) => {
          if (!ele.includes("encoded_filter_data=")) {
            return ele;
          }
        });

        let [encodedFilters, slug] = temp[temp.length - 1].split("#");
        let board = temp[0].split(/[/,?]/)?.[2] || "";
        let decodedFilters = JSON.parse(atob(encodedFilters));
        decodedFilters = query?{...decodedFilters, ...{"query": query}}:decodedFilters;
        obj = {
          slug: slug,
          filters: filterDataFormatter(decodedFilters),
          board_slug: board,
          kpi_type: "spotlight",
        };
      } else {
        let [type, board, slug] = payload["nav_url"].split(/[/,#]+/).splice(1);
        obj = {
          slug: slug,
          filters: {},
          board_slug: board,
          kpi_type: "spotlight",
        };
      }

      if (!some(obj)) return;
      getKPIData(
        obj["slug"],
        obj["filters"],
        false,
        false,
        "",
        obj["board_slug"],
        "",
        obj["kpi_type"]
      )
        .then((res) => {
          if (res && res.status === 200) {
            setChartData((prev) => {
              return {
                ...prev,
                data: res.data?.data || [],
                meta_data: res.data?.meta_data || {},
                slug: res.data?.slug || "",
                type: res.data?.type || "",
              };
            });
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };
  return (
    <>
      {chartData?.data.length > 0 ? (
        <Box
          sx={{
            cursor: "pointer",
            backgroundColor: theme.custom.secondaryGreen,
            borderRadius: "6px",
            width: "100%",
          }}
          onClick={(e) => onClick(e, payload.nav_url)}
          mx={2}
          my={1}
          padding={2}
        >
          <Stack mb={2} direction={"row"} alignItems={"center"}>
            <Grid item>
              <img
                style={{ marginRight: 5 }}
                height="18px"
                width="18px"
                src={iconSelector(payload?.type)}
                alt={payload.title}
              />
            </Grid>
            <Grid item>
              <Typography>{payload?.["title"]}</Typography>
            </Grid>
            <Grid item>
              <CustomChip
                key={payload?.title}
                extraProps={{
                  size: "small",
                  labelText: `${"KPI Result"}`,
                }}
                labelProps={{ variant: "inter_500_12" }}
                extraSX={{
                  cursor: "pointer",
                  mx: 0.5,
                  backgroundColor: theme.custom.primaryGreen,
                  color: `${theme.custom.primaryDarkColor} !important`,
                }}
              />
            </Grid>
          </Stack>
          <table>
            {chartData &&
              chartData.data &&
              chartData.data.map((ele) => {
                return (
                  <tr>
                    <td
                      style={{
                        minWidth: 120,
                        maxWidth: "90%",
                        display: "grid",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Typography
                        noWrap
                        sx={{ color: theme.custom.primaryDarkColor }}
                        variant="inter_400_14"
                      >
                        {ele.label}
                      </Typography>
                    </td>
                    <td style={{ paddingLeft: 10 }}>
                      <Typography
                        sx={{ color: theme.custom.primaryDarkColor }}
                        variant="inter_400_14"
                      >
                        {isNaN(ele.value)
                          ? ele.value
                          : numberFormatter(
                              ele.value,
                              ele?.unit
                                ? ele?.unit
                                : chartData.meta_data?.chart?.currencytype
                            )}
                      </Typography>
                    </td>
                  </tr>
                );
              })}
          </table>
        </Box>
      ) : (
        <GenericListItem
          iconSelector={iconSelector}
          onClick={onClick}
          payload={payload}
          showLoader={loading}
        />
      )}
    </>
  );
};

export default memo(ExecutedKPIListItem);
