import { capitalize, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { setNavigationData } from "../../../store/navigation-store";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../../store/hook";
import { theme } from "../../../theme";
import { getS3Image } from "../../../utils/ui-utils";
import { orderBy } from "lodash";

interface Props {
  boards: any;
  category: string;
}

const MetricsGenericMolecule = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const menuItemClickHandler = (item: any) => {
    dispatch(setNavigationData(item));
    navigate(`/board/${item.board_slug}`);
  };

  return (
    <>
      {orderBy(props.boards, "position", "asc").map(
        (board: any, index: any) => (
          <Grid
            item
            sx={{ cursor: "pointer" }}
            xs={2}
            sm={3}
            md={4}
            key={index}
            onClick={() => menuItemClickHandler(board)}
          >
            <Grid
              container
              alignItems={"center"}
              sx={{
                border: `1px solid ${theme.custom.borderColor}`,
                borderRadius: "6px",
                padding: 2,
              }}
            >
              <Grid item xs={9}>
                <Typography
                  gutterBottom
                  noWrap={true}
                  variant="poppins_h6_600_16"
                  component="div"
                >
                  {board.title}
                </Typography>
                <Typography variant="inter_400_14" color="text.secondary">
                  {board.kpi_count > 1
                    ? `${board.kpi_count} KPIS`
                    : `${board.kpi_count} KPI`}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <img
                  alt={board.board_slug}
                  width="100%"
                  src={getS3Image(
                    `metrics/${board.icon_handle || "financial-metrics"}.png`
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
        )
      )}
    </>
  );
};

export default MetricsGenericMolecule;
