import { LinearProgress } from "@mui/material";
import { SxProps } from "@mui/system";

interface Props {
  width?: string;
  borderRadius?: string;
  height?: string;
  extra?: SxProps;
  extraProps?: any;
}

const CustomLinearProgress = (props: Props) => {
  return (
    <LinearProgress
      {...props?.extraProps}
      sx={{
        width: props?.width || "100px",
        borderRadius: props?.borderRadius || "8px",
        height: props?.height || "12px",
        ...props?.extra || {},
      }}
    />
  );
};

export default CustomLinearProgress;
