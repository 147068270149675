import { createSlice } from "@reduxjs/toolkit";

const chipStateHandler = createSlice({
  name: "filter-state-handler",
  initialState: {
    close: false,
  },
  reducers: {
    closeFilter(state) {
      state.close = true;
    },
  },
});

export const { closeFilter } = chipStateHandler.actions;
export default chipStateHandler.reducer;