import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import { capitalize } from "lodash";
import React, { ChangeEvent, useEffect, useState } from "react";
import { getAllWorkbookSheetsColumn } from "../../../services/kpi-builder-service";

import { fontInter, theme } from "../../../theme";
import SelectDataSource from "../../complexes/KpiBuilder/SelectDataSource";

import { useDebounce } from "react-use";
import {
  ConfigsTypes,
  SupportedDataTypes,
} from "../../../Types/KPIBuilderChartType";
import {
  isNestedEmpty,
  filterOperatorsForSelectedDataType,
  operatorOptionsTransformer,
} from "../../../utils/kpi-builder-utils";
import {
  DataSource,
  XSeriesType,
  YSeriesType,
} from "../../../Types/KPIBuilderSeriesTypes";

type Props = {
  id: string;
  seriesData: any;

  updateDataSource: Function;
  supported_data_types: SupportedDataTypes[];
  configs: ConfigsTypes[];
  slug: string;
  xSeries: XSeriesType[];
};

const INIT_DATA_SOURCE = {
  workbookId: "",
  workbookName: "",
  sheetId: "",
  sheetName: "",
  columnId: "",
  columnName: "",
  thumbnail: "",
};

const JOINING_COLUMN_INIT = {
  connection_id: "",
  table: "",
  column_name: "",
};

const BASE_COLUMN_INIT = {
  connection_id: "",
  table: "",
  column_name: "",
};

const INIT_AXIS_DATA = {
  data_source: INIT_DATA_SOURCE,
  data_type_selected: { name: "", value: "" },
  axis_name: "",
  joining_column: JOINING_COLUMN_INIT,
  base_column: BASE_COLUMN_INIT,
  operator: "",
};

const DataSourceYZAxisComponent = (props: Props) => {
  const {
    id,
    updateDataSource,
    seriesData,
    supported_data_types,
    configs,
    slug,
    xSeries,
  } = props;
  const [joiningColumnOptions, setJoiningColumnOptions] = useState([] as any);
  const [baseColumnOptions, setBaseColumnOptions] = useState([] as any);
  const [operatorOptions, setAllOperatorOptions] = useState([] as any);
  const [yzAxisSeries, setYZAxisSeries] = useState(INIT_AXIS_DATA);
  const [dataTypes, setDataTypes] = useState(
    supported_data_types as SupportedDataTypes[]
  );

  const [isSeriesValid, setIsSeriesValid] = useState<boolean>(false);
  const [showJoiningColumns, setShowJoiningColumns] = useState(true);
  const [debouncedValue, setDebouncedValue] = useState(yzAxisSeries.axis_name);

  const [, cancel] = useDebounce(
    () => {
      updateDataSource(yzAxisSeries, slug);
    },
    1000,
    [yzAxisSeries.axis_name]
  );

  useEffect(() => {
    if (seriesData && Object.keys(seriesData).length > 0) {
      setYZAxisSeries(seriesData);
      populateOperatorOptions(
        seriesData?.["data_type_selected"]?.["value"] || ""
      );
      validateSeries();
    }
  }, [seriesData]);

  useEffect(() => {
    let _series = { ...seriesData };
    _series.isValid = isSeriesValid;
    updateDataSource(_series, slug);
  }, [isSeriesValid]);

  const validateSeries = () => {
    let _series = { ...seriesData };
    let _isValid = false;

    if (
      isNestedEmpty(_series["data_type_selected"]) ||
      isNestedEmpty(_series["data_source"]) ||
      !_series["axis_name"]
    ) {
      _isValid = false;
    } else if (showJoiningColumns) {
      if (
        isNestedEmpty(_series["joining_column"]) ||
        isNestedEmpty(_series["base_column"])
      ) {
        _isValid = false;
      } else {
        _isValid = true;
      }
    } else {
      _isValid = true;
    }
    setIsSeriesValid(_isValid);
  };

  useEffect(() => {
    let _xSeriesDataSource: DataSource = {
      columnId: "",
      columnName: "",
      sheetId: "",
      sheetName: "",
      workbookId: "",
      workbookName: "",
      thumbnail: "",
    };
    if (xSeries.length) {
      _xSeriesDataSource = xSeries[0]["data_source"];
    }

    if (
      yzAxisSeries.data_source.workbookId === "" &&
      yzAxisSeries.data_source.sheetId === ""
    ) {
      setShowJoiningColumns(false);
      return;
    }
    if (
      yzAxisSeries.data_source.workbookId !== "" &&
      yzAxisSeries.data_source.sheetId !== ""
    ) {
      if (_xSeriesDataSource) {
        if (
          _xSeriesDataSource.workbookId ===
            yzAxisSeries.data_source.workbookId &&
          _xSeriesDataSource.sheetId === yzAxisSeries.data_source.sheetId
        ) {
          setShowJoiningColumns(false);
          resetJoiningAndBaseColumn();
        } else {
          setShowJoiningColumns(true);
          getBaseColumnOptions(_xSeriesDataSource);
        }
      }
    }
  }, [JSON.stringify(xSeries[0]), yzAxisSeries.data_source]);

  const resetJoiningAndBaseColumn = () => {
    let updatedSeriesData = { ...yzAxisSeries };
    updatedSeriesData.joining_column = {
      column_name: "",
      connection_id: "",
      table: "",
    };
    updatedSeriesData.base_column = {
      column_name: "",
      connection_id: "",
      table: "",
    };

    updateDataSource(updatedSeriesData, slug);
  };

  const getBaseColumnOptions = (_xSeriesDataSource: DataSource) => {
    if (_xSeriesDataSource.workbookId && _xSeriesDataSource.sheetId) {
      let req = {
        filter_type: "column",
        workbook: _xSeriesDataSource.workbookId,
        sheet: _xSeriesDataSource.sheetId,
      };
      getAllWorkbookSheetsColumn(req).then((res) => {
        if (res.status === 200) {
          setBaseColumnOptions(res.data.data);
        }
      });
    }
  };

  const updateDataSourceSelection = (data: any) => {
    let xaxisDataSource: DataSource = xSeries[0]["data_source"];
    let updatedYZSeriesData = { ...yzAxisSeries };
    updatedYZSeriesData.data_source = data;

    if (
      xaxisDataSource.workbookId === data.workbookId &&
      xaxisDataSource.sheetId === data.sheetId
    ) {
      setShowJoiningColumns(false);
      updatedYZSeriesData.joining_column = {
        column_name: "",
        connection_id: "",
        table: "",
      };
      updatedYZSeriesData.base_column = {
        column_name: "",
        connection_id: "",
        table: "",
      };
    } else {
      setShowJoiningColumns(true);
      let req = {
        filter_type: "column",
        workbook: data.workbookId,
        sheet: data.sheetId,
      };
      getAllWorkbookSheetsColumn(req).then((res) => {
        if (res.status === 200) {
          setJoiningColumnOptions(res.data.data);
        }
      });
    }
    updateDataSource(updatedYZSeriesData, slug);
  };

  const handleChangeAxisName = (event: ChangeEvent<HTMLInputElement>) => {
    let updatedYZSeriesData = { ...yzAxisSeries };
    updatedYZSeriesData.axis_name = event.target.value;
    setYZAxisSeries(updatedYZSeriesData);
    setDebouncedValue(event.target.value);
  };

  const onDataTypeChange = (event: any) => {
    let value = event.target.value;
    let updatedYZSeriesData = { ...yzAxisSeries };
    if (!value) {
      updatedYZSeriesData.data_type_selected = {
        name: "",
        value: "",
      };
    } else {
      updatedYZSeriesData.data_type_selected = {
        name: value.split(" ").map(capitalize).join(" "),
        value: value,
      };
      updatedYZSeriesData.operator = "none";
      if (value) {
        populateOperatorOptions(value);
      }
    }
    updateDataSource(updatedYZSeriesData, slug);
  };

  const populateOperatorOptions = (value: string) => {
    if (!value) {
      return;
    }
    let filteredConfigOptions = filterOperatorsForSelectedDataType(
      configs,
      value
    );
    if (filteredConfigOptions.length) {
      setAllOperatorOptions(
        operatorOptionsTransformer(filteredConfigOptions[0]["operator_list"]) ||
          []
      );
    }
  };

  useEffect(() => {
    let yzSeriesData = { ...seriesData };
    yzSeriesData.isValid = isSeriesValid;
    updateDataSource(yzSeriesData, slug);
  }, [isSeriesValid]);

  const onJoiningColumnChange = (event: any) => {
    let value = event.target.value;

    let updatedYZSeriesData = { ...yzAxisSeries };
    if (!value) {
      updatedYZSeriesData.joining_column = {
        connection_id: "",
        column_name: "",
        table: "",
      };
    } else {
      updatedYZSeriesData.joining_column = {
        connection_id: yzAxisSeries.data_source.workbookId,
        column_name: value,
        table: yzAxisSeries.data_source.sheetId,
      };
    }
    updateDataSource(updatedYZSeriesData, slug);
  };

  const onBaseColumnChange = (event: any) => {
    let value = event?.target.value;
    let updatedYZSeriesData = { ...yzAxisSeries };
    if (!value) {
      updatedYZSeriesData.base_column = {
        column_name: "",
        connection_id: "",
        table: "",
      };
    } else {
      updatedYZSeriesData.base_column = {
        column_name: value,
        connection_id: xSeries[0].data_source.workbookId || "",
        table: xSeries[0].data_source.sheetId || "",
      };
    }
    updateDataSource(updatedYZSeriesData, slug);
  };

  const onOperatorChange = (event: any) => {
    let value = event.target.value;
    let updatedYZSeriesData = { ...yzAxisSeries };

    if (!value) {
      updatedYZSeriesData.operator = "none";
    } else {
      updatedYZSeriesData.operator = value;
    }
    updateDataSource(updatedYZSeriesData, slug);
  };

  return (
    <>
      <Grid container alignItems={"center"} spacing={2} width={"100%"}>
        <Grid item sm={3}>
          <SelectDataSource
            savedData={yzAxisSeries.data_source}
            updateDataSourceSelection={updateDataSourceSelection}
          />
        </Grid>
        <Grid item sm={1.7}>
          <TextField
            label={
              <Typography
                variant="inter_500_12"
                sx={{ color: theme.custom.primaryGreyColor }}
              >
                Data Type
              </Typography>
            }
            id={"data-type-label" + id}
            select
            size="small"
            value={yzAxisSeries.data_type_selected?.value || ""}
            fullWidth
            onChange={onDataTypeChange}
          >
            {dataTypes &&
              dataTypes.length > 0 &&
              dataTypes.map((ele: any) => {
                return (
                  <MenuItem
                    sx={{ height: 40 }}
                    key={ele.value}
                    value={ele.slug || ""}
                  >
                    <Typography variant="inter_p_500_14" mt={0.3}>
                      {ele.name}
                    </Typography>
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
        <Grid item sm={1.7}>
          <TextField
            size="small"
            inputProps={{
              maxLength: 50,
              style: {
                fontFamily: fontInter,
                fontSize: "14px",
                fontWeight: 500,
                color: theme.custom.primaryDarkColor,
              },
            }}
            value={yzAxisSeries.axis_name || ""}
            label={
              <Typography
                variant="inter_500_12"
                sx={{ color: theme.custom.primaryGreyColor }}
              >
                Axis name
              </Typography>
            }
            fullWidth
            onChange={handleChangeAxisName}
          />
        </Grid>

        {showJoiningColumns && (
          <Grid item sm={1.7}>
            <TextField
              label={
                <Typography
                  variant="inter_500_12"
                  sx={{ color: theme.custom.primaryGreyColor }}
                >
                  Y axis joining Column
                </Typography>
              }
              id={"joining-column-label" + id}
              select
              size="small"
              value={yzAxisSeries.joining_column.column_name || ""}
              fullWidth
              onChange={(e) => onJoiningColumnChange(e)}
            >
              {joiningColumnOptions &&
                joiningColumnOptions.length > 0 &&
                joiningColumnOptions.map((ele: any) => {
                  return (
                    <MenuItem
                      sx={{ height: 40 }}
                      key={ele.value}
                      value={ele.value}
                    >
                      <Typography variant="inter_p_500_14" mt={0.3}>
                        {ele.label}
                      </Typography>
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
        )}
        {showJoiningColumns && (
          <Grid item sm={1.7}>
            <TextField
              label={
                <Typography
                  variant="inter_500_12"
                  sx={{ color: theme.custom.primaryGreyColor }}
                >
                  X axis Joining Column
                </Typography>
              }
              id={"base-column-label" + id}
              select
              size="small"
              value={yzAxisSeries.base_column.column_name || ""}
              fullWidth
              onChange={(e) => onBaseColumnChange(e)}
            >
              {baseColumnOptions &&
                baseColumnOptions.length > 0 &&
                baseColumnOptions.map((ele: any) => {
                  return (
                    <MenuItem
                      sx={{ height: 40 }}
                      key={ele.value}
                      value={ele.value || ""}
                    >
                      <Typography variant="inter_p_500_14" mt={0.3}>
                        {ele.label}
                      </Typography>
                    </MenuItem>
                  );
                })}
            </TextField>
          </Grid>
        )}

        <Grid item sm={1.7}>
          <TextField
            label={
              <Typography
                variant="inter_500_12"
                sx={{ color: theme.custom.primaryGreyColor }}
              >
                Operator
              </Typography>
            }
            select
            id={"operator" + id}
            size="small"
            value={yzAxisSeries.operator}
            fullWidth
            onChange={(e) => onOperatorChange(e)}
          >
            <MenuItem value="none">
              <Typography variant="inter_p_500_14" mt={0.3}>
                None{" "}
              </Typography>
            </MenuItem>
            {operatorOptions &&
              operatorOptions.length > 0 &&
              operatorOptions.map((ele: any) => {
                return (
                  <MenuItem
                    sx={{ height: 40 }}
                    key={ele.value || ""}
                    value={ele.value}
                  >
                    <Typography variant="inter_p_500_14" mt={0.3}>
                      {ele.label}
                    </Typography>
                  </MenuItem>
                );
              })}
          </TextField>
        </Grid>
      </Grid>
    </>
  );
};

export default DataSourceYZAxisComponent;
