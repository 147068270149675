import { Box, Grid, Tooltip, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { updateBrandColors } from "../../../services/impact-report-service";
import { theme } from "../../../theme";
import RemoveCircleIcon from "../../../assets/remove_circle.png";
import AddBoxIcon from "../../../assets/add_box.png";
import styled from "@emotion/styled";
import { ThemeColorPicker } from "./ThemeColorPicker";
import { addAlphaToHexCode } from "../../../utils/color-picker-helper";
import { setSettingEditActive } from "../../../store/selected-design-element-store";
import { useDispatch } from "react-redux";

const Swatch = styled("div")({
  width: "24px",
  height: "24px",
  boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.15)",
  border: "1px solid #fff",
  borderRadius: "6px",
  margin: "2px",
  display: "inline-block",
  cursor: "pointer",
});

function BrandColorLibrary(props: any) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [currentColor, setCurrentColor] = useState("");
  const [colorSelectionAction, setColorSelectionAction] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState<Number | null>(null);

  useEffect(() => {
    if (colorSelectionAction === "remove_color") {
      updateBrandColorList(currentColor);
    }
  }, [colorSelectionAction]);

  const updateBrandColorsAPI = (brandColor: Array<"">) => {
    updateBrandColors({ colors: brandColor }).then((resp) => {
      if (resp.status === 403) {
        return;
      }
      if (resp.status === 201 || resp.status === 200) {
        props.setBrandColors(resp.data.colors);
      }
    });
  };

  const dispatch = useDispatch();
  const togglePicker = () => {
    if(!displayColorPicker) {
      dispatch(setSettingEditActive(true));
    } else {
      dispatch(setSettingEditActive(false));
    }
    setDisplayColorPicker(!displayColorPicker);
  };

  const updateBrandColorList = (color: string) => {
    let modifyBrandColors = props.brandColors;
    if (colorSelectionAction === "add_color") {
      modifyBrandColors[modifyBrandColors.length - 1] = color;
      setColorSelectionAction("select_color");
    } else if (colorSelectionAction === "remove_color") {
      let index = modifyBrandColors.indexOf(color);
      modifyBrandColors.splice(index, 1);
      setColorSelectionAction("select_color");
    } else {
      let index = modifyBrandColors.indexOf(currentColor);
      if (~index) {
        modifyBrandColors[index] = color;
      }
    }
    setCurrentColor(color);
    updateBrandColorsAPI(modifyBrandColors);
  };

  const updateHexCode = (hexCode: string) => {
    updateBrandColorList(hexCode);
  };

  return (
    <>
      <Box mb={1}>
        <Grid item md={10}>
          <Grid container justifyContent={"space-between"} alignItems="center">
            <Grid item>
              <Typography
                variant="inter_p_600_16"
                sx={{ color: theme.custom.primaryDarkColor }}
              >
                Colors
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <Box>
            {props.brandColors.map((color: string, index: number) => {
              return (
                <Box
                  key={color}
                  onMouseEnter={() => {
                    setHoveredIndex(index);
                  }}
                  onMouseLeave={() => {
                    setHoveredIndex(null);
                  }}
                  sx={{
                    display: "inline-block",
                  }}
                >
                  <Swatch
                    key={color}
                    style={{
                      background: color,
                      cursor: "pointer",
                      outline:
                        currentColor.toLowerCase() === color.toLowerCase()
                          ? `1px solid ${theme.custom.primaryDarkColor}`
                          : "",
                      boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.15)"
                    }}
                    onClick={(event) => {
                      setColorSelectionAction("select_color");
                      setCurrentColor(color);
                      setDisplayColorPicker(true);
                      dispatch(setSettingEditActive(true));
                    }}
                  />
                  {!displayColorPicker && hoveredIndex === index ? (
                    <span style={{ position: "relative" }}>
                      <img
                        src={RemoveCircleIcon}
                        alt="Remove Color"
                        style={{
                          maxHeight: "22px",
                          position: "absolute",
                          left: "-15px",
                          bottom: "20px",
                        }}
                        onClick={(event) => {
                          setColorSelectionAction("remove_color");
                          setCurrentColor(color);
                          setDisplayColorPicker(false);
                          dispatch(setSettingEditActive(false));
                        }}
                      />
                    </span>
                  ) : (
                    ""
                  )}
                </Box>
              );
            })}
            <Tooltip title="Add Color">
              <img
                src={AddBoxIcon}
                alt="Add Color"
                style={{ maxHeight: "28px", cursor: "pointer" }}
                onClick={(event) => {
                  setDisplayColorPicker(true);
                  setColorSelectionAction("add_color");
                  dispatch(setSettingEditActive(true));
                  props.setBrandColors([...props.brandColors, "#f7f7f7"]);
                  setCurrentColor("#f7f7f7");
                }}
              />
            </Tooltip>
          </Box>
        </Grid>
        <Grid item md={12}>
          {displayColorPicker && (
            <ThemeColorPicker
              closePicker={togglePicker}
              isShapes={true}
              colorSelector={true}
              onChange={(colour: any) =>
                updateBrandColorList(
                  addAlphaToHexCode(colour.hex, colour.rgb["a"])
                )
              }
              updateHexCode={updateHexCode}
              color={currentColor}
              colorPickerTitle={"Fill color"}
            />
          )}
        </Grid>
      </Box>
    </>
  );
}

export default React.memo(BrandColorLibrary);
