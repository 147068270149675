import { Tooltip } from "@mui/material";
import React, { useState } from "react";
import BubbleMenu from "../../atoms/BubbleMenu";
import MagicStick from "../../../assets/magic_wand_multi_color.png";
import MagicWandAnimation from "../../../../src/assets/magic_wand_animation.gif";

function KPIBubbleMenu(props: any) {
  const {
    id,
    element,
    loadingAddSmartStatementElement,
    disableSmartStatement,
    handleSmartStatementBulbClick,
    duplicateElement,
    deleteElement,
  } = props;

  return (
    <>
      <BubbleMenu
        id={id}
        element={element}
        duplicateElement={duplicateElement}
        deleteElement={deleteElement}
      >
        {loadingAddSmartStatementElement ? (
          <img
            src={MagicWandAnimation}
            alt="Loader"
          />
        ) : disableSmartStatement ? (
          <Tooltip
            title={
              "Smart statement is disabled due to data size. Please change filters to enable"
            }
            placement="top"
          >
            <img
              style={{
                filter: "gray",
                WebkitFilter: "grayscale(100%)",
                opacity: 0.3,
              }}
              src={MagicStick}
              alt="magic_stick"
            />
          </Tooltip>
        ) : (
          <Tooltip
            title={"Add analysis by Pebble AI instantly"}
            placement="top"
          >
            <img
              src={MagicStick}
              onClick={(e) => {
                handleSmartStatementBulbClick();
              }}
              alt="magic_stick"
            />
          </Tooltip>
        )}
      </BubbleMenu>
    </>
  );
}

export default React.memo(KPIBubbleMenu);
