import React from "react";
import NumberFormat from "react-number-format";

const NumberTextFieldComponent = (props: any) => {
  const { inputRef, onChange, min, max, decimal, ...other } = props;

  const handleChange = (event: any) => {
    onChange({
      target: {
        name: props.id,
        value: event.floatValue,
      },
    });
  };

  const validateNumbers = (inputObj: any) => {
    const { floatValue, formattedValue } = inputObj;
    if (formattedValue === "0") {
      onChange({
        target: {
          name: props.id,
          value: formattedValue,
        },
      });
      return true;
    }
    if (!formattedValue) {
      onChange({
        target: {
          name: props.id,
          value: " ",
        },
      });
      return false;
    }
    if (floatValue < min || floatValue > max) {
      return false;
    }
    return true;
  };

  return (
    <NumberFormat
      decimalScale={decimal}
      allowLeadingZeros={true}
      isAllowed={validateNumbers}
      {...other}
      getInputRef={inputRef}
      onValueChange={handleChange}
      thousandSeparator
    />
  );
};

export default NumberTextFieldComponent;
