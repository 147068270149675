import React, {memo, useEffect, useState} from "react";
import {Grid, Skeleton, Stack, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {getBudgetsAlertsList} from "../../../services/alerts-service";
import BudgetAlertsCard from "../../molecules/BudgetAlerts/BudgetAlertsCard";
import KPIMolecule from "../../molecules/KPIMolecule/KPIMolecule";
import AuthorizationErrorHandler from "../../molecules/Editor/AuthorizationErrorHandler";

interface Props {
}

const BudgetAlertsMonitoring = (props: Props) => {
  const [budgetAlerts, setBudgetAlerts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedBudget, setSelectedBudget] = useState<any>({});

  useEffect(() => {
    getBudgetAlertsList();
  }, [])

  const getBudgetAlertsList = () => {
    getBudgetsAlertsList().then(res => {
      if (res.status === 200 && res.data?.data?.response?.status === 200) {
        setBudgetAlerts(res.data?.data?.response?.data);
        setSelectedBudget(res.data?.data?.response?.data[0]);
        setLoading(false);
      }
    })
  }

  return <React.Fragment>
    <Stack direction="row">
      <Grid container>
        <Grid item md={12}>
          {!loading && budgetAlerts.length > 0 && (<Box
            px={6}
            sx={{
              zIndex: "auto",
            }}
          >
            <BudgetAlertsCard budgetAlerts={budgetAlerts} selectedBudget={selectedBudget} setSelectedBudget={setSelectedBudget} />
            <KPIMolecule
              inPanorama={true}
              customKPI={true}
              board_slug={"budget-tracker"}
              chartData={{
                position_x: 0,
                position_y: 0,
                column: 1,
                kpi_slug: "budget-comparison",
                kpi_title: "Budget Comparison",
                position: 1,
                row: 1
              }}
              filters={{
                program: selectedBudget.program,
                contract: selectedBudget.contract,
                budget_uid: selectedBudget.budget_uid,
                daterange: { start: selectedBudget.start_date, end: selectedBudget.end_date }
              }}
            />
          </Box>)
          }
          {!loading && budgetAlerts.length < 1 && (
            <Box px={6}>
              <AuthorizationErrorHandler
                errorMessage="
                  No Budget Alerts
                "
              ></AuthorizationErrorHandler>
            </Box>
          )}
          {loading && (
            <>
              <Skeleton
                sx={{ mx: 6, my: 2, bgcolor: "grey.100" }}
                variant="rectangular"
                animation="wave"
                height={400}
              />
            </>
          )}
        </Grid>
      </Grid>
    </Stack>
  </React.Fragment>
};

export default memo(BudgetAlertsMonitoring);