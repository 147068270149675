import React, { useState } from "react";
import { ChartData } from "../../Types/ChartData";
import KPIMolecule from "../molecules/KPIMolecule/KPIMolecule";
import { WidthProvider, Responsive } from "react-grid-layout";
import "react-resizable/css/styles.css";
import customResizeHandleLogo from "../../assets/resize-handle.png";
import { updateBoardLayout } from "../../services/board-service";
import { filter } from "lodash";
interface Props {
  data: Array<ChartData>;
  filters: any;
  board_slug: string;
  belongToCustomBoard?: boolean;
  board_type: string;
  showSmartStatement?: boolean;
  extraProps?: any;
}

const ResponsiveGridLayout = WidthProvider(Responsive);
const originalLayouts = {};
const KPIList: React.FC<Props> = (data) => {
  const [layouts, setLayouts] = useState<any>(originalLayouts);
  let board_slug = data.board_slug;

  const [breakPointType, setBreakPointType] = useState("");
  const onLayoutChange = (layout: any, newLayouts: any) => {
    saveNewLayout(layout);
    setLayouts(newLayouts);
  };

  const CustomResizeHandle = (props: any) => {
    return (
      <div
        style={{
          ...resizeHandleStyle,
          bottom:
            data.board_slug === "expense-proof-and-invoice-tracking"
              ? -80
              : 0,
        }}
        ref={props.innerRef}
        {...props}
      />
    );
  };

  const saveNewLayout = (layout: any) => {
    updateBoardLayout(layout, data);
  };

  const resizeHandleStyle = {
    position: "absolute",
    width: "20px",
    height: "20px",
    backgroundRepeat: "no-repeat",
    backgroundOrigin: "content-box",
    boxSizing: "border-box",
    backgroundImage: `url(${customResizeHandleLogo})`,
    backgroundPosition: "bottom right",
    padding: "0 3px 3px 0",
    right: "25px",
  };

  const returnUpdatedWidth = (slug: string) => {
    if (!breakPointType) return;
    return filter(layouts[breakPointType], (e: any) => {
      return slug === e["i"];
    });
  };

  return (
    <React.Fragment>
      <ResponsiveGridLayout
        onBreakpointChange={(el: any) => {
          setBreakPointType(el);
        }}
        isDraggable={data.board_type === "grid"}
        draggableHandle=".drag-handle"
        margin={[-25, 30]}
        rowHeight={150}
        isResizable={data.board_type === "grid" || data.board_type === "table"}
        className="layout"
        preventCollision={false}
        layouts={layouts}
        onLayoutChange={(layout, layouts) => onLayoutChange(layout, layouts)}
        cols={{ lg: 12, md: 12, sm: 12, xs: 2, xxs: 2 }}
        resizeHandles={["se"]}
        resizeHandle={(handleAxis: any, ref: React.Ref<HTMLDivElement>) => (
          <CustomResizeHandle
            innerRef={ref}
            className={`react-resizable-handle react-resizable-handle-${handleAxis}`}
          />
        )}
      >
        {data.data.map((ele: ChartData, index: number) => {
          return (
            <div
              id={ele.kpi_slug}
              style={{ width: "100%", height: "100%" }}
              key={ele.kpi_slug}
              className={`kpi_${ele.kpi_slug}`}
              data-grid={{
                x: ele["position_x"] || 0,
                y: ele["position_y"] || 0,
                w: ele["column"] || 6,
                h: ele["row"] || 3,
                minW: 2,
                minH: 2,
              }}
            >
              <KPIMolecule
                key={index}
                chartData={ele}
                customKPI={false}
                gridMetaData={ele}
                filters={{ ...data.filters, ...ele["filters"] }}
                belongToCustomBoard={data.belongToCustomBoard}
                board_slug={board_slug}
                board_type={data.board_type}
                showSmartStatement={data.showSmartStatement || false}
                layoutInfo={returnUpdatedWidth(ele.kpi_slug)}
                extraProps={data.extraProps}
              />
            </div>
          );
        })}
      </ResponsiveGridLayout>
    </React.Fragment>
  );
};

export default KPIList;
