import {
  Stack,
  Box,
  Grid,
  Skeleton,
  Dialog,
  DialogContent,
  Typography,
  Button,
} from "@mui/material";

import React, { useEffect, useState } from "react";

import { Sidebar } from "../components/complexes/Sidebar";
import { theme } from "../theme";
import QuickActionsItem from "../components/atoms/QuickActionsItem";
import { QuickActions } from "../Types/QuickActionItem";
import {
  getAllFavouriteKPIs,
  getAllQuickActions,
  getAllTopBudgets,
  getAllReportsAndTemplates,
} from "../services/panorama-service";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation } from "swiper";
import "swiper/swiper.scss"; // core Swiper
import { useNavigate } from "react-router-dom";
import { QuickBudgetItemTypes } from "../Types/QuickBudgetItemTypes";
import TopBudgetItem from "../components/atoms/TopBudgetItem";
import KPIMolecule from "../components/molecules/KPIMolecule/KPIMolecule";
import ImpactReportItem from "../components/molecules/Editor/ImpactReportItem";
import TemplateThumbnailComponent from "../components/atoms/TemplateThumbnailComponent";
import DefaultA4Thumbnail from "../assets/thumbnailA4.png";
import CreateNewImpactReport from "../components/molecules/Editor/CreateImpactReport";
import GenerateContentModal from "../components/atoms/GenerateContentModal";
import { CompaniesObject } from "../Types/CompaniesObject";
import { getCompanies, getCompanyId } from "../services/userinfo-service";
import SpotlightDialog from "../components/molecules/SpotlightDialog";
import { RecentHistory } from "../Types/UserObject";
import "./PanoramaPage.scss";
import { dateFormatter } from "../utils/dataFormatters";
import BudgetTrackerFilterDate from "../components/molecules/BudgetTracker/BudgetTrackerFilterDate";
import DateRangePickerCustomComponent from "../components/atoms/DateRangePickerCustomComponent";
import TickIcon from "../assets/TickIcon.png";
import { map, orderBy } from "lodash";
import {
  createReport,
  defaultReportSize,
  getDefaultThumbnail,
} from "../services/impact-report-service";
import { useAppDispatch } from "../store/hook";
import { showMessage } from "../store/error-handler-store";
type Props = {};

const PanoramaPage = (props: Props) => {
  const navigate = useNavigate();

  const [quickActions, setQuickActions] = useState([] as QuickActions[]);
  const [topBudgets, setTopBudgets] = useState({
    budgets: [] as QuickBudgetItemTypes[],
    asOfDate: "",
    status: 0,
    errorMessage: "",
  });
  const [favouriteKPIs, setFavouriteKPIs] = useState({
    data: [] as any[],
    filters: {} as any,
  });
  const [templatesAndReports, setTemplatesAndReports] = useState([] as any[]);
  const [selectedTemplate, setSelectedTemplate] = useState<any | null>(null);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openGenerateContentPopup, setOpenGenerateContentPopup] =
    useState<boolean>(false);
  const [quickActionsLoading, setQuickActionsLoading] = useState<boolean>(true);
  const [topBudgetsLoading, setTopBudgetsLoading] = useState<boolean>(true);
  const [favouriteKPILoading, setFavouriteKPILoading] = useState<boolean>(true);
  const [reportsLoading, setReportsLoading] = useState<boolean>(true);
  const [companies, setCompanies] = useState<CompaniesObject[]>([]);
  const [openGS, setOpenGS] = React.useState<boolean>(false);
  const [history, setHistory] = useState<RecentHistory[]>(
    JSON.parse(localStorage.getItem("recent_history") || "[]")
  );

  const [dateFilterOpen, setDateFilterOpen] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const location = window.location.pathname;
    let newHistory = history;
    const existingLocationIdx = history.findIndex(
      (i) => i.location === location
    );
    if (existingLocationIdx !== -1) {
      newHistory.splice(existingLocationIdx, 1);
    }
    newHistory = [
      { title: document.title, location },
      ...newHistory.slice(0, 4),
    ];
    setHistory(newHistory);
    localStorage.setItem("recent_history", JSON.stringify(newHistory));
  }, [window.location.pathname, document.title]);

  useEffect(() => {
    getQuickActions();
    getTopBudgets();
    getFavouriteKPIs();
    getReportsAndTemplates();
    getAllCompanies();
    document.title = "Panorama";
  }, []);

  const getAllCompanies = () => {
    getCompanies().then((res) => {
      if (res?.data?.data) {
        setCompanies(res.data.data);
      }
    });
  };

  const getQuickActions = () => {
    getAllQuickActions()
      .then((res) => {
        if (res && res.status === 200) {
          setQuickActions(
            orderBy(
              map(res.data, (val) => {
                val = { ...val, position: val["meta"]["position"] };
                return val;
              }),
              ["position"],
              ["asc"]
            )
          );
          setQuickActionsLoading(false);
        }
      })
      .finally(() => {
        setQuickActionsLoading(false);
      });
  };

  const getTopBudgets = (date: string = "") => {
    getAllTopBudgets(date)
      .then((res) => {
        if (res.status === 403) {
          setTopBudgets((prev) => {
            return {
              ...prev,
              budgets: [],
              status: res.status,
              errorMessage:
                "You don’t have access to see Budget items. Please reach out to admin to get access",
            };
          });
          return;
        }
        if (res && res.status === 200) {
          if (res.data?.data.length === 0) {
            setTopBudgets((prev) => {
              return {
                ...prev,
                budgets: res.data.data,
                asOfDate: res.data.as_of,
                errorMessage: "No active Budgets found.",
                status: res.status,
              };
            });
            return;
          }
          setTopBudgets((prev) => {
            return {
              ...prev,
              budgets: res.data.data,
              asOfDate: res.data.as_of,
              errorMessage: "",
              status: res.status,
            };
          });
          setTopBudgetsLoading(false);
        }
      })
      .finally(() => {
        setTopBudgetsLoading(false);
      });
  };

  const getFavouriteKPIs = (filters: any = {}) => {
    getAllFavouriteKPIs(filters)
      .then((res) => {
        if (res && res.status === 200) {
          setFavouriteKPIs({
            data: res.data?.data || [],
            filters: res.data?.filters || {},
          });
          setFavouriteKPILoading(false);
        }
      })
      .then(() => {
        setFavouriteKPILoading(false);
      });
  };

  const getReportsAndTemplates = () => {
    getAllReportsAndTemplates().then((res) => {
      if (res && res.status === 200) {
        let final: any = [];
        let _temp = { ...res.data };
        Object.keys(_temp).forEach((ele: string) => {
          _temp[ele] = _temp[ele].map((el: any) => {
            return { ...el, type: ele };
          });
          final = [...final, ..._temp[ele]];
        });
        setTemplatesAndReports(final);
        setReportsLoading(false);
      }
    });
  };

  const onItemClick = (type: string, metaData: any) => {
    switch (type) {
      case "module":
        navigate(metaData?.["route"]);
        break;
      case "board":
        navigate(metaData?.["route"]);
        break;
      case "generate-content":
        setOpenGenerateContentPopup(true);
        break;
      case "search":
        setOpenGS(true);
        break;

      default:
        break;
    }
  };

  const editReport = (reportName: string, reportId: string) => {
    navigate(`/impact-report/${reportName}_${reportId}`);
  };

  const handleTemplateClick = (item: any) => {
    setSelectedTemplate(item);
    setOpenPopup(true);
  };

  const setEditTemplate = (template: any) => {
    let reportData = {
      name: template?.attributes?.title,
      template_id: template?.id,
      is_template_edit_flow: true,
      company_uid: getCompanyId(),
      attributes: defaultReportSize,
    };
    createReport(reportData).then((res) => {
      if (res.status === 403) {
        dispatch(
          showMessage({
            message:
              "You don’t have access to edit this template. Please reach out to admin to get access",
            variant: "error",
          })
        );
        return;
      }
      if (res.status === 201) {
        navigate(`/impact-report/${res["data"]["name"]}_${res["data"]["uid"]}`);
      }
    });
  };

  const onPopupClose = (popupOpen: boolean) => {
    setOpenPopup(popupOpen);
    setSelectedTemplate(null);
  };

  return (
    <>
      <Stack
        sx={{ width: "calc(100% - 105px)" }}
        direction="row"
        className="panorama-page-wrapper"
      >
        <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
          <Sidebar />
        </Box>
        <Box width={"100%"} pl={5} py={5}>
          <Grid sx={{ height: "100%" }} container>
            {!quickActionsLoading ? (
              <Grid sm={12} item sx={{ padding: 1 }}>
                {quickActions && quickActions.length > 0 && (
                  <Swiper
                    style={{ width: "94%", height: 150, padding: "10px" }}
                    slidesPerView={"auto"}
                    spaceBetween={20}
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                  >
                    {quickActions &&
                      quickActions.length > 0 &&
                      quickActions.map((item: QuickActions, index: number) => (
                        <SwiperSlide
                          style={{
                            maxWidth: "200px",
                            minWidth: 200,
                            paddingTop: 20,
                          }}
                        >
                          <QuickActionsItem
                            onClick={onItemClick}
                            key={item.id + "-" + index}
                            item={item}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                )}
              </Grid>
            ) : (
              <Grid spacing={2} container>
                {[...Array(4)].map((ele: any) => {
                  return (
                    <Grid item sm={3}>
                      <Skeleton height={150} width={"100%"} />
                    </Grid>
                  );
                })}
              </Grid>
            )}

            {!quickActionsLoading && quickActions.length === 0 && (
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                sx={{
                  background: theme.custom.bgImageColor,
                  borderRadius: 3,
                  minHeight: 100,
                }}
              >
                <Grid item>
                  <Typography
                    sx={{ color: theme.custom.primaryGreyColor }}
                    variant="inter_p_500_14"
                  >
                    No Quick actions found.
                  </Typography>
                </Grid>
              </Grid>
            )}
            {!topBudgetsLoading && (
              <Grid sm={11.4} item sx={{ width: "94%", padding: 1 }}>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Typography
                        sx={{ pl: "10px" }}
                        variant="poppins_h6_600_16"
                      >
                        Budgets
                      </Typography>
                      {topBudgets.status !== 403 && (
                        <Typography
                          sx={{
                            pl: "10px",
                            marginTop: 0.2,
                            color: theme.custom.primaryGreyColor,
                          }}
                          variant="inter_500_10"
                        >
                          As of &nbsp;
                          <span
                            onClick={() => setDateFilterOpen(true)}
                            style={{
                              textDecoration: "underline",
                              textDecorationStyle: "dotted",
                              cursor: "pointer",
                            }}
                          >
                            {dateFormatter(topBudgets.asOfDate, "MMM DD, YYYY")}
                          </span>
                        </Typography>
                      )}
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack direction={"row"}>
                      <Button
                        sx={{ mr: 1 }}
                        onClick={() => {
                          navigate("/budgets");
                        }}
                        variant="text"
                      >
                        <Typography variant="poppins_600_12">
                          Manage budgets
                        </Typography>
                      </Button>
                      <Button
                        variant="text"
                        onClick={() => {
                          navigate("/budgets-monitoring");
                        }}
                      >
                        <Typography variant="poppins_600_12">
                          Monitor Budgets
                        </Typography>
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!topBudgetsLoading ? (
              <Grid sm={12} item sx={{ padding: 1 }}>
                {topBudgets &&
                  topBudgets.budgets &&
                  topBudgets.budgets.length > 0 && (
                    <Swiper
                      style={{ height: "auto", padding: "10px" }}
                      slidesPerView={"auto"}
                      spaceBetween={20}
                      navigation={true}
                      modules={[Navigation]}
                      className="mySwiper"
                    >
                      {topBudgets &&
                        topBudgets.budgets.length > 0 &&
                        topBudgets.budgets.map(
                          (item: QuickBudgetItemTypes, index: number) => (
                            <SwiperSlide style={{width: "auto"}}>
                              <TopBudgetItem key={index} item={item} as_of={dateFormatter(topBudgets.asOfDate, "MMM DD, YYYY")}/>
                            </SwiperSlide>
                          )
                        )}
                    </Swiper>
                  )}
              </Grid>
            ) : (
              <Grid spacing={2} container>
                {[...Array(4)].map((ele: any) => {
                  return (
                    <Grid item sm={3}>
                      <Skeleton height={200} width={"100%"} />
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {!topBudgetsLoading &&
              topBudgets &&
              topBudgets.budgets &&
              topBudgets.budgets.length === 0 && (
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    background: theme.custom.bgImageColor,
                    borderRadius: 3,
                    mb: 3,
                    minHeight: 100,
                  }}
                >
                  <Grid item>
                    <Typography
                      sx={{ color: theme.custom.primaryGreyColor }}
                      variant="inter_p_500_14"
                    >
                      {topBudgets.errorMessage}
                    </Typography>
                  </Grid>
                </Grid>
              )}

            {!favouriteKPILoading && (
              <Grid sm={11.4} item sx={{ padding: 1 }}>
                <Grid
                  container
                  alignItems={"center"}
                  justifyContent={"space-between"}
                >
                  <Grid item>
                    <Stack alignItems={"center"} direction={"row"}>
                      <Typography
                        sx={{ pl: "10px", mr: 1 }}
                        variant="poppins_h6_600_16"
                      >
                        Performance
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item>
                    <Stack direction={"row"}>
                      <Button
                        sx={{ mr: 1 }}
                        onClick={() => {
                          navigate("/metrics/financial");
                        }}
                        variant="text"
                      >
                        <Typography variant="poppins_600_12">
                          View all
                        </Typography>
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {!favouriteKPILoading ? (
              <Grid sm={12} item sx={{ padding: 1 }}>
                {favouriteKPIs &&
                  favouriteKPIs.data &&
                  favouriteKPIs.data.length > 0 && (
                    <Swiper
                      style={{ width: "94%", height: 350, padding: "10px" }}
                      slidesPerView={2}
                      breakpoints={{
                        500: { slidesPerView: 2 },
                        1200: { slidesPerView: 2 },
                        1400: { slidesPerView: 2 },
                        1600: { slidesPerView: 3 },
                        1800: { slidesPerView: 3 },
                        2000: { slidesPerView: 3 },
                        2400: { slidesPerView: 4 },
                      }}
                      preventClicks={false}
                      preventClicksPropagation={false}
                      simulateTouch={false}
                      spaceBetween={20}
                      navigation={true}
                      modules={[Navigation]}
                      className="mySwiper"
                    >
                      {favouriteKPIs &&
                        favouriteKPIs.data &&
                        favouriteKPIs.data.length > 0 &&
                        favouriteKPIs.data.map((item: any, index: number) => (
                          <SwiperSlide
                            style={{
                              maxWidth: "450px",
                              minWidth: 450,
                            }}
                          >
                            <KPIMolecule
                              key={index}
                              chartData={{
                                height: 600,
                                width: 400,
                                kpi_slug: item.slug,
                                kpi_title: item.meta_data.chart.title,
                              }}
                              customKPI={false}
                              filters={{}}
                              showSmartStatement={false}
                              availableChartData={item}
                              inPanorama={true}
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  )}
              </Grid>
            ) : (
              <Grid spacing={2} container>
                {[...Array(4)].map((ele: any) => {
                  return (
                    <Grid item sm={3}>
                      <Skeleton height={450} width={"100%"} />
                    </Grid>
                  );
                })}
              </Grid>
            )}

            {!favouriteKPILoading &&
              favouriteKPIs &&
              favouriteKPIs.data &&
              favouriteKPIs.data.length === 0 && (
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    background: theme.custom.bgImageColor,
                    borderRadius: 3,
                    minHeight: 100,
                  }}
                >
                  <Grid item>
                    <Typography
                      sx={{ color: theme.custom.primaryGreyColor }}
                      variant="inter_p_500_14"
                    >
                      No KPIs found.
                    </Typography>
                  </Grid>
                </Grid>
              )}

            <Grid sm={11.4} item sx={{ padding: 1 }}>
              <Grid
                container
                alignItems={"center"}
                justifyContent={"space-between"}
              >
                <Grid item>
                  <Typography sx={{ pl: "10px" }} variant="poppins_h6_600_16">
                    Reports
                  </Typography>
                </Grid>
                <Grid item>
                  <Stack direction={"row"}>
                    <Button
                      sx={{ mr: 1 }}
                      onClick={() => {
                        navigate("/create-impact-report");
                      }}
                      variant="text"
                    >
                      <Typography variant="poppins_600_12">
                        View all templates
                      </Typography>
                    </Button>
                    <Button
                      sx={{ mr: 1 }}
                      onClick={() => {
                        navigate("/impact-report");
                      }}
                      variant="text"
                    >
                      <Typography variant="poppins_600_12">
                        View all reports
                      </Typography>
                    </Button>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            {!reportsLoading ? (
              <Grid sm={12} item sx={{ padding: 1 }}>
                {templatesAndReports && templatesAndReports.length > 0 && (
                  <Swiper
                    style={{ width: "94%", height: 450, padding: "10px" }}
                    slidesPerView={1.5}
                    breakpoints={{
                      500: { slidesPerView: 2 },
                      1200: { slidesPerView: 3 },
                      1400: { slidesPerView: 4 },
                      1600: { slidesPerView: 24 },
                    }}
                    spaceBetween={20}
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                  >
                    {templatesAndReports &&
                      templatesAndReports.length > 0 &&
                      templatesAndReports.map((item: any, index: number) => {
                        if (item.type === "reports") {
                          return (
                            <SwiperSlide
                              style={{
                                maxWidth: "230px",
                                minWidth: 230,
                              }}
                            >
                              <Box
                                onClick={() => editReport(item.name, item.uid)}
                              >
                                <ImpactReportItem
                                  showOnlyTitle={true}
                                  is_editable={true}
                                  report_created={item?.created_at || ""}
                                  report_name={item.name}
                                  report_owner={item?.report_owner || ""}
                                  uid={item.uid}
                                  report_updated_at={
                                    item?.last_report_update_time || ""
                                  }
                                  report_updated_by={
                                    item?.last_modified_by_name || ""
                                  }
                                  thumbnail={item?.thumbnail || []}
                                  triggerReload={() => {}}
                                  extraSx={{ style: { height: 200 } }}
                                />
                              </Box>
                            </SwiperSlide>
                          );
                        } else if (item.type === "templates") {
                          return (
                            <SwiperSlide
                              style={{
                                maxWidth: "230px",
                                minWidth: 230,
                              }}
                            >
                              <div>
                                <TemplateThumbnailComponent
                                  handleSelect={() => handleTemplateClick(item)}
                                  onDeleteTemplate={() => {}}
                                  onEditTemplate={() => setEditTemplate(item)}
                                  style={{ height: 310 }}
                                  templateId={item.id}
                                  templateIsMutable={true}
                                  templateObj={item}
                                  title={item.handle}
                                  template_icon={
                                    item.thumbnail ||
                                    item.attributes.attributes.thumbnail ||
                                    getDefaultThumbnail(
                                      item.attributes.attributes.slug
                                    )
                                  }
                                  forPanoroma={true}
                                />
                              </div>
                            </SwiperSlide>
                          );
                        }
                      })}
                  </Swiper>
                )}
              </Grid>
            ) : (
              <Grid spacing={2} container>
                {[...Array(4)].map((ele: any) => {
                  return (
                    <Grid item sm={3}>
                      <Skeleton height={450} width={"100%"} />
                    </Grid>
                  );
                })}
              </Grid>
            )}
            {!reportsLoading &&
              templatesAndReports &&
              templatesAndReports.length === 0 && (
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    background: theme.custom.bgImageColor,
                    borderRadius: 3,
                    minHeight: 100,
                  }}
                >
                  <Grid item>
                    <Typography
                      sx={{ color: theme.custom.primaryGreyColor }}
                      variant="inter_p_500_14"
                    >
                      No reports/templates found.
                    </Typography>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Box>
        <CreateNewImpactReport
          close={() => setOpenPopup(false)}
          open={openPopup}
          selectedTemplate={selectedTemplate}
          closePreviewModal={() => onPopupClose(false)}
        />
        <GenerateContentModal
          openModal={openGenerateContentPopup}
          closeModal={() => {
            setOpenGenerateContentPopup(false);
          }}
        />
        <Dialog
          open={openGS}
          onClose={() => {
            setOpenGS(false);
          }}
          sx={{
            zIndex: (th: any) => th.zIndex.drawer + 10000,
          }}
          PaperProps={{
            sx: {
              border: `1px solid ${theme.custom.borderColor}`,
              width: "576px",
            },
          }}
        >
          <DialogContent sx={{ p: 0 }}>
            <SpotlightDialog
              openGS={openGS}
              handleCloseGS={() => {
                setOpenGS(false);
              }}
              companies={companies}
              history={history}
            />
          </DialogContent>
        </Dialog>
        <BudgetTrackerFilterDate
          open={dateFilterOpen}
          handleClose={() => setDateFilterOpen(false)}
          date={topBudgets.asOfDate}
          onDateChange={(date: any) => {
            setTopBudgetsLoading(true);
            setTopBudgets((prev) => {
              return { ...prev, asOfDate: date.format("YYYY-MM-DD") };
            });
            getTopBudgets(date.format("YYYY-MM-DD"));
          }}
          minDate={""}
          maxDate={""}
        />
      </Stack>
    </>
  );
};

export default PanoramaPage;
