import { CircularProgress, Grid, Typography } from "@mui/material";
import { decompressFromEncodedURIComponent } from "lz-string";
import queryString from "query-string";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { verifyConnectorAuthToken } from "../services/connector-service";
import {
  markConnected,
  setConnectionInProgress,
  setConnectorSyncStatusSingleBySlug,
  setExternalReferenceSlug,
  setMoveToMapping,
  triggerWindowClose,
} from "../store/connector-store";
import { showMessage } from "../store/error-handler-store";
import { useAppDispatch, useAppSelector } from "../store/hook";

interface Props {}

const ConnectorAuthCallback = (props: Props) => {
  const dispatch = useAppDispatch();
  const routeParams = useParams();
  const parsed: queryString.ParsedQuery<string> = queryString.parse(
    window.location.search
  );
  let payload = parsed;
  if (Object.keys(parsed).includes("encrypted_data")) {
    let encrypted_payload: any = parsed["encrypted_data"];
    payload = {
      ...parsed,
      ...JSON.parse(
        decompressFromEncodedURIComponent(encrypted_payload) || "{}"
      ),
    };
    delete payload["encrypted_data"];
  }
  const connectors = useAppSelector((state) => state.connector.connectors);
  const closeAuthWindow = useAppSelector(
    (state) => state.connector.closeAuthWindow
  );
  const connectorSlug = routeParams["connectorSlug"] || "";
  const connectorName = connectors.find((x) => x.slug === connectorSlug);
  useEffect(() => {
    if (closeAuthWindow) {
      window.close();
    }
  }, [closeAuthWindow]);

  useEffect(() => {
    verifyConnectorAuthToken(connectorSlug, payload)
      .then((resp) => {
        if (resp.status === 200) {
          dispatch(
            setExternalReferenceSlug({
              external_reference_slug: resp.data.external_reference_slug,
              slug: connectorSlug,
            })
          );
          dispatch(markConnected({ status: true, slug: connectorSlug }));
          dispatch(setMoveToMapping({ connectorSlug }));
        }
        else if (resp.status === 400) {
          dispatch(
            showMessage({
              message:
                "You have not provided valid inputs to create a connection",
            })
          );
        } else {
          if (resp.status === 403) {
            dispatch(
              showMessage({
                message: resp.data.detail
                  ? resp.data.detail
                  : "Invalid username/password",
              })
            );
          } else {
            dispatch(showMessage({ message: "Some error occurred" }));
          }
          dispatch(markConnected({ status: false, slug: connectorSlug }));
        }
      })
      .catch((e) => {
        dispatch(markConnected({ status: false, slug: connectorSlug }));
        dispatch(showMessage({ message: e.toString() }));
      })
      .finally(() => {
        dispatch(
          setConnectionInProgress({
            slug: connectorSlug,
            status: false,
          })
        );
        dispatch(
          setConnectorSyncStatusSingleBySlug({
            slug: connectorSlug,
            status: "running",
          })
        );
        dispatch(triggerWindowClose());
      });
  }, []);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item>
        <Typography>Please don’t refresh or close this window.</Typography>
      </Grid>
      <Grid item>
        <Typography>
          We are connecting {connectorName} with Pebble! Usually takes around 5
          mins.
        </Typography>
      </Grid>
      <Grid item>
        <CircularProgress />
      </Grid>
    </Grid>
  );
};

export default ConnectorAuthCallback;
