import { Button, Grid, Stack, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPassword } from "../services/forgot-password-service";
import { theme, fontInter } from "../theme";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SimpleSidebar } from "../components/atoms/SimpleSidebar";
import { hideEmail } from "../utils/dataFormatters";
import LoadingButton from "@mui/lab/LoadingButton";

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [resetLinkSent, setResetLinkSent] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>("");
  const [errMsg, setErrMsg] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const handleChangeUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserName(event.target.value);
  };

  const validateEmail = (email: string) => {
    let re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const resetPasswordHelper = () => {
    if (userName.trim().length === 0) {
      setErrMsg("User Name can't be empty.");
    } else if(!validateEmail(userName)) {
      setErrMsg("Please enter a valid email.");
    } else {
      setErrMsg("");
      setLoading(true);
      forgotPassword({ username: userName }).then((res) => {
        if (res.status === 404) {
          setResetLinkSent(false);
          setLoading(false);
          setErrMsg(res.data?.message);
        } else {
          setLoading(false);
          setResetLinkSent(true);
        }
      });
    }
  };

  const navigateToLogin = () => {
    navigate("/login");
  };

  return (
    <React.Fragment>
      <Stack direction="row">
        <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
          <SimpleSidebar />
        </Box>

        <Box
          onClick={navigateToLogin}
          sx={{
            position: "fixed",
            top: "15%",
            left: "10%",
            cursor: "pointer",
            zIndex: 1,
          }}
        >
          <ArrowBackIcon sx={{ color: theme.custom.primaryDarkColor }} />
        </Box>

        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          sx={{ minHeight: "100vh" }}
        >
          <Box
            p={5}
            sx={{
              width: 500,
              border: `1px solid ${theme.custom.primaryGreyColor}`,
              borderRadius: "6px",
            }}
          >
            <Grid container>
              <Grid mb={2} textAlign={"left"} item xs>
                <Typography
                  sx={{ color: theme.custom.primaryDarkColor }}
                  variant="poppins_h2_700_30"
                >
                  Forgot Password?
                </Typography>
              </Grid>
              <Grid mb={4} item xs={12}>
                <Typography
                  sx={{ color: theme.custom.primaryGreyColor }}
                  variant="inter_p_400_16"
                >
                  Please enter your registered email id to reset password
                </Typography>
              </Grid>
              {!resetLinkSent && (
                <Grid mb={3} item xs={12}>
                  <TextField
                    sx={{
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    onChange={handleChangeUserName}
                    fullWidth
                    id="username"
                    type={"email"}
                    placeholder={"Enter email id"}
                    variant="outlined"
                    inputProps={{
                      style: {
                        fontFamily: fontInter,
                        fontSize: "14px",
                        color: theme.custom.placeholderColor,
                      },
                    }}
                  />
                </Grid>
              )}
              {resetLinkSent && (
                <Grid mb={3} item xs={12}>
                  <Box
                    sx={{
                      background: theme.custom.resetMsgTextBgColor,
                      padding: 2,
                      borderRadius: "6px",
                    }}
                  >
                    <Typography
                      sx={{ color: theme.custom.placeholderColor }}
                      variant="inter60016"
                    >
                      Reset link sent to your email address{" "}
                      {hideEmail(userName)}
                    </Typography>
                  </Box>
                </Grid>
              )}
              <Grid item xs={12}>
                {!resetLinkSent && (
                  <LoadingButton
                    fullWidth
                    sx={{ height: "50px" }}
                    onClick={resetPasswordHelper}
                    variant="contained"
                    loading={loading}
                    type="submit"
                  >
                    <Typography variant="poppins_p_600_14">
                      Send Reset Link
                    </Typography>
                  </LoadingButton>
                )}
                {resetLinkSent && (
                  <Button
                    onClick={navigateToLogin}
                    sx={{ height: "50px" }}
                    variant="contained"
                    fullWidth
                  >
                    <Typography variant="poppins_p_600_14">
                      Goto signin
                    </Typography>
                  </Button>
                )}
              </Grid>

              {errMsg && errMsg.length > 0 && (
                <Grid textAlign={"center"} item xs={12}>
                  <Typography
                    sx={{ color: theme.custom.errorColor, marginTop: 1 }}
                  >
                    {errMsg}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </Grid>
      </Stack>
    </React.Fragment>
  );
};

export default ForgotPasswordPage;
