import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { theme } from "../../../theme";

type Props = {
  showDialog: string;
  onSaveTemplate: Function;
  setShowDialog: CallableFunction;
  reportAttributes: any;
};

function SaveTemplateDialog({
  showDialog,
  setShowDialog,
  onSaveTemplate,
  reportAttributes,
}: Props) {
  const [open, setOpen] = React.useState(false);
  const [templateName, setTemplateName] = React.useState("");
  const [saving, setSaving] = React.useState(false);
  const [templateNameError, setTemplateNameError] = React.useState("");
  const handleClose = () => {
    setOpen(false);
    setShowDialog("");
    setTemplateName("");
    setTemplateNameError("");
  };
  const navigate = useNavigate();

  const onEdit = (value: string) => {
    setTemplateNameError("");
    setTemplateName(value);
  };

  const handleDiscardChanges = () => {
    handleClose();
    navigate("/create-impact-report");
  };
  useEffect(() => {
    setOpen(showDialog === "template");
  }, [showDialog]);
  const handleSave = () => {
    onSaveTemplate(setSaving, setTemplateNameError, templateName, handleClose);
  };

  return (
    <Dialog
      hideBackdrop
      open={open}
      onClose={handleClose}
      PaperProps={{
        elevation: 0,
        sx: {
          border: `1px solid ${theme.custom.borderColor}`,
          width: "576px",
        },
      }}
      sx={{
        ".MuiDialog-scrollPaper": {
          justifyContent: "end",
          alignItems: "start",
          mr: 6,
          mt: 6,
        },
      }}
    >
      <DialogTitle>
        <Typography variant="poppins_h3_600_24">
          {reportAttributes?.templateId
            ? "Update template"
            : "Save as a template"}
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (t) => t.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="inter_p_600_16"
          sx={{
            display: reportAttributes?.templateId ? "none" : "block",
            height: "50px",
          }}
        >
          Enter name for the template
        </Typography>
        <Grid container mb={3} mt={1} justifyContent="space-between">
          <Grid
            xs={8.9}
            sx={{ display: reportAttributes?.templateId ? "none" : "block" }}
          >
            <TextField
              fullWidth
              error={templateNameError !== ""}
              helperText={templateNameError}
              placeholder="Template Name"
              required
              inputProps={{ sx: { height: "18px" } }}
              value={templateName}
              onChange={(e) => onEdit(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSave();
                }
              }}
            ></TextField>
          </Grid>
          <Grid
            xs={12}
            sx={{
              mb: 2,
              display: reportAttributes?.templateId ? "block" : "none",
            }}
          >
            {templateNameError && (
              <Alert color="error">
                <AlertTitle>{templateNameError}</AlertTitle>
              </Alert>
            )}
          </Grid>
          <Grid
            xs={5.9}
            sx={{ display: reportAttributes?.templateId ? "block" : "none" }}
          >
            <LoadingButton
              fullWidth
              variant="outlined"
              onClick={handleDiscardChanges}
              sx={{ height: "50px" }}
              color="error"
            >
              Discard Changes
            </LoadingButton>
          </Grid>
          <Grid xs={reportAttributes?.templateId ? 5.9 : 2.9}>
            <LoadingButton
              loading={saving}
              fullWidth
              variant="contained"
              sx={{ height: "50px" }}
              onClick={handleSave}
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(SaveTemplateDialog);
