import { createSlice, current } from "@reduxjs/toolkit";
import { omit } from "lodash";
import {
  Account,
  Class,
  ConnectorMappingConfig,
  Customer,
  Department,
} from "../services/user-mapping-service";

export interface UserMappingAPIModel {
  id?: number;
  meta_data: number;
  values: string[];
  value_slug?: string;
}

interface UserMappingStore {
  enrichedUMDatawithMeta: ConnectorMappingConfig[];
  unsavedUMDatawithMeta: ConnectorMappingConfig[];
  selectedMappings: UserMappingAPIModel[];
  accounts: Account[];
  classes: Class[];
  departments: Department[];
  customers: Customer[];
  modifiedUMMetaId: null | number;
  modifiedContractId: null | number;
  saving: boolean;
  UMDirty: boolean;
  activeUMId: number | string | null;
  actionType: string;
  invalidMappingCount: number;
  hasAtLeastOneMapping: boolean;
  allPossibleKeys: { [x: string]: any };
  attrs: { [x: string]: any };
  allResolvedAttrsDims: { [x: string]: any[] };
}

const initialState = {
  enrichedUMDatawithMeta: [],
  unsavedUMDatawithMeta: [],
  selectedMappings: [],
  modifiedContractId: null,
  accounts: [],
  classes: [],
  departments: [],
  customers: [],
  actionType: "add",
  modifiedUMMetaId: null,
  saving: false,
  UMDirty: false,
  activeUMId: null,
  invalidMappingCount: 0,
  hasAtLeastOneMapping: false,
  allPossibleKeys: {},
  attrs: {
    class: "Class",
    account: "Account",
    customer_display: "Customer",
    department: "Department",
    parent_class: "Parent Class",
    parent_account: "Parent Account",
    parent_customer: "Parent Customer",
    parent_department: "Parent Department",
    vendor_display: "Vendor",
  },
  allResolvedAttrsDims: {},
} as UserMappingStore;

export const userMappingSlice = createSlice({
  name: "userMappingStore",
  initialState,
  reducers: {
    setConfigs: (state, action) => {
      state.enrichedUMDatawithMeta = action.payload;
      state.unsavedUMDatawithMeta = action.payload;
      let dims: { [key: string]: string } = {};
      state.enrichedUMDatawithMeta
        .filter((i) => i.step === 1)
        .map((i) => (dims[i.slug] = i.title));
      state.allPossibleKeys = { ...dims, ...state.attrs, date: "Date" };
    },

    setContractConfigs: (state, action) => {
      state.enrichedUMDatawithMeta = [
        ...state.enrichedUMDatawithMeta.filter(
          (i) =>
            i.slug !== "contract" ||
            (i.slug === "contract" && !Object.keys(i.user_mapping).length)
        ),
        ...action.payload,
      ];
      state.unsavedUMDatawithMeta = [
        ...state.unsavedUMDatawithMeta.filter(
          (i) =>
            i.slug !== "contract" ||
            (i.slug === "contract" && !Object.keys(i.user_mapping).length)
        ),
        ...action.payload,
      ];
    },

    setAllResolvedAttrsDims: (state, action) => {
      state.allResolvedAttrsDims = action.payload;
    },
    setDefaultMappingFromConfig: (state, action) => {
      // set the mappings
      state.selectedMappings = [];
      action.payload.map((e: ConnectorMappingConfig) => {
        let model = {
          key: e.title,
          values: e.step === 1 ? [e.default_selected] : [],
          value_slug: e.default_selected || "",
          meta_data: e.id,
        } as UserMappingAPIModel;
        state.selectedMappings.push(model);
      });
    },
    setQBData: (state, action) => {
      state.accounts = action.payload.accounts;
      state.classes = action.payload.classes;
      state.departments = action.payload.departments;
      state.customers = action.payload.customers;
    },
    updateMapping: (state, action) => {
      let data;
      if (Object.keys(action.payload).includes("contractId")) {
        state.modifiedContractId = action.payload.contractId;
        data = state.unsavedUMDatawithMeta.find(
          (i) =>
            i.id === action.payload.meta_data_id &&
            i.user_mapping.id === action.payload.contractId
        );
      } else {
        data = state.unsavedUMDatawithMeta.find(
          (i) => i.id === action.payload.meta_data_id
        );
      }
      if (!data) {
        throw Error(
          `meta id ${action.payload.meta_data_id} not found in meta mappings`
        );
      }
      if (!data.user_mapping) {
        data.user_mapping = {};
      }
      state.modifiedUMMetaId = action.payload.meta_data_id;

      const toDelete = action.payload.delete;
      if (!!toDelete) {
        state.actionType = "delete";
      } else {
        state.actionType = "edit";
        const payload = omit(action.payload, ["meta_data_id", "delete"]);
        data.user_mapping = { ...data.user_mapping, ...payload };
      }
    },
    addContract: (state, action) => {
      let data = state.unsavedUMDatawithMeta.find(
        (i) => i.id === action.payload.meta_data_id
      );

      if (!data) {
        throw Error(
          `meta id ${action.payload.meta_data_id} not found in meta mappings`
        );
      }
      state.actionType = "add";
      let newData = { ...data };
      newData.user_mapping = action.payload;
      state.modifiedContractId = -1;
      state.modifiedUMMetaId = action.payload.meta_data_id;
      state.enrichedUMDatawithMeta = [...state.enrichedUMDatawithMeta, newData];
      state.unsavedUMDatawithMeta = [...state.unsavedUMDatawithMeta, newData];
    },
    setModifiedUMMetaId: (state, action) => {
      state.modifiedUMMetaId = action.payload;
      if (action.payload === null) {
        state.saving = false;
        state.modifiedContractId = null;
      }
    },
    setMappingFromAPI: (state, action) => {
      state.enrichedUMDatawithMeta.forEach((e: ConnectorMappingConfig) => {
        let model = {
          key: e.title,
          values: e.step === 1 ? [e.default_selected] : [],
          value_slug: e.default_selected,
          meta_data: e.id,
        } as UserMappingAPIModel;
        let apiData = action.payload.find(
          (i: UserMappingAPIModel) => i.meta_data === e.id
        );
        if (apiData) {
          model = apiData;
          state.hasAtLeastOneMapping = true;
        }
        state.selectedMappings.push(model);
      });
    },
    setHasAtLeastOneMapping: (state, action) => {
      state.hasAtLeastOneMapping = action.payload;
    },
    setSaving: (state, action) => {
      state.saving = action.payload;
    },
    setUMDirty: (state, action) => {
      state.UMDirty = action.payload;
    },
    setActiveUMId: (state, action) => {
      state.activeUMId = action.payload;
    },
  },
});

export const {
  setConfigs,
  setContractConfigs,
  addContract,
  updateMapping,
  setMappingFromAPI,
  setQBData,
  setAllResolvedAttrsDims,
  setDefaultMappingFromConfig,
  setHasAtLeastOneMapping,
  setModifiedUMMetaId,
  setSaving,
  setUMDirty,
  setActiveUMId
} = userMappingSlice.actions;

export default userMappingSlice.reducer;
