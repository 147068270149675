import axios from "axios";
import {
  DELETE_CONNECTOR,
  GET_CONNECTORS_URL,
  GET_CONNECTOR_AUTH_URL,
  GET_CONNECTOR_SYNC_STATUS,
  GET_CONNECTOR_SYNC_STATUS_LIST,
  LIST_GOOGLE_SHEETS,
  POST_VERIFY_CONNECTOR_URL,
  TRIGGER_RESYNC
} from "../endpoints/connector-endpoints";

import { ListConnectorAPIResponseModel } from "../Types/ListConnectorAPIResponseModel";

export interface ConnectorAuthUrl {
  auth_url: URL;
}

export const getConnectorsList = () => {
  return axios.get<ListConnectorAPIResponseModel[]>(GET_CONNECTORS_URL);
};

export const getConnectorAuthUrl = (connectorSlug: string) => {
  return axios.get<ConnectorAuthUrl>(GET_CONNECTOR_AUTH_URL(connectorSlug));
};

export const verifyConnectorAuthToken = (
  connectorSlug: string,
  params: Object
) => {
  return axios.post(POST_VERIFY_CONNECTOR_URL(connectorSlug), params);
};

export const getConnectorSyncStatus = (connectorSlug: string) => {
  return axios.get(GET_CONNECTOR_SYNC_STATUS(connectorSlug));
};
export const getConnectorSyncStatusList = () => {
  return axios.get(GET_CONNECTOR_SYNC_STATUS_LIST);
};

export const deleteConnector = (connectorSlug: string) => {
  return axios.delete(DELETE_CONNECTOR(connectorSlug));
};

export const triggerResync = (connectorSlug: string) => {
  return axios.get(TRIGGER_RESYNC(connectorSlug));
};
export const listGoogleSheetsFromDrive = (
  connectorSlug: string,
  nextPageToken = "",
  searchText = ""
) => {
  return axios.get(LIST_GOOGLE_SHEETS(connectorSlug, nextPageToken, searchText));
};
