import { Autocomplete, Grid, TextField, Typography } from "@mui/material";
import { SyntheticEvent, memo, useState } from "react";
import { useDeepCompareEffect } from "react-use";
import {
  Board,
  BoardCollectionMetaData,
  BoardKpiPermissionMeta,
  KpiList,
} from "../../../Types/UserManagement";
import { theme } from "../../../theme";
import CustomChip from "../../atoms/CustomChip";
import CustomizedCheckbox from "../../atoms/CustomizedCheckbox";
import { CheckboxType, SelectionType } from "./MemberBoardPermissionForm";

type Props = {
  boardCollectionName: keyof BoardCollectionMetaData;
  allBoardsMeta: BoardKpiPermissionMeta[];
  metaItem: BoardKpiPermissionMeta;
  setSelected: CallableFunction;
  memberDetailsForItem: Board | undefined;
  hasTopLevelFullAccess: boolean;
};

function MemberBoardPermissionItem({
  boardCollectionName,
  allBoardsMeta,
  metaItem,
  hasTopLevelFullAccess,
  setSelected,
  memberDetailsForItem,
}: Props) {
  const [isSelected, setIsSelected] = useState(false);
  const [selectedValues, setSelectedValues] = useState<KpiList[]>([]);

  useDeepCompareEffect(() => {
    setIsSelected(
      hasTopLevelFullAccess || memberDetailsForItem?.has_full_access || false
    );
    setSelectedValues(memberDetailsForItem?.authorized_kpis || []);
  }, [
    hasTopLevelFullAccess,
    memberDetailsForItem?.authorized_kpis,
    memberDetailsForItem?.has_full_access,
  ]);

  const handleOnChange = (values: KpiList[]) => {
    setSelected(
      boardCollectionName,
      allBoardsMeta,
      SelectionType.DROPDOWN,
      undefined,
      undefined,
      metaItem,
      values
    );
  };

  return (
    <Grid
      container
      alignItems={"center"}
      my={0.5}
      justifyContent={"space-between"}
    >
      <Grid item xs={4}>
        <Typography variant="inter60016">{metaItem.board_title}</Typography>
      </Grid>
      <Grid item xs={2}>
        <CustomizedCheckbox
          isChecked={isSelected}
          onChange={(value: boolean) =>
            setSelected(
              boardCollectionName,
              allBoardsMeta,
              SelectionType.CHECKBOX,
              CheckboxType.ALL_KPIS,
              value,
              metaItem
            )
          }
          labelProps={{
            title: "All kpis",
            extraSx: {
              variant: "inter_p_500_14",
              color: theme.custom.primaryDarkColor,
            },
          }}
        />
      </Grid>
      <Grid item>
        <Autocomplete
          multiple
          disabled={isSelected}
          size={"small"}
          sx={{ width: "300px" }}
          options={metaItem.kpi_list.filter(
            (i) => !selectedValues.find((j) => i.kpi_slug === j.kpi_slug)
          )}
          getOptionLabel={(option) => option.kpi_title}
          value={selectedValues}
          filterSelectedOptions
          onChange={(e: SyntheticEvent, values) => handleOnChange(values)}
          renderInput={(params) => <TextField {...params} />}
          renderTags={(tagValue, getTagProps) => {
            return tagValue.map((option, index) => {
              if (option) {
                return (
                  <CustomChip
                    key={option.kpi_slug}
                    labelProps={{ variant: "inter_500_10" }}
                    extraProps={{
                      ...getTagProps({ index }),
                      labelText: option.kpi_title,
                      size: "small",
                    }}
                  ></CustomChip>
                );
              }
            });
          }}
        />
      </Grid>
    </Grid>
  );
}

export default memo(MemberBoardPermissionItem);
