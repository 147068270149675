import { LoadingButton } from "@mui/lab";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from "react-use";
import { getPublicReport } from "../services/impact-report-service";
import ImpactReportEditor from "./ImpactReportEditor";
import { orderBy, pickBy } from "lodash";

type Props = {
  reportData?: any;
  disableToolbar?: boolean;
};

function ImpactReportPublic(props: Props) {
  const disableToolbar = props.disableToolbar ? props.disableToolbar : false;
  const { id: urlId } = useParams();
  let location = useLocation();
  const [reportDetails, setReportDetails] = useState<any>();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [passwordRequired, setPasswordRequired] = useState(false);
  const [publicReportUID, setPublicReportUID] = useState("");
  const [print, setPrint] = useState(false);

  useEffect(() => {
    setReportDetails(props.reportData);
  }, [props.reportData]);

  const isPresentation =
    new URL(document.location.toString()).searchParams.get("presentation") ===
    "true";

  const getReport = () => {
    let _uid: any;
    let _publicReportUID: any;
    if (urlId) {
      _uid = urlId?.split("_");
      _publicReportUID = _uid[_uid.length - 1];
    }

    if (!_uid || !_publicReportUID) return;
    setLoading(true);

    setPublicReportUID(_publicReportUID);
    getPublicReport(_publicReportUID, password).then((resp) => {
      if (resp.status === 200 && resp.data) {
        setReportDetails(resp.data);
      } else if (resp.status === 403) {
        setPasswordRequired(true);
      } else if (resp.status === 400) {
        setErrorText(resp.data[0]);
      } else if (resp.status === 404) {
        alert("Report doesn't exist");
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (urlId && !reportDetails) {
      getReport();
      setLoading(false);
    }
    if (location.hash && location.hash.slice(1) === "print") {
      setPrint(true);
    }
  }, []);

  const passwordRequiredModal = () => (
    <Dialog
      open={passwordRequired}
      onKeyUp={(e) => {
        if (e.key === "Enter") {
          getReport();
        }
      }}
    >
      <DialogTitle>Password Required</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            placeholder="abc"
            type="password"
            helperText={errorText}
            error={errorText !== ""}
            label="Enter the password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            required
            fullWidth
            variant="outlined"
          />
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        <LoadingButton
          loading={loading}
          onClick={(e) => {
            return getReport();
          }}
          variant="contained"
        >
          Access Report
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );

  return (
    <>
      {passwordRequired && !reportDetails ? (
        passwordRequiredModal()
      ) : (
        <div>
          <ImpactReportEditor
            isPublic={true}
            isPresentation={isPresentation}
            mode={"public"}
            disableToolbar={disableToolbar}
            print={print}
            reportDetails={reportDetails}
            publicReportId={publicReportUID}
          />
        </div>
      )}
    </>
  );
}

export default ImpactReportPublic;
