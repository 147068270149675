import { environment } from "../environments/environment-dev";

export const GET_CONNECTOR_MAPPING_CONFIG = (connectorSlug: string) =>
  `${environment.connectorApiUrl}/${connectorSlug}/get-connector-mapping-config`;

export const POST_SAVE_USER_MAPPINGS = (connectorSlug: string) =>
  `${environment.connectorApiUrl}/${connectorSlug}/user-mappings`;

export const GET_USER_MAPPINGS = (connectorSlug: string) =>
  `${environment.connectorApiUrl}/${connectorSlug}/user-mappings`;

export const GET_USER_MAPPINGS_CONTRACTS = (connectorSlug: string) =>
  `${environment.connectorApiUrl}/${connectorSlug}/user-mappings/contract`;

export const DELETE_USER_MAPPINGS_CONTRACTS = (connectorSlug: string, mappingSlug: string) =>
  `${environment.connectorApiUrl}/${connectorSlug}/user-mappings/contract/${mappingSlug}`;

export const GET_ATTR_DIM = (connectorSlug: string, field: string) =>
  `${environment.connectorApiUrl}/${connectorSlug}/get-dim-or-attr-values?field=${field}`;

export const GET_ALL_ATTR_DIM = (connectorSlug: string) =>
  `${environment.connectorApiUrl}/${connectorSlug}/get-all-dim-attr-values`;
