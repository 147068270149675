import {
  Box,
  Button,
  ListItemButton,
  Typography,
  CircularProgress,
} from "@mui/material";
import React, { memo } from "react";
import CustomChip from "../CustomChip";
import { theme } from "../../../theme";
import EditIcon from "../../../assets/pencil.png";
import TrackIcon from "../../../assets/target_icon.png";

type Props = {
  payload: any;
  onClick: Function;
  iconSelector: Function;
  showLoader?: boolean;
};

const GenericListItem = (props: Props) => {
  const { payload, onClick, iconSelector, showLoader } = props;

  const getActionImage = (handle: string) => {
    switch (handle) {
      case "edit":
        return EditIcon;
      case "track":
        return TrackIcon;
      default:
        break;
    }
  };
  return (
    <>
      <ListItemButton onClick={(e) => onClick(e, payload.nav_url)}>
        <img
          style={{ marginRight: 5 }}
          height="18px"
          width="18px"
          src={iconSelector(payload.type)}
          alt={payload.title}
        />
        <Typography
          variant="inter_p_400_16"
          sx={{ wordWrap: "normal", maxWidth: 400, mx: 1 }}
        >
          {payload.title}
        </Typography>
        {payload.tags.map((tag: string) => (
          <CustomChip
            key={tag}
            extraProps={{
              size: "small",
              labelText: `${tag}`,
            }}
            labelProps={{ variant: "inter_500_12" }}
            extraSX={{
              mx: 0.5,
              color: theme.custom.primaryGreyColor,
              backgroundColor: theme.CustomChip.backgroundLightGray,
            }}
          />
        ))}
        <Box ml={3}>
          {payload.extra_nav_urls.map((nav_obj: any) => (
            <Button
              variant="outlined"
              size="small"
              key={nav_obj.slug}
              sx={{ mx: 0.5 }}
              onClick={(e) => onClick(e, nav_obj.url)}
            >
              <img
                src={getActionImage(nav_obj.icon_handle)}
                height={15}
                width={15}
                alt="Downward arrow"
              />
              <Typography sx={{ ml: 1 }} variant="inter_500_12">
                {nav_obj.title}
              </Typography>
            </Button>
          ))}
        </Box>
        {payload["trigger_kpi"] && showLoader && (
          <div>
            <CircularProgress
              color="inherit"
              size={26}
              variant={"indeterminate"}
            />
          </div>
        )}
      </ListItemButton>
    </>
  );
};

export default memo(GenericListItem);
