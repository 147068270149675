import {
  fill,
  indexOf,
  map,
  uniqBy,
  groupBy,
  mapValues,
  sumBy,
  set,
  zipObject,
  keyBy,
  mergeWith,
} from "lodash";
import { chartColors, returnChartColors } from "../theme";
import { Typography } from "@mui/material";
import { BudgetDrilldownEntry } from "../Types/BudgetDrillDownEntry";
import { dateFormatter } from "./dataFormatters";

export const returnLineOrAreaChartData = (data: any, meta_data: any) => {
  let temp: any = { name: meta_data.chart?.["y-axis-text"], data: [] };
  data.forEach((ele: any) => {
    // temp.name = ele.name;
    temp.data.push(ele.value);
  });

  return [temp];
};

export const returnColumnOrBarChartData = (data: any, meta_data?: any) => {
  let obj = {
    name: (meta_data && meta_data.chart?.["y-axis-text"]) || "",
    data: [],
    colorByPoint: true,
  };
  var arr: any = [];
  if (data.length > 0) {
    data.forEach((element: any, index: number) => {
      if (!arr.some((ele: any) => ele.name === element["primary_name"])) {
        var obj: any = {};
        obj["name"] = element["primary_name"];
        obj["y"] = element["value"];
        arr.push(obj);
      }
    });
  }
  obj["data"] = arr;

  return [obj];
};

export const returnStackedChartsData = (data: any) => {
  let primaryNames = uniqBy(data, (ele: any) => ele.primary_name).map(
    (el) => el.primary_name
  );

  let _obj: any = {};

  map(data, (ele: any) => {
    _obj[ele["secondary_name"]] = fill(Array(primaryNames.length), 0);
  });

  data.forEach((el: any) => {
    _obj[el["secondary_name"]][
      returnIndexOfElementInTheArray(primaryNames, el["primary_name"])
    ] = el["value"];
  });

  let final = Object.keys(_obj).map((el: any, index: number) => {
    return {
      name: el,
      data: _obj[el],
      mode: index,
      color: chartColors[index % chartColors.length],
    };
  });

  return final;
};

const returnIndexOfElementInTheArray = (arr: Array<any>, ele: string) => {
  return indexOf(arr, ele);
};

export const returnCategoriesForStackedGraphs = (data: any) => {
  return getUniqueArrByKey(data, "primary_name");
};

export const returnCategoriesForBasicBarOrColumnCharts = (data: any) => {
  return data.map((ele: any) => {
    return ele["primary_name"];
  });
};

export const getUniqueArrByKey = (data: any, key: string) => {
  let arr: any = [];
  if (data && data.length) {
    data.forEach((ele: any) => {
      if (!arr.includes(ele[key])) {
        if (ele[key]) {
          arr.push(ele[key]);
        }
      }
    });
  }
  return arr;
};

export const returnMultipleAreaOrLineChartData = (
  data: any,
  chartType: string = "multiple-line",
  removeMultipleYAxis?: boolean
) => {
  return Object.keys(data).map((chart, index) => {
    let temp: any = { name: "", color: "", yAxis: 0, data: [] };
    data[chart].forEach((ele: any) => {
      temp.name = ele.name;
      temp.data.push(ele.value);
      if (chartType !== "area-percentage" && !removeMultipleYAxis) {
        temp.yAxis = index;
      }
      temp.color = returnChartColors(Object.keys(data).length)[index];
    });

    return temp;
  });
};

export const returnCategoriesForAreaOrLineCharts = (data: any) => {
  return Object.keys(data).length
    ? data[Object.keys(data)[0]].map((ele: any) => {
        return ele["x-axis-plot"];
      })
    : [];
};

export const returnGroupedColumnOrBarChartData = (data: any, metaData: any) => {
  let primaryNames = uniqBy(data, (ele: any) => ele.secondary_name).map(
    (el) => el.secondary_name
  );

  let _obj: any = {};

  map(data, (ele: any) => {
    _obj[ele["primary_name"]] = fill(Array(primaryNames.length), 0);
  });

  data.forEach((el: any) => {
    _obj[el["primary_name"]][
      returnIndexOfElementInTheArray(primaryNames, el["secondary_name"])
    ] = el["value"];
  });

  let final = Object.keys(_obj).map((el: any, index: number) => {
    return {
      name: el,
      data: _obj[el],
      mode: index,
      color: chartColors[index % chartColors.length],
    };
  });

  return final;
};

export const returnToolTipForGroupedBarsOrColumns = (data: any) => {
  let temp: string[] = [];
  data.forEach((ele: any) => {
    if (!temp.includes(ele["secondary_name"])) {
      temp.push(ele["secondary_name"]);
    }
  });
  return temp;
};

export const lineChartDataFormatter = (lineChartData: any) => {
  return lineChartData.map((ele: any) => {
    return ele.value;
  });
};

export const returnSmartStatementTransformedData = (kpiStateData: any) => {
  let transformedData: any = {
    data: {},
    metadata: {
      aggregation: "",
      daterange: {},
      format: "",
    },
  };
  switch (kpiStateData.type) {
    case "column":
      return returnColumn(transformedData, kpiStateData.data);

    case "bar":
      return returnBar(transformedData, kpiStateData.data);

    case "pie":
      return returnPie(transformedData, kpiStateData.data);

    case "bar-stacked":
      return returnBarStacked(transformedData, kpiStateData.data);

    case "column-stacked":
      return returnColumnStacked(transformedData, kpiStateData.data);

    case "grouped":
      return returnGrouped(transformedData, kpiStateData.data);

    case "grouped-bar":
      return returnGroupedBar(transformedData, kpiStateData.data);

    case "group-stacked":
      return returnGroupStacked(transformedData, kpiStateData.data);

    case "group-column-stacked-line":
      return returnGroupColumnStackedLine(transformedData, kpiStateData.data);

    case "treemap":
      return returnTreemap(transformedData, kpiStateData.data);

    case "area":
      return returnArea(transformedData, kpiStateData.data);

    case "line":
      return returnLine(transformedData, kpiStateData.data);

    case "multiple-area":
      return returnMultipleArea(transformedData, kpiStateData.data);

    case "multiple-line":
      return returnMultipleLine(transformedData, kpiStateData.data);

    case "column-line":
      return returnColumnLine(transformedData, kpiStateData.data);

    case "grouped-column-line":
      return returnGroupedColumnLine(transformedData, kpiStateData.data);

    case "double-pie":
      return returnDoublePie(transformedData, kpiStateData);

    case "number":
      return returnNumber(transformedData, kpiStateData.data);

    case "single-number":
      return returnSingleNumber(transformedData, kpiStateData.data);

    case "table":
      return returnTable(transformedData, kpiStateData.data);

    case "shadow-column":
      return returnShadowColumn(transformedData, kpiStateData.data);

    case "wordcloud":
      return returnWordcloud(transformedData, kpiStateData.data);

    case "waterfall":
      return returnWaterfall(transformedData, kpiStateData.data);

    case "area-percentage":
      return returnAreaPercentage(transformedData, kpiStateData.data);

    case "area-stacked":
      return returnAreaStacked(transformedData, kpiStateData.data);

    default:
      break;
  }
};

const returnWaterfall = (transformedData: any, chartData: any) => {
  transformedData.data = chartData.reduce(
    (acc: { [x: string]: any }, { primary_name, value }: any) => {
      acc[primary_name] = value || 0;
      return acc;
    },
    {}
  );
  transformedData.metadata = { ...transformedData.metadata, format: "$" };
  return transformedData;
};
const returnSingleNumber = (transformedData: any, chartData: any) => {
  transformedData.data = commonChartReduceTransformation(
    chartData,
    "dropdown-menu-item"
  );
  transformedData.metadata = {
    ...transformedData.metadata,
    format: chartData.length ? chartData[0].number_format : "",
  };
  return transformedData;
};
const returnTreemap = (transformedData: any, chartData: any) => {
  transformedData.data = mapValues(
    groupBy(chartData, "dropdown-menu-item"),
    (items) =>
      mapValues(groupBy(items, "level_1"), (levelItems) =>
        sumBy(levelItems, "value")
      )
  );
  return transformedData;
};
const returnLine = (transformedData: any, chartData: any) => {
  transformedData.data = commonChartReduceTransformation(
    chartData,
    "x-axis-plot"
  );
  transformedData.metadata = { ...transformedData.metadata, format: "$" };
  return transformedData;
};
const returnArea = (transformedData: any, chartData: any) => {
  transformedData.data = commonChartReduceTransformation(
    chartData,
    "x-axis-plot"
  );
  return transformedData;
};
const returnPie = (transformedData: any, chartData: any) => {
  transformedData.data = commonChartReduceTransformation(
    chartData,
    "primary_label_name"
  );
  return transformedData;
};
const returnColumn = (transformedData: any, chartData: any) => {
  transformedData.data = mapValues(
    groupBy(
      chartData,
      (item) => item["dropdown-menu-item"] || item.primary_name
    ),
    (items) => zipObject(map(items, "primary_name"), map(items, "value"))
  );
  return transformedData;
};
const returnShadowColumn = (transformedData: any, chartData: any) => {
  transformedData.data = mapValues(keyBy(chartData, "x-axis-label"), (o) => ({
    "Current Value": o["curr-value"],
    "Previous Value": o["prev-value"],
  }));
  return transformedData;
};
const returnMultipleArea = (transformedData: any, chartData: any) => {
  transformedData.data = commonMultiChartMergeWithTransformation(
    chartData,
    "x-axis-plot"
  );
  return transformedData;
};
const returnMultipleLine = (transformedData: any, chartData: any) => {
  transformedData.data = commonMultiChartMergeWithTransformation(
    chartData,
    "x-axis-plot"
  );
  return transformedData;
};
const returnColumnLine = (transformedData: any, chartData: any) => {
  transformedData.data = mergeWith(
    {},
    ...map(chartData, (items) =>
      mapValues(
        keyBy(items, (item) => item["primary_name"] || item["x-axis-plot"]),
        (item) => ({
          [item.name || item.secondary_name]: item.value,
        })
      )
    )
  );
  return transformedData;
};
const returnColumnStacked = (transformedData: any, chartData: any) => {
  transformedData.data = mapValues(
    groupBy(chartData, "primary_name"),
    (items) => mapValues(keyBy(items, "secondary_name"), "value")
  );
  return transformedData;
};
const returnNumber = (transformedData: any, chartData: any) => {
  transformedData.data = mapValues(keyBy(chartData, "title"), "value");
  return transformedData;
};
const returnGrouped = (transformedData: any, chartData: any) => {
  transformedData.data = mapValues(
    groupBy(chartData, "secondary_name"),
    (group) => mapValues(keyBy(group, "primary_name"), "value")
  );
  return transformedData;
};
const returnDoublePie = (transformedData: any, chartData: any) => {
  chartData.data["Chart-1"].map((obj: any) => {
    obj.name = chartData.meta_data.chart["pie-1-title"]
      ? chartData.meta_data.chart["pie-1-title"]
      : "pie-1";
    return obj;
  });
  chartData.data["Chart-2"].map((obj: any) => {
    obj.name = chartData.meta_data.chart["pie-2-title"]
      ? chartData.meta_data.chart["pie-2-title"]
      : "pie-2";
    return obj;
  });
  const updateChartData = chartData.data;
  transformedData.data = commonMultiChartMergeWithTransformation(
    updateChartData,
    "primary_label_name"
  );
  return transformedData;
};
const returnBar = (transformedData: any, chartData: any) => {
  transformedData.data = mapValues(keyBy(chartData, "primary_name"), "value");
  return transformedData;
};
const returnBarStacked = (transformedData: any, chartData: any) => {
  transformedData.data = mapValues(
    groupBy(chartData, "primary_name"),
    (group) => mapValues(keyBy(group, "secondary_name"), "value")
  );
  return transformedData;
};
const returnAreaPercentage = (transformedData: any, chartData: any) => {
  transformedData.data = mergeWith(
    {},
    ...map(chartData, (items) =>
      mapValues(keyBy(items, "x-axis-plot"), (item) => ({
        [item.name]: item.value,
      }))
    )
  );
  return transformedData;
};
const returnGroupedColumnLine = (transformedData: any, chartData: any) => {
  // Group column data by secondary_name
  const groupedColumnData = groupBy(chartData.column, "secondary_name");

  // Transform each group into a single object
  const columnData = mapValues(groupedColumnData, (items) => {
    return items.reduce((acc, item) => {
      acc[item.primary_name] = item.value;
      return acc;
    }, {});
  });

  // Add line data to the result
  chartData.line.forEach((lineItem: any, index: number) => {
    const month = Object.keys(columnData)[index];
    if (columnData[month]) {
      columnData[month][lineItem.name] = lineItem.value;
    }
  });

  transformedData.data = columnData;
  return transformedData;
};
const returnTable = (transformedData: any, chartData: any) => {
  transformedData.data = mapValues(
    keyBy(chartData, "particulars"),
    (row: any) => ({
      ...mapValues(row, (val, key) => {
        if (
          row.hasOwnProperty(key) &&
          key !== "order" &&
          key !== "type" &&
          key !== "nesting_order" &&
          key !== "particulars"
        ) {
          return row.hasOwnProperty(key) ? val : undefined;
        }
      }),
      subRows: mapValues(
        keyBy(row.subRows || [], "particulars"),
        (subRow: any) =>
          mapValues(subRow.subRowData || {}, (val, key) =>
            subRow.subRowData.hasOwnProperty(key) ? val : undefined
          )
      ),
    })
  );
  return transformedData;
};
const returnGroupColumnStackedLine = (transformedData: any, chartData: any) => {
  return returnGroupedColumnLine(transformedData, chartData);
};
const returnAreaStacked = (transformedData: any, chartData: any) => {
  transformedData.data = commonMultiChartMergeWithTransformation(
    chartData,
    "x-axis-plot"
  );
  return transformedData;
};
// We needs to Data to implement these functions
const returnGroupedBar = (transformedData: any, chartData: any) => {
  return {};
};
const returnGroupStacked = (transformedData: any, chartData: any) => {
  return {};
};
const returnWordcloud = (transformedData: any, chartData: any) => {
  return {};
};

const commonChartReduceTransformation = (chartData: any, column: string) => {
  return chartData.reduce((result: any, item: any) => {
    set(result, [item[column]], item.value);
    return result;
  }, {});
};

const commonMultiChartMergeWithTransformation = (
  chartData: any,
  column: string
) => {
  return mergeWith(
    {},
    ...map(chartData, (items) =>
      mapValues(keyBy(items, column), (item) => ({
        [item.name]: item.value,
      }))
    )
  );
};

export const returnCoumnsForBudgetDrilldown = (
  tableData: any,
  drillDownKeys: String[],
  onCellClick: Function
) => {
  let arr: any = [];
  for (let key in tableData) {
    if (key !== "subRows") {
      if (key === "particulars") {
        arr.push({
          Header: " ",
          accessor: key,
          minWidth: 400,
        });
      } else if (drillDownKeys && drillDownKeys.includes(key)) {
        arr.push({
          Header: key,
          accessor: key,
          // minWidth: 300,
          Cell: ({ row, rowsById, column }: any) => {
            if (row.isExpanded) {
              return <span></span>;
            }
            return (
              <Typography
                className="drilldown-cell"
                onClick={() => {
                  const parentRowId = row.id.split(".").slice(0, -1).join(".");
                  let obj: any = {};

                  if (row.values.type === "ROW_HEADER" && !parentRowId) {
                    obj = {
                      budget_uid: row?.["original"]?.["budget_uid"] || "",
                      budget_type: row?.["original"]?.["particulars"] || "",
                      budget_head: "",
                      drill_down_field: column["Header"] || "",
                    };
                  } else if (
                    row.original.type === "SUB_HEADER" &&
                    row.subRows.length > 0
                  ) {
                    obj = {
                      budget_uid: row?.["original"]?.["budget_uid"] || "",
                      budget_type: row?.["original"]?.["particulars"] || "",
                      budget_head: "",
                      drill_down_field: column["Header"] || "",
                    };
                  } else if (
                    row.original.type === "SUB_HEADER" &&
                    row.subRows.length === 0
                  ) {
                    obj = {
                      budget_uid: row?.["original"]?.["budget_uid"] || "",
                      budget_type:
                        rowsById[parentRowId]?.["original"]?.["particulars"] ||
                        "",
                      budget_head: row["original"]["particulars"] || "",
                      drill_down_field: column["Header"] || "",
                    };
                  } else if (row.original.type === "TOTAL_HEADER") {
                    obj = {
                      budget_uid: row?.["original"]?.["budget_uid"] || "",
                      budget_type:
                        rowsById[parentRowId]?.["original"]?.["particulars"] ||
                        "",
                      budget_head: "",
                      drill_down_field: column["Header"] || "",
                    };
                  } else {
                    obj = {
                      budget_uid: row?.["original"]?.["budget_uid"] || "",
                      budget_type:
                        rowsById[parentRowId]?.["original"]?.["particulars"] ||
                        "",
                      budget_head: row["original"]["particulars"] || "",
                      drill_down_field: column["Header"] || "",
                    };
                  }

                  onCellClick(obj);
                }}
              >
                {row["original"][key]}
              </Typography>
            );
          },
        });
      } else {
        arr.push({
          Header: key,
          accessor: key,
          minWidth: 200,
        });
      }
    }
  }

  return arr;
};

export const formatBudgetDrilldownData = (
  drilldownEntries: BudgetDrilldownEntry,
  columnWidth: number
) => {
  let arr: any = [];
  for (let key in drilldownEntries) {
    if (key !== "subRows") {
      arr.push({
        Header: key,
        accessor: key,
        minWidth: columnWidth,
        Cell: ({ row }: any) => {
          return (
            <a
              rel="noreferrer"
              className="drilldown-cell"
              target="_blank"
              href={row["original"]["transaction_external_link"]}
            >
              <span>{row["original"][key]}</span>
            </a>
          );
        },
      });
    }
  }
  return arr;
};

export const headerFormatter = (key: string) => {
  return key.replace(/[-_\s]/gi, " ");
};

export const findLabelsForShowTitleFilters = (
  dataFilters: any,
  selectedElementAllFilters: any
) => {
  const labels: string[] = [];

  if (
    !dataFilters.show_title_filters ||
    (dataFilters.show_title_filters &&
      dataFilters.show_title_filters.length === 0)
  ) {
    return "";
  }

  if (
    dataFilters.show_title_filters &&
    dataFilters.show_title_filters.length > 0
  ) {
    dataFilters.show_title_filters.forEach((filterKey: string) => {
      const filterValue = dataFilters[filterKey];
      const filterData = selectedElementAllFilters[filterKey];

      if (filterData) {
        // If the filter is a static list or connector data
        if (filterData.type !== "daterange") {
          if (Array.isArray(filterValue)) {
            // If the filter value is an array (multi-select)
            filterValue.forEach((value: any) => {
              const matchedItem = filterData.data.find(
                (item: any) => item.value === value
              );
              if (matchedItem) {
                labels.push(matchedItem.label);
              }
            });
          } else {
            // Single value
            const matchedItem = filterData.data.find(
              (item: any) => item.value === filterValue
            );
            if (matchedItem) {
              labels.push(matchedItem.label);
            }
          }
        }

        if (
          filterData.type === "daterange" &&
          typeof filterValue === "object" &&
          filterKey !== "single-date"
        ) {
          labels.push(
            (filterValue.start &&
              filterValue.end &&
              dateFormatter(filterValue.start, "MMM'YY") +
                " to " +
                dateFormatter(filterValue.end, "MMM'YY")) ||
              "Custom Date Range"
          );
        }
        if (filterKey === "single-date" && typeof filterValue === "object") {
          labels.push(dateFormatter(filterValue.end, "MMM'YY") || "As on Date");
        }
      }
    });
  }

  return labels.length > 0 ? " - " + labels.join(" - ") : "";
};
