import { Dialog, DialogContent, Typography } from "@mui/material";
import React, { useState } from "react";
import PreviewTemplate from "./PreviewTemplate";

type Props = {
  templateObj: any;
  extraSx?: any;
  userQuery?: string;
  audience?: string;
  purpose?: string;
};

const PreviewTemplatePopup = (props: Props) => {
  const { templateObj, extraSx, userQuery } = props;
  const [openPreview, setOpenPreview] = useState(false);

  const onPreviewClick = () => {
    setOpenPreview(true);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (openPreview) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [openPreview]);

  const closePreview = () => {
    setOpenPreview(false);
  };
  return (
    <>
      <Typography
        variant="poppins_h5_600_18"
        onClick={onPreviewClick}
        sx={{ ...extraSx }}
      >
        Preview
      </Typography>
      <div>
        <Dialog
          open={openPreview}
          fullScreen
          onClose={closePreview}
          aria-labelledby="scroll-title"
          aria-describedby="scroll-description"
          scroll="paper"
          PaperProps={{
            style: {
              minHeight: 600,
              minWidth: 800,
            },
          }}
        >
          <DialogContent sx={{ overflow: "hidden", px: 0 }}>
            <PreviewTemplate
              onClose={closePreview}
              templateObj={templateObj}
              userQuery={userQuery}
              audience={props.audience}
              purpose={props.purpose}
            />
          </DialogContent>
        </Dialog>
      </div>
    </>
  );
};

export default PreviewTemplatePopup;
