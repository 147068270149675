import styled from "@emotion/styled";
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  Stack,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Typography,
} from "@mui/material";
import { createFilterOptions } from "@mui/material/Autocomplete";
import React, { useState } from "react";
import { theme, fontInter } from "../../theme";
import { EmployeeObject } from "../../Types/EmployeeObject";
import AddIcon from "@mui/icons-material/Add";
import { StaffingItemsObject } from "../../Types/BudgetDetailsItem";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { SxProps } from "@mui/system";
import NumberTextFieldComponent from "../atoms/NumberTextFieldComponent";
import { numberFormatter } from "../../utils/dataFormatters";
import { showMessage } from "../../store/error-handler-store";
import { useAppDispatch } from "../../store/hook";
type Props = {
  employeeList: EmployeeObject[];
  addStaffingItem: Function;
  values?: StaffingItemsObject;
  editMode?: boolean;
  editItemCallback?: Function;
  cancelEditCallBack?: Function;
  extraSX?: SxProps<Theme>;
  className?: string;
};

const AddStaffingValuesForm = (props: Props) => {
  const filter = createFilterOptions<any>();
  const dispatch = useAppDispatch();
  let values = props.values;
  let editMode = props.editMode;
  let editItemCallback = props.editItemCallback;
  let cancelEditCallBack = props.cancelEditCallBack;
  const INIT_STATE = {
    id: values?.id || "",
    employee: {
      name: values?.employee.name || "",
      id: values?.employee.id || 0,
      regular_pay: values?.employee.regular_pay || 0,
      ref_emp_id: values?.employee?.ref_emp_id || "",
    },
    regular_pay: values?.regular_pay || "",
    budgeted_regular_pay: values?.budgeted_regular_pay || "",
    fte_r: values?.fte_r || "",
    fringe_rate: values?.fringe_rate || "",
    annual_pay: 0,
    benifits: 0,
  };

  let addStaffingItem = props.addStaffingItem;
  const [staffingItem, setStaffingItem] = useState(INIT_STATE);
  const AutoCompleteComponent = styled(Autocomplete)({
    ".MuiAutocomplete-inputRoot": {
      color: theme.custom.dropdownPrimaryColor,
      fontWeight: 500,
      borderRadius: 8,
      fontSize: "14px",
      fontFamily: fontInter,
    },
  });

  const renderOption = (props: any, option: any) => {
    return (
      <Grid item component="li" {...props}>
        <Stack direction="column">
          <Typography py={1} variant="inter_p_500_14">
            {option?.name}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  const handleChangeAutocomplete = (data: any) => {
    if (typeof data === "string") {
      let obj: EmployeeObject = {
        id: 0,
        name: data,
        regular_pay: 0,
        ref_emp_id: data,
      };
      setStaffingItem((prev) => {
        return { ...prev, employee: obj };
      });
    } else if (data && data.inputValue) {
      let obj: EmployeeObject = {
        id: 0,
        name: data.inputValue,
        regular_pay: 0,
        ref_emp_id: data.inputValue,
      };
      setStaffingItem((prev) => {
        return { ...prev, employee: obj };
      });
    } else {
      setStaffingItem((prev) => {
        return { ...prev, employee: data, regular_pay: data.regular_pay };
      });
    }
  };

  const handleChange = (key: string, event: React.ChangeEvent<any>) => {
    setStaffingItem((prev) => {
      return { ...prev, [key]: +event.target.value };
    });
  };

  const onAdd = () => {
    let temp = { ...staffingItem };
    temp.annual_pay =
      Math.min(+temp.regular_pay, +temp.budgeted_regular_pay) * +temp.fte_r;
    temp.benifits =
      Math.min(+temp.regular_pay, +temp.budgeted_regular_pay) *
      +temp.fte_r *
      +temp.fringe_rate;

    let isValid = validateForm();
    if (isValid) {
      props.employeeList.push(staffingItem.employee);
      addStaffingItem(temp);
      //Clearing Staffing Form
      let state = {
        id: "",
        employee: {
          name: "",
          id: 0,
          regular_pay: 0,
          ref_emp_id: "",
        },
        regular_pay: "",
        budgeted_regular_pay: "",
        fte_r: "",
        fringe_rate: "",
        annual_pay: 0,
        benifits: 0,
      };
      setStaffingItem(state);
    }
  };

  const validateForm = () => {
    if (staffingItem.employee.name === "") {
      dispatch(showMessage({ message: "Please Enter/Select an Employee" }));
      return false;
    }
    if (staffingItem.regular_pay === "" || staffingItem.regular_pay === 0) {
      dispatch(showMessage({ message: "Please Enter Regular Pay" }));
      return false;
    }
    if (
      staffingItem.budgeted_regular_pay === "" ||
      staffingItem.budgeted_regular_pay === 0
    ) {
      dispatch(showMessage({ message: "Please Enter Budgeted Regular Pay" }));
      return false;
    }
    if (staffingItem.fte_r === "" || staffingItem.fte_r === 0) {
      dispatch(showMessage({ message: "Please Enter FTE R" }));
      return false;
    }
    if (staffingItem.fringe_rate === "" || staffingItem.fringe_rate === 0) {
      dispatch(showMessage({ message: "Please Enter Fringe Rate" }));
      return false;
    }
    return true;
  };
  const updateData = () => {
    if (editItemCallback) {
      let isValid = validateForm();
      if (isValid) {
        let temp = { ...staffingItem };
        temp.annual_pay =
          Math.min(+temp.regular_pay, +temp.budgeted_regular_pay) * +temp.fte_r;
        temp.benifits =
          Math.min(+temp.regular_pay, +temp.budgeted_regular_pay) *
          +temp.fte_r *
          +temp.fringe_rate;
        editItemCallback(temp);
      }
    }
  };

  const onCancel = () => {
    if (cancelEditCallBack) {
      cancelEditCallBack(props.values?.id);
    }
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          ...(props?.extraSX || {}),
        }}
        key={"edit_mode"}
        className={props.className}
      >
        <TableCell component="th" scope="row">
          <Grid container>
            <Grid item sm={12}>
              <AutoCompleteComponent
                style={{
                  minWidth: 200,
                }}
                freeSolo
                size="small"
                limitTags={2}
                fullWidth
                id={"autocomplete"}
                disableClearable
                options={props.employeeList}
                renderOption={(props, option) => renderOption(props, option)}
                getOptionLabel={(option: any) => {
                  return option ? option.name : option;
                }}
                filterSelectedOptions
                onChange={(e: any, v: any, r: any, opt: any) =>
                  handleChangeAutocomplete(v)
                }
                value={{
                  id: staffingItem?.employee?.ref_emp_id,
                  name: staffingItem?.employee?.name,
                }}
                filterOptions={(options, params) => {
                  const filtered = filter(options, params);

                  const { inputValue } = params;
                  // Suggest the creation of a new value
                  const isExisting = options.some(
                    (option: any) => inputValue === option.name
                  );
                  if (inputValue !== "" && !isExisting) {
                    filtered.push({
                      inputValue,
                      name: `Add "${inputValue}"`,
                    });
                  }

                  return filtered;
                }}
                renderInput={(params) => (
                  <TextField
                    placeholder="Employee Name"
                    {...params}
                    InputLabelProps={{
                      shrink: false,
                      style: {
                        marginLeft: 7,
                      },
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid container>
            <Grid item sm={12}>
              <TextField
                placeholder="Regular Pay"
                InputProps={{
                  inputComponent: NumberTextFieldComponent,
                }}
                inputProps={{
                  style: {
                    background: theme.custom.whiteColor,
                    fontFamily: fontInter,
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                  min: 0,
                  decimal: 2,
                }}
                onChange={(e) => handleChange("regular_pay", e)}
                value={staffingItem.regular_pay || ""}
                size="small"
                fullWidth
                id="regular_pay"
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid container>
            <Grid item sm={12}>
              <TextField
                placeholder="Budgeted Regular Pay"
                inputProps={{
                  style: {
                    background: theme.custom.whiteColor,
                    fontFamily: fontInter,
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                  min: 0,
                  decimal: 2,
                }}
                InputProps={{
                  inputComponent: NumberTextFieldComponent,
                }}
                onChange={(e) => handleChange("budgeted_regular_pay", e)}
                value={staffingItem.budgeted_regular_pay || ""}
                size="small"
                fullWidth
                id="budgeted_regular_pay"
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid container>
            <Grid item sm={12}>
              <TextField
                placeholder="FTE-R"
                inputProps={{
                  style: {
                    background: theme.custom.whiteColor,
                    fontFamily: fontInter,
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                  min: 0.01,
                  decimal: 2,
                  max: 1,
                }}
                InputProps={{
                  inputComponent: NumberTextFieldComponent,
                }}
                onChange={(e) => handleChange("fte_r", e)}
                value={staffingItem.fte_r || ""}
                size="small"
                fullWidth
                id="fte_r"
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid container>
            <Grid item sm={12}>
              <TextField
                placeholder="Fringe Rate"
                inputProps={{
                  style: {
                    background: theme.custom.whiteColor,
                    fontFamily: fontInter,
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                  min: 1,
                  decimal: 2,
                  max: 100,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Typography
                        sx={{ color: theme.custom.primaryDarkColor }}
                        variant="inter_p_500_14"
                      >
                        %
                      </Typography>
                    </InputAdornment>
                  ),
                  inputComponent: NumberTextFieldComponent,
                }}
                onChange={(e) => handleChange("fringe_rate", e)}
                value={staffingItem.fringe_rate || ""}
                size="small"
                fullWidth
                id="fringe_rate"
              />
            </Grid>
          </Grid>
        </TableCell>
        {!editMode && (
          <TableCell component="th" scope="row">
            <Button
              disabled={
                staffingItem.employee.name &&
                staffingItem.regular_pay &&
                staffingItem.budgeted_regular_pay &&
                staffingItem.fte_r &&
                staffingItem.fringe_rate
                  ? false
                  : true
              }
              sx={{ background: theme.custom.whiteColor }}
              onClick={onAdd}
              variant="outlined"
            >
              <AddIcon />
            </Button>
          </TableCell>
        )}
        {editMode && (
          <TableCell width={150} component="th" scope="row">
            <Grid spacing={2} container>
              <Grid item xs={6}>
                <Button
                  disabled={
                    staffingItem.employee.name &&
                    staffingItem.regular_pay &&
                    staffingItem.budgeted_regular_pay &&
                    staffingItem.fte_r &&
                    staffingItem.fringe_rate
                      ? false
                      : true
                  }
                  sx={{ background: theme.custom.whiteColor }}
                  onClick={updateData}
                  variant="outlined"
                >
                  <DoneIcon />
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  sx={{ background: theme.custom.whiteColor }}
                  onClick={onCancel}
                  variant="outlined"
                >
                  <CloseIcon />
                </Button>
              </Grid>
            </Grid>
          </TableCell>
        )}
        <TableCell component="th" scope="row">
          <Grid container>
            <Grid item sm={12}>
              {numberFormatter(
                Math.min(
                  +staffingItem.regular_pay,
                  +staffingItem.budgeted_regular_pay
                ) * +staffingItem.fte_r,
                "$"
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell component="th" scope="row">
          <Grid container>
            <Grid item sm={12}>
              {numberFormatter(
                (Math.min(
                  +staffingItem.regular_pay,
                  +staffingItem.budgeted_regular_pay
                ) *
                  +staffingItem.fte_r *
                  +staffingItem.fringe_rate) /
                  100,
                "$"
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell component="th" scope="row">
          {numberFormatter(
            Math.min(
              +staffingItem.regular_pay,
              +staffingItem.budgeted_regular_pay
            ) *
              +staffingItem.fte_r +
              (Math.min(
                +staffingItem.regular_pay,
                +staffingItem.budgeted_regular_pay
              ) *
                +staffingItem.fte_r *
                +staffingItem.fringe_rate) /
                100,
            "$"
          )}
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default AddStaffingValuesForm;
