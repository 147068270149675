import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "authentication",
  initialState: {
    accessToken: "",
    refreshToken: "",
    isLoggedIn: false,
  },
  reducers: {
    login(state, action) {
      state.accessToken = JSON.parse(action.payload)["access"];
      state.refreshToken = JSON.parse(action.payload)["refresh"];
      state.isLoggedIn = true;
    },
    setLoggedIn(state, action) {
      state.isLoggedIn = true;
    },
  },
});

export const { login, setLoggedIn } = authSlice.actions;
export default authSlice.reducer;
