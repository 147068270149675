import { Box, Typography } from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { SingleDatePicker } from "react-dates";
import { renderMonthElement } from "../../utils/date-picker-helper-methods";
import { theme } from "../../theme";
interface Props {
  date?: string;
  onDateChange?: any;
  displayLabel?: boolean;
  minDate?: string;
  maxDate?: string;
  focused?: boolean;
  isSmall?: boolean;
  disabled?: boolean;
  updateOverlay?: Function;
}

const SingleDatePickerComponent: React.FC<Props> = (data) => {
  const disabled = data.disabled === undefined ? false : data.disabled;
  let onDateChange = data.onDateChange;
  let dateFromProps = data.date;
  let isSmall = data.isSmall;
  const [focused, setFocused] = useState(data.focused || false);
  const [dateState, setDateState] = useState<any>(null);
  const [displayLabel, setDisplayLabel] = useState(true);

  useEffect(() => {
    setDateState(dateFromProps ? moment(dateFromProps) : null);
  }, [dateFromProps]);
  useEffect(() => {
    if (data.displayLabel !== undefined) {
      setDisplayLabel(data.displayLabel);
    }
  }, [data.displayLabel]);

  const onFocusChange = ({ focused }: any) => {
    if (focused) {
      data?.updateOverlay && data?.updateOverlay(true);
    } else {
      data?.updateOverlay && data?.updateOverlay(false);
    }
    setFocused(focused);
  };

  const setDateUponSelect = (date: moment.MomentInput) => {
    if (date) {
      setDateState(date);
      onDateChange(date);
    } else {
      setDateState(null);
    }
  };

  const isOutsideRange = (day: any) => {
    if (data.minDate && data.maxDate) {
      return (
        !day.isAfter(moment(data.minDate)) ||
        !day.isBefore(moment(data.maxDate).add(1, "days"))
      );
    } else {
      return false;
    }
  };

  const setInitDate = useCallback(() => {
    return moment(data.date || new Date());
  }, [data.date]);

  return (
    <React.Fragment>
      <Box sx={{ position: "relative" }}>
        {displayLabel && (
          <label
            style={{
              position: "absolute",
              zIndex: 1000,
              color: theme.custom.primaryGreyColor,
              top: isSmall ? "-45%" : "-24%",
              left: "8%",
              background: "#fff",
              paddingLeft: "2%",
              paddingRight: "2%",
            }}
          >
            <Typography variant="inter_500_10">Date</Typography>
          </label>
        )}
        <SingleDatePicker
          disabled={disabled}
          id="single-date-picker"
          date={dateState}
          renderMonthElement={renderMonthElement}
          isOutsideRange={(day) => isOutsideRange(day)}
          numberOfMonths={1}
          small={isSmall}
          onFocusChange={onFocusChange}
          initialVisibleMonth={setInitDate}
          focused={focused}
          onDateChange={setDateUponSelect}
        />
      </Box>
    </React.Fragment>
  );
};

export default SingleDatePickerComponent;
