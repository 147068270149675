import { Divider, List, ListItem } from "@mui/material";
import {
  ConnectorMappingConfig
} from "../../services/user-mapping-service";
import QuickbooksConditionalMappingsListItem from "../molecules/QuickbooksConditionalMappingsListItem";

interface Props {
  configItems: ConnectorMappingConfig[];
}

const QuickbooksConditionalMappings = (props: Props) => {
  const renderList = () => {
    return (
      <List sx={{ border: 1, borderRadius: "6px" }}>
        {props.configItems
          .filter((e) => e.step === 3)
          .map((item, idx, arr) => (
            <div key={item.id}>
              <ListItem
                style={{
                  backgroundColor: idx % 2 === 0 ? "#fffff" : "#f9f9f957",
                }}
                className={`umitem_${item.slug}`}
              >
                <QuickbooksConditionalMappingsListItem UMData={item} />
              </ListItem>
              <Divider
                variant="fullWidth"
                sx={{ display: idx === arr.length - 1 ? "none" : "block" }}
              />
            </div>
          ))}
      </List>
    );
  };
  return <>{renderList()}</>;
};

export default QuickbooksConditionalMappings;
