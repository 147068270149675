import {
  Autocomplete,
  Checkbox,
  Chip,
  Grid,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { fontInter, theme } from "../../theme";
import CloseIcon from "@mui/icons-material/Close";
import { every, filter, isEmpty, omit, some } from "lodash";

type Props = {
  disableClearable: boolean;
  multiple: boolean;
  tagsLimit: number;
  fullwidth: boolean;
  options: Array<any>;
  onChange: Function;
  filterSelectedOptions: boolean;
  getAutocompleteValue: Function;
  onDelete: Function;
  filterKey: string;
  allFilters: any;
  currentFilters: any;
};

const AutoCompleteComponent = styled(Autocomplete)({
  ".MuiAutocomplete-inputRoot": {
    color: theme.custom.dropdownPrimaryColor,
    fontWeight: 500,
    borderRadius: 8,
    fontSize: "14px",
    fontFamily: fontInter,
  },
});

const AutoCompleteWithFilters = (props: Props) => {
  const {
    disableClearable,
    multiple,
    fullwidth,
    tagsLimit,
    options,
    onChange,
    filterSelectedOptions,
    getAutocompleteValue,
    onDelete,
    filterKey,
    allFilters,
    currentFilters,
  } = props;

  const [dropdownOptions, setDropdownOptions] = useState<any[]>();

  let arr = new Set();

  useEffect(() => {
    let _currentFilters = omit(currentFilters, filterKey);

    let obj: any = {};

    Object.keys(_currentFilters).forEach((ele: any) => {
      if (allFilters && allFilters[ele] && allFilters[ele]["kpi_slug"]) {
        obj[allFilters[ele]["kpi_slug"]] = _currentFilters[ele];
      }
    });

    let filteredOptions = filter(allFilters?.[filterKey]?.["data"], (el) =>
      some(obj, (value: any, key) => {
        if (el[key] === value["value"]) {
          arr.add(key);
          return true;
        } else {
          return false;
        }
      })
    );

    if (!isEmpty(filteredOptions)) {
      setDropdownOptions(filteredOptions);
    } else {
      Object.keys(currentFilters).forEach((ele: string) => {
        if (filterKey === ele) {
          if (
            Array.from(arr).length > 0 &&
            !Array.from(arr).includes(allFilters?.[ele]?.["kpi_slug"])
          ) {
            setDropdownOptions(allFilters?.[filterKey]?.["data"]);
            return;
          }
          setDropdownOptions([]);
        } else {
          setDropdownOptions(options);
        }
      });
    }
  }, [currentFilters]);

  const getOptions = () => {
    if (
      allFilters &&
      allFilters[filterKey] &&
      allFilters[filterKey]?.["name"].toLowerCase().includes("program")
    ) {
      return [
        {},
        ...(dropdownOptions?.filter((i: any) =>
          hideDeleted ? !i.label.includes("(deleted)") : true
        ) || []),
      ];
    }

    return dropdownOptions || [];
  };

  const [hideDeleted, setHideDeleted] = useState(true);

  const renderOption = (props: any, option: any) => {
    if (
      allFilters?.[filterKey]?.["name"].toLowerCase().includes("program") &&
      props["data-option-index"] === 0
    ) {
      return (
        <Grid container justifyContent={"end"}>
          <Grid
            item
            component="li"
            {...props}
            onClick={() => {}}
            sx={{
              background: "none !important",
            }}
          >
            <Checkbox
              checked={hideDeleted}
              onClick={(e) => {
                setHideDeleted(!hideDeleted);
              }}
            />
            <Typography py={1} variant="inter_p_500_14">
              Hide deleted
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item component="li" {...props}>
        <Stack direction="column">
          <Typography py={1} variant="inter_p_500_14">
            {option?.label}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  const getFilterOptions = (options: any, searchTerm: string) => {
    const _options = options.filter((i: any) =>
      i.label?.toLowerCase().trim().includes(searchTerm)
    );
    if (allFilters?.[filterKey]?.["name"].toLowerCase().includes("program")) {
      return [
        {},
        ...(_options.filter((i: any) =>
          hideDeleted ? !i.label?.includes("(deleted)") : true
        ) || []),
      ];
    }

    return _options || [];
  };

  return (
    <div>
      <AutoCompleteComponent
        disableClearable={disableClearable}
        multiple={multiple}
        limitTags={tagsLimit}
        fullWidth={fullwidth}
        id={filterKey + "autocomplete"}
        options={getOptions()}
        renderOption={renderOption}
        filterOptions={(options, state) =>
          getFilterOptions(options, state.inputValue.toLowerCase().trim())
        }
        getOptionLabel={(option: any) => {
          return option ? option.label : "";
        }}
        filterSelectedOptions={filterSelectedOptions}
        onChange={(e: any, v: any, r: any, opt: any) => {
          // if (!v) return;
          onChange(filterKey, opt, multiple);
        }}
        value={getAutocompleteValue(filterKey) || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              style: {
                marginLeft: 7,
              },
            }}
            label={
              <Typography variant="inter_p_500_10">
                {allFilters?.[filterKey]?.["name"] || ""}
              </Typography>
            }
          />
        )}
        renderTags={(tagValue, getTagProps) => {
          return tagValue.map((option: any, index) => (
            <Chip
              sx={{
                background: theme.custom.whiteColor,
                border: `1px solid ${theme.custom.primaryDarkColor}`,
                marginRight: "2px",
              }}
              label={
                <Typography variant="inter_500_12">{option?.label}</Typography>
              }
              onDelete={() => {
                onDelete(filterKey, option, index);
              }}
              deleteIcon={<CloseIcon />}
              size={"small"}
            />
          ));
        }}
      />
    </div>
  );
};

export default AutoCompleteWithFilters;
