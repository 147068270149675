import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Fade,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { BudgetProgramsObject } from "../../Types/BudgetProgramsObject";
import { theme, fontInter } from "../../theme";
import { useAppDispatch } from "../../store/hook";
import {
  addBudget,
  getAllContractsForProgram,
} from "../../services/budget-service";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { urlNameStrip } from "../../utils/textFunctions";
import { ContractObject } from "../../Types/ContractObject";
import DateRangePickerCustomComponent from "../atoms/DateRangePickerCustomComponent";
interface Props {
  program_name?: Array<BudgetProgramsObject>;
  showPopup?: boolean;
  setShowPopup?: React.Dispatch<React.SetStateAction<boolean>>;
}

const INIT_STATE = {
  budget_uid: "",
  budget_name: "",
  daterange: {
    start: moment().format("YYYY-MM-DD"),
    end: moment().add(1, "years").format("YYYY-MM-DD"),
    action: "reset",
  },
  program: {
    id: "",
    name: "",
  },
  contract: {
    id: "",
    name: "",
  },
};

const AutoCompleteComponent = styled(Autocomplete)({
  ".MuiAutocomplete-inputRoot": {
    color: theme.custom.dropdownPrimaryColor,
    fontWeight: 500,
    borderRadius: 8,
    fontSize: "14px",
    fontFamily: fontInter,
  },
});

const AddBudgetModalComponent: React.FC<Props> = (data) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const setShowModal = data.setShowPopup;
  const [programs, setPrograms] = useState<any>([]);
  const [addBudgetState, setAddBudgetState] = useState<any>(INIT_STATE);
  const [open, setOpen] = useState<any>(false);
  const [errMsg, setErrMsg] = useState<string>("");
  const [invalidText, setInvalidText] = useState<boolean>(false);
  const [contracts, setContracts] = useState([] as ContractObject[]);

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setPrograms(data?.program_name || []);
  }, [data.program_name]);

  useEffect(() => {
    setOpen(data.showPopup);
  }, [data.showPopup]);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    bgcolor: "#fff",
    border: `1px solid ${theme.custom.KPIMoleculeBorderColor}`,
    borderRadius: "6px",
    px: 4,
    py: 6,
  };

  const handleClose = () => {
    setOpen(false);
    if (setShowModal) {
      setShowModal(false);
    }
    setAddBudgetState((prev: any) => {
      return {
        ...prev,
        budget_uid: "",
        budget_name: "",
        daterange: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().add(1, "years").format("YYYY-MM-DD"),
          action: "reset",
        },
        program: {
          id: "",
          name: "",
        },
        contract: {
          id: "",
          name: "",
        },
      };
    });
    setErrMsg("");
  };

  const handleChangeAutoComplete = (value: any, key: string) => {
    if (!value) {
      setAddBudgetState((prev: any) => {
        return { ...prev, [key]: "" };
      });
      return;
    }
    if (key === "program") {
      setLoading(true);
      getAllContractsForProgram(value["id"])
        .then((res) => {
          if (res.status === 200) {
            setContracts(res.data?.data?.contract || []);
            setAddBudgetState((prev: any) => {
              return { ...prev, contract: { name: "", id: "" } };
            });
            setLoading(false);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setAddBudgetState((prev: any) => {
      return { ...prev, [key]: value };
    });
  };

  const onBudgetNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let regex = /^[\w \_\-\(\)\s]+$/gi;
    if (regex.test(event.target.value)) {
      setErrMsg("");
      setInvalidText(false);
    } else {
      if (event.target.value.length > 0) {
        setErrMsg(
          "Only allowed character alphanumeric, space, -, _, (, ) for Budget Name"
        );
        setInvalidText(true);
      }
    }
    setAddBudgetState((prev: any) => {
      return { ...prev, budget_name: event.target.value };
    });
  };

  const [hideDeleted, setHideDeleted] = useState(true);

  const renderOption = (props: any, option: any, key: string = "") => {
    if (key === "program" && props["data-option-index"] === 0) {
      return (
        <Grid container justifyContent={"end"}>
          <Grid
            item
            component="li"
            {...props}
            onClick={() => {}}
            sx={{
              background: "none !important",
            }}
          >
            <Checkbox
              checked={hideDeleted}
              onClick={(e) => {
                setHideDeleted(!hideDeleted);
              }}
            />
            <Typography py={1} variant="inter_p_500_14">
              Hide deleted
            </Typography>
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item component="li" {...props}>
        <Stack direction="column">
          <Typography py={1} variant="inter_p_500_14">
            {option?.name}
          </Typography>
        </Stack>
      </Grid>
    );
  };

  const onChange = useCallback((dateRange: any) => {
    setAddBudgetState((prev: any) => {
      return { ...prev, daterange: dateRange };
    });
  }, []);

  const handleSubmit = () => {
    if (addBudgetState.budget_name === "") {
      setErrMsg("Budget Name is Required");
      return;
    }
    if (
      addBudgetState.daterange.start === "" ||
      addBudgetState.daterange.end === ""
    ) {
      setErrMsg("Select Proper Date Range");
      return;
    }
    if (
      !addBudgetState?.program ||
      addBudgetState?.program?.id === "" ||
      addBudgetState?.program?.name === ""
    ) {
      setErrMsg("Program is mandatory");
      return;
    }
    if (
      !addBudgetState?.contract ||
      addBudgetState?.contract?.id === "" ||
      addBudgetState?.contract?.name === ""
    ) {
      setErrMsg("Contract is mandatory");
      return;
    }
    if (invalidText === true) {
      setErrMsg(
        "Only allowed character alphanumeric, space, -, _, (, ) for Budget Name"
      );
      return;
    }

    if (
      addBudgetState.budget_name !== "" &&
      addBudgetState.daterange.start !== "" &&
      addBudgetState.daterange.end !== "" &&
      addBudgetState.program.id !== "" &&
      addBudgetState.program.name !== "" &&
      addBudgetState.contract.id !== "" &&
      addBudgetState.contract.name !== "" &&
      invalidText === false
    ) {
      formSubmitHelper();
    }
  };

  const formSubmitHelper = () => {
    let finalObj: Object = {
      name: addBudgetState["budget_name"],
      ref_program_id: addBudgetState?.program?.id,
      ref_program_name: addBudgetState?.program?.name,
      start_date: addBudgetState?.daterange?.start,
      end_date: addBudgetState?.daterange?.end,
      ref_contract_id: addBudgetState?.contract?.id,
      ref_contract_title: addBudgetState?.contract?.name,
    };

    addBudget(finalObj).then((res: any) => {
      if (res.status === 400) {
        setErrMsg(res?.data?.message);
        return;
      }
      if (res.status === 403) {
        setErrMsg(
          "You don't have the access to create a budget. Please contact admin to get access"
        );
        return;
      }
      setAddBudgetState((prev: any) => {
        return { ...prev, budget_uid: res?.data?.budget_uid };
      });
      if (setShowModal) {
        setShowModal(false);
      }
      navigateToEditBudgetItem(res?.data?.budget_uid);
    });
  };

  const editBudgetUrlHelper = (budget_id: string) => {
    return `/budgets/${urlNameStrip(addBudgetState.budget_name)}_${budget_id}`;
  };

  const navigateToEditBudgetItem = (budget_id: string) => {
    navigate(editBudgetUrlHelper(budget_id));
  };
  return (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        sx={{
          zIndex: (th: any) => th.zIndex.drawer + 10000,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Grid container>
              <Grid textAlign={"center"} item xs={11}>
                <Typography
                  sx={{ color: `${theme.custom.primaryDarkColor}` }}
                  variant="poppins_h3_600_24"
                >
                  Add New Budget
                </Typography>
              </Grid>
              <Grid textAlign="right" item xs={1}>
                <CancelOutlinedIcon
                  onClick={handleClose}
                  sx={{
                    color: `${theme.custom.primaryGreyColor}`,
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>

            <Grid mt={6} container>
              <Grid sx={{ height: "50px" }} mb={3} item xs={12}>
                <TextField
                  onChange={onBudgetNameChange}
                  fullWidth
                  inputProps={{
                    maxLength: 200,
                    style: {
                      fontFamily: fontInter,
                      fontSize: "14px",
                      color: theme.custom.primaryDarkColor,
                    },
                  }}
                  id="budget-name"
                  label={
                    <Typography variant="inter_p_500_14">
                      Budget Name
                    </Typography>
                  }
                  variant="outlined"
                />
              </Grid>
              <Grid
                mb={3}
                sx={{
                  border: `1px solid ${theme.custom.borderColor}`,
                  borderRadius: "6px",
                }}
                item
                xs={12}
              >
                <DateRangePickerCustomComponent
                  onChange={onChange}
                  dateRangeValue={addBudgetState.daterange}
                  menuOptions={{}}
                />
              </Grid>
              <Grid sx={{ height: "50px" }} mb={3} item xs={12}>
                <AutoCompleteComponent
                  id={"program_filter"}
                  options={[
                    {},
                    ...(programs.filter((i: any) =>
                      hideDeleted ? !i.name.includes("(deleted)") : true
                    ) || []),
                  ]}
                  value={addBudgetState["program"]}
                  renderOption={(props: any, option: any) =>
                    renderOption(props, option, "program")
                  }
                  filterOptions={(options, state) => {
                    return [
                      {},
                      ...(options
                        .filter((i: any) =>
                          i.name
                            ?.toLowerCase()
                            .trim()
                            .includes(state.inputValue.toLowerCase().trim())
                        )
                        .filter((i: any) =>
                          hideDeleted ? !i.name.includes("(deleted)") : true
                        ) || []),
                    ];
                  }}
                  getOptionLabel={(option: any) => {
                    return option ? option.name : "";
                  }}
                  onChange={(e: any, v: any, r: any, opt: any) => {
                    handleChangeAutoComplete(v, "program");
                  }}
                  filterSelectedOptions={true}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        style: {
                          marginLeft: 7,
                        },
                      }}
                      label={
                        <Typography variant="inter_p_500_10">
                          Program
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid sx={{ height: "50px" }} mb={3} item xs={12}>
                <AutoCompleteComponent
                  id={"contracts_filter"}
                  disabled={loading}
                  options={contracts}
                  value={addBudgetState["contract"]}
                  renderOption={(props: any, option: any) =>
                    renderOption(props, option)
                  }
                  getOptionLabel={(option: any) => {
                    return option ? option.name : "";
                  }}
                  onChange={(e: any, v: any, r: any, opt: any) => {
                    handleChangeAutoComplete(v, "contract");
                  }}
                  filterSelectedOptions={true}
                  renderInput={(params: any) => (
                    <TextField
                      {...params}
                      InputLabelProps={{
                        style: {
                          marginLeft: 7,
                        },
                      }}
                      label={
                        <Typography variant="inter_p_500_10">
                          Contracts under program
                        </Typography>
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={handleSubmit}
                  sx={{ height: "50px" }}
                  fullWidth
                  variant="contained"
                >
                  Add Budget
                </Button>
              </Grid>
              {errMsg && errMsg.length > 0 && (
                <Grid mt={2} textAlign={"center"} item xs={12}>
                  <Typography sx={{ color: theme.custom.errorColor }}>
                    {errMsg}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  );
};

export default React.memo(AddBudgetModalComponent);
