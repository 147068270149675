import { Box } from "@mui/material";
import { theme } from "../../theme";

const AIBox = (Props: any) => {
  return (
    <Box
      style={{
        background: `linear-gradient(180deg, ${theme.custom.yellowButtonColor} 0%, ${theme.custom.activeColor} 100%)`,
        borderRadius: "6px",
        boxShadow: "0px 4px 10px 0px rgba(0, 0, 0, 0.12)",
        padding: "1px",
      }}
    >
      <Box
        style={{
          background: "#fff",
          width: "100%",
          borderRadius: "6px",
        padding: "1px",
        }}
      >
        {Props.children}
      </Box>
    </Box>
  );
};

export default AIBox
