import { Grid } from "@mui/material";
import { memo, useState } from "react";
import { useDeepCompareEffect } from "react-use";
import {
  ProgramPermissions,
  ProgramPermissionsMeta,
  Value,
} from "../../../Types/UserManagement";
import { theme } from "../../../theme";
import CustomizedCheckbox from "../../atoms/CustomizedCheckbox";

type Props = {
  metaItem: ProgramPermissionsMeta;
  valueItem: Value;
  setCheckboxSelected: CallableFunction;
  memberDetailsForItem: ProgramPermissions | undefined;
};

function MemberDataPermissionCheckboxItem({
  metaItem,
  valueItem,
  setCheckboxSelected,
  memberDetailsForItem,
}: Props) {
  const [isSelected, setIsSelected] = useState(false);

  useDeepCompareEffect(() => {
    if (memberDetailsForItem === undefined) {
      // do nothing for now as program permissions don't have default keys even
    } else
      setIsSelected(
        memberDetailsForItem.has_full_access ||
          !!memberDetailsForItem.values?.find(
            (i) => i.label === valueItem.label
          )
      );
  }, [JSON.stringify(memberDetailsForItem)]);

  const handleOnChange = (isChecked: boolean) => {
    setCheckboxSelected(metaItem, isChecked, valueItem);
  };

  return (
    <Grid container alignItems={"center"} justifyContent={"space-between"}>
      <Grid item>
        <CustomizedCheckbox
          isChecked={isSelected}
          onChange={handleOnChange}
          labelProps={{
            title: valueItem.label,
            extraSx: {
              variant: "inter_p_600_16",
              color: theme.custom.primaryDarkColor,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default memo(MemberDataPermissionCheckboxItem);
