import {
  Box,
  Button,
  ButtonGroup,
  Grid,
  InputAdornment,
  TextField,
} from "@mui/material";
import { clone } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import tickIcon from "../../assets/TickIcon.png";
import closeIcon from "../../assets/cancel.png";
import deleteIcon from "../../assets/deleteIcon.png";
import pencil from "../../assets/pencil.png";
import { ConnectorMappingConfig } from "../../services/user-mapping-service";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import {
  setSaving,
  setUMDirty,
  updateMapping,
} from "../../store/user-mapping-store";
import { createMappingEntry } from "../../utils/user-mapping-utils";
import UMQueryBuilder from "./UserMapping/UMQueryBuilder";
import { NameLabelPair, OptionGroup } from "react-querybuilder";

interface Props {
  UMData: ConnectorMappingConfig;
  existingContractNames: string[];
}

const QuickbooksContractsMappingsListItem = ({
  UMData,
  existingContractNames,
}: Props) => {
  const allPossibleKeys = useAppSelector((state) => {
    return state.userMapping.allPossibleKeys;
  });
  const [editMode, setEditMode] = useState<boolean>(false);

  const [readyForUpdate, setReadyForUpdate] = useState(false);
  const [title, setTitle] = useState(UMData.user_mapping.alt_title);
  const [contractNameError, setContractNameError] = useState("");
  const [conditions, setConditions] = useState(
    UMData.user_mapping?.mapping?.["conditions"] || []
  );
  const UMDataRef = useRef(null as any);
  const [isDirty, setisDirty] = useState(false);

  const toggleEditMode = () => {
    setEditMode(!editMode);
  };

  useEffect(() => {
    setTitle(UMData.user_mapping.alt_title);
  }, [UMData.user_mapping.alt_title]);

  useEffect(() => {
    setConditions(UMData.user_mapping?.mapping?.["conditions"] || []);
  }, [JSON.stringify(UMData.user_mapping?.mapping?.["conditions"])]);

  useEffect(() => {
    setReadyForUpdate(true);
  }, [UMData.user_mapping]);

  const dispatch = useAppDispatch();

  const updateUM = useCallback(
    (item: any, title?: string) => {
      if (item && item.length) {
        setisDirty(true);
        dispatch(setUMDirty(true));
      }
      UMDataRef.current = {
        item: item,
        title: title || UMData.user_mapping.alt_title,
      };
    },
    [JSON.stringify(UMData)]
  );

  const save = () => {
    dispatch(setSaving(true));
    setisDirty(false);
    dispatch(setUMDirty(false));

    dispatch(
      updateMapping({
        meta_data_id: UMData.id,
        mapping: createMappingEntry(
          "conditions",
          undefined,
          undefined,
          [],
          UMDataRef.current?.item
        ),
        alt_title: UMDataRef.current?.title,
        contractId: UMData.user_mapping.id,
      })
    );
  };

  const cancel = () => {
    setisDirty(false);
    dispatch(setUMDirty(false));
    setConditions(clone(UMData.user_mapping?.mapping?.["conditions"] || []));
  }

  const dateOperators: NameLabelPair[] | OptionGroup<NameLabelPair>[] = [
    { name: "gt", label: "Greater than" },
    { name: "gte", label: "Greter than equal to" },
    { name: "le", label: "Less than" },
    { name: "lte", label: "Less than equal to" },
  ];

  const fields = () => {
    return Object.keys(allPossibleKeys)
      .filter((i: string) => i !== "program")
      .map((k) => {
        if (k === "date")
          return {
            name: k,
            label: allPossibleKeys[k],
            operators: dateOperators,
            defaultOperator: "gt",
          };
        else {
          return {
            name: k,
            label: allPossibleKeys[k],
          };
        }
      });
  };

  const updateTitle = (newTitle: string) => {
    if (newTitle.length === 0) {
      setContractNameError("Name should be at least one character");
    } else if (newTitle.length > 128) {
      setContractNameError("Name should be at most 128 characters");
    } else if (existingContractNames.includes(newTitle.toLowerCase())) {
      setContractNameError("Contract name already exists");
    } else {
      UMDataRef.current = {
        item: conditions,
        title: newTitle,
      };
      save();
      setEditMode(false);
      setContractNameError("");
    }
  };

  const onDelete = () => {
    if (window.confirm("Are you sure you want to delete this contract?")) {
      dispatch(
        updateMapping({
          meta_data_id: UMData.id,
          delete: true,
          contractId: UMData.user_mapping.id,
        })
      );
    }
  };

  return (
    <Grid container justifyContent="space-between" p={0}>
      <Grid item sx={{ mt: 1 }} xs={3}>
        <TextField
          value={title}
          size="small"
          disabled={!editMode}
          onBlur={() => setEditMode(true)}
          sx={{
            border: "1px solid var(--Grey-text, #6E6E81)",
            borderRadius: "6px",
            // height: 50,
          }}
          onChange={(e) => setTitle(e.target.value)}
          required
          error={contractNameError !== ""}
          helperText={contractNameError}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <img
                  src={pencil}
                  alt="Edit"
                  height={"20px"}
                  width={"20px"}
                  onClick={toggleEditMode}
                  style={{
                    cursor: "pointer",
                    display: editMode ? "none" : "block",
                    opacity: 1,
                  }}
                />
                <img
                  src={deleteIcon}
                  alt="Delete"
                  height={"18px"}
                  width={"18px"}
                  onClick={() => onDelete()}
                  style={{
                    marginLeft: 16,
                    cursor: "pointer",
                    display: editMode ? "none" : "block",
                    opacity: 1,
                  }}
                />
                <img
                  src={tickIcon}
                  alt="Confirm"
                  height={"20px"}
                  width={"20px"}
                  onClick={() => updateTitle(title)}
                  style={{
                    cursor: "pointer",
                    display: editMode ? "block" : "none",
                  }}
                />
                <img
                  src={closeIcon}
                  alt="Close"
                  height={"14px"}
                  width={"14px"}
                  onClick={() => {
                    setTitle(UMData.user_mapping.alt_title);
                    setEditMode(false);
                    setContractNameError("");
                  }}
                  style={{
                    marginLeft: 16,
                    cursor: "pointer",
                    display: editMode ? "block" : "none",
                  }}
                />
              </InputAdornment>
            ),
          }}
        ></TextField>
      </Grid>
      <Grid item xs={8}>
        <Box style={{ position: "relative" }}>
          <ButtonGroup
            variant="text"
            disabled={!isDirty}
            style={{
              position: "absolute",
              right: "0",
              top: "0",
              zIndex: "1",
            }}
          >
            <Button onClick={save}>Save</Button>
            <Button onClick={cancel}>Cancel</Button>
          </ButtonGroup>

          <UMQueryBuilder
            umItem={conditions}
            onChange={updateUM}
            fields={fields()}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default QuickbooksContractsMappingsListItem;
