import { List, Skeleton } from "@mui/material";
import range from "lodash/range";
import { useEffect } from "react";
import { io } from "socket.io-client";
import { environment } from "../../environments/environment-dev";
import {
  getConnectorsList,
  getConnectorSyncStatusList,
} from "../../services/connector-service";
import {
  setConnectors,
  setConnectorSyncStatuses,
  setConnectorSyncStatusSingleByRefSlug,
} from "../../store/connector-store";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { ListConnectorAPIResponseModel } from "../../Types/ListConnectorAPIResponseModel";
import ConnectorListItem from "../molecules/ConnectorListItem";

interface Props {}

const ConnectorsList = (props: Props) => {
  const dispatch = useAppDispatch();
  const items = useAppSelector((state) => {
    return state.connector.connectors;
  });

  const getAllConnectors = () => {
    getConnectorsList().then((getConnectorsListResp) => {
      if (getConnectorsListResp && getConnectorsListResp.status === 200) {
        dispatch(setConnectors(getConnectorsListResp.data));
        getConnectorSyncStatusList().then((getConnectorSyncStatusListResp) => {
          if (
            getConnectorSyncStatusListResp &&
            getConnectorSyncStatusListResp.status === 200
          ) {
            dispatch(
              setConnectorSyncStatuses(getConnectorSyncStatusListResp.data)
            );
          }
        });
      }
    });
  };
  useEffect(() => {
    getAllConnectors();
  }, []);

  
  useEffect(() => {
    const socket = io(environment.socketUrl || "");
    socket.emit("join", { room: environment.socketRoom });
    socket.on("my_response", function (msg) {
      if (
        msg &&
        typeof msg.data === "object" &&
        msg.data.hasOwnProperty("connector_id")
      ) {
        try {
          dispatch(
            setConnectorSyncStatusSingleByRefSlug({
              external_reference_slug: msg.data.connector_id,
              status: msg.data.event_status,
              last_successful_sync_time: msg.data.event_time,
            })
          );
        } catch (err) {
          console.log("in error", msg.data);
        }
      }
    });
  }, []);

  return (
    <List>
      {items.length > 0 ? (
        items.map((item: ListConnectorAPIResponseModel) => (
          <ConnectorListItem
            key={item.id.toString()}
            item={item}
            refreshList={getAllConnectors}
          />
        ))
      ) : (
        <>
          {range(3).map((num) => (
            <Skeleton
              key={num}
              sx={{ my: 2, bgcolor: "grey.100" }}
              variant="rectangular"
              animation="wave"
              height={120}
            />
          ))}
        </>
      )}
    </List>
  );
};

export default ConnectorsList;
