import { has, isArray, isEmpty, isObject, isString } from "lodash";
import moment from "moment";

export const filterDataFormatter = (filterData: any, allFilters: any = {}) => {
  let obj: any = {};
  if (filterData) {
    Object.keys(filterData).forEach((el: string) => {
      // Filter type for each attributes (eg: daterange, monthrange, search)
      let filterType = allFilters?.[el]?.type

      if (filterData && isObject(filterData[el]) && isEmpty(filterData[el])) {
        return;
      } else if (
        filterData &&
        filterData[el] &&
        isArray(filterData[el]) &&
        isEmpty(filterData[el])
      ) {
        return;
      } else if (
        filterData &&
        filterData[el] &&
        isArray(filterData[el]) &&
        filterData[el].length > 0
      ) {
        let temp: any = [];
        temp = filterData[el].map((data: any) => {
          return data.value;
        });
        obj[el] = temp;
      } else if (
        el === "daterange" ||
        el.includes("Date") ||
        (has(filterData[el], "start") && has(filterData[el], "end"))
      ) {
        return (obj[el] = filterData[el]);
      } else if (el === "single-date") {
        obj["daterange"] = filterData[el];
      } else if (el === "search") {
        if (filterData[el]) {
          obj["search"] = filterData[el];
        }
      } else if (filterType && filterType === "monthrange") {
        if (filterData[el]) {
          const output = moment(filterData[el], "YYYY-MM");
          let finalObj = {
            start: output.startOf("month").format("YYYY-MM-DD"),
            end: output.endOf("month").format("YYYY-MM-DD"),
          };
          obj[el] = finalObj;
        }
      } else if (el === "company_start_date") {
        obj[el] = filterData[el];
      } else if (filterData[el].length && isString(filterData[el])) {
        if (!filterData[el]) {
          return;
        }
        obj[el] = filterData[el];
      } else {
        if (!filterData[el]["value"]) {
          return;
        }
        obj[el] = filterData[el]["value"];
      }
    });
  }

  return obj;
};
