import { Box, Tabs, Tab, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Sidebar } from "../components/complexes/Sidebar";
import ManageUsers from "../components/complexes/ManageUsers";
import { getCompanyDataFromLocalStorage } from "../services/userinfo-service";
import { theme } from "../theme";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`users-tabpanel-${index}`}
      aria-labelledby={`users-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box pt={1}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const UserManagement = () => {
  const [value, setValue] = useState(0);
  const currentCompany = JSON.parse(getCompanyDataFromLocalStorage());

  return (
    <React.Fragment>
      <Stack direction="row">
        <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
          <Sidebar />
        </Box>

        <Box px={4} mt={10} sx={{ width: "100%", position: "relative" }}>
          <Box>
            <Typography
              sx={{ color: theme.custom.primaryDarkColor }}
              variant="poppins_h4_600_20"
            >
              User Management - {currentCompany.company_name}
            </Typography>
          </Box>
          <Box mt={1}>
            <Tabs value={value} aria-label="user-management-tabs">
              <Tab
                label={
                  <Typography variant="inter_p_600_16" color="primary.main">
                    Users
                  </Typography>
                }
              />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <ManageUsers />
          </TabPanel>
        </Box>
      </Stack>
    </React.Fragment>
  );
};

export default React.memo(UserManagement);
