import {
  AppBar,
  Box,
  Divider,
  Grid,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { memo, useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { useEffectOnce } from "react-use";
import AICreate from "../assets/ai_create_2.png";
import BackButton from "../assets/back_button.png";
import BlankReport from "../assets/blank_report_2.png";
import ArrowLeft from "../assets/fi-rr-arrow-left 2.png";
import DefaultA4Thumbnail from "../assets/thumbnailA4.png";
import TickIcon from "../assets/tick_circular_white_bg.png";
import DefaultPPTThumbnail from "../assets/thumbnailPPT.png";
import TemplateThumbnailComponent from "../components/atoms/TemplateThumbnailComponent";
import TemplateListFilter from "../components/complexes/TemplateListFilter";
import CreateNewImpactReport from "../components/molecules/Editor/CreateImpactReport";
import {
  createReport,
  defaultReportSize,
  getAudiencePurpose,
  getTemplateLibrary,
} from "../services/impact-report-service";
import { getCompanyId } from "../services/userinfo-service";
import { showMessage } from "../store/error-handler-store";
import { useAppDispatch } from "../store/hook";
import { theme } from "../theme";

import { Navigation, Pagination, Scrollbar } from "swiper";
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss"; // core Swiper
import "../styles/swipe-js.scss";
import { sortBy } from "lodash";

// Direct React component imports

function CreateImpactReportPage() {
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [selectedTemplate, setSelectedTemplate] = useState<any | null>(null);
  const [templateList, setTemplateList] = useState<Array<Object>>([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    search_query: "",
    audience: "",
    purpose: "",
    category: "",
  });

  const page = useRef(1);
  const [hasNext, sethasNext] = useState(true);

  const loadTemplateLibrary = (_filters: any) => {
    setLoading(true);
    if (page.current === 1) setTemplateList([]);
    getTemplateLibrary(page.current, _filters).then((resp) => {
      setLoading(false);
      if (resp && resp.data) {
        if (page.current === 1) {
          setTemplateList(resp.data.results || []);
        } else {
          setTemplateList([...templateList, ...resp.data.results]);
        }
        if (resp.data.next) {
          sethasNext(true);
        } else {
          sethasNext(false);
        }
      }
    });
  };

  const onBackButtonClick = () => {
    navigate("/impact-report");
  };

  const onDeleteTemplate = () => {
    page.current = 1;
    loadTemplateLibrary(filters);
  };
  const handleTemplateClick = (item: any) => {
    setSelectedTemplate(item);
    setOpenPopup(true);
  };

  const onPopupClose = (popupOpen: boolean) => {
    setOpenPopup(popupOpen);
    setSelectedTemplate(null);
  };

  const dispatch = useAppDispatch();

  const setEditTemplate = (template: any) => {
    let reportData = {
      name: template?.attributes?.title,
      template_id: template?.id,
      is_template_edit_flow: true,
      company_uid: getCompanyId(),
      attributes: defaultReportSize,
    };
    createReport(reportData).then((res) => {
      if (res.status === 403) {
        dispatch(
          showMessage({
            message:
              "You don’t have access to edit this template. Please reach out to admin to get access",
            variant: "error",
          })
        );
        return;
      }
      if (res.status === 201) {
        navigate(`/impact-report/${res["data"]["name"]}_${res["data"]["uid"]}`);
      }
    });
  };

  const [audienceOptions, setAudienceOptions] = useState([]);
  const [purposeOptions, setPurposeOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffectOnce(() => {
    getAudiencePurpose().then((resp) => {
      if (resp && resp.data) {
        const audiences =
          resp.data.filter((i: any) => i.slug === "audience") || [];
        const purposes =
          resp.data.filter((i: any) => i.slug === "purpose") || [];
        const categories =
          resp.data.filter((i: any) => i.slug === "category") || [];
        setAudienceOptions(audiences);
        setPurposeOptions(purposes);
        setCategoryOptions(sortBy(categories, "option_label"));
      }
    });
    loadTemplateLibrary(filters);
  });

  const getDefaultThumbnail = (slug: any) => {
    if (slug === "presentation_16_9") return DefaultPPTThumbnail;
    return DefaultA4Thumbnail;
  };

  const onApply = (newFilters: any) => {
    page.current = 1;
    setFilters(newFilters);
    loadTemplateLibrary(newFilters);
  };
  const loadPages = () => {
    if (!hasNext) return;
    page.current += 1;
    loadTemplateLibrary(filters);
  };

  const handleOnClick = (_category: any) => {
    if (_category.id !== selectedCategory) {
      setSelectedCategory(_category.id);
      onApply({ ...filters, category: _category.id });
    } else {
      setSelectedCategory("");
      onApply({ ...filters, category: "" });
    }
  };

  return (
    <Grid
      container
      sx={{
        background: theme.impactReportingColors.bgColor,
      }}
    >
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          background: theme.impactReportingColors.headerColor,
        }}
      >
        <Toolbar>
          <Grid
            container
            alignItems={"center"}
            px={2}
            sx={{ position: "relative" }}
          >
            <Grid item sm={5}>
              <img
                onClick={onBackButtonClick}
                src={BackButton}
                alt="Back"
                style={{
                  maxHeight: "20px",
                  cursor: "pointer",
                  position: "relative",
                }}
              ></img>
            </Grid>
            <Grid item sm={6}>
              <Typography variant="inter_p_600_16">
                Create new report
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      <Grid container px={8} pt={4} pb={8}>
        <Typography variant="poppins_h5_600_18" sx={{ my: 2, ml: 0.5 }}>
          Choose a template
        </Typography>

        <TemplateListFilter
          onApply={onApply}
          filters={filters}
          setFilters={setFilters}
          audienceOptions={audienceOptions}
          purposeOptions={purposeOptions}
        />

        <Grid container mt={2}>
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={16}
            navigation
            updateOnWindowResize
            style={{
              paddingTop: "24px",
              paddingBottom: "28px",
            }}
            modules={[Navigation, Pagination]}
          >
            {categoryOptions.map((category: any) => (
              <SwiperSlide
                style={{
                  width: "242px",
                  cursor: "pointer",
                  paddingBottom: "12px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    right: "-10px",
                    top: "-10px",
                    display:
                      category.id === selectedCategory ? "block" : "none",
                  }}
                >
                  <img src={`${TickIcon}`} height={"34px"} width={"34px"} />
                </div>
                <Grid
                  container
                  sx={{
                    height: "78px",
                    borderRadius: "6px",
                    backgroundColor: "white",
                    border:
                      category.id === selectedCategory
                        ? "1px solid #7F7CFF"
                        : "1px solid #E1E1E1",
                    p: 1,
                  }}
                  onClick={() => handleOnClick(category)}
                  alignItems={"center"}
                >
                  <Grid pr={1}>
                    <img
                      src={category.option_extra_data.thumbnail_url}
                      height={40}
                      width={40}
                    />
                  </Grid>
                  <Grid xs={8.9}>
                    <Grid container direction={"column"}>
                      <Grid>
                        <Typography variant="inter_p_600_14">
                          {category.option_label.length > 18
                            ? category.option_label.substr(0, 18) + "..."
                            : category.option_label}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Tooltip title={category.option_extra_data.description}>
                          <Typography
                            variant="inter_400_12"
                            sx={{
                              color: theme.custom.primaryGreyColor,
                            }}
                          >
                            {category.option_extra_data.description.length > 42
                              ? category.option_extra_data.description.substr(
                                  0,
                                  42
                                ) + "..."
                              : category.option_extra_data.description}
                          </Typography>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </SwiperSlide>
            ))}
          </Swiper>
        </Grid>

        <Grid container mx={0} xs={12}>
          <Grid xs={12}>
            {!loading && !templateList.length ? (
              <p style={{ textAlign: "center" }}>
                <Typography
                  sx={{ color: theme.custom.primaryGreyColor }}
                  variant="inter_p_600_16"
                >
                  No templates found. Try changing the filters
                </Typography>
              </p>
            ) : (
              <InfiniteScroll
                dataLength={templateList.length}
                next={loadPages}
                hasMore={hasNext}
                endMessage={
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "32px",
                      marginBottom: "16px",
                    }}
                  >
                    <Typography
                      sx={{ color: theme.custom.primaryGreyColor }}
                      variant="inter_p_600_16"
                    >
                      No more templates to load
                    </Typography>
                  </p>
                }
                loader={
                  <p
                    style={{
                      textAlign: "center",
                      marginTop: "32px",
                      marginBottom: "16px",
                    }}
                  >
                    <Typography
                      sx={{ color: theme.custom.primaryGreyColor }}
                      variant="inter_p_600_16"
                    >
                      Loading...
                    </Typography>
                  </p>
                }
              >
                <Grid container spacing={2}>
                  <Grid item sx={{ cursor: "pointer" }} sm={2}>
                    <Box>
                      <Grid
                        onClick={() => setOpenPopup(true)}
                        container
                        sx={{
                          height: 200,
                          border: "1px solid #e1e1e1",
                          borderRadius: "6px",
                          overflow: "hidden",
                        }}
                        justifyContent={"center"}
                        alignItems={"center"}
                      >
                        <img
                          src={BlankReport}
                          alt="blank_report"
                          width={"92"}
                        />
                      </Grid>
                      <Box
                        sx={{
                          height: "42px",
                          mt: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            wordWrap: "break-word",
                            color: theme.custom.primaryDarkColor,
                          }}
                          variant="inter_p_600_16"
                        >
                          Blank report
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  {templateList.map((i: any) => (
                    <Grid item sx={{ cursor: "pointer" }} key={i.id} sm={2}>
                      <TemplateThumbnailComponent
                        onDeleteTemplate={onDeleteTemplate}
                        templateId={i.id}
                        templateObj={i}
                        handleSelect={() => handleTemplateClick(i)}
                        template_icon={
                          i.thumbnail ||
                          i.attributes.attributes.thumbnail ||
                          getDefaultThumbnail(i.attributes.attributes.slug)
                        }
                        templateIsMutable={i.is_mutable}
                        onEditTemplate={() => setEditTemplate(i)}
                        pageLen={Object.keys(i.attributes.pages).length}
                        title={i.attributes.title}
                        style={{ backgroundColor: "#ECECEC" }}
                      />
                    </Grid>
                  ))}
                </Grid>
              </InfiniteScroll>
            )}
          </Grid>
        </Grid>
      </Grid>
      <CreateNewImpactReport
        close={onPopupClose}
        open={openPopup}
        selectedTemplate={selectedTemplate}
      />
    </Grid>
  );
}

export default memo(CreateImpactReportPage);
