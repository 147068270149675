import { createSlice } from "@reduxjs/toolkit";

const selectedDesignElement = createSlice({
  name: "selected-design-element",
  initialState: {
    item: {} as any,
    format: { format: {} as any },
    isTextEditorActive: false,
    isTableEditorActive: false,
    isColorPickerActive: false,
    isSettingEditActive: false,
    tableEvents: {},
    allFiltersInStore: {}
  },
  reducers: {
    setSelectedDesignElement: (state, action) => {
      state.item = action.payload;
    },
    setCurrentFormat: (state, action) => {
      state.format = action.payload.format;
    },
    setIsTextEditorActive: (state, action) => {
      state.isTextEditorActive = action.payload;
    },
    setIsTableEditorActive: (state, action) => {
      state.isTableEditorActive = action.payload;
    },
    setIsColorPickerActive: (state, action) => {
      state.isColorPickerActive = action.payload;
    },
    setSettingEditActive: (state, action) => {
      state.isSettingEditActive = action.payload;
    },
    setAllFiltersInStore:(state, action) =>{
      state.allFiltersInStore = action.payload;
    }
  },
});

export const {
  setSelectedDesignElement,
  setCurrentFormat,
  setIsTextEditorActive,
  setIsTableEditorActive,
  setIsColorPickerActive,
  setSettingEditActive,
  setAllFiltersInStore
} = selectedDesignElement.actions;
export default selectedDesignElement.reducer;
