import { createStateSyncMiddleware } from "redux-state-sync";

const config = {
  whitelist: [
    "connectorStore/setConnectorSyncStatusSingleBySlug",
    "connectorStore/setConnectionInProgress",
    "connectorStore/markConnected",
    "error-handler/showMessage",
    "connectorStore/setMoveToMapping",
    "connectorStore/setExternalReferenceSlug",
  ],
};
export const stateSyncMiddleware = createStateSyncMiddleware(config);
