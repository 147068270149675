import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import Treemap from "highcharts/modules/treemap";
import { returnChartColors, theme, tooltipColors } from "../../theme";
import { labelNumberFormatter  } from "../../utils/dataFormatters";
import { returnFileName } from "../../utils/file-name-helper";
import { ChartProps } from "../../Types/ChartProps";
import { useEffect, useState } from "react";

Treemap(Highcharts);

const returnTreemapFormattedData = (data: any) => {
  if (data.length === 0) {
    return [];
  }
  if (data.length > 0) {
    var point: any = [];
    var temp = JSON.stringify(data[0]);
    var levelCount = (temp.match(/level_[0-9]/g) || []).length;
    var cum_sum: any = {};
    let levelOne: any = {};

    data.forEach((element: any) => {
      levelOne[element["level_1"]] = element["level_1"];
    });

    let mapColors = (levelName: string) => {
      return levelName ? levelOne[levelName] : "";
    };

    let colorArr = returnChartColors(Object.keys(levelOne).length);

    Object.keys(levelOne).forEach((ele: any, index: number) => {
      levelOne[ele] = colorArr[index];
    });

    data.forEach((ele: any) => {
      for (var i = 1; i <= levelCount; i++) {
        let key = ele["level_" + i];
        for (let index = i - 1; index > 0; index--) {
          key = key + "-" + ele["level_" + index];
        }
        ele["level_" + i + "_new"] = key;
      }

      for (var i = 1; i <= levelCount; i++) {
        if (ele["level_" + i]) {
          cum_sum[ele["level_" + i + "_new"]] =
            (cum_sum[ele["level_" + i + "_new"]]
              ? cum_sum[ele["level_" + i + "_new"]]
              : 0) + ele.value;
        }
      }
    });

    data.forEach((ele: any) => {
      for (var i = 1; i <= levelCount; i++) {
        var cursor: any = {};
        if (ele["level_" + i]) {
          cursor = {
            id: ele["level_" + i + "_new"],
            name: ele["level_" + i],
            value: cum_sum[ele["level_" + i + "_new"]],
            color: mapColors(ele["level_" + i]),
          };
        }
        if (i > 1) {
          cursor = {
            ...cursor,
            parent: ele["level_" + (i - 1) + "_new"],
          };
        }
        point.push(cursor);
      }
    });
  }

  return Array.from(new Set(point.map((ele: any) => JSON.stringify(ele)))).map(
    (ele: any) => JSON.parse(ele)
  );
};

const TreeMapChart: React.FC<ChartProps> = (chartData) => {
  let data = chartData.chartData;
  let meta_data = chartData.chartMeta;
  let date_range = chartData.dateRange;
  let data_type = "$";
  const height = chartData.height;
  const width = chartData.width;
  const editorMode = chartData.editorMode;
  const [formattedData, setFormattedData] = useState<any>(null);

  useEffect(() => {
    setFormattedData(
      returnTreemapFormattedData(JSON.parse(JSON.stringify(data)))
    );
  }, [JSON.stringify(data)]);

  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const options = {
    series: [
      {
        type: "treemap",
        layoutAlgorithm: "squarified",
        allowDrillToNode: true,
        animationLimit: 1000,
        dataLabels: {
          enabled: false,
        },
        tooltip: {
          backgroundColor: tooltipColors.bgColor,
          borderColor: tooltipColors.borderColor,
          color: tooltipColors.dataFormatColor,
          padding: 10,
          borderRadius: 5,
          borderWidth: 1,
          pointFormatter: function (this: Highcharts.Point) {
            return `<span style="color: ${theme.custom.primaryDarkColor}">${
              this.name
            }: ${labelNumberFormatter(
              this.options.value || 0,
              data_type
            )} </span>  `;
          },
        },
        levelIsConstant: false,
        levels: [
          {
            level: 1,
            dataLabels: {
              enabled: true,
            },
            borderWidth: 1,
          },
          {
            level: 2,
            colorVariation: {
              key: "brightness",
              to: -0.1,
            },
          },
          {
            level: 3,
            colorVariation: {
              key: "brightness",
              to: -0.1,
            },
          },
          {
            level: 4,
            colorVariation: {
              key: "brightness",
              to: 0.1,
            },
          },
        ],
        data: formattedData,
      },
    ],
    subtitle: {
      text: "",
    },
    title: {
      text: "",
    },
    chart: {
      events: {
        render: function () {
          chartData.kpiRendered(true);
          chartData.chartReference(chartComponentRef);
          if (chartData.customKPI === false) {
            chartComponentRef.current?.chart.reflow();
          }
        },
      },
      animation: false,
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      filename: returnFileName(meta_data?.chart?.title || "", date_range),
    },
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{
        style: {
          width: "100%",
          height: "calc(100% - 1px)",
        },
      }}
      options={
        editorMode
          ? {
              ...options,
              ...{ chart: { ...options.chart, height: height, width: width } },
            }
          : options
      }
      ref={chartComponentRef}
    ></HighchartsReact>
  );
};

export default TreeMapChart;
