import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Grid,
  ImageList,
  ImageListItem,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import LZString from "lz-string";
import React, { useEffect, useRef, useState } from "react";
import Resizer from "react-image-file-resizer";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  getPopularImages,
  getSearchedImages,
  getUploadedImages,
  updateUnsplashAnalytics,
  uploadMedia,
} from "../../../services/impact-report-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import { theme } from "../../../theme";
import AuthorizationErrorHandler from "./AuthorizationErrorHandler";

function ImageLibrary(props: any) {
  let mouseOrigin = useRef({ left: 0, top: 0 });
  let cursorPosition = useRef({ left: 0, top: 0 });
  const [activeTab, setActiveTab] = useState("library");
  const [imageList, setImageList] = useState([] as any);
  const [uploadedImageList, setUploadedImageList] = useState([] as any);
  const [query, setQuery] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [userTyping, setUserTyping] = useState(false);
  const dispatch = useAppDispatch();
  const [mediaUploading, setMediaUploading] = useState(false);
  const [uploadedImageListHasMore, setUploadedImageListHasMore] =
    useState(true);
  let pageRef = useRef({ popular: 1, search: 1, uploaded: 1 });
  const [unsplashImageListHasMore, setUnsplashImageListHasMore] =
    useState(true);
  let defaultImageList = useRef([] as any);
  const [authorized, setAuthorized] = useState(true);
  const addElement = (e: any, element: any, type: string, dnd: boolean = true) => {
    let _element = null;
    if (type === "library") {
      _element = {
        type: "ImageEditor",
        transparency: 1,
        zIndex: 0,
        posX: 100,
        posY: 100,
        rotate: 0,
        attributes: {
          url: element?.urls?.regular,
          type,
          title: element?.description || "Unnamed",
          credits: {
            firstName: element?.user?.first_name,
            lastName: element?.user?.last_name,
            userLink: element?.user?.links?.html,
          },
        },
      };
      updateUnsplashAnalytics(
        LZString.compressToEncodedURIComponent(
          element?.links?.download_location
        )
      );
    } else {
      _element = {
        type: "ImageEditor",
        transparency: 1,
        zIndex: 0,
        posX: 100,
        posY: 100,
        rotate: 0,
        attributes: {
          url: element?.media,
          type,
          title: element?.alt,
        },
      };
    }

    props.addElement(
      _element,
      {
        left: cursorPosition.current.left - mouseOrigin.current.left,
        top: cursorPosition.current.top - mouseOrigin.current.top,
      },
      "drop",
      "",
      dnd
    );
    e.preventDefault();
  };
  const onDrag = (e: any) => {
    //TODO: On DragEnd page coordinates are coming wrong.
    // Monkey patch to force correct it. Heavy ops issue here.
    cursorPosition.current.top = e.pageY;
    cursorPosition.current.left = e.pageX;
  };
  const onDragStart = (e: any) => {
    let _bounds = e.currentTarget.getBoundingClientRect();
    mouseOrigin.current.top = e.clientY - _bounds.top;
    mouseOrigin.current.left = e.clientX - _bounds.left;
  };

  const _setPopularImages = (page: number) => {
    setIsLoading(true);
    getPopularImages(page).then(
      (res) => {
        if (res.status === 403) {
          setAuthorized(false);
          return;
        }
        if (res.data) {
          if (page === 1) {
            setImageList([...res.data]);
          } else {
            setImageList([...imageList, ...res.data]);
          }
          pageRef.current.popular = page + 1;
          setUnsplashImageListHasMore(true);
          setIsLoading(false);
        } else {
          setUnsplashImageListHasMore(false);
        }
      },
      (err) => {
        // do nothing
      }
    );
  };

  const _setSearchedImages = (_query: string, page: number) => {
    setIsLoading(true);
    getSearchedImages(_query, page).then(
      (res) => {
        if (res.data.results) {
          if (page === 1) {
            setImageList([...res.data.results]);
          } else {
            setImageList([...imageList, ...res.data.results]);
          }
          pageRef.current.search = page + 1;
          if (page >= res.data.total_pages) {
            setUnsplashImageListHasMore(false);
          } else {
            setUnsplashImageListHasMore(true);
          }

          setIsLoading(false);
        } else {
          setUnsplashImageListHasMore(false);
        }
      },
      (err) => {
        // do nothing
      }
    );
  };

  useEffect(() => {
    _setPopularImages(1);
    defaultImageList.current = imageList;
    loadUploadedImages();
  }, []);

  useEffect(() => {
    if (userTyping) {
      clearTimeout(userTypingRef.current);
    }
    setUserTyping(true);
    userTypingRef.current = setTimeout(() => {
      setUserTyping(false);
      if (!query) return;
      pageRef.current = { popular: 1, search: 1, uploaded: 1 };
      _setSearchedImages(query, 1);
    }, 1000);
  }, [query]);

  const loadMore = () => {
    if (query) {
      _setSearchedImages(query, pageRef.current.search);
    } else {
      _setPopularImages(pageRef.current.popular);
    }
  };

  const loadUploadedImages = () => {
    getUploadedImages(pageRef.current.uploaded).then((resp) => {
      if (resp.status === 403) {
        setAuthorized(false);
        return;
      }
      if (resp.status === 200) {
        setAuthorized(true);
        if (pageRef.current.uploaded === 1) {
          setUploadedImageList(resp.data.results);
        } else {
          setUploadedImageList([...uploadedImageList, ...resp.data.results]);
        }
        if (resp.data.next) {
          setUploadedImageListHasMore(true);
        } else {
          setUploadedImageListHasMore(false);
        }
      }
    });
  };

  const loadMoreUploadedImages = () => {
    pageRef.current.uploaded += 1;
    loadUploadedImages();
  };
  let userTypingRef = useRef(null as any);
  const emptySearch = () => {
    setQuery("");
    pageRef.current = { popular: 1, search: 1, uploaded: 1 };
    _setPopularImages(1);
  };
  const [progress, setProgress] = useState(0);
  const onFileChange = (event: any) => {
    let file = event.target.files[0];
    function isImage(file: any) {
      return file["type"].split("/")[0] === "image"; //returns true or false
    }
    if (!file || !isImage(file)) {
      dispatch(showMessage({ message: "Please select a valid image file!" }));
      event.target.value = ''; // Reset input value
      return;
    }

    setMediaUploading(true);
    let fileExtension = file.name.split('.').pop().toUpperCase();
    if (!["JPEG", "PNG", "WEBP"].includes(fileExtension)) {
      fileExtension = "JPEG";
    }
    const resizeFile = (fileToResize: any) =>
      new Promise((resolve) => {
        Resizer.imageFileResizer(
          fileToResize,
          240,
          240,
          fileExtension,
          90,
          0,
          (uri: any) => {
            resolve(uri);
          },
          "file"
        );
      });
    resizeFile(file).then((thumbnail: any) => {
      const formData = new FormData();
      formData.append("file", file, file.name);
      formData.append("media_type", "IMAGE");
      formData.append("media_sub_type", "UPLOAD");
      formData.append("thumbnail", thumbnail, file.name);
      uploadMedia(formData, {
        onUploadProgress: (data: any) => {
          //Set the progress value to show the progress bar
          setProgress(Math.round((100 * data.loaded) / data.total));
        },
      }).then((resp) => {
        if (resp && resp.status === 201) {
          // load fresh images from beginning
          pageRef.current.uploaded = 1;
          loadUploadedImages();
          dispatch(
            showMessage({
              message: "Successfully uploaded image",
              variant: "success",
            })
          );
        } else {
          dispatch(
            showMessage({
              message: "Failed to upload, please ensure file size is < 10 MB",
              variant: "error",
            })
          );
        }
      }).finally(() => {
        setMediaUploading(false);
        event.target.value = ''; // Reset input value
      });
    });
  }

  return (
    <>
      <Box mb={1}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={activeTab}
            onChange={(e, v) => setActiveTab(v)}
            variant="fullWidth"
            aria-label="image library"
          >
            <Tab value="library" label="Library" />
            <Tab value="uploaded" label="Uploaded" />
          </Tabs>
        </Box>
        {activeTab === "library" && (
          <>
            <Box mt={3} mb={1}>
              <TextField
                label="Search Images"
                id="search-image"
                value={query}
                fullWidth
                onChange={(e: any) => setQuery(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="start">
                      <CloseIcon onClick={emptySearch} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            {authorized && (
              <InfiniteScroll
                dataLength={imageList.length}
                next={loadMore}
                hasMore={unsplashImageListHasMore}
                loader={
                  <h5 style={{ color: "grey", textAlign: "center" }}>
                    Loading...
                  </h5>
                }
                scrollableTarget="scrollableDiv"
                endMessage={
                  <Typography
                    variant="inter_500_12"
                    style={{ color: "grey", textAlign: "center" }}
                  >
                    No more images to load
                  </Typography>
                }
              >
                <ImageList variant="standard" cols={2} gap={12}>
                  {imageList.map((item: any) => (
                    <Tooltip
                      key={item.id}
                      title={item.description || "Unnamed"}
                      placement="top"
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -28],
                            },
                          },
                        ],
                      }}
                    >
                      <ImageListItem>
                        <img
                          draggable={true}
                          onDrag={onDrag}
                          onDragStart={onDragStart}
                          onDragEnd={(e) => addElement(e, item, "library")}
                          onClick={(e) => addElement(e, item, "library", false)}
                          src={item.urls.thumb}
                          alt={item.description}
                          loading="lazy"
                          style={{
                            objectFit: "contain",
                          }}
                        />
                      </ImageListItem>
                    </Tooltip>
                  ))}
                </ImageList>
              </InfiniteScroll>
            )}
          </>
        )}
        {activeTab === "uploaded" && (
          <>
            <Grid container py={2}>
              <LoadingButton
                color="primary"
                component="label"
                fullWidth
                variant="outlined"
                loading={mediaUploading}
                loadingIndicator={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <CircularProgress
                      color="inherit"
                      size={26}
                      variant={
                        progress === 100 ? "indeterminate" : "determinate"
                      }
                      value={progress}
                    />
                    <Typography position="absolute" variant="inter_500_10">
                      {progress}
                    </Typography>
                  </Box>
                }
              >
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={(e) => onFileChange(e)}
                />
                Upload image
              </LoadingButton>
              <Box sx={{ textAlign: "center", width: "100%" }}>
                <Typography
                  variant="inter_400_10"
                  color="text.secondary"
                >
                  Max file size 10MB
                </Typography>
              </Box>
            </Grid>
            {authorized && (
              <InfiniteScroll
                dataLength={uploadedImageList.length}
                next={loadMoreUploadedImages}
                hasMore={uploadedImageListHasMore}
                loader={
                  <h5 style={{ color: "grey", textAlign: "center" }}>
                    Loading...
                  </h5>
                }
                scrollableTarget="scrollableDiv"
                endMessage={
                  <Typography
                    variant="inter_500_12"
                    style={{ color: "grey", textAlign: "center" }}
                  >
                    No more images to load
                  </Typography>
                }
              >
                <ImageList variant="standard" cols={2} gap={12}>
                  {uploadedImageList.map((item: any) => (
                    <Tooltip
                      key={item.id}
                      title={item.alt}
                      placement="top"
                      PopperProps={{
                        modifiers: [
                          {
                            name: "offset",
                            options: {
                              offset: [0, -28],
                            },
                          },
                        ],
                      }}
                    >
                      <ImageListItem
                        sx={{
                          padding: 1,
                          border: `1px solid ${theme.custom.menuDividerColor}`,
                          borderRadius: "6px",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          draggable={true}
                          onDrag={onDrag}
                          onDragStart={onDragStart}
                          onDragEnd={(e) => addElement(e, item, "uploaded")}
                          onClick={(e) => addElement(e, item, "uploaded", false)}
                          src={item.thumbnail}
                          alt={item.alt}
                          loading="lazy"
                          style={{
                            objectFit: "contain",
                          }}
                        />
                      </ImageListItem>
                    </Tooltip>
                  ))}
                </ImageList>
              </InfiniteScroll>
            )}
          </>
        )}
      </Box>
      {!authorized && (
        <AuthorizationErrorHandler
          errorMessage="You don’t have access to see the list of Images. Please reach out to
        admin to get access"
        />
      )}
    </>
  );
}

export default React.memo(ImageLibrary);
