import {environment} from "../environments/environment-dev";

export function getElementBounds(el: any) {
  var x = 0,
    y = 0,
    bounds = el.getBoundingClientRect();

  while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
    x += el.offsetLeft;
    y += el.offsetTop;
    el = el.offsetParent;
  }

  return {
    top: y,
    left: x,
    right: x + bounds.width,
    bottom: y + bounds.height,
  };
}

export function getClipStylesFromBounds(
  bounds: any,
  factor = 1,
  type = "bounds"
) {
  if (!bounds) return type === "bounds" ? [] : "inset";

  let numberRegEx = new RegExp(/[^0-9.-]/, "ig");
  let factoredFormattedBounds = [
    Math.max(Number(String(bounds[0]).replace(numberRegEx, "")), 0) * factor,
    Math.max(Number(String(bounds[1]).replace(numberRegEx, "")), 0) * factor,
    Math.max(Number(String(bounds[2]).replace(numberRegEx, "")), 0) * factor,
    Math.max(Number(String(bounds[3]).replace(numberRegEx, "")), 0) * factor,
  ];

  return type === "bounds"
    ? factoredFormattedBounds
    : "inset(" +
        factoredFormattedBounds[0] +
        "% " +
        factoredFormattedBounds[1] +
        "% " +
        factoredFormattedBounds[2] +
        "% " +
        factoredFormattedBounds[3] +
        "%)";
}

export function getRectExtremeBounds(
  x1: number,
  y1: number,
  h: number,
  w: number,
  rotation: number
) {
  let cosD = (degree: number) => {
    return Math.cos((degree * Math.PI) / 180);
  };
  let r = Math.sqrt(Math.pow(h / 2, 2) + Math.pow(w / 2, 2));
  // Note x1, y1 are coordinates at 0 rotation, so first we need to
  // adjust it by taking angle from origin on top origin position
  let oa = 180 - (rotation + (Math.atan(h / w) * 180) / Math.PI);
  let x = x1 + w / 2 + r * cosD(oa);
  let y = y1 + h / 2 - r * cosD(90 - oa);

  let xBounds = [
      x,
      x + w * cosD(rotation),
      x + w * cosD(rotation) - h * cosD(90 - rotation),
      x - h * cosD(90 - rotation),
    ],
    yBounds = [
      y,
      y + w * cosD(90 - rotation),
      y + w * cosD(90 - rotation) + h * cosD(rotation),
      y + h * cosD(rotation),
    ];

  return {
    x: xBounds.includes(NaN)
      ? []
      : [
          Math.min(...xBounds),
          (Math.min(...xBounds) + Math.max(...xBounds)) / 2,
          Math.max(...xBounds),
        ],
    y: yBounds.includes(NaN)
      ? []
      : [
          Math.min(...yBounds),
          (Math.min(...yBounds) + Math.max(...yBounds)) / 2,
          Math.max(...yBounds),
        ],
  };
}

export function getRectOverlap(rectA: any, rectB: any) {
  let xOverlap = Math.max(
    0,
    Math.min(rectA.x + rectA.width, rectB.x + rectB.width) -
      Math.max(rectA.x, rectB.x)
  );
  let yOverlap = Math.max(
    0,
    Math.min(rectA.y + rectA.height, rectB.y + rectB.height) -
      Math.max(rectA.y, rectB.y)
  );
  let overlapArea = xOverlap * yOverlap;
  let areaA = rectA.width * rectA.height;
  let areaB = rectB.width * rectB.height;
  let percentOverlap = (overlapArea / (areaA + areaB - overlapArea)) * 100;

  return percentOverlap;
}

export function getS3Image(uri: string) {
  return `${environment.s3AssetUrl}/${uri}`
}
