import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Grid,
  ListItemAvatar,
  TextField,
  Typography,
} from "@mui/material";
import { compressToEncodedURIComponent } from "lz-string";
import React, { useState } from "react";
import { theme } from "../../theme";

type Props = {};

const ConnectDonorPerfect = (props: Props) => {
  const font = theme.typography.subtitle1.fontFamily;

  const INIT_STATE = {
    username: "",
    password: "",
  };
  const [userDetails, setUserDetails] = useState(INIT_STATE);

  const userNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserDetails((prev) => {
      return { ...prev, username: event.target.value };
    });
  };

  const passwordHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserDetails((prev) => {
      return { ...prev, password: event.target.value };
    });
  };

  const submitHandler = (event: React.FormEvent<EventTarget>) => {
    event.preventDefault();

    let payload = {
      username: userDetails.username,
      password: userDetails.password,
    };
    let encrypted_payload = compressToEncodedURIComponent(
      JSON.stringify(payload)
    );
    window.location.assign(
      `/connectors/donorperfect/connector-auth-callback?encrypted_data=${encrypted_payload}`
    );
  };
  return (
    <Grid
      container
      sx={{ minHeight: "600px" }}
      justifyContent="center"
      direction="column"
      px={7}
    >
      <Grid container justifyContent="center" p={5}>
        <ListItemAvatar>
          <Avatar src="https://cdn.donorperfect.com/images/20170804145828/dp-logo-white.png"></Avatar>
        </ListItemAvatar>
      </Grid>
      <Grid item>
        <Typography
          variant="inter_p_400_16"
          color={theme.custom.primaryGreyColor}
        >
          Enter credentials for DonorPerfect
        </Typography>
      </Grid>
      <Grid container>
        <Grid item md={12}>
          <form onSubmit={submitHandler}>
            <TextField
              id="username"
              required
              name="username"
              value={userDetails.username}
              type="text"
              sx={{ my: 3 }}
              variant="outlined"
              placeholder="Username"
              inputProps={{
                maxLength: 30,
                style: {
                  fontFamily: font,
                  fontSize: "14px",
                  color: theme.custom.placeholderColor,
                },
              }}
              fullWidth
              onChange={userNameHandler}
            />
            <TextField
              id="password"
              name="password"
              required
              value={userDetails.password}
              type="password"
              sx={{ mb: 1 }}
              fullWidth
              variant="outlined"
              placeholder="Password"
              inputProps={{
                maxLength: 30,
                style: {
                  fontSize: "14px",
                  fontFamily: font,
                  color: theme.custom.placeholderColor,
                },
              }}
              onChange={passwordHandler}
            />

            <LoadingButton
              sx={{ width: "100%", mt: 2, mb: 2, height: 50 }}
              variant="contained"
              type="submit"
            >
              <Typography variant="poppins_p_600_14">Authorize</Typography>
            </LoadingButton>
          </form>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConnectDonorPerfect;
