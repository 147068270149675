import { Typography } from "@mui/material";
import React, { memo } from "react";
import { theme } from "../../../theme";
import { numberFormatter } from "../../../utils/dataFormatters";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import { isEmpty } from "lodash";

type Props = {
  row: any;
  cell: any;
  cellValue: any;
  onCellClick: Function;
  isReport: boolean;
};

const CustomRichTextCell = (props: Props) => {
  const { row, cell, onCellClick, cellValue, isReport } = props;
  const drillDownMeta = cellValue?.["drilldown_meta"] || {};
  const colorRenderHelper = (num: number, isCreditAccount: boolean) => {
    return (num >= 0 && isCreditAccount) || (num <= 0 && !isCreditAccount)
      ? "green"
      : "red";
  };

  const isDrilldownEnabled = (key: string) => {
    return !isEmpty(drillDownMeta[key]) && !isReport;
  };

  return (
    <div className="richtext-cell-alignment">
      <>
        <div style={{ display: "flex", alignItems: "center", marginBottom: "5px" }}>
          <Typography
            variant="inter_400_10"
            onClick={() => {
              if (isDrilldownEnabled("primary_value")) {
                onCellClick(drillDownMeta["primary_value"]);
              }
            }}
            sx={{
              color: theme.custom.primaryDarkColor,
              textDecoration: "none",
              cursor: isDrilldownEnabled("primary_value") ? "pointer" : "auto",
              "&:hover": {
                textDecoration: isDrilldownEnabled("primary_value")
                  ? "underline"
                  : "none",
              },
            }}
          >
            {numberFormatter(
              +cellValue?.["value"]?.["primary_value"] || 0,
              "$"
            )}
          </Typography>
          {+cellValue?.["value"]?.["secondary_value"] >= 0 ? (
            <KeyboardDoubleArrowUpIcon
              sx={{
                color: colorRenderHelper(
                  cellValue?.["value"]?.["secondary_value"] || 0,
                  cellValue?.["value"]?.["quaternary_value"] || false
                ),
                height: 15,
              }}
            />
          ) : (
            <KeyboardDoubleArrowDownIcon
              sx={{
                color: colorRenderHelper(
                  +cellValue?.["value"]?.["secondary_value"] || 0,
                  cellValue?.["value"]?.["quaternary_value"] || false
                ),
                height: 15,
              }}
            />
          )}
          <Typography
            variant="inter_400_10"
            onClick={() => {
              if (isDrilldownEnabled("secondary_value")) {
                onCellClick(drillDownMeta["secondary_value"]);
              }
            }}
            sx={{
              color: colorRenderHelper(
                +cellValue?.["value"]?.["secondary_value"] || 0,
                cellValue?.["value"]?.["quaternary_value"] || false
              ),
              textDecoration: "none",
              cursor: isDrilldownEnabled("secondary_value")
                ? "pointer"
                : "auto",
              "&:hover": {
                textDecoration: isDrilldownEnabled("secondary_value")
                  ? "underline"
                  : "none",
              },
            }}
          >
            {numberFormatter(
              Math.abs(+cellValue?.["value"]?.["secondary_value"] || 0),
              "%"
            )}
          </Typography>
        </div>
        <div style={{ display: "flex" }}>
          <Typography
            variant="inter_400_10"
            sx={{
              color: theme.custom.KPIMoleculeBorderColor,
              textDecoration: "none",
              cursor: isDrilldownEnabled("tertiary_value") ? "pointer" : "auto",
              "&:hover": {
                textDecoration: isDrilldownEnabled("tertiary_value")
                  ? "underline"
                  : "none",
              },
            }}
          >
            {cellValue?.["value"]?.["formatted_tertiary_value"]
              ? cellValue?.["value"]?.["formatted_tertiary_value"]
              : numberFormatter(
                  +cellValue?.["value"]?.["tertiary_value"] || 0,
                  "$"
                )}
          </Typography>
        </div>
      </>
    </div>
  );
};

export default memo(CustomRichTextCell);
