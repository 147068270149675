import React, { useRef, useState } from "react";
import InfoIcon from "../../../assets/info.png";
import { Box, Popover } from "@mui/material";
import { fontInter } from "../../../theme";
type Props = {
  key: string;
  snippet: string;
};

const CustomPopover = (props: Props) => {
  const { key, snippet } = props;
  const popoverAnchor = useRef(null);
  const [openedPopover, setOpenedPopover] = useState(false);

  const popoverEnter = () => {
    setOpenedPopover(true);
  };

  const popoverLeave = () => {
    setOpenedPopover(false);
  };
  return (
    <div>
      {" "}
      <div>
        <img
          width={`200px`}
          alt="img"
          style={{
            verticalAlign: "middle",
            cursor: "pointer",
            height: "2ex",
            width: "2ex",
            maxHeight: "24px",
            maxWidth: "24px",
          }}
          className={`kpi_info_icon_${key}`}
          src={InfoIcon}
          ref={popoverAnchor}
          onMouseEnter={popoverEnter}
          onMouseLeave={popoverLeave}
        />
        <Popover
          id={key}
          open={openedPopover}
          anchorEl={popoverAnchor.current}
          PaperProps={{
            onMouseEnter: popoverEnter,
            onMouseLeave: popoverLeave,
          }}
        >
          <Box
            p={3}
            sx={{
              maxHeight: 350,
              width: 400,
              position: "relative",
              borderRadius: "6px",
              fontFamily: fontInter,
            }}
            dangerouslySetInnerHTML={{ __html: snippet }}
          ></Box>
        </Popover>
      </div>
    </div>
  );
};

export default CustomPopover;
