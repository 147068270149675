import {
  Typography,
  Box,
  Grid,
  Slider,
  Divider,
  Button,
  Tooltip,
  ImageList,
  ImageListItem,
  Popover,
  Stack,
  IconButton,
} from "@mui/material";
import { Icon } from "@iconify/react";
import { theme } from "../../../theme";
import React, { useEffect, useRef, useState } from "react";
import {
  getIcons,
  shapesLibrary,
  interactiveElementsLibrary,
  bgLibrary,
} from "../../../services/impact-report-service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconsLibrary from "./IconsLibrary";
import ShapesLibrary from "./ShapesLibrary";
import AuthorizationErrorHandler from "./AuthorizationErrorHandler";

function ElementsLibrary(props: any) {
  const { addElement, updatePageBg } = props;
  let mouseOrigin = useRef({ left: 0, top: 0 });
  let cursorPosition = useRef({ left: 0, top: 0 });

  const [shapesData, setShapesData] = useState<any>([]);
  const [bgData, setBgData] = useState<any>([]);
  const [interactiveElementsData, setInteractiveElementsData] = useState<any>(
    []
  );
  const [allIcons, setAllIcons] = useState([] as any);
  const [shapeAuthorized, setShapeAuthorized] = useState(true);
  const [bgAuthorized, setBgAuthorized] = useState(true);
  const [iconAuthorized, setIconAuthorized] = useState(true);
  const [interactiveElementsAuthorized, setInteractiveElementsAuthorized] =
    useState(true);
  const [elementsLibraryType, setElementsLibraryType] = useState("");

  useEffect(() => {
    shapesLibrary().then((res: any) => {
      if (res.status === 403) {
        setShapeAuthorized(false);
        return;
      }
      if (res.status === 200) {
        setShapesData(res.data.results);
      }
    });
    getIcons("all").then((res: any) => {
      if (res.status === 403) {
        setIconAuthorized(false);
        return;
      }
      setAllIcons([]);
      if (res.status === 200) {
        if (res.data && res.data.icons && res.data.icons.length > 0) {
          let formatted = res.data.icons.map((icon: string) => {
            let temp = icon.split(":");
            return {
              prefix: temp[0],
              iconName: temp[1],
            };
          });
          setAllIcons(formatted);
        }
      }
    });
    interactiveElementsLibrary().then((res: any) => {
      if (res.status === 403) {
        setInteractiveElementsAuthorized(false);
        return;
      }
      if (res.status === 200) {
        setInteractiveElementsData(res.data.results);
      }
    });
    bgLibrary().then((res: any) => {
      if (res.status === 403) {
        setBgAuthorized(false);
        return;
      }
      if (res.status === 200) {
        setBgData(res.data.results);
      }
    });
  }, []);

  const pickElementsLibraryType = () => {
    switch (elementsLibraryType) {
      case "Shapes":
        return (
          <>
            <ShapesLibrary addElement={addElement} />
          </>
        );
      case "Icons":
        return (
          <>
            <IconsLibrary
              addElement={addElement}
              clearClipBoard={props.clearClipBoard}
            />
          </>
        );
      default:
        break;
    }
  };

  const addInteractiveElement = (e: any, element: any, dnd: boolean = true) => {
    const _element = {
      type: "InteractiveElementEditor",
      transparency: 1,
      zIndex: 0,
      rotate: 0,
      height: element.attributes.height,
      width: element.attributes.width,
      attributes: {
        fill: element.attributes.fill,
        height: element.attributes.height,
        text: element.attributes.text,
        width: element.attributes.width,
        linkurl: element.linkurl,
        fontSize: element.attributes.fontSize,
        strokeColor: element.attributes.strokeColor,
      },
    };

    addElement(
      _element,
      {
        left: e.pageX || 0,
        top: e.pageY || 0,
      },
      "drop",
      "",
      dnd
    );
    e.preventDefault();
  };

  const addShapeElem = (e: any, element: any, dnd: boolean = true) => {
    const _element = {
      type: "ShapesEditor",
      transparency: 1,
      zIndex: 0,
      rotate: 0,
      height: element.attributes.height,
      width: element.attributes.width,
      attributes: {
        fill: element.attributes.fill,
        height: element.attributes.height,
        title: element.attributes.title,
        width: element.attributes.width,
        viewBoxHeight: element.attributes.viewBoxHeight,
        viewBoxWidth: element.attributes.viewBoxWidth,
        strokeWidth: element.attributes.strokeWidth,
        strokeColor: element.attributes.strokeColor,
        path: element?.attributes?.path,
        strokeDashArray: element?.attributes?.strokeDashArray,
      },
    };

    addElement(
      _element,
      {
        left: e.pageX || 0,
        top: e.pageY || 0,
      },
      "drop",
      "",
      dnd
    );
    e.preventDefault();
  };

  const addBackgroundElem = (e: any, element: any, dnd: boolean = true) => {
    const _element = {
      attributes: {
        backgroundImage: `url(${element?.attributes?.imageUrl})`,
      },
    };
    updatePageBg(
      _element,
      {
        left: e.pageX || 0,
        top: e.pageY || 0,
      },
      "drop",
      "",
      dnd
    );
    e.preventDefault();
  };

  const addIconElem = (e: any, element: any, dnd: boolean = true) => {
    let _element = null;
    _element = {
      type: "IconEditor",
      transparency: 1,
      zIndex: 0,
      posX: 100,
      posY: 100,
      rotate: 0,
      height: 50,
      width: 50,
      attributes: {
        prefix: element?.prefix,
        title: element?.iconName,
        height: 50,
        width: 50,
        color: theme.custom.primaryDarkColor,
      },
    };

    addElement(
      _element,
      {
        left: cursorPosition.current.left,
        top: cursorPosition.current.top,
      },
      "drop",
      "",
      dnd
    );
    e.preventDefault();
  };

  const onDrag = (e: any) => {
    //TODO: On DragEnd page coordinates are coming wrong.
    // Monkey patch to force correct it. Heavy ops issue here.
    cursorPosition.current.top = e.pageY;
    cursorPosition.current.left = e.pageX;
  };

  const onDragStart = (e: any) => {
    let _bounds = e.currentTarget.getBoundingClientRect();
    mouseOrigin.current.top = e.clientY - _bounds.top;
    mouseOrigin.current.left = e.clientX - _bounds.left;
  };

  const seeAllEvent = (type: string) => {
    setElementsLibraryType(type);
  };

  return (
    <>
      <Box pt={2}>
        {elementsLibraryType ? (
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item alignItems={"center"} mb={1}>
              <Stack direction="row" alignItems="center">
                <IconButton onClick={() => seeAllEvent("")} aria-label="back">
                  <ArrowBackIcon
                    sx={{ color: theme.custom.primaryGreyColor }}
                  />
                </IconButton>
                <div>
                  <Tooltip title={elementsLibraryType}>
                    <Typography
                      sx={{
                        color: theme.custom.primaryDarkColor,
                        maxWidth: 150,
                      }}
                      variant="inter_p_400_16"
                      noWrap
                    >
                      {elementsLibraryType}
                    </Typography>
                  </Tooltip>
                </div>
              </Stack>
            </Grid>
            <Box>{pickElementsLibraryType()}</Box>
          </Grid>
        ) : (
          <>
            {shapeAuthorized && (
              <Grid container mt={2} alignItems="center">
                <Grid item md={10}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        variant="inter_p_600_16"
                        sx={{ color: theme.custom.primaryDarkColor }}
                      >
                        Shapes
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={2}>
                  <Button variant="text" onClick={() => seeAllEvent("Shapes")}>
                    <Typography
                      variant="inter_500_12"
                      sx={{ color: theme.custom.primaryDarkColor }}
                    >
                      See all
                    </Typography>
                  </Button>
                </Grid>
                <Grid item md={12}>
                  <ImageList variant="standard" cols={4} gap={12}>
                    {shapesData &&
                      shapesData.length > 0 &&
                      shapesData.slice(0, 12).map((ele: any, index: number) => {
                        return (
                          <ImageListItem
                            sx={{
                              height: 30,
                              width: 50,
                            }}
                            key={index}
                          >
                            <img
                              style={{ objectFit: "contain" }}
                              draggable={true}
                              onDrag={onDrag}
                              onDragStart={onDragStart}
                              onDragEnd={(e) => addShapeElem(e, ele)}
                              onClick={(e) => addShapeElem(e, ele, false)}
                              src={ele?.attributes?.imageUrl}
                              alt={ele?.attributes?.shape}
                              loading="lazy"
                            />
                          </ImageListItem>
                        );
                      })}
                  </ImageList>
                </Grid>
              </Grid>
            )}
            {!shapeAuthorized && (
              <AuthorizationErrorHandler
                errorMessage="You don’t have access to see the list of shapes. Please reach out to
        admin to get access"
              />
            )}
            {iconAuthorized && (
              <Grid container mt={2} alignItems="center">
                <Grid item md={10}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        variant="inter_p_600_16"
                        sx={{ color: theme.custom.primaryDarkColor }}
                      >
                        Icons
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={2}>
                  <Button variant="text" onClick={() => seeAllEvent("Icons")}>
                    <Typography
                      variant="inter_500_12"
                      sx={{ color: theme.custom.primaryDarkColor }}
                    >
                      See all
                    </Typography>
                  </Button>
                </Grid>
                <Grid item md={12}>
                  <ImageList variant="standard" cols={5} gap={12}>
                    {allIcons &&
                      allIcons.length > 0 &&
                      allIcons
                        .slice(0, 15)
                        .map((iconElement: any, index: number) => {
                          return (
                            <Tooltip
                              key={iconElement.iconName + "-" + index}
                              title={iconElement.iconName}
                              placement="top"
                              PopperProps={{
                                modifiers: [
                                  {
                                    name: "offset",
                                    options: {
                                      offset: [0, -10],
                                    },
                                  },
                                ],
                              }}
                            >
                              <ImageListItem>
                                <div
                                  style={{ cursor: "pointer" }}
                                  draggable={true}
                                  onDrag={onDrag}
                                  onDragStart={onDragStart}
                                  onDragEnd={(e) => addIconElem(e, iconElement)}
                                  onClick={(e) =>
                                    addIconElem(e, iconElement, false)
                                  }
                                >
                                  <Icon
                                    icon={`${iconElement.prefix}:${iconElement.iconName}`}
                                    height="30px"
                                    width={"30px"}
                                  ></Icon>
                                </div>
                              </ImageListItem>
                            </Tooltip>
                          );
                        })}
                  </ImageList>
                </Grid>
              </Grid>
            )}
            {!iconAuthorized && (
              <AuthorizationErrorHandler
                errorMessage="You don’t have access to see the list of icons. Please reach out to
        admin to get access"
              />
            )}
            <Grid container mt={2} alignItems="center">
              <Grid item md={10}>
                <Grid
                  container
                  justifyContent={"space-between"}
                  alignItems="center"
                >
                  <Grid item>
                    <Typography
                      variant="inter_p_600_16"
                      sx={{ color: theme.custom.primaryDarkColor }}
                    >
                      Background
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={12}>
                <ImageList variant="standard" cols={3} gap={12}>
                  {bgData &&
                    bgData.length > 0 &&
                    bgData.map((ele: any, index: number) => {
                      return (
                        <ImageListItem
                          sx={{
                            height: 30,
                            width: 50,
                          }}
                        >
                          <img
                            style={{ objectFit: "contain" }}
                            alt="bg-img"
                            draggable={true}
                            onDrag={onDrag}
                            onDragStart={onDragStart}
                            onDragEnd={(e) => addBackgroundElem(e, ele)}
                            onClick={(e) => addBackgroundElem(e, ele, false)}
                            src={ele?.attributes?.imageUrl}
                            loading="lazy"
                          />
                        </ImageListItem>
                      );
                    })}
                </ImageList>
              </Grid>
            </Grid>

            {interactiveElementsAuthorized && (
              <Grid container mt={2} alignItems="center">
                <Grid item md={12}>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography
                        variant="inter_p_600_16"
                        sx={{ color: theme.custom.primaryDarkColor }}
                      >
                        Interactive Element
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={12} pt={2}>
                  {interactiveElementsData &&
                    interactiveElementsData.length > 0 &&
                    interactiveElementsData.map((ele: any, index: number) => {
                      return (
                        <div
                          style={{ cursor: "pointer", width: "fit-content" }}
                          draggable={true}
                          onDrag={onDrag}
                          onDragStart={onDragStart}
                          onDragEnd={(e) => addInteractiveElement(e, ele)}
                          onClick={(e) => addInteractiveElement(e, ele, false)}
                        >
                          <Button
                            disabled
                            sx={{
                              "&.Mui-disabled": {
                                background: theme.custom.yellowButtonColor,
                                color: theme.custom.blackColor,
                              },
                            }}
                            variant="contained"
                          >
                            Button
                          </Button>
                        </div>
                      );
                    })}
                </Grid>
              </Grid>
            )}
            {!interactiveElementsAuthorized && (
              <AuthorizationErrorHandler
                errorMessage="You don’t have access to see the list of Interactive Elements. Please reach out to
        admin to get access"
              />
            )}
          </>
        )}
      </Box>
    </>
  );
}

export default React.memo(ElementsLibrary);
