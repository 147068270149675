import { Box, Typography, styled } from "@mui/material";

import React from "react";
import { theme } from "../../theme";
import moment from "moment";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { dateFormatter } from "../../utils/dataFormatters";
type Props = {
  start_date: string;
  end_date: string;
  time_elapsed: number;
  value: number;
  unit: string;
  showLabels?: boolean;
  extraProps?: any;
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 6,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.custom.menuDividerColor,
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 0,
    backgroundColor: theme.custom.accentBgColor,
  },
}));

const LinearProgressWithLabels = (props: Props) => {
  const { start_date, end_date, time_elapsed, value, unit, extraProps } = props;
  const showLabels = props.showLabels === undefined ? true : props.showLabels;
  return (
    <Box sx={{ position: "relative" }} display="flex" alignItems="center">
      {showLabels && (
        <Box sx={{ position: "absolute", top: 33, left: 0 }} minWidth={35}>
          <Typography
            variant="inter_500_10"
            color={theme.custom.primaryGreyColor}
          >
            {dateFormatter(start_date, "DD MMM YY")}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          position: "absolute",
          top: extraProps?.style?.top ? extraProps?.style?.top : "45%",
          left: extraProps?.style?.left ? extraProps?.style?.left : "35%",
          zIndex: 1000,
        }}
        minWidth={35}
      >
        <Typography
          variant="inter_500_10"
          color={theme.custom.primaryDarkColor}
        >
          {`${time_elapsed} ${unit}`}
        </Typography>
      </Box>
      <Box
        width="100%"
        sx={{
          minWidth: extraProps?.style?.minWidth || 150,
        }}
        my={1}
      >
        <BorderLinearProgress
          sx={{ height: 20, borderRadius: 10 }}
          variant="determinate"
          value={Math.min(value, 100)}
        />
      </Box>
      {showLabels && (
        <Box sx={{ position: "absolute", top: 33, right: 10 }} minWidth={35}>
          <Typography
            variant="inter_500_10"
            color={theme.custom.primaryGreyColor}
          >
            {dateFormatter(end_date, "DD MMM YY")}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default LinearProgressWithLabels;
