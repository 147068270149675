import {theme} from "../../../theme";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@mui/material";
import {CustomKPIObject} from "../../../Types/CustomKPIObject";
import React from "react";
import {dateFormatter} from "../../../utils/dataFormatters";
import LinkIcon from "../../../assets/linkto.png";
import EditIcon from "../../../assets/pencil.png";
import DeleteIcon from "../../../assets/deleteIcon.png";

interface Props {
  tableData: any
  navigateToBoard: Function
  onEditKPI: Function
  onDeleteKPI: Function
}

const CustomKpiListView = (props: Props) => {
  const {tableData, navigateToBoard, onDeleteKPI, onEditKPI} = props

  return <TableContainer
    sx={{
      position: "relative",
      border: `1px solid ${theme.dataTableColors.borderColor}`,
      borderRadius: "6px",
    }}
  >
    <Table>
      <TableHead
        sx={{background: theme.dataTableColors.headerBgColor}}
      >
        <TableCell>
          <Typography variant="inter_p_600_16">KPI Name</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="inter_p_600_16">Connectors</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="inter_p_600_16">Chart type</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="inter_p_600_16">
            Board added
          </Typography>
        </TableCell>
        <TableCell>
          <Typography variant="inter_p_600_16">Created by</Typography>
        </TableCell>
        <TableCell>
          <Typography variant="inter_p_600_16">Created on</Typography>
        </TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
      </TableHead>
      <TableBody>
        {tableData && tableData.length > 0 && (
          tableData.map((ele: CustomKPIObject, index: number) => {
            return (
              <React.Fragment key={index}>
                <TableRow key={index}>
                  <TableCell>
                    <Typography variant="inter_400_14">
                      {ele.kpi_title}
                    </Typography>{" "}
                  </TableCell>
                  <TableCell>
                    <div style={{display: "inline"}}>
                      {ele.connector.map((ele) => {
                        return (
                          <img
                            src={ele.logo}
                            height="20"
                            width="15"
                            alt="Connector logo"
                          />
                        );
                      })}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Typography variant="inter_400_14">
                      {ele.chart_type}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="inter_400_14">
                      {ele.board}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="inter_400_14">
                      {ele.created_by}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="inter_400_14">
                      {dateFormatter(ele.created_at)}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    <img
                      onClick={() =>
                        navigateToBoard(ele.board_slug, ele.kpi_slug)
                      }
                      style={{cursor: "pointer"}}
                      height={20}
                      width={20}
                      alt="Link"
                      src={LinkIcon}
                    />
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        ele.is_editable
                          ? "Edit"
                          : "You don't have permission to edit this KPI"
                      }
                    >
                      <img
                        onClick={() =>
                          onEditKPI(ele.kpi_slug, ele.is_editable)
                        }
                        style={{
                          cursor: "pointer",
                          opacity: ele.is_editable ? 1 : 0.5,
                        }}
                        height={20}
                        width={20}
                        alt="edit"
                        src={EditIcon}
                      />
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip
                      title={
                        ele.is_deletable
                          ? "Delete"
                          : "You don't have access to delete this KPI"
                      }
                    >
                      <img
                        onClick={() =>
                          onDeleteKPI(ele.uuid, ele.is_deletable)
                        }
                        style={{
                          cursor: "pointer",
                          opacity: ele.is_deletable ? 1 : 0.5,
                        }}
                        height={20}
                        width={20}
                        alt="delete"
                        src={DeleteIcon}
                      />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            );
          })
        )}
      </TableBody>
    </Table>
  </TableContainer>
}

export default CustomKpiListView;