import {
  Button,
  Dialog,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { useState } from "react";
import SavingIcon from "../../assets/saving.gif";
import { ConnectorMappingConfig } from "../../services/user-mapping-service";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { updateMapping } from "../../store/user-mapping-store";
import { createMappingEntry } from "../../utils/user-mapping-utils";
import QuickbooksUserMappingGroupEditor from "../complexes/QuickbooksUserMappingGroupEditor";

interface Props {
  UMData: ConnectorMappingConfig;
}

const QuickbooksSystemDefinedMappingListItem = (props: Props) => {
  const dispatch = useAppDispatch();
  const selectedMapping = useAppSelector((state) => {
    return state.userMapping.enrichedUMDatawithMeta.find(
      (e) => e.id === props.UMData.id
    );
  });
  const selectedValue =
    selectedMapping?.user_mapping.mapping?.conditions[0]?.value_slug || "";
  const isGroup =
    selectedMapping?.user_mapping.mapping?.mapping_type === "group";
  const attrOrDim =
    selectedMapping?.user_mapping.mapping?.conditions[0]?.value_slug_type ||
    "attr";

  const handleChange = (key: number, event: SelectChangeEvent) => {
    if (isGroup) {
      if (
        !window.confirm(
          `This action will delete all existing groups created for ${props.UMData.title}. Do you wish to continue?`
        )
      ) {
        return;
      }
    }
    const mapping = createMappingEntry(
      event.target.value === "group" ? "group" : "direct",
      attrOrDim,
      event.target.value
    );

    dispatch(
      updateMapping({
        meta_data_id: key,
        mapping: mapping,
      })
    );
  };
  const [open, setOpen] = useState(false);
  const saving = useAppSelector(
    (state) => state.userMapping.modifiedUMMetaId === props.UMData.id
  );

  const handleOpenModal = () => {
    setOpen(true);
  };
  const handleCloseModal = () => setOpen(false);
  return (
    <Grid container>
      <Grid item sx={{ mt: 1 }} xs={5.5}>
        <Typography variant="inter60016">{props.UMData.title}</Typography>
      </Grid>
      <Grid item xs={0.5} mt={1.2}>
        <img
          style={{ display: saving ? "block" : "none" }}
          height={"24px"}
          width={"24px"}
          src={SavingIcon}
          alt="saving animation"
        />
      </Grid>
      <Grid item xs={4} justifyContent="end" sx={{ my: 0.5 }}>
        <Select
          size="small"
          displayEmpty
          value={isGroup ? "group" : selectedValue}
          fullWidth
          onChange={(e) => handleChange(props.UMData.id, e)}
        >
          {Object.entries(props.UMData.options).map(([key, value]) => (
            <MenuItem key={key} value={key}>
              <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                {value}
              </Typography>
            </MenuItem>
          ))}
          <MenuItem key="" value="">
            <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
              None
            </Typography>
          </MenuItem>
          <MenuItem key="group" value="group">
            <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
              Group
            </Typography>
          </MenuItem>
        </Select>
      </Grid>
      <Grid
        item
        xs={2}
        justifyContent="end"
        sx={{ display: isGroup ? "block" : "none" }}
      >
        <Button
          sx={{
            borderRadius: "6px",
            height: "50px",
            marginX: 1,
          }}
          fullWidth
          variant="outlined"
          onClick={() => {
            handleOpenModal();
          }}
        >
          <Typography variant="poppins_600_12">Create/Edit Mapping</Typography>
        </Button>
      </Grid>

      <Dialog
        // fullScreen
        open={open}
        maxWidth={"lg"}
        onClose={handleCloseModal}
        sx={{
          zIndex: (th: any) => th.zIndex.drawer + 600,
        }}
      >
        <QuickbooksUserMappingGroupEditor
          userMappingMeta={props.UMData}
          handleCloseModal={handleCloseModal}
        />
      </Dialog>
    </Grid>
  );
};

export default QuickbooksSystemDefinedMappingListItem;
