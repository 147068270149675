import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Grid,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, Fragment, memo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  createReport,
  defaultReportSize,
  reportSizeOptions,
} from "../../../services/impact-report-service";
import { getCompanyId } from "../../../services/userinfo-service";
import { showMessage } from "../../../store/error-handler-store";
import { useAppDispatch } from "../../../store/hook";
import { fontInter, theme } from "../../../theme";

interface Props {
  open: boolean;
  close: Function;
  selectedTemplate: any;
  closePreviewModal?: Function;
  userQuery?: string;
  audience?: string;
  purpose?: string;
}

const CreateNewImpactReport = (props: Props) => {
  const dispatch = useAppDispatch();
  const { closePreviewModal } = props;
  const INIT_STATE = {
    name: "",
    company_uid: getCompanyId(),
    template_id: 0,
    attributes: defaultReportSize,
  };

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    bgcolor: "background.paper",
    borderRadius: "6px",
    boxShadow: 24,
    outline: "none",
    p: 4,
  };

  const navigate = useNavigate();
  const [reportData, setReportData] = useState(INIT_STATE);
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const [reportSize, setReportSize] = useState(defaultReportSize.slug);
  const [reportThumbnail, setReportThumbnail] = useState(
    defaultReportSize.thumbnail
  );

  const handleClose = (event: any, reason: any = undefined) => {
    if (reason === "backdropClick") return;
    props.close(false);
    setErrMsg("");
  };

  useEffect(() => {
    if (
      props.selectedTemplate &&
      props.selectedTemplate.id &&
      props.selectedTemplate.attributes
    ) {
      setReportSize(props.selectedTemplate.attributes.attributes.slug);
      let reportAttributes = reportSizeOptions.filter(
        (i) => i.slug == props.selectedTemplate.attributes.attributes.slug
      )[0];
      if (reportAttributes && reportAttributes.thumbnail) {
        setReportThumbnail(reportAttributes.thumbnail);
      }
    }
  }, [props.selectedTemplate]);

  const handleChange = (event: ChangeEvent<any>) => {
    let regex = /^[\w \_\-\(\)\s]+$/gi;
    if (!regex.test(event.target.value) && event.target.value.length > 0) {
      setErrMsg(
        "Only allowed character alphanumeric, space, -, _, (, ) for Report name"
      );
    } else {
      setErrMsg("");
      setReportData((prev) => {
        return {
          ...prev,
          name: event.target.value,
          template_id: props?.selectedTemplate?.id,
        };
      });
    }
  };

  useEffect(() => {
    setReportData((reportData) => {
      return {
        ...reportData,
        user_query: props.userQuery,
        audience: props.audience,
        purpose: props.purpose,
      };
    });
  }, [props.userQuery]);

  const onReportSizeChange = (e: any) => {
    let reportAttributes = reportSizeOptions.filter(
      (i) => i.slug == e.target.value
    )[0];
    if (reportAttributes && reportAttributes.thumbnail) {
      setReportThumbnail(reportAttributes.thumbnail);
    }
    setReportData((prev) => {
      return {
        ...prev,
        attributes: reportAttributes || defaultReportSize,
      };
    });
    setReportSize(e.target.value);
  };

  const onCreateReport = () => {
    if (!reportData.name) {
      setErrMsg("Report name can't be empty");
    } else {
      setErrMsg("");
      setLoading(true);
      createReport(reportData).then((res) => {
        if (res.status === 403) {
          setLoading(false);
          setErrMsg(
            "You don’t have access to create impact report. Please reach out to admin to get access"
          );
          return;
        }
        if (res.status === 201) {
          setLoading(false);
          dispatch(
            showMessage({
              message: "Report created successfully.",
              variant: "success",
            })
          );
          props.close(true);
          if (closePreviewModal) {
            closePreviewModal();
          }
          navigate(
            `/impact-report/${res["data"]["name"]}_${res["data"]["uid"]}`
          );
        }
      });
    }
  };

  return (
    <Fragment>
      <Modal
        open={props.open}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={handleClose}
        sx={{ zIndex: (th: any) => th.zIndex.drawer + 10000 }}
      >
        <Box sx={style}>
          <Grid container>
            <Grid mb={3} textAlign={"center"} item xs={11}>
              <Typography
                sx={{ color: `${theme.custom.primaryDarkColor}` }}
                variant="poppins_h3_600_24"
              >
                Create new report
              </Typography>
            </Grid>
            <Grid textAlign="right" item xs={1}>
              <CancelOutlinedIcon
                onClick={handleClose}
                sx={{
                  color: `${theme.custom.primaryGreyColor}`,
                  cursor: "pointer",
                }}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid mb={2} textAlign={"center"} item sm={12}>
              <img
                style={{ maxHeight: 170, maxWidth: 150 }}
                src={reportThumbnail}
                alt="thumbnail"
              />
            </Grid>
            <Grid mb={2} textAlign={"center"} item sm={12}>
              <Typography
                sx={{ color: theme.custom.primaryDarkColor }}
                variant="inter_400_14"
              >
                {props?.selectedTemplate?.attributes?.title || "Blank Template"}
              </Typography>
            </Grid>
            <Grid mb={2} item sm={12}>
              <Select
                fullWidth
                value={reportSize}
                onChange={onReportSizeChange}
                disabled={
                  props.selectedTemplate &&
                  props.selectedTemplate.id &&
                  props.selectedTemplate.attributes
                }
                MenuProps={{
                  sx: { zIndex: (th: any) => th.zIndex.drawer + 10001 },
                }}
              >
                {reportSizeOptions.map((option) => {
                  let _value: any = {
                    height: option.height,
                    width: option.width,
                  };
                  return (
                    <MenuItem key={option.slug} value={option.slug}>
                      <Typography variant="inter_p_500_14" sx={{ mt: 0.3 }}>
                        {option.label}
                      </Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid mb={2} item sm={12}>
              <TextField
                fullWidth
                label="Report name"
                variant="outlined"
                size="medium"
                sx={{
                  borderRadius: "6px",
                }}
                onKeyPress={(ev: any) => {
                  if (ev.key === "Enter") {
                    onCreateReport();
                    ev.preventDefault();
                  }
                }}
                onChange={handleChange}
                inputProps={{
                  style: {
                    background: theme.custom.whiteColor,
                    fontFamily: fontInter,
                    fontSize: "14px",
                    fontWeight: 500,
                  },
                  maxLength: 200,
                }}
              />
              {errMsg && errMsg.length > 0 && (
                <Typography
                  variant="inter_400_14"
                  sx={{ color: theme.custom.errorColor }}
                >
                  {errMsg}
                </Typography>
              )}
            </Grid>
            <Grid mb={2} item sm={12}>
              <LoadingButton
                sx={{ height: 50 }}
                disabled={errMsg.length > 0}
                onClick={onCreateReport}
                variant="contained"
                fullWidth
                loading={loading}
              >
                Create
              </LoadingButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  );
};

export default memo(CreateNewImpactReport);
