import * as React from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import {
  getUniqueArrByKey,
  lineChartDataFormatter,
} from "../../utils/CommonKPIResponseFormatterHelper";
import {
  numberFormatter,
  labelNumberFormatter,
  numberFormatterWithoutDecimals,
} from "../../utils/dataFormatters";
import {
  returnChartColors,
  theme,
  tooltipColors,
  chartLabelsStyle,
  axisLabelStyle,
  fontInter,
} from "../../theme";
import { returnFileName } from "../../utils/file-name-helper";
import { ChartProps } from "../../Types/ChartProps";
import _ from "lodash";

const GroupedColumnAndLineChart: React.FC<ChartProps> = (chartData) => {
  let data: any = chartData.chartData;
  let meta_data = chartData.chartMeta;
  let data_type = meta_data?.chart?.currencytype || "$";
  let date_range = chartData.dateRange;
  const height = chartData.height;
  const width = chartData.width;
  const editorMode = chartData.editorMode;
  let disableDataLabels = meta_data.chart["data-label-disabled"] || false;
  let removeDecimal = meta_data.chart["remove-decimal"] || false;

  const returnCombinedChartsData = (responseData: any, metaData: any) => {
    let finalData: any = [];
    var processedData: any = {};
    Object.keys(responseData).filter((ele: any, index: number) => {
      if (ele === "column") {
        responseData[ele].map((e: any) => {
          let oldData =
            processedData[e.primary_name] && processedData[e.primary_name].data
              ? processedData[e.primary_name].data
              : [];

          processedData[e.primary_name] = {
            name: e.primary_name,
            data: [...oldData, e.value],
            type: "column",
            yAxis: 0,
            color: metaData?.chart?.misc?.colors[e.primary_name],
          };
        });
        Object.keys(processedData).map((element) => {
          finalData.push(processedData[element]);
        });
      } else if (ele === "line") {
        let obj: any = {};
        obj["name"] = meta_data?.chart?.["line-chart-name"] || "";
        obj["type"] = "spline";
        obj["data"] = lineChartDataFormatter(responseData[ele]);
        obj["yAxis"] = 1;
        obj["color"] =
          metaData?.chart?.misc?.colors["line"] || returnChartColors(1);
        finalData = [...finalData, obj];
      }
    });
    return finalData;
  };

  const chartComponentRef = React.useRef<HighchartsReact.RefObject>(null);
  const options = {
    chart: {
      zoomType: editorMode ? "" : "xy",
      events: {
        render: function () {
          chartData.kpiRendered(true);
          chartData.chartReference(chartComponentRef);
          if (chartData.customKPI === false) {
            chartComponentRef.current?.chart.reflow();
          }
        },
      },
    },
    title: {
      text: "",
    },
    xAxis: {
      title: {
        text: meta_data.chart?.["x-axis-text"] || "",
        style: axisLabelStyle,
        margin: 20,
      },
      categories: getUniqueArrByKey(data["column"], "secondary_name"),
      labels: {
        formatter: function (this: any) {
          return `<span style="${chartLabelsStyle}">${this.value}</span>`;
        },
      },
    },
    yAxis: [
      {
        // Primary yAxis
        labels: {
          formatter: function (this: any) {
            return `<span style="${chartLabelsStyle}">${labelNumberFormatter(
              this.value,
              meta_data.chart?.columncurrencytype || "",
              true
            )}</span>`;
          },
        },
        title: {
          text: meta_data.chart?.["primary-y-axis-text"] || "",
          style: axisLabelStyle,
        },
      },
      {
        // Secondary yAxis
        labels: {
          formatter: function (this: any) {
            return `<span style="${chartLabelsStyle}"> ${labelNumberFormatter(
              this.value,
              meta_data.chart?.linecurrencytype || "",
              true
            )}</span>`;
          },
        },
        title: {
          text: meta_data.chart?.["secondary-y-axis-text"] || "",
          style: axisLabelStyle,
        },
        gridLineWidth: 1,
        opposite: true,
      },
    ],
    legend: {
      enabled: true,
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          inside: true,
          enabled: !disableDataLabels,
          formatter: function (this: any) {
            if (this.series.options.type === "spline") {
              return removeDecimal
                ? numberFormatterWithoutDecimals(
                    this.y,
                    meta_data.chart?.linecurrencytype || "",
                    true
                  )
                : numberFormatter(
                    this.y,
                    meta_data.chart?.linecurrencytype || "",
                    true
                  );
            } else {
              return removeDecimal
                ? numberFormatterWithoutDecimals(
                    this.y,
                    meta_data.chart?.columncurrencytype || "",
                    true
                  )
                : numberFormatter(
                    this.y,
                    meta_data.chart?.columncurrencytype || "",
                    true
                  );
            }
          },
          style: {
            fontWeight: 500,
            fontFamily: fontInter,
            color: theme.custom.primaryDarkColor,
          },
        },
        borderRadiusTopLeft: "10%",
        borderRadiusTopRight: "10%",
        states: {
          inactive: {
            opacity: 1,
          },
        },
      },
    },
    colors: returnChartColors(data && data.length),
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },
    exporting: {
      filename: returnFileName(meta_data?.chart?.title || "", date_range),
    },
    tooltip: {
      backgroundColor: tooltipColors.bgColor,
      borderColor: tooltipColors.borderColor,
      color: tooltipColors.dataFormatColor,
      padding: 10,
      borderRadius: 5,
      borderWidth: 1,
      headerFormat: ``,
      formatter: function (this: any) {
        if (this.series.options.type === "spline") {
          data_type = meta_data.chart?.linecurrencytype || "";
        }
        if (this.series.options.type === "column") {
          data_type = meta_data.chart?.columncurrencytype || "";
        }

        return `<span style="color: ${theme.custom.whiteColor}">${
          this.key
        }: ${labelNumberFormatter(this.y, data_type)}</span>`;
      },
    },
    series: returnCombinedChartsData(data, meta_data),
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      containerProps={{
        style: {
          width: "100%",
          height: "calc(100% - 1px)",
        },
      }}
      options={
        editorMode
          ? {
              ...options,
              ...{ chart: { ...options.chart, height: height, width: width } },
            }
          : options
      }
      ref={chartComponentRef}
    ></HighchartsReact>
  );
};

export default GroupedColumnAndLineChart;
