import { Divider, List, ListItem } from "@mui/material";
import { ConnectorMappingConfig } from "../../services/user-mapping-service";
import QuickbooksSystemDefinedMappingListItem from "../molecules/QuickbooksSystemDefinedMappingListItem";

interface Props {
  configItems: ConnectorMappingConfig[];
}

const QuickbooksSystemDefinedMappings = (props: Props) => {
  const totLen = props.configItems.filter((e) => e.step === 1).length - 1;
  return (
    <List sx={{ border: 1, borderRadius: "6px" }}>
      {props.configItems
        .filter((e) => e.step === 1)
        .map((item, idx) => (
          <div key={item.id}>
            <ListItem style={{backgroundColor: idx % 2 === 0 ? "#fffff" : "#f9f9f957" }}
              className={`umitem_${item.slug}`}>
              <QuickbooksSystemDefinedMappingListItem UMData={item} />
            </ListItem>
            <Divider sx={{ display: idx === totLen ? "none" : "block" }} variant="fullWidth" />
          </div>
        ))}
    </List>
  );
};

export default QuickbooksSystemDefinedMappings;
