import axios from "axios";

import A4DefaultThumbnail from "../assets/thumbnailA4.png";
import PPTDefaultThumbnail from "../assets/thumbnailPPT.png";
import {
  IMPACT_REPORTS_COMPONENT_LIBRARY_META,
  IMPACT_REPORTS_GET_LIBRARY,
  IMPACT_REPORTS_GET_LIBRARY_SETTINGS,
  IMPACT_REPORTS_GET_PUBLIC_REPORT,
  IMPACT_REPORTS_GET_UPDATE_DELETE_URL,
  IMPACT_REPORTS_LINK,
  IMPACT_REPORTS_LIST_CREATE_BRAND_MEDIA,
  IMPACT_REPORTS_LIST_CREATE_BRAND_COLORS,
  IMPACT_REPORTS_LIST_CREATE_BRAND_LIBRARY,
  IMPACT_REPORTS_LIST_CREATE_UPLOADED_MEDIA,
  IMPACT_REPORTS_LIST_CREATE_URL,
  IMPACT_REPORTS_PDF,
  IMPACT_REPORTS_PROMPT_RESOLVE,
  IMPACT_REPORTS_PUBLIC_PDF,
  IMPACT_REPORTS_SAVE_TEMPLATE,
  IMPACT_REPORTS_SEND,
  IMPACT_REPORTS_TEMPLATE_DETAILS,
  IMPACT_REPORTS_UNSPLASH_ANALYTICS,
  IMPACT_REPORTS_UNSPLASH_POPULAR,
  IMPACT_REPORTS_UNSPLASH_SEARCH,
} from "../endpoints/impact-report-endpoints";
import { environment } from "../environments/environment-dev";

export const getReport = (reportId: any) => {
  return axios
    .get<ImpactReportGetMetadata>(
      IMPACT_REPORTS_GET_UPDATE_DELETE_URL(reportId)
    )
    .then((resp) => {
      if (resp.status === 400) {
        alert(resp.data);
        return null;
      }
      if (resp.data) {
        const decompressedString = resp.data.content || "{}";
        return {
          data: JSON.parse(decompressedString),
          name: resp.data.name,
          attributes: resp.data.attributes,
          is_editable: resp.data.is_editable,
          is_shareable: resp.data.is_shareable,
          is_exportable: resp.data.is_exportable,
        };
      }
    });
};

// TODO: Add type checking
export const saveReport = (
  editorData: any,
  id: any,
  reportName: string,
  setSaving: CallableFunction,
  isTemplate: boolean = false
) => {
  setSaving(true);
  Object.keys(editorData.pages).map((pageId: any, index: any) => {
    let page = editorData.pages[pageId];
    if (page?._destroy) {
      // TODO: This is monkey patching.
      // Since Pages are DS'ed as array on splice it will reshuffle.
      // Need to move Pages to object with unique id as index like elements.
      // editorData.pages[pageId] = {};
      delete editorData.pages[pageId];
    } else {
      Object.keys(page.elements).map((id: any) => {
        if (page.elements[id]?._destroy) {
          delete page.elements[id];
        } else {
          page.elements[id] = {
            ...page.elements[id],
            ...{
              attributes: {
                ...page.elements[id].attributes,
                onLoadDelta: page.elements[id].delta,
              },
            },
          };
        }
      });
    }
  });
  const compressedReport = JSON.stringify(editorData);
  const reportData: ImpactReportMetadata = {
    uid: id.split("_").at(-1),
    name: reportName,
    content: compressedReport,
  };
  updateReport(reportData.uid || "", reportData).then((resp) =>
    setSaving(false)
  );
};

export const updateReport = (report_uid: string, data: any) =>
  axios.patch(IMPACT_REPORTS_GET_UPDATE_DELETE_URL(report_uid), data);

export const deleteReport = (report_uid: string) =>
  axios.delete(IMPACT_REPORTS_GET_UPDATE_DELETE_URL(report_uid));

export interface ImpactReportMetadata {
  uid?: string;
  name: string;
  content?: string;
  company?: number;
  daterange?: any;
  attributes?: any;
}

export interface ImpactReportGetMetadata extends ImpactReportMetadata {
  is_editable: boolean;
  is_shareable: boolean;
  is_exportable: boolean;
}

export interface ImpactReportCreateMetadata extends ImpactReportMetadata {
  company_uid?: string;
}

export const getFonts = () => {
  return [
    { value: "abril-fatface", label: "Abril Fatface" },
    { value: "black-ops-one", label: "Black Ops One" },
    { value: "bungee-shade", label: "Bungee Shade" },
    { value: "courgette", label: "Courgette" },
    { value: "dancing-script", label: "Dancing Script" },
    { value: "dm-sans", label: "DM Sans" },
    { value: "ibm-plex-serif", label: "IBM Plex Serif" },
    { value: "indie-flower", label: "Indie Flower" },
    { value: "inter", label: "Inter" },
    { value: "luckiest-guy", label: "Luckiest Guy" },
    { value: "merriweather", label: "Merriweather" },
    { value: "merriweather-sans", label: "Merriweather Sans" },
    { value: "monoton", label: "Monoton" },
    { value: "playfair-display", label: "Playfair Display" },
    { value: "poppins", label: "Poppins" },
    { value: "pt-mono", label: "PT Mono" },
    { value: "quicksand", label: "Quicksand" },
    { value: "raleway", label: "Raleway" },
    { value: "roboto", label: "Roboto" },
    { value: "rubik-moonrocks", label: "Rubik Moonrocks" },
    { value: "rye", label: "Rye" },
    { value: "sacramento", label: "Sacramento" },
    { value: "satisfy", label: "Satisfy" },
    { value: "unica-one", label: "Unica One" },
    { value: "zilla-slab", label: "Zilla Slab" },
  ];
};

export const getFontSizes = () => {
  // TODO: Move it to BE config
  return [
    { label: 6, value: "6px" },
    { label: 8, value: "8px" },
    { label: 10, value: "10px" },
    { label: 12, value: "12px" },
    { label: 14, value: "14px" },
    { label: 16, value: "16px" },
    { label: 18, value: "18px" },
    { label: 21, value: "21px" },
    { label: 24, value: "24px" },
    { label: 28, value: "28px" },
    { label: 32, value: "32px" },
    { label: 36, value: "36px" },
    { label: 42, value: "42px" },
    { label: 48, value: "48px" },
    { label: 56, value: "56px" },
    { label: 64, value: "64px" },
    { label: 72, value: "72px" },
    { label: 80, value: "80px" },
    { label: 88, value: "88px" },
    { label: 96, value: "96px" },
    { label: 104, value: "104px" },
    { label: 120, value: "120px" },
    { label: 144, value: "144px" },
  ];
};

export const getPublicReport = (uid: string, password: string) => {
  if (password) {
    return axios.post(IMPACT_REPORTS_GET_PUBLIC_REPORT(uid), {
      password: password,
    });
  } else {
    return axios.get(IMPACT_REPORTS_GET_PUBLIC_REPORT(uid));
  }
};

export const getTextLibrary = (page?: number) => {
  return axios.get(IMPACT_REPORTS_GET_LIBRARY("text", page));
};
export const getTemplateLibrary = (page?: number, filterOptions?: any) => {
  return axios.get(
    IMPACT_REPORTS_GET_LIBRARY("template", page, filterOptions || {})
  );
};
export const sendIREmails = (
  uid: string,
  emails: string[],
  password?: string
) => {
  return axios.post(IMPACT_REPORTS_SEND(uid), {
    emails: emails,
    password: password,
  });
};
export const createReportLink = (uid: string, payload: any) => {
  return axios.post(IMPACT_REPORTS_LINK(uid), payload);
};
export const createReportPdf = (report_link_uid: string, pages: number) => {
  return axios.get(IMPACT_REPORTS_PDF(report_link_uid), {
    params: {
      pages: pages,
    },
    responseType: "blob",
  });
};
export const createPublicReportPdf = (
  public_report_link_uid: string,
  pages: number
) => {
  return axios.get(IMPACT_REPORTS_PUBLIC_PDF(public_report_link_uid), {
    params: {
      pages: pages,
    },
    responseType: "blob",
  });
};

export const uploadBrandMedia = (formData: FormData, extraArgs?: any) => {
  return axios.post(
    IMPACT_REPORTS_LIST_CREATE_BRAND_MEDIA(),
    formData,
    extraArgs
  );
};

export const updateBrandColors = (colors: any) => {
  return axios.patch(IMPACT_REPORTS_LIST_CREATE_BRAND_COLORS(), colors);
};

export const getBrandLibrary = () => {
  return axios.get(IMPACT_REPORTS_LIST_CREATE_BRAND_LIBRARY());
};

export const getBrandColors = () => {
  return axios.get(IMPACT_REPORTS_LIST_CREATE_BRAND_COLORS());
};

export const getBrandMedia = (page: number) => {
  return axios.get(IMPACT_REPORTS_LIST_CREATE_BRAND_MEDIA(page, "IMAGE"));
};

export const uploadMedia = (formData: FormData, extraArgs?: any) => {
  return axios.post(
    IMPACT_REPORTS_LIST_CREATE_UPLOADED_MEDIA(),
    formData,
    extraArgs
  );
};

export const getUploadedImages = (page: number) => {
  return axios.get(IMPACT_REPORTS_LIST_CREATE_UPLOADED_MEDIA(page, "IMAGE"));
};

export const getUploadedVideos = (page: number) => {
  return axios.get(IMPACT_REPORTS_LIST_CREATE_UPLOADED_MEDIA(page, "VIDEO"));
};

export const getComponentSettings = (componentType: string, page?: number) => {
  return axios.get(IMPACT_REPORTS_GET_LIBRARY_SETTINGS(componentType, page));
};

export const getKPILibrary = (
  type: string,
  page?: number,
  searchStr: string = "",
  tags: string[] = [],
  reportUID?: string
) => {
  let urlBase = IMPACT_REPORTS_GET_LIBRARY(type, page);
  if (reportUID) {
    urlBase = `${urlBase}&report_uid=${reportUID}`;
  }
  let payload: any = {};

  if (searchStr.length > 0) {
    payload.name = searchStr;
  }
  if (tags.length > 0) {
    payload.tag = tags;
  }

  return axios.get(`${urlBase}&filter=${JSON.stringify(payload)}`);
};

export const getPopularImages = (page: number = 1, per_page: number = 30) => {
  return axios.get(IMPACT_REPORTS_UNSPLASH_POPULAR(page, per_page));
};
export const updateUnsplashAnalytics = (url: string) => {
  return axios.get(IMPACT_REPORTS_UNSPLASH_ANALYTICS(url));
};

export const getSearchedImages = (
  query: string,
  page: number = 1,
  per_page: number = 30
) => {
  return axios.get(IMPACT_REPORTS_UNSPLASH_SEARCH(query, page, per_page));
};

export const getFiltersByKPISlug = (slug: string) => {
  const url = `${environment.connectorApiUrl}/impact-reporting/settings/get-filters/${slug}`;
  return axios.get(url);
};

export const createReport = (reportData: ImpactReportCreateMetadata) => {
  return axios.post(IMPACT_REPORTS_LIST_CREATE_URL, reportData);
};
export const getAudiencePurpose = () => {
  return axios.get(IMPACT_REPORTS_COMPONENT_LIBRARY_META("template"));
};

export const shapesLibrary = (page?: number) => {
  return axios.get(IMPACT_REPORTS_GET_LIBRARY("shapes", page));
};

export const bgLibrary = (page?: number) => {
  return axios.get(IMPACT_REPORTS_GET_LIBRARY("background", page));
};

export const interactiveElementsLibrary = (page?: number) => {
  return axios.get(IMPACT_REPORTS_GET_LIBRARY("interactive-elements", page));
};

export const maskLibrary = (page?: number) => {
  return axios.get(IMPACT_REPORTS_GET_LIBRARY("mask", page));
};

export const getAllReports = (page: number = 1, search: string = "") => {
  return axios.get(
    `${IMPACT_REPORTS_LIST_CREATE_URL}?page=${page}&search=${search}`
  );
};

export const updateReportName = (uid: string, obj: any) => {
  return axios.patch(`${IMPACT_REPORTS_LIST_CREATE_URL}${uid}`, obj);
};

export const getIcons = (query: string = "all") => {
  let url = `https://api.iconify.design/search?query=${query}&limit=100`;
  return axios.get(url);
};

export const ellipse =
  "https://bookr-pebble-media-uat.s3.us-west-1.amazonaws.com/uploaded_media/ellipse.png";
export const default_thumbnail =
  "https://bookr-pebble-media-uat.s3.us-west-1.amazonaws.com/uploaded_media/tumbnail.png";

export const defaultReportSize = {
  label: "A4 Document",
  height: 3508,
  width: 2480,
  slug: "a4_document",
  thumbnail: A4DefaultThumbnail,
};

export const reportSizeOptions = [
  defaultReportSize,
  {
    label: "Presentation (16:9)",
    height: 1080,
    width: 1920,
    slug: "presentation_16_9",
    thumbnail: PPTDefaultThumbnail,
  },
];

export const saveTemplate = (
  templateData: any,
  templateName: string,
  reportAttributes: any
) => {
  let postData: any = {
    template_data: templateData,
    template_name: templateName,
    template_attributes: reportAttributes,
  };
  return axios.post(IMPACT_REPORTS_SAVE_TEMPLATE, postData);
};

export const deleteTemplate = (templateId: string) =>
  axios.delete(IMPACT_REPORTS_TEMPLATE_DETAILS(templateId));

export const getResolvedPrompt = (data: any) => {
  return axios.post(IMPACT_REPORTS_PROMPT_RESOLVE, data);
};

export const linkExcludedTypes = [
  "KPIEditor",
  "TabularReportEditor",
  "VideoEditor",
  "EmbedEditor",
  "TableEditor",
];

export const paddingExcludedTypes = [
  "KPIEditor",
  "TabularReportEditor",
  "IconEditor",
  "TableEditor",
  "TextEditor",
];

export const getDefaultThumbnail = (slug: any) => {
  if (slug === "presentation_16_9") return PPTDefaultThumbnail;
  return A4DefaultThumbnail;
};
