import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  Typography,
  TableBody,
  TableRow,
  Grid,
  Box,
  Tooltip,
} from "@mui/material";

import React from "react";
import { theme } from "../../theme";
import { ProgramObject, UserListObject } from "../../Types/UserObject";
import DeleteIcon from "../../assets/deleteIcon.png";
import LoaderIcon from "../../assets/loader.png";
import EditIcon from "../../assets/pencil.png";
import { useNavigate } from "react-router-dom";

type Props = {
  deleteUserCallback: Function;
  usersList: UserListObject[];
  showProgress: boolean;
  setOpenPopup: CallableFunction;
  setMemberUUID: CallableFunction;
};

const UsersListTable = (props: Props) => {
  const navigate = useNavigate();
  let userList = props.usersList;
  let showProgress = props.showProgress;
  const deleteUser = props.deleteUserCallback;

  const onDeleteUser = (uuid: string) => {
    let sure = window.confirm("Are you sure to delete the user??");
    if (sure) {
      deleteUser(uuid);
    }
  };

  const programsFormatter = (
    hasFullAccess: boolean,
    programsAssigned: ProgramObject[]
  ) => {
    if (hasFullAccess === true) {
      return (
        <Tooltip title="All Programs">
          <Typography variant="inter_400_14">All Programs</Typography>
        </Tooltip>
      );
    } else if (programsAssigned.length === 0) {
      return (
        <Tooltip title="No Programs">
          <Typography variant="inter_400_14">No Programs</Typography>
        </Tooltip>
      );
    } else if (programsAssigned.length === 1) {
      return (
        <Tooltip title={programsAssigned[0]["label"]}>
          <Typography variant="inter_400_14">
            {programsAssigned[0]["label"]}
          </Typography>
        </Tooltip>
      );
    } else if (programsAssigned.length > 1) {
      let programsList = programsAssigned
        .map(function (e) {
          return e.label;
        })
        .join(", ");

      return (
        <Tooltip title={programsList}>
          <Typography variant="inter_400_14">
            {programsAssigned[0]["label"] +
              " " +
              `+ ${programsAssigned.length - 1}`}
          </Typography>
        </Tooltip>
      );
    }
  };
  const onEdit = (uuid: string) => {
    props.setMemberUUID(uuid);
    props.setOpenPopup(true);
  };

  return (
    <TableContainer
      sx={{
        border: `1px solid ${theme.custom.borderColor}`,
        borderRadius: "6px",
        position: "relative",
      }}
    >
      <Table>
        <TableHead
          sx={{
            background: theme.kpiMoleculeColors.rightDescriptionItemBgColor,
          }}
        >
          <TableCell>
            <Typography variant="inter_p_600_16">Name</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="inter_p_600_16">Email</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="inter_p_600_16">Role</Typography>
          </TableCell>
          <TableCell>
            <Typography variant="inter_p_600_16">
              Program(s) Assigned
            </Typography>
          </TableCell>
          <TableCell></TableCell>
          <TableCell></TableCell>
        </TableHead>
        <TableBody>
          {userList &&
            userList.length > 0 &&
            userList.map((row, index) => (
              <TableRow
                sx={{
                  "&.MuiTableRow-root:hover": {
                    backgroundColor: theme.custom.chipGroupBackground,
                  },
                }}
                key={index}
              >
                <TableCell component="th" scope="row">
                  <Typography variant="inter_400_14">{row.username}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="inter_400_14">{row.email}</Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="inter_400_14">
                    {row.role} {row.self_user && "(You)"}
                  </Typography>
                </TableCell>
                <TableCell>
                  {programsFormatter(
                    row.has_full_access,
                    row.programs_assigned
                  )}
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  <Typography
                    onClick={() => {
                      navigate(`/members/${row.uuid}`);
                    }}
                    sx={{
                      color: theme.custom.popoverLinkColor,
                      cursor: "pointer",
                    }}
                    variant="poppins_p_600_14"
                  >
                    View
                  </Typography>
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  <img
                    onClick={() => onEdit(row.uuid)}
                    style={{ cursor: "pointer", marginRight: 10 }}
                    height="25px"
                    src={EditIcon}
                    alt="Edit"
                  />

                  {!row.self_user && (
                    <img
                      onClick={() => onDeleteUser(row.uuid)}
                      style={{ cursor: "pointer" }}
                      height="25px"
                      src={DeleteIcon}
                      alt="Delete"
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <Box sx={{ display: showProgress ? "block" : "none" }}>
        <Grid
          className="show-loader"
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <img src={LoaderIcon} height="100" width="100" alt="Loader" />
          </Grid>
        </Grid>
      </Box>
    </TableContainer>
  );
};

export default React.memo(UsersListTable);
