import { Grid, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { theme } from "../../../theme";

type Props = {
  boardPermissions: any;
};

const MemberBoardPermissionDetails = (props: Props) => {
  const { boardPermissions } = props;
  const [
    preBuiltBoardPermissionsFormatted,
    setPreBuiltBoardPermissionsFormatted,
  ] = useState({} as any);

  const [customBoardPermissionsFormatted, setCustomBoardPermissionsFormatted] =
    useState({} as any);

  useEffect(() => {
    dataPermissionFormatter(boardPermissions);
  }, [boardPermissions]);

  const reduceValues = (values: any[]) => {
    var result = values
      .map(function (e) {
        return e.kpi_title;
      })
      .join(", ");
    if (result.length === 0) {
      return "Not given";
    }
    return result;
  };

  const getFormattedBoardPermission = (
    permissionData: any,
    boardName?: string
  ) => {
    if (
      permissionData &&
      Object.keys(permissionData).length > 0 &&
      permissionData["has_full_access"]
    ) {
      return { [`${boardName}`]: "All Boards" };
    } else {
      if (
        permissionData &&
        Object.keys(permissionData).length > 0 &&
        permissionData.boards &&
        permissionData.boards.length > 0
      ) {
        return permissionData.boards.reduce(
          (previousObject: any, currentObject: any) => {
            return Object.assign(previousObject, {
              [currentObject.board_title]: currentObject.has_full_access
                ? "All KPIs"
                : reduceValues(currentObject["authorized_kpis"]),
            });
          },
          {}
        );
      }
    }
  };
  const dataPermissionFormatter = (board: any) => {
    if (!board) {
      return;
    }

    setPreBuiltBoardPermissionsFormatted((prev: any) => {
      return {
        ...prev,
        ...getFormattedBoardPermission(
          board["pre_built_board_kpi_permissions"],
          "Pre built Board Permissions"
        ),
      };
    });

    setCustomBoardPermissionsFormatted((prev: any) => {
      return {
        ...prev,
        ...getFormattedBoardPermission(board["custom_board_kpi_permissions"],  "Custom Board Permissions"),
      };
    });
  };

  return (
    <>
      <Box my={2}>
        <Typography
          sx={{ color: theme.custom.primaryDarkColor }}
          variant="poppins_h4_600_20"
        >
          Pre Built Board Kpi Permissions
        </Typography>
      </Box>
      <Grid container justifyContent={"space-between"}>
        {preBuiltBoardPermissionsFormatted &&
          Object.keys(preBuiltBoardPermissionsFormatted).length > 0 &&
          Object.keys(preBuiltBoardPermissionsFormatted).map((ele: any) => {
            return (
              <Grid container justifyContent={"space-between"}>
                <Grid mb={2} item>
                  <Typography
                    variant="inter_p_600_16"
                    sx={{ color: theme.custom.primaryDarkColor }}
                  >
                    {ele}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="inter_p_400_16">
                    {preBuiltBoardPermissionsFormatted[ele]}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
      <Box my={2}>
        <Typography
          sx={{ color: theme.custom.primaryDarkColor }}
          variant="poppins_h4_600_20"
        >
          Custom Board Kpi Permissions
        </Typography>
      </Box>
      <Grid container justifyContent={"space-between"}>
        {customBoardPermissionsFormatted &&
          Object.keys(customBoardPermissionsFormatted).length > 0 &&
          Object.keys(customBoardPermissionsFormatted).map((ele: any) => {
            return (
              <Grid container justifyContent={"space-between"}>
                <Grid mb={2} item>
                  <Typography
                    variant="inter_p_600_16"
                    sx={{ color: theme.custom.primaryDarkColor }}
                  >
                    {ele}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="inter_p_400_16">
                    {customBoardPermissionsFormatted[ele]}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
      </Grid>
    </>
  );
};

export default MemberBoardPermissionDetails;
