import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Slider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React, { useCallback, useEffect, useState } from "react";
import DeleteIcon from "../../../assets/deleteIcon.png";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { theme } from "../../../theme";
import IconsEditorSettings from "../../molecules/Editor/IconsEditorSettings";
import ImageEditorSettings from "../../molecules/Editor/ImageEditorSettings";
import KPIEditorSettings from "../../molecules/Editor/KPIEditorSettings";
import ShapesEditorSettings from "../../molecules/Editor/ShapesEditorSettings";
import TableEditorSettings from "../../molecules/Editor/TableEditorSettings";
import TextEditorSettings from "../../molecules/Editor/TextEditorSettings";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InteractiveElementEditorSettings from "../../molecules/Editor/InteractiveElementEditorSettings";
import { setSettingEditActive } from "../../../store/selected-design-element-store";
import {
  linkExcludedTypes,
  paddingExcludedTypes,
} from "../../../services/impact-report-service";
import { addHttpsToUrl, isValidUrl } from "../../../utils/data-utils";

function SettingsDrawer(props: any) {
  const {
    updateElement,
    deleteElement,
    toggleCustomAbles,
    selectedElement,
    onResetSelection,
    updateOverlay,
  } = props;
  //TODO: Remove
  const zoom = 0.25;

  const selectedElementCurrentFormat = useAppSelector(
    (state) => state.selectedDesignElement.format
  );
  const [padding, setPadding] = useState<number>(0);
  const [linkurl, setLink] = useState<string>("");
  const [opacity, setOpacity] = useState<number>(100);

  useEffect(() => {
    setPadding(selectedElement?.padding || 0);
    setOpacity(selectedElement?.opacity || 100);
    setLink(selectedElement?.linkurl || "");
  }, [selectedElement]);

  const updatePadding = (e: any) => {
    setPadding(e.target.value as number);
    _updateElement(selectedElement.id, { padding: e.target.value });
  };

  const [isValidLink, setIsValidLink] = useState<boolean>(true);
  const updateLink = (e: any) => {
    setLink(e.target.value);
    const isValid = e.target.value === "" || isValidUrl(e.target.value);
    setIsValidLink(isValid);
    if (isValid) {
      if (e.target.value !== "") {
        _updateElement(selectedElement.id, { linkurl: addHttpsToUrl(e.target.value) });
      } else {
        _updateElement(selectedElement.id, { linkurl: "" });
      }
    }
  };

  const openLink = () => {
    window.open(linkurl, "_blank");
  };

  const updateOpacity = (e: any) => {
    setOpacity(e.target.value as number);
    _updateElement(selectedElement.id, { opacity: e.target.value });
  };
  const dispatch = useAppDispatch();
  const _updateElement = useCallback(
    (id, data: object, actionSource = "user") => {
      updateElement(
        id,
        data,
        selectedElement?.pageId,
        selectedElement?.id,
        actionSource
      );
    },
    [selectedElement?.pageId, selectedElement?.id, updateElement]
  );

  const _deleteElement = useCallback(
    (id) => {
      deleteElement(id, selectedElement.pageId);
    },
    [selectedElement, deleteElement]
  );

  const pickSettingByType = (type: any) => {
    switch (type) {
      case "TextEditor":
        return (
          <>
            <TextEditorSettings
              element={selectedElement}
              zoom={zoom}
              format={selectedElementCurrentFormat}
              updateElement={_updateElement}
              updateOverlay={updateOverlay}
            />
          </>
        );
      case "ImageEditor":
        return (
          <>
            <ImageEditorSettings
              element={selectedElement}
              zoom={zoom}
              toggleCustomAbles={toggleCustomAbles}
              format={selectedElementCurrentFormat}
              updateElement={_updateElement}
            />
          </>
        );
      case "TableEditor":
        return (
          <>
            <TableEditorSettings
              attributes={selectedElement.attributes || {}}
              tableConfig={selectedElement.tableConfig || {}}
              zoom={zoom}
              toggleCustomAbles={toggleCustomAbles}
              format={selectedElementCurrentFormat}
              updateElement={_updateElement}
              elementId={selectedElement.id}
              updateOverlay={updateOverlay}
            />
          </>
        );
      case "KPIEditor":
        return (
          <>
            <KPIEditorSettings
              element={selectedElement}
              zoom={zoom}
              updateElement={_updateElement}
              updateOverlay={updateOverlay}
            />
          </>
        );
      case "TabularReportEditor":
        return (
          <>
            <KPIEditorSettings
              element={selectedElement}
              zoom={zoom}
              updateElement={_updateElement}
              updateOverlay={updateOverlay}
            />
          </>
        );
      case "ShapesEditor":
        return (
          <>
            <ShapesEditorSettings
              attributes={selectedElement.attributes}
              id={selectedElement.id}
              updateElement={_updateElement}
              updateOverlay={updateOverlay}
            />
          </>
        );
      case "InteractiveElementEditor":
        return (
          <>
            <InteractiveElementEditorSettings
              attributes={selectedElement.attributes}
              id={selectedElement.id}
              updateElement={_updateElement}
              updateOverlay={updateOverlay}
            />
          </>
        );
      case "IconEditor":
        return (
          <>
            <IconsEditorSettings
              attributes={selectedElement.attributes}
              id={selectedElement.id}
              updateElement={_updateElement}
              updateOverlay={updateOverlay}
            />
          </>
        );
      default:
        break;
    }
  };

  const returnTitleForSettings = () => {
    if (selectedElement?.type === "TextEditor") {
      return selectedElement?.attributes?.text;
    } else if (
      selectedElement?.type === "KPIEditor" ||
      selectedElement?.type === "TabularReportEditor" ||
      selectedElement?.type === "ImageEditor" ||
      selectedElement?.type === "VideoEditor" ||
      selectedElement?.type === "EmbedEditor" ||
      selectedElement?.type === "TableEditor" ||
      selectedElement?.type === "ShapesEditor" ||
      selectedElement?.type === "IconEditor"
    ) {
      return selectedElement?.attributes?.title || "Unnamed";
    }
  };

  return (
    <Box
      id="setting-drawer-container"
      style={{
        WebkitUserSelect: "none" /* Safari */,
        msUserSelect: "none" /* IE 10 and IE 11 */,
        userSelect: "none" /* Standard syntax */,
      }}
      pt={2}
    >
      {selectedElement?.id && (
        <Grid
          px={1}
          container
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Grid item alignItems={"center"} mb={1}>
            <Stack direction="row" alignItems="center">
              <IconButton onClick={onResetSelection} aria-label="back">
                <ArrowBackIcon sx={{ color: theme.custom.primaryGreyColor }} />
              </IconButton>
              <div>
                <Tooltip title={returnTitleForSettings()}>
                  <Typography
                    sx={{ color: theme.custom.primaryDarkColor, maxWidth: 150 }}
                    variant="inter_p_400_16"
                    noWrap
                  >
                    {returnTitleForSettings()}
                  </Typography>
                </Tooltip>
              </div>
            </Stack>
          </Grid>
          <Grid alignItems={"center"} item>
            <img
              onClick={() => _deleteElement(selectedElement.id)}
              height={20}
              width={15}
              alt="Delete"
              style={{ cursor: "pointer" }}
              src={DeleteIcon}
            />
          </Grid>
        </Grid>
      )}
      <Divider sx={{ mt: 2, mb: 2 }} />

      {selectedElement?.id ? (
        <>
          <Box px={2} mt={3} mb={2} className="designSetting">
            {pickSettingByType(selectedElement?.type) ? (
              <>
                <Box>{pickSettingByType(selectedElement?.type)}</Box>
                <Divider sx={{ mt: 2, mb: 2, ml: -2, mr: -2 }} />
              </>
            ) : (
              ""
            )}
            {selectedElement &&
              !linkExcludedTypes.includes(selectedElement?.type) && (
                <>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    mt={2}
                    mb={2}
                  >
                    <Grid item md={12}>
                      <Typography
                        variant="inter_p_600_16"
                        sx={{ color: theme.custom.primaryDarkColor }}
                      >
                        Add a Link
                      </Typography>
                    </Grid>
                    <Grid item md={10}>
                      <TextField
                        placeholder="Paste a link here"
                        id="search-icon"
                        sx={{ mt: 1, width: "100%" }}
                        onChange={updateLink}
                        onFocus={() => dispatch(setSettingEditActive(true))}
                        onBlur={() => dispatch(setSettingEditActive(false))}
                        value={linkurl}
                        error={!isValidLink}
                        inputProps={{
                          style: {
                            height: "10px",
                            background: theme.custom.whiteColor,
                            fontSize: "14px",
                            fontWeight: 500,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item md={2} sx={{ mt: 1 }}>
                      <IconButton
                        onClick={() => openLink()}
                        aria-label="openInNewTab"
                        sx={{ color: (t) => t.palette.grey[500] }}
                        disabled={!linkurl || !isValidLink}
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 2, mb: 2, ml: -2, mr: -2 }} />
                </>
              )}
            {selectedElement &&
              !paddingExcludedTypes.includes(selectedElement?.type) && (
                <>
                  <Grid
                    container
                    mt={2}
                    mb={2}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Grid item md={12}>
                      <Typography
                        variant="inter_p_600_16"
                        sx={{ color: theme.custom.primaryDarkColor }}
                      >
                        Padding
                      </Typography>
                    </Grid>
                    <Grid item md={11}>
                      <Slider
                        aria-label="Padding"
                        value={padding}
                        getAriaValueText={(value: number) => `${value}px`}
                        step={10}
                        size="small"
                        sx={{
                          color: theme.custom.primaryGreyColor,
                          "& .MuiSlider-rail": {
                            color: theme.custom.borderColor,
                          },
                        }}
                        onChange={updatePadding}
                        min={0}
                        max={30}
                        valueLabelDisplay="auto"
                        marks={[
                          {
                            value: 0,
                            label: "0px",
                          },
                          {
                            value: 10,
                            label: "10px",
                          },
                          {
                            value: 20,
                            label: "20px",
                          },
                          {
                            value: 30,
                            label: "30px",
                          },
                        ]}
                      />
                    </Grid>
                  </Grid>
                  <Divider sx={{ mt: 2, mb: 2, ml: -2, mr: -2 }} />
                </>
              )}
            <Grid
              container
              mt={2}
              mb={2}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid item md={12}>
                <Typography
                  variant="inter_p_600_16"
                  sx={{ color: theme.custom.primaryDarkColor }}
                >
                  Opacity
                </Typography>
              </Grid>
              <Grid item md={11}>
                <Slider
                  aria-label="Opacity"
                  getAriaValueText={(value: number) => `${value * 100}%`}
                  value={opacity}
                  step={1}
                  size="small"
                  sx={{
                    color: theme.custom.primaryGreyColor,
                    "& .MuiSlider-rail": {
                      color: theme.custom.borderColor,
                    },
                    "& .MuiSlider-markLabel": {
                      color: theme.custom.primaryGreyColor,
                      fontSize: "12px",
                    },
                  }}
                  min={10}
                  max={100}
                  onChange={updateOpacity}
                  valueLabelDisplay="auto"
                />
              </Grid>
            </Grid>
            <Divider sx={{ mt: 2, mb: 2, ml: -2, mr: -2 }} />
          </Box>
        </>
      ) : (
        <>
          <Box
            m={4}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
              height: "100%",
              flexDirection: "column",
            }}
          >
            <div>
              <VisibilityOutlinedIcon color={"disabled"} />
            </div>
            <Typography variant="inter_400_14" color={"ghost"}>
              Select or add something to see settings
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
}

export default React.memo(SettingsDrawer);
