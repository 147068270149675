import KPIList from "../components/complexes/KPIList";
import React, { memo, useEffect, useState } from "react";
import getChartsForBoard from "../services/board-service";
import Box from "@mui/material/Box";
import { theme } from "../theme";
import FilterListWithOverlay from "../components/complexes/FiltersListWithOverlay";
import {
  useParams,
  useNavigate,
  useLocation,
  useSearchParams,
} from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hook";
import {
  Avatar,
  Button,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Sidebar } from "../components/complexes/Sidebar";
import { showMessage } from "../store/error-handler-store";
import bgImage from "../assets/Group-177-min.png";
import { filterDataFormatter } from "../utils/filterDataFormatter";
import _ from "lodash";
import FilterIcon from "../assets/filter_icon.png";
import { setNavigationData } from "../store/navigation-store";
import { BoardProps } from "../Types/BoardProps";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const INIT_STATE = {
  data: [],
  filters: {},
  isCustomBoard: false,
  board_type: "grid",
  category: "",
};

interface Props {
  slugFromProps?: string;
  showSideBar?: boolean;
  filtersForBudgetDrillDown?: Object;
  titleFromProps?: string;
}

function Board(props: Props) {
  const {
    slugFromProps,
    showSideBar,
    filtersForBudgetDrillDown = {},
    titleFromProps,
  } = props;

  const filterDataFromState: any = useAppSelector(
    (state) => state.filterState.apply
  );

  const customKPISelected = useAppSelector(
    (state) => state.filterState.selectedKPI
  );
  const location = useLocation();
  const params = useParams();

  const navigate = useNavigate();
  const [board, setBoard] = useState<BoardProps>(INIT_STATE);
  const [title, setTitle] = useState<string>("");
  const [connectorsList, setConnectorsList] = useState<Array<string>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [urlKpiFilters, setUrlKpiFilters] = useState({});
  const dispatch = useAppDispatch();

  const [slug, setSlug] = useState<string>("");

  useEffect(() => {
    setSlug(params?.slug_name || "");
    setTitle("");
  }, [params]);

  let [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams?.get("encoded_filter_data")) {
      const data = JSON.parse(
        atob(searchParams?.get("encoded_filter_data") || "{}")
      );
      setUrlKpiFilters(data);
      onFiltersApply(data);
    } else {
      setUrlKpiFilters({});
    }
  }, [searchParams]);

  useEffect(() => {
    if (slugFromProps) {
      setSlug(slugFromProps);
    }
  }, [slugFromProps]);
  useEffect(() => {
    if (location.hash !== "" && !isLoading) {
      let kpiSlug = location.hash.slice(1);
      setTimeout(() => {
        let ele = document.getElementById(kpiSlug);
        if (ele) {
          window.scrollTo({
            behavior: "smooth",
            top:
              ele.getBoundingClientRect().top -
              document.body.getBoundingClientRect().top -
              100,
          });
        }
      }, 1000);
    }
  }, [location, isLoading]);

  const onFiltersApply = (_filters: any, allFilters: any = {}) => {
    setBoard((prev) => {
      return { ...prev, filters: {} };
    });
    fetchKPIsForBoard(_filters, allFilters);
  };

  const backButtonNavigation = () => {
    let url = `/metrics/${board?.category}`;
    if (board.category === "custom-kpis") {
      url += "#board";
    }
    if (slug === "expense-proof-and-invoice-tracking") {
      url = "/budgets";
    }
    dispatch(
      setNavigationData({
        slug: (slug ? slug : slugFromProps) || "",
        title: title,
      })
    );
    navigate(url);
  };

  const fetchKPIsForBoard = (filterData: any, allFilters: any = {}) => {
    setIsLoading(true);
    if (!slug && !slugFromProps) return;
    getChartsForBoard(
      (slug ? slug : slugFromProps) || "",
      filterDataFromState?.survey?.value || ""
    )
      .then((res) => {
        if (res.status === 403) {
          setErrorMessage(
            "Either there are no linked KPIs or you don't have permission to view KPIs in this board. Please reach out to admin to get access."
          );
          setIsLoading(false);
          return;
        }
        if (res.status === 404) {
          setErrorMessage(
            "Board doesn’t exist. Please use menu to navigate to other boards."
          );
          setIsLoading(false);
          return;
        }
        if (res.status === 200 && res.data.kpi.length === 0) {
          setErrorMessage(
            "Either there are no linked KPIs or you don't have permission to view KPIs in this board. Please reach out to admin to get access."
          );
          setIsLoading(false);
        }
        if (!showSideBar) {
          document.title = (res?.data?.title || "") + " - Pebble";
        }
        setTitle(res?.data?.title || "");
        setConnectorsList(res?.data?.thumbnails || []);
        dispatch(
          setNavigationData({
            slug: (slug ? slug : slugFromProps) || "",
            title: res?.data?.title,
          })
        );

        if (
          res &&
          res.data &&
          res.data.kpi &&
          Object.keys(res.data.kpi).length > 0
        ) {
          let orderedData = _.orderBy(res?.data?.kpi, ["position"], ["asc"]);
          setIsLoading(false);
          setBoard((prev) => {
            return {
              ...prev,
              data: orderedData,
              filters: {
                ...(res.data?.is_custom_board
                  ? { ...filterData, pQoz9ykBE7: "Yke5G9WinP" }
                  : filterDataFormatter(filterData, allFilters)),
                ...filtersForBudgetDrillDown,
                ...filterDataFormatter(
                  { ...urlKpiFilters, ...filterData },
                  allFilters
                ),
              },

              isCustomBoard: res.data?.is_custom_board || false,
              board_type: res.data?.board_type || "grid",
              category: res.data?.category,
            };
          });
        } else {
          setIsLoading(false);
          setBoard((prev) => {
            return {
              ...prev,
              isCustomBoard: res.data?.is_custom_board || false,
              board_type: res.data?.board_type || "grid",
              category: res.data?.category,
            };
          });
        }
      })
      .catch((error) => {
        dispatch(showMessage({ message: "Error fetching data" }));
        navigate("/connectors/list");
      });
  };

  return (
    <React.Fragment>
      <Stack direction="row">
        {!showSideBar && (
          <Box sx={{ borderRight: `1px solid ${theme.sidebar.borderColor}` }}>
            <Sidebar />
          </Box>
        )}

        <Grid sx={{ height: "100%" }} container>
          <Grid p={4} item md={8}>
            <Grid container>
              <Grid onClick={backButtonNavigation} item>
                <ArrowBackIcon
                  sx={{
                    color: theme.custom.primaryDarkColor,
                    cursor: "pointer",
                  }}
                />
              </Grid>
              <Grid item>
                <Typography variant="poppins_h4_600_20" ml={2}>
                  {titleFromProps ? titleFromProps : title}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid p={4} item md={4}>
            {board && board.data && board.data.length > 0 && (
              <Grid container flexDirection={"row"} justifyContent={"flex-end"}>
                <Grid item style={{ display: "flex" }}>
                  <Stack direction="row" alignItems={"center"} spacing={0.5}>
                    <Typography mr={1} variant={"inter_500_12"}>
                      Connectors
                    </Typography>
                    {connectorsList.map((c) => {
                      return (
                        <>
                          <Avatar sx={{ width: 30, height: 30 }} src={c} />
                        </>
                      );
                    })}
                  </Stack>
                </Grid>
              </Grid>
            )}
          </Grid>
          {board.isCustomBoard && customKPISelected.length === 0 && (
            <Grid
              px={4}
              sx={{
                position: board.isCustomBoard ? "sticky" : "relative",
                background: theme.custom.whiteColor,
                top: board.isCustomBoard ? "64px" : "1%",
                alignSelf: "start",
                zIndex: 1000,
              }}
              item
              md={12}
              mt={2}
            >
              <Box
                style={{
                  height: 200,

                  border: `1px solid ${theme.custom.KPIMoleculeBorderColor}`,
                  borderRadius: "6px",
                }}
                className="board_filters"
              >
                <Grid
                  sx={{
                    textAlign: "center",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                  item
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ marginRight: 2 }}>
                      <img height={20} src={FilterIcon} alt="Filter Icon" />
                    </div>
                    <div>
                      <Typography
                        sx={{ color: theme.custom.primaryDarkColor }}
                        variant="inter_p_400_16"
                      >
                        Select a KPI to see the corresponding filters
                      </Typography>
                    </div>
                  </div>
                </Grid>
              </Box>
            </Grid>
          )}
          <Grid
            px={4}
            sx={{
              position: board.isCustomBoard ? "sticky" : "relative",
              top: board.isCustomBoard ? `64px` : "1%",
              alignSelf: "start",
              zIndex: 1000,
              "& div":
                (board.isCustomBoard && customKPISelected.length === 0) ||
                board.data.length === 0
                  ? {
                      visibility: "hidden",
                      height: 0,
                      width: 0,
                    }
                  : {},
            }}
            item
            md={12}
            mt={2}
          >
            <Box className="board_filters">
              <FilterListWithOverlay
                board_slug={(slug ? slug : slugFromProps) || ""}
                isCustomBoard={board.isCustomBoard}
                onFiltersApply={onFiltersApply}
                urlKpiFilters={urlKpiFilters}
              />
            </Box>
          </Grid>
          <Grid item md={12} mt={2}>
            {isLoading && (
              <Box
                sx={{
                  backgroundImage: `url(${bgImage})`,
                  width: "100%",
                  display: "flex",
                }}
              >
                <Grid px={4} mt={4} container>
                  <Grid item md={6}>
                    <Skeleton
                      sx={{ bgcolor: "grey.200", borderRadius: 2 }}
                      variant="rectangular"
                      width="90%"
                      height={400}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <Skeleton
                      sx={{ bgcolor: "grey.200", borderRadius: 2 }}
                      variant="rectangular"
                      width="90%"
                      height={400}
                    />
                  </Grid>
                </Grid>
              </Box>
            )}
            {!isLoading && board.data.length > 0 && (
              <Box
                pb={6}
                sx={{
                  backgroundImage: `url(${bgImage})`,
                  backgroundRepeat: "repeat",
                  position: "relative",
                  color: theme.custom.filterBackgroundColor,
                  zIndex: "auto",
                }}
              >
                <Box px={4}>
                  <KPIList
                    data={board.data}
                    filters={board.filters}
                    board_slug={(slug ? slug : slugFromProps) || ""}
                    belongToCustomBoard={board.isCustomBoard}
                    board_type={board.board_type}
                    showSmartStatement={showSideBar}
                    extraProps={{ ...board.filters, urlKpiFilters }}
                  />
                </Box>
              </Box>
            )}
            {!isLoading && board.data.length === 0 && (
              <Box py={3} px={6}>
                <Grid
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    background: theme.custom.bgImageColor,
                    minHeight: "250px",
                    borderRadius: 3,
                  }}
                >
                  {board.isCustomBoard && (
                    <Grid item>
                      <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        href="/kpi-builder"
                      >
                        Build Custom KPIs
                      </Button>
                    </Grid>
                  )}
                  {!board.isCustomBoard && (
                    <Grid item>
                      <Typography
                        sx={{ color: theme.custom.primaryDarkColor }}
                        variant="inter_p_400_16"
                      >
                        {errorMessage}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      </Stack>
    </React.Fragment>
  );
}

export default memo(Board);
