import { Grid, Slider, TextField, Typography } from "@mui/material";
import { CSSProperties, useEffect, useState } from "react";
import { theme } from "../../../theme";
import styled from "@emotion/styled";

import { ThemeColorPicker } from "./ThemeColorPicker";
import { getComponentSettings } from "../../../services/impact-report-service";
import { addAlphaToHexCode } from "../../../utils/color-picker-helper";
import { Box } from "@mui/system";
import { useAppDispatch } from "../../../store/hook";
import { setSettingEditActive } from "../../../store/selected-design-element-store";

const InteractiveElementEditorSettings = (props: any) => {
  const Swatch = styled("div")({
    width: "24px",
    height: "24px",
    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.15)",
    border: "1px solid #fff",
    borderRadius: "6px",
    margin: "2px",
    display: "inline-block",
    cursor: "pointer",
  });

  const colorPickerStyle: CSSProperties = {
    position: "absolute",
    padding: "10px",
    background: "#FFFFFF",
    boxShadow: "rgba(0, 0, 0, 0.12) 4px 4px 10px 10px",
    borderRadius: "6px",
    zIndex: 1,
    minWidth: "300px",
    minHeight: "200px",
  };

  const { attributes, updateElement, id } = props;
  const fill = attributes?.fill;
  const stroke = attributes?.strokeColor;
  const [displayPicker, setDisplayPicker] = useState(false);
  const [color, setColor] = useState("");
  const [strokeColor, setStrokeColor] = useState("");
  const [fontSize, setFontSize] = useState(attributes?.fontSize);
  const [label, setLabel] = useState(attributes?.text);
  const [displayStrokeColorPicker, setDisplayStrokeColorPicker] =
    useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setColor(attributes?.fill);
    setStrokeColor(attributes?.strokeColor);
  }, [fill, stroke]);

  const togglePicker = () => {
    if (!displayPicker) {
      props.updateOverlay(true);
    } else {
      props.updateOverlay(false);
    }
    dispatch(setSettingEditActive(!displayPicker));
    setDisplayPicker(!displayPicker);
  };

  const toggleStrokeColorPicker = () => {
    if (!displayStrokeColorPicker) {
      props.updateOverlay(true);
    } else {
      props.updateOverlay(false);
    }
    dispatch(setSettingEditActive(!displayStrokeColorPicker));
    setDisplayStrokeColorPicker(!displayStrokeColorPicker);
  };

  const updateStyle = (value: any, callBack: any) => {
    let nextElement = { ...attributes, fill: value };

    updateElement(id, {
      attributes: nextElement,
    });
    callBack(value);
  };

  const updateFontSize = (e: any) => {
    setFontSize(e.target.value as number);
    let nextElement = { ...attributes, fontSize: e.target.value as number };
    updateElement(id, {
      attributes: nextElement,
    });
  };

  const updateLabel = (e: any) => {
    setLabel(e.target.value as number);
    let nextElement = { ...attributes, text: e.target.value as string };

    updateElement(id, {
      attributes: nextElement,
    });
  };

  const updateHexCode = (hexCode: string) => {
    updateStyle(hexCode, setColor);
  };

  const updateStrokeHexCode = (hexCode: string) => {
    let nextElement = { ...attributes, strokeColor: hexCode };

    updateElement(id, {
      attributes: nextElement,
    });
    setStrokeColor(hexCode);
  };

  const onChangeStrokeColor = (colour: any) => {
    updateStrokeHexCode(addAlphaToHexCode(colour.hex, colour.rgb["a"]));
  };

  return (
    <Grid container>
      <Grid sx={{ zIndex: 100 }} item sm={12}>
        <Grid container>
          <Grid item xs={3}>
            <label>
              <Typography
                variant="inter_p_600_16"
                sx={{ color: theme.custom.primaryDarkColor }}
              >
                Fill
              </Typography>
            </label>
            <div aria-describedby={"fill-popper"} onClick={togglePicker}>
              <Swatch style={{ background: color }} />
            </div>
            {displayPicker && (
              <Box style={colorPickerStyle}>
                <ThemeColorPicker
                  closePicker={togglePicker}
                  isShapes={true}
                  onChange={(colour: any) =>
                    updateStyle(
                      addAlphaToHexCode(colour.hex, colour.rgb["a"]),
                      setColor
                    )
                  }
                  updateHexCode={updateHexCode}
                  color={color}
                  colorPickerTitle={"Fill color"}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={3}>
            <label>
              <Typography
                variant="inter_p_600_16"
                sx={{ color: theme.custom.primaryDarkColor }}
              >
                Stroke
              </Typography>
            </label>
            <div
              aria-describedby={"stroke-popper"}
              onClick={toggleStrokeColorPicker}
            >
              <Swatch style={{ background: strokeColor }} />
            </div>
            {displayStrokeColorPicker && (
              <Box style={colorPickerStyle}>
                <ThemeColorPicker
                  closePicker={toggleStrokeColorPicker}
                  isShapes={true}
                  onChange={(colour: any) => onChangeStrokeColor(colour)}
                  updateHexCode={updateStrokeHexCode}
                  color={strokeColor}
                  colorPickerTitle={"Stroke color"}
                />
              </Box>
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        mt={1}
        mb={1}
      >
        <Grid item md={12}>
          <Typography
            variant="inter_p_600_16"
            sx={{ color: theme.custom.primaryDarkColor }}
          >
            Add Label
          </Typography>
        </Grid>
        <Grid item md={12}>
          <TextField
            placeholder="Paste a link here"
            id="search-icon"
            sx={{ mt: 1, width: "100%" }}
            onChange={updateLabel}
            value={attributes?.text}
            onFocus={() => dispatch(setSettingEditActive(true))}
            onBlur={() => dispatch(setSettingEditActive(false))}
            inputProps={{
              style: {
                height: "10px",
                background: theme.custom.whiteColor,
                fontSize: "14px",
                fontWeight: 500,
              },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InteractiveElementEditorSettings;
