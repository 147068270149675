import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Stack,
  Typography,
  TextField,
  MenuItem,
} from "@mui/material";

import "../../../styles/CommonKPIStyles.scss";
import { fontInter, theme } from "../../../theme";
import {
  KPIBuilderChartType,
  SeriesTypeInfo,
} from "../../../Types/KPIBuilderChartType";
import { useDebounce } from "react-use";
type Props = {
  allChartTypes: any;
  savedChartTypeData: any;
  updateChartType: Function;
  seriesLength: any;
};

const INIT_CHART_TYPE_INFO: KPIBuilderChartType = {
  aggregation_type: "",
  chart_type: "",
  chart_type_title: "",
  kpi_title: "",
  series_type_info: {} as SeriesTypeInfo,
  unit: "",
};

const SelectChartType = (props: Props) => {
  const { allChartTypes, savedChartTypeData, updateChartType, seriesLength } =
    props;
  const [chartTypes, setChartTypes] = useState([] as any);
  const [selectedChartInfo, setSelectedChartInfo] =
    useState(INIT_CHART_TYPE_INFO);
  const [debouncedValue, setDebouncedValue] = useState(
    selectedChartInfo.kpi_title
  );

  useEffect(() => {
    setChartTypes(allChartTypes);
  }, [allChartTypes]);

  const [, cancel] = useDebounce(
    () => {
      setDebouncedValue(selectedChartInfo.kpi_title);
      updateChartType(selectedChartInfo);
    },
    1000,
    [selectedChartInfo.kpi_title]
  );

  useEffect(() => {
    if (Object.keys(savedChartTypeData).length > 0) {
      let _chartType: KPIBuilderChartType = {
        kpi_title: savedChartTypeData.kpi_title,
        series_type_info: savedChartTypeData.series_type_info,
        chart_type: savedChartTypeData.chart_type,
        aggregation_type: savedChartTypeData.aggregation_type || "",
        chart_type_title: savedChartTypeData?.label || "",
        unit: savedChartTypeData?.unit || "",
      };
      setSelectedChartInfo(_chartType);
    }
  }, [JSON.stringify(savedChartTypeData)]);

  const onChartTypeSelect = (item: any) => {
    let _selectedChartInfo: KPIBuilderChartType = {
      ...selectedChartInfo,
      ...{
        chart_type: item.chart_type,
        chart_type_title: item.label,
        series_type_info: item.series_type_info,
        aggregation_type: "stacked",
      },
    };
    updateChartType(_selectedChartInfo);
    deleteAllTables();
  };

  const deleteAllTables = () => {
    let tables = document.getElementsByClassName("highcharts-data-table");
    if (tables && tables.length) {
      for (var i = 0; i < tables.length; i++) {
        tables[i].remove();
      }
    }
  };

  const onKPITitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let _selectedChartType = { ...selectedChartInfo };
    _selectedChartType.kpi_title = event.target.value;
    setSelectedChartInfo(_selectedChartType);
  };

  const onAggregateColumnChange = (event: any) => {
    let _selectedChartType = { ...selectedChartInfo };
    let value = event.target.value;
    _selectedChartType.aggregation_type = value;
    setSelectedChartInfo(_selectedChartType);
    deleteAllTables();
    updateChartType(_selectedChartType);
  };

  return (
    <>
      <Box>
        <Grid mt={1} container>
          <Grid className="chart_type-wrapper" item pt={2} pr={2}>
            <Grid sx={{ width: "100%" }} spacing={2} container>
              {chartTypes &&
                chartTypes.length > 0 &&
                chartTypes.map((item: any, index: number) => {
                  return (
                    <Grid key={index} item sm={4}>
                      <Box
                        sx={{
                          display: "flex",
                          height: 90,
                          verticalAlign: "middle",
                          overflow: "hidden",
                          background:
                            item.chart_type === selectedChartInfo.chart_type
                              ? theme.kpiBuilderColors.chartTypeBackgroundColor
                              : theme.custom.whiteColor,

                          border: `1px solid ${
                            item.chart_type === selectedChartInfo.chart_type
                              ? theme.custom.CustomCheckboxBgColor
                              : theme.custom.menuDividerColor
                          }`,
                          borderRadius: "6px",
                          cursor: "pointer",
                        }}
                        onClick={() => onChartTypeSelect(item)}
                      >
                        <Stack
                          px={"10px"}
                          direction={"row"}
                          alignItems="center"
                        >
                          <Grid item sx={{ marginRight: "10%" }}>
                            <img
                              style={{
                                verticalAlign: "middle",
                                maxWidth: 40,
                              }}
                              src={item.thumbnail}
                              alt=""
                            />
                          </Grid>
                          <Grid minWidth={60} item sx={{ overflow: "hidden" }}>
                            <Typography
                              variant="inter_400_14"
                              sx={{
                                color: theme.custom.primaryDarkColor,
                                wordBreak: "break-word",
                              }}
                            >
                              {item.label}
                            </Typography>
                          </Grid>
                        </Stack>
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
            <Grid mt={3} container sx={{ width: "100%" }}>
              {selectedChartInfo && selectedChartInfo.chart_type && (
                <Grid item sm={12}>
                  <TextField
                    inputProps={{
                      maxLength: 512,
                      style: {
                        fontFamily: fontInter,
                        fontSize: "14px",
                        fontWeight: 500,
                        background: theme.custom.whiteColor,
                        color: theme.custom.primaryDarkColor,
                      },
                    }}
                    value={selectedChartInfo.kpi_title || ""}
                    onChange={onKPITitleChange}
                    size="small"
                    label={
                      <Typography
                        variant="inter_500_12"
                        sx={{ color: theme.custom.primaryGreyColor }}
                      >
                        KPI Name
                      </Typography>
                    }
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
            {selectedChartInfo &&
              selectedChartInfo.series_type_info &&
              selectedChartInfo.series_type_info.isAggregateRequired &&
              (selectedChartInfo.chart_type === "column" ||
                selectedChartInfo.chart_type === "bar") &&
              seriesLength &&
              Object.keys(seriesLength).length > 0 &&
              seriesLength["y"] > 1 && (
                <Grid mt={2} container sx={{ width: "100%" }}>
                  <Grid item sm={12}>
                    <TextField
                      label={
                        <Typography
                          variant="inter_500_12"
                          sx={{ color: theme.custom.primaryGreyColor }}
                        >
                          Aggregate column
                        </Typography>
                      }
                      id={"Aggregate column"}
                      select
                      size="small"
                      value={selectedChartInfo.aggregation_type || ""}
                      fullWidth
                      onChange={(e) => onAggregateColumnChange(e)}
                    >
                      <MenuItem
                        sx={{ height: 40 }}
                        key={"stacked"}
                        value={"stacked"}
                      >
                        <Typography variant="inter_p_500_14" mt={0.3}>
                          Stacked
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        sx={{ height: 40 }}
                        key={"grouped"}
                        value={"grouped"}
                      >
                        <Typography variant="inter_p_500_14" mt={0.3}>
                          Grouped
                        </Typography>
                      </MenuItem>
                    </TextField>
                  </Grid>
                </Grid>
              )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SelectChartType;
