import {
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getExpandedRowModel,
  flexRender,
  Column,
  RowPinningState,
} from "@tanstack/react-table";
import { CSSProperties, useEffect, useRef, useState } from "react";
import { fontInter, theme } from "../../theme";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { map, takeRight } from "lodash";
import { PinnedObj } from "../../Types/ChartMeta";

type Props = {
  columns: any;
  data: any;
  isFullscreen: boolean;
  setFullscreen: Function;
  forImpactReport?: boolean;
  collapsed: boolean;
  isGroupTable?: boolean;
  pinned?: PinnedObj;
  enableRowSelection?: boolean;
  onRowsSelection?: Function;
  getTableRef?: Function;
  onRender?: Function;
};

const DataTable = (props: Props) => {
  const {
    columns,
    data,
    isFullscreen,
    setFullscreen,
    collapsed,
    forImpactReport,
    isGroupTable,
    pinned,
    enableRowSelection,
    onRowsSelection,
    getTableRef,
  } = props;

  const [expanded, setExpanded] = useState<ExpandedState>({});
  const [rowPinning, setRowPinning] = useState<RowPinningState>({
    top: [],
    bottom: [],
  });
  const [rowSelection, setRowSelection] = useState({});

  const table = useReactTable({
    data,
    columns,
    enableRowSelection,
    onRowSelectionChange: setRowSelection,
    state: {
      expanded,
      rowPinning,
      rowSelection,
    },
    onRowPinningChange: setRowPinning,
    onExpandedChange: setExpanded,
    getSubRows: (row: any) => {
      return row.subRows;
    },
    keepPinnedRows: true,
    getCoreRowModel: getCoreRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    defaultColumn: {
      minSize: 0,
      size: 100,
    },
  });

  useEffect(() => {
    if (collapsed && !forImpactReport) {
      table.toggleAllRowsExpanded(false);
    } else {
      table.toggleAllRowsExpanded(true);
    }
    if (!forImpactReport) {
      let allCols = table.getAllColumns()?.[0]?.["columns"] || [];
      let allRows = table.getSortedRowModel().rows;
      let obj: any = {
        left: pinned?.["left"] ? allCols.slice(0, pinned?.["left"]) : [],
        right: pinned?.["right"] ? takeRight(allCols, pinned?.["right"]) : [],
        top: pinned?.["top"] ? allRows.slice(0, pinned?.["top"]) : [],
        bottom: pinned?.["bottom"]
          ? takeRight(allRows, pinned?.["bottom"])
          : [],
      };

      if (obj) {
        (obj?.left || []).forEach((el: any) => {
          table.getColumn(el.id)?.pin("left");
        });

        (obj?.right || []).forEach((el: any) => {
          table.getColumn(el.id)?.pin("right");
        });

        (obj?.top || []).forEach((row: any) => {
          row.pin("top", true, false);
        });
        (obj?.bottom || []).forEach((row: any) => {
          row.pin("bottom", true, true);
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (onRowsSelection) {
      let selectedRows: any = [];
      selectedRows = map(rowSelection, (ele: any, key: any) => {
        if (!table.getRowModel().rows[key]) {
          return;
        }
        return table.getRow(key)?.original;
      });
      onRowsSelection(selectedRows);
    }
  }, [rowSelection]);

  useEffect(() => {
    if (getTableRef) {
      getTableRef(table);
    }
  }, [table]);

  // Func to provide props to table cell
  const getCellProps = (context: any) => {
    let classes: string[] = [];

    if (
      context.row.getIsExpanded() &&
      context["column"]["id"] !== "particulars" &&
      context.row.subRows.length > 0
    ) {
      classes.push("hide-cell-values");
    }

    switch (context.row?.original?.type) {
      case "ROW_HEADER" || "HEADER":
        classes.push("row_header");
        break;
      case "SUB_HEADER":
        classes.push("sub_header");
        break;
      case "ROW":
        classes.push("normal_row");
        break;
      case "TOTAL_HEADER" || "TOTAL_HEADER_SUB":
        classes.push("total_header");
        break;
      default:
        classes.push("no_style");
        break;
    }
    return {
      className: classes.join(" "),
    };
  };

  const handle = useFullScreenHandle();

  useEffect(() => {
    if (isFullscreen) {
      handle.enter();
      setFullscreen(false);
    }
  }, [isFullscreen]);

  const getCommonPinningStyles = (column: Column<any>): CSSProperties => {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn =
      isPinned === "left" && column.getIsLastColumn("left");

    const isFirstRightPinnedColumn =
      isPinned === "right" && column.getIsFirstColumn("right");
    return {
      boxShadow: isLastLeftPinnedColumn
        ? "-4px 0 4px -4px gray inset"
        : isFirstRightPinnedColumn
        ? "4px 0 4px -4px gray inset"
        : undefined,

      left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
      right: isPinned === "right" ? `${column.getAfter("right")}px` : undefined,
      opacity: 1,
      position: isPinned ? "sticky" : "relative",
      zIndex: isPinned ? 1 : 0,
    };
  };

  const returnRowPinningStyles = (row: any): any => {
    return {
      position:
        row.getIsPinned() && row.parentId === undefined ? "sticky" : "static",
      zIndex: row.getIsPinned() && !row.parentId === undefined ? 0 : 2100,
      opacity: 1,
      height: row.getIsPinned() && !row.parentId ? "20px" : "auto",
      top:
        row.getIsPinned() && row.parentId === undefined
          ? `${row.getPinnedIndex() * 15 + 28}px`
          : undefined,

      bottom:
        row.getIsPinned() === "bottom"
          ? `${
              (table.getBottomRows().length - 1 - row.getPinnedIndex()) * 45
            }px`
          : undefined,
    };
  };

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (ref.current && props.onRender) {
        props.onRender();
      }
    });
  }, []);

  return (
    <div ref={ref}>
      <FullScreen
        handle={handle}
        className={handle.active ? "enable_scroll" : ""}
      >
        <table>
          <thead
            style={{
              background: theme.dataTableColors.headerBgColor,
              fontFamily: fontInter,
              borderBottom: `1px solid ${theme.custom.borderColor}`,
              fontSize: 12,
              fontWeight: 500,
              textAlign: isGroupTable ? "center" : "left",
            }}
          >
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const { column } = header;
                  return (
                    <th
                      key={header.id}
                      style={{
                        width:
                          header.getSize() === 0 ? undefined : header.getSize(),
                        ...getCommonPinningStyles(column),
                        background: theme.dataTableColors.headerBgColor,
                        borderTop: isGroupTable ? "none !important" : "auto",
                        borderBottom: isGroupTable ? "none !important" : "auto",
                        borderRight: `1px solid ${theme.custom.borderColor}`,
                        boxShadow: isGroupTable
                          ? "inset 0 1px 0 #e1e1e1, inset 0 -1px 0 #e1e1e1,inset 1px 0 1px #e1e1e1"
                          : "auto",
                        backgroundClip: isGroupTable ? "padding-box" : "auto",
                      }}
                      colSpan={header.colSpan}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr style={{ ...returnRowPinningStyles(row) }} key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td
                    {...getCellProps(cell.getContext())}
                    style={{
                      width:
                        cell.column.getSize() !== 0
                          ? cell.column.getSize()
                          : undefined,
                      ...getCommonPinningStyles(cell.column),
                      background: row.getIsSelected() ? "#FAFAFA" : "white",
                    }}
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </FullScreen>
    </div>
  );
};

export default DataTable;
