import React, { ReactElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getAccessToken } from "../services/authentication-service";
import { getCompanyId } from "../services/userinfo-service";

function AuthGuard({ children }: any): ReactElement {
  const location = useLocation();
  if (getAccessToken() && getAccessToken().length > 0 && !!getCompanyId()) {
    return children
  } else {
    return <Navigate to="/login" replace state={{ path: location.pathname }} />;
  }
}

export default AuthGuard;
