import { createSlice } from "@reduxjs/toolkit";

const filterOverlay = createSlice({
  name: "filter-overlay",
  initialState: {
    overlay: false,
  },
  reducers: {
    showOverlay(state, action) {
      state.overlay = !state.overlay;
    },
    setOverlay(state, action) {
      state.overlay = action.payload;
    },
  },
});

export const { showOverlay, setOverlay } = filterOverlay.actions;
export default filterOverlay.reducer;