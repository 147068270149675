import { createSlice } from "@reduxjs/toolkit";
import _, { set, unset } from "lodash";

const initialState = {
  isXValid: false,
  isYValid: false,
  x: {} as any,
  y: {} as any,
  orderBy: {
    axis: "",
    type: "asc",
  },
  board: {},
  filters: {},
  order_by_options: [] as any,
  isDataSourceDirty: false,
  isAxisNamesUnique: false,
};
export const dataSourceSlice = createSlice({
  name: "data-source",
  initialState,
  reducers: {
    addXSeries(state, action) {
      let newDataSourceX = JSON.parse(JSON.stringify(state.x));
      newDataSourceX = action.payload.data;
      state.x = newDataSourceX;
    },
    addYSeries(state, action) {
      let newDataSource = JSON.parse(JSON.stringify(state.y));
      newDataSource = action.payload.data;
      state.y = newDataSource;
    },
    updateXSeries(state, action) {
      let key = action.payload.key;
      let field = action.payload.field;
      let newDataSourceX = JSON.parse(JSON.stringify(state.x));
      newDataSourceX[key] = {
        ...newDataSourceX[key],
        [field]: action.payload.data,
      };

      state.x = newDataSourceX;
    },
    updateYSeries(state, action) {
      let key = action.payload.key;
      let field = action.payload.field;
      let newDataSource = JSON.parse(JSON.stringify(state.y));
      newDataSource[key] = {
        ...newDataSource[key],
        [field]: action.payload.data,
      };

      state.y = newDataSource;
    },
    deleteYSeries(state, action) {
      unset(state.y, [action.payload]);
    },
    addOrderByOptions(state, action) {
      let newDataSource = JSON.parse(JSON.stringify(state.order_by_options));
      newDataSource.push(action.payload);
      state.order_by_options = newDataSource;
    },
    updateXValid(state, action) {
      state.isXValid = action.payload;
    },
    updateYValid(state, action) {
      state.isYValid = action.payload;
    },
    updateOrderByOptions(state, action) {
      state.order_by_options = action.payload;
    },
    updateOrderBySelected(state, action) {
      state.orderBy = action.payload;
    },
    updateSortType(state, action) {
      let temp = { ...state.orderBy };
      temp.type = action.payload;
      state.orderBy = temp;
    },
    updateDirtyKey(state, action) {
      state.isDataSourceDirty = action.payload;
    },
    updateIsAxisNamesUnique(state, action) {
      state.isAxisNamesUnique = action.payload;
    },
    updateFilters(state, action) {
      set(state.filters, [action.payload.id], action.payload.data);
    },
    deleteFilters(state, action) {
      unset(state.filters, [action.payload]);
    },
    resetFilters(state, action) {
      state.filters = action.payload;
    },
    resetDataSource(state) {
     state.isXValid = false;
     state.isYValid = false;
     state.x = {};
     state.y = {};
    },
  },
});

export const {
  addXSeries,
  addYSeries,
  updateXSeries,
  updateYSeries,
  deleteYSeries,
  addOrderByOptions,
  updateOrderByOptions,
  updateOrderBySelected,
  updateSortType,
  updateXValid,
  updateYValid,
  updateDirtyKey,
  updateIsAxisNamesUnique,
  updateFilters,
  deleteFilters,
  resetFilters,
  resetDataSource,
} = dataSourceSlice.actions;
export default dataSourceSlice.reducer;
