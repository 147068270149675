import React from "react";
import { useParams } from "react-router-dom";
import QuickbooksUserMapping from "../components/complexes/QuickbooksUserMapping";
import NotFoundPage from "./NotFoundPage";

interface Props {}

const UserMapping = (props: Props) => {
  const { connectorSlug } = useParams();
  const renderHelper = () => {
    if (connectorSlug === "quickbooks") {
      return <QuickbooksUserMapping />;
    } else {
      return <NotFoundPage />;
    }
  };
  return <>{renderHelper()}</>;
};

export default UserMapping;
