import { Stack, Typography } from "@mui/material";
import CustomLinearProgress from "../components/atoms/CustomLinearProgress";

export const generateStatusDisplay = (
  status: string | undefined,
  extraProps?: any
) => {
  if (status === "fail") {
    return (
      <Typography
        variant={extraProps ? extraProps.variant : "caption"}
        color="red"
      >
        Sync failed
      </Typography>
    );
  } else if (status === "success") {
    return (
      <Typography
        variant={extraProps ? extraProps.variant : "caption"}
        color="green"
      >
        {extraProps && extraProps.isExcelUploadStatus
          ? "Upload complete"
          : "Sync complete"}
      </Typography>
    );
  } else if (status === "auth_failed") {
    return (
      <Stack direction="column" alignItems="right">
        <Typography
          variant={extraProps ? extraProps.variant : "caption"}
          color="red"
          align="right"
        >
          Authorization failed
        </Typography>
        {extraProps && extraProps.showSyncMessage && (
          <Typography variant="caption" color="grey.500">
            Your connection is not active. You might be seeing the old data,
            please click <a href="/connectors/list">here</a> to connect.
          </Typography>
        )}
      </Stack>
    );
  } else if (status === "running" || "resyncing" || "syncing") {
    return (
      <Stack
        direction="row"
        alignItems="center"
        justifyContent={extraProps ? extraProps.progressJustify : ""}
      >
        <Typography variant={extraProps ? extraProps.variant : "inter_500_12"}>
          Syncing...{" "}
        </Typography>
        <CustomLinearProgress
          width={extraProps ? extraProps.width : 75}
          extra={{ ml: 2, ...(extraProps ? extraProps.progressProps : "") }}
        />
      </Stack>
    );
  } else {
    return "";
  }
};

export const popupCenter = (
  window: any,
  screen: any,
  url: URL,
  title: string,
  w: number,
  h: number
) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  );

  if (window.focus) newWindow.focus();
};
