import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Backdrop,
  Modal,
  Box,
  Fade,
  Typography,
  Divider,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import { fontInter, theme } from "../../theme";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AIIcon from "../../assets/content_icon.png";
import GeneratingContentLoader from "../../assets/magic_wand_multi_color.png";
import { generateTextRewrite } from "../../services/menu-service";
import { getConversations } from "../../services/ai";
import "./GenerateContentModal.scss";
import { useAppDispatch } from "../../store/hook";
import { showMessage } from "../../store/error-handler-store";

type Props = {
  openModal: boolean;
  closeModal: Function;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  outline: 0,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "6px",
  p: 4,
};

const GenerateContentModal = (props: Props) => {
  const { openModal, closeModal } = props;
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [text, setText] = useState<string>("");
  const [generatedResults, setGeneratedResults] = useState<string>("");
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    if (openModal) {
      handleClickOpen();
    }
  }, [openModal]);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (event: any, reason: any = undefined) => {
    if (reason === "backdropClick") return;
    setOpen(false);
    setGeneratedResults("");
    setText("");
    closeModal();
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
  };

  const generateText = () => {
    setLoading(true);
    abortControllerRef.current = new AbortController();
    getConversations({ text: text }, abortControllerRef.current.signal).then((res) => {
      setGeneratedResults(res?.data?.response || "");
      setLoading(false);
    });
  };

  const handleTextRewriteClick = (text_rephrase: string) => {
    setLoading(true);
    let requestTextRewriteObj = {
      template_slug: text_rephrase,
      context: { content: generatedResults },
    };
    generateTextRewrite(requestTextRewriteObj)
      .then((res) => {
        if (res?.data?.response) {
          setGeneratedResults(res.data.response);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Modal
        sx={{ zIndex: 10000 }}
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Grid mb={2.5} container alignItems={"center"}>
              <Grid textAlign={"left"} item xs={11}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ marginRight: 10 }}>
                    <img src={AIIcon} height={40} width={40} alt="AI" />
                  </span>
                  <Typography
                    sx={{ color: `${theme.custom.primaryDarkColor}` }}
                    variant="inter_p_600_16"
                  >
                    Generate Content with AI
                  </Typography>
                </div>
              </Grid>
              <Grid textAlign="right" item xs={1}>
                <CancelOutlinedIcon
                  onClick={handleClose}
                  sx={{
                    color: `${theme.custom.primaryGreyColor}`,
                    cursor: "pointer",
                  }}
                />
              </Grid>
            </Grid>
            <Divider orientation="horizontal" flexItem />
            <Box
              height={100}
              p={0.5}
              mt={3}
              mb={3}
              sx={{
                border: `1px solid ${theme.custom.primaryDarkColor}`,
                borderRadius: "6px",
              }}
            >
              <TextField
                sx={{
                  "& fieldset": { border: "none" },
                }}
                inputProps={{
                  style: {
                    fontFamily: fontInter,
                    fontSize: 14,
                    color: theme.custom.primaryGreyColor,
                  },
                }}
                placeholder="Write your prompt.."
                fullWidth
                minRows={6}
                onChange={(e: any) => setText(e?.target?.value || "")}
              />
              <Button
                disabled={loading}
                onClick={(e: any) => {
                  generateText();
                }}
                sx={{ float: "right" }}
              >
                <Typography variant="poppins_p_600_14">
                  {generatedResults.length ? "Regenerate" : "Generate"}
                </Typography>
              </Button>
            </Box>
            {generatedResults && generatedResults.length && (
              <Box sx={{ width: "100%" }} mt={1}>
                <Button
                  disabled={loading}
                  onClick={(e) => {
                    handleTextRewriteClick("text_rephrase_summarise");
                  }}
                  sx={{ marginRight: 1 }}
                  variant="outlined"
                >
                  <Typography variant="poppins_600_12">
                    Summarize
                  </Typography>
                </Button>
                <Button
                  disabled={loading}
                  onClick={(e) => {
                    handleTextRewriteClick("text_rephrase_more_casual");
                  }}
                  sx={{ marginRight: 1 }}
                  variant="outlined"
                >
                  <Typography variant="poppins_600_12">
                    Make it more casual
                  </Typography>
                </Button>
                <Button
                  disabled={loading}
                  onClick={(e) => {
                    handleTextRewriteClick("text_rephrase_more_formal");
                  }}
                  sx={{ marginRight: 1 }}
                  variant="outlined"
                >
                  <Typography variant="poppins_600_12">
                    Make it more Formal
                  </Typography>
                </Button>
                <Button
                  disabled={loading}
                  onClick={(e) => {
                    handleTextRewriteClick("text_rephrase_fix_spelling_and_grammer");
                  }}
                  sx={{ marginRight: 1 }}
                  variant="outlined"
                >
                  <Typography variant="poppins_600_12">Fix Grammar & Spelling</Typography>
                </Button>
              </Box>
            )}
            {generatedResults && generatedResults.length && (
              <Box sx={{ textAlign: "right" }}>
                <Button
                  disabled={loading}
                  onClick={() => {
                    navigator.clipboard.writeText(generatedResults);
                    dispatch(
                      showMessage({
                        message: "Text copied successfully",
                        variant: "success",
                      })
                    );
                  }}
                >
                  Copy
                </Button>
              </Box>
            )}
            {
              <Box
                sx={{ minHeight: 350 }}
                className={
                  generatedResults.length && !loading ? "gradient-border" : ""
                }
              >
                {generatedResults.length > 0 && !loading && (
                  <Typography
                    sx={{ color: theme.custom.primaryDarkColor }}
                    variant="inter_p_400_16"
                  >
                    {generatedResults}
                  </Typography>
                )}
                <Box>
                  {loading && (
                    <Box
                      sx={{
                        position: "relative",
                        height: "350px",
                        width: "100%",
                      }}
                    >
                      <Grid item xs={12}>
                        <Box>
                          <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                              background: theme.custom.bgImageColor,
                              borderRadius: 3,
                              minHeight: 350,
                            }}
                          >
                            <Grid item>
                              <Stack alignItems={"center"} direction="row">
                                <img
                                  style={{ marginRight: "10px" }}
                                  src={GeneratingContentLoader}
                                  height={30}
                                  width={30}
                                  alt="Loading."
                                />
                                <Typography
                                  sx={{ color: theme.custom.primaryGreyColor }}
                                  variant="inter_p_500_14"
                                >
                                  Generating content
                                </Typography>
                              </Stack>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Box>
            }
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default GenerateContentModal;
